import { useRef } from 'react';
import { dismiss, showInfo, showSuccess } from '../../../services/notification.service';
import { ActiveRecordContextProps } from '../active-record.component';
import { BehaviorSubject } from 'rxjs';
import { useTranslation } from 'react-i18next';

const useActiveRecordBatchSave = (
  panelSettings?: BehaviorSubject<ActiveRecordContextProps>,
  setActiveRecordPanelFrozen?: (frozen: boolean) => void,
  closeAll?: () => void
) => {
  const { t } = useTranslation();
  const dirtyItems = useRef<Array<string>>([]);
  const saveFailedItems = useRef<Array<string>>([]);
  const toastId = useRef<string>();
  const batchSize = useRef<number>(0);

  const setItemDirty = (id: string) => {
    if (!dirtyItems.current.includes(id)) {
      dirtyItems.current.push(id);
    }
    saveFailedItems.current = saveFailedItems.current.filter((_id) => _id !== id);
  };

  const clearItemDirty = (id: string) => {
    dirtyItems.current = dirtyItems.current.filter((_id) => _id !== id);
    saveFailedItems.current = saveFailedItems.current.filter((_id) => _id !== id);
    _updateSaveProgress();
  };

  const setItemSaveFailed = (id: string) => {
    dirtyItems.current = dirtyItems.current.filter((_id) => _id !== id);
    if (!saveFailedItems.current.includes(id)) {
      saveFailedItems.current.push(id);
    }
    _updateSaveProgress();
  };

  const hasDirtyItems = () => {
    return dirtyItems.current.length > 0;
  };

  const showBatchSaveInProgress = () => {
    if (!toastId.current) {
      setActiveRecordPanelFrozen?.(true);

      batchSize.current = dirtyItems.current.length;
      toastId.current = showInfo(t('performing-save-percentage', { percent: '0' }), {
        id: 'active-records-batch-save',
        duration: Infinity,
      });

      panelSettings?.next({
        ...panelSettings.value,
        batchSaveInProgress: true,
        numberOfDirtyItems: dirtyItems.current.length,
      });
    }
  };

  const _updateSaveProgress = () => {
    if (toastId.current) {
      if (dirtyItems.current.length === 0) {
        dismiss(toastId.current);
        toastId.current = undefined;

        if (saveFailedItems.current.length > 0) {
          showInfo(
            t('items-save-failed', { count: saveFailedItems.current.length, total: saveFailedItems.current.length }),
            { duration: 4000 }
          );
        } else {
          showSuccess(t('saved-all-items'));
        }

        dirtyItems.current = [...saveFailedItems.current];
        saveFailedItems.current = [];

        panelSettings?.next({
          ...panelSettings.value,
          batchSaveInProgress: false,
          numberOfDirtyItems: dirtyItems.current.length,
          numberOfFailedItems: 0,
        });

        // execute after the success message is dispalyed for 1 second
        setTimeout(() => {
          setActiveRecordPanelFrozen?.(false);

          if (dirtyItems.current.length === 0) {
            // explicitly close all to handle case when non-dirty items remain open
            closeAll?.();
          }
        }, 1000);
      } else {
        const percent = _totalPercentage();
        showInfo(t('performing-save-percentage', { percent }), {
          id: toastId.current,
          duration: Infinity,
        });

        panelSettings?.next({
          ...panelSettings.value,
          numberOfDirtyItems: dirtyItems.current.length,
          numberOfFailedItems: saveFailedItems.current.length,
        });
      }
    }
  };

  const _totalPercentage = () => {
    const rawPercentage = ((batchSize.current - dirtyItems.current.length) / batchSize.current) * 100;
    return Math.round(rawPercentage / 10) * 10; // round to nearest 10
  };

  return {
    setItemDirty,
    clearItemDirty,
    setItemSaveFailed,
    hasDirtyItems,
    showBatchSaveInProgress,
  };
};

export default useActiveRecordBatchSave;
