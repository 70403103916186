import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useModulesQuery } from '../../../redux/services/api';
import DynamicPage from '../../../pages/dynamic.page';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { dynamicPageState, setSelectedForm } from '../../../redux/slices/dynamic-page-slice';
import { ModuleForm } from '../../../@types/modules';
import { DropDownResult } from '@myosh/odin-components';
import DynamicPageLoader from '../../dynamic-page/dynamic-page-loader.component';
import RecordNavigatorRoute from '../../dynamic-page/record-navigator-route.component';
import { useTranslation } from 'react-i18next';
import Meta from '../../../features/meta/meta';
import DynamicPageHeader from '../../dynamic-page/dynamic-page-header';
import useFormPermissions from '../../../hooks/use-form-permissions';
import { extractFormPermissions } from '../../../common/form-permissions-util';
import useLegacyRecordUrl from '../../dynamic-page/use-legacy-record-url';
import { allFormsForm } from '../../../common/common-functions';
import useProfileData from '../../../hooks/use-profile-data';

function DynamicPageRoute() {
  const { lastUsedForms } = useAppSelector(dynamicPageState);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { viewId, formId, schema } = useParams();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const [viewHasChanged, setViewHasChanged] = useState(false);

  // needed to resolve recordId when a url is copy/pasted
  const match = useMatch('/:schema/modules/:viewId/forms/:formId/records/:id');
  const { id: recordId } = match?.params ?? {};

  const { profileData: { user: userData } = {} } = useProfileData();
  const isLegacyUrl = formId === 'records';
  const { formId: legacyFormId, recordId: legacyRecordId, error: legacyRecordFetchError } = useLegacyRecordUrl();

  const lastUsedForm = useMemo(() => {
    if (viewId) {
      return lastUsedForms[viewId];
    }
    // this memo intentionally does not specify `lastUsedForms` as a dependency
  }, [viewId]);

  const { data: modules, isFetching: isModuleFormsFetching } = useModulesQuery({ includeFormList: true });

  const module = useMemo(() => {
    if (modules && viewId) {
      return modules.find((item) => item.viewId === viewId);
    }
  }, [modules, viewId]);

  const forms: Array<ModuleForm> = useMemo(() => {
    if (module?.forms) {
      const forms = [...module.forms];
      if (forms.length > 1) {
        forms.push(allFormsForm);
      }
      return forms;
    }
    return [];
  }, [module]);

  const showAllFormsFirst = useMemo(() => Boolean(module?.showAllFormsFirst), [module]);

  const resolvedForm: DropDownResult | undefined = useMemo(() => {
    if (forms && !isModuleFormsFetching) {
      // needed for backward URL compatiblity
      let _formId: string | number | undefined;
      if (isLegacyUrl && legacyFormId) {
        _formId = legacyFormId;
      } else if (!isLegacyUrl && formId) {
        _formId = formId;
      }

      const form = forms.find(
        (form: ModuleForm) => form.id === Number(_formId) || (form.id === 0 && _formId === '/all-forms')
      );

      if (_formId && form) {
        return {
          value: form.id,
          text: form.caption,
        };
      }

      const hasActiveForms = lastUsedForm !== undefined || forms.length > 0;
      if (hasActiveForms && (!isLegacyUrl || (isLegacyUrl && legacyRecordFetchError))) {
        let formToLoad = {} as DropDownResult;
        if (lastUsedForm) {
          formToLoad = lastUsedForm;
        } else if (showAllFormsFirst && forms.length > 1) {
          formToLoad = {
            value: 0,
            text: 'All forms',
          };
        } else if (forms) {
          const defaultForm = forms.find((form) => form.defaultForm === true);
          const isAllForms = pathname.includes('/all-forms');
          const moduleAllForms = forms.find((form) => form.id === 0);

          if (isAllForms && moduleAllForms) {
            formToLoad = {
              value: moduleAllForms.id,
              text: moduleAllForms.caption,
            };
          } else if (defaultForm) {
            formToLoad = {
              value: defaultForm.id,
              text: defaultForm.caption,
            };
          } else if (forms.length > 0) {
            formToLoad = {
              value: forms[0].id,
              text: forms[0].caption,
            };
          }
        }

        return formToLoad;
      }
    }
  }, [
    formId,
    legacyFormId,
    legacyRecordFetchError,
    isLegacyUrl,
    lastUsedForm,
    forms,
    showAllFormsFirst,
    isModuleFormsFetching,
  ]);

  const onFormChange = useCallback(
    (form: DropDownResult) => {
      let path = pathname,
        state;
      if (isLegacyUrl) {
        path = pathname.substring(0, pathname.indexOf('/records'));
        state = { legacyRecordId }; // store legacyRecordId in state so it can be resolved after navigate
      } else if (pathname.includes('/forms')) {
        path = pathname.substring(0, pathname.indexOf('/forms'));
        state = { recordId };
      } else if (pathname.includes('/all-forms')) {
        path = pathname.substring(0, pathname.indexOf('/all-forms'));
        state = { recordId };
      }

      if (form.value === 0) {
        navigate(`${path}/all-forms`, { state });
      } else {
        navigate(`${path}/forms/${form.value}`, { state });
      }
    },
    [pathname, isLegacyUrl, legacyRecordId]
  );

  const replaceWithBasePath = useCallback(() => {
    if (resolvedForm) {
      onFormChange(resolvedForm);
    }
  }, [resolvedForm]);

  useEffect(() => {
    if (viewId && resolvedForm) {
      if (!pathname.includes(`/forms/${resolvedForm.value}`)) {
        // update URL when we resolve the form
        onFormChange(resolvedForm);

        if (!lastUsedForms[viewId]) {
          // update last used form if there was no value
          dispatch(setSelectedForm({ viewId, form: resolvedForm }));
        }
      } else if (recordId) {
        //otherwise, if we have a recordid, the we need to manually update last used form
        // only if it's different from the last used form for this view
        if (resolvedForm.value !== lastUsedForms[viewId]?.value) {
          dispatch(setSelectedForm({ viewId, form: resolvedForm }));
        }
      }
    }
  }, [viewId, resolvedForm, pathname, onFormChange]);

  const { formPermissions, isLoading: areFormPermissionsLoading } = useFormPermissions(
    resolvedForm ? (resolvedForm?.value as number) : undefined
  );

  //TODO: add allForms permission when we have the permission for that
  const isAllForms = resolvedForm?.value === 0;
  const recordsPermissions = !isAllForms
    ? extractFormPermissions(formPermissions)
    : { create: !userData?.superUser, showCreateButton: !userData?.superUser };

  const resolvedRecordId =
    recordId ??
    legacyRecordId ??
    (state?.legacyRecordId ? String(state.legacyRecordId) : undefined) ??
    (state?.recordId ? String(state.recordId) : undefined);

  if (viewId && resolvedForm && forms && module) {
    return (
      <>
        <Meta title={`myosh - ${resolvedForm?.text ?? t('records')}`} />
        <div className="flex h-full w-full flex-col">
          <DynamicPageHeader
            selectedForm={resolvedForm}
            module={module}
            forms={forms}
            onFormChange={onFormChange}
            permissions={recordsPermissions}
            viewHasChanged={viewHasChanged}
            formPermissions={formPermissions}
          />
          <DynamicPage
            key={`module_${viewId}_forms_${resolvedForm.value}`}
            selectedForm={resolvedForm}
            module={module}
            onViewChange={setViewHasChanged}
          />
        </div>
        {/* Handles the case when the user copy/paste's a record URL */}
        {!areFormPermissionsLoading && formPermissions && resolvedRecordId && (
          <RecordNavigatorRoute
            id={resolvedRecordId}
            schema={schema}
            form={resolvedForm}
            module={module}
            formPermissions={formPermissions}
            replaceWithBasePath={replaceWithBasePath}
          />
        )}
      </>
    );
  }

  return <DynamicPageLoader />;
}

export default DynamicPageRoute;
