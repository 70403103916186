import React from 'react';
import cx from 'classnames';
import { FormLoader } from '@myosh/odin-components';

interface DataGridLoaderProps {
  isAllForms?: boolean;
}

export const DataGridLoader = ({ isAllForms = false }: DataGridLoaderProps) => {
  const containerStyles = cx('z-20 absolute h-full w-full bg-mono-1', { 'pl-10': !isAllForms });
  return (
    <div className={containerStyles}>
      <div className="h-14">
        <FormLoader width="xl" height="lg" />
      </div>
      <div className="pt-2">
        <FormLoader width="xl" height="md" />
        <FormLoader width="xl" height="md" />
        <FormLoader width="xl" height="md" />
      </div>
    </div>
  );
};
