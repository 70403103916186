import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ContinuousScroller,
  JsonData,
  JsonDataWrapper,
  Loader,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import { useGetMyActivitiesQuery } from '../../../redux/services/record';
import { useUser } from '@myosh/myosh-login';
import OdinLoadingIndicator from '../../common/odin-loading-indicator.component';
import { forceAssert } from '../../../common/common-functions';

interface BadgeTooltipProps {
  id: number;
  name: string;
  viewId: string;
}

export default function BadgeTooltip({ id, name, viewId }: BadgeTooltipProps) {
  const [scrollElement, setScrollElement] = useState<Element>();
  const [options, setOptions] = useState<OdinDataRetrievalOptions>();
  const subscriberRef = useRef<OdinDataSender<JsonDataWrapper>>();

  const {
    state: { user: { currentSchema } = {} },
  } = useUser();

  const moduleIdFilter = useMemo(() => {
    return { moduleId: { value: [String(id)], comparison: 'contains' } };
  }, [id]);

  const { data, isLoading, isFetching } = useGetMyActivitiesQuery(
    {
      filters: moduleIdFilter,
      page: options?.page ?? 1,
      pageSize: options?.pageSize ?? 50,
    },
    {
      skip: !id || id < 1,
    }
  );

  useEffect(() => {
    if (data && !isFetching) {
      if (data.length > 0 && data?.length > 0) {
        subscriberRef.current?.sendData({ data: forceAssert<JsonData>(data), requestId: options?.requestId });
      } else {
        subscriberRef.current?.sendData();
      }
    }
  }, [data, isFetching, options]);

  const dataRetriever: OdinDataRetrieval = useMemo(() => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        subscriberRef.current = subscriber;
        setOptions(options);
      },
    };
  }, []);

  const onScrollElementCreated = (element: HTMLDivElement) => {
    setScrollElement(element);
  };

  const renderItems = useCallback((data: JsonData): ReactNode | ReactNode[] => {
    return (
      <div className="flex flex-col gap-1">
        {data.map((item) => {
          return (
            <Link
              key={item.id as string}
              to={`/${currentSchema}/modules/${viewId}/forms/${item.formId}/records/${item.id}`}
              className="pb-0.5 pt-0.5 font-normal text-primary-2 underline hover:text-primary-3"
            >
              {`${item.docNo} - ${item.description}`}
            </Link>
          );
        })}
      </div>
    );
  }, []);

  return isLoading ? (
    <div className="mx-auto flex h-52 w-96 flex-col items-center justify-center">
      <Loader />
    </div>
  ) : (
    <div className="flex flex-col">
      <div className="mb-2 flex w-96 font-bold">{name}</div>
      <div className="text-sm" ref={(element) => element && onScrollElementCreated(element)}>
        <ContinuousScroller
          dataRetriever={dataRetriever}
          scrollingElement={scrollElement}
          dataLoadIndicator={<OdinLoadingIndicator />}
          scrollerStyles={{ height: '208px' }}
        >
          {renderItems}
        </ContinuousScroller>
      </div>
    </div>
  );
}
