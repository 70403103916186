import {
  DropDownResult,
  DynamicFieldComponentProps,
  DynamicFieldDataProps,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  OdinDataSender,
  TwinColumnSelect,
} from '@myosh/odin-components';
import React, { useMemo } from 'react';
import { LookupTypeFieldProps } from '../../../@types/forms';
import { Control } from 'react-hook-form';
import useSupportTwinColumnSelectFunctionalities from './use-support-twincolumn-select-functionalities';

export interface TwinColumnSelectFieldProps
  extends DynamicFieldComponentProps<Array<DropDownResult>>,
    DynamicFieldDataProps,
    LookupTypeFieldProps {
  control: Control;
  formId: number;
  parentFieldId: string;
  parentLabel: string;
  subType?: string;
}

const TEXT_FIELD = 'text';
const VALUE_FIELD = 'value';

const TwinColumnSelectField = ({
  id,
  control,
  formId,
  lookupType,
  lookupKeyId,
  lookupHierarchyId,
  readOnly,
  ...rest
}: TwinColumnSelectFieldProps) => {
  const { setOptions, subscriberRef, dataRequestedRef, twinColumnRef } = useSupportTwinColumnSelectFunctionalities(
    TEXT_FIELD,
    id,
    control,
    lookupType,
    lookupKeyId,
    lookupHierarchyId,
    formId
  );

  const getOptionsData = useMemo(() => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        dataRequestedRef.current = true;
        subscriberRef.current = subscriber;
        setOptions(options);
      },
    };
  }, []);

  return (
    <TwinColumnSelect
      {...rest}
      ref={twinColumnRef}
      id={id}
      textField={TEXT_FIELD}
      valueField={VALUE_FIELD}
      data={getOptionsData}
      readOnly={readOnly}
    />
  );
};

export default TwinColumnSelectField;
