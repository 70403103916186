import React from 'react';
import { Button, DynamicFieldComponentProps, ErrorLabel, FieldLabel, RequiredIndicator } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';

const NotificationTargetField = ({
  error,
  value,
  label,
  readOnly,
  required,
}: DynamicFieldComponentProps<Array<string>>) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      {label && (
        <div className="flex">
          <FieldLabel label={label} />
          <RequiredIndicator readOnly={readOnly} required={required} />
        </div>
      )}
      <div className="flex flex-row justify-end">
        <div className="flex flex-wrap flex-1 odin-text-sm">{value}</div>
        <Button type="text" variant="alternative">
          {String(t('add'))}
        </Button>
      </div>
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </div>
  );
};

export default NotificationTargetField;
