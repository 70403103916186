import { DropDownResult } from '@myosh/odin-components';
import { CompetenciesItem, CompetencyFormFieldValues, CompetencyPatch } from '../../../../@types/competencies';
import { HierarchyDropdownValue } from '../../../../@types/hierarchy-fields';
import { forceAssert } from '../../../../common/common-functions';
import { omit } from 'lodash';

function convertHierarchies(dataValue: Record<string, HierarchyDropdownValue>) {
  const hierarchyFields = Object.keys(dataValue)
    .map((hierarchyKey) => {
      const hierarchy = dataValue[hierarchyKey];
      return {
        id: hierarchy?.id,
        hierarchyTypeId: hierarchy.hierarchyTypeId,
      };
    })
    .filter((value) => value?.id);

  return hierarchyFields;
}

export const transformCompetencyData = (data: CompetencyFormFieldValues) => {
  const { renewalInterval, trainingTimeInterval, hierarchies, group, testMethod, status, ...rest } = data;

  const multiplier = renewalInterval?.multiplier &&
    renewalInterval.multiplier !== '' && {
      multiplier: Number(renewalInterval.multiplier),
    };
  const interval = renewalInterval?.interval && { interval: renewalInterval.interval.value };
  const groupValue = group && { group: { id: group.value ?? 0 } };
  const trainingTimeUnit = trainingTimeInterval?.trainingTimeUnit && {
    trainingTimeUnit: trainingTimeInterval.trainingTimeUnit.value,
  };
  const trainingTimeValue = trainingTimeInterval?.trainingTimeValue &&
    trainingTimeInterval.trainingTimeValue !== '' && {
      trainingTimeValue: Number(trainingTimeInterval.trainingTimeValue),
    };

  const formatedRestParams = omit(rest, ['trainingTime', 'original']);
  const forceAssertedStatus = forceAssert<{
    text: string;
    value: string;
  }>(status);
  const statusValue = status && { status: forceAssertedStatus.value };
  const transformedObject: CompetencyPatch = {
    ...forceAssert<CompetenciesItem>(formatedRestParams),
    hierarchies: hierarchies && convertHierarchies(forceAssert(hierarchies)),
    testMethod: (testMethod as unknown as DropDownResult)?.value as string,
    ignoreHierarchyAccess: hierarchies?.ignoreHierarchyAccess?.checked,
    ...groupValue,
    ...multiplier,
    ...interval,
    ...trainingTimeUnit,
    ...trainingTimeValue,
    ...statusValue,
  };

  return transformedObject;
};
