export enum ModuleFields {
  id = 'id',
  caption = 'caption',
  viewId = 'viewId',
  type = 'type',
  icon = 'icon',
  sourceUrl = 'sourceUrl',
  position = 'position',
  removed = 'removed',
  forms = 'forms',
  roles = 'roles',
  hidden = 'hidden',
  displayInMobile = 'displayInMobile',
  target = 'target',
  showAllFormsFirst = 'showAllFormsFirst',
}
