import {
  DataGrid,
  DataGridColumnSettings,
  DataGridSettings,
  JsonData,
  ModalDialog,
  OdinDataRetrieval,
  TabView,
  TabViewPanel,
  TextArea,
} from '@myosh/odin-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import i18next from '../../i18n';
import { forceAssert } from '../../common/common-functions';
import { useTranslation } from 'react-i18next';
import { AuditLogData, AuditLogDataItem } from '../../@types/audit';
import { GroupAuditLogData } from '../../@types/groups';

interface VersionLogProps {
  auditGridData: OdinDataRetrieval;
  dataGridSettings: DataGridSettings;
  recordId?: string;
  onRowSelectionChange: (selectedRow: AuditLogData | GroupAuditLogData) => Promise<Array<AuditLogDataItem>>;
}

// the 'detail' grid which shows data about a selected row in the audit/version data grid
const columns: Array<DataGridColumnSettings> = [
  {
    id: 108,
    title: i18next.t('field'),
    field: 'field',
    visible: true,
    isIdField: true,
  },
  {
    id: 109,
    title: i18next.t('old'),
    field: 'oldValue',
    visible: true,
  },
  {
    id: 110,
    title: i18next.t('new'),
    field: 'newValue',
    visible: true,
  },
];

const VersionLogs = ({ auditGridData, dataGridSettings, onRowSelectionChange }: VersionLogProps) => {
  const { t } = useTranslation();

  const [detailState, setDetailState] = useState<Array<AuditLogDataItem>>([]);
  const [detailsModalData, setDetailsModalData] = useState<Array<AuditLogDataItem>>([]);
  const [rowData, setRowData] = useState<JsonData>([]);
  const [detailsModalVisible, setDetailsModalVisible] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const onRowSelected = async (_gridId: string, selectedRows: JsonData) => {
    const detailState = await onRowSelectionChange(forceAssert<AuditLogData | GroupAuditLogData>(selectedRows[0]));
    setDetailState(detailState);
  };

  const onDetailsSelected = (_gridId: string, selectedRows: JsonData) => {
    setDetailsModalVisible(true);
    setDetailsModalData(detailState);
    setRowData(selectedRows);
  };

  //temporary callback that is neccesary until a fix is done in the odin-copmonents
  const tempCallback = useCallback(onDetailsSelected, [detailState]);

  useEffect(() => {
    if (detailsModalData && detailsModalData.length > 0 && rowData && rowData.length > 0) {
      const foundElement = detailsModalData.find((el) => el.field === rowData[0].field);

      if (foundElement) {
        const activeIndex = detailsModalData.indexOf(foundElement);
        setActiveIndex(activeIndex);
      }
    }
  }, [rowData, detailsModalData]);

  const detailGridSettings: DataGridSettings = useMemo(() => {
    return {
      columns: columns,
      autoSizeColumns: true,
      fullHeight: true,
    };
  }, []);

  return (
    <>
      <div className="mb-4 shadow-lg">
        <DataGrid
          data={auditGridData}
          gridSettings={dataGridSettings}
          onRowSelectionChange={onRowSelected}
          showSettings={false}
        />
      </div>
      <div className="shadow-lg">
        <DataGrid
          data={forceAssert<JsonData>(detailState)}
          gridSettings={detailGridSettings}
          showSettings={false}
          onRowSelectionChange={tempCallback}
        />
      </div>
      <ModalDialog
        header={t('details')}
        visible={detailsModalVisible}
        shouldCheckZIndex={true}
        minDialogWidth={750}
        hidden={() => setDetailsModalVisible(false)}
        renderChildrenLazily={true}
      >
        <TabView activeIndex={activeIndex}>
          {detailsModalData &&
            detailsModalData.map((el) => {
              return (
                <TabViewPanel header={el.field} key={el.field}>
                  <TextArea name="oldValueTextArea" readOnly={true} rows={10} value={el.oldValue} label={t('old')} />
                  <TextArea name="newValueTextArea" readOnly={true} rows={10} value={el.newValue} label={t('new')} />
                </TabViewPanel>
              );
            })}
        </TabView>
      </ModalDialog>
    </>
  );
};

export default VersionLogs;
