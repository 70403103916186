import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { DropDownResult, DynamicFormRequiredType, HfRadioGroup } from '@myosh/odin-components';
import {
  groupRecordsBy,
  selectionDataSettings,
  stateChangeSummary,
} from '../../../record-notification/notifications-utils';
import { useFormSettingsQuery } from '../../../../redux/services/api';
import { NotificationTypeFieldProps, getTriggerFieldsForType } from './notification-properties-utils';
import HierarchyNotificationField from './hierarchy-notification-field.component';
import WeekdaysTimeField from './weekdays-time-field';
import TriggerWorkflowSteps from './trigger-workflow-steps';
import TriggerDateField from '../trigger-type-field/trigger-date-field';
import HfDropDown from '../../custom-drop-downs/hf-drop-down.component';

const NotificationSummaryField = ({ control, value, readOnly }: NotificationTypeFieldProps) => {
  const { t } = useTranslation();
  const deliveryMethod = { text: t('email'), value: 'EMAIL' };

  const { data: formSettingsData } = useFormSettingsQuery(value?.moduleForm?.id ?? 0, {
    skip: value?.moduleForm?.id === undefined,
  });

  const groupBy: DropDownResult = useWatch({
    control,
    name: 'recordGroupingType',
    exact: true,
  });

  const triggerType: DropDownResult = useWatch({
    control,
    name: 'stateChange',
    exact: true,
  });

  return (
    <div className="flex flex-col gap-y-0.5">
      <HfRadioGroup
        control={control}
        name="notificationDelivery"
        id="notificationDelivery"
        textField="text"
        valueField="value"
        data={[deliveryMethod]}
        orientation="HORIZONTAL"
        label={t('delivery-method')}
        readOnly={true}
      />
      <HfRadioGroup
        control={control}
        name="stateChange"
        id="stateChange"
        textField="text"
        valueField="value"
        label={t('trigger')}
        data={stateChangeSummary}
        orientation="VERTICAL"
        readOnly={readOnly}
        required={DynamicFormRequiredType.True}
      />
      <WeekdaysTimeField control={control} />
      {triggerType?.value === 'WORKFLOW_PROGRESS' && (
        <TriggerWorkflowSteps
          control={control}
          id="notificationPropertiesDTO"
          name="notificationPropertiesDTO"
          value={value}
          readOnly={readOnly}
        />
      )}
      {triggerType?.value === 'DATE_FIELD' && (
        <TriggerDateField
          value={value}
          control={control}
          showSendOnce={false}
          id="notificationPropertiesDTO"
          name="notificationPropertiesDTO"
          readOnly={readOnly}
        />
      )}

      {/**GROUP RECORDS BY */}
      <HfRadioGroup
        control={control}
        name="recordGroupingType"
        id="recordGroupingType"
        textField="text"
        valueField="value"
        label={t('group-records-by')}
        data={groupRecordsBy}
        orientation="HORIZONTAL"
        readOnly={readOnly}
        required={DynamicFormRequiredType.True}
      />
      <div>
        {groupBy?.value === 'HIERARCHY' && (
          <HierarchyNotificationField control={control} value={value} readOnly={readOnly} />
        )}
        {groupBy?.value === 'PERSONFIELD' && (
          <HfDropDown
            selectorStyles="text-sm"
            label={t('person-field')}
            id={value?.moduleForm.id}
            shouldUseCreatePortal={false}
            control={control}
            name="personField"
            readOnly={readOnly}
            required={DynamicFormRequiredType.True}
            data={getTriggerFieldsForType(formSettingsData, ['PERSONFIELD', 'MULTISELECTPERSONFIELD', 'READERS'])}
            {...selectionDataSettings}
          />
        )}
      </div>
    </div>
  );
};

export default NotificationSummaryField;
