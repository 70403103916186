import React from 'react';
import {
  DropDownNoSelection,
  DropDownResult,
  DynamicFieldComponentProps,
  Input,
  JsonData,
  isDropDownNoSelection,
  isDropDownResult,
  MakeRequired,
} from '@myosh/odin-components';
import { forceAssert, intNumberPattern } from '../../../common/common-functions';
import OdinDropDown from '../custom-drop-downs/odin-drop-down.component';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';

// The Interval field specific blank value
const deselectValue: DropDownResult = {
  value: '',
  text: '',
};

// 'interval' and 'multiplier' specify the keys to which the values of the corresponding fields are mapped
type IntervalFiledNames = {
  interval: string;
  multiplier: string;
};

export interface IntervalFieldProps extends MakeRequired<DynamicFieldComponentProps<Record<string, unknown>>, 'name'> {
  intervalOptions: JsonData;
  fieldNames: IntervalFiledNames;
  fieldError?: FieldError;
}

const IntervalField = ({
  name,
  value = {},
  label,
  fieldError,
  readOnly,
  intervalOptions,
  fieldNames,
  onChange,
}: IntervalFieldProps) => {
  const { t } = useTranslation();

  const handleMultiplierChange = (multiplier?: string) => {
    if (onChange) {
      value[fieldNames.multiplier] = multiplier;
      onChange(value);
    }
  };

  const handleIntervalChange = (multiplierValue?: DropDownResult | Array<DropDownResult> | DropDownNoSelection) => {
    const fieldValue = !(multiplierValue instanceof Array) ? multiplierValue : multiplierValue[0];
    if (onChange && (isDropDownResult(fieldValue) || isDropDownNoSelection(fieldValue))) {
      value[fieldNames.interval] = isDropDownResult(fieldValue) ? fieldValue : deselectValue;
      onChange(value);
    }
  };

  const isUnitError = fieldError?.type?.includes('interval-unit') ? fieldError.message : '';
  const isValueError = fieldError?.type?.includes('interval-value') ? fieldError.message : '';

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className={'mb-3 grow'}>
        <Input
          name={name}
          label={label}
          value={value[fieldNames.multiplier] as string}
          error={isValueError}
          readOnly={readOnly}
          onChange={handleMultiplierChange}
          type="number"
          min={1}
          validations={[{ pattern: intNumberPattern, errorLabel: t('integer-validation-message') }]}
        />
      </div>
      <div className={'mb-3 mt-[20px] grow'}>
        <OdinDropDown
          key={`dd_interval`}
          error={isUnitError}
          textField="text"
          valueField="value"
          className="!h-[30px]"
          data={forceAssert<JsonData>(intervalOptions)}
          value={value[fieldNames.interval] as DropDownResult}
          readOnly={readOnly}
          onChange={handleIntervalChange}
          deselectValue={deselectValue}
        />
      </div>
    </div>
  );
};

export default IntervalField;
