import React from 'react';
import { DataGridDataCell, JsonDataItem } from '@myosh/odin-components';
import { CombinedFieldType } from '../../../../@types/forms';
import { formatDisplayValue } from '../conflicts-modal-util';
import RecordlinkTableCell from './recordlink-table-cell.component';
import QuestionnaireTableCell from './questionnaire-table-cell.component';
import { QuestionnaireConfigProps, QuestionnaireFieldValue } from '../../../../@types/questionnaire';

interface ConflictsTableCellProps {
  value: unknown;
  type: CombinedFieldType;
  customFieldType?: string;
  width: number;
  dynamicProperties?: JsonDataItem;
  recordId?: string;
}

const ConflictsTableCell = ({
  value,
  type,
  customFieldType,
  dynamicProperties,
  recordId,
  width,
}: ConflictsTableCellProps) => {
  if ('RECORDLINK' === type) {
    const recordIds = Array.isArray(value) ? Array.from(value) : [];
    return <RecordlinkTableCell recordIds={recordIds} width={width} />;
  } else if (('TEXTUAL_QUESTIONNAIRE' === type || 'NUMERIC_QUESTIONNAIRE' === type) && dynamicProperties) {
    const _value = (value as QuestionnaireFieldValue).value;
    const configuration = dynamicProperties.configuration as QuestionnaireConfigProps;
    return <QuestionnaireTableCell value={_value} configuration={configuration} recordId={recordId} />;
  } else {
    const displayValue = formatDisplayValue(value, type, customFieldType, dynamicProperties);
    return <DataGridDataCell value={displayValue} width={width} />;
  }
};

export default ConflictsTableCell;
