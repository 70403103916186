import React, { useEffect, useState } from 'react';
import { JsonDataItem } from '@myosh/odin-components';
import { forceAssert } from '../../../../common/common-functions';
import useFetchRecords from '../../../../hooks/use-fetch-records';
import Record, { RecordSaveState } from '../../../record/record.component';
import { FormLoading } from '../../../form/form-loading.component';
import RecordPreviewPanel from './record-preview-panel';

interface LinkedRecordPreviewProps {
  recordId: string;
  onClose: () => void;
}

export default function LinkedRecordPreview({ recordId, onClose }: LinkedRecordPreviewProps) {
  const { records, isLoading } = useFetchRecords(recordId, true);
  const [record, setRecord] = useState<JsonDataItem>();

  useEffect(() => {
    if (records.length === 1) {
      setRecord(forceAssert<JsonDataItem>(records[0]));
    }
  }, [records]);

  return (
    <RecordPreviewPanel>
      {isLoading ? (
        <FormLoading />
      ) : (
        <Record
          recordId={recordId}
          formId={record?.formId as number}
          onClose={onClose}
          recordState={RecordSaveState.Update}
          title={record ? `${record['formName']} ${record['docNo']} - ${record['status']}` : ''}
          isPreview={true}
        />
      )}
    </RecordPreviewPanel>
  );
}
