import React, { useRef } from 'react';
import { Button, DataGrid, DynamicFieldComponentProps, FieldLabel, RequiredIndicator } from '@myosh/odin-components';
import { DataGridColumnSettings } from '@myosh/odin-components';
import { DataGridSettings } from '@myosh/odin-components';
import { DataGridRef } from '@myosh/odin-components';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useGetRecordByIdQuery } from '../../../redux/services/record';

export interface TargerRecordProps extends DynamicFieldComponentProps<string> {
  formId?: number;
  gridColumns?: Array<DataGridColumnSettings>;
  showSelectButton?: boolean;
}

const TargetRecord = ({
  value,
  formId,
  label,
  required,
  gridColumns = [],
  showSelectButton,
  error,
  readOnly,
}: TargerRecordProps) => {
  const { t } = useTranslation();

  const gridRef = useRef<DataGridRef>();

  const { data } = useGetRecordByIdQuery(
    { id: value ?? 0, keyTypeId: true, cache: true },
    { skip: value === undefined }
  );

  const onDataGridRefCreated = (dataGridRef: DataGridRef) => {
    gridRef.current = dataGridRef;
  };

  const gridSettings: DataGridSettings = {
    fullHeight: true,
    columns: [
      ...gridColumns,
      {
        id: 107,
        title: t('id'),
        field: 'id',
        visible: false,
        isIdField: true,
      },
    ],
  };

  const buttonsContainerStyles = cx('my-2 flex flex-row mx-1 gap-2', {
    'border border-error': error && !readOnly,
  });

  return (
    <>
      {label && (
        <div className="flex">
          <FieldLabel label={label} />
          <RequiredIndicator readOnly={readOnly} required={required} />
        </div>
      )}
      <div className={buttonsContainerStyles}>
        {showSelectButton && (
          <Button variant="alternative" disabled={!formId} classNames="bg-gray-5">
            {t('select')}
          </Button>
        )}
      </div>
      <div className="mt-2">
        {data && (
          <DataGrid
            ref={(gridRef) => gridRef && onDataGridRefCreated(gridRef)}
            data={[data.flat]}
            gridSettings={gridSettings}
            showSettings={false}
          />
        )}
      </div>
    </>
  );
};

export default TargetRecord;
