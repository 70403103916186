import React from 'react';
import { DynamicFormRequiredType, HfCheckboxGroup } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import { selectionDataSettings } from '../../../record-notification/notifications-utils';
import { NotificationTypeFieldProps } from './notification-properties-utils';
import { dayValues } from '../../schedule-rules-field/scheduler-rules-utils';
import SendAfterTimezoneComponent from './send-after-timezone.component';

const WeekdaysTimeField = ({ control, readOnly }: NotificationTypeFieldProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-y-2">
      <HfCheckboxGroup
        control={control}
        data={dayValues}
        name="weekDays"
        id="weekDays"
        label={t('what-days-should-send')}
        className="flex-row flex-wrap"
        readOnly={readOnly}
        required={DynamicFormRequiredType.True}
        {...selectionDataSettings}
      />
      <SendAfterTimezoneComponent control={control} readOnly={readOnly} />
    </div>
  );
};

export default WeekdaysTimeField;
