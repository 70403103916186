import React, { Ref, forwardRef, useEffect, useImperativeHandle, useRef, useState, useMemo } from 'react';
import { FormTitle } from '../form/form-title.component';
import { HierarchiesPermissions } from '../../common/user.permissions';
import useProfileData from '../../hooks/use-profile-data';
import { getHierarchyFormSettings } from './hierarchy-form-settings';
import { cloneDeep, debounce } from 'lodash';
import { v4 } from 'uuid';
import {
  DataGridRef,
  DynamicFormButtonSetting,
  DynamicForm,
  DynamicFormRef,
  JsonDataItem,
  ModalDialogRef,
  ModalDialog,
  OdinDataRetrieval,
  OdinDataSender,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
} from '@myosh/odin-components';
import { FormLoading } from '../form/form-loading.component';
import { Subject, takeUntil } from 'rxjs';
import { getDataGridReference } from '../../services/data-grid.service';
import { ExtendedDynamicFormSettings, forceAssert } from '../../common/common-functions';
import { useHierarchyTypesQuery } from '../../redux/services/hierarchy';
import { saveModalButtons } from '../../common/common-administration-utils';
import i18next from '../../i18n';
import { useTranslation } from 'react-i18next';
import useDynamicFormNotifier from '../../hooks/use-dynamic-form-notifier';
import { getHierarchyFieldOptions } from './hierarchy-utils';

export interface HierarchyDetailsProps {
  hierarchyId: number;
  title: string;
  editable?: boolean;
  onClose: (id: string) => void;
  onCloseActiveRecordConfirmationModalRef?: ModalDialogRef | null;
}

interface HierarchyDetailsRef {
  submitForm?: () => void;
}

const saveButton: DynamicFormButtonSetting = {
  name: 'save',
  text: i18next.t('save'),
  htmlType: 'submit',
  type: 'primary',
  variant: 'alternative',
};

const HierarchyDetails = (props: HierarchyDetailsProps, ref: Ref<HierarchyDetailsRef>) => {
  const { hierarchyId, title, editable, onClose, onCloseActiveRecordConfirmationModalRef } = props;

  const [hierarchy, setHierarchy] = useState<Record<string, unknown>>();
  const [readOnly, setReadOnly] = useState<boolean>(Boolean(hierarchyId) != editable);
  const [formSettings, setFormSettings] = useState<ExtendedDynamicFormSettings>();

  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const saveModalReference = useRef<ModalDialogRef>(null);
  const dataGridRef = useRef<DataGridRef>();
  const destroySubject = useRef(new Subject<void>());
  const dynamicFormId = useRef(v4());

  const { profileData: { user: userData } = {} } = useProfileData();
  const { t } = useTranslation();

  const { data, isFetching } = useHierarchyTypesQuery({});

  const { notifyDirty } = useDynamicFormNotifier(dynamicFormId.current);

  const hasEditAccess = useMemo(() => {
    return (
      userData?.superUser ||
      (userData?.adminAccess &&
        (userData?.adminAccess.includes(HierarchiesPermissions.HIERARCHIES_MODIFY) ||
          userData?.adminAccess.includes(HierarchiesPermissions.HIERARCHIES_CREATE)))
    );
  }, [userData]);

  useEffect(() => {
    if (hasEditAccess) {
      setReadOnly(false);
    }
  }, [hasEditAccess]);

  useEffect(() => {
    if (data && !isFetching) {
      const currentHierarchyType = data.find((d) => d.id === hierarchyId);
      const hierarchyData = cloneDeep(currentHierarchyType);
      if (hierarchyData) {
        const transformedData: Record<string, unknown> = {};
        for (const [key, value] of Object.entries(hierarchyData)) {
          switch (key) {
            case 'archived':
            case 'dependsOnParent':
            case 'external':
            case 'mandatoryForNewForms':
            case 'mandatoryForUserRecords':
              transformedData[key] = {
                value: value,
                text: '',
              };
              break;
            default:
              transformedData[key] = value;
              break;
          }
        }
        setHierarchy(transformedData);
      }
    }
  }, [data, isFetching, hasEditAccess]);

  useEffect(() => {
    setFormSettings(getHierarchyFormSettings(readOnly, hierarchyId));
  }, [readOnly]);

  useEffect(() => {
    onCloseActiveRecordConfirmationModalRef
      ?.subscribeToDialogResult()
      ?.pipe(takeUntil(destroySubject.current))
      .subscribe((result) => {
        if (result.buttonName === 'save' && dynamicFormReference.current?.formIsDirty()) {
          dynamicFormReference.current?.submitForm(true);
        } else if (result.buttonName === 'discard') {
          onClose(dynamicFormId.current);
        }
      });

    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((gridRef) => {
        if (gridRef) {
          dataGridRef.current = gridRef;
        }
      });

    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    submitForm: dynamicFormReference.current?.submitForm,
  }));

  const onFormSubmit = () => {};

  const onFieldChanged = debounce(() => notifyDirty(dynamicFormReference.current), 500);

  const dataRetrieval: OdinDataRetrieval = {
    getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
      if ('COMBOBOX' === options?.fieldType || 'OPTIONGROUP' === options?.fieldType) {
        await getHierarchyFieldOptions(subscriber, options);
      } else {
        subscriber.sendData();
      }
    },
  };

  return (
    <>
      <div className="flex flex-shrink-0 flex-col">
        <div className="flex">
          <FormTitle title={title} />
        </div>
      </div>
      <div className="admin-form-default">
        {!hierarchy && <FormLoading />}
        {hierarchy && formSettings && (
          <DynamicForm
            ref={dynamicFormReference}
            dynamicFormId={dynamicFormId.current}
            data={forceAssert<JsonDataItem>(hierarchy)}
            dataRetrieval={dataRetrieval}
            settings={formSettings}
            buttons={[saveButton]}
            buttonsLocation={0}
            readOnly={readOnly}
            buttonsPosition={1}
            onSubmit={onFormSubmit}
            onFieldChanged={onFieldChanged}
          />
        )}
      </div>
      <ModalDialog ref={saveModalReference} header={t('save-unsaved-changes')} buttons={saveModalButtons}>
        <div>{t('save-message')}</div>
      </ModalDialog>
    </>
  );
};
export default forwardRef(HierarchyDetails);
