import React from 'react';
import { Signal } from '@preact/signals-react';
import { Control } from 'react-hook-form';
import DependentDropDown, { DependentDropDownType } from './dependent-drop-down.component';
import { DropDownProps } from '@myosh/odin-components/dist/types/components/fields/drop-down/drop-down.component';
import { DynamicFormParentFieldValue } from '@myosh/odin-components/dist/types/components/dynamic-form/dynamic-form.interfaces';
import { DataSearchLocation, DropDown, OdinController } from '@myosh/odin-components';

interface HfDropDownProps extends DropDownProps {
  control: Control;
  name: string;
  parentFieldId?: string;
  parentLabel?: string;
  parentValue?: Signal<Array<DynamicFormParentFieldValue>>;
}

export default function HfDropDown(props: HfDropDownProps) {
  const {
    control,
    name,
    parentFieldId,
    parentLabel,
    parentValue,
    id,
    textField,
    valueField,
    label,
    value,
    labelInsideDropDown = false,
    selectorStyles,
    allowClear = true,
    allowSearch = true,
    setDefaultValue = false,
    preloadData = false,
    placeholder,
    filterLocation = DataSearchLocation.Local,
    actionItems,
    onActionItemChanged,
    className = 'text-sm',
    dropDownIconSize,
    data,
    readOnly,
    required,
    deselectValue,
    onDoubleClick,
    onChange,
    fieldValueConverter,
    disabled,
    showAddNewOption,
    shouldUseCreatePortal = false,
    labelStyles,
  } = props;

  return (
    <OdinController
      name={name}
      defaultValue={value}
      control={control}
      onChange={onChange}
      fieldValueConverter={fieldValueConverter}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return !parentFieldId || !parentValue ? (
          <DropDown
            id={id || 0}
            textField={textField}
            valueField={valueField}
            label={label}
            value={value}
            error={error?.message}
            labelInsideDropDown={labelInsideDropDown}
            allowClear={allowClear}
            allowSearch={allowSearch}
            setDefaultValue={setDefaultValue}
            preloadData={preloadData}
            selectorStyles={selectorStyles}
            data={data}
            placeholder={placeholder}
            filterLocation={filterLocation}
            actionItems={actionItems}
            onActionItemChanged={onActionItemChanged}
            className={className}
            dropDownIconSize={dropDownIconSize}
            onChange={onChange}
            deselectValue={deselectValue}
            readOnly={readOnly}
            required={required}
            onDoubleClick={onDoubleClick}
            disabled={disabled}
            showAddNewOption={showAddNewOption}
            shouldUseCreatePortal={shouldUseCreatePortal}
            labelStyles={labelStyles}
          />
        ) : (
          <DependentDropDown
            parentFieldId={parentFieldId}
            parentLabel={parentLabel || ''}
            parentValue={parentValue}
            dropDownType={DependentDropDownType.DropDown}
            id={id || 0}
            textField={textField}
            valueField={valueField}
            label={label}
            value={value}
            error={error?.message}
            labelInsideDropDown={labelInsideDropDown}
            allowClear={allowClear}
            allowSearch={allowSearch}
            setDefaultValue={setDefaultValue}
            preloadData={preloadData}
            data={data}
            placeholder={placeholder}
            filterLocation={filterLocation}
            className={className}
            dropDownIconSize={dropDownIconSize}
            onChange={onChange}
            deselectValue={deselectValue}
            readOnly={readOnly}
            required={required}
            onDoubleClick={onDoubleClick}
            disabled={disabled}
            showAddNewOption={showAddNewOption}
            shouldUseCreatePortal={shouldUseCreatePortal}
          />
        );
      }}
    />
  );
}
