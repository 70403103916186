import { CheckboxChangeEventType } from '@myosh/odin-components';
import { HierarchyDropdownValue, HierarchyType, TransformedFormHierarchyTypes } from '../../../@types/hierarchy-fields';
import { forceAssert } from '../../../common/common-functions';
import { RecordHierarchyFields } from './hierarchy-field-group.component';
import {
  getHierarchyFieldValue,
  getInitialHierarchyFieldsConfigurations,
  mergeIgnoreHierarchyAccess,
} from './hierarchy-functions';
import { isArray } from 'lodash';
import { hierarchyDeselectValue } from './hierarchy-field.component';

export type FieldValues = Record<string, HierarchyDropdownValue | HierarchyDropdownValue[]>;

interface HierarchyFieldsState {
  fieldsValues?: FieldValues;
  dependentHierarchyTypes?: Record<string, string>;
  hierarchyFieldsState?: Array<RecordHierarchyFields>;
}

export enum HierarchiesActionType {
  SetInitialFieldsConfigurations,
  SetInitialFieldsValue,
  UpdateFieldValue,
  SetIgnoreHIerarchyAccess,
}

interface HierarchyReducerAction {
  type: HierarchiesActionType;
  hierachyTypesAll?: Array<HierarchyType>;
  hierarchyTypes?: Array<TransformedFormHierarchyTypes | HierarchyType>;
  value?: Record<string, HierarchyDropdownValue | HierarchyDropdownValue[]>;
  hierarchyField?: RecordHierarchyFields;
  fieldValue?: HierarchyDropdownValue | HierarchyDropdownValue[];
  isUserHierarchy?: boolean;
  isCompetencyHierarchy?: boolean;
  isExternal?: boolean;
  ignoreHierarchyAccess?: CheckboxChangeEventType;
  usesMultiSelectDropdowns?: boolean;
  isTreeHierarchy?: boolean;
}

export const HierarchiesReducer = (
  state: HierarchyFieldsState,
  action: HierarchyReducerAction
): HierarchyFieldsState => {
  const {
    type,
    value = {},
    hierarchyTypes,
    hierachyTypesAll,
    hierarchyField,
    fieldValue,
    isUserHierarchy,
    isCompetencyHierarchy,
    isExternal,
    ignoreHierarchyAccess,
    usesMultiSelectDropdowns,
    isTreeHierarchy = false,
  } = action;
  const { fieldsValues, dependentHierarchyTypes = {}, hierarchyFieldsState } = state;

  switch (type) {
    case HierarchiesActionType.SetInitialFieldsConfigurations:
      if (hierarchyTypes && hierachyTypesAll) {
        const fieldsConfiguration = getInitialHierarchyFieldsConfigurations(
          hierarchyTypes,
          hierachyTypesAll,
          isUserHierarchy,
          isCompetencyHierarchy,
          isExternal,
          isTreeHierarchy
        );
        return { ...state, ...fieldsConfiguration };
      }
      return state;

    case HierarchiesActionType.SetInitialFieldsValue:
      const initialFieldValues: Record<string, HierarchyDropdownValue | HierarchyDropdownValue[]> = {};

      if (hierarchyFieldsState) {
        for (let i = 0; i < hierarchyFieldsState.length; i++) {
          const field = hierarchyFieldsState[i];
          const fieldValue = isArray(value) ? value.map((item) => item[field.caption]) : value[field.caption];
          initialFieldValues[field.caption] = forceAssert(
            getHierarchyFieldValue(value, field, fieldValue, dependentHierarchyTypes, usesMultiSelectDropdowns)
          );
        }
      }
      return {
        ...state,
        fieldsValues: mergeIgnoreHierarchyAccess(initialFieldValues, fieldsValues),
      };

    case HierarchiesActionType.UpdateFieldValue:
      if (hierarchyField && fieldsValues && fieldValue && hierarchyFieldsState) {
        const updatedState: Record<string, HierarchyDropdownValue | HierarchyDropdownValue[]> = {
          ...fieldsValues,
          [hierarchyField.caption]: forceAssert(
            getHierarchyFieldValue(
              fieldsValues,
              hierarchyField,
              fieldValue,
              dependentHierarchyTypes,
              usesMultiSelectDropdowns
            )
          ),
        };

        for (const key in dependentHierarchyTypes) {
          const currentValue = updatedState[key];
          const parentValue = updatedState[dependentHierarchyTypes[key]];
          // Parent related logic is not needed when using multiselect dropdowns
          if (!isArray(currentValue) && !isArray(parentValue) && !usesMultiSelectDropdowns) {
            if (currentValue?.parentId !== parentValue?.id || parentValue?.id === undefined) {
              updatedState[key] = forceAssert({
                ...hierarchyDeselectValue,
                selectedParentId: parentValue?.id,
              });
            }
          }
        }

        return {
          ...state,
          fieldsValues: mergeIgnoreHierarchyAccess(updatedState, fieldsValues),
        };
      }
      return state;

    case HierarchiesActionType.SetIgnoreHIerarchyAccess:
      const updatedFieldValues = {
        ...fieldsValues,
        ignoreHierarchyAccess: ignoreHierarchyAccess,
      };

      return {
        ...state,
        fieldsValues: forceAssert(updatedFieldValues),
      };

    default:
      return state;
  }
};
