import {
  DataGridColumnSettings,
  DataGridSettings,
  JsonDataWrapper,
  ModalDialogRef,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import React, { Ref, forwardRef, useMemo } from 'react';
import { forceAssert } from '../../../common/common-functions';
import i18next from '../../../i18n';
import { useLazyGetGroupAuditLogQuery } from '../../../redux/services/group';
import AuditLogModal from '../../audit-log/audit-log-modal.component';
import { AuditLogData } from '../../../@types/audit';
import { GroupAuditLogData } from '../../../@types/groups';

const columns: Array<DataGridColumnSettings> = [
  {
    id: 108,
    title: i18next.t('version'),
    field: 'version',
    visible: true,
    type: 1,
  },
  {
    id: 109,
    title: i18next.t('date'),
    field: 'date',
    visible: true,
    type: 8,
  },
  {
    id: 110,
    title: i18next.t('user'),
    field: 'person',
    visible: true,
    type: 0,
  },
  {
    id: 107,
    title: i18next.t('id'),
    field: 'id',
    visible: false,
    isIdField: true,
  },
];

interface AuditProps {
  header: string;
  groupId?: number;
}

function GroupAuditView({ header, groupId }: AuditProps, ref: Ref<ModalDialogRef>) {
  const [getGroupAuditLog] = useLazyGetGroupAuditLogQuery();

  const groupAuditGridData = useMemo<OdinDataRetrieval>(() => {
    return {
      getData: (dataResult: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if (options?.fieldType !== 'COMBOBOX' && options?.page === 1) {
          getGroupAuditLog(String(groupId), true)
            .unwrap()
            .then((groupAuditLogData) => {
              dataResult.sendData(forceAssert<JsonDataWrapper>({ data: groupAuditLogData }));
            })
            .catch(() => {
              dataResult.sendData();
            });
        } else {
          dataResult.sendData();
        }
      },
    };
  }, []);

  const onRowSelectionChange = async (selectedRow: AuditLogData | GroupAuditLogData) => {
    const rowData = selectedRow as GroupAuditLogData;

    if (rowData && rowData.historyState && Array.isArray(rowData.historyState)) {
      return [...rowData.historyState];
    }

    return [];
  };

  const groupAuditGridSettings: DataGridSettings = useMemo(() => {
    return {
      columns: columns,
      autoSizeColumns: true,
      fullHeight: true,
    };
  }, []);

  return (
    <AuditLogModal
      dataGridData={groupAuditGridData}
      dataGridSettings={groupAuditGridSettings}
      header={header}
      onRowSelectionChange={onRowSelectionChange}
      ref={ref}
    />
  );
}

export default forwardRef(GroupAuditView);
