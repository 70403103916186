import {
  OdinDataSender,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  JsonDataItem,
  DropDownResult,
} from '@myosh/odin-components';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { getFormWorkflowSteps, getGroups, getUsers } from '../../../common/common-administration-utils';
import { api } from '../../../redux/services/api';
import { getHierarchyKeyFilter } from '../../../common/data-grid-filters';
import { SchedulerRecordPatch, SchedulerRule, SchedulerRuleField } from '../../../@types/scheduler-record';
import { forceAssert } from '../../../common/common-functions';
import { IntervalFieldRuleState } from '../../fields/schedule-rules-field/interval-rule-field';
import { SchedulerRuleFieldState } from '../../fields/schedule-rules-field/field-rule-field';
import { createCaptionObj } from '../../../common/caption-util';

export const getSchedulerOptions = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch<AnyAction>,
  isExternal?: boolean
) => {
  switch (options?.fieldId?.toString()) {
    case 'targetField':
      await getTargetFields(subscriber, options, dispatch);
      break;
    case 'moduleFormCaption':
      await getModuleCaptions(subscriber, options, dispatch);
      break;
    case 'groups':
      await getGroups(subscriber, options, dispatch, isExternal);
      break;
    case 'users':
      await getUsers(subscriber, options, dispatch);
      break;
    case 'targetStatus':
      await getFormWorkflowSteps(subscriber, options, dispatch);
      break;
    default:
      if (options?.customProperties?.entityType === 'HIERARCHY_FIELD') {
        await getHierarchyKeyFilter(subscriber, options, dispatch);
      } else {
        subscriber.sendData();
      }
  }
};

const getTargetFields = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch<AnyAction>
) => {
  const formId = options?.customProperties?.moduleFormId as number;
  const moduleId = options?.customProperties?.moduleId as number;

  if (options?.page === 1 && formId) {
    const dispatchResult = dispatch(api.endpoints.formSettings.initiate(formId));
    const result = await dispatchResult;

    if ('fulfilled' === result.status) {
      const comboboxOptions = result.data.fields
        .flatMap((field) => field.fields ?? field)
        .filter((field) => (field.fieldType as string) === 'PERSONFIELD')
        .map((field) => {
          return {
            value: field.id,
            text: field.caption,
          };
        });
      subscriber.sendData({ data: comboboxOptions, requestId: options.requestId });
    } else {
      subscriber.sendData();
    }

    dispatchResult.unsubscribe();
  } else if (moduleId && options?.page === 1) {
    const dispatchResult = dispatch(api.endpoints.moduleForms.initiate({ moduleId }));
    const result = await dispatchResult;

    if ('fulfilled' === result.status) {
      const forms = result.data.result?.forms
        ?.flatMap((field) => field.fields ?? field)
        .filter((field) => (field.fieldType as string) === 'PERSONFIELD')
        .map((form) => {
          return {
            value: form.id,
            text: form.caption,
          };
        });
      subscriber.sendData({ data: forms, requestId: options.requestId });
    } else {
      subscriber.sendData();
    }
    dispatchResult.unsubscribe();
  } else {
    subscriber.sendData({ data: [], requestId: options.requestId });
  }
};

const getModuleCaptions = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch<AnyAction>
) => {
  const moduleId = options?.customProperties?.moduleId as number;
  if (moduleId && options?.page === 1) {
    const dispatchResult = dispatch(api.endpoints.moduleForms.initiate({ moduleId }));
    const result = await dispatchResult;

    if ('fulfilled' === result.status) {
      let forms = result.data.result?.forms?.map((form) => {
        return {
          value: form.id,
          text: form.caption,
        };
      });

      const filter = (options.fieldFilters?.['text']?.value as string)?.toLowerCase() ?? undefined;

      if (filter && !options?.getAllData && forms) {
        forms = forms.filter((forms) => forms.text.toLowerCase().includes(filter));
      }

      subscriber.sendData({ data: forms, requestId: options.requestId });
    } else {
      subscriber.sendData();
    }
    dispatchResult.unsubscribe();
  } else {
    subscriber.sendData();
  }
};

export function isSchedulerRuleField(rule: SchedulerRule): rule is SchedulerRuleField {
  return (rule as SchedulerRuleField).conditionField !== undefined;
}

export const transformSchedulerPatchData = (data: JsonDataItem) => {
  const patch: SchedulerRecordPatch = { id: Number(data.id) };

  const keys = Object.keys(data);
  for (const dataKey of keys) {
    const dataValue = data[dataKey];
    switch (dataKey) {
      case 'targetStatus':
        patch['targetWorkflowStep'] = { id: Number((dataValue as DropDownResult).value) };
        break;
      case 'users':
      case 'groups':
      case 'targetField':
        patch['userAssignment'] = patch['userAssignment'] || {};
        if (Array.isArray(dataValue)) {
          patch['userAssignment'][dataKey] = forceAssert(
            (dataValue as Array<DropDownResult>).map((item: DropDownResult) => ({
              id: Number(item.value),
            }))
          );
        } else {
          const value = { id: Number((dataValue as DropDownResult).value) ?? null };
          patch['userAssignment'][dataKey] = forceAssert(value);
        }
        break;
      case 'caption':
        patch[dataKey] = createCaptionObj(dataValue as string);
        break;
      case 'rules':
        const transformedRules = (dataValue as Array<IntervalFieldRuleState & SchedulerRuleFieldState>)?.map(
          ({
            id,
            type,
            interval,
            month,
            dayOfMonth,
            dayOfWeek,
            timeZone,
            hourOfDay,
            conditionField,
            firstOccurrence,
            repeatEvery,
            ...rule
          }) => {
            if (type === 'periodic-schedule') {
              return {
                ...rule,
                type,
                interval: interval?.value || interval,
                month: month?.value || month,
                dayOfMonth: dayOfMonth && Number(dayOfMonth),
                dayOfWeek: dayOfWeek?.value || dayOfWeek,
                timeZone: timeZone?.value || timeZone,
                hourOfDay: hourOfDay && Number(hourOfDay),
                ...(!String(id).includes('new') ? { id } : {}),
              };
            } else {
              return {
                ...rule,
                type,
                conditionField: { id: conditionField.value },
                firstOccurrence: firstOccurrence && Number(firstOccurrence),
                repeatEvery: repeatEvery && Number(repeatEvery),
                ...(!String(id).includes('new') ? { id } : {}),
              };
            }
          }
        );
        patch[dataKey] = forceAssert(transformedRules);
        break;
      default:
        patch[dataKey as keyof SchedulerRecordPatch] = forceAssert(dataValue);
        break;
    }
  }
  return patch;
};
