import { IdCaptionValueType, UserHierarchy, UserResult, UserStructure } from '../@types/users';
import { forceAssert, isInstanceOfValueTextType } from './common-functions';
import { UserFields, UserHierarchyField } from './user-config';
import { cloneDeep } from 'lodash';
import {
  isObject,
  JsonData,
  JsonDataItem,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import { api } from '../redux/services/api';
import { occupationApi } from '../redux/services/occupation';
import { getComboboxData } from '../pages/admin/admin-utils';
import { HierarchyDropdownValue } from '../@types/hierarchy-fields';
import { getHierarchyKeyFilter } from './data-grid-filters';
import { getGroups, getUsers } from './common-administration-utils';
import { FieldFilters } from '../@types/common';

function hasOnlySpecificAccess(adminAccessArray: string[], specialAccessRoles: string[]) {
  return adminAccessArray.every((item) => specialAccessRoles.includes(item));
}

// These roles should not give access to administration if no other role is specified
// https://myosh.atlassian.net/browse/MYOSH-7359?focusedCommentId=55044
const specialAccessRoles = ['ONLINE_LEARNING_ENROLMENT_ACCESS', 'ONLINE_LEARNING_FULL_ADMIN_ACCESS'];

export function hasAdminAccess(userResponse?: UserResult) {
  if (userResponse && userResponse.externalUser === false && userResponse.adminAccess) {
    return hasOnlySpecificAccess(userResponse.adminAccess, specialAccessRoles)
      ? false
      : userResponse.adminAccess.length > 0;
  } else if (userResponse && userResponse.superUser) {
    return userResponse.superUser;
  } else {
    return false;
  }
}

export function createUserData(userResponse: UserResult) {
  const modifiedUserResponse: JsonDataItem = cloneDeep(forceAssert<JsonDataItem>(userResponse));
  const keys = Object.keys(modifiedUserResponse);
  for (let i = 0; i < keys.length; i++) {
    const dataKey = keys[i] as keyof UserResult;
    const dataValue = userResponse[dataKey];
    if (isObject(dataValue)) {
      if (Array.isArray(dataValue)) {
        // special case for the user hierarchy
        if (dataKey === UserFields.userHierarchy) {
          const hierarchies: JsonDataItem = {}; // match name to record hierarchies object
          for (let j = 0; j < dataValue.length; j++) {
            const hierarchy = dataValue[j];
            //FIXME: Once the API provides us with the users translation change this code
            const hierarchyItemKey = hierarchy['hierarchyTypeCaption'].translations[0].value;
            const hierarchyItemValue = hierarchy['caption'].translations[0].value;
            hierarchies[hierarchyItemKey] = {
              id: hierarchy.id,
              caption: hierarchyItemValue,
              hierarchyTypeId: hierarchy.hierarchyTypeId,
              parentId: hierarchy?.parentId,
              archived: hierarchy?.archived,
            };
            modifiedUserResponse[hierarchyItemKey] = hierarchyItemValue; // needed for the user data grid
          }
          modifiedUserResponse['hierarchies'] = hierarchies; // needed for the hierarchy
          delete modifiedUserResponse[UserFields.userHierarchy];
        } else if (isArrayOfIdCaptionType(dataValue)) {
          // FIXME: Aida or Goran please review this section since there doesn't appear to be a single property in the
          //  UserResult type that has an array of an object type.
          const responseArrayProp = modifiedUserResponse[dataKey] as JsonData;
          for (let j = 0; j < dataValue.length; j++) {
            responseArrayProp[j] = formatToDropDownValue(dataValue[j]);
          }
        }
      } else if (UserFields.manager === dataKey) {
        //special handling for the manager
        modifiedUserResponse[UserFields.manager] = {
          text: dataValue.fullName,
          value: dataValue.id,
        };
      } else if (isIdCaptionType(dataValue)) {
        modifiedUserResponse[dataKey] = formatToDropDownValue(dataValue);
      }
    } else if ('localeCode' === dataKey) {
      // special handling for the localeId/localeTag
      modifiedUserResponse[UserFields.localeId] = { text: dataValue, value: dataValue };
      modifiedUserResponse[UserFields.localeTag] = { text: dataValue, value: dataValue };
    } else if (UserFields.affiliation === dataKey) {
      // special handling for the affiliation
      modifiedUserResponse[UserFields.affiliation] = { text: dataValue, value: dataValue };
    }
  }

  const data: UserStructure = {
    original: {
      ...userResponse,
    },
    ...modifiedUserResponse,
  };

  return data;
}

const ignoreKeys: string[] = [...Object.values(UserHierarchyField), 'original'];

export function updateOriginalUserData(data: JsonDataItem, originalData: JsonDataItem) {
  const originalDataKeys = Object.keys(originalData);
  const dataKeys = Object.keys(data);
  for (let i = 0, length = dataKeys.length; i < length; i++) {
    const dataKey = dataKeys[i];
    if (!ignoreKeys.includes(dataKey)) {
      const dataValue = data[dataKey];
      if (dataKey === 'hierarchies' && dataValue && isObject(dataValue)) {
        setUserHierarchyValue(dataValue as Record<string, HierarchyDropdownValue>, originalData);
      } else if (originalDataKeys.includes(dataKey)) {
        setUserPropertyValue(data, originalData, dataKey);
      } else if (dataValue) {
        setUserPropertyValue(data, originalData, dataKey);
      }
    }
  }
}

function isIdCaptionType(object: unknown): object is IdCaptionValueType {
  return (
    isObject(object) &&
    (typeof object.id === 'number' || typeof object.id === 'string') &&
    object.hasOwnProperty('caption')
  );
}

function isArrayOfIdCaptionType(object: unknown): object is Array<IdCaptionValueType> {
  return Array.isArray(object) && object.every(isIdCaptionType);
}

function formatToDropDownValue(item: IdCaptionValueType) {
  if (isIdCaptionType(item)) {
    //FIXME: Once the API provides us with the users translation change this code
    return {
      value: item.id,
      text: item.caption.translations?.[0]?.value,
    };
  }
  return item;
}

export const transformUserHierarchies = (userHierarchies?: Array<UserHierarchy>) => {
  const result: Record<string, HierarchyDropdownValue> = {};
  if (userHierarchies) {
    for (const hierarchy of userHierarchies) {
      const key = hierarchy.hierarchyTypeCaption.translations[0].value;
      result[key] = {
        ...hierarchy,
        caption: hierarchy.caption.translations[0].value,
      };
    }
  }

  return result;
};

function setUserPropertyValue(data: JsonDataItem, originalData: JsonDataItem, key: string) {
  const dataValue = data[key];
  if (isObject(dataValue)) {
    if (Array.isArray(dataValue)) {
      const newDataValue = [];
      for (let j = 0; j < dataValue.length; j++) {
        const newDataValueItem: JsonDataItem = {};
        if (isInstanceOfValueTextType(dataValue[j])) {
          newDataValueItem.id = dataValue[j].value;
          newDataValue.push(newDataValueItem);
        }
      }
      originalData[key] = newDataValue;
    } else if (UserFields.localeId === key) {
      // special handling for the localeId/localeTag
      originalData['localeCode'] = dataValue.value;
    } else if (UserFields.affiliation === key) {
      // special handling for the affiliation
      originalData[UserFields.affiliation] = dataValue.text;
    } else {
      if (isInstanceOfValueTextType(dataValue)) {
        originalData[key] = { id: dataValue.value };
      }
    }
  } else {
    originalData[key] = dataValue;
  }
}

function setUserHierarchyValue(dataValue: Record<string, HierarchyDropdownValue>, originalData: JsonDataItem) {
  const userHierarchy = [];
  const hierarchyKeys = Object.keys(dataValue);
  for (let j = 0; j < hierarchyKeys.length; j++) {
    if (dataValue[hierarchyKeys[j]]) {
      const hierarchy = dataValue[hierarchyKeys[j]];
      const hierarchyValue = {
        id: hierarchy.id,
        hierarchyTypeId: hierarchy.hierarchyTypeId,
      };
      userHierarchy.push(hierarchyValue);
    }
  }
  originalData[UserFields.userHierarchy] = userHierarchy;
}

export const getChangedFieldsData = (data: JsonDataItem, dirtyFields: JsonDataItem, id: number) => {
  const modifiedFields: JsonDataItem = { id };
  for (const fieldName in dirtyFields) {
    if (dirtyFields[fieldName] && data.hasOwnProperty(fieldName)) {
      modifiedFields[fieldName] = data[fieldName];
    }
  }
  return modifiedFields;
};

export const getUsersFieldOptionsData = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  isExternal?: boolean
) => {
  switch (options?.fieldId?.toString()) {
    case UserFields.occupation:
    case UserFields.secondaryOccupations:
      await getOccupations(subscriber, options, dispatch);
      break;
    case UserFields.localeId:
      await getLocales(subscriber, options, dispatch);
      break;
    case UserFields.groups:
      await getGroups(subscriber, options, dispatch, isExternal);
      break;
    case UserFields.affiliation:
      await getAffiliations(subscriber, options);
      break;
    case UserFields.manager:
      await getUsers(subscriber, options, dispatch);
      break;
    case UserFields.archived:
    case UserFields.loginRequired:
    case UserFields.externalUser:
    case UserFields.activeExternalUser:
    case UserFields.occasionalExternalUser:
      await getComboboxData(subscriber, options);
      break;
    default:
      if (options?.customProperties?.entityType === 'HIERARCHY_TYPE') {
        await getHierarchyKeyFilter(subscriber, options, dispatch);
      } else {
        subscriber.sendData();
      }
  }
};

const getOccupations = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch
) => {
  if (options.page && options.pageSize) {
    let finalFilter: FieldFilters | undefined = options?.fieldFilters;
    if (options?.fieldFilters?.text) {
      finalFilter = {
        ...finalFilter,
        name: options.fieldFilters.text,
      };
      delete finalFilter.text;
    }
    const dispatchResult = dispatch(
      occupationApi.endpoints.getOccupations.initiate({
        page: options.page,
        pageSize: options.pageSize,
        filters: { ...finalFilter, archived: { value: false, comparison: '=' } },
        excludeAllOccupations: true,
      })
    );
    const result = await dispatchResult;
    if ('fulfilled' === result.status) {
      const comboboxOptions = result.data.map((item) => {
        return {
          value: item.id,
          text: item.caption,
        };
      });
      subscriber.sendData({ data: comboboxOptions, requestId: options.requestId });
    } else {
      subscriber.sendData();
    }
    dispatchResult.unsubscribe();
  }
};

const getLocales = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch
) => {
  if (options.page === 1) {
    const dispatchResult = dispatch(api.endpoints.getLocales.initiate());
    const result = await dispatchResult;
    if ('fulfilled' === result.status) {
      const comboboxOptions = result.data.map((item) => {
        return {
          value: item.languageTag,
          text: item.languageTag,
        };
      });
      subscriber.sendData({ data: comboboxOptions, requestId: options.requestId });
    } else {
      subscriber.sendData();
    }
    dispatchResult.unsubscribe();
  } else {
    subscriber.sendData();
  }
};

const getAffiliations = async (subscriber: OdinDataSender<JsonDataWrapper>, options: OdinDataRetrievalOptions) => {
  if (options.page === 1) {
    const affiliations = [
      {
        id: 'Employee',
        caption: 'Employee',
      },
      {
        id: 'Contractor',
        caption: 'Contractor',
      },
      {
        id: 'Volunteer',
        caption: 'Volunteer',
      },
      {
        id: 'Guest',
        caption: 'Guest',
      },
      {
        id: 'Casual',
        caption: 'Casual',
      },
    ];

    const comboboxOptions = affiliations.map((item) => {
      return {
        value: item.id,
        text: item.caption,
      };
    });

    subscriber.sendData({ data: comboboxOptions, requestId: options.requestId });
  } else {
    subscriber.sendData();
  }
};
