/* eslint-disable prettier/prettier */
import { CheckboxChangeEventType, DynamicFormRequiredType, DynamicFormSettings } from '@myosh/odin-components';
import i18next from '../../i18n';
import { forceAssert } from '../../common/common-functions';
import HfDropDownMultiSelect from '../fields/custom-drop-downs/hf-drop-down-multi-select.component';
import HfDropDown from '../fields/custom-drop-downs/hf-drop-down.component';
import HfNotificationPropsField from '../fields/notification-field/property-section/hf-notification-props-field.component';
import { selectionDataSettings } from './notifications-utils';
import HfNotificationTargetField from '../fields/notification-field/text-add/hf-notification-target-field.component';

export const getNotificationFormSettings = (): DynamicFormSettings => {
  return {
    id: 'notification-form-settings',
    caption: '',
    fields: [
      {
        id: 'notificationDetails',
        fieldName: 'details',
        fieldType: 'GROUP',
        caption: i18next.t('details'),
        position: 1,
        startOpen: true,
        permissions: { read: true },
        fields: [
          {
            id: 'notificationCaption',
            fieldType: 'TEXTFIELD',
            fieldName: 'notificationCaption',
            caption: i18next.t('name'),
            position: 0,
            required: DynamicFormRequiredType.True,
          },
          {
            id: 'notificationType',
            fieldType: 'OPTIONGROUP',
            fieldName: 'notificationType',
            caption: i18next.t('type'),
            position: 1,
            dataSettings: selectionDataSettings,
            required: DynamicFormRequiredType.True,
            dynamicProperties: {
              disabled:true,
              orientation: 'VERTICAL',
            },
              helpMessage: typeHelpMessage()
          },
          {
            id: 'sendTo',
            fieldType: 'CUSTOM',
            customFieldType: 'NOTIFICATIONTARGET',
            fieldName: 'details.sendTo',
           // fieldName: 'sendTo',
            caption: i18next.t('send-to'),
            position: 2,
            required: DynamicFormRequiredType.True,
          },
          {
            id: 'replyTo',
            fieldType: 'CUSTOM',
            customFieldType: 'NOTIFICATIONTARGET',
            fieldName: 'details.replyTo',
           // fieldName: 'replyTo',
            caption: i18next.t('reply-to'),
            position: 3,
            required: DynamicFormRequiredType.True,
          },
          {
            id: 'copyTo',
            fieldType: 'CUSTOM',
            customFieldType: 'NOTIFICATIONTARGET',
           // fieldName: 'copyTo',
            fieldName: 'details.copyTo',
            caption: i18next.t('copy-to'),
            position: 4,
          },
          {
            id: 'bccTo',
            fieldType: 'CUSTOM',
            customFieldType: 'NOTIFICATIONTARGET',
            fieldName: 'details.blindCopyTo',
            //fieldName: 'blindCopyTo',
            caption: i18next.t('blind-copy-to'),
            position: 5,
          },
        ],
      },
      {
        id: 'notificationPropertiesDTO',
        fieldName: '',
        fieldType: 'GROUP',
        caption: i18next.t('notification-properties'),
        position: 2,
        startOpen: true,
        permissions: { read: true },
        fields: [
          //Pass all data into the component
          {
            id: 'notificationTypeProperties',
            fieldType: 'CUSTOM',
            customFieldType: 'NOTIFICATIONTYPE',
            fieldName: 'original',
            caption: i18next.t('notification-type'),
            position: 1,
            dataSettings: selectionDataSettings,
            dynamicProperties: {
              orientation: 'HORIZONTAL',
            },
          },
        ],
      },
      {
        id: 'template',
        fieldName: 'template',
        fieldType: 'GROUP',
        caption: i18next.t('template'),
        position: 3,
        startOpen: false,
        permissions: { read: true },
        fields: [],
      },
    ],
    fieldValueConverters: {
      CHECKBOX: (value) => {
        const convertedValue = forceAssert<CheckboxChangeEventType>(value);
        return convertedValue.checked;
      },
    },
    customFieldComponents: {
      DROPDOWNMULTISELECT: HfDropDownMultiSelect,
      COMBOBOX: HfDropDown,
      NOTIFICATIONTYPE: HfNotificationPropsField,
      NOTIFICATIONTARGET: HfNotificationTargetField,
    },
  };
};

const typeHelpMessage = () => {
  const triggered = `<b>${i18next.t('triggered')}</b>: ${i18next.t('notification-triggered-tooltip')} <br>`
  const timed = `<b>${i18next.t('timed')}</b>: ${i18next.t('notification-timed-tooltip')} <br>`
  const summary = `<b>${i18next.t('summary')}</b>: ${i18next.t('notification-summary-tooltip')} <br>`
  return `${triggered + timed + summary}`;
} 
