import React from 'react';
import { Control } from 'react-hook-form';
import { OdinController } from '@myosh/odin-components';
import RiskLevel, { RiskLevelProps } from './risk-level.component';

interface HfRiskRatingProps extends RiskLevelProps {
  control?: Control;
  name?: string;
}

const HfRiskLevel = ({ control, name, readOnly, label, riskMatrixId }: HfRiskRatingProps) => {
  return (
    <OdinController
      name={name as string}
      control={control}
      render={() => <RiskLevel riskMatrixId={riskMatrixId} label={label} readOnly={readOnly} />}
    />
  );
};

export default HfRiskLevel;
