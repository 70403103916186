import { t } from 'i18next';
import { ImportConfiguration } from './configuration-section';
import { StartImportJobApiProps } from '../../../@types/background-jobs';

interface Payload extends Omit<StartImportJobApiProps, 'id'> {}

export const transformConfigurationData = (data?: ImportConfiguration) => {
  const payload: Payload = {};
  for (const property in data) {
    switch (property) {
      case 'timeZone':
        payload['time_zone'] = data[property].value as string;
        break;
      case 'optionsImportMode':
        payload['options_import_mode'] = data[property].value as number;
        break;
      case 'sendRecordNotifications':
        payload['send_record_notifications'] = data[property] ? String(data[property]?.checked) : undefined;
        break;
    }
  }
  return payload;
};

export const errorhandlingOptions = [
  {
    value: 0,
    text: t('do-not-import-record-warn'),
  },
  { value: 1, text: t('import-record-and-leave-field-blank') },
  {
    value: 2,
    text: t('import-record-and-autocreate-unrecognized-options'),
  },
  {
    value: 3,
    text: t('import-record-and-autocreate-and-archive-unrecognized-options'),
  },
];

export const templateHelpMessage = () => {
  return (
    `${t('import-records-template-tooltip-1')} <br><br>` +
    `${t('import-records-template-tooltip-2')} <br><br>` +
    `${t('import-records-template-tooltip-3')} <br><br>` +
    `${t('import-records-template-tooltip-4')} <br><br>` +
    t('import-records-template-tooltip-5')
  );
};

export const configurationHelpMessage = () => {
  return `<b>${t('error-handling')}</b>: ${t('import-records-configuration-tooltip')}`;
};

export const unrecognisedOptionHelpText = () => {
  return `<p>${t('option-does-not-apply-to-fields')}</p><ul class="list-disc pl-5"><li>${t('competency-fields')}</li><li>${t('hierarchy-fields')}</li><li>${t('person-fields')}</li></ul>`;
};
