import {
  CheckboxChangeEventType,
  DynamicFieldDataProps,
  DynamicFormRequiredType,
  DynamicFormSettings,
} from '@myosh/odin-components';
import { HfHierarchyField } from '../../../fields/hierarchy/hf-hierarchy-field.component';
import i18next from '../../../../i18n';
import * as yup from 'yup';
import { HierarchyType } from '../../../../@types/hierarchy-fields';
import HfInterval from '../../../fields/interval-field/hf-interval-field.component';
import { forceAssert, intNumberPattern } from '../../../../common/common-functions';
import HfDropDownMultiSelect from '../../../fields/custom-drop-downs/hf-drop-down-multi-select.component';
import HfDropDown from '../../../fields/custom-drop-downs/hf-drop-down.component';

const dataSettings: DynamicFieldDataProps = {
  valueField: 'value',
  textField: 'text',
};

export const getCompetencyFormSettings = (
  hierarchyTypes?: HierarchyType[],
  onlineLearning?: boolean,
  ignoreHierarchyAccess?: boolean,
  readOnly?: boolean
): DynamicFormSettings => {
  return {
    id: 0,
    caption: '',
    fields: [
      {
        id: 'hierarchies',
        fieldType: 'CUSTOM',
        customFieldType: 'HIERARCHY',
        caption: i18next.t('hierarchy'),
        fieldName: 'hierarchies',
        required: DynamicFormRequiredType.True,
        position: 0,
        permissions: { read: true, edit: !readOnly },
        dynamicProperties: {
          hierarchyTypes: hierarchyTypes,
          isCompetencyHierarchy: true,
          ignoreHierarchyAccess,
        },
        wrapInGroup: true,
        customGroupTitle: i18next.t('hierarchy'),
        startOpen: true,
        validationFunc: competencyHierarchyFieldValidation(ignoreHierarchyAccess, hierarchyTypes),
      },
      {
        id: 100,
        fieldType: 'GROUP',
        fieldName: 'Details',
        caption: 'Details',
        position: 1,
        startOpen: true,
        permissions: { read: true, edit: !readOnly },
        fields: [
          {
            id: 'name',
            fieldType: 'TEXTFIELD',
            fieldName: 'name',
            caption: i18next.t('competency-name'),
            required: DynamicFormRequiredType.True,
            position: 0,
          },
          {
            id: 'group',
            fieldType: 'COMBOBOX',
            fieldName: `group`,
            caption: i18next.t('competency-group'),
            position: 1,
            dataSettings: dataSettings,
          },
          {
            id: 'providedBy',
            fieldType: 'TEXTFIELD',
            fieldName: 'providedBy',
            caption: i18next.t('provided-by'),
            position: 2,
          },
          {
            id: 'trainingTimeInterval',
            customFieldType: 'INTERVAL',
            fieldType: 'CUSTOM',
            fieldName: 'trainingTimeInterval',
            caption: i18next.t('training-time'),
            required: DynamicFormRequiredType.True,
            position: 3,
            validationFunc: trainingTimeValidation(),
            dynamicProperties: {
              fieldNames: {
                interval: 'trainingTimeUnit',
                multiplier: 'trainingTimeValue',
              },
              intervalOptions: trainingTimeIntervalOptions,
            },
          },
          {
            id: 'costs',
            fieldType: 'INTFIELD',
            fieldName: 'costs',
            caption: i18next.t('costs'),
            position: 4,
            dynamicProperties: {
              validations: [
                {
                  pattern: intNumberPattern,
                  errorLabel: i18next.t('integer-validation-message'),
                },
              ],
            },
          },
          {
            id: 'testMethod',
            fieldType: 'COMBOBOX',
            fieldName: 'testMethod',
            caption: i18next.t('test-method'),
            position: 5,
            dataSettings: dataSettings,
          },
          {
            id: 'proficiency',
            fieldType: 'TEXTFIELD',
            fieldName: 'proficiency',
            caption: i18next.t('proficiency'),
            position: 6,
          },
          {
            id: 'courseCode',
            fieldType: 'TEXTFIELD',
            fieldName: 'courseCode',
            caption: i18next.t('course-code'),
            position: 7,
          },
          {
            id: 'renewalInterval',
            customFieldType: 'INTERVAL',
            fieldType: 'CUSTOM',
            fieldName: 'renewalInterval',
            caption: i18next.t('renew-interval'),
            position: 8,
            required: DynamicFormRequiredType.True,
            validationFunc: renewalIntervalValidation(),
            dynamicProperties: {
              fieldNames: {
                interval: 'interval',
                multiplier: 'multiplier',
              },
              intervalOptions: renewalIntervalOptions,
            },
          },
          {
            id: 'description',
            fieldType: 'RICHTEXTAREA',
            fieldName: 'description',
            caption: i18next.t('description'),
            position: 9,
          },
          {
            id: 'onlineLearning',
            fieldType: 'CHECKBOX',
            fieldName: 'onlineLearning',
            caption: i18next.t('online-learning'),
            position: 10,
            dynamicProperties: { disabled: true },
          },
          {
            id: 'lessons',
            fieldType: 'TEXTAREA',
            fieldName: 'lessons',
            caption: i18next.t('lessons'),
            position: 11,
            hidden: !onlineLearning,
          },
          {
            id: 'status',
            fieldType: 'COMBOBOX',
            fieldName: 'status',
            caption: i18next.t('status'),
            position: 12,
            dataSettings: dataSettings,
            hidden: !onlineLearning,
          },
          {
            id: 'tags',
            fieldType: 'DROPDOWNMULTISELECT',
            fieldName: 'tags',
            caption: i18next.t('tags'),
            position: 13,
            dataSettings: dataSettings,
            hidden: !onlineLearning,
          },
        ],
      },
    ],
    fieldValueConverters: {
      CHECKBOX: (value) => {
        const convertedValue = forceAssert<CheckboxChangeEventType>(value);
        return convertedValue.checked;
      },
    },
    customFieldComponents: {
      HIERARCHY: HfHierarchyField,
      INTERVAL: HfInterval,
      DROPDOWNMULTISELECT: HfDropDownMultiSelect,
      COMBOBOX: HfDropDown,
    },
  };
};

const renewalIntervalOptions = [
  {
    value: 'Days',
    text: i18next.t('days'),
  },
  {
    value: 'Weeks',
    text: i18next.t('weeks'),
  },
  {
    value: 'Months',
    text: i18next.t('months'),
  },
  {
    value: 'Years',
    text: i18next.t('years'),
  },
];

export const trainingTimeIntervalOptions = [
  {
    value: 'MINUTES',
    text: i18next.t('minutes'),
  },
  {
    value: 'HOURS',
    text: i18next.t('hours'),
  },
  {
    value: 'DAYS',
    text: i18next.t('days'),
  },
  {
    value: 'WEEKS',
    text: i18next.t('weeks'),
  },
];

const competencyHierarchyFieldValidation = (ignoreHierarchyAccess?: boolean, hierarchyTypes?: HierarchyType[]) => {
  if (!ignoreHierarchyAccess) {
    const requiredHierarchyTypes = hierarchyTypes?.filter((hierarchy) => !hierarchy.external) ?? [];
    return yup.object(
      requiredHierarchyTypes.reduce(
        (schema, key) => ({
          ...schema,
          [key.caption]: yup.object({
            id: yup.number().required(i18next.t('is-mandatory-hierarchy', { hierarchy: `'${key.caption}'` })),
          }),
        }),
        {}
      )
    );
  }
};

const trainingTimeValidation = () =>
  yup
    .object()
    .shape({
      trainingTimeUnit: yup.object().shape({
        value: yup.string(),
      }),
      trainingTimeValue: yup.string().nullable(),
    })
    .test('validate-field', '', function (value) {
      const { trainingTimeUnit, trainingTimeValue } = value;

      //check if all empty or all filled
      if ((!trainingTimeValue && !trainingTimeUnit.value) || (trainingTimeValue && trainingTimeUnit.value)) {
        return true;
      }

      if (!!trainingTimeUnit.value && !trainingTimeValue) {
        return this.createError({
          message: i18next.t('interval-value-validation-message', 'Please enter a number'),
          type: 'interval-value',
        });
      }

      if (!trainingTimeUnit.value && trainingTimeValue) {
        return this.createError({
          message: i18next.t('interval-unit-validation-message', 'Please select an interval'),
          type: 'interval-unit',
        });
      }
    });

const renewalIntervalValidation = () =>
  yup
    .object()
    .shape({
      interval: yup.object().shape({
        value: yup.string(),
      }),
      multiplier: yup.string().nullable(),
    })
    .test('validate-field', '', function (value) {
      const { interval, multiplier } = value;

      //check if all empty or all filled
      if ((!multiplier && !interval.value) || (multiplier && interval.value)) {
        return true;
      }

      if (!!interval.value && !multiplier) {
        return this.createError({
          message: i18next.t('interval-value-validation-message', 'Please enter a number'),
          type: 'interval-value',
        });
      }

      if (!interval.value && multiplier) {
        return this.createError({
          message: i18next.t('interval-unit-validation-message', 'Please select an interval'),
          type: 'interval-unit',
        });
      }
    });
