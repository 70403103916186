import {
  DataGrid,
  DataGridCellRendererProps,
  DataGridRef,
  DataGridSettings,
  DynamicFormFieldType,
  isDropDownNoSelection,
  JsonDataItem,
  JsonDataWrapper,
  Notification,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  RowTemplateType,
  DataGridSettingsMenuItem,
  DropDownResult,
  useTestTag,
} from '@myosh/odin-components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  dateFormatBasedOnFilter,
  ExtendedDynamicFormWorkflowStep,
  processFieldFilters,
  buildSortedFieldsUrlString,
  buildGlobalHierarchyFiltersUrl,
} from '../../common/common-functions';
import { getDataGridComboboxFilters } from '../../common/data-grid-filters';
import { columnConfigurator, getCurrentlyLoadedAndTotalRecords } from '../../common/dynamic-page-utils';
import useGridSettings from '../../hooks/use-grid-settings';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useFormSettingsQuery, useGetWorkflowStepsQuery } from '../../redux/services/api';
import { useGetRecordCountQuery, useGetRecordsQuery } from '../../redux/services/record';
import { dynamicPageState } from '../../redux/slices/dynamic-page-slice';
import { setDataGridReference } from '../../services/data-grid.service';
import RiskRatingDataCell from '../data-grid-data-cells/risk-rating-data-cell';
import { defaultGridSettings as commonGridSettings } from './default-grid-settings';
import { DataGridLoader } from './data-grid-loader.component';
import useRenderDataGrid from '../../hooks/use-render-data-grid';
import { selectedGlobalHierarchies, selectedMatchingOption } from '../../redux/slices/global-hierarchies';
import ErrorLoadingDataNotification from '../common/error-loading-data-notification';

export interface DefaultFormGridProps {
  menuItems: Array<DataGridSettingsMenuItem>;
  rowTemplate: RowTemplateType;
  selectedForm: DropDownResult;
  moduleId: number;
  onDataLoadCompleted?: () => void;
  onViewChange?: (hasChanged: boolean) => void;
}

function SingleFormGrid({
  menuItems,
  rowTemplate,
  selectedForm,
  moduleId,
  onDataLoadCompleted,
  onViewChange,
}: DefaultFormGridProps) {
  const [gridOptions, setGridOptions] = useState<OdinDataRetrievalOptions>();

  const gridSubscriber = useRef<OdinDataSender<JsonDataWrapper>>();
  const gridRef = useRef<DataGridRef>();

  const { selectedFilters } = useAppSelector(dynamicPageState);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const globalFilters = useAppSelector(selectedGlobalHierarchies);
  const globalFiltersMatchingOption = useAppSelector(selectedMatchingOption);

  const { data: formSettings } = useFormSettingsQuery(Number(selectedForm?.value), {
    skip: selectedForm === undefined || (selectedForm?.value as number) <= 0 || isDropDownNoSelection(selectedForm),
  });

  const defaultGridSettings = useMemo<DataGridSettings>(() => {
    const settings: DataGridSettings = {
      ...commonGridSettings,
      components: {
        ...commonGridSettings.components,
        RISK_RATING_DATA_CELL: {
          // eslint-disable-next-line react/jsx-no-undef
          CellComponent: (props: DataGridCellRendererProps) => {
            const { column, rowData } = props;
            const extendedColumnData = {
              ...column,
              customDataProperties: {
                ...column?.customDataProperties,
                displayRecordListColour: formSettings?.displayRecordListColour,
              },
            };
            return <RiskRatingDataCell rowData={rowData} column={extendedColumnData} />;
          },
        },
      },
    };

    return settings;
  }, [formSettings]);

  const {
    settings: gridSettings,
    error: gridSettingsError,
    loading: gridSettingsLoading,
  } = useGridSettings(
    defaultGridSettings,
    columnConfigurator,
    selectedForm && !isDropDownNoSelection(selectedForm) ? (selectedForm.value as number) : undefined,
    selectedFilters.viewConfig && !isDropDownNoSelection(selectedFilters.viewConfig)
      ? (selectedFilters.viewConfig.value as number)
      : undefined,
    moduleId,
    false
  );

  const {
    data: recordsData,
    isLoading: isRecordsLoading,
    isFetching: isRecordsFetching,
    error: recordsError,
  } = useGetRecordsQuery(
    {
      keyTypeId: true,
      formId: selectedForm?.value as number,
      filters: processFieldFilters(gridOptions?.fieldFilters, dateFormatBasedOnFilter),
      globalHierarchyFilters: buildGlobalHierarchyFiltersUrl(globalFilters, globalFiltersMatchingOption),
      sortDirective: buildSortedFieldsUrlString(gridOptions?.sortedFields, gridRef?.current?.api.data.columns),
      page: gridOptions?.page,
      pageSize: gridOptions?.pageSize,
    },
    {
      skip:
        gridOptions === undefined ||
        gridOptions?.page === undefined ||
        gridOptions?.pageSize === undefined ||
        selectedForm === undefined ||
        (selectedForm?.value as number) <= 0 ||
        isDropDownNoSelection(selectedForm) ||
        selectedFilters.viewConfig === undefined ||
        selectedFilters.records === undefined ||
        selectedFilters.lastEdited === undefined ||
        gridSettingsLoading,
    }
  );

  const isRecordsLoaded: boolean = !isRecordsLoading && !isRecordsFetching;

  const { data: workflowSteps, isFetching: areWorkflowStepsFetching } = useGetWorkflowStepsQuery(
    selectedForm?.value as number,
    {
      skip: selectedForm === undefined || (selectedForm?.value as number) <= 0,
    }
  );

  const {
    data: recordCount,
    isLoading: isRecordsCountLoading,
    isFetching: isRecordsCountFetching,
  } = useGetRecordCountQuery(
    {
      formId: selectedForm?.value as number,
      filters: processFieldFilters(gridOptions?.fieldFilters, dateFormatBasedOnFilter),
      globalHierarchyFilters: buildGlobalHierarchyFiltersUrl(globalFilters, globalFiltersMatchingOption),
    },
    {
      skip:
        gridOptions === undefined ||
        gridOptions?.page === undefined ||
        gridOptions?.pageSize === undefined ||
        selectedForm === undefined ||
        (selectedForm?.value as number) <= 0 ||
        isDropDownNoSelection(selectedForm) ||
        selectedFilters.viewConfig === undefined ||
        selectedFilters.records === undefined ||
        selectedFilters.lastEdited === undefined ||
        gridSettingsLoading,
    }
  );

  const isRecordsCountLoaded: boolean = !isRecordsCountLoading && !isRecordsCountFetching;

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.api.data.getDataOverwritePageCache();
    }
  }, [globalFilters, globalFiltersMatchingOption]);

  useEffect(() => {
    if (recordsError) {
      gridSubscriber.current?.sendData();
    } else if (recordsData && isRecordsLoaded && !gridSettingsLoading) {
      if (recordsData.length > 0) {
        gridSubscriber.current?.sendData({
          data: recordsData.map((record) => record.flat),
          requestId: gridOptions?.requestId,
        });
      } else {
        gridSubscriber.current?.sendData();
      }

      onDataLoadCompleted?.();
    }
  }, [recordsData, isRecordsLoaded, gridOptions, gridSettingsLoading, recordsError]);

  const tagCreator = useTestTag('single-form-grid');

  const recordCountDisplayValue = useMemo<string | undefined>(() => {
    if (isRecordsLoaded && isRecordsCountLoaded && recordsData && !gridSettingsLoading) {
      const currentPageRecordsCount = recordsData.length;

      return getCurrentlyLoadedAndTotalRecords(
        currentPageRecordsCount,
        recordCount ?? 0,
        gridOptions?.pageSize ?? 1,
        gridOptions?.page ?? 1
      );
    }
    return undefined;
  }, [isRecordsLoaded, isRecordsCountLoaded, recordsData, gridSettingsLoading]);

  const gridData = useMemo<OdinDataRetrieval | undefined>(() => {
    if (!areWorkflowStepsFetching && formSettings?.id === selectedForm?.value) {
      return {
        getSubscriber: (subscriber, fieldType?: DynamicFormFieldType) => {
          if (fieldType !== 'COMBOBOX') {
            gridSubscriber.current = subscriber;
          }
        },
        getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
          if (options?.fieldType === 'COMBOBOX') {
            await getDataGridComboboxFilters(
              subscriber,
              options,
              dispatch,
              false,
              {
                selectedForm,
                workflowSteps,
              },
              formSettings
            );
          } else {
            setGridOptions(options);
          }
        },
      };
    }
  }, [workflowSteps, areWorkflowStepsFetching, selectedForm?.value, formSettings]);

  const rowIndicator = useMemo(() => {
    return {
      indicator: (data?: JsonDataItem) => {
        const backgroundColor = workflowSteps?.find(
          (step: ExtendedDynamicFormWorkflowStep) => step.label === data?.status
        )?.backgroundColor;
        return <div className="h-4 w-4 rounded" style={{ backgroundColor: backgroundColor }} />;
      },
    };
  }, [workflowSteps]);

  const onDataGridRefCreated = (dataGridRef: DataGridRef) => {
    gridRef.current = dataGridRef;
    setDataGridReference(dataGridRef);
  };

  const canRenderDataGrid = !gridSettingsLoading && !!gridSettings?.columns && !!gridData && !!selectedForm?.value;
  const { renderDataGrid } = useRenderDataGrid(canRenderDataGrid);

  const handleViewChange = (hasChanged: boolean) => {
    onViewChange?.(hasChanged);
  };

  return (
    <div
      className={`-ml-10 flex h-full flex-col overflow-hidden relative${gridSettingsError ? ' justify-center' : ''}`}
    >
      {!renderDataGrid && !gridSettingsError && <DataGridLoader />}
      {canRenderDataGrid && (
        <>
          <DataGrid
            key={`${moduleId}_${selectedForm.value as unknown as string}`}
            ref={(gridRef) => gridRef && onDataGridRefCreated(gridRef)}
            data={gridData}
            gridSettings={gridSettings}
            rowTemplate={rowTemplate}
            rowIndicator={rowIndicator}
            customSettingsMenuItems={menuItems}
            onViewChange={handleViewChange}
          />
          <div className="px-10 font-semibold mb-5" ref={tagCreator('record-count')}>
            {recordCountDisplayValue}
          </div>
        </>
      )}
      {gridSettingsError && (
        <div className="flex items-center justify-center">
          <Notification
            key="configNotification"
            title={t('error-config-title')}
            description={t('error-config-view-description')}
            type="error"
          />
        </div>
      )}
      <ErrorLoadingDataNotification dataError={recordsError} />
    </div>
  );
}

export default SingleFormGrid;
