import React from 'react';
import cx from 'classnames';
import AttachmentListItem from './attachment-list-item.component';
import AttachmentThumbnail from './attachment-thumbnail.component';
import { PersonOption } from '../../../../@types/options';

export interface AttachmentFileProps {
  id: string;
  name: string;
  lastModified: string;
  person: PersonOption;
  url: string;
  attachmentId?: string; // only present for TQ attachments
}

interface AttachmentListProps {
  attachments: AttachmentFileProps[];
  thumbnails: boolean;
  readOnly: boolean;
  onAttachmentDelete?: (id: string) => void;
}

export default function AttachmentList({
  attachments,
  thumbnails = false,
  readOnly,
  onAttachmentDelete,
}: AttachmentListProps) {
  const containerStyles = cx('flex h-full w-full gap-1.5', {
    'flex-col': !thumbnails,
    'flex-row flex-wrap': thumbnails,
    'opacity-70': readOnly,
  });

  return (
    <div className={containerStyles}>
      {attachments.map((attachment) =>
        thumbnails ? (
          <AttachmentThumbnail
            key={attachment.id}
            {...attachment}
            readOnly={readOnly}
            onDelete={onAttachmentDelete}
            person={attachment.person?.value ?? ''}
          />
        ) : (
          <AttachmentListItem key={attachment.id} {...attachment} />
        )
      )}
    </div>
  );
}
