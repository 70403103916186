import { Key, useCallback, useEffect, useRef, useState } from 'react';
import {
  DropDownRef,
  JsonData,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import { LookupType } from '../../../@types/forms';
import useFetchPersonFieldOptions from './use-fetch-person-field-options';
import { forceAssert } from '../../../common/common-functions';
import useLookupTypeField from '../../../hooks/use-lookup-type-field';
import { LookupTypeFieldApiProps } from '../../../@types/options';
import { Control } from 'react-hook-form';

/**
 * A hook that handles all aspects for fetching the person fields data.
 *
 * @param textFieldKey the fields 'text' key propery (i.e. 'text')
 * @param id the 'id' of the field in the form
 * @param lookupType the defined lookup type, currently only `WITH_FIELD_KEY` and `WITH_HIERARCHY_FIELD` are supported
 * @param lookupKeyId the 'id' of the form field from which the value should be read.
 * @param lookupHierarchyId the 'id' of the hierarchy from which the value should be read.
 *
 */
const usePersonFieldOptions = (
  textFieldKey: string,
  id?: Key,
  control?: Control,
  lookupType?: LookupType,
  lookupKeyId?: number,
  lookupHierarchyId?: number
) => {
  const [options, setOptions] = useState<OdinDataRetrievalOptions>();
  const subscriberRef = useRef<OdinDataSender<JsonDataWrapper>>();
  const dropDownRef = useRef<DropDownRef>(null);
  const dataRequestedRef = useRef<boolean>();
  const hierarchyFieldIdRef = useRef<number>();

  const fetchPersonFieldOptions = useFetchPersonFieldOptions(textFieldKey);

  const {
    shouldUseLookup,
    hierarchyFieldId: resolvedHierarchyFieldId,
    keyValue,
  } = useLookupTypeField(control, lookupType, lookupKeyId, lookupHierarchyId);

  useEffect(() => {
    if (shouldUseLookup) {
      hierarchyFieldIdRef.current = resolvedHierarchyFieldId;
    }
  }, [shouldUseLookup, resolvedHierarchyFieldId]);

  useEffect(() => {
    if (id && options) {
      if (shouldUseLookup) {
        if (hierarchyFieldIdRef.current || keyValue) {
          fetchAndSetData(id, { ...options, hierarchyFieldId: hierarchyFieldIdRef.current, keyValue });
        } else {
          subscriberRef.current?.sendData();
          dataRequestedRef.current = false;
        }
      } else {
        fetchAndSetData(id, options);
      }
    }
  }, [id, options, shouldUseLookup, keyValue]);

  const fetchAndSetData = useCallback(
    (id: Key, options: OdinDataRetrievalOptions & LookupTypeFieldApiProps) => {
      fetchPersonFieldOptions(id, options)
        .then((data) => {
          if (data) {
            subscriberRef.current?.sendData({ data: forceAssert<JsonData>(data), requestId: options.requestId });
          } else {
            subscriberRef.current?.sendData();
          }
        })
        .catch(() => subscriberRef.current?.sendData())
        .finally(() => (dataRequestedRef.current = false));
    },
    [fetchPersonFieldOptions]
  );

  const onSelectorVisible = (open: boolean) => {
    if (shouldUseLookup) {
      if (!open) {
        dropDownRef.current?.resetDropDownData();
      } else if (open && dataRequestedRef.current === false) {
        dropDownRef.current?.getDataOverwritePageCache();
      }
    }
  };

  return {
    setOptions,
    onSelectorVisible,
    subscriberRef,
    dataRequestedRef,
    dropDownRef,
  };
};

export default usePersonFieldOptions;
