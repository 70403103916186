import React from 'react';
import { Button } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';

interface LinkedRecordsActionsCellProps {
  rowId: string;
  removeLinkedRecord: (rowId: string) => void;
}

const LinkedRecordsActionsCell = ({ rowId, removeLinkedRecord }: LinkedRecordsActionsCellProps) => {
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    removeLinkedRecord(rowId);
  };

  return (
    // NOTE: if modifying the padding 'pl-2', please remember to update all other cells
    <div className="flex h-full items-center justify-center py-1 pl-2">
      <Button type="text" onClick={handleClick} classNames="hover:bg-primary-5">
        {t('remove')}
      </Button>
    </div>
  );
};

export default LinkedRecordsActionsCell;
