import React, { Ref, useEffect, useRef } from 'react';
import {
  Button,
  DataGrid,
  DataGridCellRendererProps,
  DataGridColumnSettings,
  DataGridRef,
  DataGridSettings,
  DynamicFieldComponentProps,
  JsonData,
  JsonDataItem,
  ModalDialog,
  ModalDialogButtonSetting,
  ModalDialogRef,
} from '@myosh/odin-components';
import { forceAssert } from '../../../common/common-functions';
import {
  recordApi,
  useCreateNewVersionMutation,
  useDeleteLatestVersionMutation,
  useDeleteRecordMutation,
  useGetRecordVersionsQuery,
} from '../../../redux/services/record';
import useActiveRecordRef from '../../../hooks/use-active-record-ref';
import { RecordTitle } from '../../record/record-title.component';
import i18next from '../../../i18n';
import { useTranslation } from 'react-i18next';
import VersionGridActiveCell from './version-grid-active-cell.component';
import { getDataGridReference } from '../../../services/data-grid.service';
import { Subject, takeUntil } from 'rxjs';
import { constructRecordTitle } from '../../record/record-component-util';
import { useApiLogger } from '../../../hooks/use-api-logger';
import { promiseToast, showError, showInfo, showSuccess, showWarning } from '../../../services/notification.service';
import useFormPermissions from '../../../hooks/use-form-permissions';
import RemoveVersionCell from './remove-version-cell.component';
import { useAppDispatch } from '../../../redux/hooks';

type RecordLocationState = {
  moduleIcon: string;
  viewId: string;
  formId: number;
  formName: string;
  docNo: string;
  status: string;
  openInEditMode?: boolean;
  vikingLinkUrl?: string;
  displayText?: string;
};

export interface VersionFieldProps extends DynamicFieldComponentProps<JsonData> {
  name?: string;
  version?: number;
  recordId?: number;
  locationState?: RecordLocationState;
  formId: number;
}

const versionGridColumns: Array<DataGridColumnSettings> = [
  {
    id: 0,
    field: 'id',
    title: i18next.t('id'),
    visible: false,
    isIdField: true,
  },
  {
    id: 1,
    field: 'version',
    title: i18next.t('version'),
    visible: true,
    width: 70,
  },
  {
    id: 2,
    field: 'lastChange',
    title: i18next.t('date-modified'),
    visible: true,
    type: 8,
    width: 180,
  },
  {
    id: 3,
    field: 'status',
    title: i18next.t('status'),
    visible: true,
    cellRenderer: 'VERSION_STATUS_CELL',
    type: 6,
  },
];

function VersionField({ version, recordId, readOnly, permissions, locationState, formId }: VersionFieldProps) {
  const selectedRowIdRef = useRef<number>();
  const { activeRecordReference } = useActiveRecordRef();
  const versionGridRef = useRef<DataGridRef>(null);
  const destroySubject = useRef<Subject<void>>();
  const recordGridRef = useRef<DataGridRef>();
  const skipWarningOnFirstRowSelection = useRef(true);
  const createVersionModalReference = useRef<ModalDialogRef>(null);
  const deleteVersionModalReference = useRef<ModalDialogRef>(null);
  const { t } = useTranslation();
  const log = useApiLogger();
  const dispatch = useAppDispatch();
  const { formPermissions } = useFormPermissions(formId);

  const { data: recordVersions, isFetching } = useGetRecordVersionsQuery(
    { recordId: recordId ?? 0 },
    { skip: recordId === undefined || version === undefined || !permissions?.read }
  );
  const canDeleteVersions = recordVersions && recordVersions.length > 1 && formPermissions?.canDeleteRecordVersions;

  const [deleteLatestVersion, { isLoading: isDeleteLatestVersionRunning }] = useDeleteLatestVersionMutation();
  const [createNewVersion, { isLoading: isCreateNewVersionRunning }] = useCreateNewVersionMutation();
  const [deleteRecord] = useDeleteRecordMutation();

  useEffect(() => {
    if (recordVersions && !isFetching && formPermissions) {
      if (!selectedRowIdRef.current) {
        const activeVersionRecordId = recordVersions.find((record) => record.version === version)?.id as number;
        selectedRowIdRef.current = activeVersionRecordId;
      }

      if (selectedRowIdRef.current) {
        versionGridRef.current?.api.data.addSelectedRows([{ id: selectedRowIdRef.current }], true);
      }
    }
  }, [recordVersions, isFetching, formPermissions]);

  useEffect(() => {
    destroySubject.current = new Subject<void>();
    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((dataGridRef) => {
        if (dataGridRef) {
          recordGridRef.current = dataGridRef;
        }
      });

    return () => {
      destroySubject.current?.next();
      destroySubject.current?.complete();
    };
  }, []);

  const updateActiveRecord = (newRecord: JsonDataItem, setIsReadOnly = true) => {
    const _newRecordId = Number(newRecord['id']);

    if (selectedRowIdRef.current !== _newRecordId) {
      selectedRowIdRef.current = _newRecordId;

      const title = constructRecordTitle(
        locationState?.formName,
        String(newRecord['docNo']),
        String(newRecord['status'])
      );

      const details = <RecordTitle title={title} date={new Date(newRecord['creationDate'] as string)} />;
      const isRecordReadOnly =
        recordVersions?.[0]?.version !== newRecord['version'] && !formPermissions?.canEditRecordPastVersions;

      const settings = {
        title,
        recordId: _newRecordId,
        moduleIcon: locationState?.moduleIcon,
        viewId: locationState?.viewId,
        vikingLinkUrl: locationState?.vikingLinkUrl,
        formId: locationState?.formId,
        formName: locationState?.formName,
        docNo: newRecord['docNo'],
        status: newRecord['status'],
        isReadOnly: setIsReadOnly ? isRecordReadOnly : false,
        openInEditMode: !setIsReadOnly,
      };

      activeRecordReference.current?.updateActiveRecord(
        String(recordId),
        String(newRecord['id']),
        locationState?.moduleIcon,
        details,
        undefined,
        settings,
        true // force render to show correct data
      );
    }
  };

  const onVersionSelectionChange = (_gridId: string, selectedRows: JsonData) => {
    if (!skipWarningOnFirstRowSelection.current) {
      if (formPermissions?.canReadRecordPastVersions) {
        const selectedRow = selectedRows[0];
        updateActiveRecord(selectedRow);
      } else {
        showWarning(t('record-no-access-permissions'));
      }
    } else {
      skipWarningOnFirstRowSelection.current = false;
    }
  };

  const gridColumnsWithActions = canDeleteVersions
    ? [
        ...versionGridColumns,
        {
          id: 0,
          field: '',
          title: '',
          visible: readOnly ?? false,
          cellRenderer: 'VERSION_ACTIONS',
          type: 6,
          disableSort: true,
        },
      ]
    : versionGridColumns;

  const gridSettings: DataGridSettings = {
    columns: gridColumnsWithActions,
    fullHeight: true,
    components: {
      VERSION_STATUS_CELL: {
        CellComponent: (props: DataGridCellRendererProps, ref: Ref<HTMLDivElement>) => (
          <VersionGridActiveCell
            ref={ref}
            column={props.column}
            version={props.rowData.version as number}
            recordId={recordId}
          />
        ),
      },
      VERSION_ACTIONS: {
        CellComponent: (props: DataGridCellRendererProps) => {
          return (
            <RemoveVersionCell
              rowId={String(props.rowData.id)}
              removeRecord={handleDeleteRecord}
              version={props.rowData.version as number}
              recordVersions={recordVersions}
            />
          );
        },
      },
    },
  };

  const handleDeleteRecord = (rowId: string) => {
    deleteRecord(Number(rowId))
      .unwrap()
      .then((response) => {
        dispatch(recordApi.util.invalidateTags(['RecordVersion']));
        showSuccess(t('version-delete-success'));
        if (recordId !== response.result && recordId === Number(rowId)) {
          const newActiveRecordId = response.result;
          const newActiveVersion = recordVersions?.find((version) => version.id === newActiveRecordId);

          if (newActiveVersion) {
            showInfo(t('version-delete-redirect'));
            updateActiveRecord(forceAssert<JsonDataItem>(newActiveVersion));
          }
        }
        refreshRecordGrid();
      })
      .catch(() => showError(t('version-delete-failed')));
  };

  const refreshRecordGrid = () => {
    recordGridRef.current?.api.data.getDataOverwritePageCache();
  };

  const onVersionDelete = () => {
    const latestVersionRecordId = recordVersions?.[0]?.id;

    promiseToast(
      deleteLatestVersion(latestVersionRecordId ?? 0)
        .unwrap()
        .then((response) => {
          if (latestVersionRecordId === recordId) {
            const newActiveRecordId = response.result;
            const newActiveVersion = recordVersions?.find((version) => version.id === newActiveRecordId);

            if (newActiveVersion) {
              updateActiveRecord(forceAssert<JsonDataItem>(newActiveVersion));
            }
          }

          refreshRecordGrid();
        })
        .catch((e) => {
          log('Failed to save conflict resolved record', { error: e?.data?.validation?.errors });
        }),
      {
        loading: t('performing-delete-message'),
        success: t('delete-successful-message'),
        error: t('version-delete-failed'),
      }
    );
  };

  const onVersionCreate = () => {
    promiseToast(
      createNewVersion(recordId ?? 0)
        .unwrap()
        .then((response) => {
          const newActiveRecordId = response.result;
          const newActiveVersion = {
            ...recordVersions?.[0],
            id: newActiveRecordId,
            lastChange: new Date(),
          };
          updateActiveRecord(newActiveVersion, false);

          refreshRecordGrid();
        })
        .catch((e) => {
          log('Failed to create a new record version', { error: e?.data?.validation?.errors });
        }),
      {
        loading: t('creating'),
        success: t('created'),
        error: t('version-create-failed'),
      }
    );
  };

  const deleteVersionButton: ModalDialogButtonSetting = {
    type: 'primary',
    name: 'delete',
    text: t('delete'),
    onClick: onVersionDelete,
  };

  const onVersionDeleteClick = () => {
    deleteVersionModalReference.current?.show();
  };

  const createVersionButton: ModalDialogButtonSetting = {
    type: 'primary',
    name: 'create',
    text: t('create'),
    onClick: onVersionCreate,
  };

  const onVersionCreateClick = () => {
    createVersionModalReference.current?.show();
  };

  return (
    <>
      {permissions?.read && (
        <>
          {readOnly && permissions?.edit && (
            <div className="mb-2 flex gap-2 p-2">
              {formPermissions?.canCreateRecordNewVersions && (
                <Button onClick={onVersionCreateClick} variant="alternative" disabled={isCreateNewVersionRunning}>
                  {t('version-new')}
                </Button>
              )}
              {canDeleteVersions && (
                <Button onClick={onVersionDeleteClick} variant="alternative" disabled={isDeleteLatestVersionRunning}>
                  {t('version-delete-latest')}
                </Button>
              )}
            </div>
          )}
          <DataGrid
            ref={versionGridRef}
            data={forceAssert<JsonData>(recordVersions) ?? []}
            gridSettings={gridSettings}
            onRowSelectionChange={onVersionSelectionChange}
            showSettings={false}
          />
          <ModalDialog ref={createVersionModalReference} header={t('version-new')} buttons={[createVersionButton]}>
            <div>{t('version-new-confirmation')}</div>
          </ModalDialog>
          <ModalDialog ref={deleteVersionModalReference} header={t('version-delete')} buttons={[deleteVersionButton]}>
            <div>{t('version-delete-confirmation')}</div>
          </ModalDialog>
        </>
      )}
    </>
  );
}

export default React.memo(VersionField);
