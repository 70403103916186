import React from 'react';
import { MenuComponentProps, MenuItem } from '../../record/record-menu.component';
import { IconButton, OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

interface SchedulerMenuProps {
  onDeleteSchedule: () => void;
  isAdmin: boolean;
  canModify: boolean;
  fullScreen?: boolean;
}

const SchedulerMenu = ({ onDeleteSchedule, canModify, fullScreen = false }: SchedulerMenuProps) => {
  const { t } = useTranslation();

  const actionDropdownItemStyle = cx({
    'text-sm flex items-center whitespace-nowrap px-2 py-1 leading-5 hover:bg-primary-4 cursor-pointer': !fullScreen,
  });

  const menuStyle = cx({
    'z-30 absolute right-0 top-9 hidden w-48 flex-col bg-mono-1 py-1 shadow group-hover:flex': !fullScreen,
    flex: fullScreen,
  });

  const menus: MenuComponentProps[] = [
    {
      title: t('delete'),
      className: actionDropdownItemStyle,
      onClick: onDeleteSchedule,
      icon: 'DeleteBin5',
      iconType: OdinIconType.Line,
      hidden: !canModify,
    },
  ].filter((menu) => !menu.hidden);

  return menus.length > 0 ? (
    <div className="group relative">
      {!fullScreen && (
        <IconButton classNames="mx-2 h-9 flex items-center group-hover:text-gray-1 ">
          <OdinIcon size={OdinIconSize.Medium} icon="More" className="w-6" />
        </IconButton>
      )}
      <div className={menuStyle}>
        {menus.map((menu) => {
          return <MenuItem key={menu.title} menu={menu} fullScreenMode={fullScreen} />;
        })}
      </div>
    </div>
  ) : undefined;
};

export default SchedulerMenu;
