import {
  DynamicFormField,
  findRecursive,
  isDropDownNoSelection,
  isObject,
  JsonDataItem,
  OptionWithOptionalProperties,
  VisibilityCondition,
  VisibilityConditionConfig,
} from '@myosh/odin-components';
import { isEqual } from 'lodash';
import { DropDownValue, OptionBuilderValue, ValueTextType } from '../../@types/common';
import { CombinedFieldType } from '../../@types/forms';
import { QuestionnaireFieldValue } from '../../@types/questionnaire';
import { PostRecordResponse, RecordResult } from '../../@types/records';
import {
  ExtendedDynamicFormHierarchySettings,
  ExtendedDynamicFormSettings,
  ExtendedDynamicFormWorkflowStep,
  findFieldRecursive,
  forceAssert,
  isArrayOfOptionWithOptionalProperties,
  isArrayOfValueTextType,
  isInstanceOfOptionBuilder,
  isInstanceOfValueTextType,
} from '../../common/common-functions';
import { Conflict } from './conflicts-modal/conflicts-modal.component';
import { FormWorkflowStepAccessControl } from '../../@types/form-permissions';
import { formatToDateOnly } from '../../common/date-util';
import { HierarchyDropdownValue } from '../../@types/hierarchy-fields';
import i18next from '../../i18n';

/**
 * Updates the record object 'original' data.
 *
 * The 'original' data represents the Record object as it was received from the API.
 *
 * @param data the updated record data
 * @param originalData the original record data.
 * @param schemaTimeZone the schema time zone
 */
export function updateOriginalData(data: Record<string, unknown>, originalData: RecordResult) {
  const dataKeys = Object.keys(data);
  const originalDataKeys = Object.keys(originalData);
  const originalFieldsDataKeys = Object.keys(originalData.fields);

  for (let i = 0, length = dataKeys.length; i < length; i++) {
    if (originalFieldsDataKeys.includes(dataKeys[i])) {
      _setFieldValue(data, originalData, dataKeys[i]);
    } else if (dataKeys[i] === 'hierarchyValues') {
      originalData.hierarchies = _createHierarchiesObject(data[dataKeys[i]] as Record<string, HierarchyDropdownValue>);
    } else if (dataKeys[i] === 'files') {
      originalData.files = forceAssert<Array<string>>(data[dataKeys[i]]);
    } else if (dataKeys[i] === 'images') {
      originalData.images = forceAssert<Array<string>>(data[dataKeys[i]]);
    } else if (!originalDataKeys.includes(dataKeys[i])) {
      _setFieldValue(data, originalData, dataKeys[i]);
    }
  }
}

function _setFieldValue(
  data: Record<string, unknown>,
  originalData: RecordResult,
  key: string,
  fieldsToEliminate?: Array<string>
) {
  const dataItem = data[key];
  if (isInstanceOfValueTextType(dataItem)) {
    originalData.fields[key] = {
      id: parseInt(dataItem.value as string),
      value: dataItem.text,
    };
  } else if (
    Array.isArray(dataItem) &&
    dataItem.length > 0 &&
    (isArrayOfOptionWithOptionalProperties(dataItem) || isArrayOfValueTextType(dataItem))
  ) {
    originalData.fields[key] = []; // reset original value
    for (let i = 0; i < dataItem.length; i++) {
      let currentItem;
      if (isArrayOfOptionWithOptionalProperties(dataItem)) {
        currentItem = forceAssert<OptionWithOptionalProperties>(dataItem[i]);
        forceAssert<Array<OptionBuilderValue>>(originalData.fields[key])[i] = {
          id: typeof currentItem.value === 'string' ? parseInt(currentItem.value) : (currentItem.value as number),
          value: currentItem.text,
          archivedOption: currentItem.archivedOption ?? false,
          defaultOption: currentItem.defaultOption ?? false,
          position: currentItem.position ?? 0,
        };
      } else if (isArrayOfValueTextType(dataItem)) {
        currentItem = forceAssert<ValueTextType>(dataItem[i]);
        forceAssert<Array<{ id: number; value: string }>>(originalData.fields[key])[i] = {
          id: typeof currentItem.value === 'string' ? parseInt(currentItem.value) : currentItem.value,
          value: currentItem.text,
        };
      }
    }
  } else if (isDropDownNoSelection(dataItem)) {
    originalData.fields[key] = null;
  } else if (dataItem instanceof Date) {
    originalData.fields[key] = formatToDateOnly(dataItem);
  } else if (!fieldsToEliminate || (fieldsToEliminate && !fieldsToEliminate.some((_key) => _key === key))) {
    originalData.fields[key] = data[key];
  }
}

/**
 * Creates a new Record object that will be sent to the backend for persisting.
 *
 * @param formId {number} - the id of the form.
 * @param status {string} the status of the record defined by the workflow steps
 * @param data {Record<string, unknown>} the record data.
 * @param schemaTimeZone {string} the schema time zone
 *
 * @returns A record object ready to be sent to the backend.
 */
export function createSaveData(formId: number, status: string, data: Record<string, unknown>) {
  const record: RecordResult = {
    formId,
    status,
    hierarchies: {},
    fields: {},
  };

  return _createRecordObject(record, data, Object.keys(data));
}

/**
 * Creates a patch Record object that will be sent to the backend for persisting.
 *
 * @param formId {number} - the id of the form.
 * @param status {string} the status of the record defined by the workflow steps
 * @param data {Record<string, unknown>} the record data.
 * @param dirtyFields {Record<string, unknown>} the form fields that have been modified. Used when updating existing records.
 * @param schemaTimeZone {string} the schema time zone
 *
 * @returns A record object ready to be sent to the backend.
 */
export function createPatchData(
  formId: number,
  status: string,
  data: Record<string, unknown>,
  dirtyFields: Record<string, unknown>
) {
  const record: RecordResult = {
    formId,
    status,
    hierarchies: {},
    fields: {},
  };
  const _dirtyFieldKeys = Object.keys(dirtyFields).filter((key) => key !== 'attachments');
  const recordObject = _createRecordObject(record, data, _dirtyFieldKeys);

  const patchData = {
    ...recordObject,
    files: (data.files as string[]) ?? [],
    images: (data.images as string[]) ?? [],
  };

  if (data.deletedFiles && Array.isArray(data.deletedFiles)) {
    // include the 'deletedFiles' in the payload (https://myosh.atlassian.net/browse/MYOSH-6113?focusedCommentId=53410)
    patchData.deletedFiles = data.deletedFiles;
  }

  return patchData;
}

function _createRecordObject(record: RecordResult, data: Record<string, unknown>, dataKeys: string[]) {
  const hierarchyKeys = data.hierarchies ? Object.keys(forceAssert<Record<string, unknown>>(data.hierarchies)) : [];
  for (let i = 0, length = dataKeys.length; i < length; i++) {
    const dataKey = dataKeys[i];
    const dataItem = data[dataKey];
    if (dataKey === 'hierarchyValues') {
      record.hierarchies = _createHierarchiesObject(dataItem as Record<string, HierarchyDropdownValue>);
    } else if (isInstanceOfValueTextType(dataItem)) {
      const textType = forceAssert<ValueTextType>(dataItem);
      record.fields[dataKey] = {
        id: typeof textType.value === 'string' ? parseInt(textType.value) : textType.value,
        value: textType.text,
      };
    } else if (
      Array.isArray(dataItem) &&
      dataItem.length > 0 &&
      (isInstanceOfOptionBuilder(dataItem[0]) || isInstanceOfValueTextType(dataItem[0]))
    ) {
      const dataArray = dataItem;
      record.fields[dataKey] = [];
      for (let j = 0; j < dataArray.length; j++) {
        const dataArrayItem = dataArray[j];
        if (isInstanceOfOptionBuilder(dataArrayItem)) {
          forceAssert<Array<OptionBuilderValue>>(record.fields[dataKey])[j] = {
            id:
              typeof dataArrayItem.value === 'string' ? parseInt(dataArrayItem.value) : (dataArrayItem.value as number),
            value: dataArrayItem.text,
            position: dataArrayItem.position ?? 0,
            archivedOption: dataArrayItem.archivedOption ?? false,
            defaultOption: dataArrayItem.defaultOption ?? false,
          };
        } else if (isInstanceOfValueTextType(dataArrayItem)) {
          forceAssert<Array<DropDownValue>>(record.fields[dataKey])[j] = {
            id: typeof dataArrayItem.value === 'string' ? parseInt(dataArrayItem.value) : dataArrayItem.value,
            value: dataArrayItem.text,
          };
        }
      }
    } else if (dataItem instanceof Date) {
      record.fields[dataKey] = formatToDateOnly(dataItem);
    } else if (!hierarchyKeys.some((key) => dataKey === key)) {
      record.fields[dataKey] = dataItem;
    }
  }

  return record;
}

function _createHierarchiesObject(hierarchies: Record<string, HierarchyDropdownValue>) {
  const transformedObject: Record<string, string> = {};

  for (const key in hierarchies) {
    if (hierarchies.hasOwnProperty(key) && hierarchies[key]) {
      transformedObject[key] = hierarchies[key].caption || '';
    }
  }

  return transformedObject;
}

interface HistoryFieldType {
  key: number | string;
  value?: string;
}

/**
 * Extracts the history fields, if present.
 *
 * This method searches the record data for the 'HISTORY' field.
 * If found, it is checked for a 'draft' value. The 'draft' value indicates a 'new' comment.
 *
 * If a 'draft' is not found, the history field is removed from the record, as there are no updates.
 *
 * If a 'draft' is found, the new comment object is modified and attached to the data field in a format expected by the component.
 * This is the same format in which we receive the data from the REST endpoint for a record.
 *
 * Furthermore, the key/value pair is returned from the method so it can be added to the record data. This step is needed
 * as the REST endpoint expect a new value of the history field to just be the 'string' of the new message.
 *
 * Please note that this method does **mutate** the supplied `data` object.
 *
 * @param fields the form fields configuration
 * @param data the record data, which can be **mutated**.
 * @returns an array of objects with key/value pair of the 'new' history value or an empty array
 */
export function extractHistoryFields(fields: Array<DynamicFormField>, data: JsonDataItem) {
  const historyFields: Array<HistoryFieldType> = [];
  const dataKeys = Object.keys(data);

  for (const key of dataKeys) {
    const field = findRecursive(
      fields,
      'fields',
      (item: DynamicFormField) => item.fieldType === 'HISTORY' && item.id.toString() === key
    );

    if (field) {
      const _value = _parseHistoryFieldValue(data[field.id]);
      if (_value?.[0]?.draft === true) {
        // remove flag
        delete _value[0].draft;

        // transform value to what we would receive from the backend
        _value[0] = { ..._value[0], date: new Date().toISOString() };

        // update data with new comment added
        data[field.id] = JSON.stringify(_value);

        // return the key/value pair for submission with the update/save of the record
        historyFields.push({
          key: field.id,
          value: _value[0].message,
        });
      } else {
        delete data[field.id];

        historyFields.push({
          key: field.id,
        });
      }
    }
  }

  return historyFields;
}

/**
 * Transforms the history field value representation in the record to be saved.
 *
 * @param recordData The record data that is to be submitted to the backend
 * @param historyFields The array of history field pairs extracted by calling `extractHistoryFields`.
 * @returns The record data with a correctly formatted history field value.
 */
export function transformHistoryFieldsValue(recordData: RecordResult, historyFields: Array<HistoryFieldType>) {
  let dataToSave = recordData;

  if (historyFields.length > 0) {
    for (let i = 0; i < historyFields.length; i++) {
      if (historyFields[i].value) {
        // The history field expects a simple key/value pair when adding a new comment
        dataToSave = {
          ...recordData,
          fields: { ...recordData.fields, [historyFields[i].key]: historyFields[i].value },
        };
      } else {
        // Remove the history field from the data to be sent to the backend
        delete dataToSave.fields[historyFields[i].key];
      }
    }
  }

  return dataToSave;
}

function _parseHistoryFieldValue(value: unknown) {
  if (Array.isArray(value)) {
    return Array.from(value);
  } else if (typeof value === 'string') {
    return JSON.parse(value);
  }
  return value;
}

/**
 * Finds the merge conflicts in the given record.
 *
 * This method will comapre the locally changed fields with the values from the remote record and compile a list of merge conflicts.
 *
 * @param initialServerRecord The initial record from the server that the user has locally modified.
 * @param localRecord The local record that the user is currently working with.
 * @param serverRecord The latest record as stored on the server, against which we are comapring.
 * @param dirtyFields The list of fileds the user has locally modified.
 * @param formSettings The form settings of the form the record belongs to.
 *
 * @returns A list of conflicts.
 */
export function findMergeConflicts(
  initialServerRecord: RecordResult,
  localRecord: RecordResult,
  serverRecord: RecordResult,
  dirtyFields: JsonDataItem,
  formSettings: ExtendedDynamicFormSettings
) {
  const fieldKeys = Object.keys(dirtyFields);
  const conflicts: Conflict[] = [];

  for (let i = 0; i < fieldKeys.length; i++) {
    const fieldKey = fieldKeys[i];

    if (fieldKey === 'hierarchies') {
      const isLocalHierarchyValueDifferentFromServerHierarchyValue = !isEqual(
        localRecord.hierarchies,
        serverRecord.hierarchies
      );

      const isInitialServerHierarchyValueDifferentFromServerHierarchyValue = !isEqual(
        initialServerRecord.hierarchies,
        serverRecord.hierarchies
      );

      if (
        isLocalHierarchyValueDifferentFromServerHierarchyValue &&
        isInitialServerHierarchyValueDifferentFromServerHierarchyValue
      ) {
        const conflict: Conflict = {
          id: 'hierarchies',
          caption: i18next.t('hierarchy'),
          type: 'CUSTOM',
          customFieldType: 'HIERARCHY',
          theirValue: serverRecord.hierarchies,
          myValue: localRecord.hierarchies,
          dynamicProperties: {
            hierarchySettings: { ...formSettings?.hierarchySettings } as ExtendedDynamicFormHierarchySettings,
          },
        };
        conflicts.push(conflict);
      }
    } else {
      const isLocalRecordValueDifferentFromServerRecordValue = !isEqual(
        localRecord.fields[fieldKey],
        serverRecord.fields[fieldKey]
      );

      // If the initial server record has the same value as the server record,
      // there is no conflict and the API has only reproted so due to time stamp mismatch.
      const isInitialServerRecordValueDifferentFromServerRecordValue = !isEqual(
        initialServerRecord.fields[fieldKey],
        serverRecord.fields[fieldKey]
      );

      if (
        isLocalRecordValueDifferentFromServerRecordValue &&
        isInitialServerRecordValueDifferentFromServerRecordValue
      ) {
        // this is a conflict
        const field = findFieldRecursive(formSettings.fields, fieldKey);
        if (field && 'HISTORY' !== field.fieldType) {
          // The questionnaire field needs special handling for the files/images which are part of the more details
          if (
            'TEXTUAL_QUESTIONNAIRE' === (field.customFieldType as CombinedFieldType) ||
            'NUMERIC_QUESTIONNAIRE' === (field.customFieldType as CombinedFieldType)
          ) {
            const myValue = (localRecord.fields[fieldKey] as QuestionnaireFieldValue).value;
            const theirValue = (serverRecord.fields[fieldKey] as QuestionnaireFieldValue).value;
            const iLength = myValue.length;
            for (let i = 0; i < iLength; i++) {
              const myValueItem = myValue[i];
              const jLength = theirValue.length;
              for (let j = 0; j < jLength; j++) {
                const theirValueItem = theirValue[j];
                if (myValueItem.questionId === theirValueItem.questionId) {
                  // overwrite local values as server values are always up to date
                  myValueItem.files = [...(theirValueItem.files ?? [])];
                  myValueItem.images = [...(theirValueItem.images ?? [])];
                }
              }
            }
          }

          const conflict: Conflict = {
            id: field.id,
            caption: field.caption,
            type: field.customFieldType as CombinedFieldType,
            theirValue: serverRecord.fields[fieldKey],
            myValue: localRecord.fields[fieldKey],
            dynamicProperties: field.dynamicProperties,
          };
          conflicts.push(conflict);
        }
      }
    }
  }

  return conflicts;
}

export function cleanupRecordData(data: JsonDataItem) {
  //the API throws an error if it finds an property that doesn't exist,  instead of just ignoring it, so we have to do this
  if (data.attachments) {
    delete data.attachments;
  }
  if (isObject(data.fields) && data.fields.attachments) {
    delete data.fields.attachments;
  }
}

export function resolveRecordStatus(status?: string, defaultStatus?: string) {
  return status || defaultStatus || 'Open';
}

export const getConstructedRecordTitle = (recordData: RecordResult) => {
  return (
    recordData.title ??
    `${recordData.formName} #${recordData.docNo} - ${recordData.status} ${
      recordData.version ? `- Version ${recordData.version}` : ''
    }`
  );
};

/**
 * Finds the first dynamic field that matches the predicate.
 * @param {Array<DynamicFormField>} fields - The recursive list of fields to search.
 * @param {(field: DynamicFormField) => boolean} predicate - A function called to determine if a field is a match.
 * @returns {DynamicFormField | undefined} The first matching dynamic field or undefined if nothing is found.
 */
export function findFieldRecursiveSearch(
  fields: Array<DynamicFormField>,
  predicate: (field: DynamicFormField) => boolean
): DynamicFormField | undefined {
  for (let i = 0, length = fields.length; i < length; i++) {
    const field = fields[i];
    if (predicate(field)) {
      return field;
    }
    if (field.fields) {
      const result = findFieldRecursiveSearch(field.fields, predicate);
      if (result) {
        return result;
      }
    }
  }
}

/**
 * Builds the array of workflow step visibility conditions.
 *
 * @param activeWorkflowStep the current workflow step.
 *
 * @returns the workflow step visibility conditions.
 */
export function buildWorkflowStepVisibilityConditions(activeWorkflowStep?: ExtendedDynamicFormWorkflowStep) {
  const workflowStepVisibilityConditions: Array<VisibilityConditionConfig> = [];
  if (activeWorkflowStep && activeWorkflowStep.nextSteps) {
    for (let i = 0, length = activeWorkflowStep.nextSteps.length; i < length; i++) {
      const nextStep = activeWorkflowStep.nextSteps[i];
      if (nextStep.visibilityConditions) {
        workflowStepVisibilityConditions.push({
          id: `${nextStep.id}_${i}`,
          groups: [
            {
              conditions: nextStep.visibilityConditions,
            },
          ],
        });
      }
    }
  }
  return workflowStepVisibilityConditions;
}

/**
 * Adds the person field or user groups access control conditions as visibility conditions.
 *
 * This is performed to ensure that dynamic field changes are correctly applied.
 * Please note that this method **mutates** the supplied
 * `workflowStepVisibilityConditions` array.
 *
 * @param workflowStepVisibilityConditions The current workflow step visibility conditions.
 * @param workflowSteps The form workflow steps.
 * @param activeWorkflowStep The currently active workflow step.
 * @param userId The current user id.
 * @returns {Array<VisibilityConditionConfig>} the mutated `workflowStepVisibilityConditions` array of visibility conditions.
 */
export function addAccessControlConditionsAsVisibilityConditions(
  workflowStepVisibilityConditions: Array<VisibilityConditionConfig>,
  workflowSteps: Array<FormWorkflowStepAccessControl>,
  activeWorkflowStep: ExtendedDynamicFormWorkflowStep,
  userId: number
) {
  const workflowStep = workflowSteps.find((step) => step.id === activeWorkflowStep.id);
  if (workflowStep && activeWorkflowStep && activeWorkflowStep.nextSteps) {
    const accessControlVisibilityConditions: Array<VisibilityCondition> = [];
    const workflowStepAccessControlConfig = workflowStep.configs.find((step) => step.type === 'progress');

    // add the person field conditions
    if (workflowStepAccessControlConfig?.personFields) {
      for (let i = 0, length = workflowStepAccessControlConfig.personFields.length; i < length; i++) {
        const personField = workflowStepAccessControlConfig.personFields[i];

        const personCondition: VisibilityCondition = {
          id: personField.id,
          sourceFieldId: personField.id,
          fieldVisibilityType: 'FIELD',
          targetValueType: 'ENTITY_ID',
          targetValueId: userId,
          negation: false,
          order: i + 1,
          conjugate: 'OR',
          filterOperator: 'EQUALS',
        };

        accessControlVisibilityConditions.push(personCondition);
      }
    }

    // add the groups conditions (otherwise dynamic data chages might remove a workflow button)
    if (workflowStepAccessControlConfig?.roles) {
      const previousTotal = accessControlVisibilityConditions.length;

      for (let j = 0, jLength = workflowStepAccessControlConfig.roles.length; j < jLength; j++) {
        const role = workflowStepAccessControlConfig.roles[j];

        const visibilityCondition: VisibilityCondition = {
          id: role.id,
          fieldVisibilityType: 'GROUP',
          targetValueId: role.id,
          negation: false,
          order: previousTotal + j + 1,
          conjugate: 'OR',
          filterOperator: 'EQUALS',
        };

        accessControlVisibilityConditions.push(visibilityCondition);
      }
    }

    // combine any access control conditions to the existing visibility conditions
    if (accessControlVisibilityConditions.length > 0) {
      // this adds access control visibility conditions for each next step workflow button
      for (let k = 0, kLength = activeWorkflowStep.nextSteps.length; k < kLength; k++) {
        const nextStep = activeWorkflowStep.nextSteps[k];
        const conditionId = nextStep.id;
        if (workflowStepVisibilityConditions.length > 0) {
          const workflowStepVisibilityCondition = workflowStepVisibilityConditions.find(
            (condition) => condition.id.toString() === `${conditionId}_${k}`
          );

          if (workflowStepVisibilityCondition) {
            workflowStepVisibilityCondition.groups = [
              {
                conditions: [...accessControlVisibilityConditions],
              },
              ...workflowStepVisibilityCondition.groups,
            ];
          } else {
            workflowStepVisibilityConditions.push({
              id: `${conditionId}_${k}`,
              groups: [
                {
                  conditions: accessControlVisibilityConditions,
                },
              ],
            });
          }
        } else {
          workflowStepVisibilityConditions.push({
            id: `${conditionId}_${k}`,
            groups: [
              {
                conditions: accessControlVisibilityConditions,
              },
            ],
          });
        }
      }
    }
  }

  return workflowStepVisibilityConditions;
}

export function recordErrorType(postResponse: PostRecordResponse) {
  const errorResult = postResponse.error.data.result;
  if (
    errorResult &&
    errorResult[0].error ===
      'More than one record matches automatic versioning criteria. Please contact your administrator.'
  ) {
    return 'automaticVersioningError';
  } else {
    return 'genericError';
  }
}

export const constructRecordTitle = (
  formName?: string,
  docNo?: number | string,
  status?: string,
  title?: string
): string => {
  return title ?? `${formName} #${leftPadWithZeros(docNo)} [${status}]`;
};

const leftPadWithZeros = (num?: number | string): string => {
  return num?.toString().padStart(6, '0') ?? '000000';
};
