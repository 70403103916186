import React, { forwardRef, Ref, useEffect, useMemo, useRef, useState, useImperativeHandle, Key } from 'react';
import {
  DataGridRef,
  DynamicFormButtonSetting,
  DynamicForm,
  DynamicFormRef,
  JsonDataItem,
  JsonDataWrapper,
  ModalDialogRef,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  OdinDataUpdate,
  DynamicFormFieldType,
  DropDownResult,
  ModalDialog,
} from '@myosh/odin-components';
import { ActiveRecordContextProps } from '../../../active-record/active-record.component';
import { cloneDeep, debounce } from 'lodash';
import { BehaviorSubject, Subject, skip, takeUntil } from 'rxjs';
import { v4 } from 'uuid';
import { FormLoading } from '../../../form/form-loading.component';
import { FormTitle } from '../../../form/form-title.component';
import useProfileData from '../../../../hooks/use-profile-data';
import { getDataGridReference } from '../../../../services/data-grid.service';
import { forceAssert } from '../../../../common/common-functions';
import { getOcccupationalRequirementsFormSettings } from './occupational-requirements-form-settings';
import {
  occupationApi,
  useGetOccupationalRequirementsByIdQuery,
  useUpdateOccupationalRequirementMutation,
} from '../../../../redux/services/occupation';
import {
  OccupationalRequirementsItem,
  OccupationalRequirementsMutationResponse,
  OccupationalRequirementsStructure,
} from '../../../../@types/occupational-requirements';
import { useAppDispatch } from '../../../../redux/hooks';
import { getOccupationalRequirementsFieldOptionsData } from '../../../../common/training-management-functions';
import useTrainingManagementEditAccess from '../../../../hooks/use-training-management-edit-access';
import { useHierarchyTypesQuery } from '../../../../redux/services/hierarchy';
import { saveModalButtons } from '../../../../common/common-administration-utils';
import { createOccupationalRequirementsPatch } from '../../../../common/occupational-requirements-functions';
import usePerformingSaveToast from '../../../../hooks/use-performing-save-toast';
import { promiseToast, showError, showSuccess } from '../../../../services/notification.service';
import useDynamicFormNotifier from '../../../../hooks/use-dynamic-form-notifier';
import { useApiLogger } from '../../../../hooks/use-api-logger';
import CloseActiveItemButton from '../../../common/close-active-item-button';
import { useTranslation } from 'react-i18next';
import {
  archieveButton,
  getArchiveRestoreMessages,
  restoreButton,
  saveButton,
} from '../../../../pages/admin/admin-utils';

interface OccupationalRequirementsRef {
  submitForm?: () => void;
}

export interface OccupationalRequirementsDetailsProps {
  occupationalRequirementId: number;
  title?: string;
  editable?: boolean;
  onClose: (id: string) => void;
  panelSettings?: BehaviorSubject<ActiveRecordContextProps>;
  onCloseActiveRecordConfirmationModalRef?: ModalDialogRef | null;
}

function OccupationalRequirementsDetails(
  props: OccupationalRequirementsDetailsProps,
  ref: Ref<OccupationalRequirementsRef>
) {
  const { title, occupationalRequirementId, onClose, panelSettings, onCloseActiveRecordConfirmationModalRef } = props;
  const dispatch = useAppDispatch();
  const log = useApiLogger();

  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const dynamicFormId = useRef(v4());
  const destroySubject = useRef(new Subject<void>());
  const dataGridRef = useRef<DataGridRef>();
  const closeOnModalSaveRef = useRef(false);
  const saveModalReference = useRef<ModalDialogRef>(null);

  const [occupationalRequirement, setOccupationalRequirement] = useState<OccupationalRequirementsStructure>();
  const [readOnly, setReadOnly] = useState<boolean>(Boolean(false));
  const [panelContextProps, setPanelContextProps] = useState<ActiveRecordContextProps>();
  const [formButtons, setFormButtons] = useState<Array<DynamicFormButtonSetting>>();
  const [validateCompetencies, setValidateCompetencies] = useState<boolean>(false);

  const { t } = useTranslation();

  const { profileData: { user: userData } = {} } = useProfileData();
  const { showPerformingSaveToast, hidePerformingSaveToast } = usePerformingSaveToast({
    shouldFreezeActiveRecord: true,
    disabled: panelContextProps?.batchSaveInProgress,
  });
  const { notifyDirty, notifySaveSucceeded, notifySaveFailed } = useDynamicFormNotifier(dynamicFormId.current);

  const isEditDisabled = useTrainingManagementEditAccess(userData);

  const { data: occupationalRequirementData, isFetching } =
    useGetOccupationalRequirementsByIdQuery(occupationalRequirementId);
  const [updateOccupationalRequirement] = useUpdateOccupationalRequirementMutation();

  const { data: hierarchyTypes } = useHierarchyTypesQuery({ archived: 'false' });

  useEffect(() => {
    panelSettings?.pipe(skip(1), takeUntil(destroySubject.current)).subscribe((settings) => {
      setPanelContextProps(settings);
    });

    onCloseActiveRecordConfirmationModalRef
      ?.subscribeToDialogResult()
      ?.pipe(takeUntil(destroySubject.current))
      .subscribe((result) => {
        if (result.buttonName === 'save' && dynamicFormReference.current?.formIsDirty()) {
          dynamicFormReference.current?.submitForm(true);
        } else if (result.buttonName === 'discard') {
          onClose(dynamicFormId.current);
        }
      });

    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((gridRef) => {
        if (gridRef) {
          dataGridRef.current = gridRef;
        }
      });

    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
    };
  }, []);

  const dataRetrieval: OdinDataRetrieval & OdinDataUpdate<JsonDataWrapper> = {
    getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
      if (options && ['COMBOBOX', 'LIST_BOX', 'OPTIONGROUP', 'TWIN_COLUMN_SELECT'].includes(options.fieldType || '')) {
        await getOccupationalRequirementsFieldOptionsData(subscriber, options, dispatch);
      } else {
        subscriber.sendData();
      }
    },
  };

  useEffect(() => {
    if (!isEditDisabled && !occupationalRequirement?.original?.archived) {
      setReadOnly(false);
    } else {
      setReadOnly(true);
    }
  }, [isEditDisabled, occupationalRequirement?.original?.archived]);

  const onArchieveOccupationalRequirement = (archive: boolean) => {
    if (occupationalRequirementId) {
      promiseToast(
        updateOccupationalRequirement({ id: occupationalRequirementId, archived: archive })
          .then(() => {
            onClose(dynamicFormId.current);
            dataGridRef.current?.api.data.getDataOverwritePageCache();
            dispatch(occupationApi.util.invalidateTags([{ type: 'OccupationalRequirement', id: 'LIST' }]));
          })
          .catch(() => showErrorToast()),
        getArchiveRestoreMessages(archive)
      );
    }
  };

  useEffect(() => {
    if (occupationalRequirementData && !isFetching) {
      setOccupationalRequirement(cloneDeep(occupationalRequirementData));
      const newFormButtons: Array<DynamicFormButtonSetting> = [];
      if (occupationalRequirementData?.original.archived) {
        const restore = cloneDeep(restoreButton);
        newFormButtons.push(restore);
        restore.onClick = () => onArchieveOccupationalRequirement(false);
      } else {
        const archieve = cloneDeep(archieveButton);
        archieve.onClick = () => onArchieveOccupationalRequirement(true);
        newFormButtons.push(cloneDeep(saveButton), archieve);
      }
      if (isEditDisabled) {
        setFormButtons(newFormButtons.map((button) => ({ ...button, disabled: true })));
      } else {
        setFormButtons(newFormButtons);
      }
      dataGridRef.current?.api.data.getDataOverwritePageCache();
    }
  }, [occupationalRequirementData, isFetching, isEditDisabled]);

  const occupationalRequirementsSettings = useMemo(() => {
    if (occupationalRequirementId && occupationalRequirement && hierarchyTypes) {
      return getOcccupationalRequirementsFormSettings(hierarchyTypes, validateCompetencies);
    }
  }, [occupationalRequirement, hierarchyTypes, validateCompetencies]);

  useImperativeHandle(ref, () => ({
    submitForm: dynamicFormReference.current?.submitForm,
  }));

  const onCloseOccupationalRequirementDetails = () => {
    if (!dynamicFormReference.current?.formIsDirty()) {
      onClose(dynamicFormId.current);
    } else {
      onCloseActiveRecordConfirmationModalRef?.show();
    }
  };

  const saveOccupatinalRequirementData = (
    occupationalRequirementData: OccupationalRequirementsItem,
    successCallback: (response?: unknown) => void
  ) => {
    showPerformingSaveToast();
    if (occupationalRequirementData.id) {
      updateOccupationalRequirement(occupationalRequirementData)
        .then(successCallback)
        .catch(() => showErrorToast());
      dataGridRef.current?.api.data.getDataOverwritePageCache();
    } else {
      hidePerformingSaveToast();
      showErrorToast();
    }
  };

  const onFormSubmit = (data: JsonDataItem) => {
    if (occupationalRequirementId) {
      const patchData = createOccupationalRequirementsPatch(
        data,
        dynamicFormReference.current?.formDirtyFields() || {}
      );

      const occupationalRequirementChanges = {
        ...patchData,
        id: occupationalRequirementId,
      };

      saveOccupatinalRequirementData(occupationalRequirementChanges as OccupationalRequirementsItem, (response) => {
        const convertedResponse = forceAssert<OccupationalRequirementsMutationResponse>(response);
        if (convertedResponse?.data?.result) {
          hidePerformingSaveToast();
          showSaveToast();
        } else if (convertedResponse?.error) {
          hidePerformingSaveToast();
          showErrorToast();
          log('Failed to update Occupatinal Requirements data', { error: convertedResponse.error });
        }
      });
    }
  };

  const showSaveToast = () => {
    if (!panelContextProps?.batchSaveInProgress) {
      showSuccess(t('record-saved'));
    }
    notifySaveSucceeded(dynamicFormId.current);
    closeOnModalSaveRef.current = true;
    if (!occupationalRequirementId || closeOnModalSaveRef.current) {
      onClose(dynamicFormId.current);
    }
  };

  const showErrorToast = () => {
    if (!panelContextProps?.batchSaveInProgress) {
      showError(t('occupational-requirements-details-save-failed'));
    }
    notifySaveFailed(dynamicFormId.current);
  };

  const onFieldChanged = debounce(
    (fieldId: Key, fieldName: string, fieldType: DynamicFormFieldType, fieldValue: unknown) => {
      if (fieldId === 'mandatoryCompetencies' || fieldId === 'desirableCompetencies') {
        const otherField = fieldName === 'mandatoryCompetencies' ? 'desirableCompetencies' : 'mandatoryCompetencies';
        const otherFieldItemsCount = (dynamicFormReference.current?.getData()?.[otherField] as JsonDataItem)?.length;
        if ((fieldValue as Array<DropDownResult>)?.length === 0 && otherFieldItemsCount === 0) {
          setValidateCompetencies(true);
        } else {
          setValidateCompetencies(false);
          if (!otherFieldItemsCount) {
            dynamicFormReference.current?.resetFormField(otherField, []);
          }
        }
      }

      notifyDirty(dynamicFormReference.current);
    },
    500
  );

  return (
    <>
      <div className="flex flex-shrink-0 flex-col">
        <div className="flex">
          <FormTitle title={title} />
          <div className="flex min-w-min justify-end text-gray-1">
            <CloseActiveItemButton onClick={onCloseOccupationalRequirementDetails} />
          </div>
        </div>
      </div>
      <div className="admin-form-default">
        {isFetching && <FormLoading />}
        {occupationalRequirementsSettings && (
          <DynamicForm
            ref={dynamicFormReference}
            dynamicFormId={dynamicFormId.current}
            data={forceAssert<JsonDataItem>(occupationalRequirement) ?? {}}
            settings={occupationalRequirementsSettings}
            dataRetrieval={dataRetrieval}
            readOnly={readOnly}
            buttons={formButtons}
            buttonsLocation={0}
            buttonsPosition={1}
            onSubmit={onFormSubmit}
            onFieldChanged={onFieldChanged}
          />
        )}
      </div>
      <ModalDialog ref={saveModalReference} header={t('save-unsaved-changes')} buttons={saveModalButtons}>
        <div>{t('save-message')}</div>
      </ModalDialog>
    </>
  );
}

export default forwardRef(OccupationalRequirementsDetails);
