import React, { useEffect, useRef, useState, useMemo } from 'react';
import { RecordHierarchyFields } from './hierarchy-field-group.component';
import {
  DropDownResult,
  OdinDataSender,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  TwinColumnSelect,
} from '@myosh/odin-components';
import { cloneDeep } from 'lodash';
import { useLazyHierarchyValuesQuery } from '../../../redux/services/hierarchy';
import { HierarchyFieldGroupAdminProps } from './hierarchy-field-group-admin.component';

interface HierarchyFieldPropsAdmin extends HierarchyFieldGroupAdminProps {
  hierarchyField: RecordHierarchyFields;
  dependentHierarchyTypes?: Record<string, string> | undefined;
}

const HierarchyFieldAdmin = ({
  hierarchyField,
  readOnly,
  value = {},
  errorObject,
  onChange,
}: HierarchyFieldPropsAdmin) => {
  const key = useRef(hierarchyField.caption);
  const subscriberRef = useRef<OdinDataSender<JsonDataWrapper>>();
  const [options, setOptions] = useState<OdinDataRetrievalOptions>();
  const [dropDownValue, setDropDownValue] = useState<Array<DropDownResult>>();

  const [getHierarchyValues] = useLazyHierarchyValuesQuery();

  useEffect(() => {
    if (hierarchyField.id && options) {
      getHierarchyValues({
        page: options.page ?? 1,
        pageSize: options.pageSize ?? 50,
        sort: 'caption',
        filters: { typeId: { value: hierarchyField.id, comparison: '=' }, archived: { value: false, comparison: '=' } },
      })
        .unwrap()
        .then((result) => {
          if (result?.items) {
            subscriberRef.current?.sendData({
              data: result.items.map((hierarchy) => {
                return {
                  text: hierarchy.caption,
                  value: hierarchy.id,
                  originalData: hierarchy,
                };
              }),
              requestId: options.requestId,
            });
            if (result.page === result.availablePages) {
              subscriberRef.current?.sendData();
            }
          } else {
            subscriberRef.current?.sendData();
          }
        })
        .catch(() => subscriberRef.current?.sendData());
    }
  }, [hierarchyField, options]);

  useEffect(() => {
    const hierarchyCaption = key.current;
    if (value) {
      if (value[hierarchyCaption] !== dropDownValue) {
        if (value[hierarchyCaption]?.length > 0) {
          setDropDownValue(value[hierarchyCaption]);
        } else {
          setDropDownValue([]);
        }
      } else {
        value[hierarchyCaption] = [];
      }
    }
  }, [value]);

  const getOptionsData = useMemo(() => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        subscriberRef.current = subscriber;
        setOptions(options);
      },
    };
  }, []);

  const onValueChange = (fieldValue?: Array<DropDownResult>) => {
    if (onChange) {
      const updatedValue = cloneDeep(value);
      updatedValue[key.current] = fieldValue ?? [];
      onChange(updatedValue);
    }
  };

  return (
    <div key={key.current} className="mb-3">
      <TwinColumnSelect
        key={`dd_${key.current}`}
        textField="text"
        valueField="value"
        value={dropDownValue}
        error={errorObject?.message}
        label={key.current}
        data={getOptionsData}
        readOnly={readOnly}
        onChange={onValueChange}
      />
    </div>
  );
};

export default HierarchyFieldAdmin;
