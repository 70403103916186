import {
  ContinuousScrollerComparisonType,
  ContinuousScrollerFilter,
  ContinuousScrollerSort,
  ContinuousScrollerSortDirection,
  DataGridColumnSettings,
  DropDownResult,
  JsonDataItem,
  SearchValueType,
} from '@myosh/odin-components';
import { FieldInfo, ViewColumnType, ViewConfigArchivedType, ViewConfigDateRange } from '../@types/views';
import { isArray, isNumber } from 'lodash';
import { formatToSchemaTimeZoneDateOnly } from './date-util';
import { getOptionsByFieldType } from './data-grid-filters';
import { CombinedFieldType } from '../@types/forms';
import i18n from '../i18n';

// Records filter
export const recordFilterOptions = [
  {
    value: 'false',
    text: i18n.t('active'),
  },
  {
    value: 'true',
    text: i18n.t('archived'),
  },
  {
    value: 'both',
    text: i18n.t('all'),
  },
];

// 'Active' is default when the view config has no value configured
export const defaultRecordFilterOption = recordFilterOptions[0];

export const processRecordFilterForContinuousScroller = (recordFilter: DropDownResult) => {
  return {
    scrollerId: 'archivedStep',
    field: 'archivedStep',
    value: recordFilter.value as SearchValueType,
    comparison: 1 as ContinuousScrollerComparisonType,
  };
};

export const processSortedFiltersForContinuousScroller = (
  columns: DataGridColumnSettings[]
): ContinuousScrollerSort[] => {
  const sortedFieldFilters: ContinuousScrollerSort[] = [];
  for (let i = 0, columnsLength = columns.length; i < columnsLength; i++) {
    if (columns[i].sortColumn) {
      sortedFieldFilters.push({
        scrollerId: columns[i].id,
        field: columns[i].field,
        sortDirection: columns[i].ascending
          ? (0 as ContinuousScrollerSortDirection)
          : (1 as ContinuousScrollerSortDirection),
        order: columns[i].sortSequence !== undefined ? (columns[i].sortSequence as number) + 1 : undefined,
      });
    }
  }
  return sortedFieldFilters;
};

export const resolveRecordFilterConfigValue = (archivedType?: ViewConfigArchivedType) => {
  if ('NO' === archivedType) {
    return recordFilterOptions[0];
  } else if ('YES' === archivedType) {
    return recordFilterOptions[1];
  } else if ('ALL' === archivedType) {
    return recordFilterOptions[2];
  } else {
    return defaultRecordFilterOption;
  }
};

// Last edited filter
export const lastEditedFilterOptions = [
  {
    value: 'thisMonth',
    text: i18n.t('last-edited-this-month'),
  },
  {
    value: 'lastThreeMonths',
    text: i18n.t('last-edited-last-3-months'),
  },
  {
    value: 'lastSixMonths',
    text: i18n.t('last-edited-last-6-months'),
  },
  {
    value: 'thisYear',
    text: i18n.t('last-edited-this-year'),
  },
  {
    value: 'all',
    text: i18n.t('all'),
  },
];

// 'All' is default  when the view config has no value configured
export const defaultLastEditedFilterOption = lastEditedFilterOptions[4];

export const processLastEditedFilterForContinousScroller = (lastEditedFilter: DropDownResult) => {
  return {
    scrollerId: 'lastModifiedDateRange',
    field: 'lastModifiedDateRange',
    value: lastEditedFilter.value as SearchValueType,
    comparison: 1 as ContinuousScrollerComparisonType,
  };
};

export const resolveLastEditedFilterConfigValue = (dateRange?: ViewConfigDateRange) => {
  if ('THIS_MONTH' === dateRange) {
    return lastEditedFilterOptions[0];
  } else if ('LAST_THREE_MONTHS' === dateRange) {
    return lastEditedFilterOptions[1];
  } else if ('LAST_SIX_MONTHS' === dateRange) {
    return lastEditedFilterOptions[2];
  } else if ('THIS_YEAR' === dateRange) {
    return lastEditedFilterOptions[3];
  } else if ('ALL' === dateRange) {
    return lastEditedFilterOptions[4];
  } else {
    return defaultLastEditedFilterOption;
  }
};

// Misc
export const gridCheckboxFilterOptions = [
  { id: 'True', caption: i18n.t('true') },
  { id: 'False', caption: i18n.t('false') },
];

export const getFileExtention = (eventType: string) => {
  switch (eventType) {
    case 'PDF':
      return '.pdf';
    case 'CSV':
      return '.csv';
    default:
      return '.xlsx';
  }
};

export const getVisibleColumnsForFileExport = (columns?: Array<DataGridColumnSettings>): string | undefined => {
  if (columns) {
    return columns
      .filter((column) => column.visible && !column.isIdField)
      .map((column) => {
        if (column.visible && column.customDataProperties) {
          const type = transformColumnEntityTypes(column.customDataProperties.entityType as string);
          const entityId = column.customDataProperties.entityId;

          if (type === 'RECORD_LINK') {
            return `${type}:${entityId}:${transformColumnEntityTypes(
              column?.customDataProperties.linkedEntityType as string
            )}:${column?.customDataProperties.linkedFieldId}`;
          }
          if (type === 'REVERSE_RECORD_LINK') {
            return `RECORD_LINK:${entityId}:${transformColumnEntityTypes(
              column?.customDataProperties.linkedEntityType as string
            )}:${transformLinkedFieldId(column?.customDataProperties.linkedFieldId as number, column?.customDataProperties.linkedEntityType as string)}`;
          }
          if (type === 'QUESTIONNAIRE') {
            return `${type}:${entityId}:${column?.customDataProperties?.questionRecordListFieldTypes}`;
          }
          if (type === 'GRAND_SCORE') {
            return `${type}:${entityId}:${column.customDataProperties?.grandScoreFieldItemType}`;
          }
          return `${type}:${entityId}`;
        }
      })
      .join(',');
  }
};

export const getVisibleColumnsForFileExportAllForms = (columns?: Array<DataGridColumnSettings>): string | undefined => {
  if (columns) {
    return columns
      .filter((column) => column.visible && !column.isIdField)
      .map((column) => {
        if (column.visible && column.customDataProperties) {
          const type = transformColumnEntityTypes(column.customDataProperties.entityType as string);
          switch (type) {
            case 'FIELD':
            case 'WORKFLOW_STEP':
              return `FIELD:"${column.customDataProperties.entityName}"`;
            case 'HIERARCHY_FIELD':
              return `${type}:${column.customDataProperties.entityId}`;
            case 'KEYWORD':
              return `${type}:${column?.customDataProperties.entityPropertyName}`;
            case 'GRAND_SCORE':
              return `${type}:${transformGrandScoreEntityName(column.customDataProperties)}:${column.customDataProperties?.grandScoreFieldItemType}`;
            default:
              break;
          }
        }
      })
      .join(',');
  }
};

const transformGrandScoreEntityName = (customDataProperties: JsonDataItem) => {
  return (customDataProperties?.entityName as string)
    .replace(`- ${customDataProperties?.grandScoreFieldItemTypeName}`, '')
    .trim();
};

const transformColumnEntityTypes = (type: string) => {
  switch (type) {
    case 'HIERARCHY_TYPE':
      return 'HIERARCHY_FIELD';
    default:
      return type;
  }
};

type TemplateKeywords =
  | 'LINK'
  | 'DOCUMENT_NUMBER'
  | 'WORKFLOW_STEP'
  | 'AUTHOR'
  | 'FORM_NAME'
  | 'CREATION_DATE'
  | 'RECORD_VERSION'
  | 'ACCOUNTABLE_PERSON'
  | 'ATTACHMENTS';
//TODO Once https://myosh.atlassian.net/browse/MYOSH-4610 or https://myosh.atlassian.net/browse/MYOSH-4609 are implemented
// drop this lookup implemented as a workaround
const transformLinkedFieldId = (linkedFieldId: number, linkedEntityType?: string): TemplateKeywords | number => {
  if (linkedEntityType === 'KEYWORD') {
    switch (linkedFieldId) {
      case 1:
        return 'LINK';
      case 2:
        return 'DOCUMENT_NUMBER';
      case 3:
        return 'WORKFLOW_STEP';
      case 4:
        return 'AUTHOR';
      case 5:
        return 'FORM_NAME';
      case 6:
        return 'CREATION_DATE';
      case 7:
        return 'RECORD_VERSION';
      case 8:
        return 'ACCOUNTABLE_PERSON';
      case 9:
        return 'ATTACHMENTS';

      default:
        return linkedFieldId;
    }
  }

  return linkedFieldId;
};

// Utility method that determines if dropdowns use caption on dropdown values
// Every dropdown except the ones that use all-forms options endpoint use id instead of caption
const shouldUseCaptionOnDropdownValues = (configColumn: DataGridColumnSettings, fieldInfo?: FieldInfo) => {
  return (
    fieldInfo &&
    fieldInfo.isAllForms &&
    configColumn.customDataProperties &&
    getOptionsByFieldType(configColumn.customDataProperties.fieldType as CombinedFieldType) !== 'PERSON_OPTIONS' &&
    configColumn.customDataProperties.field !== 'moduleForm' &&
    configColumn.customDataProperties.entityType !== 'HIERARCHY_TYPE'
  );
};

export const columnConfigurator = (configColumn: DataGridColumnSettings, fieldInfo?: FieldInfo) => {
  const column: DataGridColumnSettings = { ...configColumn, visible: configColumn.visible ?? true };

  if (configColumn.searchType === 'MultiSelect' || configColumn.searchType === 'SingleSelect') {
    column.dropDownValue = shouldUseCaptionOnDropdownValues(configColumn, fieldInfo) ? 'caption' : 'id';
    column.dropDownText = 'caption';
    column.dropDownArchived = 'archived';
  }

  return column;
};

export const getCurrentlyLoadedAndTotalRecords = (
  currentPageRecordsCount: number,
  totalAmountOfRecords: number,
  pageSize: number,
  page: number
) => {
  const isLastPage = currentPageRecordsCount < pageSize;

  let currentRecordsLoadedCount = 0;
  if (isLastPage && currentPageRecordsCount > 0) {
    currentRecordsLoadedCount = pageSize * (page - 1) + currentPageRecordsCount;
  } else if (currentPageRecordsCount > 0) {
    currentRecordsLoadedCount = currentPageRecordsCount * page;
  } else {
    currentRecordsLoadedCount = totalAmountOfRecords;
  }

  return `${currentRecordsLoadedCount} / ${totalAmountOfRecords}`;
};

// Utility method that determines if id should not be send on payload for multiselect filters
// If the filter uses the all-forms options endpoint it shouldn't send id on payload
const shouldSendIdOnPayload = (
  item: SearchValueType,
  isAllFormsFieldOptions: boolean,
  isAllForms: boolean,
  columnHasFilterSelected: ContinuousScrollerFilter
) => {
  return (
    isNumber(item) &&
    (!isAllForms ||
      !isAllFormsFieldOptions ||
      columnHasFilterSelected.customDataProperties?.field === 'moduleForm' ||
      columnHasFilterSelected.customDataProperties?.entityType === 'HIERARCHY_TYPE')
  );
};

export const transformFilterDataToUpdateView = (
  filterType: ViewColumnType,
  columnHasFilterSelected: ContinuousScrollerFilter,
  isAllForms: boolean,
  schemaTimeZone: string
) => {
  switch (filterType) {
    case 'STRING':
      return { stringFilter: columnHasFilterSelected.value };
    case 'MULTISELECTRANGE':
      if (isArray(columnHasFilterSelected.value)) {
        const isAllFormsFieldOptions =
          isAllForms &&
          getOptionsByFieldType(columnHasFilterSelected.customDataProperties?.fieldType as CombinedFieldType) ===
            'FIELD_OPTIONS';
        return {
          multiselectRangeFilter: columnHasFilterSelected.value.map((item, index) => {
            return {
              id: shouldSendIdOnPayload(item, isAllFormsFieldOptions, isAllForms, columnHasFilterSelected)
                ? item
                : undefined,
              key: columnHasFilterSelected.text
                ? columnHasFilterSelected.text[index] === 'Blank'
                  ? '[Blank]'
                  : columnHasFilterSelected.text[index]
                : '',
              selected: true,
            };
          }),
        };
      }
      break;
    case 'DATE':
      if (isArray(columnHasFilterSelected.value)) {
        return {
          firstDate: formatToSchemaTimeZoneDateOnly(columnHasFilterSelected.value[0] as Date, schemaTimeZone),
          secondDate: formatToSchemaTimeZoneDateOnly(columnHasFilterSelected.value[1] as Date, schemaTimeZone),
        };
      }
      break;
    case 'NUMBER':
    case 'NUMBER_EQUALS':
      if (columnHasFilterSelected.value !== undefined) {
        switch (columnHasFilterSelected.comparison) {
          //Equals
          case 1:
            return {
              columnType: 'NUMBER_EQUALS',
              firstValue: Number(columnHasFilterSelected.value),
            };
          //Between
          case 2:
            if (Array.isArray(columnHasFilterSelected.value) && columnHasFilterSelected.value.length === 2) {
              return {
                columnType: 'NUMBER',
                firstValue: columnHasFilterSelected.value[1],
                secondValue: columnHasFilterSelected.value[0],
              };
            } else {
              return {};
            }
          //Less than
          case 4:
            return {
              columnType: 'NUMBER',
              firstValue: Number(columnHasFilterSelected.value),
            };
          //Greater than
          case 6:
            return {
              columnType: 'NUMBER',
              secondValue: Number(columnHasFilterSelected.value),
            };
        }
      }
      break;
    case 'BOOLEAN':
      return { stringFilter: columnHasFilterSelected.value };
  }
};

export const transformSortedFieldsToUpdateView = (columnHasSortedFilter: ContinuousScrollerSort) => {
  return {
    sortColumn: true,
    ascending: columnHasSortedFilter.sortDirection === 0 ? true : false,
    sortSequence: columnHasSortedFilter.order ? columnHasSortedFilter.order - 1 : undefined,
  };
};
