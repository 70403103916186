import React from 'react';
import { Button } from '@myosh/odin-components';
import { ButtonProps } from '@myosh/odin-components/dist/types/components/button/button';

const PrimaryViewActionButton = (props: ButtonProps) => {
  const { children, ...rest } = props;

  return (
    <Button type="primary" variant="alternative" classNames="grow sm:flex-none my-1" {...rest}>
      {children}
    </Button>
  );
};

export default PrimaryViewActionButton;
