import React, { useCallback, useEffect, useRef, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AssignedRecordItem } from '../@types/records';
import { getTextColor } from '../common/common-functions';
import { useGetMyActivitiesQuery } from '../redux/services/record';
import {
  ContinuousScroller,
  ContinuousScrollerDataLoadingIndicator,
  JsonData,
  JsonDataItem,
  JsonDataWrapper,
  Loader,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import { useUser } from '@myosh/myosh-login';

interface CustomActionScrollProperties {
  showHeader?: boolean;
}
const CustomActionScroll = ({ showHeader = true }: CustomActionScrollProperties) => {
  const [scrollElement, setScrollElement] = useState<Element>();
  const [scrollOptions, setScrollOptions] = useState<OdinDataRetrievalOptions>();
  const [currentRecords, setCurrentRecords] = useState<Array<AssignedRecordItem>>();

  const {
    data: myActivityPosts,
    isLoading,
    isFetching,
    isError,
  } = useGetMyActivitiesQuery(
    {
      page: scrollOptions?.page ?? 1,
      pageSize: scrollOptions?.pageSize ?? 50,
    },
    { skip: scrollOptions === undefined }
  );

  const { t } = useTranslation();
  const scrollerSubsciber = useRef<OdinDataSender<JsonDataWrapper>>();
  const { state } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      //prevent infinite grid scrolling indicator if API returns error
      setCurrentRecords([]);
    } else if (myActivityPosts && !isFetching) {
      setCurrentRecords(myActivityPosts);
    }
  }, [myActivityPosts, isFetching, isError]);

  useEffect(() => {
    if (currentRecords && scrollerSubsciber.current) {
      if (currentRecords.length > 0) {
        scrollerSubsciber.current?.sendData({
          data: currentRecords as unknown as JsonDataItem[],
          requestId: scrollOptions?.requestId,
        });
      } else {
        scrollerSubsciber.current.sendData();
      }
    }
  }, [currentRecords]);

  const actionItemTemplate = useCallback((item: AssignedRecordItem, i: number) => {
    const colorStatusStyles = item.backgroundColor
      ? { backgroundColor: `${item.backgroundColor}`, color: getTextColor(item.backgroundColor) }
      : undefined;

    return (
      <div
        key={i}
        className="mb-2 flex cursor-pointer bg-gray-5 rounded "
        onClick={() => {
          navigate(`/${state.user?.currentSchema}/my-activities`, {
            state: {
              item: item,
            },
          });
        }}
      >
        <div
          className="flex rounded-l h-auto w-1 border border-r-0 border-gray-4 rounded-s-lg"
          style={colorStatusStyles}
        ></div>
        <div className="flex w-full flex-col px-2 py-4 border border-gray-4 border-l-0 rounded-br rounded-tr">
          <h3 className="mb-2">
            <strong>{`${item.status} - ${item.moduleName} #${item.docNo}`}</strong>
          </h3>
          {item.description}
        </div>
      </div>
    );
  }, []);

  const renderItems = (finalData: Array<AssignedRecordItem>) => finalData.map(actionItemTemplate);

  const renderScrollerItems = useCallback((data: JsonData): ReactNode | ReactNode[] => {
    return <div>{renderItems(data as unknown as Array<AssignedRecordItem>)}</div>;
  }, []);

  const dataLoadIndicator = useMemo(() => <ContinuousScrollerDataLoadingIndicator />, []);

  const renderContinuousScroller = useCallback(
    () =>
      scrollElement ? (
        <ContinuousScroller
          dataRetriever={dataRetrieval}
          scrollingElement={scrollElement}
          dataLoadIndicator={dataLoadIndicator}
          className="grow pb-5"
        >
          {renderScrollerItems}
        </ContinuousScroller>
      ) : (
        <></>
      ),
    [scrollElement]
  );

  const onScrollElementCreated = (element: HTMLDivElement) => {
    setScrollElement(element);
  };

  const dataRetrieval = useMemo<OdinDataRetrieval>(() => {
    return {
      getSubscriber: (subscriber) => {
        scrollerSubsciber.current = subscriber;
      },
      getData: async (_subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        setScrollOptions(options);
      },
    };
  }, []);

  const noActivities = useMemo(() => {
    if (scrollOptions && !isLoading && myActivityPosts) {
      return scrollOptions.page === 1 && myActivityPosts?.length === 0;
    }
    return false;
  }, [isLoading, scrollOptions]);

  return (
    <div
      className="flex flex-col overflow-hidden max-sm:w-full sm:w-1/2"
      ref={(element) => element && onScrollElementCreated(element)}
    >
      {showHeader && <h2 className="dark:text-white pb-2 text-3xl font-bold">{t('my-activities')}</h2>}
      {isLoading && (
        <div className="flex grow items-center justify-center">
          <Loader title={t('loading-activities')} />
        </div>
      )}

      <div className="custom-scroll flex pb-3 gap-4 overflow-y-scroll grow" hidden={isLoading}>
        {noActivities ? (
          <div className="flex h-full grow items-center justify-center text-lg font-bold">{t('nothing-assigned')}</div>
        ) : (
          renderContinuousScroller()
        )}
      </div>
    </div>
  );
};

export default CustomActionScroll;
