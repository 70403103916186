import React, { useRef, useState } from 'react';
import {
  DataGrid,
  DataGridCellRendererProps,
  DataGridColumnSettings,
  DataGridSettings,
  DynamicFieldComponentProps,
  JsonData,
  ModalDialog,
  ModalDialogButtonSetting,
  ModalDialogRef,
} from '@myosh/odin-components';
import InvitationActionsCell from './invitation-action-cell';
import { forceAssert } from '../../../common/common-functions';
import { useDeleteRecordCollaboratorMutation, useGetRecordCollaboratorsQuery } from '../../../redux/services/record';
import InviteDialog from '../invite-button-field/invite-modal.component';
import { take } from 'rxjs';
import { useTranslation } from 'react-i18next';
import { showError } from '../../../services/notification.service';

export interface InvitationFieldProps extends DynamicFieldComponentProps<JsonData> {
  recordId?: number;
  permissions?: { read: boolean; edit: boolean };
}

export interface Invitation {
  id: number;
  email: string;
  status: string;
  collaboratorEmailId?: number;
}
export interface CollaboratorsRequestBody {
  recordId: number;
}

function InvitationField({ recordId, permissions, readOnly }: InvitationFieldProps) {
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const invitationEmail = useRef<Invitation | undefined>();
  const continueModalReference = useRef<ModalDialogRef>(null);
  const { t } = useTranslation();

  const [deleteCollaborator, { isLoading }] = useDeleteRecordCollaboratorMutation();

  const removeCollaboratorModalButtons: Array<ModalDialogButtonSetting> = [
    {
      name: 'remove',
      text: t('remove'),
      type: 'primary',
      variant: 'alternative',
      disabled: isLoading,
    },
    {
      name: 'do-not-remove',
      text: t('do-not-remove'),
      type: 'text',
      disabled: isLoading,
    },
  ];

  const { data: recordInvitations } = useGetRecordCollaboratorsQuery(
    { recordId: recordId ?? 0 },
    { skip: recordId === undefined || isNaN(recordId) || permissions?.read === false }
  );

  const showResendInvitationModal = (invitation: Invitation) => {
    invitationEmail.current = invitation;
    setShowInviteDialog(true);
  };

  const showDeleteCollaboratorModal = (collaboratorId: number) => {
    continueModalReference.current
      ?.show()
      ?.pipe(take(1))
      .subscribe((result) => {
        if (result.buttonName === 'remove') {
          deleteCollaborator(collaboratorId)
            .unwrap()
            .catch(() => {
              showError(t('error-removing-collaborator'));
            });
        }
      });
  };

  const onCloseDialog = () => setShowInviteDialog(false);

  const invitationGridColumns: Array<DataGridColumnSettings> = [
    {
      id: 1,
      field: 'email',
      title: t('email'),
      visible: true,
      width: 210,
    },
    {
      id: 2,
      field: 'status',
      title: t('status'),
      visible: true,
      width: 70,
    },
    {
      id: 3,
      field: '',
      title: '',
      visible: !readOnly,
      cellRenderer: 'INVITATION_ACTIONS',
      type: 6,
      disableSort: true,
    },
    {
      id: 0,
      field: 'id',
      title: t('id'),
      visible: false,
      isIdField: true,
    },
  ];

  const gridSettings: DataGridSettings = {
    columns: invitationGridColumns,
    fullHeight: true,
    rowIsClickable: false,
    components: {
      INVITATION_ACTIONS: {
        CellComponent: (props: DataGridCellRendererProps) => {
          return (
            <InvitationActionsCell
              showResendInvitationModal={showResendInvitationModal}
              showDeleteCollaboratorModal={showDeleteCollaboratorModal}
              invitation={{ ...props.rowData } as unknown as Invitation}
            />
          );
        },
      },
    },
  };

  return (
    <>
      {permissions?.read && recordInvitations && recordInvitations?.length > 0 && (
        <>
          <div className="mt-2">
            <DataGrid
              data={forceAssert<JsonData>(recordInvitations) ?? []}
              gridSettings={gridSettings}
              showSettings={false}
            />
          </div>
          <InviteDialog
            invitationEmail={invitationEmail.current}
            recordId={recordId ?? 0}
            visible={showInviteDialog}
            hide={onCloseDialog}
          />
          <ModalDialog
            ref={continueModalReference}
            header={t('contractor-invite-will-be-removed')}
            buttons={removeCollaboratorModalButtons}
          >
            <div>{t('continue-message')}</div>
          </ModalDialog>
        </>
      )}
    </>
  );
}

export default React.memo(InvitationField);
