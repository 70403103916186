import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, DynamicFormRequiredType } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import i18next from '../../../i18n';
import HfPasswordField from '../../fields/password-field/hf-password-field.component';
import { passwordValidationPattern } from '../../admin/user/user-form-settings';
import { useChangePasswordMutation } from '../../../redux/services/user';
import { showError, showSuccess } from '../../../services/notification.service';
import useTimeoutRef from '../../../hooks/use-timeout-ref';
import { CurrentUserPasswordChangeResponse } from '../../../@types/users';

interface ChangePasswordModalProps {
  visible: boolean;
}

export default function ChangePasswordModal({ visible }: ChangePasswordModalProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const modalRef = useRef<HTMLDialogElement>(null);
  const openedRef = useRef(false);

  const { t } = useTranslation();
  const { timeoutRef, clearTimeoutRef } = useTimeoutRef();

  const [changePassword] = useChangePasswordMutation();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    if (visible && modalRef.current && !openedRef.current) {
      openedRef.current = true;
      modalRef.current.showModal();
    }

    return () => {
      modalRef.current?.close();
    };
  }, []);

  const handleCancel = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
  }, []);

  const handleClose = useCallback(() => {
    clearTimeoutRef();

    timeoutRef.current = setTimeout(() => {
      openedRef.current = false;
    }, 600000); // 10 minutes
  }, []);

  const onSubmit = async (data: FieldValues) => {
    try {
      setLoading(true);

      await changePassword({
        oldPassword: data[CURRENT_PASSWORD],
        newPassword: data[NEW_PASSWORD],
        retypeNewPassword: data[CONFIRM_NEW_PASSWORD],
      }).unwrap();

      modalRef.current?.close();
      showSuccess(t('password-change-success'));
    } catch (error) {
      const errorResult = error as CurrentUserPasswordChangeResponse;
      if (
        (errorResult.status === 400 || errorResult.status === 403) &&
        errorResult.data.validation?.responseErrors[0].message
      ) {
        const errorMessages = errorResult.data.validation.responseErrors.map((error) => error.message).join('\n');
        setError(errorMessages);
      } else {
        modalRef.current?.close();
        showError(t('password-change-error'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <dialog
      ref={modalRef}
      onCancel={handleCancel}
      onClose={handleClose}
      className="outline-none rounded-md w-96 backdrop:backdrop-blur-sm"
    >
      <div className="text-base font-medium text-mono-0 pb-2 px-4 pt-3">{t('change-password')}</div>
      <div className="mt-1 block text-xs px-4 text-error">{error}</div>
      <form method="dialog" onSubmit={handleSubmit(onSubmit)} className="px-8 py-5 flex flex-col gap-8">
        <HfPasswordField
          control={control}
          name={CURRENT_PASSWORD}
          label={t(CURRENT_PASSWORD)}
          readOnly={false}
          required={DynamicFormRequiredType.True}
        />
        <HfPasswordField
          control={control}
          name={NEW_PASSWORD}
          label={t(NEW_PASSWORD)}
          readOnly={false}
          required={DynamicFormRequiredType.True}
        />
        <HfPasswordField
          control={control}
          name={CONFIRM_NEW_PASSWORD}
          label={t(CONFIRM_NEW_PASSWORD)}
          readOnly={false}
          required={DynamicFormRequiredType.True}
        />
        <Button type="primary" htmlType="submit" disabled={loading}>
          {t('confirm')}
        </Button>
      </form>
    </dialog>
  );
}

// miscellaneous utils
const CURRENT_PASSWORD = 'current-password';
const NEW_PASSWORD = 'new-password';
const CONFIRM_NEW_PASSWORD = 'confirm-new-password';

const schema = yup.object().shape({
  [CURRENT_PASSWORD]: yup.string().required(i18next.t('required-field', { label: i18next.t(CURRENT_PASSWORD) })),
  [NEW_PASSWORD]: yup
    .string()
    .required(i18next.t('required-field', { label: i18next.t(NEW_PASSWORD) }))
    .matches(passwordValidationPattern, { message: i18next.t('password-validation-message') }),
  [CONFIRM_NEW_PASSWORD]: yup.string().when(NEW_PASSWORD, (value, schema) => {
    return value
      ? schema
          .required(i18next.t('required-field', { label: i18next.t(CONFIRM_NEW_PASSWORD) }))
          .oneOf([yup.ref(NEW_PASSWORD)], i18next.t('confirm-new-password-validation-message'))
      : schema.required(i18next.t('required-field', { label: i18next.t(CONFIRM_NEW_PASSWORD) }));
  }),
});
