import { RiskMatrixData } from '../../../@types/risk-matrix-field';

export interface RiskRatingBaseState {
  riskMatrixCells: Array<RiskMatrixData>;
  riskRatingId?: number;
  displayValue?: string;
  selectedDescription?: string;
  currentDescription?: string;
}

export enum RiskRatingBaseActionType {
  SetRiskMatrixCells,
  UpdateRiskRatingProperties,
  UpdateCurrentDescription,
}

interface RiskRatingBaseReducerAction {
  type: RiskRatingBaseActionType;
  riskMatrixCells?: Array<RiskMatrixData>;
  riskRatingId?: number;
  displayValue?: string;
  selectedDescription?: string;
  currentDescription?: string;
}

export default function reducer(state: RiskRatingBaseState, action: RiskRatingBaseReducerAction): RiskRatingBaseState {
  switch (action.type) {
    case RiskRatingBaseActionType.SetRiskMatrixCells:
      if (action.riskMatrixCells) {
        return {
          ...state,
          riskMatrixCells: action.riskMatrixCells,
        };
      }
      return { ...state };

    case RiskRatingBaseActionType.UpdateRiskRatingProperties:
      if (action.riskRatingId && action.displayValue && action.selectedDescription) {
        return {
          ...state,
          riskRatingId: action.riskRatingId,
          displayValue: action.displayValue,
          selectedDescription: action.selectedDescription,
        };
      }
      return { ...state };

    case RiskRatingBaseActionType.UpdateCurrentDescription:
      if (action.currentDescription) {
        return {
          ...state,
          currentDescription: action.currentDescription,
        };
      }
      return { ...state };

    default:
      return state;
  }
}
