import {
  ContinuousScrollerFilter,
  DataSearchLocation,
  DropDown,
  DropDownChip,
  DropDownNoSelection,
  DropDownRef,
  DropDownResult,
  DropDownSelectionType,
  DropDownWrapperTemplateType,
  DynamicFieldComponentProps,
  DynamicFieldDataProps,
  ModalDialogRef,
  OdinIcon,
  OdinIconSize,
  OdinIconType,
  TwinColumnSelectModal,
  deepEquals,
  isDropDownNoSelection,
  useStateRef,
  useTestTag,
  DropDownFeatures,
} from '@myosh/odin-components';
import { TypeOrArray } from '@myosh/odin-components/dist/types/components/common-interfaces';
import { ActionSectionItem } from '@myosh/odin-components/dist/types/components/fields/drop-down/drop-down-action-section.component';
import { Signal } from '@preact/signals-react';
import cx from 'classnames';
import React, { forwardRef, Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';

export interface ExtendedMultiSelectProps
  extends DynamicFieldComponentProps<TypeOrArray<DropDownResult> | DropDownNoSelection>,
    DynamicFieldDataProps {
  placeholder?: string;
  /**
   * If true this will cause the data to load when the component is created instead of when the drop-down-old list is opened
   * (default: false).
   */
  preloadData?: boolean;
  dropDownStyles?: string;
  filterSignal?: Signal<Array<ContinuousScrollerFilter>>;
  filterLocation?: DataSearchLocation;
  actionItems?: Array<ActionSectionItem>;
  onActionItemChanged?: (item: ActionSectionItem, features: DropDownFeatures) => void;
  shouldUseCreatePortal?: boolean;
  allowDuplicateCall?: boolean;
  onSelectorVisible?: (visible: boolean) => void;
  subType?: string;
}

const ExtendedMultiSelect = (props: ExtendedMultiSelectProps, ref: Ref<DropDownRef>) => {
  const {
    id,
    label,
    textField,
    valueField,
    data,
    value = [],
    readOnly = false,
    required,
    onChange,
    placeholder = 'Type to search..',
    preloadData = false,
    dropDownStyles = '',
    error,
    pageSize = 50,
    filterSignal,
    filterLocation,
    actionItems,
    onActionItemChanged,
    shouldUseCreatePortal = false,
    disabled,
    showAddNewOption,
    allowDuplicateCall,
    onSelectorVisible,
    subType,
  } = props;

  const [valueState, setValueState, valueRef] = useStateRef<TypeOrArray<DropDownResult> | DropDownNoSelection>();
  const [twinColumnVisible, setTwinColumnVisible] = useState(false);

  const modalRef = useRef<ModalDialogRef>(null);

  const tagCreator = useTestTag(label || 'multi-select');

  useEffect(() => {
    if (!deepEquals(value, valueRef.current)) {
      setValueState(value);
    }
  }, [value]);

  const changeHandler = useCallback(
    (dropDownValue?: TypeOrArray<DropDownResult> | DropDownNoSelection) => {
      const finalDropDownValue =
        dropDownValue && !isDropDownNoSelection(dropDownValue)
          ? Array.isArray(dropDownValue)
            ? dropDownValue
            : [dropDownValue]
          : [];

      // This was added to keep the twin column select up to date with the drop-down. I could have created a separate
      // state variable for the twin column select, but that would have meant firing 2 state updates for the other
      // places where valueState was set.
      setValueState(finalDropDownValue);
      onChange?.(finalDropDownValue);
    },
    [onChange]
  );

  const removeValue = useCallback(
    (chipValue: DropDownResult) => {
      if (valueRef.current && Array.isArray(valueRef.current)) {
        const newSelectedValues = valueRef.current.filter((el) => el.value !== chipValue.value);
        setValueState(newSelectedValues);
        onChange?.(newSelectedValues);
      }
    },
    [onChange]
  );

  const twinColumnSelectAddValue = (newValue?: Array<DropDownResult>) => {
    changeHandler(newValue);
  };

  const showDialogTwinColumn = useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setTwinColumnVisible(true);
  }, []);

  const onTwinColumnDialogHidden = useCallback(() => setTwinColumnVisible(false), []);

  const dropDownFinalStyles = useMemo(
    () =>
      cx(dropDownStyles, {
        '!text-gray-3': disabled,
      }),
    [dropDownStyles, error, readOnly, disabled]
  );

  const dropDownWrapperTemplate: DropDownWrapperTemplateType = useCallback(
    (props, children) => {
      valueRef.current = props.selectedValue;

      return (
        <>
          {!readOnly && <div className="pb-1">{children}</div>}
          <div className="flex flex-1 flex-row flex-wrap text-xs">
            {props.selectedValue?.map((value) => (
              <DropDownChip
                key={value.text}
                value={value}
                readOnly={readOnly}
                disabled={disabled}
                onRemove={removeValue}
              />
            ))}
          </div>
        </>
      );
    },
    [readOnly, removeValue, disabled]
  );

  const actionTemplate = useCallback(
    () => (
      <>
        {!readOnly && (
          <div className="border-l" onClick={showDialogTwinColumn} ref={tagCreator('more-icon')}>
            <OdinIcon icon="More" type={OdinIconType.Fill} size={OdinIconSize.Small} />
          </div>
        )}
      </>
    ),
    [readOnly]
  );

  return (
    <>
      <DropDown
        ref={ref}
        id={id}
        textField={textField}
        valueField={valueField}
        label={label}
        placeholder={placeholder}
        selectionType={DropDownSelectionType.Multiple}
        data={data}
        dropDownWrapperTemplate={dropDownWrapperTemplate}
        actionTemplate={actionTemplate}
        value={valueState}
        onChange={changeHandler}
        actionItems={actionItems}
        onActionItemChanged={onActionItemChanged}
        useCheckboxForMultiSelect={false}
        removeOnSelect={true}
        allowSearch={true}
        showAddNewOption={showAddNewOption}
        showValueInSelector={false}
        preloadData={preloadData}
        readOnly={readOnly}
        required={required}
        error={error}
        optionListStyle="!w-full !text-sm"
        selectorStyles="!overflow-hidden !w-full !text-sm !pl-2"
        labelStyles="!font-bold !text-sm"
        dropDownStyles={dropDownFinalStyles}
        pageSize={pageSize}
        filterSignal={filterSignal}
        shouldUseCreatePortal={shouldUseCreatePortal}
        filterLocation={filterLocation}
        disabled={disabled}
        allowDuplicateCall={allowDuplicateCall}
        onSelectorVisible={onSelectorVisible}
      />
      {data && (
        <TwinColumnSelectModal
          addValue={twinColumnSelectAddValue}
          data={data}
          header="Twin Column Select Modal"
          showCloseIcon={true}
          transitionDuration={400}
          minDialogWidth={700}
          minDialogHeight={200}
          maxDialogWidth={1000}
          maxDialogHeight={1000}
          buttonType="submit"
          valueField={valueField}
          textField={textField}
          shouldCheckZIndex={false}
          visible={twinColumnVisible}
          hidden={onTwinColumnDialogHidden}
          id={id}
          ref={modalRef}
          pageSize={pageSize}
          value={value as Array<DropDownResult>}
          showAddNewOption={showAddNewOption}
          subType={subType}
        />
      )}
    </>
  );
};

export default forwardRef(ExtendedMultiSelect);
