import React, { DropDownResult, DynamicFieldComponentProps } from '@myosh/odin-components';
import PersonField from './person-field.component';
import useGetPersonFieldDefaultValue from './use-get-person-field-default-value';
import { useEffect, useRef } from 'react';
import { PersonFieldDependentProps } from '../../../@types/forms';

interface DependentPersonFieldProps extends DynamicFieldComponentProps<DropDownResult>, PersonFieldDependentProps {
  shouldUseCreatePortal?: boolean;
}

const DependentPersonField = ({
  id,
  control,
  onChange,
  shouldUseCreatePortal = false,
  defaultFieldConfiguration,
  resetField,
  value,
  readOnly,
  ...rest
}: DependentPersonFieldProps) => {
  const initialChange = useRef(true);
  //Only user-triggered updates (derived from a parent field or a local update) change the field's value
  const ignoreFirstValue = useRef(true);

  const parentManager = useGetPersonFieldDefaultValue({
    control,
    fieldId: Number(id),
    config: defaultFieldConfiguration,
  });

  useEffect(() => {
    if (!readOnly && parentManager) {
      if (ignoreFirstValue.current) {
        ignoreFirstValue.current = false;
        return;
      }
      handleOnChange(parentManager);
      if (id && resetField && initialChange.current && parentManager.value === value?.value) {
        resetField(String(id), parentManager);
        initialChange.current = false;
      }
    }
  }, [parentManager, readOnly]);

  const handleOnChange = (value?: DropDownResult) => {
    onChange?.(value);
  };

  return (
    <PersonField
      {...rest}
      id={id}
      onChange={handleOnChange}
      shouldUseCreatePortal={shouldUseCreatePortal}
      control={control}
      value={value}
      readOnly={readOnly}
    />
  );
};

export default DependentPersonField;
