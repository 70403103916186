import { useUser } from '@myosh/myosh-login';
import { log as postLog } from '../common/log-util';

// A hook that enables UI components to `log` messages to the API
export const useApiLogger = () => {
  const { state: { user: { currentSchema: schema, prefered_username: username } = {} } = {} } = useUser();

  const log = (message: string, context?: Record<string, unknown>) => {
    postLog({
      username,
      schema,
      message,
      ...(context ?? {}),
    });
  };

  return log;
};
