import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AddonsValidation,
  AddonsWrapper,
  ViewColumn,
  ViewColumnsWrapper,
  ViewConfig,
  ViewConfigsWrapper,
  ViewConfiguration,
} from '../../@types/views';
import { baseQueryWithReAuthAndUrlEncoding, providesList } from './api';
import { transformFormAddons, transformFormViewConfigrations } from '../../common/common-functions';

export const viewApi = createApi({
  reducerPath: 'view',
  baseQuery: baseQueryWithReAuthAndUrlEncoding,
  tagTypes: ['ViewConfig', 'AllFormsViewConfig'],
  endpoints: (builder) => ({
    getViewConfigs: builder.query<Array<ViewConfig>, { formId?: number }>({
      query: (args: { formId: number }) => `/forms/${args.formId}/user-view-configurations`,
      transformResponse: (response: ViewConfigsWrapper) => transformFormViewConfigrations(response),
      providesTags: (result) => providesList(result, 'ViewConfig'),
    }),
    getViewColumns: builder.query<Array<ViewColumn>, { configId?: number }>({
      query: (args: { configId: number }) => `/user-view-configurations/${args.configId}`,
      transformResponse: (response: ViewColumnsWrapper) =>
        response.result?.columnConfigurations?.filter((viewColumn) => viewColumn.draftMarker !== true) ?? [],
    }),
    updateViewConfig: builder.mutation<void, Pick<ViewConfiguration, 'id'> & Partial<ViewConfiguration>>({
      query: ({ id, ...patch }) => ({
        url: `/user-view-configurations/${id}`,
        method: 'PUT',
        body: patch,
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'ViewConfig', id: id },
        { type: 'AllFormsViewConfig', id: id },
      ],
    }),
    deleteViewConfig: builder.mutation<void, number>({
      query: (id) => ({
        url: `/user-view-configurations/${id}`,
        method: 'DELETE',
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),
      invalidatesTags: [
        { type: 'ViewConfig', id: 'LIST' },
        { type: 'AllFormsViewConfig', id: 'LIST' },
      ],
    }),
    addViewConfig: builder.mutation<void, Partial<ViewConfiguration>>({
      query: (arg) => ({
        url: `/user-view-configurations`,
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: [
        { type: 'ViewConfig', id: 'LIST' },
        { type: 'AllFormsViewConfig', id: 'LIST' },
      ],
    }),
    getAllFormsUserViewConfigurations: builder.query<Array<ViewConfig>, number>({
      query: (moduleId: number) => `modules/${moduleId}/all-forms/user-view-configurations`,
      transformResponse: (response: ViewConfigsWrapper) => transformFormViewConfigrations(response),
      providesTags: (result) => providesList(result, 'AllFormsViewConfig'),
    }),
    getFormAddOns: builder.query<Array<AddonsValidation>, number>({
      query: (formId: number) => `/forms/${formId}/add-ons`,
      transformResponse: (response: AddonsWrapper) => transformFormAddons(response?.result) ?? [],
    }),
    getDefaultView: builder.query<Array<ViewColumn>, { formId?: number }>({
      query: (args: { formId: number }) => `/forms/${args.formId}/default-view`,
      transformResponse: (response: ViewColumnsWrapper) =>
        response.result?.columnConfigurations?.filter((viewColumn) => viewColumn.draftMarker !== true) ?? [],
    }),
    getAllFormsDefaultView: builder.query<Array<ViewColumn>, { moduleId?: number }>({
      query: (args: { moduleId: number }) => `/modules/${args.moduleId}/all-forms/default-view`,
      transformResponse: (response: ViewColumnsWrapper) =>
        response.result?.columnConfigurations?.filter((viewColumn) => viewColumn.draftMarker !== true) ?? [],
    }),
  }),
});

export const {
  useGetViewConfigsQuery,
  useGetViewColumnsQuery,
  useUpdateViewConfigMutation,
  useDeleteViewConfigMutation,
  useAddViewConfigMutation,
  useGetAllFormsUserViewConfigurationsQuery,
  useGetFormAddOnsQuery,
  useGetDefaultViewQuery,
  useGetAllFormsDefaultViewQuery,
} = viewApi;
