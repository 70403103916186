import { useEffect, useRef, useState } from 'react';
import { DropDownNoSelection, DropDownResult, isDropDownNoSelection, isDropDownResult } from '@myosh/odin-components';
import { Control, useWatch } from 'react-hook-form';
import { isArray } from 'lodash';
import { DefaultChildValue } from '../../../@types/options';
import { forceAssert } from '../../../common/common-functions';

interface DependentComboboxState {
  showOverlay: boolean;
  dependsOn?: number;
}

const initialState: DependentComboboxState = {
  showOverlay: false,
};

const useDependentCombobox = (
  control: Control,
  parentFieldId: string,
  parentLabel: string,
  onChange?: (value: DropDownResult | Array<DropDownResult> | DropDownNoSelection | undefined) => void,
  isMultiselect = false,
  fieldId?: number
) => {
  const [state, setState] = useState(initialState);
  const dependsOnRef = useRef<number>();
  const noSelectionValueRef = useRef<DropDownNoSelection>({
    noSelectionValue: null,
  });
  const previousValue = useRef<DropDownResult>();

  const targetFieldValue: DropDownResult | DropDownNoSelection | undefined = useWatch({
    control,
    name: parentFieldId,
    disabled: !parentFieldId || !parentLabel,
    exact: true,
  });

  useEffect(() => {
    if (parentFieldId && parentLabel) {
      const resetValue = isMultiselect ? [] : noSelectionValueRef.current;

      if (!targetFieldValue || isDropDownNoSelection(targetFieldValue)) {
        dependsOnRef.current = undefined;
        setState({ showOverlay: true, dependsOn: dependsOnRef.current });
        if (targetFieldValue !== null) {
          previousValue.current = undefined;
          onChange?.(resetValue);
        }
      } else {
        const defaultChildValues = (targetFieldValue as DropDownResult)?.originalData
          ?.defaultChildOptions as DefaultChildValue[];
        const hasDefaultChildValueForSpecificField = defaultChildValues?.some((option) => option.fieldId === fieldId);

        if (defaultChildValues && isArray(defaultChildValues)) {
          dependsOnRef.current = targetFieldValue?.value as number;
          setState({ showOverlay: false, dependsOn: dependsOnRef.current });
          const finalData = defaultChildValues
            .map((item) => {
              return {
                value: item.id,
                text: item.caption,
                archived: item.archived,
                originalData: item,
              };
            })
            .filter((option) => option.originalData.fieldId === fieldId);
          previousValue.current = targetFieldValue;
          onChange?.(forceAssert(isMultiselect ? finalData : finalData[0]));
        } else if (targetFieldValue && (isArray(targetFieldValue) || isDropDownResult(targetFieldValue))) {
          dependsOnRef.current = isArray(targetFieldValue)
            ? (targetFieldValue[0].value as number)
            : (targetFieldValue.value as number);
          setState({ showOverlay: false, dependsOn: dependsOnRef.current });
          if (
            (!defaultChildValues || !hasDefaultChildValueForSpecificField) &&
            previousValue.current?.value !== targetFieldValue?.value
          ) {
            previousValue.current = targetFieldValue;
            onChange?.(resetValue);
          }
        } else if (!defaultChildValues || !hasDefaultChildValueForSpecificField) {
          dependsOnRef.current = undefined;
          setState({ showOverlay: true, dependsOn: dependsOnRef.current });
          previousValue.current = targetFieldValue;
          onChange?.(resetValue);
        } else {
          previousValue.current = targetFieldValue;
        }
      }
    }
  }, [targetFieldValue]);

  return {
    ...state,
  };
};

export default useDependentCombobox;
