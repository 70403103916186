import { OdinController } from '@myosh/odin-components';
import React from 'react';
import OptionGroup, { OptionGroupProps } from './option-group.component';

interface HfOptionGroupProps extends OptionGroupProps {
  name: string;
}

export default function HfOptionGroup({
  control,
  name,
  value,
  onChange,
  textField,
  valueField,
  fieldValueConverter,
  ...rest
}: HfOptionGroupProps) {
  return (
    <OdinController
      name={name}
      control={control}
      onChange={onChange}
      defaultValue={value}
      fieldValueConverter={fieldValueConverter}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <OptionGroup
          {...rest}
          name={name}
          control={control}
          value={value}
          onChange={onChange}
          error={error?.message}
          textField={textField}
          valueField={valueField}
        />
      )}
    />
  );
}
