import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyGetRecordByIdQuery } from '../../redux/services/record';
import { RecordTitle } from '../record/record-title.component';
import useActiveRecordRef from '../../hooks/use-active-record-ref';
import { useAppSelector } from '../../redux/hooks';
import { RecordSaveState } from '../record/record.component';
import { shouldDisplaySchemaConfirmationModal } from '../layout/layout-util';
import { useUser } from '@myosh/myosh-login';
import { selectIsActiveRecordsPanelRendered } from '../../redux/slices/layout';
import { constructRecordTitle } from '../record/record-component-util';
import { showError } from '../../services/notification.service';
import { Module } from '../../@types/modules';
import { DropDownResult } from '@myosh/odin-components';
import { FormPermissionsResults } from '../../@types/form-permissions';
import useFullScreenQueryParam from '../../hooks/use-full-screen-query-param';

interface RecordNavigatorRouteProps {
  id?: string;
  schema?: string;
  module?: Module;
  form?: DropDownResult;
  formPermissions?: FormPermissionsResults;
  replaceWithBasePath?: () => void;
}

function RecordNavigatorRoute({
  id: recordId,
  schema,
  form,
  module,
  formPermissions,
  replaceWithBasePath,
}: RecordNavigatorRouteProps) {
  const { activeRecordReference } = useActiveRecordRef();
  const { t } = useTranslation();
  const isActiveRecordsPanelVisible = useAppSelector(selectIsActiveRecordsPanelRendered);
  const [getRecordById] = useLazyGetRecordByIdQuery();
  const { state: userState } = useUser();
  const { fullScreen } = useFullScreenQueryParam();

  useEffect(() => {
    if (recordId && module && form && activeRecordReference.current && isActiveRecordsPanelVisible && formPermissions) {
      if (recordId.includes('new')) {
        if (formPermissions?.canShowNewRecordButton === false) {
          showError(t('record-create-failed'), { id: recordId });
          replaceWithBasePath?.();
        } else {
          const title = t('form-new-record', { formName: form.text });
          const details = <RecordTitle title={title} />;

          activeRecordReference.current.addActiveRecord('new_1', module?.icon ?? '', details, fullScreen, {
            title,
            recordState: RecordSaveState.New,
            viewId: module.viewId,
            formId: form.value,
            formName: form.text,
            moduleIcon: module?.icon,
          });
        }
      } else if (recordId && !shouldDisplaySchemaConfirmationModal(schema, userState.user?.currentSchema)) {
        // explicitly fetch the record data
        let id: number;
        if (!isNaN((id = parseInt(recordId)))) {
          getRecordById({ id, keyTypeId: true })
            .unwrap()
            .then((data) => {
              const record = data.original;
              const creationDate = record.creationDate ? new Date(record.creationDate) : undefined;
              const title = constructRecordTitle(record.formName, record.docNo, record.status, record.title);
              const details = <RecordTitle title={title} date={creationDate} />;

              activeRecordReference.current?.addActiveRecord(String(recordId), module.icon ?? '', details, fullScreen, {
                title,
                recordId: recordId,
                viewId: module.viewId,
                moduleIcon: module.icon,
                formId: record.formId,
                formName: record.formName,
                docNo: record.docNo,
                status: record.status,
                vikingLinkUrl: record.vikingLinkUrl,
                displayText: record.displayText,
              });
            })
            .catch((error) => {
              if (error?.status === 404 && error?.data?.validation?.errors[0]?.includes('DeletedRecordException')) {
                showError(t('deleted-record-message'), { id: recordId });
              } else {
                showError(t('record-toast-message'), { id: recordId });
              }
            });
        }
      }
    }
  }, [recordId, module, form, isActiveRecordsPanelVisible, formPermissions, replaceWithBasePath]);

  return null;
}

export default RecordNavigatorRoute;
