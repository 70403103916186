import React, { forwardRef, Ref } from 'react';
import dot from 'dot-object';
import { DataGridCellRendererProps, DataGridDataCell } from '@myosh/odin-components';
import { stripHTML } from '../../common/common-functions';

const RichTextAreaDataCell = ({ column, rowData }: DataGridCellRendererProps, forwardRef: Ref<HTMLDivElement>) => {
  const value = (column.valuePath ? dot.pick(column.valuePath, rowData) : '') ?? '';
  const displayValue = stripHTML(value);

  return <DataGridDataCell ref={forwardRef} value={displayValue} width={column.width} />;
};

export default forwardRef(RichTextAreaDataCell);
