import React from 'react';
import { Control } from 'react-hook-form';
import { OdinController } from '@myosh/odin-components';
import RiskRating, { RiskRatingProps } from './risk-rating.component';

interface HfRiskRatingProps extends RiskRatingProps {
  control?: Control;
  name?: string;
}

export default function HfRiskRating({
  control,
  name,
  label,
  value,
  readOnly,
  required,
  onChange,
  displayDescription,
  valueDisplayConfig,
  riskMatrixId,
}: HfRiskRatingProps) {
  return (
    <OdinController
      name={name as string}
      control={control}
      defaultValue={value}
      onChange={onChange}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <RiskRating
          label={label}
          value={value}
          onChange={onChange}
          error={error?.message}
          readOnly={readOnly}
          required={required}
          displayDescription={displayDescription}
          valueDisplayConfig={valueDisplayConfig}
          riskMatrixId={riskMatrixId}
        />
      )}
    />
  );
}
