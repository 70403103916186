import { useState, useEffect } from 'react';
import { batchPromises, makeCancelable } from '../common/promise-util';
import { useLazyGetRecordByIdQuery } from '../redux/services/record';
import { RecordResult, RecordStructure } from '../@types/records';

interface FetchRecordsState {
  isLoading: boolean;
  records: RecordResult[];
}

/**
 * A hook that fetches records by id
 *
 * @param {string | Array<string>} recordIds - a list of records to fetch.
 * @param {boolean} keyTypeId - When `true`, the returned record data keys will be the field IDs, otherwise the data keys will be the field names.
 * Defaults to `false`.
 *
 * @returns {{isLoading: boolean, records: Array<any>}}
 */
const useFetchRecords = (recordIds: string | Array<string>, keyTypeId = false) => {
  const [state, setState] = useState<FetchRecordsState>({ isLoading: false, records: [] });
  const [getRecordById] = useLazyGetRecordByIdQuery();

  const fetchRecords = async (recordIds: Array<string>) => {
    const recordRequests: Array<() => Promise<RecordStructure>> = []; // holds the list of record requests to be made

    recordIds.forEach((_id) =>
      recordRequests.push(() => {
        return getRecordById({ id: parseInt(_id), keyTypeId }).unwrap();
      })
    );

    // fetch records in batches
    const promises = await batchPromises(recordRequests, 10);

    // extract the fulfilled promises
    const fulfilledResponses: Array<RecordStructure> = (
      promises.filter((promise) => promise.status === 'fulfilled') as PromiseFulfilledResult<RecordStructure>[]
    ).map((promise) => promise.value);

    const records: Array<Record<string, unknown>> = [];
    fulfilledResponses.forEach((fulfilledResponse) => fulfilledResponse && records.push(fulfilledResponse.flat));

    return records;
  };

  useEffect(() => {
    const _recordIds = Array.isArray(recordIds) ? recordIds : Array.of(recordIds);
    const fetchRecordsPromise = makeCancelable(fetchRecords(_recordIds));

    setState({
      ...state,
      isLoading: true,
    });

    fetchRecordsPromise.promise
      .then((_records) =>
        setState({
          isLoading: false,
          records: _records as RecordResult[],
        })
      )
      .catch(({ isCanceled }) => {
        if (isCanceled !== true) {
          // only update the state if the promise was not cancelled
          setState({
            ...state,
            isLoading: false,
          });
        }
      });

    return () => {
      fetchRecordsPromise.cancel();
    };
  }, [recordIds]);

  return {
    ...state,
  };
};

export default useFetchRecords;
