import { useMemo } from 'react';
import { useThemeContext } from '../context/theme.context';

const useCustomJoyrideButtonsStyling = () => {
  const theme = useThemeContext();
  const primaryButton = useMemo(() => {
    return {
      backgroundColor: theme['primary-1'],
      color: theme['mono-1'],
      padding: '0.5rem 1.25rem',
      fontSize: '1rem',
      lineHeight: '1.25rem',
      borderRadius: '0.375rem',
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '3rem',
      minHeight: '2.25rem',
      fontWeight: '500',
      transition: 'background-color 0.2s',
      width: '100%',
      margin: '0.25rem 0',
      outline: 'none',
      cursor: 'pointer',
    };
  }, [theme]);

  const textButton = useMemo(() => {
    return {
      backgroundColor: 'transparent',
      color: theme['primary-1'],
      padding: '0.5rem 1.25rem',
      fontSize: '1rem',
      lineHeight: '1.25rem',
      borderRadius: '0.375rem',
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '3rem',
      minHeight: '2.25rem',
      fontWeight: '500',
      transition: 'background-color 0.2s',
      width: '100%',
      margin: '0.25rem 0',
      outline: 'none',
      cursor: 'pointer',
    };
  }, [theme]);

  const closeIconButton = useMemo(() => {
    return {
      color: theme['primary-2'],
      width: 17,
      height: 17,
    };
  }, [theme]);

  return { primaryButton, textButton, closeIconButton };
};

export default useCustomJoyrideButtonsStyling;
