import { OdinController } from '@myosh/odin-components';
import React from 'react';
import { Control } from 'react-hook-form';
import VersionField, { VersionFieldProps } from './version-field.component';

interface HfVersionFieldProps extends VersionFieldProps {
  control?: Control;
  name?: string;
}

function HfVersionField({
  name = 'version',
  control,
  value,
  onChange,
  readOnly,
  required,
  version,
  recordId,
  permissions,
  locationState,
  formId,
}: HfVersionFieldProps) {
  return (
    <OdinController
      name={name}
      control={control}
      defaultValue={value}
      onChange={onChange}
      render={({ field: { value }, fieldState: { error } }) => (
        <VersionField
          name={name}
          readOnly={readOnly}
          required={required}
          error={error?.message}
          value={value}
          version={version}
          recordId={Number(recordId)}
          permissions={permissions}
          locationState={locationState}
          formId={formId}
        />
      )}
    />
  );
}

export default HfVersionField;
