import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { v4 } from 'uuid';
import { OdinDataRetrievalOptions } from '@myosh/odin-components';

export enum JobStatus {
  NotStarted,
  InProgress,
  Success,
  Failed,
}

export interface JobDetails {
  backgroundJobId: number;
  id: string;
  fileName: string;
}

export interface SubmitJobDetails {
  id?: string;
  format: string;
  formId?: string;
  moduleId?: number;
  formName: string;
  columns?: string;
  gridOptions?: OdinDataRetrievalOptions;
  status?: JobStatus;
  fileName?: string;
  fileDownloadUrl?: string;
  completedDate?: string;
}

interface ExportState {
  exportJobDetails?: SubmitJobDetails;
  submittedJobs: Array<SubmitJobDetails>;
}

const initialState: ExportState = {
  submittedJobs: [],
};

const exportSlice = createSlice({
  name: 'exportState',
  initialState,
  reducers: {
    submitExportJob: (state, action: PayloadAction<SubmitJobDetails>) => {
      state.submittedJobs = state.submittedJobs.concat({ id: v4(), status: JobStatus.NotStarted, ...action.payload });
    },
    setExportJobStatus: (state, action: PayloadAction<Partial<SubmitJobDetails>>) => {
      state.submittedJobs = state.submittedJobs.map((job) => {
        if (job.id === action.payload.id) {
          job.status = action.payload.status;
          job.fileName = action.payload.fileName ?? job.fileName;
          job.fileDownloadUrl = action.payload.fileDownloadUrl ?? job.fileDownloadUrl;
          job.completedDate = action.payload.completedDate ?? job.completedDate;
        }

        return job;
      });
    },
    removeExportJob: (state, action: PayloadAction<string>) => {
      state.submittedJobs = state.submittedJobs.filter((job) => job.id !== action.payload);
    },
  },
});

export const { submitExportJob, setExportJobStatus, removeExportJob } = exportSlice.actions;
export const selectExportJobDetails = (state: RootState) => state.exportState.exportJobDetails;
export const selectSubmittedJobs = (state: RootState) => state.exportState.submittedJobs;

export default exportSlice.reducer;
