import React, { useEffect, useState } from 'react';
import { useUser } from '@myosh/myosh-login';
import { Loader } from '@myosh/odin-components';
import { useAddUserToNotificationsGroupMutation, useGetCompanyLogoQuery } from '../../redux/services/user';
import { useAppSelector } from '../../redux/hooks';
import { selectIsActivePanelEmpty } from '../../redux/slices/layout';
import OdinSideNavigation from './components/odin-side-navigation.component';
import useUserSchemas from '../../hooks/use-user-schemas';
import useProfileData from '../../hooks/use-profile-data';
import { Outlet, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import useNavigationItemClickHandler from './hooks/use-click-handler';
import useNavigationItems from './hooks/use-navigation-items';
import ChangePasswordModal from './components/change-password-modal.component';
import OdinHeader from './components/odin-header.component';

const Layout = () => {
  const { state: userState } = useUser();
  const { schemas } = useUserSchemas(userState);
  const isSuperUser = userState.user?.superuser === 'true';
  const [addUserToNotifcationGroup] = useAddUserToNotificationsGroupMutation();

  const [isCollapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();
  const isEmbeddedPage = pathname.includes('embedded');

  const isHidden = useAppSelector(selectIsActivePanelEmpty);
  const { t } = useTranslation();
  const itemClickHandler = useNavigationItemClickHandler(setCollapsed);
  const {
    navigationItems,
    isLoading: isLoadingNavigationItems,
    isError: isNavigationItemsError,
  } = useNavigationItems(itemClickHandler);

  const { profileData: { user: userData } = {}, isLoadingUser } = useProfileData(schemas);

  const { data: companyLogo, isLoading: isLogoLoading } = useGetCompanyLogoQuery(userData?.id ?? 0, {
    skip: !userData || !userData.id || userData.superUser,
  });

  const navigatorWrapperStyling = cx('', {
    'sm:visible sm:ml-52 max-sm:invisible': !isCollapsed,
    'sm:ml-14': isCollapsed,
    'pr-16': !isHidden,
    'pr-4': isHidden,
  });

  // Non-embedded page heights must account for the top bar to prevent text at the bottom of the page from being pushed below the visible area
  const contentStyles = cx('flex-grow pl-1 custom-scroll pt-3 overflow-auto', navigatorWrapperStyling, {
    'h-[calc(100vh-2.5rem)]': !isEmbeddedPage,
    'h-full': isEmbeddedPage,
  });

  const isLoading = isLoadingNavigationItems || isLoadingUser || isLogoLoading;

  useEffect(() => {
    if (!isSuperUser) {
      addUserToNotifcationGroup();
    }
  }, [isSuperUser]);

  return (
    <div className="h-full w-full">
      {isLoading ? (
        <>
          <OdinSideNavigation loading={true} setItemCollapsed={setCollapsed} isItemCollapsed={isCollapsed} items={[]} />
          <div className="flex h-full w-full">
            <div className="mx-auto flex h-full w-full flex-col items-center justify-center">
              <Loader title={t('loading-data')} />
            </div>
          </div>
        </>
      ) : (
        <>
          <OdinSideNavigation
            setItemCollapsed={setCollapsed}
            isItemCollapsed={isCollapsed}
            items={navigationItems}
            companyLogo={{ url: companyLogo, isLoading: isLogoLoading }}
            isNavigationItemsError={isNavigationItemsError}
            hasUserAdminAccess={userData?.hasAdminAccess}
          />
          {!isEmbeddedPage && <OdinHeader isSideNavigationCollaped={isCollapsed} />}
          <div className={contentStyles}>
            <div className="relative mx-auto flex h-full w-full flex-col items-center justify-center overflow-hidden pl-10">
              <Outlet />
            </div>
          </div>

          <ChangePasswordModal visible={Boolean(userData?.passwordChangeRequired)} />
        </>
      )}
    </div>
  );
};

export default Layout;
