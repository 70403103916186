import React from 'react';
import { Control, Controller } from 'react-hook-form';
import SummaryRecordLink, { SummaryRecordLinkProps } from './summary-record-link.component';

interface HfSummaryRecordLinkProps extends SummaryRecordLinkProps {
  control?: Control;
  name?: string;
}

export default function HfSummaryRecordLink({
  control,
  name,
  id,
  label,
  value,
  recordId,
  fieldsToInclude,
  summaryRecordLinkFormFields,
  readOnly,
  required,
}: HfSummaryRecordLinkProps) {
  return (
    <Controller
      name={name as string}
      control={control}
      defaultValue={value}
      render={({ fieldState: { error } }) => (
        <SummaryRecordLink
          id={id}
          label={label}
          value={value}
          summaryRecordLinkFormFields={summaryRecordLinkFormFields}
          recordId={recordId}
          fieldsToInclude={fieldsToInclude}
          error={error?.message}
          readOnly={readOnly}
          required={required}
        />
      )}
    />
  );
}
