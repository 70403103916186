import { useMemo } from 'react';
import { useAddFieldOptionMutation } from '../../../redux/services/field';
import { useAppDispatch } from '../../../redux/hooks';
import {
  JsonDataItem,
  JsonData,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import { getRecordFieldOptions } from '../../record/record-field-options';
import { showError } from '../../../services/notification.service';
import { useTranslation } from 'react-i18next';
import useComboboxFieldOptions from './use-combobox-field-options';
import { ComboboxMultiselectProps } from './combobox-multiselect.component';
import useDependentCombobox from './use-dependent-combobox';

const useSupportComboboxFunctionalities = ({
  control,
  formId,
  lookupType,
  lookupKeyId,
  lookupHierarchyId,
  parentFieldId,
  parentLabel,
  ...rest
}: ComboboxMultiselectProps) => {
  const { showOverlay, dependsOn } = useDependentCombobox(
    control,
    parentFieldId,
    parentLabel,
    rest.onChange,
    rest.isMultiselect,
    rest.id as number
  );
  const { setOptions, onSelectorVisible, subscriberRef, dataRequestedRef, dropDownRef } = useComboboxFieldOptions(
    formId,
    control,
    lookupType,
    lookupKeyId,
    lookupHierarchyId,
    dependsOn
  );
  const [addFieldOption] = useAddFieldOptionMutation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const getOptionsData = useMemo(() => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if ('TWIN_COLUMN_SELECT' === options?.fieldType && options?.getAllData) {
          await getRecordFieldOptions(formId, dispatch, subscriber, options);
        } else if (options?.page === 1 || options?.customProperties?.next !== undefined) {
          dataRequestedRef.current = true;
          subscriberRef.current = subscriber;
          setOptions(options);
        } else {
          subscriber.sendData();
        }
      },
      saveData: (
        data: JsonDataItem | JsonData,
        returnResult: OdinDataSender<JsonDataWrapper>,
        options?: OdinDataRetrievalOptions
      ) => {
        if ('caption' in data && options?.customFieldType === 'ADDNEWMODAL' && options.fieldId) {
          addFieldOption({ caption: data.caption as string, fieldId: Number(options.fieldId) })
            .unwrap()
            .then((response) => {
              if (!Array.isArray(data) && response && 'result' in response) {
                const returnObj = {
                  caption: data.caption,
                  id: response.result,
                };
                returnResult.sendData({
                  data: [returnObj],
                  requestId: options.requestId,
                });
              } else if (Array.isArray(data)) {
                returnResult.sendData({ data, requestId: options.requestId });
              }
            })
            .catch((error) => {
              const errorMessage = error?.data?.validation?.responseErrors[0]?.message;
              const detailedMessage = error?.data?.validation?.errors[0] ?? '';
              showError(errorMessage ?? t('add-new-option-failed', { message: detailedMessage }));
              //Let component know there was an error
              returnResult.sendData({ customProperties: { isError: true, error: detailedMessage } });
            });
        }
      },
    };
  }, []);

  return {
    getOptionsData,
    showOverlay,
    dropDownRef,
    onSelectorVisible,
  };
};

export default useSupportComboboxFunctionalities;
