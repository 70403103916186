export enum DateTimeStyle {
  Short = 'short',
  Medium = 'medium',
  Long = 'long',
  Full = 'full',
}

// User's time zone i.e "Europe/Helsinki"
export const USER_TIME_ZONE = Intl.DateTimeFormat(navigator.language).resolvedOptions().timeZone;

/* Date formatters (user's time zone) */
const DATE_FORMAT_SHORT = Intl.DateTimeFormat(navigator.language, {
  dateStyle: DateTimeStyle.Short,
  timeZone: USER_TIME_ZONE,
});
const DATE_FORMAT_MEDIUM = Intl.DateTimeFormat(navigator.language, {
  dateStyle: DateTimeStyle.Medium,
  timeZone: USER_TIME_ZONE,
});
const DATE_FORMAT_LONG = Intl.DateTimeFormat(navigator.language, {
  dateStyle: DateTimeStyle.Long,
  timeZone: USER_TIME_ZONE,
});
const DATE_FORMAT_FULL = Intl.DateTimeFormat(navigator.language, {
  dateStyle: DateTimeStyle.Full,
  timeZone: USER_TIME_ZONE,
});

/**
 * Format a date for display.
 *
 * @param date the date to format
 * @param dateStyle the date style, 'short' by default
 *
 * @returns a date string formatted based on the users's locale and user's time zone
 */
export function formatDisplayDate(date: Date, dateStyle: DateTimeStyle = DateTimeStyle.Short) {
  if (DateTimeStyle.Short === dateStyle) {
    return DATE_FORMAT_SHORT.format(date);
  } else if (DateTimeStyle.Medium === dateStyle) {
    return DATE_FORMAT_MEDIUM.format(date);
  } else if (DateTimeStyle.Long === dateStyle) {
    return DATE_FORMAT_LONG.format(date);
  } else if (DateTimeStyle.Full === dateStyle) {
    return DATE_FORMAT_FULL.format(date);
  }

  return DATE_FORMAT_SHORT.format(date);
}

/* Time formatters (user's time zone) */
const TIME_FORMAT_SHORT = Intl.DateTimeFormat(navigator.language, {
  timeStyle: DateTimeStyle.Short,
  timeZone: USER_TIME_ZONE,
});
const TIME_FORMAT_MEDIUM = Intl.DateTimeFormat(navigator.language, {
  timeStyle: DateTimeStyle.Medium,
  timeZone: USER_TIME_ZONE,
});
const TIME_FORMAT_LONG = Intl.DateTimeFormat(navigator.language, {
  timeStyle: DateTimeStyle.Long,
  timeZone: USER_TIME_ZONE,
});
const TIME_FORMAT_FULL = Intl.DateTimeFormat(navigator.language, {
  timeStyle: DateTimeStyle.Full,
  timeZone: USER_TIME_ZONE,
});

/**
 * Format a time for display.
 *
 * @param date the date to format
 * @param timeStyle the time style, 'short' by default
 *
 * @returns a time string formatted based on the users's locale and user's time zone
 */
export function formatDisplayTime(date: Date, timeStyle: DateTimeStyle = DateTimeStyle.Short) {
  if (DateTimeStyle.Short === timeStyle) {
    return TIME_FORMAT_SHORT.format(date);
  } else if (DateTimeStyle.Medium === timeStyle) {
    return TIME_FORMAT_MEDIUM.format(date);
  } else if (DateTimeStyle.Long === timeStyle) {
    return TIME_FORMAT_LONG.format(date);
  } else if (DateTimeStyle.Full === timeStyle) {
    return TIME_FORMAT_FULL.format(date);
  }

  return TIME_FORMAT_SHORT.format(date);
}

/* Date formatters (schema time zone) */
let SCHEMA_DATE_FORMAT_YEAR: Intl.DateTimeFormat,
  SCHEMA_DATE_FORMAT_MONTH: Intl.DateTimeFormat,
  SCHEMA_DATE_FORMAT_DAY: Intl.DateTimeFormat;

function _lazyInitSchemaFormatters(timeZone: string) {
  if (!SCHEMA_DATE_FORMAT_YEAR) {
    SCHEMA_DATE_FORMAT_YEAR = Intl.DateTimeFormat(navigator.language, { year: 'numeric', timeZone });
  }
  if (!SCHEMA_DATE_FORMAT_MONTH) {
    SCHEMA_DATE_FORMAT_MONTH = Intl.DateTimeFormat(navigator.language, { month: '2-digit', timeZone });
  }
  if (!SCHEMA_DATE_FORMAT_DAY) {
    SCHEMA_DATE_FORMAT_DAY = Intl.DateTimeFormat(navigator.language, { day: '2-digit', timeZone });
  }
}

/**
 * Format a date to a date only string in the schema time zone.
 *
 * @param date the date to format
 * @param schemaTimeZone the schema time zone
 *
 * @returns a date only string from the given date object in the format: yyyy-mm-dd.
 */
export function formatToSchemaTimeZoneDateOnly(date: Date, schemaTimeZone: string) {
  _lazyInitSchemaFormatters(schemaTimeZone);

  return `${SCHEMA_DATE_FORMAT_YEAR.format(date)}-${SCHEMA_DATE_FORMAT_MONTH.format(date)}-${SCHEMA_DATE_FORMAT_DAY.format(date)}`;
}

/**
 * Format a date to a date only string.
 *
 * @param date the date to format
 *
 * @returns a date only string from the given date object in the format: yyyy-mm-dd.
 */
export function formatToDateOnly(date: Date) {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split('T')[0];
}
