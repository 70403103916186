import React, { useEffect, useRef, useState, useMemo } from 'react';
import cx from 'classnames';
import {
  DataGrid,
  DataGridColumnSettings,
  DataGridDataRowTemplateProps,
  DataGridRowIndicator,
  DataGridSettings,
  DataGridRef,
  JsonDataWrapper,
  Notification,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  RowTemplateType,
  DropDownResult,
  DynamicFormFieldType,
  Link,
} from '@myosh/odin-components';
import {
  dateFormatBasedOnFilter,
  processFieldFilters,
  buildSortedFieldsUrlString,
  buildGlobalHierarchyFiltersUrl,
} from '../../../../common/common-functions';
import useGridSettings from '../../../../hooks/use-grid-settings';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useGetRecordCountQuery, useGetRecordsQuery } from '../../../../redux/services/record';
import {
  getCurrentlyLoadedAndTotalRecords,
  processLastEditedFilterForContinousScroller,
  processRecordFilterForContinuousScroller,
} from '../../../../common/dynamic-page-utils';
import { useFormSettingsQuery, useGetWorkflowStepsQuery } from '../../../../redux/services/api';
import { getDataGridComboboxFilters } from '../../../../common/data-grid-filters';
import { useTranslation } from 'react-i18next';
import useRenderDataGrid from '../../../../hooks/use-render-data-grid';
import { DataGridLoader } from '../../../dynamic-page/data-grid-loader.component';
import { selectedGlobalHierarchies, selectedMatchingOption } from '../../../../redux/slices/global-hierarchies';

const mapHighlightedRows = (highlightedRows: Array<string>) =>
  highlightedRows?.map((id) => {
    return { id: Number(id) };
  });

interface AllRecordsGridProps {
  formId?: number;
  configId?: number;
  defaultGridSettings: DataGridSettings;
  columnConfigurator: (configColumn: DataGridColumnSettings) => DataGridColumnSettings;
  rowIndicator: DataGridRowIndicator;
  onAdded: (id: string) => void;
  highlightedRows: Array<string>;
  selectedRecordsFilter: DropDownResult;
  selectedLastEditedFilter: DropDownResult;
  previewRecordId?: string;
}

// Displays all records that can be linked to the field
export default function AllRecordsGrid({
  formId,
  configId,
  defaultGridSettings,
  columnConfigurator,
  rowIndicator,
  onAdded,
  highlightedRows,
  previewRecordId,
  selectedLastEditedFilter,
  selectedRecordsFilter,
}: AllRecordsGridProps) {
  const [allRecordsGridSetting, setAllRecordsGridSetting] = useState<DataGridSettings>();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const gridRef = useRef<DataGridRef>();
  const gridSubscriber = useRef<OdinDataSender<JsonDataWrapper>>();
  const [gridOptions, setGridOptions] = useState<OdinDataRetrievalOptions>();
  const globalFilters = useAppSelector(selectedGlobalHierarchies);
  const globalFiltersMatchingOption = useAppSelector(selectedMatchingOption);

  const {
    settings: gridSettings,
    error: gridSettingsError,
    loading: gridSettingsLoading,
  } = useGridSettings(defaultGridSettings, columnConfigurator, formId, configId);

  const { data: formSettings } = useFormSettingsQuery(formId ?? 0, {
    skip: formId === undefined || formId <= 0,
  });

  const { data: workflowSteps, isFetching: areWorkflowStepsFetching } = useGetWorkflowStepsQuery(formId!, {
    skip: formId === undefined || formId <= 0,
  });

  const {
    data: recordsData,
    isLoading: isRecordsLoading,
    isFetching: isRecordsFetching,
  } = useGetRecordsQuery(
    {
      keyTypeId: true,
      formId: formId || 0,
      filters: processFieldFilters(gridOptions?.fieldFilters, dateFormatBasedOnFilter),
      globalHierarchyFilters: buildGlobalHierarchyFiltersUrl(globalFilters, globalFiltersMatchingOption),
      sortDirective: buildSortedFieldsUrlString(gridOptions?.sortedFields, gridRef?.current?.api.data.columns),
      page: gridOptions?.page,
      pageSize: gridOptions?.pageSize,
    },
    {
      skip:
        formId === undefined ||
        formId <= 0 ||
        gridOptions === undefined ||
        gridOptions?.page === undefined ||
        gridOptions?.pageSize === undefined ||
        gridSettingsLoading,
    }
  );

  const isRecordsLoaded: boolean = !isRecordsLoading && !isRecordsFetching;

  const {
    data: recordCount,
    isLoading: isRecordsCountLoading,
    isFetching: isRecordsCountFetching,
  } = useGetRecordCountQuery(
    {
      formId: formId || 0,
      filters: processFieldFilters(gridOptions?.fieldFilters, dateFormatBasedOnFilter),
    },
    {
      skip: formId === undefined || formId === 0 || gridOptions === undefined || gridSettingsLoading,
    }
  );

  const isRecordsCountLoaded: boolean = !isRecordsCountLoading && !isRecordsCountFetching;

  const gridData = useMemo<OdinDataRetrieval | undefined>(() => {
    if (!areWorkflowStepsFetching)
      return {
        getSubscriber: (subscriber, fieldType?: DynamicFormFieldType) => {
          if (fieldType !== 'COMBOBOX') {
            gridSubscriber.current = subscriber;
          }
        },
        getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
          if (options?.fieldType === 'COMBOBOX') {
            const selectedForm: DropDownResult = {
              value: formId,
              text: formSettings?.caption as string,
            };
            await getDataGridComboboxFilters(
              subscriber,
              options,
              dispatch,
              false,
              {
                selectedForm,
                workflowSteps,
              },
              formSettings
            );
          } else {
            setGridOptions(options);
          }
        },
      };
  }, [workflowSteps, areWorkflowStepsFetching]);

  const canRenderDataGrid =
    !gridSettingsLoading && !!gridSettings?.columns && !!gridData && !!allRecordsGridSetting?.columns;

  const { renderDataGrid } = useRenderDataGrid(canRenderDataGrid);

  useEffect(() => {
    if (gridRef.current && canRenderDataGrid) {
      gridRef.current.api.filters.addFilter(processRecordFilterForContinuousScroller(selectedRecordsFilter));
    }
  }, [selectedRecordsFilter, canRenderDataGrid]);

  useEffect(() => {
    if (gridRef.current && canRenderDataGrid) {
      gridRef.current.api.filters.addFilter(processLastEditedFilterForContinousScroller(selectedLastEditedFilter));
    }
  }, [selectedLastEditedFilter, canRenderDataGrid]);

  useEffect(() => {
    if (recordsData && isRecordsLoaded && !gridSettingsLoading) {
      if (recordsData.length > 0) {
        gridSubscriber.current?.sendData({
          data: recordsData.map((record) => record.flat),
          requestId: gridOptions?.requestId,
        });
      } else {
        gridSubscriber.current?.sendData();
      }
    }
  }, [recordsData, isRecordsLoaded, gridOptions, gridSettingsLoading]);

  useEffect(() => {
    if (highlightedRows.length > 0) {
      gridRef.current?.api.data.addHighlightedRows(mapHighlightedRows(highlightedRows), true);
    } else {
      gridRef.current?.api.data.removeHighlightedRows();
    }
  }, [highlightedRows]);

  useEffect(() => {
    if (!previewRecordId) {
      gridRef.current?.api.data.removeSelectedRows();
    }
  }, [previewRecordId]);

  useEffect(() => {
    if (!gridSettingsLoading && gridSettings?.columns) {
      setAllRecordsGridSetting({
        ...gridSettings,
        headerRowIndicator,
      });
    }
  }, [gridSettings, gridSettingsLoading]);

  const headerRowIndicator = {
    indicator: <Link classNames="text-xs">{t('add-all')}</Link>,
    visible: false,
  };

  const recordCountDisplayValue = useMemo<string | undefined>(() => {
    if (isRecordsLoaded && isRecordsCountLoaded && recordsData && !gridSettingsLoading) {
      const currentPageRecordsCount = recordsData.length;

      return getCurrentlyLoadedAndTotalRecords(
        currentPageRecordsCount,
        recordCount ?? 0,
        gridOptions?.pageSize ?? 1,
        gridOptions?.page ?? 1
      );
    }
    return undefined;
  }, [isRecordsLoaded, isRecordsCountLoaded, recordsData, gridSettingsLoading]);

  const addLinkedRecord = (id: string) => onAdded(id);

  const selectingRowTemplate: RowTemplateType = (props: DataGridDataRowTemplateProps, children: React.ReactNode) => (
    // eslint-disable-next-line react/prop-types
    <div className="flex" onClick={() => addLinkedRecord((props.data['id'] as number).toString())}>
      {children}
    </div>
  );

  const initializeGridRef = (ref: DataGridRef) => {
    if (!gridRef.current) {
      gridRef.current = ref;

      gridRef.current.api.filters.addFilter(processRecordFilterForContinuousScroller(selectedRecordsFilter));
      gridRef.current.api.filters.addFilter(processLastEditedFilterForContinousScroller(selectedLastEditedFilter));

      if (highlightedRows.length > 0) {
        gridRef.current.api.data.addHighlightedRows(mapHighlightedRows(highlightedRows));
      }
    }
  };

  const containerStyles = cx('flex h-full flex-col', { 'justify-center': gridSettingsError });

  return (
    <div className={containerStyles}>
      {!renderDataGrid && !gridSettingsError && <DataGridLoader />}
      {canRenderDataGrid && (
        <>
          <DataGrid
            ref={(ref) => ref && initializeGridRef(ref)}
            data={gridData}
            gridSettings={allRecordsGridSetting}
            rowIndicator={rowIndicator}
            rowTemplate={selectingRowTemplate}
            showGridMenu={false}
          />
          <div className="px-10 font-semibold mb-5">{recordCountDisplayValue}</div>
        </>
      )}
      {gridSettingsError && (
        <div className="flex justify-center">
          <Notification
            key="configNotification"
            title={t('error-config-title')}
            description={t('error-config-view-description')}
            type="error"
          />
        </div>
      )}
    </div>
  );
}
