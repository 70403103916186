import React, { Ref } from 'react';
import {
  DataGridCellRenderer,
  DataGridCellRendererProps,
  DataGridDataCell,
  DataGridSettings,
  DataSearchLocation,
} from '@myosh/odin-components';
import GrandScoreDataCell, {
  GrandScoreDataProps,
  GrandScoreFieldType,
  transformGrandScoreCellData,
} from '../data-grid-data-cells/grand-score-data-cell';
import HistoryFieldDataCell from '../data-grid-data-cells/history-field-data-cell';
import QuestionnaireDataCell, { transformQuestionnaireCellData } from '../data-grid-data-cells/questionnaire-data-cell';
import RecordlinkDataCell from '../data-grid-data-cells/recordlink-data-cell';
import RichTextAreaDataCell from '../data-grid-data-cells/richtextarea-data-cell';
import RiskRatingDataCell from '../data-grid-data-cells/risk-rating-data-cell';
import ReverseRecordlinkDataCell from '../data-grid-data-cells/reverse-recordlink-data-cell';
import FixedNumberDataCell from '../data-grid-data-cells/fixed-number-data-cell';
import AttachmentsDataCell from '../data-grid-data-cells/attachments-data-cell';
import { get } from 'lodash';
import { formatDisplayDate } from '../../common/date-util';
import { EntitySubType } from '../../@types/linked-records-field';
import { QuestionnaireFieldValue } from '../../@types/questionnaire';

export const defaultGridSettings = {
  columns: [],
  initialPageSize: 50,
  filterLocation: DataSearchLocation.Api,
  initialFieldFilters: [],
  initialSortedFields: [],
  stopRequestsOnNoData: false,
  fullHeight: true,
  autoSizeColumns: true,
  components: {
    RECORDLINK_DATA_CELL: {
      CellComponent: (props: DataGridCellRendererProps) => <RecordlinkDataCell {...props} />,
    },
    REVERSE_RECORDLINK_DATA_CELL: {
      CellComponent: (props: DataGridCellRendererProps) => <ReverseRecordlinkDataCell {...props} />,
    },
    GRAND_SCORE_DATA_CELL: {
      CellComponent: (props: DataGridCellRendererProps, ref: Ref<HTMLDivElement>) => (
        <GrandScoreDataCell {...props} ref={ref} />
      ),
    },
    RISK_RATING_DATA_CELL: {
      CellComponent: (props: DataGridCellRendererProps) => <RiskRatingDataCell {...props} />,
    },
    QUESTIONNAIRE_DATA_CELL: {
      CellComponent: (props: DataGridCellRendererProps, ref: Ref<HTMLDivElement>) => (
        <QuestionnaireDataCell {...props} ref={ref} />
      ),
    },
    HISTORY_FIELD_DATA_CELL: {
      CellComponent: (props: DataGridCellRendererProps, ref: Ref<HTMLDivElement>) => (
        <HistoryFieldDataCell {...props} ref={ref} />
      ),
    },
    RICHTEXTAREA_DATA_CELL: {
      CellComponent: (props: DataGridCellRendererProps, ref: Ref<HTMLDivElement>) => (
        <RichTextAreaDataCell {...props} ref={ref} />
      ),
    },
    ATTACHMENTS_DATA_CELL: {
      CellComponent: (props: DataGridCellRendererProps) => <AttachmentsDataCell {...props} />,
    },
  },
};

export const defaultLinkedRecordGridSetting: DataGridSettings = {
  columns: [],
  fullHeight: true,
  filterLocation: DataSearchLocation.Api,
  stopRequestsOnNoData: true,
  autoSizeColumns: true,
  components: {
    GRAND_SCORE: {
      CellComponent: (props: DataGridCellRendererProps, ref: Ref<HTMLDivElement>) => (
        <FixedNumberDataCell {...props} ref={ref} />
      ),
    },
    QUESTIONNAIRE: {
      CellComponent: (props: DataGridCellRendererProps, ref: Ref<HTMLDivElement>) => (
        <FixedNumberDataCell {...props} ref={ref} />
      ),
    },
  },
};

export const customLinkedRecordsComponents: Record<string, DataGridCellRenderer> = {
  DATEFIELD: {
    CellComponent: ({ column, rowData }: DataGridCellRendererProps) => {
      const value = get(rowData, column.valuePath ?? column.field);
      if (value) {
        return <div className="p-2 truncate">{formatDisplayDate(new Date(String(value)))}</div>;
      }
    },
  },
  RICHTEXTAREA: {
    CellComponent: (props: DataGridCellRendererProps, ref: Ref<HTMLDivElement>) => (
      <RichTextAreaDataCell {...props} ref={ref} />
    ),
  },
  RISKRATING: {
    CellComponent: ({ column, rowData }: DataGridCellRendererProps, ref: Ref<HTMLDivElement>) => {
      const value = get(rowData, column.valuePath ?? '') as Record<string, string>;
      if (value) {
        return (
          <DataGridDataCell value={value?.['displayValue'] ?? ''} width={column.width} ref={ref} allowCellScrollBar />
        );
      }
    },
  },
  QUESTIONNAIRE: {
    CellComponent: ({ column, rowData }: DataGridCellRendererProps) => {
      const questionnaireColumn = column as unknown as Record<string, unknown>;
      const entitySubType = questionnaireColumn['entitySubType'] as EntitySubType;

      const fieldValue = get(rowData, column.valuePath ?? '') as QuestionnaireFieldValue;
      return <>{transformQuestionnaireCellData(entitySubType, fieldValue?.result)}</>;
    },
  },
  GRAND_SCORE: {
    CellComponent: ({ column, rowData }: DataGridCellRendererProps) => {
      const grandScoreColumn = column as unknown as Record<string, unknown>;
      const entitySubType = grandScoreColumn['entitySubType'] as GrandScoreFieldType;

      const fieldValue = get(rowData, column.valuePath ?? '') as GrandScoreDataProps;
      return <>{transformGrandScoreCellData(entitySubType, fieldValue)}</>;
    },
  },
};
