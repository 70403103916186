import React, { useRef } from 'react';
import { DynamicFormRequiredType, HfInput, JsonData } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import { NotificationTypeFieldProps } from './notification-properties-utils';
import { getAllTimezones } from '../../schedule-rules-field/scheduler-rules-utils';
import HfDropDown from '../../custom-drop-downs/hf-drop-down.component';

const SendAfterTimezoneComponent = ({ control, readOnly }: NotificationTypeFieldProps) => {
  const { t } = useTranslation();
  const timezones = useRef<JsonData>(getAllTimezones());

  return (
    <div className="flex flex-row gap-x-2 items-end">
      <HfInput
        control={control}
        name="notificationPropertiesDTO.sendTime"
        id="sendTime"
        type="number"
        className="ml-2"
        label={t('send-after')}
        readOnly={readOnly}
        required={DynamicFormRequiredType.True}
      />
      <HfDropDown
        label={t('select-time-zone')}
        data={timezones.current}
        valueField="value"
        textField="text"
        className="self-end"
        selectorStyles="text-sm"
        id="sendTimeZoneId"
        name="sendTimeZoneId"
        control={control}
        readOnly={readOnly}
        required={DynamicFormRequiredType.True}
      />
    </div>
  );
};

export default SendAfterTimezoneComponent;
