import React from 'react';
import { DropDownResult } from '@myosh/odin-components';
import { useWatch } from 'react-hook-form';
import NotificationSummaryField from './notification-summary-field.component';
import NotificationTriggeredField from './notification-triggered-field.component';
import { NotificationTypeFieldProps } from './notification-properties-utils';
import NotificationTimedField from './notification-timed-field.component';

const NotificationPropsField = ({ control, value, readOnly }: NotificationTypeFieldProps) => {
  //Type drives fields shown in the properties section
  const notificationType: DropDownResult = useWatch({
    control,
    name: 'notificationType',
    exact: true,
  });

  return (
    <div className="flex flex-col">
      {notificationType.value === 'SUMMARY' && (
        <NotificationSummaryField control={control} value={value} readOnly={readOnly} />
      )}
      {notificationType.value === 'TRIGGERED' && (
        <NotificationTriggeredField control={control} value={value} readOnly={readOnly} />
      )}
      {notificationType.value === 'TIMED' && (
        <NotificationTimedField control={control} value={value} readOnly={readOnly} />
      )}
    </div>
  );
};

export default NotificationPropsField;
