// Entity name format of record link columns: `Record Link - Status`, `Action - Brief Description`, `Record Link - Module - Field`
export function extractFieldName(entityName: string) {
  const nameKeyParts = entityName.split('-');
  const totalKeys = nameKeyParts.length;
  return totalKeys > 1 ? nameKeyParts[totalKeys - 1].trim() : '';
}

/*
  Due to the possibility of having "-" in the field name, the `extractFieldName` method does not always yield a correct result.
  Without support from the API, we need to try a few entity name parts starting form the last token and working backwards, in order to resolve a value.

  This method works such that for a given entity property name: "Record Link - Multiselect - Add New - Option", 
  it will construct the following array: ["Option", "Add New - Option", "Multiselect - Add New - Option", "Record Link - Multiselect - Add New - Option"].

  This gives Odin more flexibility in resolving the linked field value.

  See https://myosh.atlassian.net/browse/MYOSH-2107?focusedCommentId=48751 for the investigation.
*/
export function linkedFieldValueKeys(entityName: string) {
  const parts: string[] = entityName.split(' - ');
  const result: string[] = [];

  let currentString = '';
  for (let i = parts.length - 1; i >= 0; i--) {
    if (currentString !== '') {
      currentString = ' - ' + currentString;
    }
    currentString = parts[i] + currentString;
    result.push(currentString);
  }

  return result;
}
