import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useActiveRecordRef from '../../../hooks/use-active-record-ref';
import { useAppSelector } from '../../../redux/hooks';
import { useUser } from '@myosh/myosh-login';
import { shouldDisplaySchemaConfirmationModal } from '../layout-util';
import { selectIsActiveRecordsPanelRendered } from '../../../redux/slices/layout';
import { useTranslation } from 'react-i18next';
import { showInfo } from '../../../services/notification.service';
import { AdminTabs } from '../../../pages/admin/admin-utils';
import useFullScreenQueryParam from '../../../hooks/use-full-screen-query-param';
import { useLazyGetRiskMatrixByIdQuery } from '../../../redux/services/risk-matrix';

function RiskMatrixNavigatorRoute() {
  const { id, schema } = useParams();
  const { activeRecordReference } = useActiveRecordRef();
  const { t } = useTranslation();
  const isActiveRecordsPanelVisible = useAppSelector(selectIsActiveRecordsPanelRendered);
  const [getRiskMatrixById] = useLazyGetRiskMatrixByIdQuery();

  const { state: userState } = useUser();
  const { fullScreen } = useFullScreenQueryParam();

  useEffect(() => {
    if (activeRecordReference.current && isActiveRecordsPanelVisible) {
      if ('new' === id) {
        const title = t('new-riskMatrix');
        const panelCount = activeRecordReference.current.currentRecordCount() ?? 0;
        activeRecordReference.current?.addActiveRecord(`new_${panelCount + 1}`, 'RISK_MATRIX', title, fullScreen, {
          isNewRiskMatrix: true,
          title,
          administrationTab: AdminTabs.RISK_MATRIX,
        });
      } else if (id && !shouldDisplaySchemaConfirmationModal(schema, userState.user?.currentSchema)) {
        let _id: number;
        if (!isNaN((_id = parseInt(id)))) {
          getRiskMatrixById(_id)
            .unwrap()
            .then((riskMatrix) => {
              const title = riskMatrix.caption as string;
              activeRecordReference.current?.addActiveRecord(`RISK_MATRIX-${id}`, 'RISK_MATRIX', title, fullScreen, {
                riskMatrixId: _id,
                originalRecordKey: id,
                title,
                administrationTab: AdminTabs.RISK_MATRIX,
              });
            })
            .catch(() => showInfo(t('riskMatrix-toast-message'), { id }));
        }
      }
    }
  }, [isActiveRecordsPanelVisible]);

  return null;
}

export default RiskMatrixNavigatorRoute;
