import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  IconButton,
  ModalDialog,
  ModalDialogButtonSetting,
  ModalDialogRef,
  OdinIcon,
  OdinIconSize,
  OdinIconType,
  DataGridRef,
  Tooltip,
  useTestTag,
} from '@myosh/odin-components';
import { ActiveRecordContextProps } from '../active-record/active-record.component';
import {
  useDeleteRecordMutation,
  useGetRecordVersionsQuery,
  useLazyExportRecordQuery,
} from '../../redux/services/record';
import { Subject, take, takeUntil } from 'rxjs';
import AuditView from './audit-view.component';
import { QrCodeShare } from './qr-code-share.component';
import { getFileExtention } from '../../common/dynamic-page-utils';
import { getDataGridReference } from '../../services/data-grid.service';
import i18next from '../../i18n';
import cx from 'classnames';
import { useGetFormAddOnsQuery } from '../../redux/services/view';
import useAddonQuery from '../../hooks/use-addon-query';
import { dismiss, showError, showInfo, showSuccess } from '../../services/notification.service';
import useFileGenerationToast from '../../hooks/use-file-generation-toast';
import { useTranslation } from 'react-i18next';
import { dataTag } from '../../common/data-tags';

const deleteModalButtons: Array<ModalDialogButtonSetting> = [
  {
    name: 'delete',
    text: i18next.t('delete'),
    type: 'primary',
  },
  {
    name: 'cancel',
    text: i18next.t('cancel'),
  },
];

interface RecordMenuProps {
  panelContextProps?: ActiveRecordContextProps;
  recordId?: string;
  recordTitle?: string;
  hasDeletePermission: boolean;
  isRecordReadOnly: boolean;
  closeAndRefresh: () => void;
  onClose: (id: string) => void;
  onRefreshRecordClick?: () => void;
  hasViewRecordAuditPermission: boolean;
  formId: number;
  vikingLinkUrl?: string;
}
export interface MenuComponentProps {
  title: string;
  menuElement?: (children: ReactNode) => ReactElement;
  className: string;
  onClick?: () => void;
  icon?: string;
  iconType?: OdinIconType;
  hidden?: boolean;
  subMenuItems?: Array<SubMenuItem>;
  isSeparator?: boolean;
  titleElement?: ReactElement;
}

interface MenuItemProps {
  menu: MenuComponentProps;
  fullScreenMode?: boolean;
}

interface SubmenuItemsProps {
  items: SubMenuItem[];
  subMenuVisibility: boolean;
  fullScreenMode?: boolean;
}
interface SubMenuItem {
  title: string;
  onClick: () => void;
}

export function RecordMenu({
  recordId,
  recordTitle,
  panelContextProps,
  hasDeletePermission: canDelete,
  hasViewRecordAuditPermission: canViewRecordAudit,
  closeAndRefresh,
  onClose,
  onRefreshRecordClick,
  isRecordReadOnly,
  formId,
  vikingLinkUrl,
}: RecordMenuProps) {
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [currentRecordUrl, setCurrentRecordUrl] = useState('');
  const [fullScreenMode, setFullScreenMode] = useState<boolean>();

  const deleteModalReference = useRef<ModalDialogRef>(null);
  const auditModalReference = useRef<ModalDialogRef>(null);
  const destroySubject = useRef(new Subject<void>());
  const dataGridRef = useRef<DataGridRef>();
  const toastId = useRef<string>();

  const params = useAddonQuery(recordId);
  const { t } = useTranslation();

  const [deleteRecord] = useDeleteRecordMutation();
  const [exportRecord, { isFetching: exportingRecordIsFetching, isSuccess, isError }] = useLazyExportRecordQuery();

  useFileGenerationToast(exportingRecordIsFetching, isSuccess, isError);
  const { data: formAddons } = useGetFormAddOnsQuery(formId, {
    skip: formId === undefined,
  });

  const { data: recordVersions } = useGetRecordVersionsQuery({ recordId: Number(recordId) ?? 0 }, { skip: !recordId });
  const activeVersionId = recordVersions && recordVersions[0]?.id;

  useEffect(() => {
    setFullScreenMode(panelContextProps?.fullScreen);
  }, [panelContextProps]);

  useEffect(() => {
    // Initialize value lazily (only when share menu is opened)
    if (showShareMenu === true) {
      setCurrentRecordUrl(window.location.href);
    }
  }, [showShareMenu]);

  useEffect(() => {
    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((gridRef) => {
        if (gridRef) {
          dataGridRef.current = gridRef;
        }
      });

    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
    };
  }, []);

  const tagCreator = useTestTag('record-menu');

  const onDeleteRecord = (recordId?: string, successCallback?: () => void) => {
    if (recordId) {
      const selectedRecordId = parseInt(recordId);
      deleteModalReference.current
        ?.show()
        ?.pipe(take(1))
        .subscribe((result) => {
          if (result.buttonName === 'delete') {
            toastId.current = showInfo(t('performing-delete-message'));
            deleteRecord(selectedRecordId)
              .unwrap()
              .then(successCallback)
              .finally(() => dismiss(toastId.current));
          } else {
            closeAndRefresh();
          }
        });
    }
  };

  const onSuccessfulDeletedRecord = () => {
    showSuccess(t('delete-successful-message'));
    if (recordId) {
      dataGridRef.current?.api.data.removeRowById(Number(recordId));
      onClose(recordId);
    }
  };

  const onAuditButtonClicked = () => {
    auditModalReference.current?.show();
  };

  const onShowShareMenu = () => {
    setShowShareMenu(true);
  };

  const onHideShareMenu = () => {
    setShowShareMenu(false);
  };

  const onCopyRecordUrl = () => {
    navigator.clipboard.writeText(currentRecordUrl);
  };

  const onRefreshClick = () => {
    onRefreshRecordClick?.();
  };

  const handleRecordExport = (eventType: string) => {
    if (recordId) {
      exportRecord({
        recordId: recordId,
        format: eventType,
      })
        .unwrap()
        .then((data) => {
          if (data.action === 'new-tab') {
            window.open(data.fileSource, '_blank');
          } else {
            const fileExtention = getFileExtention(eventType);
            const button = document.createElement('a');
            button.href = data.fileSource;
            button.download = `${recordTitle}${fileExtention}`;
            button.click();
          }
        })
        .catch(() => {
          showError(t('file-generation-failed'));
        });
    }
  };

  const actionDropdownItemStyle = cx('text-sm flex items-center whitespace-nowrap  leading-5  cursor-pointer', {
    'py-1 px-2 hover:bg-primary-4': !fullScreenMode,
  });
  const addOnMenuItems = useMemo(() => {
    const addOns: Array<MenuComponentProps> | undefined =
      params && formAddons
        ? formAddons
            .filter((addOn) => addOn.result.displayType === 'RECORD')
            .map((addOnWrapper) => {
              const addOn = addOnWrapper.result;
              const url = addOn.url + params;
              const link = (children: ReactNode) => (
                <a href={url} key={addOn.caption} rel="noreferrer" target="_blank" className={actionDropdownItemStyle}>
                  {children}
                </a>
              );
              return {
                title: addOn.caption,
                //Hardcoded default icon until we get correct icons from the backend
                icon: addOn.icon ?? 'ExternalLink',
                iconType: OdinIconType.Line,
                menuElement: link,
              } as MenuComponentProps;
            })
        : undefined;

    return addOns;
  }, [formAddons, fullScreenMode, recordId, params, actionDropdownItemStyle]);

  const separator = useMemo(() => {
    if (addOnMenuItems && addOnMenuItems.length > 0) {
      const props: MenuComponentProps = { title: 'separator', className: actionDropdownItemStyle, isSeparator: true };
      return <MenuItem menu={props} fullScreenMode={fullScreenMode} />;
    }
    return undefined;
  }, [addOnMenuItems]);

  const vikingTitleElement = useCallback(
    (children: ReactNode): ReactElement => {
      return (
        <VikingMenuElement vikingLinkUrl={vikingLinkUrl} className={actionDropdownItemStyle}>
          {children}
        </VikingMenuElement>
      );
    },
    [vikingLinkUrl, fullScreenMode]
  );

  const menus: MenuComponentProps[] = [
    {
      title: t('audit-log'),
      className: actionDropdownItemStyle,
      onClick: onAuditButtonClicked,
      icon: 'Time',
      iconType: OdinIconType.Line,
      hidden: !canViewRecordAudit,
    },
    {
      title: t('delete'),
      className: actionDropdownItemStyle,
      onClick: () => onDeleteRecord(recordId, onSuccessfulDeletedRecord),
      icon: 'DeleteBin5',
      iconType: OdinIconType.Line,
      hidden: !canDelete || (activeVersionId ? activeVersionId !== Number(recordId) : false),
    },
    {
      title: t('export'),
      className: actionDropdownItemStyle,
      icon: 'Download2',
      iconType: OdinIconType.Line,
      subMenuItems: [
        {
          title: 'CSV',
          onClick: () => handleRecordExport('CSV'),
        },
        {
          title: 'Excel',
          onClick: () => handleRecordExport('EXCEL'),
        },
        {
          title: 'PDF',
          onClick: () => handleRecordExport('PDF'),
        },
      ],
    },
    {
      title: t('refresh'),
      className: actionDropdownItemStyle,
      onClick: onRefreshClick,
      icon: 'Refresh',
      iconType: OdinIconType.Line,
      hidden: !isRecordReadOnly,
    },
    {
      title: t('share'),
      className: actionDropdownItemStyle,
      onClick: onShowShareMenu,
      icon: 'Share',
      iconType: OdinIconType.Line,
    },
    {
      title: t('viking'),
      className: actionDropdownItemStyle,
      menuElement: vikingTitleElement,
      icon: 'ExternalLink',
      iconType: OdinIconType.Line,
      hidden: !vikingLinkUrl,
    },
  ];

  const menuSyle = cx({
    'z-30 absolute right-0 top-9 hidden w-48 flex-col bg-mono-1 py-1 shadow group-hover:flex ': !fullScreenMode,
    flex: fullScreenMode,
  });

  return (
    <>
      <div className={menuSyle} ref={tagCreator()}>
        {menus.map((menu) => {
          if (menu.hidden) {
            return;
          }

          return <MenuItem key={menu.title} menu={menu} fullScreenMode={fullScreenMode} />;
        })}
        {separator}
        {addOnMenuItems?.map((addOn) => {
          return <MenuItem key={addOn.title} menu={addOn} fullScreenMode={fullScreenMode} />;
        })}
      </div>

      <AuditView ref={auditModalReference} header={`${t('audit-log')} - ` + recordTitle} recordId={recordId} />
      <ModalDialog header={t('qr-code')} visible={showShareMenu} hidden={onHideShareMenu}>
        <QrCodeShare url={currentRecordUrl} onCopy={onCopyRecordUrl} />
      </ModalDialog>
      <ModalDialog ref={deleteModalReference} header={t('delete-modal-header')} buttons={deleteModalButtons}>
        <div>
          {recordVersions && recordVersions.length > 1
            ? t('delete-record-multiple-versions-warning')
            : t('record-delete-message')}
        </div>
      </ModalDialog>
    </>
  );
}

export const MenuItem = ({ menu, fullScreenMode }: MenuItemProps) => {
  const [subMenuVisibility, setSubMenuVisibility] = useState(false);

  const tagCreator = useTestTag(menu.title || '');

  const menuItemStyle = cx({
    'mx-1 h-9 hover:text-primary-2': fullScreenMode,
    'mr-2 ml-1 px-1': !fullScreenMode,
  });

  if (menu.isSeparator) {
    return !fullScreenMode ? <hr key={menu.title} className="m-2" /> : null;
  }

  const getMenuItemContent = () => {
    const tooltip = menu.icon && (
      <Tooltip
        tooltipClassName="bg-mono-1"
        description={menu.title}
        debounceTime={100}
        disabled={!fullScreenMode}
        wrapperClassName={'flex justify-center'}
      >
        <IconButton classNames={menuItemStyle}>
          <OdinIcon
            size={fullScreenMode ? OdinIconSize.Medium : OdinIconSize.ExtraSmall}
            className={fullScreenMode ? 'w-5' : ''}
            type={menu.iconType}
            icon={menu.icon}
          />
        </IconButton>
      </Tooltip>
    );
    const title = !fullScreenMode && (menu.titleElement ?? menu.title);
    const submenus = menu?.subMenuItems && (
      <SubmenuItems items={menu.subMenuItems} subMenuVisibility={subMenuVisibility} fullScreenMode={fullScreenMode} />
    );

    return (
      <>
        {tooltip}
        {title}
        {submenus}
      </>
    );
  };

  const customElement: ReactElement | undefined = menu.menuElement ? menu.menuElement(getMenuItemContent()) : undefined;
  return (
    customElement ?? (
      <div
        key={menu.title}
        className={`${menu.className} ${subMenuVisibility ? 'group relative' : ''}`}
        onClick={menu?.onClick}
        onMouseEnter={() => menu?.subMenuItems && setSubMenuVisibility(true)}
        onMouseLeave={() => menu?.subMenuItems && setSubMenuVisibility(false)}
        ref={tagCreator('menu-item')}
      >
        {getMenuItemContent()}
      </div>
    )
  );
};

const SubmenuItems = ({ items, subMenuVisibility, fullScreenMode }: SubmenuItemsProps) => {
  const submenuItemsStyle = cx({
    'z-20 absolute -top-1 right-48 w-max flex-col bg-mono-1 py-1  shadow': !fullScreenMode,
    hidden: !subMenuVisibility,
    'z-30 absolute right-0 top-9 w-48 flex-col bg-mono-1 py-1  shadow ': fullScreenMode,
  });

  const tagCreator = useTestTag('sub-menu');

  return (
    <div className={submenuItemsStyle} ref={tagCreator()}>
      {items.map((item) => {
        return (
          <div
            key={item.title}
            className="flex cursor-pointer items-center whitespace-nowrap px-2 py-1 text-sm leading-5 hover:bg-primary-4"
            onClick={() => item.onClick()}
            ref={tagCreator(`${dataTag(item.title)}-menu-item`)}
          >
            {item.title}
          </div>
        );
      })}
    </div>
  );
};

interface VikingMenuElementType {
  children: ReactNode;
  vikingLinkUrl?: string;
  className?: string;
}
const VikingMenuElement = ({ children, vikingLinkUrl, className }: VikingMenuElementType) => {
  const testTag = useTestTag('viking');
  return vikingLinkUrl ? (
    <a
      href={vikingLinkUrl}
      target="_blank"
      rel="noreferrer"
      className={className}
      ref={testTag('menu-item')}
      key="viking"
    >
      {children}
    </a>
  ) : (
    <></>
  );
};
