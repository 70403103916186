import React, { useCallback, useMemo } from 'react';
import { ModalDialog, Button } from '@myosh/odin-components';
import { useState, useEffect } from 'react';
import AllUsersGrid from './all-users-grid';
import SelectedUsersGrid from './selected-users-grid';
import { LinkedUser } from '../../../../@types/users';
import { useTranslation } from 'react-i18next';

interface UserRecordLinkSelectingProps {
  title?: string;
  visible: boolean;
  hidden: () => void;
  applyCallback: (value: Array<LinkedUser>) => void;
  value: Array<LinkedUser>;
}

export default function UserRecordLinkSelecting({
  title,
  visible,
  hidden,
  applyCallback,
  value,
}: UserRecordLinkSelectingProps) {
  const [linkedUsers, setLinkedUsers] = useState<Array<LinkedUser>>(value || []);

  const { t } = useTranslation();

  useEffect(() => {
    if (value && Array.isArray(value)) {
      setLinkedUsers(value);
    }
  }, [value]);

  const addLinkedUser = useCallback(
    (addedUser: LinkedUser) => {
      const newAddedUser = linkedUsers.some((user) => user.value === addedUser.value);
      if (!newAddedUser) {
        setLinkedUsers([...linkedUsers, addedUser]);
      }
    },
    [linkedUsers]
  );

  const removeLinkedUser = useCallback(
    (removedUser: LinkedUser) => {
      setLinkedUsers(linkedUsers.filter((user) => user.value !== removedUser.value));
    },
    [linkedUsers]
  );

  const handleApplyChanges = () => {
    applyCallback(linkedUsers);
    hidden();
  };

  const header = title ? <div className="mb-2">{title}</div> : <></>;

  const footer = (
    <div className="flex justify-start">
      <Button type="primary" variant="alternative" onClick={handleApplyChanges}>
        {t('apply')}
      </Button>
    </div>
  );
  const menuGridSettings = useMemo(
    () => [
      {
        label: t('restore-view'),
        key: 'restore',
      },
    ],
    []
  );
  return (
    <ModalDialog header={header} footer={footer} visible={visible} hidden={hidden} fullScreen={true}>
      <div className="flex h-full w-full flex-row gap-5">
        <div className="flex h-full w-full flex-col gap-5">
          <div className="h-1/2">
            <AllUsersGrid highlightedRows={linkedUsers} onAdded={addLinkedUser} menuGridSettings={menuGridSettings} />
          </div>
          <div className="h-1/2">
            <SelectedUsersGrid
              linkedUsers={linkedUsers}
              onRemoved={removeLinkedUser}
              menuGridSettings={menuGridSettings}
            />
          </div>
        </div>
      </div>
    </ModalDialog>
  );
}
