import React from 'react';
import { OdinController } from '@myosh/odin-components';
import LinkField, { LinkFieldProps } from './link-field';
import { Control } from 'react-hook-form';
interface HfLinkFieldProps extends LinkFieldProps {
  control?: Control;
}

function HfLinkField({ name = 'link', control, label, onChange, readOnly, required, target }: HfLinkFieldProps) {
  return (
    <OdinController
      name={name}
      control={control}
      onChange={onChange}
      render={({ field: { ref: fieldRef, onChange, value }, fieldState: { error } }) => (
        <LinkField
          label={label}
          value={value}
          readOnly={readOnly}
          required={required}
          onChange={onChange}
          error={error?.message}
          inputRef={fieldRef}
          target={target}
        />
      )}
    />
  );
}

export default HfLinkField;
