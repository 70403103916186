import React, { useRef } from 'react';
import { HelpComponent, HfCheckbox } from '@myosh/odin-components';
import DateFieldOn from './date-field-on';
import { NotificationTypeFieldProps } from '../property-section/notification-properties-utils';
import TriggerWorkflowSteps from '../property-section/trigger-workflow-steps';
import i18next from '../../../../i18n';
import { useTranslation } from 'react-i18next';
import SendAfterTimezoneComponent from '../property-section/send-after-timezone.component';

interface TriggerDateFieldProps extends NotificationTypeFieldProps {
  showSendOnce?: boolean;
}

const TriggerDateField = ({ control, value, showSendOnce = true, readOnly }: TriggerDateFieldProps) => {
  const tooltip = useRef<string>(sendOnceTooltip());
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-y-2">
      <TriggerWorkflowSteps
        control={control}
        value={value}
        id="notificationPropertiesDTO"
        name="notificationPropertiesDTO"
        readOnly={readOnly}
      />
      <SendAfterTimezoneComponent control={control} readOnly={readOnly} />
      <DateFieldOn control={control} value={value} readOnly={readOnly} />
      {showSendOnce && (
        <div className="odin-relative w-fit pr-5">
          <HfCheckbox
            control={control}
            name="notificationPropertiesDTO.notificationEventDetailDTO.sendOnce"
            label={t('send-once')}
            readOnly={readOnly}
          />
          <HelpComponent className="help-component-default" description={tooltip.current} />
        </div>
      )}
    </div>
  );
};

export default TriggerDateField;

const sendOnceTooltip = (): string => {
  const enabled = i18next.t('send-once-tooltip-enabled');
  const disabled = i18next.t('send-once-tooltip-disabled');
  const example = i18next.t('send-once-tooltip-example');

  return `${enabled} <br> ${disabled} <br> ${example}`;
};
