import { useEffect, useState } from 'react';
import { LookupType } from '../@types/forms';
import { isObject } from 'lodash';
import { Control, useWatch } from 'react-hook-form';
import { HierarchyDropdownValue } from '../@types/hierarchy-fields';

// Extract the IDs when resolving `keyValue`
const resolveKeyValue = (value: unknown): string | undefined => {
  if (Array.isArray(value) && value.length > 0) {
    return value
      .map(resolveKeyValue)
      .filter((val) => val && val.trim().length > 0)
      .join(',');
  } else if (isObject(value)) {
    if ('value' in value) {
      return String(value.value);
    } else if ('id' in value) {
      return String(value.id);
    } else {
      return '';
    }
  } else if (value) {
    return String(value);
  }
};

/**
 * A hook that handles the aspects of resolving the 'lookup type' query parameters
 *
 * @param lookupType the defined lookup type, currently only `WITH_FIELD_KEY` and `WITH_HIERARCHY_FIELD` are supported
 * @param lookupKeyId the 'id' of the form field from which the value should be read.
 * @param lookupHierarchyId the 'id' of the hierarchy from which the value should be read.
 *
 */
const useLookupTypeField = (
  control?: Control,
  lookupType?: LookupType,
  lookupKeyId?: number,
  lookupHierarchyId?: number
) => {
  const [hierarchyFieldId, setHierarchyFieldId] = useState<number>();
  const [keyValue, setKeyValue] = useState<string>();
  const fieldLookupType = 'WITH_FIELD_KEY' === lookupType;
  const hierarchyLookupType = 'WITH_HIERARCHY_FIELD' === lookupType;

  const hierarchiesFieldValue: Record<string, HierarchyDropdownValue> = useWatch({
    control,
    name: 'hierarchyValues',
    exact: true,
    disabled: !hierarchyLookupType || !lookupHierarchyId,
  });

  const fieldValue = useWatch({
    control,
    name: String(lookupKeyId),
    exact: true,
    disabled: !fieldLookupType || !lookupKeyId,
  });

  useEffect(() => {
    if (hierarchyLookupType && lookupHierarchyId && hierarchiesFieldValue) {
      const key = Object.keys(hierarchiesFieldValue).find(
        (key) => hierarchiesFieldValue[key].hierarchyTypeId === lookupHierarchyId
      );

      if (key) {
        const hierarchy: HierarchyDropdownValue = hierarchiesFieldValue[key];
        if (hierarchy.id !== hierarchyFieldId) {
          setHierarchyFieldId(hierarchy.id);
        }
      } else {
        setHierarchyFieldId(undefined);
      }
    }
  }, [hierarchyLookupType, lookupHierarchyId, hierarchiesFieldValue]);

  useEffect(() => {
    if (fieldLookupType && lookupKeyId) {
      setKeyValue(resolveKeyValue(fieldValue));
    }
  }, [fieldLookupType, lookupKeyId, fieldValue]);

  return {
    shouldUseLookup: fieldLookupType || hierarchyLookupType,
    hierarchyFieldId,
    keyValue,
  };
};

export default useLookupTypeField;
