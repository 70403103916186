import React from 'react';
import { Button, FieldLabel, Input } from '@myosh/odin-components';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';

interface QrCodeShareProps {
  url: string;
  onCopy: () => void;
}

export function QrCodeShare({ url, onCopy }: QrCodeShareProps) {
  const { t } = useTranslation();

  return (
    <div className="flex w-96 flex-col items-start justify-center gap-6 bg-mono-1 p-4">
      <QRCode size={200} value={url} level="M" />
      <div className="flex w-full flex-col gap-2">
        <FieldLabel label={t('record-link')} />
        <div className="flex w-full flex-row items-center justify-between">
          <div className="w-64">
            <Input name="record-link" value={url} readOnly />
          </div>
          <Button onClick={onCopy}>{t('copy')}</Button>
        </div>
      </div>
    </div>
  );
}
