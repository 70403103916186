import React, { forwardRef, Ref } from 'react';
import { DataGridCellRendererProps, DataGridDataCell } from '@myosh/odin-components';
import { isNumber } from 'lodash';

function FixedNumberDataCell({ column, rowData }: DataGridCellRendererProps, forwardRef: Ref<HTMLDivElement>) {
  const cellValue = rowData[column.field];

  return (
    <DataGridDataCell
      ref={forwardRef}
      value={isNumber(cellValue) ? Number(cellValue).toFixed(2) : String(cellValue ?? '')}
      width={column.width}
    />
  );
}

export default forwardRef(FixedNumberDataCell);
