import { DynamicFieldComponentProps, JsonDataItem } from '@myosh/odin-components';
import EmailThisModal from './email-this-modal.component';
import React, { useState } from 'react';
import WrapperStyledButtonField from '../../common/wrapper-styled-button-field';

export interface EmailThisButtonProps extends DynamicFieldComponentProps<unknown> {
  buttonCaption?: string;
  recordId?: number;
  saveRecord?: () => void;
  help?: JsonDataItem;
  keepEmailAudit?: boolean;
  includeAttachment?: boolean;
  attachGeneratedDoc?: boolean;
  emailThisDefaultEmailSubject?: string;
  emailThisDefaultEmailBody?: string;
}

function EmailThisButton({ id, recordId, buttonCaption, saveRecord, readOnly, ...rest }: EmailThisButtonProps) {
  const [showEmailModal, setShowEmailModal] = useState(false);

  const onOpenModal = () => {
    if (recordId === undefined) {
      saveRecord?.();
    } else {
      setShowEmailModal(true);
    }
  };
  const onCloseModal = () => setShowEmailModal(false);

  return (
    <>
      <WrapperStyledButtonField
        onClick={onOpenModal}
        disabled={readOnly}
        buttonCaption={buttonCaption}
        icon="MailSend"
      />
      <EmailThisModal
        fieldId={Number(id)}
        recordId={recordId}
        visible={showEmailModal}
        showCloseIcon={true}
        hidden={onCloseModal}
        readOnly={readOnly}
        {...rest}
      ></EmailThisModal>
    </>
  );
}

export default EmailThisButton;
