import React, { useEffect, useRef } from 'react';
import ActiveRecord, {
  ActiveRecordChildRenderProps,
  ActiveRecordRef,
} from '../../active-record/active-record.component';
import { ActiveRecordChildSetting } from '../../active-record/active-record.reducer';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  clearActiveRecordId,
  selectIsActiveRecordsPanelRendered,
  setActiveRecordId,
} from '../../../redux/slices/layout';
import { setActiveRecordReference } from '../../../services/active-record.service';
import ActiveSidePanelItem from './active-side-panel-item.component';
import { useLocation, useNavigate } from 'react-router-dom';
import { FULL_SCREEN_QUERY_PARAM } from '../../../hooks/use-full-screen-query-param';

export default function ActiveSidePanel() {
  const activeRecordPanelRef = useRef<ActiveRecordRef>();

  const dispatch = useAppDispatch();
  const isActiveRecordsPanelVisible = useAppSelector(selectIsActiveRecordsPanelRendered);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const hidden =
    '/home' === pathname ||
    '/dashboard' === pathname ||
    '/viking-analytics' === pathname ||
    pathname.startsWith('/embedded');

  const handleCloseActiveRecordPanel = () => {
    dispatch(clearActiveRecordId());
    let newPath: string = pathname;
    // we use 'pathname' here instead of 'path' as it's points to the correct module/admin section
    if (pathname.indexOf('administration') !== -1) {
      newPath = `${pathname.substring(0, pathname.indexOf('/administration'))}/administration`;
    } else if (pathname.indexOf('/records') !== -1) {
      newPath = pathname.substring(0, pathname.indexOf('/records'));
    }

    navigate(newPath, { state: null });
  };

  const handleActiveRecordChanged = (childSetting: ActiveRecordChildSetting) => {
    dispatch(setActiveRecordId(Number(childSetting.key)));
    const path = window.location.pathname;
    const shouldActiveRecordUrlChange = !path.endsWith(childSetting.key);

    if (isActiveRecordsPanelVisible && shouldActiveRecordUrlChange) {
      const recordSettings = childSetting.extraSettings as Record<string, unknown>;
      let newPath: string;

      if (recordSettings.administrationTab) {
        if (path.indexOf('/modules') !== -1) {
          newPath = path.substring(0, path.indexOf('/modules'));
        } else {
          newPath = path.substring(0, path.indexOf('/administration'));
        }
        const recordKey = recordSettings.originalRecordKey ?? childSetting.key;
        newPath = `${newPath}/administration/${recordSettings.administrationTab}/${recordKey}`;
      } else {
        newPath = '';
        if (path.indexOf('/modules') !== -1) {
          newPath = path.substring(0, path.indexOf('/modules'));
        } else if (path.indexOf('/my-activities') !== -1) {
          newPath = path.substring(0, path.indexOf('/my-activities'));
        } else if (path.indexOf('/administration') !== -1) {
          newPath = path.substring(0, path.indexOf('/administration'));
        }
        newPath = `${newPath}/modules/${recordSettings?.viewId}/forms/${recordSettings?.formId}/records/${childSetting.key}`;
      }

      if (activeRecordPanelRef.current?.isFullScreen() === true) {
        newPath += `?${FULL_SCREEN_QUERY_PARAM}=true`;
      }

      window.history.replaceState(null, '', newPath);
    }
  };

  const handleFullScreenChanged = (fullScreen: boolean) => {
    if (isActiveRecordsPanelVisible) {
      const url = new URL(window.location.href);

      if (fullScreen === true) {
        url.searchParams.set(FULL_SCREEN_QUERY_PARAM, 'true');
      } else {
        url.searchParams.delete(FULL_SCREEN_QUERY_PARAM);
      }

      window.history.replaceState(null, '', url);
    }
  };

  const onActiveRecordRefCreated = (ref: ActiveRecordRef) => {
    activeRecordPanelRef.current = ref;
    setActiveRecordReference(ref);
  };

  useEffect(() => {
    return () => {
      setActiveRecordReference(null);
      dispatch(clearActiveRecordId());
    };
  }, []);

  return (
    <ActiveRecord
      isCollapsed={true}
      ref={(arRef) => arRef && onActiveRecordRefCreated(arRef)}
      onClose={handleCloseActiveRecordPanel}
      onActiveRecordChanged={handleActiveRecordChanged}
      onLastActiveRecordClosed={handleCloseActiveRecordPanel}
      onFullScreenChanged={handleFullScreenChanged}
      hidden={hidden}
    >
      {(props: ActiveRecordChildRenderProps) => <ActiveSidePanelItem {...props} />}
    </ActiveRecord>
  );
}
