import {
  CheckboxChangeEventType,
  DataSearchLocation,
  DynamicFieldDataProps,
  DynamicFormField,
  DynamicFormRequiredType,
  DynamicFormSettings,
  fieldValidationErrorMessage,
  VisibilityCondition,
} from '@myosh/odin-components';
import { forceAssert } from '../../../common/common-functions';
import { UserFields } from '../../../common/user-config';
import { HfHierarchyField } from '../../fields/hierarchy/hf-hierarchy-field.component';
import i18next from '../../../i18n';
import * as yup from 'yup';
import { HierarchyType } from '../../../@types/hierarchy-fields';
import HfPasswordField from '../../fields/password-field/hf-password-field.component';
import { HFUserPictureField } from '../../fields/user-picture/hf-user-picture-field.component';
import HfDropDownMultiSelect from '../../fields/custom-drop-downs/hf-drop-down-multi-select.component';
import HfDropDown from '../../fields/custom-drop-downs/hf-drop-down.component';

const dataSettings: DynamicFieldDataProps = {
  valueField: 'value',
  textField: 'text',
};

const userFormVisibilityCondition: Array<VisibilityCondition> = [
  {
    id: 'loginRequiredVisibilityCondition',
    sourceFieldId: UserFields.loginRequired,
    fieldVisibilityType: 'FIELD',
    targetValueType: 'BOOLEAN',
    targetValue: true,
    negation: false,
  },
];
const ssoUserVisibilityCondition: Array<VisibilityCondition> = [
  {
    id: 'ssoUserVisibilityCondition',
    sourceFieldId: UserFields.ssoUser,
    fieldVisibilityType: 'FIELD',
    targetValueType: 'BOOLEAN',
    targetValue: true,
    negation: true,
  },
];
/**
 * This regular expression is used to validate passwords based on the following rules:
 * the password should have between 8 and 40 characters, contain both uppercase and lowercase letters, contain at least one digit.
 */
export const passwordValidationPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,40}$/;

export const getUserFormSettings = (
  isExternal: boolean,
  userPermission?: boolean,
  existingUser?: boolean,
  loginRequiredValue?: boolean,
  hierarchyTypes?: HierarchyType[],
  isSettingsView = false,
  userId?: number,
  isVikingAnalyticsEnabled?: boolean,
  hasSystemHostName?: boolean
): DynamicFormSettings => {
  return {
    id: 0,
    caption: '',
    fields: [
      {
        id: 100,
        fieldType: 'GROUP',
        fieldName: 'Account Details',
        caption: i18next.t('account-details'),
        position: 0,
        startOpen: true,
        permissions: { read: true, edit: isSettingsView || userPermission },
        fields: [
          {
            id: UserFields.firstName,
            fieldType: 'TEXTFIELD',
            fieldName: UserFields.firstName,
            caption: i18next.t('first-name'),
            required: DynamicFormRequiredType.True,
            position: 0,
            dynamicProperties: { disabled: !userPermission },
          },
          {
            id: UserFields.lastName,
            fieldType: 'TEXTFIELD',
            fieldName: UserFields.lastName,
            caption: i18next.t('last-name'),
            required: DynamicFormRequiredType.True,
            position: 1,
            dynamicProperties: { disabled: !userPermission },
          },
          {
            id: UserFields.email,
            fieldType: 'TEXTFIELD',
            fieldName: UserFields.email,
            caption: i18next.t('email'),
            required: loginRequiredValue === true ? DynamicFormRequiredType.True : DynamicFormRequiredType.False,
            position: 2,
            dynamicProperties: {
              disabled: !userPermission,
            },
            validationFunc: emailValidation(i18next.t('email')),
          },
          {
            id: UserFields.userId,
            fieldType: 'TEXTFIELD',
            fieldName: UserFields.userId,
            caption: i18next.t('username'),
            required: loginRequiredValue === true ? DynamicFormRequiredType.True : DynamicFormRequiredType.False,
            position: 3,
            dynamicProperties: { disabled: !userPermission },
          },
          {
            id: UserFields.employeeId,
            fieldType: 'TEXTFIELD',
            fieldName: UserFields.employeeId,
            caption: i18next.t('employee-id'),
            position: 4,
            dynamicProperties: { disabled: !userPermission },
          },
          {
            id: UserFields.phone,
            fieldType: 'TEXTFIELD',
            fieldName: UserFields.phone,
            caption: i18next.t('phone'),
            position: 5,
            dynamicProperties: { disabled: !userPermission },
          },
          {
            id: UserFields.occupation,
            fieldType: 'COMBOBOX',
            fieldName: UserFields.occupation,
            caption: i18next.t('occupation'),
            position: 6,
            dataSettings: dataSettings,
            dynamicProperties: { disabled: !userPermission },
            hidden: isSettingsView,
          },
          {
            id: UserFields.secondaryOccupations,
            fieldType: 'DROPDOWNMULTISELECT',
            fieldName: UserFields.secondaryOccupations,
            caption: i18next.t('secondary-occupation'),
            position: 7,
            dataSettings: dataSettings,
            dynamicProperties: { disabled: !userPermission },
            hidden: isSettingsView,
          },
          {
            id: UserFields.localeId,
            fieldType: 'COMBOBOX',
            fieldName: UserFields.localeId,
            caption: i18next.t('locale-id'),
            required: DynamicFormRequiredType.True,
            position: 8,
            dataSettings: dataSettings,
            dynamicProperties: { disabled: !userPermission },
          },
          {
            id: UserFields.affiliation,
            fieldType: 'COMBOBOX',
            fieldName: UserFields.affiliation,
            caption: i18next.t('affiliation'),
            position: 9,
            dataSettings: dataSettings,
            dynamicProperties: { disabled: !userPermission },
          },
          {
            id: UserFields.manager,
            fieldType: 'COMBOBOX',
            fieldName: UserFields.manager,
            caption: i18next.t('manager'),
            position: 10,
            dataSettings: dataSettings,
            dynamicProperties: { disabled: !userPermission, filterLocation: DataSearchLocation.Api },
          },
          {
            id: UserFields.loginRequired,
            fieldType: 'CHECKBOX',
            fieldName: UserFields.loginRequired,
            caption: i18next.t('login-required'),
            position: 11,
            dynamicProperties: { disabled: !userPermission },
          },
          {
            id: UserFields.ssoUser,
            fieldType: 'CHECKBOX',
            fieldName: UserFields.ssoUser,
            caption: i18next.t('sso-user'),
            position: 12,
            hidden: isSettingsView && !hasSystemHostName,
            permissions: { read: !isSettingsView && hasSystemHostName },
          },
          {
            id: UserFields.oldPassword,
            fieldType: 'CUSTOM',
            customFieldType: 'PASSWORDFIELD',
            fieldName: UserFields.oldPassword,
            caption: i18next.t('old-password'),
            position: 13,
            required: DynamicFormRequiredType.True,
            hidden: isSettingsView && !userPermission ? false : true,
          },
          {
            id: UserFields.password,
            fieldType: 'CUSTOM',
            customFieldType: 'PASSWORDFIELD',
            fieldName: UserFields.password,
            caption: i18next.t('password'),
            position: 14,
            required: existingUser ? DynamicFormRequiredType.Partial : DynamicFormRequiredType.True,
            validationFunc: passwordValidation(i18next.t('password'), false, existingUser),
          },
          {
            id: UserFields.retypePassword,
            fieldType: 'CUSTOM',
            customFieldType: 'PASSWORDFIELD',
            fieldName: UserFields.retypePassword,
            caption: i18next.t('retype-password'),
            position: 15,
            required: existingUser ? DynamicFormRequiredType.Partial : DynamicFormRequiredType.True,
            validationFunc: passwordValidation(i18next.t('retype-password'), true, existingUser),
          },
          {
            id: UserFields.groups,
            fieldType: 'DROPDOWNMULTISELECT',
            fieldName: UserFields.groups,
            caption: i18next.t('groups'),
            position: 16,
            dataSettings: dataSettings,
            required: DynamicFormRequiredType.True,
            hidden: isSettingsView,
          },
          {
            id: UserFields.vikingAnalytics,
            fieldType: 'CHECKBOX',
            fieldName: UserFields.vikingAnalytics,
            caption: i18next.t('viking-analytics'),
            position: 17,
            dynamicProperties: { disabled: !userPermission },
            hidden: !isVikingAnalyticsEnabled,
          },
          {
            id: UserFields.vikingAnalyticsContributor,
            fieldType: 'CHECKBOX',
            fieldName: UserFields.vikingAnalyticsContributor,
            caption: i18next.t('viking-analytics-contributor'),
            position: 18,
            dynamicProperties: { disabled: !userPermission },
            hidden: !isVikingAnalyticsEnabled,
          },
          {
            id: UserFields.avatar,
            fieldType: 'CUSTOM',
            customFieldType: 'AVATAR',
            fieldName: UserFields.avatar,
            caption: i18next.t('user-picture'),
            position: 19,
            dynamicProperties: {
              userId: userId,
              disabled: !isSettingsView ?? !userPermission,
              label: i18next.t('user-picture'),
            },
          },
        ],
      },
      {
        id: 'hierarchies',
        fieldType: 'CUSTOM',
        customFieldType: 'HIERARCHY',
        caption: i18next.t('hierarchy'),
        fieldName: 'hierarchies',
        required: DynamicFormRequiredType.True,
        position: 1,
        permissions: { read: true, edit: userPermission },
        dynamicProperties: {
          hierarchyTypes: hierarchyTypes,
          isUserHierarchy: true,
          isExternal: isExternal,
        },
        wrapInGroup: true,
        customGroupTitle: i18next.t('hierarchy'),
        hidden: isSettingsView,
        startOpen: true,
        validationFunc: userHierarchyFieldValidation(hierarchyTypes, isExternal),
      },
    ],

    visibilityConditions: [
      {
        id: 'oldPassword',
        type: 'CUSTOM',
        groups: [
          {
            conditions: isSettingsView && !userPermission ? userFormVisibilityCondition : [],
          },
        ],
      },
      {
        id: 'password',
        type: 'CUSTOM',
        groups: [
          {
            conditions: ssoUserVisibilityCondition,
          },
        ],
      },
      {
        id: 'retypePassword',
        type: 'CUSTOM',
        groups: [
          {
            conditions: ssoUserVisibilityCondition,
          },
        ],
      },
      {
        id: 'groups',
        type: 'DROPDOWNMULTISELECT',
        groups: [
          {
            conditions: isSettingsView ? [] : userFormVisibilityCondition,
          },
        ],
      },
      {
        id: 'ssoUser',
        type: 'CHECKBOX',
        groups: [
          {
            conditions: userFormVisibilityCondition,
          },
        ],
      },
    ],
    fieldValueConverters: {
      CHECKBOX: (value) => {
        const convertedValue = forceAssert<CheckboxChangeEventType>(value);
        return convertedValue.checked;
      },
    },
    customFieldComponents: {
      HIERARCHY: HfHierarchyField,
      PASSWORDFIELD: HfPasswordField,
      AVATAR: HFUserPictureField,
      DROPDOWNMULTISELECT: HfDropDownMultiSelect,
      COMBOBOX: HfDropDown,
    },
  };
};

export const getUserFormGroups = (
  isExternal: boolean,
  userPermission?: boolean,
  existingUser?: boolean,
  loginRequiredValue?: boolean
): Array<DynamicFormField> => {
  return getUserFormSettings(isExternal, userPermission, existingUser, loginRequiredValue).fields.filter(
    (field) => field.fieldType === 'GROUP' || (field.fieldType === 'CUSTOM' && field.customFieldType === 'HIERARCHY')
  );
};

const userHierarchyFieldValidation = (hierarchyTypes?: HierarchyType[], isExternal = false) => {
  let requiredHierarchyTypes = hierarchyTypes ?? [];
  if (requiredHierarchyTypes) {
    requiredHierarchyTypes = requiredHierarchyTypes.filter(
      (hierarchy) => hierarchy?.mandatoryForUserRecords && hierarchy.external === isExternal
    );
  }

  return yup.object(
    requiredHierarchyTypes.reduce(
      (schema, key) => ({
        ...schema,
        [key.caption]: yup.object({
          id: yup.number().required(i18next.t('is-mandatory-hierarchy', { hierarchy: key.caption })),
        }),
      }),
      {}
    )
  );
};

const passwordValidation = (
  fieldLabel: string,
  isRetypePasswordField: boolean,
  existingUser?: boolean
): yup.StringSchema<string | null | undefined> => {
  let schema = yup.string().nullable(); //ensure you dont have to complete password field when not mandatory

  if (!isRetypePasswordField) {
    schema = schema.matches(passwordValidationPattern, {
      message: i18next.t('password-validation-message'),
      excludeEmptyString: true,
    });
  } else {
    //Retype password is invalid only when there is a value in a password field that doesn't match the retype value
    schema = schema.test('equalValues', i18next.t('retype-password-validation-message'), (value, context) => {
      const passwordValue = context.parent.password;
      if ((!passwordValue || passwordValue.length === 0) && (!value || value.length === 0)) {
        return true;
      }
      return passwordValue === value;
    });
  }
  if (!existingUser) {
    schema = schema.required(fieldValidationErrorMessage(fieldLabel));
  }

  return schema;
};

const emailValidation = (fieldLabel: string): yup.StringSchema => {
  const schema = yup
    .string()
    .email(i18next.t('is-not-valid-email'))
    .when(UserFields.loginRequired, {
      is: true,
      then: (schema) => schema.required(fieldValidationErrorMessage(fieldLabel)),
    });

  return schema;
};
