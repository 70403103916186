import { cloneDeep } from 'lodash';
import {
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  OdinDataSender,
  isObject,
  JsonData,
  DropDownResult,
} from '@myosh/odin-components';
import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import { competencyApi } from '../redux/services/competency';
import { CompetenciesItem, CompetencyStructure } from '../@types/competencies';
import { occupationApi } from '../redux/services/occupation';
import { OccupationalRequirementsItem, OccupationalRequirementsStructure } from '../@types/occupational-requirements';
import { Caption, IdCaptionType } from '../@types/common';
import i18next from '../i18n';
import { getComboboxData } from '../pages/admin/admin-utils';
import { api } from '../redux/services/api';
import { CompetenciesConfigurationItem } from '../@types/competencies-configuration';
import { CombinedFieldType } from '../@types/forms';
import { trainingTimeIntervalOptions } from '../components/admin/training-management/competency/competency-form-settings';

export interface RenewInterval {
  multiplier?: number;
  interval?: DropDownResult;
}

export interface TrainingTimeInterval {
  trainingTimeValue?: number;
  trainingTimeUnit?: DropDownResult;
}

interface IdNameValueType {
  id: string | number;
  name: Caption;
}

export function createCompetencyData(competencyResponse: CompetenciesItem): CompetencyStructure {
  const modifiedCompetencyResponse: Partial<Record<keyof CompetenciesItem, unknown>> = cloneDeep(competencyResponse);
  const renewInterval: RenewInterval = {};
  const trainingTimeInterval: TrainingTimeInterval = {};

  for (const [dataKey, dataValue] of Object.entries(modifiedCompetencyResponse)) {
    if (isObject(dataValue)) {
      if (Array.isArray(dataValue)) {
        if (dataKey === 'hierarchies') {
          const hierarchies: Record<string, string> = {}; // match name to record hierarchies object
          for (let j = 0, jlength = dataValue.length; j < jlength; j++) {
            const hierarchy = dataValue[j];
            const hierarchyItemKey = hierarchy.hierarchyTypeCaption;
            hierarchies[hierarchyItemKey] = hierarchy;
          }
          modifiedCompetencyResponse['hierarchies'] = hierarchies;
        } else if (dataKey === 'tags') {
          modifiedCompetencyResponse[dataKey] = (dataValue as IdCaptionType[]).map((data) => {
            return { text: data.caption, value: data.id };
          });
        }
      } else if (dataKey === 'group') {
        modifiedCompetencyResponse['group'] = {
          text: dataValue.name,
          value: dataValue.id,
        };
      }
    } else if ('testMethod' === dataKey) {
      modifiedCompetencyResponse[dataKey as keyof CompetenciesItem] = { text: dataValue, value: dataValue };
    } else if (dataKey === 'status') {
      modifiedCompetencyResponse[dataKey] = {
        text: dataValue === 'ACTIVE' ? i18next.t('active') : i18next.t('in-active'),
        value: dataValue,
      };
    } else if (['multiplier', 'interval'].includes(dataKey)) {
      if (dataKey === 'multiplier') {
        renewInterval['multiplier'] = dataValue as number;
      } else {
        renewInterval['interval'] = { text: dataValue as string, value: dataValue };
      }
    } else if (['trainingTimeValue', 'trainingTimeUnit'].includes(dataKey)) {
      if (dataKey === 'trainingTimeValue') {
        trainingTimeInterval['trainingTimeValue'] = dataValue as number;
      } else {
        trainingTimeInterval['trainingTimeUnit'] = trainingTimeIntervalOptions.find((item) => item.value === dataValue);
      }
    }
  }

  const data: CompetencyStructure = {
    original: competencyResponse,
    ...modifiedCompetencyResponse,
    renewalInterval: renewInterval,
    trainingTimeInterval: trainingTimeInterval,
  };

  return data;
}

function isIdNameType(object: unknown): object is IdNameValueType {
  return (
    isObject(object) &&
    (typeof object.id === 'number' || typeof object.id === 'string') &&
    object.hasOwnProperty('name')
  );
}

function isArrayOfIdNameType(object: unknown): object is Array<IdNameValueType> {
  return Array.isArray(object) && object.every(isIdNameType);
}

function formatDropDownValue(item: IdNameValueType) {
  if (isIdNameType(item)) {
    return {
      ...item,
      text: item.name,
      value: item.id,
    };
  }
  return item;
}

export const getCompetencyFieldOptionsData = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch
) => {
  switch (options?.fieldId?.toString()) {
    case 'group':
      await getCompetencyGroups(subscriber, options, dispatch);
      break;
    case 'trainingTime':
      await getTrainingTime(subscriber, options);
      break;
    case 'testMethod':
      await getTestMethod(subscriber, options);
      break;
    case 'status':
      await getStatus(subscriber, options);
      break;
    case 'tags':
      await getCompetencyTags(subscriber, options, dispatch);
      break;
    default:
      subscriber.sendData();
  }
};
const getCompetencyGroups = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch
) => {
  if (options.page && options.pageSize) {
    const dispatchResult = dispatch(
      competencyApi.endpoints.getCompetencyGroups.initiate({
        page: options.page,
        pageSize: options.pageSize,
        filters: { ...options.fieldFilters, archived: { value: false, comparison: '=' } },
      })
    );
    const result = await dispatchResult;
    if ('fulfilled' === result.status) {
      const comboboxOptions = result.data.map((item) => {
        return {
          value: item.id,
          text: item.name,
        };
      });
      subscriber.sendData({ data: comboboxOptions, requestId: options.requestId });
    } else {
      subscriber.sendData();
    }
    dispatchResult.unsubscribe();
  }
};

const getTrainingTime = async (subscriber: OdinDataSender<JsonDataWrapper>, options: OdinDataRetrievalOptions) => {
  if (options.page === 1) {
    const trainingTime = [
      {
        value: '0.5 Hours',
        text: i18next.t('prefix-hours', { hours: '0.5' }),
      },
      {
        value: '1.0 Hour',
        text: i18next.t('prefix-hour', { hours: '1.0' }),
      },
      {
        value: '1.5 Hours',
        text: i18next.t('prefix-hours', { hours: '1.5' }),
      },
      {
        value: '2.0 Hours',
        text: i18next.t('prefix-hours', { hours: '2.0' }),
      },
      {
        value: '4.0 Hours',
        text: i18next.t('prefix-hours', { hours: '4.0' }),
      },
      {
        value: '12.0 Hours',
        text: i18next.t('prefix-hours', { hours: '12.0' }),
      },
      {
        value: '0.5 Day',
        text: i18next.t('prefix-days', { days: '0.5' }),
      },
      {
        value: '1.0 Day',
        text: i18next.t('prefix-day', { day: '1.0' }),
      },
      {
        value: '1.5 Days',
        text: i18next.t('prefix-days', { days: '1.5' }),
      },
      {
        value: '2.0 Days',
        text: i18next.t('prefix-days', { days: '2.0' }),
      },
      {
        value: '3.0 Days',
        text: i18next.t('prefix-days', { days: '3.0' }),
      },
      {
        value: '4.0 Days',
        text: i18next.t('prefix-days', { days: '4.0' }),
      },
      {
        value: '1.0 Week',
        text: i18next.t('prefix-weeks', { weeks: '1.0' }),
      },
      {
        value: '2.0 Weeks',
        text: i18next.t('prefix-weeks', { weeks: '2.0' }),
      },
      {
        value: '4.0 Weeks',
        text: i18next.t('prefix-weeks', { weeks: '4.0' }),
      },
    ];
    subscriber.sendData({ data: trainingTime, requestId: options.requestId });
  } else {
    subscriber.sendData();
  }
};

const getTestMethod = async (subscriber: OdinDataSender<JsonDataWrapper>, options: OdinDataRetrievalOptions) => {
  if (options.page === 1) {
    const testMethod = [
      {
        value: 'Written',
        text: i18next.t('written'),
      },
      {
        value: 'Practical',
        text: i18next.t('practical'),
      },
      {
        value: 'Observation',
        text: i18next.t('observation'),
      },
      {
        value: 'Written + Practical',
        text: i18next.t('written-practical'),
      },
    ];
    subscriber.sendData({ data: testMethod, requestId: options.requestId });
  } else {
    subscriber.sendData();
  }
};

const getStatus = async (subscriber: OdinDataSender<JsonDataWrapper>, options: OdinDataRetrievalOptions) => {
  if (options.page === 1) {
    const testMethod = [
      {
        value: 'ACTIVE',
        text: i18next.t('active'),
      },
      {
        value: 'INACTIVE',
        text: i18next.t('in-active'),
      },
    ];
    subscriber.sendData({ data: testMethod, requestId: options.requestId });
  } else {
    subscriber.sendData();
  }
};

const getCompetencyTags = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch
) => {
  if (options.page === 1) {
    const dispatchResult = dispatch(competencyApi.endpoints.getCompetencyTags.initiate());
    const result = await dispatchResult;
    if ('fulfilled' === result.status) {
      const comboboxOptions = result.data.map((item) => {
        return {
          value: item.id,
          text: item.caption,
        };
      });
      subscriber.sendData({ data: comboboxOptions, requestId: options.requestId });
    } else {
      subscriber.sendData();
    }
    dispatchResult.unsubscribe();
  } else {
    subscriber.sendData();
  }
};

export const getOccupationalRequirementsFieldOptionsData = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch
) => {
  switch (options?.fieldId?.toString()) {
    case 'occupation':
      await getOccupations(subscriber, options, dispatch);
      break;
    case 'mandatoryCompetencies':
      await getCompetencies(subscriber, options, dispatch);
      break;
    case 'desirableCompetencies':
      await getCompetencies(subscriber, options, dispatch);
      break;
    case 'hierarchyMatchingOption':
      getHierarchyMatchingOption(subscriber, options);
      break;
    default:
      subscriber.sendData();
  }
};

const getOccupations = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch
) => {
  if (options.page && options.pageSize) {
    const dispatchResult = dispatch(
      occupationApi.endpoints.getOccupations.initiate({
        page: options.page,
        pageSize: options.pageSize,
        filters: options.fieldFilters,
      })
    );
    const result = await dispatchResult;
    if ('fulfilled' === result.status) {
      const comboboxOptions = result.data.map((item) => {
        return {
          value: item.id,
          text: item.caption,
        };
      });
      subscriber.sendData({ data: comboboxOptions, requestId: options.requestId });
    } else {
      subscriber.sendData();
    }
    dispatchResult.unsubscribe();
  }
};

const getCompetencies = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch
) => {
  if ((options.page && options.pageSize) || options.getAllData) {
    const dispatchResult = dispatch(
      competencyApi.endpoints.getCompetencies.initiate({
        page: options.page ?? 1,
        pageSize: options.pageSize ?? -1, // -1 is used to get all data
        filters: { ...options.fieldFilters, archived: { value: false, comparison: '=' } },
        sortedFields: { name: { direction: 'asc', order: 1 } },
      })
    );
    const result = await dispatchResult;
    if ('fulfilled' === result.status) {
      const comboboxOptions = result.data.map((item) => {
        return {
          value: item.id,
          text: item.name,
        };
      });
      if (options.getAllData) {
        subscriber.sendData({ data: comboboxOptions });
      } else {
        subscriber.sendData({ data: comboboxOptions, requestId: options.requestId });
      }
    } else {
      subscriber.sendData();
    }
    dispatchResult.unsubscribe();
  }
};
const getHierarchyMatchingOption = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions
) => {
  if (options.page && options.page === 1) {
    const comboboxOptions = [
      {
        value: 'MATCH_ANY',
        text: i18next.t('match-any-description', { any: `<strong>${i18next.t('any').toLowerCase()}</strong>` }),
      },
      {
        value: 'MATCH_ALL',
        text: i18next.t('match-all-description', { all: `<strong>${i18next.t('all').toLowerCase()}</strong>` }),
      },
    ];
    subscriber.sendData({ data: comboboxOptions, requestId: options.requestId });
  } else {
    subscriber.sendData();
  }
};

export const getCompetenciesFieldOptionsData = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions
) => {
  switch (options?.fieldId?.toString()) {
    case 'competencyArchived':
      await getComboboxData(subscriber, options);
      break;
    default:
      subscriber.sendData();
  }
};

export function createOccupationalRequirementsData(occupationalRequirementsResponse: OccupationalRequirementsItem) {
  const modifiedOccupationalRequirementsResponse: Record<keyof OccupationalRequirementsItem, unknown> = cloneDeep(
    occupationalRequirementsResponse
  );
  const keys = Object.keys(modifiedOccupationalRequirementsResponse);
  for (let i = 0, ilength = keys.length; i < ilength; i++) {
    const dataKey = keys[i] as keyof OccupationalRequirementsItem;
    const dataValue = occupationalRequirementsResponse[dataKey];

    if (isObject(dataValue)) {
      if (Array.isArray(dataValue) && dataKey === 'hierarchyFields') {
        const hierarchies: Record<string, Array<DropDownResult>> = {};
        for (let j = 0; j < dataValue.length ?? 0; j++) {
          const occupationData = dataValue[j];
          if (occupationData) {
            const translationItem = occupationData.caption?.translations[0];
            const hierarchyItemKey = translationItem.value;
            const hierarchyTypeCaption = occupationData.hierarchyTypeCaption?.translations[0].value as string;
            const hierarchyOption = { text: hierarchyItemKey, value: occupationData.id };
            if (hierarchies[hierarchyTypeCaption]) {
              hierarchies[hierarchyTypeCaption].push(hierarchyOption);
            } else {
              hierarchies[hierarchyTypeCaption] = [hierarchyOption];
            }
          }
        }

        modifiedOccupationalRequirementsResponse.hierarchyFields = hierarchies; // needed for the hierarchy
      } else if (dataKey === 'desirableCompetencies' || dataKey === 'mandatoryCompetencies') {
        const responseArrayProp = [] as JsonData;
        if (isArrayOfIdNameType(dataValue)) {
          for (let j = 0, jlength = dataValue.length; j < jlength; j++) {
            responseArrayProp[j] = formatDropDownValue(dataValue[j]);
          }
        }
        modifiedOccupationalRequirementsResponse[dataKey] = responseArrayProp;
      } else if (dataKey === 'occupation') {
        modifiedOccupationalRequirementsResponse[dataKey] = {
          text: (dataValue.caption as Caption).translations[0]?.value,
          value: dataValue.id,
        };
      }
    } else if (dataKey === 'hierarchyMatchingOption') {
      modifiedOccupationalRequirementsResponse[dataKey] = {
        text: dataValue,
        value: dataValue,
      };
    }
  }

  const data: OccupationalRequirementsStructure = {
    original: {
      ...occupationalRequirementsResponse,
    },
    ...modifiedOccupationalRequirementsResponse,
  };

  return data;
}

export const getTrainingRecordsData = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  targetIds: CompetenciesConfigurationItem
) => {
  switch (options?.fieldId?.toString()) {
    case 'targetModule':
      await getModules(subscriber, options, dispatch);
      break;
    case 'targetModuleForm':
      await getModuleForms(subscriber, options, dispatch, targetIds);
      break;
    case 'targetFieldId':
      await getPersonFields(subscriber, options, dispatch, targetIds);
      break;
    case 'competencyFieldId':
      await getCompetencyFields(subscriber, options, dispatch, targetIds);
      break;
    case 'startedDateFieldId':
    case 'completedDateFieldId':
    case 'scheduledDateFieldId':
    case 'expiryDateFieldId':
      await getDateFields(subscriber, options, dispatch, targetIds);
      break;
    default:
      subscriber.sendData();
  }
};

const getModules = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch
) => {
  if (options.page === 1) {
    const dispatchResult = dispatch(api.endpoints.modules.initiate({}));
    const result = await dispatchResult;
    if ('fulfilled' === result.status) {
      const comboboxOptions = result.data.map((item) => {
        return {
          value: item.id,
          text: item.caption,
        };
      });
      subscriber.sendData({ data: comboboxOptions, requestId: options.requestId });
    } else {
      subscriber.sendData();
    }
    dispatchResult.unsubscribe();
  } else {
    subscriber.sendData();
  }
};

const getModuleForms = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  targetIds: CompetenciesConfigurationItem
) => {
  if (options.page === 1) {
    const dispatchResult = dispatch(
      api.endpoints.moduleForms.initiate({ moduleId: targetIds?.targetModuleId as number })
    );
    const result = await dispatchResult;
    if ('fulfilled' === result.status) {
      const comboboxOptions = result.data.result.forms
        ?.map((item) => {
          return {
            value: item.id,
            text: item.caption,
          };
        })
        .filter((item) => item.value !== 0);
      subscriber.sendData({ data: comboboxOptions, requestId: options.requestId });
    } else {
      subscriber.sendData();
    }
    dispatchResult.unsubscribe();
  } else {
    subscriber.sendData();
  }
};

const getPersonFields = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  targetIds: CompetenciesConfigurationItem
) => {
  if (options.page === 1) {
    const dispatchResult = dispatch(api.endpoints.formSettings.initiate(targetIds?.targetFormId as number));
    const result = await dispatchResult;
    if ('fulfilled' === result.status) {
      const fields = result.data.fields;
      const filteredFields = fields.map((field) => {
        return field;
      });
      for (let i = 0; i < filteredFields.length; i++) {
        const filteredField = filteredFields[i].fields?.filter((item) => {
          const fieldType: CombinedFieldType = item.fieldType;
          if (['PERSONFIELD'].includes(fieldType)) {
            return {
              item,
            };
          }
        });
        const comboboxOptions = filteredField?.map((item) => {
          return {
            value: item.id,
            text: item.caption,
          };
        });
        subscriber.sendData({
          data: comboboxOptions,
          requestId: options.requestId,
        });
      }
    } else {
      subscriber.sendData();
    }
    dispatchResult.unsubscribe();
  }
};

const getCompetencyFields = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  targetIds: CompetenciesConfigurationItem
) => {
  if (options.page === 1) {
    const dispatchResult = dispatch(api.endpoints.formSettings.initiate(targetIds?.targetFormId as number));
    const result = await dispatchResult;
    if ('fulfilled' === result.status) {
      const fields = result.data.fields;
      const filteredFields = fields.map((field) => {
        return field;
      });
      for (let i = 0; i < filteredFields.length; i++) {
        const filteredField = filteredFields[i].fields?.filter((item) => {
          const fieldType: CombinedFieldType = item.fieldType;
          if (['TRAINING'].includes(fieldType)) {
            return {
              item,
            };
          }
        });
        const comboboxOptions = filteredField?.map((item) => {
          return {
            value: item.id,
            text: item.caption,
          };
        });
        subscriber.sendData({
          data: comboboxOptions,
          requestId: options.requestId,
        });
      }
    } else {
      subscriber.sendData();
    }
    dispatchResult.unsubscribe();
  } else {
    subscriber.sendData();
  }
};

const getDateFields = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  targetIds: CompetenciesConfigurationItem
) => {
  if (options.page === 1) {
    const dispatchResult = dispatch(api.endpoints.formSettings.initiate(targetIds?.targetFormId as number));
    const result = await dispatchResult;
    if ('fulfilled' === result.status) {
      const fields = result.data.fields;
      const filteredFields = fields.map((field) => {
        return field;
      });
      for (let i = 0; i < filteredFields.length; i++) {
        const filteredField = filteredFields[i].fields?.filter((item) => {
          const fieldType: CombinedFieldType = item.fieldType;
          if (['DATEFIELD_COMPETENCY', 'DATEFIELD'].includes(fieldType)) {
            return {
              item,
            };
          }
        });
        const comboboxOptions = filteredField?.map((item) => {
          return {
            value: item.id,
            text: item.caption,
          };
        });
        subscriber.sendData({
          data: comboboxOptions,
          requestId: options.requestId,
        });
      }
    } else {
      subscriber.sendData();
    }
    dispatchResult.unsubscribe();
  } else {
    subscriber.sendData();
  }
};
