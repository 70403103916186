import React, { useMemo, useRef } from 'react';
import { DynamicFieldComponentProps, ErrorLabel, FieldLabel } from '@myosh/odin-components';
import cx from 'classnames';
import { isEmpty, orderBy } from 'lodash';
import { FieldError } from 'react-hook-form';
import { Observable } from 'rxjs';
import {
  QuestionnaireConfigProps,
  QuestionnaireFieldValue,
  QuestionnaireValue,
  QuestionnaireValueItem,
  QuestionnaireItemValidationErrors,
  QuestionnaireItemValidationMap,
} from '../../../@types/questionnaire';
import { RecordResult } from '../../../@types/records';
import QuestionnaireQuestion from './questionnaire-question.component';
import NumericQuestionnaireScore from './numeric-questionnaire-score.component';
import QuestionnaireScore from './questionnaire-score.component';

interface QuestionnaireFieldError extends FieldError {
  value?: Array<QuestionnaireItemValidationErrors>;
}

export interface QuestionnaireProps extends DynamicFieldComponentProps<QuestionnaireFieldValue> {
  configuration?: QuestionnaireConfigProps;
  recordId?: string;
  saveRecord?: () => Observable<RecordResult>;
  errorObject?: QuestionnaireFieldError;
  submitCount: number;
}

const Questionnaire = ({
  label,
  value,
  configuration,
  readOnly,
  required,
  onChange,
  id,
  recordId,
  registerPreSubmitHandler,
  registerPostSubmitHandler,
  saveRecord,
  errorObject,
  submitCount,
}: QuestionnaireProps) => {
  const answers = value?.value ?? [];
  const answersRef = useRef<QuestionnaireValue>(answers);
  answersRef.current = answers;

  const showScores = configuration?.displayScores && answersRef.current.length > 0;

  const handleChange = (item?: QuestionnaireValueItem) => {
    if (item) {
      let _answers: QuestionnaireValue = answersRef.current.map((el) => {
        const questionIndex = configuration?.questions.findIndex((obj) => obj.id === el.questionId);

        let linkedRecordIds = el.linkedRecordIds;
        if (el.linkedRecords && el.linkedRecords.length > 0) {
          linkedRecordIds = el.linkedRecords.map((item) => String(item.id));
        }

        return {
          ...el,
          questionPosition: configuration?.questions[questionIndex ?? answersRef.current.length].position,
          linkedRecordIds,
        };
      });
      const questionIndex = configuration?.questions.findIndex((obj) => obj.id === item.questionId);
      if (answersRef.current.find((answer) => answer.questionId === item.questionId)) {
        const objectIndex = answersRef.current.findIndex((obj) => obj.questionId === item.questionId);
        _answers[objectIndex] = {
          ...item,
          questionPosition: configuration?.questions[questionIndex ?? objectIndex].position,
        };
      } else {
        _answers = [
          ..._answers,
          { ...item, questionPosition: configuration?.questions[questionIndex ?? answersRef.current.length].position },
        ];
      }
      if (onChange) {
        onChange({ value: orderBy(_answers, ['questionPosition'], ['asc']) });
      } else {
        throw Error('The <Questionnaire/> should not be used directly. Please use <HfQuestionnaire/> instead.');
      }
    }
  };

  const validationErrorMap: QuestionnaireItemValidationMap = useMemo(() => {
    const errorMap: QuestionnaireItemValidationMap = {};

    if (errorObject?.value) {
      for (let i = 0; i < errorObject.value.length; i++) {
        const errorItem = errorObject.value[i];
        if (errorItem?.attachments?.message) {
          const { questionId, message } = errorItem?.attachments?.message;
          errorMap[questionId] = { ...(errorMap[questionId] ?? {}), attachments: message };
        }

        if (errorItem?.observations?.message) {
          const { questionId, message } = errorItem?.observations?.message;
          errorMap[questionId] = { ...(errorMap[questionId] ?? {}), observations: message };
        }

        if (errorItem?.linkedRecords?.message) {
          const { questionId, message } = errorItem?.linkedRecords?.message;
          errorMap[questionId] = { ...(errorMap[questionId] ?? {}), linkedRecords: message };
        }
      }
    }

    return errorMap;
  }, [errorObject?.value]);

  const containerClasses = cx('flex flex-col pt-2', {
    'font-bold': !label,
    'border border-error':
      errorObject && errorObject.type === 'has-answer' && !readOnly && !isEmpty(configuration?.questions),
  });

  return (
    <div className="flex flex-col">
      {label && <div className="flex">{configuration?.showFieldCaption && <FieldLabel label={label} />}</div>}
      <div className={containerClasses}>
        {configuration &&
          configuration.questions.map((question) => {
            const _answer = answersRef.current.find((answer) => answer.questionId === question.id);
            const _validationConfig = configuration?.fieldValidations?.find(
              (fieldValidation) => fieldValidation.answerId === _answer?.id
            );

            const _error = validationErrorMap[question.id];

            return (
              <QuestionnaireQuestion
                key={question.id}
                id={id}
                name={question.caption}
                label={question.caption}
                value={_answer}
                readOnly={readOnly}
                onChange={handleChange}
                question={question}
                configuration={configuration}
                recordId={recordId}
                registerPreSubmitHandler={registerPreSubmitHandler}
                registerPostSubmitHandler={registerPostSubmitHandler}
                saveRecord={saveRecord}
                validationErrors={_error}
                validationConfig={_validationConfig}
                submitCount={submitCount}
                required={required}
              />
            );
          })}
      </div>
      {errorObject && <ErrorLabel>{errorObject.message}</ErrorLabel>}
      {showScores && configuration.type === 'NUMERIC_QUESTIONNAIRE' && (
        <NumericQuestionnaireScore answers={answersRef.current} questions={configuration.questions} />
      )}
      {showScores && configuration.type === 'TEXTUAL_QUESTIONNAIRE' && (
        <QuestionnaireScore
          answers={answersRef.current}
          answersConfig={configuration.answers}
          questions={configuration.questions}
        />
      )}
    </div>
  );
};

export default Questionnaire;
