import React from 'react';
import { OdinIcon, OdinIconType } from '@myosh/odin-components';

interface AttachmentListItemProps {
  name: string;
  url: string;
  lastModified: string;
}

export default function AttachmentListItem({ name, url, lastModified }: AttachmentListItemProps) {
  return (
    <a className="mb-1 flex w-full" href={url} download={name}>
      <OdinIcon icon="FileText" className="mr-1 mt-0.5" type={OdinIconType.Line} />
      <div>
        <div className="mb-0.25 text-sm font-bold">{name}</div>
        <div className="text-xs">{lastModified}</div>
      </div>
    </a>
  );
}
