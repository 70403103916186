import React, { useEffect, useMemo } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { ClusterProvider, useUser } from '@myosh/myosh-login';
import { ThemeProvider } from '../../../context/theme.context';
import Layout from '../layout.component';
import { SchemaConfirmationModal } from '../components/schema-confirmation-modal.component';
import { useAppDispatch } from '../../../redux/hooks';
import { hideActiveRecordsPanel, setActivePanelEmpty, showActiveRecordsPanel } from '../../../redux/slices/layout';
import ActiveSidePanel from '../components/active-side-panel.component';
import { shouldDisplaySchemaConfirmationModal } from '../layout-util';

export default function PrivateLayoutRoute() {
  const { schema } = useParams();
  const { pathname } = useLocation();
  const { state } = useUser();
  const { auth: isAuthenticated, loginStatus } = state;
  const currentSchema = state.user?.currentSchema;
  const dispatch = useAppDispatch();

  const isSchemaConfirmationModalVisible = useMemo(
    () => shouldDisplaySchemaConfirmationModal(schema, currentSchema),
    [schema, currentSchema]
  );

  useEffect(() => {
    dispatch(showActiveRecordsPanel());
    return () => {
      dispatch(hideActiveRecordsPanel());
      dispatch(setActivePanelEmpty());
    };
  }, []);

  const isLoginPage = pathname.includes('/login');

  if (isAuthenticated && loginStatus === 'done') {
    if (isSchemaConfirmationModalVisible && !isLoginPage) {
      return <SchemaConfirmationModal schema={schema} visible={true} />;
    }

    return (
      <ClusterProvider>
        <ThemeProvider>
          <Layout />
          <ActiveSidePanel />
        </ThemeProvider>
      </ClusterProvider>
    );
  }

  if (!isAuthenticated && !isLoginPage) {
    localStorage.setItem('postLogInRedirect', pathname);
    return <Navigate to="/login" replace />;
  }

  return null;
}
