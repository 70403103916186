import { createApi } from '@reduxjs/toolkit/query/react';
import { RiskMatricesApiProps, RiskMatrixItem } from '../../@types/risk-matrix';
import { createCustomSortWithOrderBy, createFieldFilterText } from '../../common/common-functions';
import { baseQueryWithReAuthAndUrlEncoding, providesList } from './api';
import {
  RiskLevelsApiResponse,
  RiskLevelsDataResponse,
  RiskMatrixApiResponse,
  RiskMatrixDataItem,
  RiskMatrixDataResponse,
} from '../../@types/risk-matrix-field';

export const riskMatrixApi = createApi({
  reducerPath: 'risk-matrices',
  baseQuery: baseQueryWithReAuthAndUrlEncoding,
  tagTypes: ['RiskMatrix', 'RiskRatingConfig', 'RiskLevel'],
  endpoints: (builder) => ({
    getRiskMatrices: builder.query<Array<RiskMatrixItem>, RiskMatricesApiProps>({
      query: (args) =>
        `/risk-matrices?page=${args.page}&pageSize=${args.pageSize}${createFieldFilterText({
          filters: args.filters,
        })}${createCustomSortWithOrderBy(args.sort)}`,
      providesTags: (result) => providesList(result, 'RiskMatrix'),
    }),
    getRiskMatrixById: builder.query<RiskMatrixItem, number>({
      query: (id) => `/risk-matrices/${id}`,
      providesTags: (result) => (result ? [{ type: 'RiskMatrix' as const, id: result.id as number }] : ['RiskMatrix']),
    }),
    updateRiskMatrix: builder.mutation<void, Partial<RiskMatrixItem>>({
      query: (body) => ({
        url: `/risk-matrices`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result) {
          return [{ type: 'RiskMatrix', id: arg.id }];
        } else {
          return [];
        }
      },
    }),
    riskRatingMatrixConfig: builder.query<Array<RiskMatrixDataResponse>, number | void>({
      query: (matrixId?: number) => {
        const url = `/risk-matrices/risk-ratings`;
        if (matrixId) {
          return `${url}/${matrixId}`;
        }
        //default system matrix
        return `${url}/default`;
      },
      transformResponse: (response: RiskMatrixApiResponse) => response.items || [],
      providesTags: (result) => providesList(result, 'RiskRatingConfig'),
    }),
    riskMatrixRiskLevels: builder.query<Array<RiskLevelsDataResponse>, number>({
      query: (matrixId?: number) => `/risk-matrices/${matrixId}/risk-levels/simple`,
      transformResponse: (response: RiskLevelsApiResponse) =>
        response.items?.sort((a, b) => b.category - a.category) || [],
      providesTags: (result) => providesList(result, 'RiskLevel'),
    }),
    createNewRiskLevel: builder.mutation<void, Partial<RiskLevelsDataResponse> & { matrixId: number }>({
      query: ({ matrixId, ...rest }) => ({
        url: `/risk-matrices/${matrixId}/risk-levels`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: ['RiskLevel'],
    }),
    editRiskLevel: builder.mutation<void, Partial<RiskLevelsDataResponse>>({
      query: (body) => ({
        url: `/risk-matrices/risk-levels`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['RiskLevel'],
    }),
    deleteRiskLevel: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `/risk-matrices/risk-levels/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['RiskLevel'],
    }),
    createNewMatrixLikelyhood: builder.mutation<void, number>({
      query: (matrixId: number) => ({
        url: `/risk-matrices/${matrixId}/likelihoods`,
        method: 'POST',
      }),
      invalidatesTags: ['RiskRatingConfig'],
    }),
    createNewMatrixConsequence: builder.mutation<void, number>({
      query: (matrixId: number) => ({
        url: `/risk-matrices/${matrixId}/consequences`,
        method: 'POST',
      }),
      invalidatesTags: ['RiskRatingConfig'],
    }),
    editRiskRating: builder.mutation<void, Partial<RiskMatrixDataItem>>({
      query: (body) => ({
        url: `/risk-matrices/risk-ratings`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['RiskRatingConfig'],
    }),
    editLikelyhood: builder.mutation<void, Partial<RiskMatrixDataItem>>({
      query: (body) => ({
        url: `/risk-matrices/likelihoods`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['RiskRatingConfig'],
    }),
    editConsequence: builder.mutation<void, Partial<RiskMatrixDataItem>>({
      query: (body) => ({
        url: `/risk-matrices/consequences`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['RiskRatingConfig'],
    }),
    deleteLikelyhood: builder.mutation<void, number>({
      query: (id) => ({
        url: `/risk-matrices/likelihoods/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['RiskRatingConfig'],
    }),
    deleteConsequence: builder.mutation<void, number>({
      query: (id) => ({
        url: `/risk-matrices/consequences/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['RiskRatingConfig'],
    }),
  }),
});

export const {
  useGetRiskMatricesQuery,
  useGetRiskMatrixByIdQuery,
  useLazyGetRiskMatrixByIdQuery,
  useRiskRatingMatrixConfigQuery,
  useRiskMatrixRiskLevelsQuery,
  useCreateNewRiskLevelMutation,
  useEditRiskLevelMutation,
  useCreateNewMatrixLikelyhoodMutation,
  useCreateNewMatrixConsequenceMutation,
  useEditRiskRatingMutation,
  useEditLikelyhoodMutation,
  useEditConsequenceMutation,
  useDeleteLikelyhoodMutation,
  useDeleteConsequenceMutation,
  useDeleteRiskLevelMutation,
  useUpdateRiskMatrixMutation,
} = riskMatrixApi;
