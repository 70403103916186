import React from 'react';
import { OdinController } from '@myosh/odin-components';
import Combobox, { ComboboxProps } from './combobox.component';

interface HfComboboxProps extends ComboboxProps {
  name: string;
}

export default function HfCombobox({
  control,
  name,
  value,
  onChange,
  textField,
  valueField,
  fieldValueConverter,
  ...rest
}: HfComboboxProps) {
  return (
    <OdinController
      name={name}
      control={control}
      onChange={onChange}
      defaultValue={value}
      fieldValueConverter={fieldValueConverter}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Combobox
          {...rest}
          name={name}
          control={control}
          value={value}
          onChange={onChange}
          error={error?.message}
          textField={textField}
          valueField={valueField}
        />
      )}
    />
  );
}
