import { useScreenSize } from '@myosh/odin-components';
import { useRef } from 'react';

const useDoubleClick = () => {
  const isClickPending = useRef<boolean>(false);
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();
  const { isMobile } = useScreenSize();

  const rowClickHandler = (callBack: (doubleClick: boolean) => void) => {
    if (isMobile) {
      callBack(false);
    } else {
      if (isClickPending.current) {
        clearTimeout(timeoutId.current);
        isClickPending.current = false;
        callBack(true);
        return;
      }

      isClickPending.current = true;
      timeoutId.current = setTimeout(() => {
        isClickPending.current = false;
        callBack(false);
      }, 300);
    }
  };

  return { rowClickHandler };
};

export default useDoubleClick;
