import {
  DynamicFieldDataProps,
  DynamicFormRequiredType,
  DynamicFormSettings,
  fieldValidationErrorMessage,
} from '@myosh/odin-components';
import i18next from '../../../i18n';
import { HfHierarchyField } from '../../fields/hierarchy/hf-hierarchy-field.component';
import HfDropDownMultiSelect from '../../fields/custom-drop-downs/hf-drop-down-multi-select.component';
import HfDropDown from '../../fields/custom-drop-downs/hf-drop-down.component';
import HfScheduleRules from '../../fields/schedule-rules-field/hf-schedule-rule-field.component';
import { DynamicFieldDataTypes } from '@myosh/odin-components/dist/types/components/dynamic-form/dynamic-form.interfaces';
import * as yup from 'yup';

const dataSettings: DynamicFieldDataProps = {
  valueField: 'value',
  textField: 'text',
};

export const getSchedulerFormSettings = (
  formDefault?: DynamicFormSettings,
  formIntFields?: DynamicFieldDataTypes,
  validateUserAssignment?: boolean,
  readOnly?: boolean
): DynamicFormSettings => {
  const additionalFieldsConverters = formDefault?.customFieldComponents ?? {};
  return {
    id: 'scheduler-form-settings',
    caption: '',
    fields: [
      {
        id: 'caption',
        fieldType: 'TEXTFIELD',
        fieldName: 'caption',
        caption: i18next.t('name'),
        position: 0,
        required: DynamicFormRequiredType.True,
        permissions: { read: true, edit: !readOnly },
      },
      {
        id: 'targetStatus',
        fieldType: 'COMBOBOX',
        fieldName: 'targetStatus',
        caption: i18next.t('target-status'),
        position: 1,
        dataSettings: dataSettings,
        required: DynamicFormRequiredType.True,
        permissions: { read: true, edit: !readOnly },
      },
      {
        id: 'accountDetails',
        fieldName: 'accountDetails',
        fieldType: 'GROUP',
        caption: i18next.t('user-assignment'),
        position: 2,
        startOpen: true,
        permissions: { read: true, edit: !readOnly },
        fields: [
          {
            id: 'targetField',
            fieldType: 'COMBOBOX',
            caption: i18next.t('target-field'),
            fieldName: 'targetField',
            position: 0,
            permissions: { read: true, edit: !readOnly },
            dataSettings: dataSettings,
          },
          {
            id: 'users',
            fieldType: 'DROPDOWNMULTISELECT',
            fieldName: 'users',
            caption: i18next.t('users'),
            required: validateUserAssignment ? DynamicFormRequiredType.True : DynamicFormRequiredType.False,
            validationFunc: userAssignmentValidation(i18next.t('users'), validateUserAssignment),
            permissions: { read: true, edit: !readOnly },
            position: 1,
            dataSettings: dataSettings,
          },
          {
            id: 'groups',
            fieldType: 'DROPDOWNMULTISELECT',
            fieldName: 'groups',
            caption: i18next.t('groups'),
            required: validateUserAssignment ? DynamicFormRequiredType.True : DynamicFormRequiredType.False,
            validationFunc: userAssignmentValidation(i18next.t('groups'), validateUserAssignment),
            permissions: { read: true, edit: !readOnly },
            position: 2,
            dataSettings: dataSettings,
          },
        ],
      },
      {
        id: 'rules',
        fieldType: 'GROUP',
        fieldName: i18next.t('rules'),
        caption: i18next.t('rules'),
        position: 3,
        startOpen: true,
        permissions: { read: true, edit: !readOnly },
        fields: [
          {
            id: 'rules',
            fieldType: 'CUSTOM',
            customFieldType: 'RULES',
            fieldName: 'rules',
            caption: i18next.t('scheduled-running-times'),
            position: 0,
            required: DynamicFormRequiredType.True,
            validationFunc: scheduleRulesValidation(),
            permissions: { read: true, edit: !readOnly },
            dynamicProperties: {
              formIntFields: formIntFields,
            },
          },
        ],
      },
    ],
    customFieldComponents: {
      ...additionalFieldsConverters,
      HIERARCHY: HfHierarchyField,
      DROPDOWNMULTISELECT: HfDropDownMultiSelect,
      COMBOBOX: HfDropDown,
      RULES: HfScheduleRules,
    },
  };
};

export const userAssignmentValidation = (fieldLabel: string, validateUserAssignment?: boolean) => {
  if (validateUserAssignment) {
    return yup.object({
      value: yup.number().required(fieldValidationErrorMessage(fieldLabel)),
    });
  }
};

export const scheduleRulesValidation = () => {
  return yup.array().of(
    yup.object().shape({
      type: yup.string().oneOf(['periodic-schedule', 'field-condition']).required(),
      conditionFieldId: yup.number().when('type', {
        is: 'field-condition',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
      conditionField: yup.object().when('type', {
        is: 'field-condition',
        then: (schema) =>
          schema.shape({
            text: yup.string().required(),
            value: yup.number().required(),
          }),
        otherwise: (schema) => schema.notRequired(),
      }),
      firstOccurrence: yup.number().when('type', {
        is: 'field-condition',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
      repeatEvery: yup.number(),
      hourOfDay: yup.number().when('type', {
        is: 'periodic-schedule',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
      interval: yup.mixed().when('type', {
        is: (type: string) => type === 'periodic-schedule',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
      month: yup.mixed().when('interval', {
        is: (interval: Record<string, unknown>) =>
          interval?.value === 'QUARTERLY' || interval?.value === 'YEARLY' || interval?.value === 'BIANNUAL',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
      dayOfMonth: yup.number().when(['type', 'interval'], {
        is: (type: string, interval: Record<string, unknown>) =>
          type === 'periodic-schedule' && interval?.value === 'MONTHLY',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
      dayOfWeek: yup.mixed().when(['type', 'interval'], {
        is: (type: string, interval: Record<string, unknown>) =>
          type === 'periodic-schedule' && interval?.value === 'WEEKLY',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
      timeZone: yup.mixed().when('type', {
        is: 'periodic-schedule',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
    })
  );
};
