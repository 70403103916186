import React from 'react';
import { OdinIcon, OdinIconType } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';

interface ThumbnailActionsProps {
  id: string;
  name: string;
  downloadUrl: string;
  previewUrl?: string;
  readOnly: boolean;
  attachmentId?: string;
  onDelete: (id: string) => void;
}

export const ThumbnailActions = ({
  id,
  name,
  downloadUrl,
  previewUrl,
  readOnly,
  attachmentId,
  onDelete,
}: ThumbnailActionsProps) => {
  const { t } = useTranslation();

  const actionStyles = 'hidden group-hover:inline-flex absolute top-0 right-0 py-1 w-full justify-end bg-gray-5';
  const actionStyle = 'inline-flex cursor-pointer ml-1';

  return (
    <div className={actionStyles}>
      {!readOnly && (
        // TQ attachments should be deleted using the 'attachmentId' property
        <a title={t('delete')} className={actionStyle} onClick={() => onDelete(attachmentId ?? id)}>
          <OdinIcon icon="DeleteBin5" type={OdinIconType.Line} />
        </a>
      )}
      <a
        title={t('download')}
        className={actionStyle}
        download={name}
        href={downloadUrl}
        onClick={(event) => event.stopPropagation()}
      >
        <OdinIcon icon="FileDownload" type={OdinIconType.Line} />
      </a>
      {previewUrl && (
        <a
          title={t('preview')}
          className={actionStyle}
          href={previewUrl}
          target="_blank"
          rel="noreferrer"
          onClick={(event) => event.stopPropagation()}
        >
          <OdinIcon icon="ExternalLink" type={OdinIconType.Line} />
        </a>
      )}
    </div>
  );
};
