import { OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';
import React from 'react';

const DataCellLoading = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <OdinIcon type={OdinIconType.Line} icon="Refresh" spin={true} size={OdinIconSize.ExtraSmall} />
    </div>
  );
};

export default DataCellLoading;
