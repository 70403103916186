import {
  DynamicFieldComponentProps,
  ModalDialog,
  ModalDialogButtonSetting,
  fieldValidationErrorMessage,
  DropDownResult,
  HfInput,
  JsonData,
  OdinDataSender,
  OdinDataRetrievalOptions,
  JsonDataWrapper,
  DataSearchLocation,
} from '@myosh/odin-components';

import React, { useState, useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import HfDropDown from '../custom-drop-downs/hf-drop-down.component';
import { dateFormatBasedOnFilter, forceAssert, processFieldFilters } from '../../../common/common-functions';
import { useGetGroupsQuery } from '../../../redux/services/group';
import { FieldFilters, FieldSorts } from '../../../@types/common';
import ErrorLoadingDataNotification from '../../common/error-loading-data-notification';
import { LookupTypeFieldProps } from '../../../@types/forms';
import { UserFields } from '../../../common/user-config';
import { useLazyGetUsersQuery } from '../../../redux/services/user';
import HfDropDownMultiSelect from '../custom-drop-downs/hf-drop-down-multi-select.component';
import i18next from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { UserStructure } from '../../../@types/users';
import { GroupStructure } from '../../../@types/groups';

//format for every recipient - address, user or group
export interface EmailAddressTemplate {
  typeOfRecipient: string;
  id: string;
  recipientId: string;
  value: string;
  text: string;
}

export interface EmailThisAddressModalProps extends DynamicFieldComponentProps<unknown>, LookupTypeFieldProps {
  visible?: boolean;
  showCloseIcon?: boolean;
  hidden: () => void;
  onNewAddressAdded?: (data: Array<EmailAddressTemplate>) => void;
}

const convertGroupData = (inputData: Array<GroupStructure>) =>
  inputData.map((item) => ({
    groupId: String(item.id),
    value: item.id,
    text: item.caption,
  }));

const convertUserData = (inputData: Array<UserStructure>) =>
  inputData.map((item) => ({
    id: String(item.original.id),
    value: item.original.email,
    text: `${item.original.lastName} ${item.original.firstName}`,
  }));

const dropDownOptions: JsonData = [
  { value: 'email', text: i18next.t('enter-an-email-address') },
  { value: 'users', text: i18next.t('choose-a-user') },
  { value: 'groups', text: i18next.t('choose-a-group') },
];

const groupFilters: FieldFilters = {
  archived: {
    value: false,
    comparison: '=',
  },
};

const groupSortCriteria: FieldSorts = {
  name: {
    direction: 'asc',
    order: 0,
    customDataProperties: {
      caseSensitive: false,
    },
  },
};

const userSortCriteria: FieldSorts = {
  lastName: {
    direction: 'asc',
    order: 0,
    customDataProperties: {
      caseSensitive: false,
    },
  },
};

const UserFieldString = [UserFields.lastName, UserFields.firstName, UserFields.email, UserFields.userId].join(', ');

const recipientsToEmailAddressTemplate = (
  type: string,
  recipients: Array<{ id: string; value: string; text: string }>
) => {
  return recipients.map((recipient) => {
    return {
      id: type + '-' + recipient.id,
      typeOfRecipient: type,
      recipientId: recipient.id,
      value: recipient.value,
      text: recipient.text,
    };
  });
};

const EmailThisAddressModal = ({ visible, onNewAddressAdded, hidden }: EmailThisAddressModalProps) => {
  const [typeOfRecipient, setTypeOfRecipient] = useState('email');
  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      setTypeOfRecipient('email');
      reset({});
    }
  }, [visible]);

  const {
    data: groupsData,
    isFetching,
    error,
  } = useGetGroupsQuery({
    filters: processFieldFilters(groupFilters, dateFormatBasedOnFilter),
    externalUserSignupRoles: false,
    sortedFields: groupSortCriteria,
  });
  const [getUsers] = useLazyGetUsersQuery();

  const getUserOptionsData = {
    getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
      if (options && options.page && options.pageSize) {
        try {
          const initialFilters: FieldFilters = {
            email: {
              value: false,
              comparison: 'is_blank',
            },
          };
          const userFilters: FieldFilters = {
            ...initialFilters,
            fullName: {
              value: options?.fieldFilters?.text?.value ?? '',
              comparison: 'contains',
            },
          };
          const filtersToProcess =
            userFilters.fullName?.value && userFilters.fullName.value !== '' ? userFilters : initialFilters;
          const result = await getUsers({
            fields: UserFieldString,
            page: options.page,
            archivedUsers: false,
            pageSize: options.pageSize,
            sort: userSortCriteria,
            filters: processFieldFilters(filtersToProcess, dateFormatBasedOnFilter),
          }).unwrap();

          const data = convertUserData(result);

          subscriber.sendData({ data, requestId: options.requestId });
        } catch (_) {
          subscriber.sendData();
        }
      } else {
        subscriber.sendData();
      }
    },
  };

  const schema = yup.object().shape({
    typeOfRecipient: yup
      .object()
      .nullable()
      .required(fieldValidationErrorMessage(t('type-of-recipient')))
      .typeError(fieldValidationErrorMessage(t('type-of-recipient'))),

    emailAddress: yup.string().when('typeOfRecipient', {
      is: (typeOfRecipient: DropDownResult) => typeOfRecipient?.value === 'email',
      then: (schema) =>
        schema
          .required(fieldValidationErrorMessage(t('email-address')))
          .email(fieldValidationErrorMessage(t('invalid-email'))),
      otherwise: (schema) => schema.notRequired(),
    }),

    chooseUsers: yup.array().when('typeOfRecipient', {
      is: (typeOfRecipient: DropDownResult) => typeOfRecipient?.value === 'users',
      then: (schema) => schema.required(fieldValidationErrorMessage(t('users'))),
      otherwise: (schema) => schema.notRequired(),
    }),

    chooseGroups: yup.array().when('typeOfRecipient', {
      is: (typeOfRecipient: DropDownResult) => typeOfRecipient?.value === 'groups',
      then: (schema) => schema.required(fieldValidationErrorMessage(t('groups'))),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const {
    control,
    reset,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      emailAddress: '',
      chooseGroups: [],
      chooseUsers: [],
    } as FieldValues,
  });

  const groupOptions = !isFetching && groupsData ? convertGroupData(groupsData) : [];

  const updateRecipients = async () => {
    const isValid = await trigger(); // This validates all fields

    if (isValid) {
      const data = getValues();
      const emailAddressDetails: Array<EmailAddressTemplate> = [];

      if (data.typeOfRecipient.value === 'email') {
        const transformedEmail = [{ id: data.emailAddress, value: data.emailAddress, text: data.emailAddress }];
        emailAddressDetails.push(...recipientsToEmailAddressTemplate('email', transformedEmail));
      } else if (data.typeOfRecipient.value === 'groups') {
        const transformedGroups = data.chooseGroups.map((group: DropDownResult) => {
          return { id: group.originalData?.groupId, value: group.value, text: group.text };
        });
        emailAddressDetails.push(...recipientsToEmailAddressTemplate('groups', transformedGroups));
      } else if (data.typeOfRecipient.value === 'users') {
        const transformedUsers = data.chooseUsers.map((user: DropDownResult) => {
          return { id: user.originalData?.id, value: user.value, text: user.text };
        });
        emailAddressDetails.push(...recipientsToEmailAddressTemplate('users', transformedUsers));
      }
      // Call the onNewAddressAdded function with the array of email addresses
      onNewAddressAdded?.(emailAddressDetails);
    } else {
      console.error('Form validation failed:', errors); // Access validation errors
    }
  };

  const modalButtons: Array<ModalDialogButtonSetting> = [
    { name: 'ok', text: t('ok'), closeModalOnClick: false, type: 'primary', onClick: () => updateRecipients() },
  ];
  const onChangeSource = (value?: DropDownResult) => {
    if (value) {
      const selectedValue = value.value as string;
      setTypeOfRecipient(selectedValue);
    }
  };

  const handleChange = (e: unknown) => {
    const result = forceAssert<DropDownResult>(e);
    onChangeSource(result);
  };

  return (
    <ModalDialog
      header={t('add-send-to-address')}
      minDialogWidth={500}
      fullScreen={false}
      buttonType="submit"
      buttonsCloseModal={false}
      visible={visible}
      showCloseIcon={true}
      buttons={modalButtons}
      shouldCheckZIndex={true}
      hidden={hidden}
    >
      <form id="addSendToAddress">
        <>
          <HfDropDown
            control={control}
            name="typeOfRecipient"
            textField="text"
            valueField="value"
            data={dropDownOptions}
            value={{ value: 'email', text: t('enter-an-email-address') }}
            label={t('type-of-recipient')}
            onChange={handleChange}
          />
          {typeOfRecipient === 'email' && (
            <>
              <HfInput control={control} name="emailAddress" label={t('email-address')} />
            </>
          )}
          {typeOfRecipient === 'users' && (
            <HfDropDownMultiSelect
              control={control}
              name="chooseUsers"
              data={getUserOptionsData}
              textField="text"
              valueField="value"
              label={t('choose-users')}
              filterLocation={DataSearchLocation.Api}
            />
          )}
          {typeOfRecipient === 'groups' && (
            <HfDropDownMultiSelect
              control={control}
              name="chooseGroups"
              textField="text"
              valueField="value"
              data={groupOptions}
              label={t('choose-groups')}
            />
          )}
          <ErrorLoadingDataNotification dataError={error} />
        </>
      </form>
    </ModalDialog>
  );
};

export default EmailThisAddressModal;
