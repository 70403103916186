import { ContinuousScrollerComparisonType, DataGridColumnSettings, DropDownResult } from '@myosh/odin-components';
import { cloneDeep, isNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { EntityReference, EntityType, ViewColumn, ViewColumnType } from '../@types/views';
import { gridCheckboxFilterOptions } from '../common/dynamic-page-utils';
import { useGetAllFormsDefaultViewQuery, useGetDefaultViewQuery, useGetViewColumnsQuery } from '../redux/services/view';
import useProfileData from './use-profile-data';
import { CombinedFieldType } from '../@types/forms';
import { extractFieldName } from '../common/data-grid-column-utils';
import { getOptionsByFieldType } from '../common/data-grid-filters';

interface GridColumnSettings {
  data?: Array<DataGridColumnSettings>;
  error: boolean;
  loading: boolean;
}

const defaultState = { loading: true, error: false };
const errorState = { loading: false, error: true };

/**
 * A hook that provides the view config, which essentially represents the grid columns settings
 *
 * @param {boolean} isAllForms - Indicates if the view is the special 'all forms' view.
 * @param {number} formId - The forms settings we need to retrieve.
 * @param {number} configId - The view configuration id.
 * @param {number} moduleId - The module id, passed when viewing the 'all forms'.
 * @param {boolean} hideFirstColumn - Hide first column config setting.
 *
 * @returns {GridColumnSettings} The settings or an error.
 */
const useGridColumnsSettings = (
  isAllForms: boolean,
  formId?: number,
  configId?: number,
  moduleId?: number,
  hideFirstColumn?: boolean
) => {
  const [settings, setSettings] = useState<GridColumnSettings>(defaultState);

  const { profileData: { user: userData } = {} } = useProfileData();
  const isSuperUser = userData?.superUser;

  const {
    data: viewColumns,
    isFetching,
    isUninitialized,
    isError,
  } = useGetViewColumnsQuery(
    {
      configId,
    },
    { skip: !configId || isSuperUser }
  );

  const {
    data: allFormsDefaultViewColumns,
    isFetching: isAllFormsDefaultViewFetching,
    isUninitialized: isAllFormsDefaultViewUninitialized,
    isError: isAllFormsDefaultViewError,
  } = useGetAllFormsDefaultViewQuery(
    {
      moduleId,
    },
    { skip: !isSuperUser || !isAllForms || !moduleId }
  );

  const {
    data: defaultViewColumns,
    isFetching: isDefaultViewFetching,
    isUninitialized: isDefaultViewUninitialized,
    isError: isDefaultViewError,
  } = useGetDefaultViewQuery(
    {
      formId,
    },
    { skip: isAllForms || !isSuperUser || !formId }
  );

  useEffect(() => {
    if (!isAllForms && isSuperUser) {
      const queryPerformed = !isDefaultViewFetching && !isDefaultViewUninitialized;
      if (queryPerformed) {
        const hasData = defaultViewColumns && !isDefaultViewError;
        const hasError = isDefaultViewError || !defaultViewColumns;
        if (hasData) {
          setSettings({
            loading: false,
            error: false,
            data: viewColumnsToGridColumns(cloneDeep(defaultViewColumns)),
          });
        } else if (hasError) {
          setSettings(errorState);
        }
      } else if (isDefaultViewUninitialized || isDefaultViewFetching) {
        setSettings((settings) => {
          return { ...settings, loading: true, error: false };
        });
      }
    }
  }, [
    defaultViewColumns,
    isDefaultViewFetching,
    isDefaultViewUninitialized,
    isDefaultViewError,
    isAllForms,
    isSuperUser,
  ]);

  useEffect(() => {
    if (isAllForms && isSuperUser) {
      const queryPerformed = !isAllFormsDefaultViewFetching && !isAllFormsDefaultViewUninitialized;
      if (queryPerformed) {
        const hasData = allFormsDefaultViewColumns && !isAllFormsDefaultViewError;
        const hasError = isAllFormsDefaultViewError || !allFormsDefaultViewColumns;
        if (hasData) {
          setSettings({
            loading: false,
            error: false,
            data: viewColumnsToGridColumns(cloneDeep(allFormsDefaultViewColumns), isAllForms),
          });
        } else if (hasError) {
          setSettings(errorState);
        }
      } else if (isAllFormsDefaultViewUninitialized || isAllFormsDefaultViewFetching) {
        setSettings((settings) => {
          return { ...settings, loading: true, error: false };
        });
      }
    }
  }, [
    allFormsDefaultViewColumns,
    isAllFormsDefaultViewFetching,
    isAllFormsDefaultViewUninitialized,
    isAllFormsDefaultViewError,
    isAllForms,
    isSuperUser,
  ]);

  useEffect(() => {
    if (!isSuperUser) {
      const queryPerformed = !isFetching && !isUninitialized;
      if (queryPerformed) {
        const hasData = viewColumns && !isError;
        const hasError = isError || !viewColumns;
        if (hasData) {
          setSettings({
            loading: false,
            error: false,
            data: viewColumnsToGridColumns(cloneDeep(viewColumns), hideFirstColumn, isAllForms),
          });
        } else if (hasError) {
          setSettings(errorState);
        }
      } else if (isUninitialized || isFetching) {
        setSettings((settings) => {
          return { ...settings, loading: true, error: false };
        });
      }
    }
  }, [viewColumns, isFetching, isUninitialized, isError, isSuperUser]);

  return settings;
};

export default useGridColumnsSettings;

// Helper methods
function viewColumnsToGridColumns(viewConfig: Array<ViewColumn>, hideFirstColumn?: boolean, isAllForms = false) {
  const initialArray: Array<ViewColumn> = [];
  return viewConfig
    ?.sort((a, b) => a.position - b.position)
    .reduce((columnArray, columnItem) => {
      if (columnItem.entityReference) {
        columnArray.push(columnItem);
      }
      return columnArray;
    }, initialArray)
    .filter((item) => !hideFirstColumn || item.position !== 1)
    .map((column) => {
      const entityReference = column.entityReference;
      return {
        id: column.id || 0,
        title: entityReference?.entityName || '',
        sortColumn: column.sortColumn || undefined,
        ascending: column.ascending || undefined,
        field: entityReference?.entityPropertyName || '',
        visible: column.visible,
        sortSequence: column.sortSequence ?? undefined,
        isIdField: column.key === 'ID',
        searchType: getSearchType(entityReference?.filterType),
        searchValue: getSearchValue(column),
        searchComparison: getSearchComparison(column),
        valuePath: getColumnValuePath(entityReference),
        sortPath: getColumnSortPath(entityReference),
        showBlankAction: showBlankAction(
          entityReference?.entityType,
          entityReference?.fieldType,
          entityReference?.entityPropertyName
        ),
        showArchivedAction: showArchivedAction(
          entityReference?.entityType,
          entityReference?.fieldType,
          entityReference?.entityPropertyName
        ),
        includeArchived: column.includeArchived,
        minWidth: getColumnMinWidth(entityReference),
        maxWidth: getColumnMaxWidth(),
        allowSelectAll: showSelectAll(
          isAllForms,
          entityReference?.entityType,
          entityReference?.fieldType,
          entityReference?.entityPropertyName,
          entityReference?.linkedEntityType
        ),
        customDataProperties: {
          ...entityReference,
          field: entityReference?.entityPropertyName || '',
          sortEntityType: getSortEntityType(entityReference?.entityType),
        },
      } as DataGridColumnSettings;
    });
}

function getSearchType(filterType?: ViewColumnType) {
  switch (filterType) {
    case 'BOOLEAN':
      return 'SingleSelect';
    case 'MULTISELECTRANGE':
      return 'MultiSelect';
    case 'NUMBER':
    case 'NUMBER_EQUALS':
      return 'Integer';
    case 'DATE':
      return 'DateRange';
    case 'STRING':
      return 'TextSearch';
    default:
      'TextSearch';
  }
}

function getSearchValue(column: ViewColumn) {
  const filterType = column.entityReference?.filterType;
  if ('MULTISELECTRANGE' === filterType) {
    const selectedFilters: DropDownResult[] = [];
    column?.filterConfiguration?.includeBlank &&
      selectedFilters.push({
        text: 'Blank',
        value: 'blank',
      });
    column?.filterConfiguration?.values?.map((item) => {
      selectedFilters.push({
        text: item.value as string,
        value: item.id ?? item.optionIds ?? item.value, // default to 'item.value', as i.e. in AllForms views an item id might not be available
        originalData: item,
      });
    });
    return selectedFilters;
  } else if ('DATE' === filterType) {
    const leftBoundary = column?.filterConfiguration?.leftBoundary as string;
    const rightBoundary = column?.filterConfiguration?.rightBoundary as string;
    if (leftBoundary || rightBoundary) {
      const _leftBoundary = leftBoundary ? new Date(leftBoundary) : undefined;
      const _rightBoundary = rightBoundary ? new Date(rightBoundary) : undefined;
      return [_leftBoundary, _rightBoundary];
    }
  } else if ('BOOLEAN' === filterType) {
    if (column?.filterConfiguration?.targetValue === true) {
      return {
        text: gridCheckboxFilterOptions[0].caption,
        value: gridCheckboxFilterOptions[0].id,
      };
    } else if (column?.filterConfiguration?.targetValue === false) {
      return {
        text: gridCheckboxFilterOptions[1].caption,
        value: gridCheckboxFilterOptions[1].id,
      };
    }
  } else if ('NUMBER' === filterType || 'NUMBER_EQUALS' === filterType) {
    if (column.filterConfiguration?.includeLeftBoundary || column.filterConfiguration?.includeRightBoundary) {
      const leftBoundary = column.filterConfiguration?.leftBoundary;
      const rightBoundary = column.filterConfiguration?.rightBoundary;

      if (isNumber(leftBoundary) && isNumber(rightBoundary)) {
        return [leftBoundary?.toString(), rightBoundary?.toString()];
      } else if (isNumber(leftBoundary)) {
        return leftBoundary?.toString();
      } else if (isNumber(rightBoundary)) {
        return rightBoundary?.toString();
      }
    } else {
      return column.filterConfiguration?.targetValue?.toString();
    }
  } else {
    return column?.filterConfiguration?.targetValue;
  }
}

function getSearchComparison(column: ViewColumn) {
  const filterType = column.entityReference?.filterType;
  if ('NUMBER' === filterType || 'NUMBER_EQUALS' === filterType) {
    const { includeLeftBoundary, includeRightBoundary, leftBoundary, rightBoundary } = {
      ...column.filterConfiguration,
    };
    if (includeLeftBoundary || includeRightBoundary) {
      if (isNumber(leftBoundary) && isNumber(rightBoundary)) {
        return 2 as ContinuousScrollerComparisonType;
      } else if (isNumber(leftBoundary)) {
        return 6 as ContinuousScrollerComparisonType;
      } else if (isNumber(rightBoundary)) {
        return 4 as ContinuousScrollerComparisonType;
      }
    }

    return 1 as ContinuousScrollerComparisonType;
  }
}

function getSortEntityType(entityType?: EntityType): EntityType | undefined {
  switch (entityType) {
    case 'QUESTIONNAIRE':
    case 'GRAND_SCORE':
    case 'RECORD_LINK':
    case 'REVERSE_RECORD_LINK':
      return 'FIELD';
    default:
      return entityType;
  }
}

function showArchivedAction(entityType?: EntityType, fieldType?: CombinedFieldType, entityPropertyName?: string) {
  // 'Archived' is enabled only for specific fields (https://myosh.atlassian.net/browse/MYOSH-4362?focusedCommentId=48283)
  if ('FIELD' === entityType) {
    switch (fieldType) {
      case 'TRAINING':
      // option fields
      case 'COMBOBOX':
      case 'MULTISELECTFIELD':
      case 'OPTIONGROUP':
      case 'TWINCOLUMNSELECT':
      case 'MULTISELECTCHECKBOX':
      // person fields
      case 'PERSONFIELD':
      case 'MULTISELECTPERSONFIELD':
      case 'READERS':
      case 'USER_RECORDLINK':
      case 'TWINCOLUMNSELECTPERSONFIELD':
        return true;
      default:
        return false;
    }
  } else if ('HIERARCHY_TYPE' === entityType) {
    return true;
  } else if ('KEYWORD' === entityType && entityPropertyName === 'author') {
    return true;
  } else if (
    ['RECORD_LINK', 'REVERSE_RECORD_LINK'].includes(entityType ?? '') &&
    extractFieldName(entityPropertyName ?? '').toLowerCase() === 'author'
  ) {
    return true;
  }
  return false;
}

function showBlankAction(entityType?: EntityType, fieldType?: CombinedFieldType, entityPropertyName?: string) {
  if (entityPropertyName) {
    const isAuthorTypeColumn =
      ('KEYWORD' === entityType && entityPropertyName === 'author') ||
      (['RECORD_LINK', 'REVERSE_RECORD_LINK'].includes(entityType ?? '') &&
        extractFieldName(entityPropertyName).toLowerCase() === 'author');
    if (['moduleForm', 'status'].includes(entityPropertyName) || isAuthorTypeColumn) {
      return false;
    } else if ('FIELD' === entityType) {
      switch (fieldType) {
        case 'CHECKBOX':
          return false;
        default:
          true;
      }
    }
  }
  return true;
}

export function showSelectAll(
  isAllForms: boolean,
  entityType?: EntityType,
  fieldType?: CombinedFieldType,
  entityPropertyName?: string,
  linkedEntityType?: string
) {
  if (entityPropertyName) {
    const isAccountableColumn =
      ('KEYWORD' === entityType && entityPropertyName === 'accountable') ||
      (['RECORD_LINK', 'REVERSE_RECORD_LINK'].includes(entityType ?? '') &&
        extractFieldName(entityPropertyName).toLowerCase() === 'accountable');
    const isAuthorTypeColumn =
      ('KEYWORD' === entityType && entityPropertyName === 'author') ||
      (['RECORD_LINK', 'REVERSE_RECORD_LINK'].includes(entityType ?? '') &&
        extractFieldName(entityPropertyName).toLowerCase() === 'author');
    const isHierarchyColumnType = entityType === 'HIERARCHY_TYPE' || linkedEntityType === 'HIERARCHY_TYPE';
    const isAllFormsFieldType =
      isAllForms && entityType === 'FIELD' && getOptionsByFieldType(fieldType as CombinedFieldType) === 'FIELD_OPTIONS';
    const isAllFormsPersonFieldType =
      isAllForms &&
      entityType === 'FIELD' &&
      getOptionsByFieldType(fieldType as CombinedFieldType) === 'PERSON_OPTIONS';
    if (
      isAccountableColumn ||
      isAuthorTypeColumn ||
      isHierarchyColumnType ||
      isAllFormsFieldType ||
      isAllFormsPersonFieldType
    ) {
      return false;
    } else {
      return true;
    }
  }
  return true;
}

/**
 * Resolves the value path for a data grid cell.
 *
 * @param entityReference The column entity reference object.
 *
 * @returns The resolve `valuePath`, or `undefined`.
 */
function getColumnValuePath(entityReference: Omit<EntityReference, 'entityName' | 'filterType' | 'fieldType'>) {
  const { entityType, entityId, entityPropertyName } = entityReference;
  let valuePath;
  if (['FIELD', 'QUESTIONNAIRE', 'GRAND_SCORE', 'RECORD_LINK', 'REVERSE_RECORD_LINK'].includes(entityType)) {
    valuePath = `fields.${entityId}`;
  } else if ('WORKFLOW_STEP' === entityType) {
    valuePath = `workflowStepHistory.${entityPropertyName}`;
  } else if ('HIERARCHY_TYPE' === entityType) {
    valuePath = `hierarchies.${entityPropertyName}`;
  }
  return valuePath;
}

/**
 * Attempts to determine the sort path based upon the field type. The sort path is only used for local sorting.
 *
 * @param {EntityReference} entityReference - This contains the field type needed to determine if a field needs a sort path.
 * @returns {string | undefined} Either the sort path or undefined is returned.
 */
function getColumnSortPath(entityReference: EntityReference) {
  switch (entityReference.fieldType) {
    case 'COMBOBOX':
    case 'OPTIONGROUP':
    case 'PERSONFIELD':
    case 'READERS':
      return '.text';
    case 'MULTISELECTFIELD':
    case 'MULTISELECTPERSONFIELD':
      return '[0].text'; // the assumption here is if multiple items are selected we would sort on the first item
    default:
      return undefined;
  }
}

/**
 * Resolves the column's minWidth.
 */
const getColumnMinWidth = (entityReference: EntityReference): number => {
  let minWidth = 100;
  const { entityType, filterType, fieldType } = entityReference;

  if ('KEYWORD' === entityType) {
    if ('MULTISELECTRANGE' == filterType) {
      minWidth = 170;
    } else if ('DATE' === filterType) {
      minWidth = 100;
    }
  } else if ('WORKFLOW_STEP' === entityType) {
    minWidth = 100;
  } else if ('HIERARCHY_TYPE' === entityType) {
    minWidth = 150;
  } else if (['QUESTIONNAIRE', 'GRAND_SCORE'].includes(entityType)) {
    minWidth = 100;
  } else if (['RECORD_LINK', 'REVERSE_RECORD_LINK'].includes(entityType)) {
    minWidth = 170;
  } else if ('FIELD' === entityType) {
    // adjust based on filter type
    if ('MULTISELECTRANGE' == filterType) {
      minWidth = 170;
      if ('OPTIONGROUP' === fieldType) {
        minWidth = 150;
      }
    } else if ('DATE' === filterType) {
      minWidth = 100;
    } else if ('BOOLEAN' === filterType) {
      minWidth = 100;
    }

    // adjust based on field type
    if (['RISKRATING', 'RICHTEXTAREA', 'HISTORY'].includes(fieldType)) {
      minWidth = 200;
    }
  }

  return minWidth;
};

/**
 * Resolves the column's maxWidth.
 */
const getColumnMaxWidth = (): number => {
  return 400;
};
