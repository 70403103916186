import {
  DataGrid,
  DataGridCellRenderer,
  DataGridCellRendererProps,
  DataGridColumnSettings,
  DataGridDataCell,
  DataGridDataRowTemplateProps,
  DataGridRef,
  DataGridSettings,
  DataSearchLocation,
  DynamicFormFieldType,
  JsonDataWrapper,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  RowTemplateType,
} from '@myosh/odin-components';
import React, { Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { resolveFieldDisplayValue } from '../../../common/field-property-util';
import { UserFields } from '../../../common/user-config';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useGetUsersQuery } from '../../../redux/services/user';
import { selectActiveRecordId } from '../../../redux/slices/layout';
import useProfileData from '../../../hooks/use-profile-data';
import { UsersPermissions } from '../../../common/user.permissions';
import i18next from '../../../i18n';
import { setDataGridReference } from '../../../services/data-grid.service';
import PrimaryViewActionButton from '../../../components/common/primary-view-action-button';
import { dateFormatBasedOnFilter, processFieldFilters } from '../../../common/common-functions';
import { getUsersFieldOptionsData } from '../../../common/user-functions';
import { useHierarchyTypesQuery } from '../../../redux/services/hierarchy';
import { HierarchyType } from '../../../@types/hierarchy-fields';
import { getUsersColumnConfig } from './users-utils';
import ErrorLoadingDataNotification from '../../../components/common/error-loading-data-notification';
import { showInfo } from '../../../services/notification.service';
import useActiveRecordRef from '../../../hooks/use-active-record-ref';
import { AdminTabs, recordGridSettingsOptions } from '../admin-utils';
import { useTranslation } from 'react-i18next';
import useDoubleClick from '../../../hooks/use-double-click';

const customUserDataGridCells: Record<string, DataGridCellRenderer> = {
  CUSTOM_USER_DATA_CELL: {
    CellComponent: ({ column, rowData }: DataGridCellRendererProps, ref: Ref<HTMLDivElement>) => {
      return (
        <DataGridDataCell ref={ref} value={resolveFieldDisplayValue(rowData[column.field])} width={column.width} />
      );
    },
  },
};

const getUsersPageDataGridColumns = (hierarchyTypes: Array<HierarchyType>): DataGridColumnSettings[] => {
  const configurations = getUsersColumnConfig(hierarchyTypes);
  return Object.keys(configurations).map((field) => {
    const columnConfig = configurations[field];
    return {
      id: field,
      field: field,
      title: columnConfig?.title ?? i18next.t(field),
      type: columnConfig?.type || 0,
      isIdField: columnConfig?.isIdField,
      visible: columnConfig?.visible !== false,
      cellRenderer: columnConfig?.cellRenderer,
      valuePath: columnConfig?.valuePath,
      searchType: columnConfig?.searchType,
      dropDownText: columnConfig?.dropDownText,
      dropDownValue: columnConfig?.dropDownValue,
      searchValue: columnConfig?.searchValue,
      showBlankAction: columnConfig?.showBlankAction,
      customDataProperties: columnConfig?.customDataProperties,
    } as DataGridColumnSettings;
  });
};

export default function UsersPage() {
  const [gridOptions, setGridOptions] = useState<OdinDataRetrievalOptions>();
  const gridRef = useRef<DataGridRef>();
  const gridSubscriber = useRef<OdinDataSender<JsonDataWrapper>>();
  const { activeRecordReference } = useActiveRecordRef();
  const { rowClickHandler } = useDoubleClick();

  const { profileData: { user: userData } = {} } = useProfileData();
  const { data: hierarchyTypes } = useHierarchyTypesQuery({ archived: 'false' });
  const activeRecordId = useAppSelector(selectActiveRecordId);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    data: usersData,
    isFetching,
    error,
  } = useGetUsersQuery(
    {
      fields: UsersPageFields,
      page: gridOptions?.page ?? 1,
      pageSize: gridOptions?.pageSize ?? 50,
      sort: gridOptions?.sortedFields,
      filters: processFieldFilters(gridOptions?.fieldFilters, dateFormatBasedOnFilter),
    },
    {
      skip: gridOptions === undefined || gridOptions?.page === undefined || gridOptions?.pageSize === undefined,
    }
  );

  useEffect(() => {
    if (activeRecordId) {
      gridRef.current?.api.data.addSelectedRows([{ id: activeRecordId }], true);
    }
  }, [activeRecordId]);

  useEffect(() => {
    if (usersData && !isFetching) {
      gridSubscriber.current?.sendData({ data: usersData, requestId: gridOptions?.requestId });
    } else if (error) {
      gridSubscriber.current?.sendData();
    }
  }, [usersData, isFetching, error]);

  const gridData = useMemo<OdinDataRetrieval>(() => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if (options?.fieldType === 'COMBOBOX') {
          await getUsersFieldOptionsData(subscriber, options, dispatch);
        } else {
          setGridOptions(options);
        }
      },

      getSubscriber: (subscriber, fieldType?: DynamicFormFieldType) => {
        if (fieldType !== 'COMBOBOX') {
          gridSubscriber.current = subscriber;
        }
      },
    };
  }, []);

  const gridSettings = useMemo(() => {
    if (hierarchyTypes) {
      return {
        columns: getUsersPageDataGridColumns(hierarchyTypes),
        autoSizeColumns: true,
        fullHeight: true,
        filterLocation: DataSearchLocation.Api,
        components: customUserDataGridCells,
        initialSortedFields: [{ scrollerId: UserFields.id, field: UserFields.lastName, sortDirection: 0, order: 1 }],
      } satisfies DataGridSettings;
    }
  }, [hierarchyTypes]);

  const onCogMenuItemClicked = useCallback((item: string) => {
    // Rest of the menu items are not available.
    if (item === 'columns' || item === 'restore') return;

    showInfo(t('feature-not-available'));
  }, []);

  const rowTemplate: RowTemplateType = useCallback(
    (props: DataGridDataRowTemplateProps, children: React.ReactNode | React.ReactNode[]) => {
      const handleRowClick = (isFullScreen?: boolean) => {
        const userId = String(props.data[UserFields.id]);
        const details = `${props.data[UserFields.firstName]} ${props.data[UserFields.lastName]}`;
        activeRecordReference.current?.addActiveRecord(userId, 'USER', details, isFullScreen, {
          userId: Number(userId),
          title: details,
          isFullScreen,
          administrationTab: AdminTabs.USERS,
        });
      };

      const onClick = () => {
        rowClickHandler(handleRowClick);
      };

      return (
        // matches the structure of the dynamic.page row
        <div className="flex w-full" onClick={onClick}>
          <div className="flex flex-grow items-center" onClick={props.onRowClicked}>
            {children}
          </div>
        </div>
      );
    },
    []
  );

  const onAddClick = useCallback(() => {
    const panelCount = activeRecordReference.current?.currentRecordCount() ?? 0;
    const details = t('new-user');

    activeRecordReference.current?.addActiveRecord(`new_${panelCount + 1}`, 'USER', details, false, {
      isNewUser: true,
      title: details,
      administrationTab: AdminTabs.USERS,
    });
  }, []);

  const onDataGridRefCreated = (dataGridRef: DataGridRef) => {
    gridRef.current = dataGridRef;
    setDataGridReference(dataGridRef);
  };

  const canCreateUser = useMemo(
    () => userData?.adminAccess && userData?.adminAccess.includes(UsersPermissions.USERS_CREATE),
    [userData]
  );

  return (
    <div className="flex h-full w-full flex-col">
      {canCreateUser && (
        <div className="mb-1">
          <PrimaryViewActionButton onClick={onAddClick}>{t('add')}</PrimaryViewActionButton>
        </div>
      )}
      {gridSettings && (
        <DataGrid
          ref={(gridRef) => gridRef && onDataGridRefCreated(gridRef)}
          data={gridData}
          gridSettings={gridSettings}
          onCogMenuItemClicked={onCogMenuItemClicked}
          rowTemplate={rowTemplate}
          customSettingsMenuItems={recordGridSettingsOptions}
        />
      )}
      <ErrorLoadingDataNotification dataError={error} />
    </div>
  );
}

// The data to be retrieved when fetching user information
const UsersPageFieldsArray = Object.keys(getUsersColumnConfig([])).concat(
  UserFields.userHierarchy,
  UserFields.email,
  UserFields.phone,
  UserFields.localeId,
  UserFields.vikingAnalytics,
  UserFields.vikingAnalyticsContributor,
  UserFields.ssoUser
);

export const UsersPageFields = UsersPageFieldsArray.join();
