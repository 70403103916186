import React from 'react';
import { Control } from 'react-hook-form';
import { OdinController } from '@myosh/odin-components';
import CloneRecordButtonField, { CloneRecordButtonFieldProps } from './clone-record-button-field.component';

interface HfCloneRecordButtonFieldProps extends CloneRecordButtonFieldProps {
  control?: Control;
  name?: string;
}

function HfCloneRecordButtonField({
  id,
  name = 'clone_record_button',
  control,
  buttonCaption,
  recordId,
  readOnly,
  saveRecord,
  recordSaveState,
  formId,
  shouldUseCloneWizzard,
  cloneWizardTargetPersonField,
  cloneWizardTargetWorkflowStep,
  allowUserAttachmentCloning,
  moduleFormCloneTargetId,
}: HfCloneRecordButtonFieldProps) {
  return (
    <OdinController
      name={name}
      control={control}
      render={() => (
        <CloneRecordButtonField
          id={id}
          buttonCaption={buttonCaption}
          recordId={recordId}
          readOnly={readOnly}
          saveRecord={saveRecord}
          recordSaveState={recordSaveState}
          formId={formId}
          shouldUseCloneWizzard={shouldUseCloneWizzard}
          cloneWizardTargetPersonField={cloneWizardTargetPersonField}
          cloneWizardTargetWorkflowStep={cloneWizardTargetWorkflowStep}
          allowUserAttachmentCloning={allowUserAttachmentCloning}
          moduleFormCloneTargetId={moduleFormCloneTargetId}
        />
      )}
    />
  );
}

export default HfCloneRecordButtonField;
