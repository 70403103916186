import { useEffect, useRef, useState } from 'react';
import useFormPermissions from '../../../hooks/use-form-permissions';
import useRecordPermissions from '../../../hooks/use-record-permissions';
import { extractRecordPermissions } from '../../../common/form-permissions-util';

/**
 * A hook that handles record permissions and edit/read mode.
 *
 * @param {string} recordId - The record id.
 * @param {number} formId - The form id.
 * @param {boolean} openInEditMode - Indicates if the record should open in edit mode
 *
 * @returns {{recordPanelReadOnlyMode: boolean, canEditRecord: boolean, formPermissions: FormPermissionsResults,isFormPermissionsLoading: boolean, recordPermissions: FormPermissionsResults, isRecordPermissionLoading: boolean,currentRecordPermissions: CurrentRecordPermisions, onRecordsEdit: () => void }}
 */

const useRecordPermissionsAndMode = (recordId?: string, formId?: number, openInEditMode?: boolean) => {
  const [recordPanelReadOnlyMode, setRecordPanelReadOnlyMode] = useState(Boolean(recordId) !== openInEditMode); // simulate logical XOR
  const editButtonClickedRef = useRef(Boolean(recordId) === openInEditMode);

  const { formPermissions, isLoading: isFormPermissionsLoading } = useFormPermissions(formId);
  const { recordPermissions, isLoading: isRecordPermissionLoading } = useRecordPermissions(
    Number(recordId) || undefined
  );
  const currentRecordPermissions = extractRecordPermissions(recordPermissions);

  const canEditRecord =
    (formPermissions?.canEditRecord && currentRecordPermissions?.edit) || recordPermissions?.canEditRecord;

  const onRecordsEdit = () => {
    setRecordPanelReadOnlyMode(false);
    editButtonClickedRef.current = true;
  };

  useEffect(() => {
    canEditRecord && editButtonClickedRef.current
      ? setRecordPanelReadOnlyMode(false)
      : setRecordPanelReadOnlyMode(true);
  }, [canEditRecord]);

  return {
    recordPanelReadOnlyMode,
    canEditRecord,
    formPermissions,
    isFormPermissionsLoading,
    recordPermissions,
    isRecordPermissionLoading,
    currentRecordPermissions,
    onRecordsEdit,
  };
};

export default useRecordPermissionsAndMode;
