import React from 'react';
import { Helmet } from 'react-helmet';

interface MetaProps {
  charSet?: string;
  title?: string;
  description?: string;
  canonical?: string;
  viewport?: string;
}

const Meta = ({
  charSet = 'utf-8',
  title = 'myosh',
  description = 'myosh OHS - Safety Management Software - Health & Safety Software',
  canonical = 'https://myosh.com/',
  viewport = 'width=device-width, initial-scale=1',
}: MetaProps) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet={charSet} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonical} />
      <meta name="viewport" content={viewport} />
    </Helmet>
  );
};

export default Meta;
