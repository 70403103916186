import { OdinIcon, OdinIconSize } from '@myosh/odin-components';
import cx from 'classnames';
import DOMPurify from 'dompurify';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateNewsItemDismissedMutation, useUpdateNewsItemSeenMutation } from '../../redux/services/api';
import CloseActiveItemButton from '../common/close-active-item-button';

export interface NewsEntryProps {
  id: number;
  date: string;
  heading: string;
  article?: string;
  author: string;
  alert: boolean;
  url?: string;
  openInNewWindow: boolean;
  expired: string;
  newsPublishState: string;
  seen?: string;
  impressionId?: number;
}

export interface NewsApiProps {
  dismissed: string;
  markAsSeen?: boolean;
  type?: string;
  publishState?: string;
}
export interface NewsItemDismissed {
  dismissed: string;
  id: number;
}
export interface NewsItemSeen {
  newsItemId: number;
  seen: string;
}

const customDOMPurify = DOMPurify();

const customAddHookCallBack = (node: Element) => {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
  }
};

customDOMPurify.addHook('afterSanitizeAttributes', customAddHookCallBack);

const NewsEntry = ({ date, heading, article, author, alert, url, seen, impressionId, id }: NewsEntryProps) => {
  const { t } = useTranslation();
  const [updateNewsItemSeen, { isLoading: newsItemUpdating }] = useUpdateNewsItemSeenMutation();
  const [updateNewsitemDismissed, { isLoading: itemDismissing }] = useUpdateNewsItemDismissedMutation();

  const createMarkup = (value: string) => {
    return { __html: customDOMPurify.sanitize(value, { USE_PROFILES: { html: true }, ADD_ATTR: ['target'] }) };
  };

  const conditionalStyle = cx('custom-scroll relative max-sm:overflow-x-auto max-sm:my-1', {
    'bg-gray-5 border border-gray-4 rounded': !alert,
    'bg-error text-mono-1 flex w-full max-sm:flex-col sm:flex-row relative': alert,
    'bg-mono-1 hover:bg-primary-5 cursor-pointer': !seen && !alert,
    'cursor-pointer': url,
  });

  const labelColor = cx(
    'relative inline-flex items-center justify-center rounded-r-lg h-8 w-16 text-mono-1 p-1 mr-2 text-sm shrink-0',
    {
      'bg-warning': !seen,
      'bg-success': seen,
    }
  );

  const publishDate = new Date(date);

  const dismissNewsItem = () => {
    if (impressionId) {
      const formatedDate = new Date().toISOString();
      const payload = {
        dismissed: formatedDate,
        id: impressionId,
      };
      updateNewsitemDismissed(payload);
    }
  };

  const markAsSeen = () => {
    if (!newsItemUpdating && !seen) {
      const formatedDate = new Date().toISOString();
      const payload = {
        seen: formatedDate,
        newsItemId: id,
      };
      updateNewsItemSeen(payload);
    }
  };

  const openUrlinNewTab = () => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleClickCardContainer = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // disabling onClick event for anchor tag
    if ((e.target as HTMLElement).tagName !== 'A') {
      markAsSeen();
      openUrlinNewTab();
    }
  };

  const headerElement = useMemo(() => {
    if (seen) {
      return (
        <div className="flex flex-row space-between p-1">
          <h2 className="w-full text-xl p-1">
            <strong>{heading}</strong>
          </h2>
        </div>
      );
    }
    return (
      <div className="flex flex-row text-lg">
        <span className={labelColor}>{t('new')}</span>
        <h3>
          <strong>{heading}</strong>
        </h3>
      </div>
    );
  }, [seen, itemDismissing, heading]);

  const bottomInfo = useMemo(() => {
    return (
      <div className="flex font-bold justify-center border-t border-gray-4">
        <span className="mb-2 ml-1 mr-2 ">{`${publishDate.toLocaleDateString()} - ${publishDate.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })}`}</span>
        {author && <span className="before:content-['_-_'] text-center">{author}</span>}
      </div>
    );
  }, [publishDate, author]);

  return (
    <div className={conditionalStyle}>
      <span className="absolute top-1 right-1">
        <CloseActiveItemButton
          onClick={dismissNewsItem}
          disabled={itemDismissing}
          spin={itemDismissing}
          size={alert ? OdinIconSize.Small : OdinIconSize.Medium}
        />
      </span>

      {!alert && (
        <div className="flex w-full flex-col" onClick={handleClickCardContainer}>
          {headerElement}
          <div className="flex flex-row w-full">
            <div className="flex w-full flex-col p-4">
              {article && <div dangerouslySetInnerHTML={createMarkup(article)} />}
            </div>
          </div>
          {bottomInfo}
        </div>
      )}
      {alert && (
        <div className="flex flex-col gap-2 p-4 pl-8">
          <div className="flex flex-row items-center gap-4">
            <OdinIcon icon="Alert" size={OdinIconSize.Medium} />
            <span className="text-xl">
              <strong>
                {typeof url !== 'undefined' && (
                  <a href={url} rel="noreferrer" target="_blank">
                    {heading}
                  </a>
                )}
                {!url && <a>{heading}</a>}
              </strong>
            </span>
          </div>
          {article && <div className="pl-12" dangerouslySetInnerHTML={createMarkup(article)} />}
        </div>
      )}
    </div>
  );
};

export default NewsEntry;
