import { useState, useEffect } from 'react';
import { useGetReverseLinkedRecordsQuery } from '../redux/services/record';
import { LinkedRecordResult } from '../@types/linked-records-field';
import { FieldSorts } from '../@types/common';

const mapLinkedRecordIds = (linkedRecordIds?: Array<string | number>) => {
  return linkedRecordIds?.map((linkedRecordId) => {
    return Number(linkedRecordId);
  });
};

/**
 * A hook that fetches records of reverse linked records fields, by recordId and fieldId and linkedRecordIds.
 * @param {number} recordId
 * @param {number} fieldId
 * @param {Array<number | string>} linkedRecordIds
 * @param {FieldSorts} sorts
 * @returns {{isFetching: boolean, linkedRecords: Array<LinkedRecordResult>}}
 */
const useFetchReverseLinkedRecords = (
  recordId?: number,
  fieldId?: number,
  linkedRecordIds?: Array<number | string>,
  sorts?: FieldSorts
) => {
  const [reverseLinkedRecordsData, setReverseLinkedRecordsData] = useState<Array<LinkedRecordResult>>([]);

  const {
    data: recordData,
    isFetching: isRecordDataFetching,
    isLoading: isRecordDataLoading,
  } = useGetReverseLinkedRecordsQuery(
    { recordId, fieldId, linkedRecordIds: mapLinkedRecordIds(linkedRecordIds), sorts },
    {
      skip:
        recordId === undefined ||
        fieldId === undefined ||
        linkedRecordIds === undefined ||
        linkedRecordIds.length === 0,
    }
  );

  useEffect(() => {
    if (recordData && !isRecordDataFetching) {
      setReverseLinkedRecordsData(recordData);
    }
  }, [recordData, isRecordDataFetching]);

  return {
    reverseLinkedRecordsData,
    isFetching: isRecordDataFetching,
    isLoading: isRecordDataLoading,
  };
};

export default useFetchReverseLinkedRecords;
