import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyGetFilesByRecordIdAndQuestionIdQuery } from '../../../../redux/services/file';
import { AttachmentItem } from '../../../../@types/attachments';
import {
  AnswerItemConfiguration,
  QuestionnaireConfigProps,
  QuestionnaireValue,
} from '../../../../@types/questionnaire';
import DataCellLoading from '../../../data-grid-data-cells/data-cell-loading';
import useLinkedRecordsDisplayValue from '../use-linked-records-display-value';
import { forceAssert } from '../../../../common/common-functions';

interface QuestionnaireTableCellProps {
  value: QuestionnaireValue;
  configuration: QuestionnaireConfigProps;
  recordId?: string;
}

const QuestionnaireTableCell = ({ value, configuration, recordId }: QuestionnaireTableCellProps) => {
  const valueData = useMemo(() => {
    return value
      .map((questionnaireValueItem) => {
        const questionConfig = configuration.questions.find(
          (question) => question.id === questionnaireValueItem.questionId
        );
        const answerConfig =
          configuration.type === 'TEXTUAL_QUESTIONNAIRE'
            ? forceAssert<AnswerItemConfiguration[]>(configuration.answers).find(
                (answer) => answer.id === questionnaireValueItem.id
              )
            : configuration.questions.find((answer) => answer.id === questionnaireValueItem.questionId);
        if (questionConfig && answerConfig) {
          return {
            position: questionConfig.position,
            questionConfig,
            answerConfig,
            questionnaireValueItem,
          };
        }
      })
      .filter((item) => item !== undefined)
      .sort((item1, item2) => (item1?.position ?? 0) - (item2?.position ?? 0));
  }, [value, configuration]);

  return (
    <>
      {valueData?.map((item) => {
        if (item) {
          const { questionnaireValueItem, questionConfig, answerConfig } = item;
          const { observations, linkedRecordIds, questionId } = questionnaireValueItem;

          const itemValue =
            configuration.type === 'TEXTUAL_QUESTIONNAIRE'
              ? `${questionConfig.caption}: ${answerConfig.caption}`
              : `${questionConfig.caption}: ${questionnaireValueItem.numericAnswer}`;
          return (
            <div key={questionConfig.id} className="pl-2">
              {itemValue}
              <div className="flex flex-col gap-y-2 pl-2">
                <AttachmentCell recordId={recordId} questionId={questionId} />
                <RecordlinkCell recordIds={linkedRecordIds ?? []} />
                <ObservationsCell observations={observations} />
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

export default QuestionnaireTableCell;

// observations
interface ObservationCellProps {
  observations?: string;
}

const ObservationsCell = ({ observations }: ObservationCellProps) => {
  const { t } = useTranslation();
  return (
    <DetailsCell isLoading={false} hasValue={Boolean(observations)} value={`${t('observations')}: ${observations}`} />
  );
};

// linked records
interface RecordlinkCellProps {
  recordIds: Array<string>;
}

const RecordlinkCell = ({ recordIds }: RecordlinkCellProps) => {
  const { t } = useTranslation();
  const { value, isLoading } = useLinkedRecordsDisplayValue(recordIds);

  return <DetailsCell isLoading={isLoading} hasValue={Boolean(value)} value={`${t('linked-records')}: ${value}`} />;
};

// attachments
interface AttachmentCellProps {
  recordId?: string;
  questionId: number;
}

const AttachmentCell = ({ recordId, questionId }: AttachmentCellProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [finalData, setFinalData] = useState<AttachmentItem[]>([]);
  const [getQuestionnaireAttachments] = useLazyGetFilesByRecordIdAndQuestionIdQuery();
  const { t } = useTranslation();

  useEffect(() => {
    if (recordId && questionId) {
      setIsLoading(true);
      getQuestionnaireAttachments({ recordId, questionId })
        .then((result) => setFinalData(result?.data ?? []))
        .finally(() => setIsLoading(false));
    }
  }, [recordId, questionId]);

  const displayValue = useMemo(() => {
    if (finalData && finalData.length > 0) {
      return finalData.map((attachment) => attachment.name).join(', ');
    }
    return '';
  }, [finalData]);

  return (
    <DetailsCell
      isLoading={isLoading}
      hasValue={Boolean(finalData && finalData.length > 0)}
      value={`${t('attachments')}: ${displayValue}`}
    />
  );
};

// more details display value cell
interface DetailsCellProps {
  isLoading: boolean;
  hasValue: boolean;
  value: string;
}

const DetailsCell = ({ isLoading, hasValue, value }: DetailsCellProps) => {
  return isLoading ? <DataCellLoading /> : hasValue ? <div className="px-2 leading-5">{value}</div> : <></>;
};
