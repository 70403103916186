import { DropDownResult } from '@myosh/odin-components';
import i18next from '../../../i18n';

export type IntervalType = DropDownResult & Record<string, unknown>;
export const timeIntervals: Array<IntervalType> = [
  { text: i18next.t('interval-daily'), value: 'DAILY' },
  { text: i18next.t('interval-weekly'), value: 'WEEKLY', displayWeek: true },
  { text: i18next.t('interval-monthly'), value: 'MONTHLY', displayDayOfMonth: true },
  { text: i18next.t('interval-quarterly'), value: 'QUARTERLY', displayMonth: true, displayDayOfMonth: true },
  { text: i18next.t('interval-biannual'), value: 'BIANNUAL', displayMonth: true, displayDayOfMonth: true },
  { text: i18next.t('interval-annual'), value: 'YEARLY', displayMonth: true, displayDayOfMonth: true },
];

export const dayValues: Array<IntervalType> = [
  { text: i18next.t('weekdays-monday'), value: 'MONDAY' },
  { text: i18next.t('weekdays-tuesday'), value: 'TUESDAY' },
  { text: i18next.t('weekdays-wednesday'), value: 'WEDNESDAY' },
  { text: i18next.t('weekdays-thursday'), value: 'THURSDAY' },
  { text: i18next.t('weekdays-friday'), value: 'FRIDAY' },
  { text: i18next.t('weekdays-saturday'), value: 'SATURDAY' },
  { text: i18next.t('weekdays-sunday'), value: 'SUNDAY' },
];

export const monthValues: Array<IntervalType> = [
  { text: i18next.t('months-january'), value: 'JANUARY' },
  { text: i18next.t('months-february'), value: 'FEBRUARY' },
  { text: i18next.t('months-march'), value: 'MARCH' },
  { text: i18next.t('months-april'), value: 'APRIL' },
  { text: i18next.t('months-may'), value: 'MAY' },
  { text: i18next.t('months-june'), value: 'JUNE' },
  { text: i18next.t('months-july'), value: 'JULY' },
  { text: i18next.t('months-august'), value: 'AUGUST' },
  { text: i18next.t('months-september'), value: 'SEPTEMBER' },
  { text: i18next.t('months-october'), value: 'OCTOBER' },
  { text: i18next.t('months-november'), value: 'NOVEMBER' },
  { text: i18next.t('months-december'), value: 'DECEMBER' },
];

export function getAllTimezones() {
  return Intl.supportedValuesOf('timeZone').map((timeZone) => {
    const offset = getTimeZoneOffset(timeZone);
    return { text: `${timeZone} (${offset})`, value: timeZone };
  });
}

export const getTimeZoneOffset = (timeZone: string) => {
  return new Intl.DateTimeFormat('en', { timeZone: timeZone, timeZoneName: 'shortOffset' })
    .formatToParts()
    .find((part) => part.type === 'timeZoneName')?.value;
};
