import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  DomElementAlignment,
  DomTargetPosition,
  IconButton,
  OdinIcon,
  OdinIconSize,
  OdinIconType,
  Tooltip,
  useTestTag,
} from '@myosh/odin-components';
import cx from 'classnames';
import { throttle } from 'lodash';
import { MIN_ALLOWED_PANEL_WIDTH, getDefaultRecordWidth } from './active-record-helper';
import { useTranslation } from 'react-i18next';

export interface RecordPanelInfo {
  recordWidth?: number;
  fullScreen?: boolean;
}

interface ActiveRecordPanelProps {
  opened?: boolean;
  fullScreen?: boolean;
  isNavCollapsed?: boolean;
  recordWidth?: number;
  defaultRecordWidth?: number;
  onPanelInfoChanged: (info: RecordPanelInfo) => void;
  children: ReactNode | ReactNode[];
  hidden?: boolean;
}

export default function ActiveRecordPanel({
  opened = false,
  fullScreen = false,
  isNavCollapsed = true,
  recordWidth,
  defaultRecordWidth = getDefaultRecordWidth(),
  onPanelInfoChanged,
  children,
  hidden = false,
}: ActiveRecordPanelProps) {
  const [currentFullScreen, setCurrentFullScreen] = useState(fullScreen);

  const { t } = useTranslation();

  const toggleFullScreen = () => {
    const newFullScreen = !currentFullScreen;
    setCurrentFullScreen(newFullScreen);
    onPanelInfoChanged({
      fullScreen: newFullScreen,
    });
  };

  const minOffset = 208;
  const [currentRecordWidth, setCurrentRecordWidth] = useState(recordWidth || defaultRecordWidth);
  const currentRecordWidthRef = useRef(recordWidth || defaultRecordWidth);

  useEffect(() => {
    if (recordWidth && recordWidth !== currentRecordWidth) {
      localStorage.setItem('recordWidth', recordWidth.toString());
      setCurrentRecordWidth(recordWidth);
    }
  }, [recordWidth]);

  useEffect(() => {
    if (fullScreen !== undefined) {
      setCurrentFullScreen(fullScreen);
    }
  }, [fullScreen]);

  const tagCreator = useTestTag('active-record-panel');

  const resizeStart = throttle((event) => {
    const windowWidth = window.innerWidth;
    const cursorOffset = event.x;
    const panelWidth = Math.max(MIN_ALLOWED_PANEL_WIDTH, Math.min(windowWidth - cursorOffset, windowWidth - minOffset));
    setCurrentRecordWidth(panelWidth);
    currentRecordWidthRef.current = panelWidth;
  }, 70);

  const resizeEnd = () => {
    onPanelInfoChanged({
      recordWidth: currentRecordWidthRef.current,
    });

    document.removeEventListener('mousemove', resizeStart);
    document.removeEventListener('mouseup', resizeEnd);
  };

  const resizeHandler = () => {
    document.addEventListener('mousemove', resizeStart);
    document.addEventListener('mouseup', resizeEnd);
  };

  const panelStyles = cx(
    'fixed z-30 flex flex-col top-0 bottom-0 right-0 pr-10 shadow-left-1 bg-gray-5 max-w-full transition-all',
    {
      hidden: hidden || !opened,
    },
    {
      '!right-72': !isNavCollapsed && currentFullScreen,
    }
  );

  const getPanelWidth = () => {
    if (currentFullScreen) {
      return isNavCollapsed ? '100%' : 'calc(100% - 18rem)'; // 18rem compensates for .right-72 in panelStyles
    } else {
      return `${currentRecordWidth}px`;
    }
  };

  return (
    <div
      className={panelStyles}
      style={{ width: getPanelWidth() }}
      ref={tagCreator(currentFullScreen ? 'fullscreenexit-icon' : 'fullscreen-icon')}
    >
      <Tooltip
        description={currentFullScreen ? t('fullscreen-exit') : t('fullscreen')}
        tooltipClassName="bg-mono-1"
        wrapperClassName="absolute h-6 w-6 max-sm:hidden"
        debounceTime={200}
        tooltipAlignment={DomElementAlignment.TopLeft}
        tooltipTargetPosition={DomTargetPosition.BottomRight}
      >
        <IconButton classNames="absolute p-1 bg-gray-4 rounded-br-lg max-sm:hidden" onClick={() => toggleFullScreen()}>
          <OdinIcon
            icon={currentFullScreen ? 'FullscreenExit' : 'Fullscreen'}
            size={OdinIconSize.ExtraSmall}
            type={OdinIconType.Line}
          />
        </IconButton>
      </Tooltip>
      <div
        className="absolute bottom-0 left-0 top-0 w-1 cursor-col-resize select-none"
        onMouseDown={() => resizeHandler()}
      />
      <div className="flex w-full grow flex-col overflow-y-auto max-sm:pl-5 sm:pl-10 pr-10 pt-5">{children}</div>
    </div>
  );
}
