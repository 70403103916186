import { OdinController } from '@myosh/odin-components';
import React from 'react';
import { Control } from 'react-hook-form';
import AttachmentsField, { AttachmentsFieldProps } from './attachments-field.component';

interface HfAttachmentsFieldProps extends AttachmentsFieldProps {
  control?: Control;
  name?: string;
}

export function HfAttachmentsField({
  id,
  control,
  name = 'attachments',
  registerPreSubmitHandler,
  registerPostSubmitHandler,
  value,
  onChange,
  recordId,
  readOnly,
  files,
  images,
  permissions,
}: HfAttachmentsFieldProps) {
  return (
    <OdinController
      name={name}
      control={control}
      defaultValue={value}
      onChange={onChange}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <AttachmentsField
          id={id}
          name={name}
          readOnly={readOnly}
          error={error?.message}
          value={value}
          files={files}
          images={images}
          registerPreSubmitHandler={registerPreSubmitHandler}
          registerPostSubmitHandler={registerPostSubmitHandler}
          thumbnails={true}
          recordId={recordId}
          onChange={onChange}
          permissions={permissions}
        />
      )}
    />
  );
}
