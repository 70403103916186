import React from 'react';
import {
  DropDownResult,
  ModalDialog,
  ModalDialogButtonSetting,
  OdinDataRetrievalOptions,
} from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/hooks';
import { submitExportJob } from '../../redux/slices/export-slice';

interface ExportRecordListTemplatesModalProps {
  visible: boolean;
  onClose: () => void;
  selectedForm?: DropDownResult;
  columns?: string;
  gridOptions?: OdinDataRetrievalOptions;
}

const ExportRecordListTemplatesModal = ({
  selectedForm,
  columns,
  gridOptions,
  visible,
  onClose,
}: ExportRecordListTemplatesModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const buttons: ModalDialogButtonSetting[] = [
    { text: t('no'), name: 'no', onClick: () => handleTemplateDownloadByFormat('TEMPLATE') },
    {
      text: t('yes'),
      name: 'yes',
      type: 'primary',
      onClick: () => handleTemplateDownloadByFormat('TEMPLATE_AND_RECORDS'),
    },
  ];

  const handleTemplateDownloadByFormat = (format: string) => {
    dispatch(
      submitExportJob({
        format,
        moduleId: undefined,
        formId: selectedForm?.value as string,
        formName: selectedForm?.text as string,
        columns,
        gridOptions,
      })
    );
  };

  return (
    <>
      <ModalDialog
        header={''}
        visible={visible}
        hidden={onClose}
        minDialogWidth={380}
        maxDialogWidth={380}
        closeOnEscape={false}
        renderChildrenLazily={true}
        buttons={buttons}
        buttonsCloseModal={true}
      >
        <h3>{t('export-record-template-text')}</h3>
      </ModalDialog>
    </>
  );
};

export default ExportRecordListTemplatesModal;
