import React, { useState, useRef, useMemo, useEffect } from 'react';
import {
  DomElementAlignment,
  DomTargetPosition,
  OverlayPanel,
  DynamicForm,
  DynamicFormSettings,
  OdinDataRetrieval,
  JsonDataWrapper,
  OdinDataUpdate,
  OdinDataSender,
  OdinDataRetrievalOptions,
  DynamicFormButtonSetting,
  JsonDataItem,
  Tooltip,
  OdinIcon,
  OdinIconType,
  OdinIconSize,
  Loader,
  Button,
  DynamicFormRef,
  useScreenSize,
} from '@myosh/odin-components';
import { v4 } from 'uuid';
import {
  useDeleteGlobalHierarchyFiltersMutation,
  useGetGlobalHierarchyFiltersQuery,
  useHierarchyTypesQuery,
  useUpdateGlobalHierarchyFiltersMutation,
} from '../../redux/services/hierarchy';
import { saveButton } from '../../pages/admin/admin-utils';
import { useTranslation } from 'react-i18next';
import { forceAssert } from '../../common/common-functions';
import { cloneDeep, isArray } from 'lodash';
import { showError, showSuccess } from '../../services/notification.service';
import { HfHierarchyField } from '../fields/hierarchy/hf-hierarchy-field.component';
import {
  GlobalHierarchyFilters,
  HierarchyDropdownValue,
  HierarchyMatchingOptionDropDownValue,
} from '../../@types/hierarchy-fields';
import { createGlobalHierarchyFiltersPatch } from '../../common/hierarchy-functions';
import i18next from '../../i18n';
import {
  DataObject,
  resetGlobalHierarchyFilters,
  setMatchingOption,
  setSelectedHierarchies,
} from '../../redux/slices/global-hierarchies';
import { useAppDispatch } from '../../redux/hooks';

const resetButton: DynamicFormButtonSetting = {
  name: 'reset',
  text: i18next.t('reset'),
  htmlType: 'button',
  type: 'default',
  variant: 'alternative',
};

const optionGroupOptions = [
  {
    value: 'MATCH_ANY',
    text: i18next.t('match-any-hierarchy-values', { any: `<strong>${i18next.t('any').toLowerCase()}</strong>` }),
  },
  {
    value: 'MATCH_ALL',
    text: i18next.t('match-all-hierarchy-values', { all: `<strong>${i18next.t('all').toLowerCase()}</strong>` }),
  },
];

const GlobalHierarchiesFilter = () => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isOverlayShown, setIsOverlayShown] = useState(false);
  const [formButtons, setFormButtons] = useState<DynamicFormButtonSetting[]>();
  const [globalHierarchiesFilterData, setGlobalHierarchiesFilterData] = useState<GlobalHierarchyFilters>();

  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const dynamicFormId = useRef(v4());

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobile } = useScreenSize();

  const { data: hierarchyTypes } = useHierarchyTypesQuery({ archived: 'false' });
  const { data } = useGetGlobalHierarchyFiltersQuery();
  const [updateGlobalHierarchies] = useUpdateGlobalHierarchyFiltersMutation();
  const [resetGlobalHierarchies] = useDeleteGlobalHierarchyFiltersMutation();

  useEffect(() => {
    if (data) {
      setGlobalHierarchiesFilterData(data);
      const newFormButtons: Array<DynamicFormButtonSetting> = [];

      resetButton.onClick = () =>
        resetGlobalHierarchies()
          .unwrap()
          .then(() => {
            showSuccess(t('global-hierarchies-reset'));
            dispatch(resetGlobalHierarchyFilters());
            setIsOverlayVisible(false);
          })
          .catch(() => showError(t('global-hierarchies-reset-failed')));

      const _saveButton = cloneDeep(saveButton);
      _saveButton.onClick = () => onFormSubmit();
      _saveButton.htmlType = 'button';

      newFormButtons.push(_saveButton, resetButton);
      setFormButtons(newFormButtons);
    }

    // Handle global state for global hierarchy filters
    if (data && data.globalHierarchyFilters) {
      const listOfActiveHierarchyTypes =
        !isArray(data.globalHierarchyFilters) &&
        Object.keys(data.globalHierarchyFilters as Record<string, HierarchyDropdownValue[]>);
      if (isArray(listOfActiveHierarchyTypes)) {
        let dispatchData: DataObject[] = [];
        for (let i = 0, length = listOfActiveHierarchyTypes.length; i < length; i++) {
          const filterData = forceAssert<Record<string, HierarchyDropdownValue[]>>(data.globalHierarchyFilters)[
            listOfActiveHierarchyTypes[i]
          ];
          const formatFilterData = filterData.map((filter) => {
            return {
              id: filter.id,
              typeId: filter.hierarchyTypeId,
            };
          });
          dispatchData = [...dispatchData, ...formatFilterData];
        }

        const groupedData: Record<number, number[]> = {};

        for (let i = 0, length = dispatchData.length; i < length; i++) {
          const globalFilterData = dispatchData[i];
          if (!groupedData[globalFilterData.typeId]) {
            groupedData[globalFilterData.typeId] = [];
          }
          groupedData[globalFilterData.typeId].push(globalFilterData.id);
        }
        dispatch(setSelectedHierarchies(groupedData));

        const globalFiltersMatchingOption =
          forceAssert<HierarchyMatchingOptionDropDownValue>(data?.hierarchyMatchingOption).value === 'MATCH_ALL'
            ? 'all'
            : 'any';
        dispatch(setMatchingOption(globalFiltersMatchingOption));
      }
    }
  }, [data]);

  const hierarchyGlobalFiltersFormSettings: DynamicFormSettings = useMemo(() => {
    return {
      id: 0,
      caption: '',
      fields: [
        {
          id: 'globalHierarchyFilters',
          fieldType: 'CUSTOM',
          customFieldType: 'HIERARCHY_ADMIN',
          caption: t('hierarchy'),
          fieldName: 'globalHierarchyFilters',
          position: 0,
          permissions: { read: true, edit: true },
          dynamicProperties: {
            hierarchyTypes: hierarchyTypes,
            usesMultiSelectDropdowns: true,
          },
          wrapInGroup: false,
          hidden: false,
        },
        {
          id: 'hierarchyMatchingOption',
          fieldType: 'OPTIONGROUP',
          fieldName: 'hierarchyMatchingOption',
          caption: '',
          position: 1,
          permissions: { read: true, edit: true },
          dataSettings: {
            valueField: 'value',
            textField: 'text',
          },
        },
      ],
      customFieldComponents: {
        HIERARCHY_ADMIN: HfHierarchyField,
      },
    };
  }, [hierarchyTypes]);

  const toggleHierarchyDropdown = () => {
    setIsOverlayVisible((prev) => !prev);
  };

  const dataRetrieval: OdinDataRetrieval & OdinDataUpdate<JsonDataWrapper> = {
    getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
      if (
        options &&
        options.fieldType === 'OPTIONGROUP' &&
        options.fieldId === 'hierarchyMatchingOption' &&
        options.page === 1
      ) {
        subscriber.sendData({ data: optionGroupOptions, requestId: options.requestId });
      } else {
        subscriber.sendData();
      }
    },
  };

  const onFormSubmit = () => {
    const data = dynamicFormReference.current?.getData() as JsonDataItem;
    setGlobalHierarchiesFilterData(data);
    const patchData = createGlobalHierarchyFiltersPatch(data);
    updateGlobalHierarchies(patchData)
      .unwrap()
      .then(() => {
        showSuccess(t('global-hierarchies-saved'));
        setIsOverlayVisible(false);
      })
      .catch(() => {
        showError(t('global-hierarchies-save-failed'));
      });
  };

  const onOverlayHidden = () => {
    setIsOverlayVisible(false);
    setIsOverlayShown(false);
  };

  const onOverlayShown = () => {
    // needed to mask the dynamic form layout jumping when it renders the fields
    setTimeout(() => setIsOverlayShown(true), 1000);
  };

  const activeHierarchyFilters = useMemo(() => {
    if (isMobile) {
      return (
        <OdinIcon
          icon="Filter"
          type={OdinIconType.Line}
          size={OdinIconSize.Medium}
          className="cursor-pointer rounded-md bg-gray-5 px-2 text-primary-1 hover:bg-primary-5"
        />
      );
    } else if (globalHierarchiesFilterData && globalHierarchiesFilterData.globalHierarchyFilters) {
      return (
        <div className="flex flex-row justify-items-center pl-1">
          <div className="text-sm text-center font-bold pr-1">{t('global-hierarchy')}:</div>
          <div className="text-sm font-normal text-center text-primary-1">
            {getGlobalHierarchyFiltersDisplayText(globalHierarchiesFilterData.globalHierarchyFilters)}
          </div>
        </div>
      );
    }
  }, [globalHierarchiesFilterData, isMobile]);

  const formButtonsComponent = () => {
    return (
      <div className="bg-mono-1 w-full max-w-full flex items-center gap-2 absolute bottom-0 left-0 p-4 odin-border-t odin-border-solid odin-border-gray-4 ">
        {formButtons?.map((button, index) => (
          <Button
            key={`button_${button.name}_${index}`}
            htmlType={button.htmlType}
            type={button.type}
            variant={button.variant}
            classNames={button.className}
            onClick={() => button.onClick?.()}
          >
            {button.text}
          </Button>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-row items-center cursor-pointer">
      <div ref={targetRef} onClick={toggleHierarchyDropdown}>
        {activeHierarchyFilters}
      </div>
      <OverlayPanel
        target={targetRef.current}
        visible={isOverlayVisible}
        hidden={onOverlayHidden}
        elementAlignment={DomElementAlignment.TopLeft}
        targetPosition={DomTargetPosition.BottomLeft}
        shouldScrollCloseOverlay={false}
        mountOnEnter={true}
        unmountOnExit={true}
        shouldCheckZIndex={true}
        shouldUseCreatePortal={false}
        className="w-96 max-w-lg bg-mono-1 p-4 text-mono-0 h-[500px]"
        shouldRemainOnScreen={true}
        showCloseIcon={true}
        shown={onOverlayShown}
      >
        <div
          className="absolute z-[1000] bg-mono-1 w-full h-full -mt-4 -ml-4 flex items-center justify-center"
          hidden={isOverlayShown}
        >
          <Loader />
        </div>
        <div className="max-h-[420px] overflow-y-auto custom-scroll pt-2">
          <DynamicForm
            ref={dynamicFormReference}
            dynamicFormId={dynamicFormId.current}
            data={forceAssert<JsonDataItem>(cloneDeep(globalHierarchiesFilterData)) ?? {}}
            settings={hierarchyGlobalFiltersFormSettings}
            dataRetrieval={dataRetrieval}
            onSubmit={onFormSubmit}
            showSubmitButtons={false}
          />
        </div>

        {formButtonsComponent()}
      </OverlayPanel>
    </div>
  );
};

export default GlobalHierarchiesFilter;

// util
const getGlobalHierarchyFiltersDisplayText = (selectedHierarchies: Record<string, HierarchyDropdownValue[]>) => {
  if (isArray(selectedHierarchies)) {
    return <div className="text-sm font-semibold text-primary-1">{`${i18next.t('all')}`}</div>;
  } else {
    const keys = Object.keys(selectedHierarchies);

    const tooltipDescription = keys.map((key) => (
      <div key={key} className="text-sm font-normal">
        {`${key}(${selectedHierarchies[key].map((h) => h.caption).join(', ')})`}
      </div>
    ));

    if (keys.length > 2) {
      return (
        <Tooltip
          description={tooltipDescription}
          tooltipClassName="bg-mono-1"
          debounceTime={200}
          tooltipAlignment={DomElementAlignment.TopLeft}
        >
          <div className="text-sm font-normal">{`${i18next.t('Multiple')}(${keys.length})`}</div>
        </Tooltip>
      );
    } else {
      const displayText = keys
        .map((key) => {
          return `${key}(${selectedHierarchies[key].length})`;
        })
        .join(' ');

      return (
        <Tooltip
          description={tooltipDescription}
          tooltipClassName="bg-mono-1"
          debounceTime={200}
          tooltipAlignment={DomElementAlignment.TopLeft}
        >
          <div className="text-sm font-normal">{displayText}</div>
        </Tooltip>
      );
    }
  }
};
