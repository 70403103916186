import { Wizard, WizardRef, WizardStep } from '@myosh/odin-components';
import React, { useEffect, useRef, useState } from 'react';
import WrapperStyledButtonField from '../../common/wrapper-styled-button-field';
import FirstStep from './first-step';
import SecondStep from './second-step';
import ThirdStep from './third-step';
import { CloneWizardState, initialCloneWizardState } from './clone-record-button-field.component';
import { useTranslation } from 'react-i18next';

interface CloneRecordWizzardProps {
  buttonCaption?: string;
  disabled?: boolean;
  onClick: () => void;
  formId: number;
  cloneWizardTargetPersonField?: number;
  cloneWizardTargetWorkflowStep?: number;
  allowUserAttachmentCloning?: boolean;
  moduleFormCloneTargetId?: number;
  cloneWizardState: CloneWizardState;
  setCloneWizardState: React.Dispatch<React.SetStateAction<CloneWizardState>>;
  saveNewRecord: () => void;
  isNewRecord: boolean;
}

const CloneRecordWizzard = ({
  buttonCaption,
  disabled,
  onClick,
  formId,
  cloneWizardTargetPersonField,
  cloneWizardTargetWorkflowStep,
  allowUserAttachmentCloning,
  moduleFormCloneTargetId,
  cloneWizardState,
  setCloneWizardState,
  saveNewRecord,
  isNewRecord,
}: CloneRecordWizzardProps) => {
  const [key, setKey] = useState(0);
  const wizardRef = useRef<WizardRef>(null);
  const [firstStepError, setFirstStepError] = useState(false);
  const [secondStepError, setSecondStepError] = useState(false);
  const [disableFinishButton, setDisableFinishButton] = useState(false);

  const { t } = useTranslation();

  const resetState = () => {
    setKey((prev) => prev + 1);
    setCloneWizardState(initialCloneWizardState);
  };

  const onFinish = () => {
    resetState();
    onClick();
  };

  const validateFirstStep = () => {
    setFirstStepError(!Boolean(cloneWizardState.status));
    return Boolean(cloneWizardState.status);
  };

  const validateSecondStep = () => {
    setSecondStepError(!(cloneWizardState.users.length > 0 || cloneWizardState.groups.length > 0));
    return cloneWizardState.users.length > 0 || cloneWizardState.groups.length > 0;
  };

  useEffect(() => {
    if (!(cloneWizardState.users.length > 0 || cloneWizardState.groups.length > 0)) {
      return setDisableFinishButton(true);
    } else if (!Boolean(cloneWizardTargetWorkflowStep)) {
      return setDisableFinishButton(!Boolean(cloneWizardState.status));
    } else {
      return setDisableFinishButton(false);
    }
  }, [cloneWizardState, cloneWizardTargetWorkflowStep]);

  return (
    <>
      <Wizard
        key={key}
        ref={wizardRef}
        title={t('clone-records')}
        onCancel={resetState}
        onFinish={onFinish}
        disableFinishButton={disableFinishButton}
      >
        <WizardStep label={t('status')} hideStep={Boolean(cloneWizardTargetWorkflowStep)} onNext={validateFirstStep}>
          <FirstStep
            formId={formId}
            setCloneWizardState={setCloneWizardState}
            moduleFormCloneTargetId={moduleFormCloneTargetId}
            error={firstStepError}
            setFirstStepError={setFirstStepError}
          />
        </WizardStep>
        <WizardStep label={t('assignment')} onNext={validateSecondStep}>
          <SecondStep
            formId={formId}
            cloneWizardState={cloneWizardState}
            setCloneWizardState={setCloneWizardState}
            moduleFormCloneTargetId={moduleFormCloneTargetId}
            cloneWizardTargetPersonField={cloneWizardTargetPersonField}
            error={secondStepError}
            setSecondStepError={setSecondStepError}
          />
        </WizardStep>
        <WizardStep label={t('attachments')} hideStep={!allowUserAttachmentCloning}>
          <ThirdStep setCloneWizardState={setCloneWizardState} cloneWizardState={cloneWizardState} />
        </WizardStep>
      </Wizard>
      <WrapperStyledButtonField
        onClick={() => (isNewRecord ? saveNewRecord() : wizardRef.current?.open())}
        disabled={disabled}
        buttonCaption={buttonCaption}
        icon="FileCopy"
      />
    </>
  );
};

export default CloneRecordWizzard;
