import React from 'react';
import { TabView, TabViewPanel } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import QuickLinks from '../../components/home-page/quick-links.component';
import CustomActionScroll from '../custom-actions-scroll';
import { HomePageProps } from './home-utility';

export default function HomeMobilePage({ isLoading, alertNews, getNewsPanel }: HomePageProps) {
  const { t } = useTranslation();

  return (
    <div className="flex h-full w-full flex-col max-sm:visible sm:hidden">
      <TabView
        activeIndex={0}
        showScrollbars={false}
        styles={{
          panelStyles: 'overflow-y-scroll [&_a]:text-url [&_a]:underline',
        }}
      >
        <TabViewPanel header={t('news')} key={'news'}>
          {!isLoading && !isEmpty(alertNews) && <div className="mb-2 flex flex-col gap-2">{alertNews}</div>}
          {getNewsPanel(true)}
        </TabViewPanel>
        <TabViewPanel header={t('quick-links')} key="quick-links">
          <QuickLinks />
        </TabViewPanel>
        <TabViewPanel header={t('my-activities')} key={'my-activities'}>
          {<CustomActionScroll showHeader={false} />}
        </TabViewPanel>
      </TabView>
    </div>
  );
}
