import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@myosh/odin-components';
import EmbeddedPage from './embedded.page';
import { useGetCurrentSchemaInfoQuery } from '../redux/services/api';

export const resolveDashboardUrl = (systemHostname?: string) => {
  const configuredUrl = process.env.REACT_APP_DASHBOARD_URL;

  if (systemHostname && configuredUrl) {
    const url = new URL(configuredUrl);
    url.hostname = systemHostname;
    return url.toString();
  }

  return configuredUrl;
};

const DashboardEmbeddedPage = () => {
  const { t } = useTranslation();
  const { data: { system_hostname } = {}, isFetching } = useGetCurrentSchemaInfoQuery();

  const url = useMemo(() => resolveDashboardUrl(system_hostname), [system_hostname]);

  return isFetching ? (
    <Loader title={`${t('loading')}`} />
  ) : (
    <EmbeddedPage slug="dashboard" url={url} title="Dashboard" />
  );
};

export default DashboardEmbeddedPage;
