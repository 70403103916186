import { Button, ModalDialog } from '@myosh/odin-components';
import React, { forwardRef, Ref, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { ExtendedDynamicFormNextWorkflowStep } from '../../../common/common-functions';
import { useTranslation } from 'react-i18next';

interface WorkflowStepModalState {
  nextStep?: ExtendedDynamicFormNextWorkflowStep;
  visible: boolean;
}

const initialState: WorkflowStepModalState = {
  nextStep: undefined,
  visible: false,
};

export interface WorkflowStepModalRef {
  show: (nextStepLabel: ExtendedDynamicFormNextWorkflowStep) => Promise<ExtendedDynamicFormNextWorkflowStep>;
}

const WorkflowStepModal = (_: unknown, ref: Ref<WorkflowStepModalRef>) => {
  const [state, setState] = useState<WorkflowStepModalState>(initialState);
  const promiseResolveRef =
    useRef<(value: ExtendedDynamicFormNextWorkflowStep | PromiseLike<ExtendedDynamicFormNextWorkflowStep>) => void>();
  const { t } = useTranslation();

  const showDialog = useCallback((nextStep: ExtendedDynamicFormNextWorkflowStep) => {
    setState(() => {
      return {
        nextStep,
        visible: true,
      };
    });
  }, []);

  const hideDialog = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        visible: false,
      };
    });
  }, []);

  const handleClose = useCallback(() => {
    promiseResolveRef.current?.({} as ExtendedDynamicFormNextWorkflowStep);
    setState(initialState);
  }, []);

  const show = (nextStep: ExtendedDynamicFormNextWorkflowStep) => {
    showDialog(nextStep);

    return new Promise<ExtendedDynamicFormNextWorkflowStep>((resolve) => {
      promiseResolveRef.current = resolve;
    });
  };

  useImperativeHandle(ref, () => ({
    show,
  }));

  const handleProgressToNextStep = () => {
    state.nextStep && promiseResolveRef.current?.(state.nextStep);
    hideDialog();
  };

  const footer = (
    <div className="flex justify-end">
      <Button type="primary" onClick={handleProgressToNextStep}>
        {state?.nextStep?.buttonCaption}
      </Button>
    </div>
  );

  return (
    <ModalDialog
      header={t(`record-will-change-status`)}
      hidden={handleClose}
      visible={state.visible}
      footer={footer}
      showCloseIcon
      renderChildrenLazily
    >
      <h1>{String(t(`continue-message`))}</h1>
    </ModalDialog>
  );
};

export default forwardRef(WorkflowStepModal);
