import React, { useEffect, useState, useMemo, useRef, Ref, useCallback } from 'react';
import {
  Button,
  DataGrid,
  DataGridCellRenderer,
  DataGridCellRendererProps,
  DataGridColumnSettings,
  DataGridRef,
  DataSearchLocation,
  DynamicFieldComponentProps,
  DynamicFormFieldType,
  EmptyFieldLabel,
  ErrorLabel,
  FieldLabel,
  JsonData,
  JsonDataWrapper,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  RequiredIndicator,
  RowTemplateType,
} from '@myosh/odin-components';
import { forceAssert } from '../../../common/common-functions';
import useFetchUsers from '../../../hooks/use-fetch-users';
import UserRecordLinkSelecting from './components/user-record-link-selecting';
import { UserFields } from '../../../common/user-config';
import UserRecordLinkPropertyNameCell from './components/user-record-link-cell.component';
import { LinkedUser, UserRecordLinkEntity } from '../../../@types/users';
import CreateUserDialog from './components/create-user-dialog';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { OdinDataGridLinkedUsersRowTemplate } from '../../dynamic-page/odin-data-grid-linked-users-row-template.component';
import useActiveRecordRef from '../../../hooks/use-active-record-ref';
import useFetchLinkedUsers from '../../../hooks/use-fetch-linked-users';
import useProfileData from '../../../hooks/use-profile-data';
import { UsersPermissions } from '../../../common/user.permissions';
import LinkedRecordsActionsCell from '../linked-records/components/linked-records-action-cell';
import { showSelectAll } from '../../../hooks/use-grid-columns-settings';

/**
 * Transforms the user record link columns into DataGridColumnSettings.
 * @param {Array<UserRecordLinkEntity>} recordLinkColumns - The items to transform into data grid columns.
 * @return {Array<DataGridColumnSettings>} The converted array of DataGridColumnSettings objects.
 */
const transformUserRecordLinkColumns = (
  recordLinkColumns: Array<UserRecordLinkEntity>
): Array<DataGridColumnSettings> => {
  return recordLinkColumns.map((recordLinkColumn: UserRecordLinkEntity) => {
    return {
      ...recordLinkColumn, // enrich column data with record link entity configuration data
      title: recordLinkColumn.entityName,
      field: recordLinkColumn.entityPropertyName,
      entityId: recordLinkColumn.entityId,
      visible: true,
      type: 6,
      cellRenderer: 'USER_FIELD',
      isIdField: false,
      allowSelectAll: showSelectAll(
        false,
        recordLinkColumn?.entityType,
        undefined,
        recordLinkColumn?.entityPropertyName
      ),
      customDataProperties: { ...recordLinkColumn },
    };
  });
};

export function extractUserIds(value: Array<LinkedUser>) {
  return value.map((item) => item.value);
}

export const linkedUsersCustomComponents: Record<string, DataGridCellRenderer> = {
  USER_FIELD: {
    CellComponent: (props: DataGridCellRendererProps, ref: Ref<HTMLDivElement>) => (
      <UserRecordLinkPropertyNameCell {...props} ref={ref} />
    ),
  },
};

export interface UserRecordLinkProps extends DynamicFieldComponentProps<Array<LinkedUser>> {
  recordLinkFormat?: Array<UserRecordLinkEntity>;
  buttonCaption?: string;
  showSelectButton?: boolean;
  createExternalUsers?: boolean;
  saveRecord?: () => void;
}

export default function UserRecordLink({
  label,
  readOnly,
  required,
  value,
  onChange,
  recordLinkFormat,
  buttonCaption,
  showSelectButton,
  createExternalUsers,
  saveRecord,
  error,
}: UserRecordLinkProps) {
  const [linkedUsers, setLinkedUsers] = useState<Array<LinkedUser>>(value || []);
  const [linkedUsersIds, setLinkedUsersIds] = useState<Array<number>>([]);
  const [linkedUsersData, setLinkedUsersData] = useState<JsonData>([]);
  const [selectLinkedUsersDialogVisible, setSelectLinkedUsersDialogVisible] = useState<boolean>(false);
  const [createUserDialogVisible, setCreateUserDialogVisible] = useState<boolean>(false);
  const gridSubscriber = useRef<OdinDataSender<JsonDataWrapper>>();
  const gridRef = useRef<DataGridRef>();
  const [gridOptions, setGridOptions] = useState<OdinDataRetrievalOptions>();
  const { t } = useTranslation();
  const { activeRecordReference } = useActiveRecordRef();
  const { profileData: { user: userData } = {} } = useProfileData();

  const userFields = useMemo(() => {
    return (
      recordLinkFormat
        ?.map((column) => column.entityPropertyName)
        .concat(UserFields.userHierarchy)
        .join() || ''
    );
  }, [recordLinkFormat]);

  const { linkedUsersRecords, isFetching: areLinkedUserRecordsFetching } = useFetchLinkedUsers(
    userFields,
    gridOptions?.page ?? 1,
    gridOptions?.pageSize ?? 50,
    linkedUsersIds,
    gridOptions?.sortedFields
  );

  const { users } = useFetchUsers(linkedUsersIds, userFields);

  useEffect(() => {
    setLinkedUsers(value ?? []);
  }, [value]);

  useEffect(() => {
    let data: JsonData = [];
    if (linkedUsersRecords && linkedUsersRecords.length > 0) {
      data = forceAssert<JsonData>(linkedUsersRecords);
    }
    setLinkedUsersData(data);
  }, [linkedUsersRecords]);

  useEffect(() => {
    const userIds = extractUserIds(linkedUsers);
    setLinkedUsersIds(userIds);
  }, [linkedUsers]);

  const gridColumns = useMemo(() => {
    const userRecordLinkColumns = recordLinkFormat ? transformUserRecordLinkColumns(recordLinkFormat) : [];
    userRecordLinkColumns.push({
      id: 'linkedUsersRemoveAction',
      field: '',
      title: '',
      visible: !readOnly,
      cellRenderer: 'LINKED_RECORDS_ACTIONS',
      type: 6,
      disableSort: true,
    });
    return userRecordLinkColumns;
  }, [recordLinkFormat]);

  const gridSettings = {
    columns: gridColumns,
    autoSizeColumns: true,
    components: {
      ...linkedUsersCustomComponents,
      LINKED_RECORDS_ACTIONS: {
        CellComponent: (props: DataGridCellRendererProps) => {
          return (
            <LinkedRecordsActionsCell rowId={String(props.rowData.id)} removeLinkedRecord={handleRemoveLinkedRecord} />
          );
        },
      },
    },
    filterLocation: DataSearchLocation.Api,
    stopRequestsOnNoData: true,
    fullHeight: true,
  };

  const handleRemoveLinkedRecord = useCallback(
    (rowId: string) => {
      const filteredList: LinkedUser[] = linkedUsers?.filter((user) => String(user.value) !== rowId) ?? [];
      onChange?.(filteredList);
      saveRecord?.(); // save the Record when a linked record is removed (MYOSH-5074)
      resetGridPageData();
    },
    [onChange, saveRecord]
  );

  const resetGridPageData = () => gridRef.current?.api.data.getDataOverwritePageCache();

  const rowTemplate: RowTemplateType = OdinDataGridLinkedUsersRowTemplate({
    activeRecordReference: activeRecordReference.current,
    successCallback: resetGridPageData,
  });

  const onSelectLinkedUsersDialogOpen = () => setSelectLinkedUsersDialogVisible(true);
  const onSelectLinkedUsersDialogHidden = () => setSelectLinkedUsersDialogVisible(false);

  const onCreateUserDialogOpen = () => setCreateUserDialogVisible(true);
  const onCreateUserDialogHidden = () => setCreateUserDialogVisible(false);

  const handleUserCreated = (user: LinkedUser) => {
    handleAddLinkedUsers([...linkedUsers, user]);
  };

  const handleAddLinkedUsers = (addedLinkedUsers: Array<LinkedUser>) => {
    setLinkedUsers(addedLinkedUsers);
    onChange?.(addedLinkedUsers);
    saveRecord?.(); // save the Record when a linked user is selected or created
    resetGridPageData();
  };

  const canCreateNewUser =
    userData?.superUser || (userData?.adminAccess && userData.adminAccess.includes(UsersPermissions.USERS_CREATE));

  useEffect(() => {
    if (linkedUsersData && !areLinkedUserRecordsFetching) {
      if (linkedUsersData.length > 0) {
        gridSubscriber.current?.sendData({ data: linkedUsersData, requestId: gridOptions?.requestId });
      } else {
        gridSubscriber.current?.sendData();
      }
    }
  }, [linkedUsersData, gridOptions]);

  const gridData = useMemo<OdinDataRetrieval>(() => {
    return {
      getSubscriber: (subscriber, fieldType?: DynamicFormFieldType) => {
        if (fieldType !== 'COMBOBOX') {
          gridSubscriber.current = subscriber;
        }
      },
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if (options?.fieldType !== 'COMBOBOX') {
          setGridOptions(options);
        } else {
          subscriber.sendData();
        }
      },
    };
  }, []);

  const onDataGridRefCreated = (dataGridRef: DataGridRef) => {
    gridRef.current = dataGridRef;
  };

  const buttonsContainerStyles = cx('my-2 flex flex-row m-1 gap-2', {
    'border border-error': error && !readOnly,
  });

  return (
    <>
      {label && (
        <div className="flex">
          <FieldLabel label={label} />
          <RequiredIndicator readOnly={readOnly} required={required} />
        </div>
      )}
      {!readOnly && (
        <div className={buttonsContainerStyles}>
          {showSelectButton && (
            <Button variant="alternative" classNames="bg-gray-5" onClick={onSelectLinkedUsersDialogOpen}>
              {t('select')}
            </Button>
          )}
          {canCreateNewUser && (
            <Button variant="alternative" classNames="bg-gray-5" onClick={onCreateUserDialogOpen}>
              {buttonCaption || t('create-user')}
            </Button>
          )}
        </div>
      )}
      {readOnly && linkedUsers.length === 0 && <EmptyFieldLabel />}
      {users.length > 0 && (
        <div className="my-2 flex max-h-[40rem]">
          <DataGrid
            ref={(gridRef) => gridRef && onDataGridRefCreated(gridRef)}
            data={gridData}
            gridSettings={gridSettings}
            showSettings={false}
            rowTemplate={rowTemplate}
          />
        </div>
      )}
      {selectLinkedUsersDialogVisible && (
        <UserRecordLinkSelecting
          visible={true}
          hidden={onSelectLinkedUsersDialogHidden}
          value={linkedUsers}
          applyCallback={handleAddLinkedUsers}
        />
      )}
      {createUserDialogVisible && (
        <CreateUserDialog
          header={t('new-user')}
          visible={true}
          hidden={onCreateUserDialogHidden}
          onUserCreated={handleUserCreated}
          isExternal={createExternalUsers}
        />
      )}
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </>
  );
}
