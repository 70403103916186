import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuthAndUrlEncoding } from './api';
import { GetRecordListMenuItemsResponse, RecordListMenuItem } from '../../@types/record-list-menu';

export const recordListMenuApi = createApi({
  reducerPath: 'recordListMenu',
  baseQuery: baseQueryWithReAuthAndUrlEncoding,
  tagTypes: ['PinnedMenuItems'],
  endpoints: (builder) => ({
    getPinnedRecordListMenuItems: builder.query<Array<RecordListMenuItem>, void>({
      query: () => '/record-list-menus',
      transformResponse: (response: GetRecordListMenuItemsResponse) => response.result ?? [],
      providesTags: ['PinnedMenuItems'],
    }),
    pinRecordListMenuItem: builder.mutation<void, RecordListMenuItem>({
      query: (menuItem) => ({
        url: '/record-list-menus/pin',
        method: 'POST',
        body: menuItem,
      }),
      invalidatesTags: ['PinnedMenuItems'],
    }),
    unpinRecordListMenuItem: builder.mutation<void, RecordListMenuItem>({
      query: (menuItem) => ({
        url: '/record-list-menus/unpin',
        method: 'POST',
        body: menuItem,
      }),
      invalidatesTags: ['PinnedMenuItems'],
    }),
  }),
});

export const {
  useGetPinnedRecordListMenuItemsQuery,
  usePinRecordListMenuItemMutation,
  useUnpinRecordListMenuItemMutation,
} = recordListMenuApi;
