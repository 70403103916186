import React from 'react';
import MyoshLogo from '../assets/myosh-logo.svg';
import { useGetVisitorFormsQuery } from '../redux/services/api';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@myosh/myosh-login';
import { FormLoader } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';

function VisitorSignInPage() {
  const {
    state: { user },
  } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, isFetching } = useGetVisitorFormsQuery();

  const onSignIn = () => {
    if (user?.currentSchema && data?.[0].viewId) {
      navigate(`/${user.currentSchema}/modules/${data[0].viewId}/forms/${data[0].id}/basic-record?hideSections=true`);
    }
  };

  const onSignOut = () => {
    navigate(`/visitor-sign-out`);
  };

  const buttonStyles =
    'text-[2em] font-semibold rounded shadow-sm py-4 px-32 hover:opacity-90 focus-visible:outline-2 focus-visible:outline-offset-4 focus-visible:outline-mono-0';

  return (
    <div className="flex flex-col justify-center items-center h-full w-full">
      <div className="flex flex-col p-8 border rounded-2xl border-gray-4 shadow-xl items-center gap-8">
        <p className="text-[6em] font-bold uppercase text-center">{t('visitor')}</p>
        {isFetching && (
          <>
            <FormLoader height="lg" />
            <FormLoader height="lg" />
          </>
        )}
        {!isFetching && (
          <div className="flex gap-2 w-full justify-center">
            <button className={`${buttonStyles}  bg-success text-mono-1`} onClick={onSignIn}>
              {t('sign-in')}
            </button>
            <button className={`${buttonStyles} border-solid border-2 border-error text-error `} onClick={onSignOut}>
              {t('sign-out')}
            </button>
          </div>
        )}

        <img src={MyoshLogo} alt="myosh-logo" className="aspect-video w-64 h-32" />
      </div>
    </div>
  );
}

export default VisitorSignInPage;
