export enum FormGroupPersonFieldPermissionType {
  PERSONFIELD_READ_ROLE = 'PERSONFIELD_READ_ROLE',
  PERSONFIELD_EDIT_ROLE = 'PERSONFIELD_EDIT_ROLE',
  PERSONFIELD_HIERARCHY_READ_ROLE = 'PERSONFIELD_HIERARCHY_READ_ROLE',
  PERSONFIELD_HIERARCHY_EDIT_ROLE = 'PERSONFIELD_HIERARCHY_EDIT_ROLE',
  PERSONFIELD_ATTACHMENT_READ_ROLE = 'PERSONFIELD_ATTACHMENT_READ_ROLE',
  PERSONFIELD_ATTACHMENT_EDIT_ROLE = 'PERSONFIELD_ATTACHMENT_EDIT_ROLE',
  PERSONFIELD_VERSION_READ_ROLE = 'PERSONFIELD_VERSION_READ_ROLE',
  PERSONFIELD_VERSION_EDIT_ROLE = 'PERSONFIELD_VERSION_EDIT_ROLE',
  PERSONFIELD_INVITATION_READ_ROLE = 'PERSONFIELD_INVITATION_READ_ROLE',
  PERSONFIELD_INVITATION_EDIT_ROLE = 'PERSONFIELD_INVITATION_EDIT_ROLE',
}

export enum FormGroupPermissionType {
  Read = 'READ',
  Edit = 'EDIT',
}
