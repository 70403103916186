import React from 'react';

interface SummaryFieldTitle {
  moduleName: string;
  formName: string;
}

export default function SummaryFieldTitle({ moduleName, formName }: SummaryFieldTitle) {
  const titleStyle = 'text-sm font-medium';
  return <div className={titleStyle}>{moduleName + '-' + formName}</div>;
}
