import React, { useMemo } from 'react';
import { DataGridCellRendererProps } from '@myosh/odin-components';
import { forceAssert } from '../../common/common-functions';
import { EntityReference } from '../../@types/views';
import useFetchReverseLinkedRecords from '../../hooks/use-fetch-reverse-linked-records';
import { linkedFieldValueKeys } from '../../common/data-grid-column-utils';
import RecordlinkBaseDataCell from './recordlink-base-data-cell';

/** A custom grid data cell that resolves the correct value to display when the column is 'REVERSE_RECORD_LINK' type */
const ReverseRecordlinkDataCell = ({ column, rowData }: DataGridCellRendererProps) => {
  const columnEntityReference = forceAssert<EntityReference>(column.customDataProperties);
  const { entityId, entityPropertyName } = columnEntityReference;
  const valueKeys = linkedFieldValueKeys(entityPropertyName);

  const recordIds = useMemo(
    () => forceAssert<Record<string, Array<string>>>(rowData?.fields)?.[entityId] || [],
    [rowData.fields]
  );

  const recordId = Number(rowData['id']);
  const { reverseLinkedRecordsData: records, isFetching } = useFetchReverseLinkedRecords(recordId, entityId, recordIds);

  return <RecordlinkBaseDataCell records={records} valueKeys={valueKeys} loading={isFetching} width={column.width} />;
};

export default ReverseRecordlinkDataCell;
