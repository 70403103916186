import {
  DataGrid,
  DataGridRef,
  JsonData,
  JsonDataWrapper,
  OdinDataRetrieval,
  Notification,
  OdinDataRetrievalOptions,
  OdinDataSender,
  isDropDownNoSelection,
  DynamicFormFieldType,
  useTestTag,
} from '@myosh/odin-components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  dateFormatBasedOnFilter,
  forceAssert,
  processFieldFilters,
  buildSortedFieldsUrlString,
  buildGlobalHierarchyFiltersUrl,
} from '../../common/common-functions';
import { columnConfigurator, getCurrentlyLoadedAndTotalRecords } from '../../common/dynamic-page-utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useGetAllFormsRecordsQuery } from '../../redux/services/record';
import { dynamicPageState } from '../../redux/slices/dynamic-page-slice';
import { setDataGridReference } from '../../services/data-grid.service';
import { DefaultFormGridProps } from './single-form-grid';
import { defaultGridSettings } from './default-grid-settings';
import useGridSettings from '../../hooks/use-grid-settings';
import { useTranslation } from 'react-i18next';
import { getDataGridComboboxFilters } from '../../common/data-grid-filters';
import { useModuleWorkflowStepsQuery } from '../../redux/services/api';
import { DataGridLoader } from './data-grid-loader.component';
import useRenderDataGrid from '../../hooks/use-render-data-grid';
import { selectedGlobalHierarchies, selectedMatchingOption } from '../../redux/slices/global-hierarchies';
import ErrorLoadingDataNotification from '../common/error-loading-data-notification';

interface AllFormsGridProps extends DefaultFormGridProps {
  moduleId: number;
}

function AllFormsGrid({
  menuItems,
  selectedForm,
  moduleId,
  rowTemplate,
  onDataLoadCompleted,
  onViewChange,
}: AllFormsGridProps) {
  const [gridOptions, setGridOptions] = useState<OdinDataRetrievalOptions>();

  const gridSubscriber = useRef<OdinDataSender<JsonDataWrapper>>();
  const gridRef = useRef<DataGridRef>();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { selectedFilters } = useAppSelector(dynamicPageState);
  const globalFilters = useAppSelector(selectedGlobalHierarchies);
  const globalFiltersMatchingOption = useAppSelector(selectedMatchingOption);

  const { data: allWorkflowSteps, isFetching: areWorkflowStepsFetching } = useModuleWorkflowStepsQuery(
    { moduleId, filters: { archived: { comparison: '=', value: false } } },
    {
      skip: moduleId === undefined,
    }
  );

  const {
    settings: gridSettings,
    error: gridSettingsError,
    loading: gridSettingsLoading,
  } = useGridSettings(
    defaultGridSettings,
    columnConfigurator,
    selectedForm && !isDropDownNoSelection(selectedForm) ? (selectedForm?.value as number) : undefined,
    selectedFilters.viewConfig && !isDropDownNoSelection(selectedFilters.viewConfig)
      ? (selectedFilters.viewConfig?.value as number)
      : undefined,
    moduleId,
    true
  );

  const {
    data: allFormsRecords,
    isFetching: isAllFormsRecordsFetching,
    isLoading: isAllFormsRecordsLoading,
    error: allFormsRecordsError,
  } = useGetAllFormsRecordsQuery(
    {
      moduleId,
      filters: processFieldFilters(gridOptions?.fieldFilters, dateFormatBasedOnFilter),
      globalHierarchyFilters: buildGlobalHierarchyFiltersUrl(globalFilters, globalFiltersMatchingOption),
      sorts: buildSortedFieldsUrlString(gridOptions?.sortedFields, gridRef?.current?.api.data.columns, true),
      page: gridOptions?.page ?? 0,
      pageSize: gridOptions?.pageSize ?? 0,
      captionValuesBase64encoded: true,
    },
    {
      skip:
        selectedForm === undefined ||
        isDropDownNoSelection(selectedForm) ||
        gridOptions === undefined ||
        gridOptions?.page === undefined ||
        gridOptions?.pageSize === undefined ||
        selectedFilters.viewConfig === undefined ||
        selectedFilters.records === undefined ||
        gridSettingsLoading,
    }
  );

  const isAllFormsRecordsLoaded: boolean = !isAllFormsRecordsFetching && !isAllFormsRecordsLoading;

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.api.data.getDataOverwritePageCache();
    }
  }, [globalFilters, globalFiltersMatchingOption]);

  useEffect(() => {
    if (allFormsRecordsError) {
      gridSubscriber.current?.sendData();
    } else if (allFormsRecords && isAllFormsRecordsLoaded && !gridSettingsLoading) {
      if (allFormsRecords.results?.length > 0) {
        gridSubscriber.current?.sendData({
          data: forceAssert<JsonData>(allFormsRecords.results),
          requestId: gridOptions?.requestId,
        });
      } else {
        gridSubscriber.current?.sendData();
      }

      onDataLoadCompleted?.();
    }
  }, [allFormsRecords, isAllFormsRecordsLoaded, gridOptions, gridSettingsLoading, allFormsRecordsError]);

  const tagCreator = useTestTag('all-form-grid');

  const gridData = useMemo<OdinDataRetrieval | undefined>(() => {
    if (!areWorkflowStepsFetching) {
      return {
        getSubscriber: (subscriber, fieldType?: DynamicFormFieldType) => {
          if (fieldType !== 'COMBOBOX') {
            gridSubscriber.current = subscriber;
          }
        },
        getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
          if (options?.fieldType === 'COMBOBOX') {
            await getDataGridComboboxFilters(
              subscriber,
              options,
              dispatch,
              true,
              {
                selectedForm,
                workflowSteps: allWorkflowSteps?.map((step) => {
                  return { label: step, id: step };
                }),
              },
              undefined,
              moduleId
            );
          } else {
            setGridOptions(options);
          }
        },
      };
    }
  }, [allWorkflowSteps, areWorkflowStepsFetching]);

  const recordCountDisplayValue = useMemo<string | undefined>(() => {
    if (isAllFormsRecordsLoaded && allFormsRecords && !gridSettingsLoading) {
      const currentPageRecordsCount = allFormsRecords?.results?.length ?? 0;

      return getCurrentlyLoadedAndTotalRecords(
        currentPageRecordsCount,
        allFormsRecords.total ?? 0,
        gridOptions?.pageSize ?? 1,
        gridOptions?.page ?? 1
      );
    }
    return undefined;
  }, [allFormsRecords, isAllFormsRecordsLoaded, gridSettingsLoading]);

  const onDataGridRefCreated = (dataGridRef: DataGridRef) => {
    gridRef.current = dataGridRef;
    setDataGridReference(dataGridRef);
  };

  const handleViewChange = (hasChanged: boolean) => {
    onViewChange?.(hasChanged);
  };

  const canRenderDataGrid = !gridSettingsLoading && !!gridSettings?.columns && !!gridData;
  const { renderDataGrid } = useRenderDataGrid(canRenderDataGrid);

  return (
    <div className="flex h-full flex-col overflow-hidden">
      {!renderDataGrid && !gridSettingsError && <DataGridLoader isAllForms />}
      {canRenderDataGrid && (
        <>
          <DataGrid
            key={`${moduleId}_all_forms`}
            ref={(gridRef) => gridRef && onDataGridRefCreated(gridRef)}
            data={gridData}
            gridSettings={gridSettings}
            rowTemplate={rowTemplate}
            customSettingsMenuItems={menuItems}
            onViewChange={handleViewChange}
          />
          <div className="font-semibold mb-5" ref={tagCreator('record-count')}>
            {recordCountDisplayValue}
          </div>
        </>
      )}
      {gridSettingsError && (
        <div className="flex items-center justify-center">
          <Notification
            key="configNotification"
            title={t('error-config-title')}
            description={t('error-config-view-description')}
            type="error"
          />
        </div>
      )}
      <ErrorLoadingDataNotification dataError={allFormsRecordsError} />
    </div>
  );
}

export default AllFormsGrid;
