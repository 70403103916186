export enum UsersPermissions {
  USERS_CREATE = 'USERS_CREATE',
  USERS_MODIFY = 'USERS_MODIFY',
  USERS_READ = 'USERS_READ',
}
export enum GroupsPermissions {
  ROLES_CREATE = 'ROLES_CREATE',
  ROLES_MODIFY = 'ROLES_MODIFY',
  ROLES_READ = 'ROLES_READ',
}
export enum HierarchiesPermissions {
  HIERARCHIES_CREATE = 'HIERARCHIES_CREATE',
  HIERARCHIES_MODIFY = 'HIERARCHIES_MODIFY',
  HIERARCHIES_READ = 'HIERARCHIES_READ',
}
export enum OccupationsPermissions {
  OCCUPATION_CREATE = 'OCCUPATION_CREATE',
  OCCUPATION_MODIFY = 'OCCUPATION_MODIFY',
  OCCUPATION_READ = 'OCCUPATION_READ',
}
export enum TrainingManagementPermissions {
  TRAINING_MANAGEMENT_CREATE = 'TRAINING_MANAGEMENT_CREATE',
  TRAINING_MANAGEMENT_MODIFY = 'TRAINING_MANAGEMENT_MODIFY',
  TRAINING_MANAGEMENT_READ = 'TRAINING_MANAGEMENT_READ',
  TRAINING_MANAGEMENT_DELETE = 'TRAINING_MANAGEMENT_DELETE',
}
export enum FormsPermissions {
  BASIC_FORMBUILDER_DELETE_FIELDS = 'BASIC_FORMBUILDER_DELETE_FIELDS',
  BASIC_FORMBUILDER_READ = 'BASIC_FORMBUILDER_READ',
  BASIC_FORMBUILDER_CREATE = 'BASIC_FORMBUILDER_CREATE',
  BASIC_FORMBUILDER_MODIFY = 'BASIC_FORMBUILDER_MODIFY',
  ADVANCED_FORMBUILDER_READ = 'ADVANCED_FORMBUILDER_READ',
  ADVANCED_FORMBUILDER_CREATE = 'ADVANCED_FORMBUILDER_CREATE',
  ADVANCED_FORMBUILDER_MODIFY = 'ADVANCED_FORMBUILDER_MODIFY',
  ADVANCED_FORMBUILDER_DELETE = 'ADVANCED_FORMBUILDER_DELETE',
}
export enum AdminHomePagePermissions {
  HOME_PAGE_CREATE = 'HOME_PAGE_CREATE',
  HOME_PAGE_MODIFY = 'HOME_PAGE_MODIFY',
  HOME_PAGE_READ = 'HOME_PAGE_READ',
}
export enum LicensingPermissions {
  LICENSING_READ = 'LICENSING_READ',
}
export enum SchedulePagePermissions {
  SCHEDULES_CREATE = 'SCHEDULES_CREATE',
  SCHEDULES_MODIFY = 'SCHEDULES_MODIFY',
  SCHEDULES_READ = 'SCHEDULES_READ',
}
export enum RulesPermissions {
  AUTO_RULE = 'AUTO_RULE',
}
export enum RecordNotificationsPermissions {
  NOTIFICATIONS_CREATE = 'NOTIFICATIONS_CREATE',
  NOTIFICATIONS_MODIFY = 'NOTIFICATIONS_MODIFY',
  NOTIFICATIONS_READ = 'NOTIFICATIONS_READ',
}
export enum RiskMatrixPermissions {
  RISK_MATRIX_MODIFY = 'RISK_MATRIX_MODIFY',
  RISK_MATRIX_READ = 'RISK_MATRIX_READ',
}
export enum ModulesPermissions {
  MODULES_CREATE = 'MODULES_CREATE',
  MODULES_MODIFY = 'MODULES_MODIFY',
  MODULES_READ = 'MODULES_READ',
}
