import {
  DropDownResult,
  DynamicFieldDataProps,
  DynamicFormRequiredType,
  DynamicFormSettings,
  fieldValidationErrorMessage,
} from '@myosh/odin-components';
import i18next from '../../../../i18n';
import * as yup from 'yup';
import { HierarchyType } from '../../../../@types/hierarchy-fields';
import { HfHierarchyFieldAdmin } from '../../../fields/hierarchy/hf-hierarchy-field-admin.component';
import HfDropDown from '../../../fields/custom-drop-downs/hf-drop-down.component';

const dataSettings: DynamicFieldDataProps = {
  valueField: 'value',
  textField: 'text',
};

export const getOcccupationalRequirementsFormSettings = (
  hierarchyTypes: HierarchyType[],
  validateCompetencies: boolean,
  setAllHierarchies: boolean = false
): DynamicFormSettings => {
  return {
    id: 0,
    caption: '',
    fields: [
      {
        id: 'hierarchySection',
        fieldName: 'hierarchySection',
        fieldType: 'GROUP',
        caption: i18next.t('hierarchy'),
        position: 0,
        startOpen: true,
        permissions: { read: true, edit: true },
        fields: [
          {
            id: 'hierarchyFields',
            fieldType: 'CUSTOM',
            customFieldType: 'HIERARCHY_ADMIN',
            caption: i18next.t('hierarchy'),
            fieldName: 'hierarchyFields',
            required: DynamicFormRequiredType.True,
            position: 0,
            permissions: { read: true },
            dynamicProperties: {
              hierarchyTypes: hierarchyTypes,
            },
            hidden: setAllHierarchies,
            validationFunc: hierarchyFieldValidation(hierarchyTypes),
          },
          {
            id: 'hierarchyMatchingOption',
            fieldType: 'OPTIONGROUP',
            fieldName: 'hierarchyMatchingOption',
            caption: i18next.t('hierarchy-matching'),
            position: 1,
            dataSettings: dataSettings,
          },
        ],
      },

      {
        id: 100,
        fieldType: 'GROUP',
        fieldName: 'Details',
        caption: 'Details',
        position: 1,
        startOpen: true,
        permissions: { read: true, edit: true },
        fields: [
          {
            id: 'occupation',
            fieldType: 'COMBOBOX',
            fieldName: 'occupation',
            caption: i18next.t('occupation'),
            position: 1,
            dataSettings: dataSettings,
            required: DynamicFormRequiredType.True,
          },
          {
            id: 'mandatoryCompetencies',
            fieldType: 'TWIN_COLUMN_SELECT',
            fieldName: 'mandatoryCompetencies',
            caption: i18next.t('mandatory-competencies'),
            position: 2,
            dataSettings: dataSettings,
            required: validateCompetencies ? DynamicFormRequiredType.True : DynamicFormRequiredType.False,
            validationFunc: validationOfDesirableAndMandatoryCompetencies(
              i18next.t('mandatory-competencies'),
              'desirableCompetencies'
            ),
          },

          {
            id: 'desirableCompetencies',
            fieldType: 'TWIN_COLUMN_SELECT',
            fieldName: 'desirableCompetencies',
            caption: i18next.t('desirable-competencies'),
            position: 3,
            dataSettings: dataSettings,
            required: validateCompetencies ? DynamicFormRequiredType.True : DynamicFormRequiredType.False,
            validationFunc: validationOfDesirableAndMandatoryCompetencies(
              i18next.t('desirable-competencies'),
              'mandatoryCompetencies'
            ),
          },
        ],
      },
    ],
    customFieldComponents: {
      HIERARCHY_ADMIN: HfHierarchyFieldAdmin,
      COMBOBOX: HfDropDown,
    },
  };
};

const hierarchyFieldValidation = (hierarchyTypes?: HierarchyType[]) => {
  const requiredHierarchyTypes = hierarchyTypes ?? [];
  return yup
    .object(
      requiredHierarchyTypes.reduce(
        (schema, key) => ({
          ...schema,
          [key.caption]: yup.array(),
        }),
        {}
      )
    )
    .test('at-least-one-hierarchy-value', i18next.t('at-least-one-hierarchy'), (value) => {
      const arrays = Object.values(value);
      const hasElements = arrays.some((array) => Array.isArray(array) && array.length > 0);
      return hasElements;
    });
};

const validationOfDesirableAndMandatoryCompetencies = (fieldLabel: string, otherField: string) => {
  return yup.array().when(otherField, {
    is: (otherFieldValue: Array<DropDownResult>) => !otherFieldValue || otherFieldValue.length === 0,
    then: (schema) => schema.required(fieldValidationErrorMessage(fieldLabel)),
    otherwise: (schema) => schema,
  });
};
