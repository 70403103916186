import { JsonDataItem, JsonDataWrapper, OdinDataRetrievalOptions, OdinDataSender } from '@myosh/odin-components';
import i18next from 'i18next';

const booleanType: Array<JsonDataItem> = [
  { text: i18next.t('yes'), value: true },
  { text: i18next.t('no'), value: false },
];

const getBooleanTypes = async (subscriber: OdinDataSender<JsonDataWrapper>, options: OdinDataRetrievalOptions) => {
  if (options.page === 1) {
    subscriber.sendData({ data: booleanType });
  } else {
    subscriber.sendData();
  }
};

export const getHierarchyFieldOptions = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions
) => {
  switch (options?.fieldId?.toString()) {
    case 'mandatoryForUserRecords':
    case 'mandatoryForNewForms':
    case 'external':
    case 'dependsOnParent':
      await getBooleanTypes(subscriber, options);
      break;
    default:
      subscriber.sendData();
  }
};
