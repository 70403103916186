import { toast, ToastOptions } from 'react-hot-toast';
import { NotificationProps } from '../components/notifications/notifications.component';

export function showSuccess(message: string, options?: NotificationProps): string {
  return toast.success(message, options);
}

export function showError(message: string, options?: NotificationProps): string {
  return toast.error(message, options);
}

export function showInfo(message: string, options?: NotificationProps): string {
  return toast.custom(message, { duration: 3000, ...(options ?? {}), type: 'info' } as unknown as ToastOptions);
}

export function showWarning(message: string, options?: NotificationProps): string {
  return toast.custom(message, { ...(options ?? {}), type: 'warning', duration: 4000 } as unknown as ToastOptions);
}

export function dismiss(toastId?: string): void {
  if (toastId) {
    toast.dismiss(toastId);
  }
}

export function promiseToast<T>(
  promise: Promise<T>,
  msgs: {
    loading: string;
    success: string;
    error: string;
  },
  options?: NotificationProps
): Promise<T> {
  return toast.promise(promise, msgs, options);
}
