import React from 'react';
import { OdinController } from '@myosh/odin-components';
import { Control } from 'react-hook-form';
import IntervalField, { IntervalFieldProps } from './interval-field.component';

interface HfInterval extends IntervalFieldProps {
  control: Control;
}

export default function HfInterval({
  control,
  name = '',
  label,
  readOnly,
  intervalOptions,
  fieldNames,
  onChange,
}: HfInterval) {
  return (
    <OdinController
      name={name}
      control={control}
      onChange={onChange}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <IntervalField
            name={name}
            label={label}
            value={value}
            fieldError={error}
            readOnly={readOnly}
            intervalOptions={intervalOptions}
            fieldNames={fieldNames}
            onChange={onChange}
          />
        );
      }}
    />
  );
}
