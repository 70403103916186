import { BehaviorSubject } from 'rxjs';
import { RecordRef } from '../components/record/record.component';

const recordSubject = new BehaviorSubject<RecordRef | null>(null);

export function getRecordReference() {
  return recordSubject.asObservable();
}

export function setRecordReference(reference: RecordRef) {
  recordSubject.next(reference);
}
