import React, { Ref, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { t } from 'i18next';
import {
  Checkbox,
  CheckboxChangeEventType,
  DomElementAlignment,
  DomTargetPosition,
  DropDownResult,
  HelpComponent,
} from '@myosh/odin-components';
import { configurationHelpMessage, errorhandlingOptions, unrecognisedOptionHelpText } from './record-import-settings';
import OdinDropDown from '../../fields/custom-drop-downs/odin-drop-down.component';
import { timeZones } from '../../../common/time-zones';
import { USER_TIME_ZONE } from '../../../common/date-util';

export interface ImportConfiguration {
  optionsImportMode: DropDownResult;
  timeZone: DropDownResult;
  sendRecordNotifications?: CheckboxChangeEventType;
}
export interface ImportRecordsModalRef {
  getConfigurationValues: () => ImportConfiguration | undefined;
}

function ConfigurationSection(props: unknown, ref: Ref<ImportRecordsModalRef>) {
  const [fieldValues, setFieldValues] = useState({} as ImportConfiguration);
  const [validationError, setValidationError] = useState(false);

  const handleFieldChange = (fieldName: keyof ImportConfiguration, value: unknown) => {
    if (fieldName === 'timeZone' && (value as DropDownResult)?.value) {
      setValidationError(false);
    }

    setFieldValues({
      ...fieldValues,
      [fieldName]: value,
    });
  };

  const getInitialValues = useCallback(() => {
    const timeZoneOption = timeZones.find((timeZone) => timeZone.value === USER_TIME_ZONE);
    return {
      optionsImportMode: errorhandlingOptions[0],
      timeZone: timeZoneOption as DropDownResult,
      sendRecordNotifications: undefined,
    };
  }, []);

  useEffect(() => {
    setFieldValues(getInitialValues());
  }, []);

  const getConfigurationValues = () => {
    if (fieldValues.timeZone?.value) {
      return fieldValues;
    } else {
      setValidationError(true);
    }
  };

  useImperativeHandle(ref, () => ({
    getConfigurationValues,
  }));

  return (
    <div className="my-6 odin-relative">
      <label className="text-sm font-bold">{`2. ${t('configuration')}`}</label>
      <HelpComponent
        className="help-component-default"
        description={configurationHelpMessage()}
        tooltipAlignment={DomElementAlignment.TopRight}
        tooltipTargetPosition={DomTargetPosition.TopLeft}
        tooltipZIndexCheck
      />

      <div className="my-2">
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-[8rem,1fr] items-center">
            <span className="whitespace-nowrap text-sm w-32">{String(t('error-handling'))}</span>
            <div className="flex flex-row gap-2 items-center">
              <OdinDropDown
                name="optionsImportMode"
                textField="text"
                valueField="value"
                value={fieldValues['optionsImportMode']}
                onChange={(value) => handleFieldChange('optionsImportMode', value)}
                data={errorhandlingOptions}
                allowClear={false}
              />
              <HelpComponent
                description={unrecognisedOptionHelpText()}
                tooltipAlignment={DomElementAlignment.TopRight}
                tooltipTargetPosition={DomTargetPosition.TopLeft}
                tooltipZIndexCheck
              />
            </div>
            <span className="whitespace-nowrap text-sm mt-4">{String(t('time-zone'))}</span>
            <OdinDropDown
              name="timeZone"
              textField="text"
              valueField="value"
              className="mt-4"
              data={timeZones}
              value={fieldValues['timeZone']}
              onChange={(value) => handleFieldChange('timeZone', value)}
              deselectValue={undefined}
            />
            {validationError && (
              <span className="col-start-2 mt-1 whitespace-nowrap text-sm text-info">
                {String(t('file-creation-timezone'))}
              </span>
            )}
          </div>

          <Checkbox
            name="sendRecordNotifications"
            label={t('send-record-notifications')}
            onChange={(value) => {
              handleFieldChange('sendRecordNotifications', value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default forwardRef(ConfigurationSection);
