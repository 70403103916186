import { DropDownResult, HfDynamicFieldComponentProps, OdinController } from '@myosh/odin-components';
import React, { useMemo } from 'react';
import MultiselectPersonField, { MultiselectPersonFieldProps } from './multiselect-person-field.component';
import { LookupTypeFieldProps, ReadersProps } from '../../../@types/forms';
import useProfileData from '../../../hooks/use-profile-data';

interface HfMultiselectPersonFieldProps
  extends HfDynamicFieldComponentProps<DropDownResult[]>,
    LookupTypeFieldProps,
    ReadersProps {
  shouldUseCreatePortal?: boolean;
}

export default function HfReadersField(props: HfMultiselectPersonFieldProps) {
  const { name, control, value, onChange, shouldUseCreatePortal = false, ...rest } = props;

  return (
    <OdinController
      name={name as string}
      control={control}
      defaultValue={value}
      onChange={onChange}
      render={({ field: { ref: fieldRef, onChange, value }, fieldState: { error } }) => (
        <ReaderFieldWrapper
          {...rest}
          value={value}
          onChange={onChange}
          shouldUseCreatePortal={shouldUseCreatePortal}
          error={error?.message}
          inputRef={fieldRef}
          control={control}
        />
      )}
    />
  );
}

interface ReaderFieldWrapperProps extends MultiselectPersonFieldProps, ReadersProps {
  shouldUseCreatePortal?: boolean;
  showRecordWillBecomeUnavailableMessage?: (field?: string) => void;
  hideRecordWillBecomeUnavailableMessage?: (field?: string) => void;
}

// A wrapper around the 'reader' field that displays a warning if the current user is not in the list of selected values
const ReaderFieldWrapper = (props: ReaderFieldWrapperProps) => {
  const {
    name,
    onChange,
    readersExemptGroups = [],
    shouldUseCreatePortal = false,
    showRecordWillBecomeUnavailableMessage,
    hideRecordWillBecomeUnavailableMessage,
    ...rest
  } = props;
  const { profileData: { user: { id, groups } = {} } = {} } = useProfileData();

  const exception = useMemo(() => {
    const groupsUserBelongsTo = groups?.map((group) => Number(group.id)) ?? [];
    return readersExemptGroups.some((exemptGroupId) => groupsUserBelongsTo.includes(exemptGroupId));
  }, [groups]);

  const onValueChange = (fieldValue: DropDownResult[]) => {
    if (fieldValue && fieldValue.length > 0 && id) {
      const currentUserNotFound = !fieldValue.find((person) => person.value === id);
      if (!exception && currentUserNotFound) {
        showRecordWillBecomeUnavailableMessage?.(name);
      } else {
        hideRecordWillBecomeUnavailableMessage?.(name);
      }
    } else {
      hideRecordWillBecomeUnavailableMessage?.(name);
    }

    onChange?.(fieldValue);
  };

  return (
    <MultiselectPersonField
      name={name}
      shouldUseCreatePortal={shouldUseCreatePortal}
      onChange={(value) => onValueChange(value as DropDownResult[])}
      {...rest}
    />
  );
};
