import {
  DynamicFieldDataProps,
  DynamicFormRequiredType,
  DynamicFormSettings,
  JsonDataItem,
} from '@myosh/odin-components';
import { t } from 'i18next';
import HfDropDown from '../../fields/custom-drop-downs/hf-drop-down.component';
import HfLinkedRecords from '../../fields/linked-records/hf-linked-records.component';
import HfTargetRecord from '../../fields/target-record/hf-target-record.component';

const dataSettings: DynamicFieldDataProps = {
  valueField: 'value',
  textField: 'text',
};

export const getRuleFormSettings = (
  hasEditAccess?: boolean,
  targetModuleFormId?: number,
  ruleType?: JsonDataItem,
  triggerType?: JsonDataItem
): DynamicFormSettings => {
  return {
    id: 'rule-form-settings',
    caption: '',
    fields: [
      {
        id: 'details',
        fieldName: 'details',
        fieldType: 'GROUP',
        caption: t('details'),
        position: 2,
        startOpen: true,
        permissions: { read: true, edit: hasEditAccess },
        fields: [
          {
            id: 'caption',
            fieldType: 'TEXTFIELD',
            caption: t('name'),
            fieldName: 'caption',
            position: 0,
            permissions: { read: true, edit: hasEditAccess },
            required: DynamicFormRequiredType.True,
          },
          {
            id: 'autoRuleType',
            fieldType: 'OPTIONGROUP',
            caption: t('type'),
            fieldName: 'autoRuleType',
            position: 1,
            permissions: { read: true, edit: hasEditAccess },
            required: DynamicFormRequiredType.True,
            dataSettings: dataSettings,
            dynamicProperties: {
              lookupType: 'NONE',
              orientation: 'VERTICAL',
            },
          },
          {
            id: 'module',
            fieldType: 'COMBOBOX',
            caption: t('target-module'),
            fieldName: 'module',
            position: 2,
            permissions: { read: true, edit: hasEditAccess },
            dynamicProperties: { disabled: !hasEditAccess },
            required: DynamicFormRequiredType.True,
            dataSettings: dataSettings,
            hidden: ruleType?.value === 'UPDATE_CURRENT_RECORD',
          },
          {
            id: 'moduleForm',
            fieldType: 'COMBOBOX',
            caption: t('target-form'),
            fieldName: 'moduleForm',
            position: 3,
            permissions: { read: true, edit: hasEditAccess },
            dynamicProperties: { disabled: !hasEditAccess },
            required: DynamicFormRequiredType.True,
            dataSettings: dataSettings,
            hidden: ruleType?.value === 'UPDATE_CURRENT_RECORD',
          },
          {
            id: 'recordId',
            caption: t('target-record'),
            fieldName: 'recordId',
            fieldType: 'CUSTOM',
            position: 4,
            permissions: { read: true, edit: hasEditAccess },
            required: DynamicFormRequiredType.True,
            hidden: ruleType?.value !== 'UPDATE_EXISTING',
            dynamicProperties: {
              formId: targetModuleFormId,
              showSelectButton: Boolean(targetModuleFormId),
              gridColumns: [
                {
                  id: 1,
                  field: 'docNo',
                  title: t('doc-no'),
                  visible: true,
                },
                {
                  id: 2,
                  title: t('form-name'),
                  field: 'formName',
                  visible: true,
                },
                {
                  id: 3,
                  title: t('status'),
                  field: 'status',
                  visible: true,
                },
                {
                  id: 4,
                  field: 'version',
                  title: t('version'),
                  visible: true,
                },
              ],
            },
            customFieldType: 'TARGETRECORD',
          },
          {
            id: 'autoRuleTrigger',
            fieldType: 'OPTIONGROUP',
            caption: t('trigger'),
            fieldName: 'autoRuleTrigger',
            position: 5,
            permissions: { read: true, edit: hasEditAccess },
            dataSettings: dataSettings,
            required: DynamicFormRequiredType.True,
            dynamicProperties: {
              lookupType: 'NONE',
              orientation: 'VERTICAL',
            },
          },
          {
            id: 'triggerWorkflowStep',
            fieldType: 'COMBOBOX',
            caption: t('status-record-goes-to'),
            fieldName: 'triggerWorkflowStep',
            position: 2,
            permissions: { read: true, edit: hasEditAccess },
            dynamicProperties: { disabled: !hasEditAccess },
            required: DynamicFormRequiredType.True,
            dataSettings: dataSettings,
            hidden: triggerType?.value === 'RECORD_SAVE',
          },
        ],
      },
      {
        id: 'condition',
        fieldName: 'condition',
        fieldType: 'GROUP',
        caption: t('condition'),
        position: 3,
        startOpen: true,
        permissions: { read: true, edit: hasEditAccess },
      },
      {
        id: 'actions',
        fieldName: 'actions',
        fieldType: 'GROUP',
        caption: t('actions'),
        position: 4,
        startOpen: true,
        permissions: { read: true, edit: hasEditAccess },
      },
    ],
    customFieldComponents: {
      COMBOBOX: HfDropDown,
      RECORDLINK: HfLinkedRecords,
      TARGETRECORD: HfTargetRecord,
    },
  };
};
