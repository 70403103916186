import { useEffect, useState } from 'react';

/**
 * A helper hook that specifies when the data grid can be rendered.
 *
 * This hook intentionally uses an artificial delay when setting the state value,
 * to ensure that any data grid re-renders won't be visible to the end user.
 *
 * @param canRenderDataGrid Specifies if all conditions are met to show the data grid.
 */
const useRenderDataGrid = (canRenderDataGrid: boolean) => {
  const [renderDataGrid, setRenderDataGrid] = useState(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (canRenderDataGrid && canRenderDataGrid !== renderDataGrid) {
      timeoutId = setTimeout(() => {
        setRenderDataGrid(true);
      }, 800); // artificial delay to prevent UI flickering
    } else {
      setRenderDataGrid(false);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [canRenderDataGrid]);

  return {
    renderDataGrid,
  };
};

export default useRenderDataGrid;
