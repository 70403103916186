import { DynamicFieldDataProps, DynamicFormRequiredType, DynamicFormSettings } from '@myosh/odin-components';
import i18next from '../../i18n';
import HierarchyValuesTable from '../fields/hierarchy-values-field/hierarchy-values-table.component';

const selectionDataSettings: DynamicFieldDataProps = {
  valueField: 'value',
  textField: 'text',
};

export const getHierarchyFormSettings = (readOnly: boolean, hierarchyId: number): DynamicFormSettings => {
  return {
    id: 0,
    caption: '',
    fields: [
      {
        id: 'hierarchyDetails',
        fieldName: 'details',
        fieldType: 'GROUP',
        caption: i18next.t('details'),
        position: 1,
        startOpen: true,
        permissions: { read: true, edit: true },
        fields: [
          {
            id: 'caption',
            fieldType: 'TEXTFIELD',
            fieldName: 'caption',
            caption: i18next.t('name'),
            position: 0,
            required: DynamicFormRequiredType.True,
            permissions: { read: true, edit: !readOnly },
          },
          {
            id: 'mandatoryForUserRecords',
            fieldType: 'OPTIONGROUP',
            fieldName: 'mandatoryForUserRecords',
            caption: i18next.t('is-this-mandatory-for-user-records'),
            position: 1,
            dataSettings: selectionDataSettings,
            required: DynamicFormRequiredType.True,
            dynamicProperties: {
              orientation: 'HORIZONTAL',
            },
            helpMessage: i18next.t('mandatory-for-user-records-help-text'),
            permissions: { read: true, edit: !readOnly },
          },
          {
            id: 'mandatoryForNewForms',
            fieldType: 'OPTIONGROUP',
            fieldName: 'mandatoryForNewForms',
            caption: i18next.t('should-be-mandatory-for-new-forms'),
            position: 2,
            dataSettings: selectionDataSettings,
            required: DynamicFormRequiredType.True,
            dynamicProperties: {
              orientation: 'HORIZONTAL',
            },
            helpMessage: i18next.t('mandatory-for-new-forms-help-text'),
            permissions: { read: true, edit: !readOnly },
          },
          {
            id: 'external',
            fieldType: 'OPTIONGROUP',
            fieldName: 'external',
            caption: i18next.t('is-used-for-external'),
            position: 2,
            dataSettings: selectionDataSettings,
            required: DynamicFormRequiredType.True,
            dynamicProperties: {
              orientation: 'HORIZONTAL',
            },
            helpMessage: i18next.t('external-help-text'),
            permissions: { read: true, edit: !readOnly },
          },
          {
            id: 'dependsOnParent',
            fieldType: 'OPTIONGROUP',
            fieldName: 'dependsOnParent',
            caption: i18next.t('do-values-have-parent-values'),
            position: 3,
            dataSettings: selectionDataSettings,
            required: DynamicFormRequiredType.True,
            dynamicProperties: {
              orientation: 'HORIZONTAL',
            },
            helpMessage: i18next.t('depends-on-parent-help-text'),
            permissions: { read: true, edit: !readOnly },
          },
          {
            id: 'weight',
            fieldType: 'INTFIELD',
            fieldName: 'weight',
            caption: i18next.t('position'),
            position: 4,
            required: DynamicFormRequiredType.True,
            helpMessage: i18next.t('weight-help-text'),
            permissions: { read: true, edit: !readOnly },
          },

          {
            id: 'values',
            fieldType: 'CUSTOM',
            customFieldType: 'HIERARCHYVALUESTABLE',
            fieldName: 'values',
            caption: i18next.t('values'),
            position: 5,
            required: DynamicFormRequiredType.True,
            permissions: { read: true, edit: !readOnly },
            dynamicProperties: {
              hierarchyId,
              readOnly,
            },
          },
        ],
      },
    ],
    customFieldComponents: {
      HIERARCHYVALUESTABLE: HierarchyValuesTable,
    },
  };
};
