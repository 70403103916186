import { DynamicFormRequiredType, DynamicFormSettings } from '@myosh/odin-components';
import i18next from '../../../i18n';

export const getGroupFormSettings = (readOnly: boolean): DynamicFormSettings => {
  return {
    id: 0,
    caption: '',
    fields: [
      {
        id: 'caption',
        fieldType: 'TEXTFIELD',
        fieldName: 'caption',
        caption: i18next.t('name'),
        position: 0,
        required: DynamicFormRequiredType.True,
        permissions: { read: true, edit: !readOnly },
      },
      {
        id: 'managerEmail',
        fieldType: 'TEXTFIELD',
        fieldName: 'managerEmail',
        caption: i18next.t('email-authorisation-changes-to'),
        position: 2,
      },
    ],
  };
};
