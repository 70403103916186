import {
  Button,
  DataGridColumnSettings,
  DataGridSettings,
  DataSearchLocation,
  DropDownNoSelection,
  DropDownResult,
  isDropDownNoSelection,
  JsonData,
  JsonDataItem,
  ModalDialog,
  OdinIcon,
  OdinIconType,
} from '@myosh/odin-components';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ExtendedDynamicFormWorkflowStep, forceAssert } from '../../../../common/common-functions';
import {
  defaultLastEditedFilterOption,
  defaultRecordFilterOption,
  lastEditedFilterOptions,
  recordFilterOptions,
  resolveLastEditedFilterConfigValue,
  resolveRecordFilterConfigValue,
} from '../../../../common/dynamic-page-utils';
import OdinDropDown from '../../custom-drop-downs/odin-drop-down.component';
import useViewConfigs from '../../../../hooks/use-view-configs';
import { useFormSettingsQuery } from '../../../../redux/services/api';
import { ViewConfig } from '../../../../@types/views';
import AllRecordsGrid from './all-records-grid';
import LinkedRecordPreview from './linked-record-preview';
import LinkedRecordsGrid from './linked-records-grid';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { RecordLinkType } from '../../../../@types/forms';
import { defaultGridSettings } from '../../../dynamic-page/default-grid-settings';

interface LinkedRecordsSelectingProps {
  title?: string;
  visible: boolean;
  hidden: () => void;
  applyCallback: (linkedReocrdIds: Array<string>) => void;
  sourceModuleFormId?: number;
  targetModuleFormId?: number;
  recordLinkType?: RecordLinkType;
  value: Array<string>;
  linkedWorkflowSteps?: Array<ExtendedDynamicFormWorkflowStep>;
}

enum LinkedRecordsSelectingGrid {
  ALL_RECORDS_GRID,
  SELECTED_RECORDS_GRID,
}

// column configurator
const getColumnsConfigurator = (linkedRecordsGrid: LinkedRecordsSelectingGrid) => {
  const isAllRecordsGrid = linkedRecordsGrid === LinkedRecordsSelectingGrid.ALL_RECORDS_GRID;
  return (configColumn: DataGridColumnSettings) => {
    const column: DataGridColumnSettings = {
      ...configColumn,
      searchType: isAllRecordsGrid ? configColumn.searchType : undefined,
      isIdField: 'id' === configColumn.field.toLowerCase(),
    };

    if (configColumn.searchType === 'MultiSelect' || configColumn.searchType === 'SingleSelect') {
      column.dropDownValue = 'id';
      column.dropDownText = 'caption';
    }

    return column;
  };
};
const allRecordsColumnsConfigurator = getColumnsConfigurator(LinkedRecordsSelectingGrid.ALL_RECORDS_GRID);
const selectedRecordsColumnConfigurator = getColumnsConfigurator(LinkedRecordsSelectingGrid.SELECTED_RECORDS_GRID);

// default grid settings
const buildDefaultGridSettings = (linkedRecordsGrid: LinkedRecordsSelectingGrid) => {
  const isAllRecordsGrid = linkedRecordsGrid === LinkedRecordsSelectingGrid.ALL_RECORDS_GRID;

  return {
    ...defaultGridSettings,
    filterLocation: isAllRecordsGrid ? DataSearchLocation.Api : DataSearchLocation.Local,
  } as DataGridSettings;
};

const allRecordsDefaultGridSettings = buildDefaultGridSettings(LinkedRecordsSelectingGrid.ALL_RECORDS_GRID);
const selectedRecordsDefaultGridSettings = buildDefaultGridSettings(LinkedRecordsSelectingGrid.SELECTED_RECORDS_GRID);

export default function LinkedRecordsSelecting({
  title,
  visible,
  hidden,
  applyCallback,
  sourceModuleFormId,
  targetModuleFormId,
  recordLinkType,
  value,
  linkedWorkflowSteps,
}: LinkedRecordsSelectingProps) {
  const [linkedRecordIds, setLinkedRecordIds] = useState<Array<string>>(value || []);
  const [selectedConfig, setSelectedConfig] = useState<DropDownResult | DropDownNoSelection>();
  const [selectedLastEditedFilter, setSelectedLastEditedFilter] =
    useState<DropDownResult>(defaultLastEditedFilterOption);
  const [selectedRecordsFilter, setSelectedRecordsFilter] = useState<DropDownResult>(defaultRecordFilterOption);
  const [previewRecordId, setPreviewRecordId] = useState<string>();

  const { t } = useTranslation();

  const isCloneRecordLinkType = recordLinkType === 'CLONE';
  const formId = isCloneRecordLinkType ? sourceModuleFormId : targetModuleFormId;

  const { data: viewConfigs } = useViewConfigs(formId);
  const { data: formSettings } = useFormSettingsQuery(formId!, { skip: formId === undefined || formId <= 0 });

  useEffect(() => {
    if (value && Array.isArray(value)) {
      setLinkedRecordIds(value);
    }
  }, [value]);

  useEffect(() => {
    setSelectedLastEditedFilter(resolveLastEditedFilterConfigValue(formSettings?.dateRange));
  }, [formSettings]);

  const lastViewedRecord = useRef<string>();

  const updatePreviewRecord = useCallback((recordId?: string) => {
    if (recordId) {
      setPreviewRecordId(recordId);
      lastViewedRecord.current = recordId;
    }
  }, []);

  const getColorElementIndicator = useCallback(
    (data?: JsonDataItem) => {
      const backgroundColor = linkedWorkflowSteps?.find(
        (step: ExtendedDynamicFormWorkflowStep) => step.label === data?.status
      )?.backgroundColor;
      return <div className="h-4 w-4 self-center rounded" style={{ backgroundColor: backgroundColor }} />;
    },
    [linkedWorkflowSteps]
  );

  const optionRowIndicator = useMemo(() => {
    return {
      indicator: (data?: JsonDataItem) => {
        // preview icon should be shown on hover or when active
        const previewIconStyles = cx('text-primary-2 transition-opacity group-hover:odin-opacity-100', {
          'opacity-0': lastViewedRecord.current !== data?.id,
        });
        return (
          <div className="flex flex-row">
            <div
              className="contents"
              onClick={() => {
                const recordId = data?.id as string;
                updatePreviewRecord(recordId);
              }}
            >
              <OdinIcon icon="Eye" type={OdinIconType.Line} className={previewIconStyles} />
              {getColorElementIndicator(data)}
            </div>
          </div>
        );
      },
    };
  }, [getColorElementIndicator]);

  const selectedRowIndicator = useMemo(() => {
    return {
      indicator: (data?: JsonDataItem) => {
        return getColorElementIndicator(data);
      },
    };
  }, [getColorElementIndicator]);

  const addLinkedRecord = (id: string) => {
    const index = linkedRecordIds.findIndex((_id) => _id === id);

    if (isCloneRecordLinkType) {
      //For the clone record link type this function works like a toggle add/remove
      if (index === -1) {
        setLinkedRecordIds([...linkedRecordIds, id]);
      } else {
        setLinkedRecordIds(linkedRecordIds.filter((recordId) => recordId !== id));
      }
    } else {
      if (index === -1) {
        setLinkedRecordIds([...linkedRecordIds, id]);
      }
    }
  };

  const removeLinkedRecord = (id: string) => {
    const index = linkedRecordIds.findIndex((_id) => _id === id);
    if (index !== -1) {
      setLinkedRecordIds([...linkedRecordIds.slice(0, index), ...linkedRecordIds.slice(index + 1)]);
    }
  };

  const onConfigChange = (config?: DropDownResult | Array<DropDownResult> | DropDownNoSelection) => {
    if (config && !Array.isArray(config)) {
      setSelectedConfig(config);

      if (!isDropDownNoSelection(config)) {
        const viewConfig = viewConfigs?.find((item: ViewConfig) => item.id === config.value);
        const selectedRecordsFilter = resolveRecordFilterConfigValue(viewConfig?.archivedType);
        const selectedLastEditedFilter = resolveLastEditedFilterConfigValue(viewConfig?.dateRange);

        setSelectedRecordsFilter(selectedRecordsFilter);
        setSelectedLastEditedFilter(selectedLastEditedFilter);
      }
    }
  };

  const onRecordsFilterChange = (value?: DropDownResult | Array<DropDownResult> | DropDownNoSelection) => {
    if (value) {
      if (isDropDownNoSelection(value)) {
        setSelectedRecordsFilter(defaultRecordFilterOption);
      } else if (!Array.isArray(value)) {
        setSelectedRecordsFilter(value);
      } else {
        setSelectedRecordsFilter(value[0]);
      }
    }
  };

  const onLastEditedFilterChange = (value?: DropDownResult | Array<DropDownResult> | DropDownNoSelection) => {
    if (value) {
      if (isDropDownNoSelection(value)) {
        setSelectedLastEditedFilter(defaultLastEditedFilterOption);
      } else if (!Array.isArray(value)) {
        setSelectedLastEditedFilter(value);
      } else {
        setSelectedLastEditedFilter(value[0]);
      }
    }
  };

  const handleApplyChanges = () => {
    applyCallback(linkedRecordIds);
    hidden();
  };

  const handleClosePreview = () => setPreviewRecordId(undefined);

  const configDropdownStyle = 'mb-5 flex flex-shrink-0 flex-row items-center transition-transform';

  const header = (
    <div className="flex flex-col items-start pl-9">
      {title && <div className="mb-2">{title}</div>}
      <div className="mt-2 flex gap-3">
        <OdinDropDown
          textField="name"
          valueField="id"
          data={forceAssert<JsonData>(viewConfigs)}
          value={selectedConfig}
          allowSearch={false}
          allowClear={false}
          setDefaultValue={true}
          className={configDropdownStyle}
          label={t('view')}
          size="md"
          onChange={onConfigChange}
        />
        <OdinDropDown
          textField="text"
          valueField="value"
          data={lastEditedFilterOptions}
          allowSearch={false}
          allowClear={false}
          className={configDropdownStyle}
          label={t('last-edited')}
          size="md"
          onChange={onLastEditedFilterChange}
          value={selectedLastEditedFilter}
        />
        <OdinDropDown
          textField="text"
          valueField="value"
          data={recordFilterOptions}
          allowSearch={false}
          allowClear={false}
          className={configDropdownStyle}
          label={t('show-records')}
          size="md"
          onChange={onRecordsFilterChange}
          value={selectedRecordsFilter}
        />
      </div>
    </div>
  );

  const footer = useMemo(
    () => (
      <div className="flex justify-start">
        <Button type="primary" variant="alternative" onClick={handleApplyChanges}>
          {isCloneRecordLinkType ? t('clone-records') : t('apply')}
        </Button>
      </div>
    ),
    [handleApplyChanges]
  );

  const configId =
    selectedConfig && !isDropDownNoSelection(selectedConfig) ? (selectedConfig.value as number) : undefined;

  const gridWrapperStyles = cx('relative overflow-hidden', {
    'h-1/2': !isCloneRecordLinkType,
    'h-full': isCloneRecordLinkType,
  });

  return (
    <>
      <ModalDialog header={header} footer={footer} visible={visible} hidden={hidden} fullScreen={true}>
        <>
          <div className="flex h-full w-full flex-col gap-5">
            <div className={gridWrapperStyles}>
              <AllRecordsGrid
                formId={formId}
                configId={configId}
                defaultGridSettings={allRecordsDefaultGridSettings}
                columnConfigurator={allRecordsColumnsConfigurator}
                rowIndicator={optionRowIndicator}
                onAdded={addLinkedRecord}
                highlightedRows={linkedRecordIds}
                previewRecordId={previewRecordId}
                selectedLastEditedFilter={selectedLastEditedFilter}
                selectedRecordsFilter={selectedRecordsFilter}
              />
            </div>
            {!isCloneRecordLinkType && (
              <div className={gridWrapperStyles}>
                <LinkedRecordsGrid
                  recordIds={linkedRecordIds}
                  formId={targetModuleFormId}
                  configId={configId}
                  defaultGridSettings={selectedRecordsDefaultGridSettings}
                  columnConfigurator={selectedRecordsColumnConfigurator}
                  rowIndicator={selectedRowIndicator}
                  onRemoved={removeLinkedRecord}
                />
              </div>
            )}
          </div>
          {previewRecordId !== undefined && (
            <LinkedRecordPreview recordId={previewRecordId} onClose={handleClosePreview} />
          )}
        </>
      </ModalDialog>
    </>
  );
}
