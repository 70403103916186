import React from 'react';
import { Notification } from '@myosh/odin-components';
import { t } from 'i18next';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

interface ErrorLoadingDataNotificationProps {
  dataError?: FetchBaseQueryError | SerializedError;
}

const ErrorLoadingDataNotification = ({ dataError }: ErrorLoadingDataNotificationProps) => {
  return (
    <>
      {dataError && (
        <div className="absolute -translate-y-2/4 -translate-x-2/4 top-1/2 left-1/2">
          <Notification key="errorNotification" description={t('error-loading-data')} type="error" />
        </div>
      )}
    </>
  );
};

export default ErrorLoadingDataNotification;
