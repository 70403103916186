import React from 'react';
import { DropDownResult, DynamicFormRequiredType, HfRadioGroup } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { deliveryMethod, stateChangeTriggered } from '../../../record-notification/notifications-utils';
import { NotificationTypeFieldProps } from './notification-properties-utils';
import TriggerStatusChanged from '../trigger-type-field/trigger-status-changed';
import TriggerRecordEditedField from '../trigger-type-field/trigger-record-edited';
import TriggerDateField from '../trigger-type-field/trigger-date-field';

const NotificationTriggeredField = ({ control, value, readOnly }: NotificationTypeFieldProps) => {
  const { t } = useTranslation();

  const triggerType: DropDownResult = useWatch({
    control,
    name: 'stateChange',
    exact: true,
  });

  return (
    <div className="flex flex-col gap-y-0.5">
      <HfRadioGroup
        control={control}
        name="notificationDelivery"
        id="notificationDelivery"
        textField="text"
        valueField="value"
        data={deliveryMethod}
        orientation="HORIZONTAL"
        label={t('delivery-method')}
        readOnly={readOnly}
      />
      <HfRadioGroup
        control={control}
        name="stateChange"
        id="stateChange"
        textField="text"
        valueField="value"
        label={t('trigger')}
        data={stateChangeTriggered}
        orientation="VERTICAL"
        readOnly={readOnly}
        required={DynamicFormRequiredType.True}
      />
      {triggerType?.value === 'WORKFLOW_PROGRESS' && (
        <TriggerStatusChanged
          control={control}
          id="notificationPropertiesDTO"
          name="notificationPropertiesDTO"
          readOnly={readOnly}
          value={value}
        />
      )}
      {triggerType?.value === 'DATE_FIELD' && (
        <TriggerDateField
          value={value}
          control={control}
          id="notificationPropertiesDTO"
          name="notificationPropertiesDTO"
          readOnly={readOnly}
        />
      )}
      {triggerType?.value === 'DATA_CHANGED' && (
        <TriggerRecordEditedField
          control={control}
          id="notificationPropertiesDTO"
          name="notificationPropertiesDTO"
          value={value}
          readOnly={readOnly}
        />
      )}
    </div>
  );
};

export default NotificationTriggeredField;
