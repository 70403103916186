import {
  ModalDialogRef,
  JsonData,
  DataGridSettings,
  OdinDataRetrieval,
  OdinDataSender,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
} from '@myosh/odin-components';
import React, { forwardRef, Ref, useMemo } from 'react';
import { forceAssert } from '../../common/common-functions';
import { useLazyGetAuditLogsQuery, useLazyGetAuditLogDetailsQuery } from '../../redux/services/record';
import i18next from '../../i18n';
import AuditLogModal from '../audit-log/audit-log-modal.component';
import { AuditLogData } from '../../@types/audit';
import { GroupAuditLogData } from '../../@types/groups';

const dataGridSettings: DataGridSettings = {
  columns: [
    {
      id: 108,
      title: i18next.t('version'),
      field: 'version',
      visible: true,
      type: 1,
      width: 150,
    },
    {
      id: 109,
      title: i18next.t('date'),
      field: 'date',
      visible: true,
      type: 8,
      width: 150,
      isIdField: true,
    },
    {
      id: 110,
      title: i18next.t('user'),
      field: 'user',
      visible: true,
      width: 150,
      type: 0,
    },
    {
      id: 111,
      title: i18next.t('status'),
      field: 'status',
      visible: true,
      width: 150,
      type: 0,
    },
    {
      id: 112,
      title: i18next.t('record-version-update'),
      field: 'recordVersionUpdate',
      visible: true,
      width: 934,
      type: 0,
    },
  ],
};

interface AuditProps {
  header: string;
  recordId?: string;
}

function AuditView({ header, recordId }: AuditProps, ref: Ref<ModalDialogRef>) {
  const [getAuditLogs] = useLazyGetAuditLogsQuery();
  const [getAuditLogDetails] = useLazyGetAuditLogDetailsQuery();

  const auditGridData = useMemo<OdinDataRetrieval>(() => {
    return {
      getData: (dataResult: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if (options?.fieldType !== 'COMBOBOX') {
          getAuditLogs(
            {
              recordId: recordId!,
              page: options?.page ?? 1,
              pageSize: options?.pageSize ?? 50,
            },
            false
          )
            .unwrap()
            .then((auditData) => {
              dataResult.sendData({ data: forceAssert<JsonData>(auditData) });
            })
            .catch(() => {
              dataResult.sendData();
            });
        } else {
          dataResult.sendData();
        }
      },
    };
  }, []);

  const onRowSelectionChange = async (selectedRow: AuditLogData | GroupAuditLogData) => {
    const version = selectedRow.version;
    if (version && recordId) {
      try {
        const auditLogDetails = await getAuditLogDetails(
          {
            recordId,
            auditLogVersion: String(selectedRow.version),
          },
          true
        ).unwrap();

        return [...auditLogDetails];
      } catch (_) {
        return [];
      }
    } else {
      return [];
    }
  };

  return (
    <AuditLogModal
      dataGridData={auditGridData}
      dataGridSettings={dataGridSettings}
      onRowSelectionChange={onRowSelectionChange}
      header={header}
      recordId={recordId}
      ref={ref}
    />
  );
}

export default forwardRef(AuditView);
