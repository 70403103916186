import {
  DynamicForm,
  DynamicFormButtonSetting,
  DynamicFormRef,
  DynamicFormRequiredType,
  DynamicFormSettings,
  JsonDataItem,
  ModalDialog,
} from '@myosh/odin-components';
import i18next from 'i18next';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { RiskMatrixDataItem } from '../../../@types/risk-matrix-field';
import {
  useDeleteConsequenceMutation,
  useDeleteLikelyhoodMutation,
  useEditConsequenceMutation,
  useEditLikelyhoodMutation,
} from '../../../redux/services/risk-matrix';
import { createCaptionObj } from '../../../common/caption-util';
import { handleRiskMatrixApiResponse } from './risk-rating-functions';

export type RiskMatrixHederValue = {
  type: 'likelihood' | 'consequence';
  value: RiskMatrixDataItem;
};

interface RiskHeadersModalProps {
  visible: boolean;
  selectedRiskMatrixHeader?: RiskMatrixHederValue;
  handleModalClose: () => void;
}

const formSettings: DynamicFormSettings = {
  id: 0,
  caption: '',
  fields: [
    {
      id: 'caption',
      fieldType: 'TEXTFIELD',
      fieldName: 'caption',
      caption: i18next.t('name'),
      position: 0,
      required: DynamicFormRequiredType.True,
      permissions: { read: true, edit: true },
    },
    {
      id: 'descriptionCaption',
      fieldType: 'RICHTEXTAREA',
      fieldName: 'descriptionCaption',
      caption: i18next.t('description'),
      position: 1,
      permissions: { read: true, edit: true },
    },
  ],
};

const RiskHeadersModal = ({ selectedRiskMatrixHeader, visible, handleModalClose }: RiskHeadersModalProps) => {
  const { t } = useTranslation();
  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const dynamicFormId = useRef(v4());

  const [editConequence] = useEditConsequenceMutation();
  const [editLikelyhood] = useEditLikelyhoodMutation();
  const [deleteLikelyhood] = useDeleteLikelyhoodMutation();
  const [deleteConsequence] = useDeleteConsequenceMutation();

  const onFormSubmit = (data: JsonDataItem) => {
    const payload = {
      id: data?.id,
      caption: createCaptionObj(data.caption as string),
      descriptionCaption: data.descriptionCaption && createCaptionObj(data.descriptionCaption as string),
    };

    if (selectedRiskMatrixHeader?.type === 'likelihood') {
      handleRiskMatrixApiResponse(editLikelyhood(payload as Partial<RiskMatrixDataItem>).unwrap());
    } else {
      handleRiskMatrixApiResponse(editConequence(payload as Partial<RiskMatrixDataItem>).unwrap());
    }
    handleModalClose();
  };

  const handleDelete = () => {
    if (selectedRiskMatrixHeader?.value?.id) {
      if (selectedRiskMatrixHeader?.type === 'likelihood') {
        handleRiskMatrixApiResponse(deleteLikelyhood(selectedRiskMatrixHeader?.value?.id).unwrap());
      } else {
        handleRiskMatrixApiResponse(deleteConsequence(selectedRiskMatrixHeader?.value?.id).unwrap());
      }
      handleModalClose();
    }
  };

  const formValues = useMemo(() => {
    return {
      id: selectedRiskMatrixHeader?.value?.id,
      caption: selectedRiskMatrixHeader?.value?.caption?.translations[0]?.value,
      descriptionCaption: selectedRiskMatrixHeader?.value?.descriptionCaption?.translations[0]?.value,
    };
  }, [selectedRiskMatrixHeader]);

  const confirmationDialogButtons: Array<DynamicFormButtonSetting> = [
    {
      name: 'save',
      text: i18next.t('save'),
      htmlType: 'submit',
      type: 'primary',
      variant: 'alternative',
    },
    {
      name: 'delete',
      text: i18next.t('delete'),
      htmlType: 'button',
      type: 'default',
      variant: 'alternative',
      onClick: handleDelete,
    },
    {
      name: 'discard',
      text: i18next.t('discard'),
      htmlType: 'button',
      type: 'default',
      variant: 'alternative',
      onClick: handleModalClose,
    },
  ];

  return (
    <ModalDialog
      header={`${selectedRiskMatrixHeader?.type === 'likelihood' ? t('likelihood') : t('consequence')} - ${formValues.caption}`}
      visible={visible}
      hidden={handleModalClose}
      minDialogWidth={400}
      closeOnEscape={false}
      buttonsCloseModal={false}
    >
      <DynamicForm
        ref={dynamicFormReference}
        dynamicFormId={dynamicFormId.current}
        data={{ ...formValues }}
        settings={formSettings}
        readOnly={false}
        buttons={confirmationDialogButtons}
        buttonsLocation={1}
        buttonsPosition={1}
        onSubmit={onFormSubmit}
        validationEnabled={true}
      />
    </ModalDialog>
  );
};

export default RiskHeadersModal;
