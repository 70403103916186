import {
  ContinuousScrollerSort,
  DataGridColumnSettings,
  isObject,
  JsonData,
  JsonDataItem,
} from '@myosh/odin-components';
import i18next from '../../../i18n';
import { EntitySubType, RecordLinkEntity } from '../../../@types/linked-records-field';
import { forceAssert } from '../../../common/common-functions';
import { get, isString, orderBy } from 'lodash';

const questionnaireColumnTitleSuffix = (entitySubType?: EntitySubType) => {
  if ('TOTAL_SCORE' === entitySubType) {
    return i18next.t('score-total');
  } else if ('MAX_SCORE_POSSIBLE' === entitySubType) {
    return i18next.t('score-max');
  } else if ('AVERAGE_SCORE' === entitySubType) {
    return i18next.t('score-average');
  } else if ('PERCENT_SCORE' === entitySubType) {
    return i18next.t('score-percent');
  } else if ('MAX_POSSIBLE' === entitySubType) {
    return i18next.t('score-max');
  } else if ('PERCENTAGE' === entitySubType) {
    return i18next.t('score-percent');
  }
  return '';
};

const resolveEntityName = (recordLinkColumn: RecordLinkEntity) => {
  if ('QUESTIONNAIRE' === recordLinkColumn.entityType || 'GRAND_SCORE' === recordLinkColumn.entityType) {
    return `${recordLinkColumn.entityName.trim()} - ${questionnaireColumnTitleSuffix(recordLinkColumn.entitySubType)}`;
  } else {
    return recordLinkColumn.entityName;
  }
};

const resolveEntityPropertyName = (recordLinkColumn: RecordLinkEntity) => {
  if ('KEYWORD' === recordLinkColumn.entityType) {
    return recordLinkColumn.entityPropertyName;
  } else {
    return resolveEntityName(recordLinkColumn);
  }
};

const resolveCellRenderer = (
  recordLinkColumn: RecordLinkEntity,
  customCellRenderer?: (column: RecordLinkEntity) => string | undefined
) => {
  // only these two field types use a custom cell renderer
  if ('QUESTIONNAIRE' === recordLinkColumn.entityType || 'GRAND_SCORE' === recordLinkColumn.entityType) {
    return recordLinkColumn.entityType;
  } else if (customCellRenderer) {
    return customCellRenderer(recordLinkColumn);
  }
};

/**
 * Transforms the record link columns into DataGridColumnSettings.
 * @param {Array<RecordLinkEntity>} recordLinkColumns - The items to transform into data grid columns.
 * @return {Array<DataGridColumnSettings>} The converted array of DataGridColumnSettings objects.
 */
export const transformRecordLinkColumns = (
  recordLinkColumns: Array<RecordLinkEntity>,
  valuePath?: (recordLinkColumn: RecordLinkEntity) => string | undefined,
  sortPath?: (recordLinkColumn: RecordLinkEntity) => string | undefined,
  customCellRenderer?: (recordLinkColumn: RecordLinkEntity) => string | undefined
): Array<DataGridColumnSettings> => {
  return recordLinkColumns.map((recordLinkColumn: RecordLinkEntity, index: number) => {
    const recordLinkTitle = resolveEntityName(recordLinkColumn);
    const recordLinkField = resolveEntityPropertyName(recordLinkColumn);
    return {
      ...recordLinkColumn, // enrich column data with record link entity configuration data
      title: recordLinkTitle,
      field: recordLinkField,
      visible: true,
      type: recordLinkColumn.entityType ? 6 : undefined, // if there is an entity type then this is a custom column
      cellRenderer: resolveCellRenderer(recordLinkColumn, customCellRenderer),
      isIdField: index === 0,
      valuePath: valuePath?.(recordLinkColumn),
      sortPath: sortPath?.(recordLinkColumn),
    } satisfies DataGridColumnSettings;
  });
};

export const linkedRecordColumnValuePaths = (entity: RecordLinkEntity) => {
  const { entityType, entityId, entityPropertyName } = entity;
  let valuePath;
  if (['FIELD', 'QUESTIONNAIRE', 'GRAND_SCORE', 'RECORD_LINK', 'REVERSE_RECORD_LINK'].includes(entityType)) {
    valuePath = `fields.${entityId}`;
  } else if ('WORKFLOW_STEP' === entityType) {
    valuePath = `workflowStepHistory.${entityPropertyName}`;
  } else if ('HIERARCHY_TYPE' === entityType) {
    valuePath = `hierarchies.${entityPropertyName}`;
  }
  return valuePath;
};

export const getColumnSortPath = (entityReference: RecordLinkEntity): string | undefined => {
  if (entityReference.entityType === 'QUESTIONNAIRE') {
    switch (entityReference.entitySubType) {
      case 'TOTAL_SCORE':
        return '.result.totalScore';
      case 'AVERAGE_SCORE':
        return '.result.averageScore';
      case 'MAX_SCORE_POSSIBLE':
        return '.result.maxPossible';
      case 'PERCENT_SCORE':
        return '.result.percentage';
      default:
        return undefined;
    }
  }

  if (entityReference.entityType === 'GRAND_SCORE') {
    const subType = entityReference.entitySubType as string;
    switch (subType) {
      case 'TOTAL_SCORE':
        return '.totalScore';
      case 'MAX_POSSIBLE':
        return '.maxPossible';
      case 'PERCENTAGE':
        return '.percentage';
      default:
        return undefined;
    }
  }

  switch (entityReference.fieldType) {
    case 'COMBOBOX':
    case 'OPTIONGROUP':
    case 'PERSONFIELD':
      return '.value';
    case 'TRAINING':
      return '.name';
    case 'MULTISELECTFIELD':
    case 'MULTISELECTPERSONFIELD':
    case 'MULTISELECTCHECKBOX':
    case 'TWINCOLUMNSELECT':
    case 'READERS':
      return '[0].value'; // the assumption here is if multiple items are selected we would sort on the first item
    case 'RISKRATING':
      return '.displayValue';
    default:
      return undefined;
  }
};

export const linkedRecordsCellRenderer = (column: RecordLinkEntity): string | undefined => {
  if (['DATEFIELD', 'RICHTEXTAREA', 'RISKRATING', 'QUESTIONNAIRE', 'HISTORY'].includes(column.fieldType)) {
    return column.fieldType;
  } else if (column.entityType === 'KEYWORD' && column.entityPropertyName === 'creationDate') {
    return 'DATEFIELD';
  }
};

/**
 * Modify the linked records fields data to the same type as the default linked records data with the keytypes as id.
 * This needs to be done because the value path for the columns would not otherwise match.
 * @param data Linked record data
 * @returns Linked records fields data modified to a Record type
 */
export const transformLinkedRecordDataWithKeyTypeId = (data: JsonData) => {
  const transformedData: JsonData = [];

  for (let i = 0; i < data.length; i++) {
    const record = data[i];
    const fieldsData = forceAssert<Array<Record<string, unknown>>>(record?.['fields']);

    let transformedFieldsData: Record<string, unknown> = {};
    //If not array, data is already using keyType
    if (Array.isArray(fieldsData)) {
      for (let j = 0; j < fieldsData.length; j++) {
        const field = fieldsData[j];
        const fieldId = forceAssert<number>(field['fieldId']);

        if (field['type'] === 'TEXTUAL_QUESTIONNAIRE' || field['type'] === 'NUMERIC_QUESTIONNAIRE') {
          transformedFieldsData[fieldId] = { result: field['result'], value: field['value'] };
        } else {
          transformedFieldsData[fieldId] = field['value'];
        }
      }
    } else {
      transformedFieldsData = fieldsData;
    }

    transformedData.push({
      ...record,
      fields: transformedFieldsData,
    });
  }
  return transformedData;
};

export const linkedRecordsSortFunction = (
  data: JsonData,
  sorts: Array<ContinuousScrollerSort>,
  columns: Array<DataGridColumnSettings>
): JsonData => {
  const sortFields = sorts.map((sort) => {
    const column = columns.find((column) => column.field === sort.field);

    let sortValuePath: string;
    if (column?.valuePath) {
      sortValuePath = column.valuePath;
    } else {
      sortValuePath = sort.field;
    }

    if (column?.sortPath) {
      sortValuePath = sortValuePath + column.sortPath;
    }

    return (record: JsonDataItem) => {
      const sortValue = get(record, sortValuePath);
      if (isObject(sortValue)) {
        if (Array.isArray(sortValue) && sortValue.length > 0) {
          return sortValue[0].value ?? '';
        } else if (Array.isArray(sortValue) && sortValue.length === 0) {
          return undefined;
        } else if (sortValue.hasOwnProperty('value')) {
          return sortValue.value ?? '';
        }

        return sortValue;
      } else if (isString(sortValue)) {
        return sortValue.toLowerCase();
      } else {
        return sortValue;
      }
    };
  });

  const sortDirections = sorts.map((sort) => (sort.sortDirection === 0 ? 'asc' : 'desc'));

  const transformedData = data.map((record) =>
    forceAssert<JsonDataItem>({ ...record, docNo: Number(record['docNo']) })
  );

  return orderBy(transformedData, sortFields, sortDirections);
};
