import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface SchemaState {
  isManualSchemaUpdate: boolean;
}

const initialState: SchemaState = {
  isManualSchemaUpdate: false,
};

const schemaSlice = createSlice({
  name: 'schema',
  initialState,
  reducers: {
    setIsManualSchemaUpdate: (state, action: PayloadAction<boolean>) => {
      state.isManualSchemaUpdate = action.payload;
    },
  },
});

export const { setIsManualSchemaUpdate } = schemaSlice.actions;
export const selectIsManualSchemaUpdate = (state: RootState) => state.schema.isManualSchemaUpdate;
export default schemaSlice.reducer;
