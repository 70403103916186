import React, { useEffect, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, Events, STATUS } from 'react-joyride';
import { FeatureTours } from '../../../../@types/feature-tours';
import { useUpdateFeatureToursMutation } from '../../../../redux/services/api';
import { showError } from '../../../../services/notification.service';
import { useTranslation } from 'react-i18next';
import i18next from '../../../../i18n';
import useCustomJoyrideButtonsStyling from '../../../../hooks/use-custom-joyride-buttons-styling';

export interface FormSettingsFeatureTourProps {
  isMenuOpen: boolean;
  featureTourData: FeatureTours;
  setIsMoreOptionsActive: (value: boolean) => void;
}

const steps = [
  {
    target: '.form-settings-menu',
    content: i18next.t('feature-tour-form-settings-step-one'),
    disableBeacon: true,
    offset: 10,
    popover: {
      placement: 'left',
    },
    spotlightClicks: true,
    disableOverlayClose: true,
    hideFooter: true,
  },
  {
    target: '.restore-view',
    content: i18next.t('feature-tour-form-settings-step-two'),
    disableBeacon: true,
    popover: {
      placement: 'right',
    },
    spotlightClicks: false,
  },
  {
    target: '.save-view',
    content: i18next.t('feature-tour-form-settings-step-three'),
    popover: {
      placement: 'right',
    },
    spotlightClicks: false,
  },
  {
    target: '.manage-views',
    content: i18next.t('feature-tour-form-settings-step-four'),
    popover: {
      placement: 'right',
    },
    spotlightClicks: false,
  },
  {
    target: '.import-records',
    content: i18next.t('feature-tour-form-settings-step-five'),
    popover: {
      placement: 'right',
    },
    spotlightClicks: false,
  },
  {
    target: '.export-records',
    content: i18next.t('feature-tour-form-settings-step-six'),
    popover: {
      placement: 'right',
    },
    spotlightClicks: false,
  },
  {
    target: '.pin-feature',
    content: i18next.t('feature-tour-form-settings-step-seven'),
    popover: {
      placement: 'top',
    },
    spotlightClicks: false,
  },
];

const FormSettingsFeatureTour = ({
  isMenuOpen,
  featureTourData,
  setIsMoreOptionsActive,
}: FormSettingsFeatureTourProps) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [runTour, setRunTour] = useState(true);

  const [setFeatureTourAsSeen] = useUpdateFeatureToursMutation();
  const { t } = useTranslation();
  const { primaryButton, textButton, closeIconButton } = useCustomJoyrideButtonsStyling();

  useEffect(() => {
    if (isMenuOpen && stepIndex === 0) {
      setStepIndex(1);
    }
  }, [isMenuOpen]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status) || action === 'close') {
      setStepIndex(0);
      if (action === 'close') {
        setRunTour(false);
        setIsMoreOptionsActive(false);
      } else {
        setFeatureTourAsSeen({ ...featureTourData, hasFormSettingsTourRan: true })
          .unwrap()
          .then(() => setIsMoreOptionsActive(false))
          .catch(() => showError(t('feature-tour-save-fail')));
      }
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as Events[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (isMenuOpen && index === 1) {
        setStepIndex(nextStepIndex);
      } else {
        setStepIndex(nextStepIndex);
      }
    }
  };

  return (
    <Joyride
      steps={steps}
      run={runTour}
      continuous={true}
      stepIndex={stepIndex}
      showProgress={true}
      callback={handleJoyrideCallback}
      styles={{
        options: {
          zIndex: 1000,
        },
        buttonNext: primaryButton,
        buttonBack: textButton,
        buttonClose: closeIconButton,
      }}
      locale={{
        back: t('back'),
        close: t('close'),
        last: t('finish'),
        next: t('next'),
      }}
    />
  );
};

export default FormSettingsFeatureTour;
