import React, { ReactNode, ReactNodeArray } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

interface QuestionnaireDetailsProps {
  visible: boolean;
  open: boolean;
  onToggle: (open: boolean) => void;
  children: ReactNode | ReactNodeArray;
  readOnly?: boolean;
}

const QuestionnaireDetails = ({
  visible = false,
  open = false,
  onToggle,
  children,
  readOnly,
}: QuestionnaireDetailsProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onToggle(!open);
  };

  const detailsContainerStyles = cx('text mb-4 flex w-full flex-col', {
    // We use hidden, because we need the children to render.
    // This is required for the 'attachments' field, as we have to check if any are present.
    // The questionnaire field does not always provide all file values as part of the field data
    hidden: !visible,
  });

  const childrenContainerStyles = cx('shadow-md p-4 h-0 overflow-hidden mr-1 h-auto', {
    hidden: !open,
    'opacity-70': readOnly,
  });

  return (
    <div className={detailsContainerStyles}>
      <div className="flex flex-shrink cursor-pointer flex-row" onClick={handleClick}>
        <div className="select-none text-xs uppercase">{open ? t('details-less') : t('details-more')}</div>
      </div>
      <div className={childrenContainerStyles}>{children}</div>
    </div>
  );
};

export default QuestionnaireDetails;
