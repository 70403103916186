import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
  DynamicFieldComponentProps,
  DynamicFormRequiredType,
  ErrorLabel,
  JsonDataItem,
  RadioGroup,
  RadioGroupResult,
  RequiredIndicator,
} from '@myosh/odin-components';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Observable } from 'rxjs';
import {
  AnswerItemConfiguration,
  NumericAnswerItemConfiguration,
  QuestionConfigurationItem,
  QuestionnaireConfigProps,
  QuestionnaireItemValidationConfig,
  QuestionnaireItemValidationMapItem,
  QuestionnaireValueItem,
} from '../../../@types/questionnaire';
import { RecordResult } from '../../../@types/records';
import { forceAssert, getTextColor } from '../../../common/common-functions';
import AttachmentsField from '../attachments-field/attachments-field.component';
import QuestionnaireDetails from './questionnaire-details.component';
import QuestionnaireImage from './questionnaire-image.component';
import QuestionnaireLinkedRecords from './questionnaire-linked-records.component';
import QuestionnaireObservations from './questionnaire-observations.component';
import { AttachmentItem, DeletedAttachmentItem } from '../../../@types/attachments';

interface QuestionnaireQuestionProps extends DynamicFieldComponentProps<QuestionnaireValueItem> {
  question: QuestionConfigurationItem;
  configuration: Omit<QuestionnaireConfigProps, 'questions' | 'displayScores'>;
  recordId?: string;
  saveRecord?: () => Observable<RecordResult>;
  validationErrors?: QuestionnaireItemValidationMapItem;
  validationConfig?: QuestionnaireItemValidationConfig;
  submitCount: number;
}

const QuestionnaireQuestion = ({
  name,
  label,
  value,
  error,
  onChange,
  readOnly,
  question,
  configuration,
  id,
  recordId,
  registerPreSubmitHandler,
  registerPostSubmitHandler,
  saveRecord,
  validationErrors,
  validationConfig,
  submitCount,
  required,
}: QuestionnaireQuestionProps) => {
  const { t } = useTranslation();
  const { answers, allowAttachments, allowRecordLinks, allowObservations, recordLink, type } = configuration;
  const [loadedAttachments, setLoadedAttachments] = useState<AttachmentItem[]>([]);
  const [detailsOpen, setDetailsOpen] = useState<boolean>();

  const valueRef = useRef<QuestionnaireValueItem>();
  valueRef.current = value;

  useEffect(() => {
    if (submitCount > 0 && hasValidationErrors()) {
      setDetailsOpen(true);
    }
  }, [submitCount]);

  const attachmentsLoaded = useCallback((attachments: AttachmentItem[]) => {
    setLoadedAttachments(attachments);
  }, []);

  const toggleMoreDetails = useCallback((open: boolean) => {
    setDetailsOpen(open);
  }, []);

  const hasMoreDetailsValue = (): boolean => {
    const hasAttachmentsValue =
      allowAttachments &&
      ((valueRef.current?.files && valueRef.current.files?.length > 0) ||
        (valueRef.current?.images && valueRef.current.images?.length > 0) ||
        loadedAttachments.length > 0);

    const hasObservationsValue =
      allowObservations && valueRef.current?.observations && valueRef.current.observations?.length > 0;

    const hasLinkedRecordsValue =
      allowRecordLinks && valueRef.current?.linkedRecords && valueRef.current.linkedRecords.length > 0;

    return Boolean(hasAttachmentsValue || hasObservationsValue || hasLinkedRecordsValue);
  };

  const showDetails = (): boolean => {
    if (readOnly) {
      return Boolean(valueRef.current && hasMoreDetailsValue());
    } else {
      return Boolean(valueRef.current && (allowAttachments || allowRecordLinks || allowObservations));
    }
  };

  const hasMandatoryMoreDetails = (): boolean => {
    const mandatoryAttachments = allowAttachments && Boolean(validationConfig?.allowMedia);
    const mandatoryLinkedRecords = allowRecordLinks && Boolean(validationConfig?.allowLinkedRecords);
    const mandatoryObservations = allowObservations && Boolean(validationConfig?.allowObservations);
    return mandatoryAttachments || mandatoryLinkedRecords || mandatoryObservations;
  };

  const hasValidationErrors = (): boolean => {
    return Boolean(validationErrors?.attachments || validationErrors?.observations || validationErrors?.linkedRecords);
  };

  const handleAnswerChange = (
    answer: AnswerItemConfiguration | NumericAnswerItemConfiguration,
    value?: RadioGroupResult
  ) => {
    setDetailsOpen(undefined);
    type === 'TEXTUAL_QUESTIONNAIRE'
      ? onChange?.({
          ...valueRef.current,
          id: forceAssert<AnswerItemConfiguration>(answer).id,
          questionId: question.id,
        })
      : onChange?.({
          ...valueRef.current,
          questionId: question.id,
          numericAnswer: value?.value as number,
        });
  };

  const handleAttachmentChange = (change?: Array<File> | DeletedAttachmentItem) => {
    if (valueRef.current && onChange) {
      let attachments = [...(valueRef.current?.attachments || [])];
      if (change && Array.isArray(change)) {
        // File has been added/removeed from the file upload
        attachments = [...change];
      } else {
        // Attachment has been removed from the attachmend list (existing files/images)
        if (valueRef.current.files) {
          const _files = valueRef.current.files.filter((fileId) => fileId !== String(change?.id));
          valueRef.current.files = [..._files];
        }

        if (valueRef.current.images) {
          const _images = valueRef.current.images.filter((imageId) => imageId !== String(change?.id));
          valueRef.current.images = [..._images];
        }
      }
      // We need to call onChange when an attachment is added or removed, to ensure that any enclosing form is marked as dirty and validation is performed.
      // Otherwise, adding or removing a file to a questionniare field causes the form to be in an incosistent state.
      onChange({ ...valueRef.current, attachments });
    }
  };

  const handleObservationsChange = (observation?: string) => {
    if (valueRef.current && onChange) {
      onChange({
        ...valueRef.current,
        observations: observation,
      });
    }
  };

  const handleLinkedRecordsChange = (recordIds?: Array<string>) => {
    if (valueRef.current && onChange) {
      onChange({
        ...valueRef.current,
        linkedRecordIds: recordIds,
      });
    }
  };

  const isSelected = (item: AnswerItemConfiguration) => item.id === valueRef.current?.id;

  const buttonStyles = cx(
    'min-w-5 h-9 rounded px-2.5 text-sm focus:outline-none focus:ring-2 focus:ring-primary-1 focus:ring-offset-2',
    {
      'pointer-events-none opacity-70 focus:ring-transparent': readOnly,
    }
  );

  const numericOptions = (numericQuestionnaireItem: NumericAnswerItemConfiguration) => {
    const fieldOptions: Array<JsonDataItem> = [];
    for (
      let i = numericQuestionnaireItem.startInterval, length = numericQuestionnaireItem.endInterval;
      i <= length;
      i++
    ) {
      fieldOptions.push({ text: String(i), value: i });
    }
    return fieldOptions;
  };

  const renderItem = (item: AnswerItemConfiguration | NumericAnswerItemConfiguration, i: number) => {
    if (type === 'TEXTUAL_QUESTIONNAIRE') {
      const tqItem = item as AnswerItemConfiguration;
      const activeColorStyle = {
        backgroundColor: isSelected(tqItem) ? tqItem.color : 'var(--gray-4)',
        color: isSelected(tqItem) ? getTextColor(tqItem.color) : '',
      };

      return (
        <button
          key={`${name}_item_${i}`}
          style={activeColorStyle}
          className={buttonStyles}
          disabled={readOnly}
          onClick={() => handleAnswerChange(tqItem)}
          type="button"
        >
          {tqItem.caption}
          <input
            name={tqItem.caption}
            className="hidden"
            type="radio"
            checked={isSelected(tqItem)}
            value={tqItem.id}
            onChange={() => handleAnswerChange(tqItem)}
            disabled={readOnly}
          />
        </button>
      );
    } else if (type === 'NUMERIC_QUESTIONNAIRE') {
      const numericQuestionnaireItem = item as NumericAnswerItemConfiguration;
      const numericQuestionnaireOptions = numericOptions(numericQuestionnaireItem);

      const value = () => {
        return { text: String(valueRef.current?.numericAnswer), value: valueRef.current?.numericAnswer };
      };

      return (
        <RadioGroup
          key={`${name}_item_${i}`}
          name="numeric_questionnaire"
          id="numeric_questionnaire"
          textField="text"
          valueField="value"
          data={numericQuestionnaireOptions}
          orientation="HORIZONTAL"
          readOnly={readOnly}
          onChange={(value) => handleAnswerChange(numericQuestionnaireItem, value)}
          value={value()}
        />
      );
    }
  };

  const questionTitleStyle = cx(
    'mb-4 text-sm',
    { 'pl-4': configuration?.showFieldCaption },
    { 'font-bold': !configuration?.showFieldCaption }
  );

  const answerStyle = cx('mb-7 flex flex-wrap gap-4 ml-1', { 'pl-4': configuration.showFieldCaption });

  const moreDetailsOpen = detailsOpen !== undefined ? detailsOpen : hasMoreDetailsValue() || hasMandatoryMoreDetails();

  return (
    <>
      <div className={questionTitleStyle}>
        {label}
        {required === DynamicFormRequiredType.True && <RequiredIndicator readOnly={readOnly} required={required} />}
      </div>
      {question.image && <QuestionnaireImage fieldId={configuration.id} image={question.image} readOnly={readOnly} />}
      <div className={answerStyle}>{answers.map((answer, i) => renderItem(answer, i))}</div>
      {error && <ErrorLabel>{error}</ErrorLabel>}

      <QuestionnaireDetails
        visible={showDetails()}
        open={moreDetailsOpen}
        readOnly={readOnly}
        onToggle={toggleMoreDetails}
      >
        {allowObservations && (
          <QuestionnaireObservations
            readOnly={readOnly}
            value={valueRef.current?.observations}
            onChange={handleObservationsChange}
            error={validationErrors?.observations}
            required={isRequired(validationConfig?.allowObservations)}
          />
        )}
        {allowRecordLinks && recordLink && (
          <QuestionnaireLinkedRecords
            recordId={recordId ? Number(recordId) : undefined}
            fieldId={id as number}
            recordLink={recordLink}
            readOnly={readOnly}
            value={valueRef.current?.linkedRecords}
            onChange={handleLinkedRecordsChange}
            saveRecord={saveRecord}
            required={isRequired(validationConfig?.allowLinkedRecords)}
            error={validationErrors?.linkedRecords}
          />
        )}
        {allowAttachments && (
          <>
            <AttachmentsField
              id={id}
              label={t('attachments')}
              recordId={recordId}
              questionId={question.id}
              registerPreSubmitHandler={registerPreSubmitHandler}
              registerPostSubmitHandler={registerPostSubmitHandler}
              readOnly={readOnly}
              error={validationErrors?.attachments}
              thumbnails={true}
              onAttachmentsLoaded={attachmentsLoaded}
              onChange={handleAttachmentChange}
              required={isRequired(validationConfig?.allowMedia)}
            />
          </>
        )}
      </QuestionnaireDetails>
    </>
  );
};

export default QuestionnaireQuestion;

const isRequired = (required?: boolean) => {
  return required === true ? DynamicFormRequiredType.True : DynamicFormRequiredType.False;
};
