import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { IconButton, Input, OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';

// ActionIcon internal component
interface ActionIconProps {
  icon: string;
  onClick: () => void;
  iconStyles?: string;
  hidden?: boolean;
}

const ActionIcon = ({ icon, onClick, hidden = false, iconStyles }: ActionIconProps) => {
  const actionIconClassNames = cx(
    'group-hover:flex ',
    { 'text-primary-2 hover:text-primary-1': !iconStyles },
    {
      hidden: hidden,
    },
    iconStyles
  );

  return (
    <IconButton classNames="h-9 p-1" onClick={onClick}>
      <OdinIcon icon={icon} size={OdinIconSize.ExtraSmall} type={OdinIconType.Line} className={actionIconClassNames} />
    </IconButton>
  );
};

// ViewEditor component
export interface View {
  id: number;
  name: string;
  isGlobal: boolean;
  canEdit: boolean;
}

interface ViewNameEditorProps {
  view: View;
  isEven: boolean;
  onChange: (id: number, name: string) => void;
  onRemove: (id: number) => void;
}

const ViewNameEditor = ({ view, isEven, onChange, onRemove }: ViewNameEditorProps) => {
  const { id, name, isGlobal, canEdit } = view;

  const [viewName, setViewName] = useState<string>(name);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => setViewName(name), [name]);

  const enableEditMode = () => setEditMode(true);

  const disableEditMode = () => {
    setHasError(false);
    setEditMode(false);
    setViewName(name);
  };

  const handleViewNameChange = (value?: string) => {
    setHasError(false);
    setViewName(value || '');
  };

  const applyChanges = () => {
    if (viewName.trim().length > 0) {
      setEditMode(false);
      onChange(id, viewName);
    } else {
      setHasError(true);
    }
  };

  const removeItem = () => setShowDeleteConfirmation(true);

  const confirmRemoveItem = () => onRemove(id);
  const cancelRemoveItem = () => setShowDeleteConfirmation(false);

  const rowClassNames = cx('flex group w-full text-sm items-center h-9', {
    'bg-gray-5': isEven && !showDeleteConfirmation,
    'bg-mono-1': !isEven && !showDeleteConfirmation,
    'bg-error75 text-mono-1': showDeleteConfirmation,
  });
  const leadingIconClassNames = cx('text-primary-2 ml-1 mr-2', { invisible: !isGlobal });
  const nameClassNames = cx('flow-root w-full max-w-sm', {
    truncate: !editMode,
  });
  const inputStyles = cx('h-8 -ml-1 pl-1', { 'border-error': hasError });

  return (
    <div className={rowClassNames}>
      {showDeleteConfirmation ? (
        <>
          <div className="ml-7 inline-flex w-full max-w-sm">
            <span className="mr-1">{t('delete')}</span>
            <span style={{ maxWidth: '200px' }} className="truncate">{`"${viewName}"`}</span>
            <span className="ml-1">?</span>
          </div>
          <ActionIcon icon="Check" onClick={confirmRemoveItem} iconStyles="text-mono-1 hover:text-mono-1" />
          <ActionIcon icon="Close" onClick={cancelRemoveItem} iconStyles="text-mono-1 hover:text-mono-1" />
        </>
      ) : (
        <>
          <OdinIcon
            icon="Global"
            size={OdinIconSize.ExtraSmall}
            type={OdinIconType.Line}
            className={leadingIconClassNames}
          />
          <div title={viewName} className={nameClassNames}>
            {canEdit && editMode ? (
              <Input name="view-name" value={viewName} onChange={handleViewNameChange} inputStyles={inputStyles} />
            ) : (
              viewName
            )}
          </div>
          <div className="flex w-16 items-center justify-end">
            {canEdit && (
              <>
                {editMode ? (
                  <ActionIcon icon="Check" onClick={applyChanges} />
                ) : (
                  <ActionIcon icon="Pencil" onClick={enableEditMode} hidden />
                )}
                {editMode ? (
                  <ActionIcon icon="Close" onClick={disableEditMode} />
                ) : (
                  <ActionIcon icon="DeleteBin5" onClick={removeItem} hidden />
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ViewNameEditor;
