import { useEffect, useState } from 'react';
import {
  useCreateImportAttachmentsJobMutation,
  useGetImportAttachmentsJobDetailsQuery,
  useStartImportAttachmentsJobMutation,
} from '../../../redux/services/file';
import { showInfo, showSuccess } from '../../../services/notification.service';
import { useTranslation } from 'react-i18next';

const useImportAttachments = (onImportJobDone?: () => void) => {
  const [importJobId, setImportJobId] = useState<number>();

  const { t } = useTranslation();

  const [createImportJob] = useCreateImportAttachmentsJobMutation();
  const [startImportJob] = useStartImportAttachmentsJobMutation();

  const { data: importAttachmentsJobDetails } = useGetImportAttachmentsJobDetailsQuery(importJobId ?? 0, {
    skip: !importJobId,
    pollingInterval: 6000,
  });

  useEffect(() => {
    if (importAttachmentsJobDetails?.status === 'FINISHED') {
      setImportJobId(undefined);

      onImportJobDone?.();

      showSuccess(t('import-attachments-success'));
    }
  }, [importAttachmentsJobDetails]);

  const startImportAttachmentsJob = (file: File, prefix?: 'RECORD_ID' | 'RECORD_SOURCE_ID') => {
    showInfo(t('import-attachments-starting'));

    createImportJob()
      .unwrap()
      .then((response) => {
        fetch(response.presignedUrl, {
          method: 'PUT',
          body: file,
        }).then(() => {
          startImportJob({ jobId: response.id, prefix: prefix })
            .unwrap()
            .then(() => {
              setImportJobId(response.id);
            });
        });
      });
  };

  return {
    startImportAttachmentsJob,
  };
};

export default useImportAttachments;
