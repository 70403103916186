import {
  AddNewModal,
  Checkbox,
  CheckboxChangeEventType,
  ContinuousScrollerFilter,
  ContinuousScrollerSort,
  DataGridColumnSettings,
  DropDownResult,
  JsonDataWrapper,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  OdinDataUpdate,
  isDropDownNoSelection,
} from '@myosh/odin-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  defaultLastEditedFilterOption,
  defaultRecordFilterOption,
  transformFilterDataToUpdateView,
  transformSortedFieldsToUpdateView,
} from '../../common/dynamic-page-utils';
import useViewConfigs from '../../hooks/use-view-configs';
import { useAppSelector } from '../../redux/hooks';
import {
  useAddViewConfigMutation,
  useGetViewColumnsQuery,
  useUpdateViewConfigMutation,
} from '../../redux/services/view';
import { dynamicPageState } from '../../redux/slices/dynamic-page-slice';
import { ViewColumn, ViewConfigArchivedType, ViewConfigDateRange, ViewConfiguration } from '../../@types/views';
import { useGetCurrentSchemaInfoQuery } from '../../redux/services/api';
import { USER_TIME_ZONE } from '../../common/date-util';

interface SaveViewModalProps {
  getColumns: () => Array<DataGridColumnSettings>;
  moduleId: number;
  selectedForm: DropDownResult;
  visible: boolean;
  onCloseModal: () => void;
  getFilters: () => ContinuousScrollerFilter[];
  getSortedFields: () => ContinuousScrollerSort[];
  isAllForms: boolean;
}

export default function SaveViewModal({
  getColumns,
  moduleId,
  selectedForm,
  visible,
  onCloseModal,
  getFilters,
  getSortedFields,
  isAllForms,
}: SaveViewModalProps) {
  const [defaultViewName, setDefaultViewName] = useState('');
  const [isGlobalCheckboxChecked, setIsGlobalCheckboxChecked] = useState(false);
  const [columns, setColumns] = useState<Array<ViewColumn>>([]);
  const dialogTransitionDuration = 300;
  const formId = 'saveViewModal';
  const { t } = useTranslation();
  const {
    selectedFilters: { lastEdited = defaultLastEditedFilterOption, records = defaultRecordFilterOption, viewConfig },
  } = useAppSelector(dynamicPageState);

  const { data: schemaInfo } = useGetCurrentSchemaInfoQuery();

  const { data: viewConfigs, isLoading: isViewConfigsLoading } = useViewConfigs(
    selectedForm && !isDropDownNoSelection(selectedForm) ? (selectedForm?.value as number) : undefined,
    true,
    moduleId,
    isAllForms
  );

  const {
    data: viewColumns,
    isLoading: isViewColumnsLoading,
    isFetching: isViewColumnsFetching,
    refetch: refetchViewColumns,
  } = useGetViewColumnsQuery(
    { configId: viewConfig?.value as number },
    {
      skip: !viewConfig?.value,
    }
  );
  const [addViewConfig] = useAddViewConfigMutation();
  const [updateViewConfig] = useUpdateViewConfigMutation();

  useEffect(() => {
    if (visible && !isViewConfigsLoading) {
      const currentView = viewConfigs?.find((view) => view.id === viewConfig?.value);
      if (currentView && !currentView.defaultConfig) {
        setDefaultViewName(currentView.name);
        setIsGlobalCheckboxChecked(currentView.global);

        //Modal has a transition before it´s visible
        setTimeout(() => {
          const form = document.getElementById(formId);
          const formInput = form?.querySelector('input');
          formInput?.focus();
          formInput?.select();
        }, dialogTransitionDuration);
      } else {
        setDefaultViewName('');
      }
    }
  }, [visible, isViewConfigsLoading]);

  useEffect(() => {
    if (viewColumns && !isViewColumnsFetching && !isViewColumnsLoading) {
      setColumns(viewColumns);
    }
  }, [viewColumns, isViewColumnsFetching, isViewColumnsLoading]);

  const dataRetrieval: OdinDataRetrieval & OdinDataUpdate<JsonDataWrapper> = {
    getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
      if (viewConfigs && options) {
        const hasViewConfig = viewConfigs.find(
          (view) => view.name?.toLowerCase() === String(options.filter)?.toLowerCase()
        );
        if (hasViewConfig) {
          const errorMessage =
            String(options.filter).toLowerCase() === 'default'
              ? t('view-validation-modify')
              : t('view-validation-exists');

          //The AddNewModal shows the validation message only when there is some data
          subscriber.sendData({ data: [{ error: errorMessage }], requestId: options.requestId });
        } else {
          subscriber.sendData();
        }
      }
    },
    saveData: (data, returnResult, options) => {
      if (!Array.isArray(data) && options?.fieldType === 'CUSTOM' && options?.customFieldType === 'ADDNEWMODAL') {
        const viewName = data['value'] as string;
        const configuration = getViewConfiguration(viewName, schemaInfo?.['time-zone'] ?? USER_TIME_ZONE);
        if (viewName === defaultViewName) {
          updateViewConfig({ id: configuration.id ?? 0, ...configuration }).then(() => {
            //Old columns were read from cached data, so a refetch is made here to get the updated columns
            refetchViewColumns();
            returnResult.sendData({ data: [data], requestId: options.requestId });
          });
        } else {
          addViewConfig(configuration);
          returnResult.sendData({ data: [data], requestId: options.requestId });
        }
      }
    },
  };

  const getViewConfiguration = (name: string, schemaTimeZone: string) => {
    const currentView = viewConfigs?.find((view) => view.id === viewConfig?.value);

    const filteredColumns = getColumns()
      .filter((dataGridColumn) => (dataGridColumn.id as number) > 0 && dataGridColumn.visible)
      .filter((dataGridColumn) => columns.findIndex((viewColumn) => viewColumn.id === dataGridColumn.id) > -1)
      .map((dataGridColumn, index) => {
        // column config from the API
        const view = columns.find((viewColumn) => viewColumn.id === dataGridColumn.id) as Partial<ViewColumn>;

        const columnHasFilterSelected = getFilters().find(
          (filter) => filter.field === view.entityReference?.entityPropertyName
        );

        const columnHasSortedFilter = getSortedFields().find(
          (sortedfield) => sortedfield.field === view.entityReference?.entityPropertyName
        );

        return mapViewColumn(
          isAllForms,
          view,
          dataGridColumn,
          index,
          schemaTimeZone,
          columnHasFilterSelected,
          columnHasSortedFilter
        );
      });

    const configuration: Partial<ViewConfiguration> = {
      id: currentView?.id,
      moduleFormId: isAllForms ? undefined : (selectedForm?.value as number),
      moduleId: moduleId,
      allForms: isAllForms,
      defaultConfig: false,
      global: isGlobalCheckboxChecked,
      archivedType: archivedType[records.value as string],
      dateRange: lastEditedType[lastEdited.value as string],
      name: name,
      columnConfigurations: filteredColumns,
    };

    return configuration;
  };

  const onCheckboxChange = (value?: CheckboxChangeEventType) => {
    const isChecked = value ? value.checked : false;
    setIsGlobalCheckboxChecked(isChecked);
  };

  const footer = (
    <Checkbox name="global" label={t('global')} onChange={onCheckboxChange} initialChecked={isGlobalCheckboxChecked} />
  );

  return (
    <AddNewModal
      formId={formId}
      header={t('view')}
      textField="text"
      valueField="value"
      defaultValue={defaultViewName}
      placeholder={t('enter-name')}
      footer={footer}
      minDialogWidth={340}
      visible={visible}
      data={dataRetrieval}
      hidden={onCloseModal}
      buttonText={t('save')}
      buttonType="submit"
      transitionDuration={dialogTransitionDuration}
    />
  );
}

// utils
const archivedType: Record<string, ViewConfigArchivedType> = {
  false: 'NO',
  true: 'YES',
  both: 'ALL',
};

const lastEditedType: Record<string, ViewConfigDateRange> = {
  thisMonth: 'THIS_MONTH',
  lastThreeMonths: 'LAST_THREE_MONTHS',
  lastSixMonths: 'LAST_SIX_MONTHS',
  thisYear: 'THIS_YEAR',
  all: 'ALL',
};

const viewReset = {
  sortSequence: undefined,
  sortColumn: false,
  ascending: false,
  stringFilter: undefined,
  firstDate: undefined,
  secondDate: undefined,
  firstValue: undefined,
  secondValue: undefined,
  multiselectRangeFilter: undefined,
  explicitShowAll: false,
};

function mapViewColumn(
  isAllForms: boolean,
  view: Partial<ViewColumn>,
  dataGridColumn: DataGridColumnSettings,
  index: number,
  schemaTimeZone: string,
  columnHasFilterSelected?: ContinuousScrollerFilter,
  columnHasSortedFilter?: ContinuousScrollerSort
) {
  if (view.entityReference && (columnHasFilterSelected || columnHasSortedFilter)) {
    let allDataGridFiltersOnAView = {};
    if (columnHasFilterSelected) {
      const viewWithFilters = transformFilterDataToUpdateView(
        view.entityReference.filterType,
        columnHasFilterSelected,
        isAllForms,
        schemaTimeZone
      );
      allDataGridFiltersOnAView = { ...allDataGridFiltersOnAView, ...viewWithFilters };
    }
    if (columnHasSortedFilter) {
      const viewWithSortedFilter = columnHasSortedFilter
        ? transformSortedFieldsToUpdateView(columnHasSortedFilter)
        : undefined;
      allDataGridFiltersOnAView = { ...allDataGridFiltersOnAView, ...viewWithSortedFilter };
    }
    return {
      ...view,
      ...viewReset,
      ...allDataGridFiltersOnAView,
      includeArchived: dataGridColumn.includeArchived,
      position: index + 1,
    } as ViewColumn;
  }

  return {
    ...view,
    ...viewReset,
    includeArchived: dataGridColumn.includeArchived,
    position: index + 1,
  } as ViewColumn;
}
