import React, { useCallback, useMemo } from 'react';
import NewsEntry, { NewsEntryProps } from '../components/home-page/news-entry.component';
import { useGetNewsQuery } from '../redux/services/api';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import HomeMobilePage from './home/home-mobile.component';
import HomePageDesktop from './home/home-desktop.component';

export default function HomePage() {
  const { t } = useTranslation();

  const { data, isLoading } = useGetNewsQuery({ dismissed: 'false', markAsSeen: false, type: 'NEWS_ITEM' });
  const { data: alertsData, isLoading: areAlertsLoading } = useGetNewsQuery({
    dismissed: 'false',
    markAsSeen: true,
    type: 'ALERT',
  });

  const newsEntries = useMemo(() => {
    return data && !isEmpty(data) ? (
      data.map((newsDetails: NewsEntryProps) => <NewsEntry {...newsDetails} key={newsDetails.id} />)
    ) : (
      <div className="flex h-full grow items-center justify-center text-lg font-bold">{t('no-news')}</div>
    );
  }, [data]);

  const alertNews = useMemo(() => {
    return alertsData?.map((newsDetails: NewsEntryProps) => <NewsEntry {...newsDetails} key={newsDetails.id} />) || [];
  }, [alertsData]);

  const getNewsPanel = useCallback(
    (mobileLayout = false) => {
      return (
        <div className={cx('flex flex-col overflow-hidden', { 'w-1/2': !mobileLayout })}>
          {!mobileLayout && <h2 className="dark:text-white pb-2 text-3xl font-bold">{t('news')}</h2>}
          <div className="custom-scroll flex grow flex-col sm:gap-2 overflow-y-scroll">{newsEntries}</div>
        </div>
      );
    },
    [newsEntries]
  );

  return (
    <>
      <HomeMobilePage
        isLoading={isLoading || areAlertsLoading}
        data={data}
        newsEntries={newsEntries}
        alertNews={alertNews}
        getNewsPanel={getNewsPanel}
      />
      <HomePageDesktop
        isLoading={isLoading || areAlertsLoading}
        data={data}
        newsEntries={newsEntries}
        alertNews={alertNews}
        getNewsPanel={getNewsPanel}
      />
    </>
  );
}
