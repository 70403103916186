import React from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionConfigurationItem, QuestionnaireValue } from '../../../@types/questionnaire';

interface NumericQuestionnaireScoreProps {
  answers: QuestionnaireValue;
  questions: Array<QuestionConfigurationItem>;
}

const NumericQuestionnaireScore = ({ answers, questions }: NumericQuestionnaireScoreProps) => {
  const { t } = useTranslation();

  const questionsScore = () => {
    let score = 0;
    answers.map((answer) => {
      const weight = questions.find((question) => answer.questionId === question.id)?.weight;
      if (answer?.numericAnswer && weight) {
        return (score += answer?.numericAnswer * weight);
      }
    });
    return score;
  };

  const averageScore = () => (answers.length > 0 ? questionsScore() / answers.length : 0);

  return (
    <div className="text-sm">
      {`${t('score-total')}: ${questionsScore().toFixed(2)} - ${t('score-average')}: ${averageScore().toFixed(2)}`}
    </div>
  );
};

export default NumericQuestionnaireScore;
