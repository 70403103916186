import { DataGridCellRendererProps, OdinIcon, OdinIconSize, OdinIconType, Tooltip } from '@myosh/odin-components';
import React, { useState, useCallback } from 'react';
import { useLazyGetFileUrlQuery } from '../../redux/services/file';

interface AttachmentInformation {
  name: string;
  sourceId: string;
}

const AttachmentsDataCell = ({ rowData }: DataGridCellRendererProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getFileUrl] = useLazyGetFileUrlQuery();

  const handleAttachmentDownloadCallback = useCallback(
    (attachment: AttachmentInformation) => (event: React.MouseEvent) => {
      event.stopPropagation(); // We stop Propagation in order to prevend opening the record navigator on DataGrid row click

      if (!isLoading) {
        setIsLoading(true);
        getFileUrl(attachment.sourceId)
          .unwrap()
          .then((fileUrl) => {
            const hiddenDownloadLink = document.createElement('a');
            hiddenDownloadLink.href = fileUrl as string;
            hiddenDownloadLink.download = attachment.name;
            hiddenDownloadLink.click();
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    []
  );

  return (
    // NOTE: if modifying the padding 'pl-2', please remember to update all other cells
    <div className="flex h-full w-full items-center gap-2 pl-2">
      {(rowData && rowData['attachmentInfo'] ? (rowData['attachmentInfo'] as Array<AttachmentInformation>) : []).map(
        (attachment, index) => {
          return (
            <AttachRender
              key={`sourceId-${attachment.sourceId}-index-${index}`}
              attachment={attachment}
              onDownload={handleAttachmentDownloadCallback(attachment)}
            />
          );
        }
      )}
    </div>
  );
};

export default AttachmentsDataCell;

const AttachRender = ({
  attachment,
  onDownload,
}: {
  attachment: AttachmentInformation;
  onDownload: (event: React.MouseEvent) => void;
}) => {
  return (
    <Tooltip description={String(attachment?.name)} tooltipClassName="bg-mono-1" tooltipZIndexCheck={true}>
      <div className="flex items-center gap-1 p-1 text-primary-2 hover:underline" onClick={onDownload}>
        <OdinIcon type={OdinIconType.Editor} icon="Attachment2" size={OdinIconSize.ExtraSmall} />
        <span className="max-w-[100px] truncate">{attachment.name}</span>
      </div>
    </Tooltip>
  );
};
