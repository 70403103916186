import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CheckboxGroupRef,
  JsonData,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  OdinDataSender,
  RadioGroupRef,
} from '@myosh/odin-components';
import { Control } from 'react-hook-form';
import { forceAssert } from '../../../common/common-functions';
import { useLazyFieldOptionsQuery } from '../../../redux/services/field';
import { FieldOptionsApiProps } from '../../../@types/options';
import { LookupType } from '../../../@types/forms';
import useLookupTypeField from '../../../hooks/use-lookup-type-field';

/**
 * A hook that handles all aspects for fetching the options fields data.
 *
 * @param formId the form id where the field is used
 * @param control the hook form 'control' object
 * @param lookupType the defined lookup type, currently only `WITH_FIELD_KEY` and `WITH_HIERARCHY_FIELD` are supported
 * @param lookupKeyId the 'id' of the form field from which the value should be read.
 * @param lookupHierarchyId the 'id' of the hierarchy from which the value should be read.
 */
const useOptionFieldOptions = (
  formId: number,
  control?: Control,
  lookupType?: LookupType,
  lookupKeyId?: number,
  lookupHierarchyId?: number
) => {
  const [hierarchyFieldId, setHierarchyFieldId] = useState<number>();
  const [keyValue, setKeyValue] = useState<string>();
  const [options, setOptions] = useState<OdinDataRetrievalOptions>();
  const subscriberRef = useRef<OdinDataSender<JsonDataWrapper>>();
  const [getFieldOptions] = useLazyFieldOptionsQuery();
  const scrollerRef = useRef<CheckboxGroupRef | RadioGroupRef>(null);

  const {
    shouldUseLookup,
    hierarchyFieldId: resolvedHierarchyFieldId,
    keyValue: resolvedKeyValue,
  } = useLookupTypeField(control, lookupType, lookupKeyId, lookupHierarchyId);

  useEffect(() => {
    if (shouldUseLookup && hierarchyFieldId !== resolvedHierarchyFieldId) {
      scrollerRef.current?.clearOptions();
      setHierarchyFieldId(resolvedHierarchyFieldId);
    }
  }, [shouldUseLookup, resolvedHierarchyFieldId]);

  useEffect(() => {
    if (shouldUseLookup && keyValue !== resolvedKeyValue) {
      scrollerRef.current?.clearOptions();
      setKeyValue(resolvedKeyValue);
    }
  }, [shouldUseLookup, resolvedKeyValue]);

  useEffect(() => {
    if (options?.fieldId) {
      const _options = {
        queryId: Number(options.fieldId),
        formId,
        limit: 100, // ensure all data loads with the initial request
      };

      if (shouldUseLookup) {
        fetchAndSetData({
          ..._options,
          keyValue,
          hierarchyFieldId,
        });
      } else {
        fetchAndSetData(_options);
      }
    }
  }, [options, shouldUseLookup, hierarchyFieldId, keyValue]);

  const fetchAndSetData = useCallback((fieldOptions: FieldOptionsApiProps) => {
    getFieldOptions(fieldOptions, true)
      .unwrap()
      .then((result) => {
        const data = result.items ?? [];
        subscriberRef.current?.sendData({ data: forceAssert<JsonData>(data), requestId: options?.requestId });
      })
      .catch(() => subscriberRef.current?.sendData());
  }, []);

  const getOptionsData = useMemo(() => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if (options?.page === 1 && options.fieldId) {
          subscriberRef.current = subscriber;
          setOptions(options);
        } else {
          subscriber.sendData();
        }
      },
    };
  }, []);

  return {
    scrollerRef,
    getOptionsData,
  };
};

export default useOptionFieldOptions;
