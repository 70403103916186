import { useEffect, useState } from 'react';
import { useUser } from '@myosh/myosh-login';

const useAddonQuery = (recordsIds?: string) => {
  const [query, setQuery] = useState<string>();
  const {
    state: { user },
  } = useUser();

  useEffect(() => {
    const currentSchema = user?.currentSchema;
    const userName = user?.prefered_username;
    let query = '?';
    if (currentSchema) {
      query = query + `schemaName=${currentSchema}`;
    }
    if (userName) {
      query = query + `&userName=${userName}`;
    }
    if (recordsIds) {
      query = query + `&records=${recordsIds}`;
    }

    setQuery(query.length > 1 ? query : '');
  }, [user, recordsIds]);

  return query;
};

export default useAddonQuery;
