import React from 'react';
import { Button } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';

interface ConflictsFooterProps {
  resolved: boolean;
  onSave: () => void;
}

const ConflictsFooter = ({ resolved, onSave }: ConflictsFooterProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full justify-end">
      <Button type="primary" variant="alternative" disabled={!resolved} onClick={onSave}>
        {t('save')}
      </Button>
    </div>
  );
};

export default ConflictsFooter;
