import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  DynamicFormField,
  RowTemplateType,
  DataGrid,
  FormLoader,
  JsonData,
  DataGridRef,
  OdinDataSender,
  JsonDataWrapper,
  OdinDataRetrieval,
  DynamicFormFieldType,
  OdinDataRetrievalOptions,
  JsonDataItem,
} from '@myosh/odin-components';
import { ExtendedDynamicFormWorkflowStep, forceAssert } from '../../../common/common-functions';
import { SummaryRecordLinkResult } from '../../../@types/summary-record-link-field';
import { extractRecordLinkColumns } from './summary-record-link.functions';
import SummaryFieldTitle from './summary-record-link-field-title.component';
import useActiveRecordRef from '../../../hooks/use-active-record-ref';
import {
  LinkedRecordRowProps,
  OdinDataGridRowTemplate,
} from '../../dynamic-page/odin-data-grid-row-template.component';
import { useGetWorkflowStepsQuery, useModulesQuery } from '../../../redux/services/api';
import useFormPermissions from '../../../hooks/use-form-permissions';
import { extractLinkedRecordsPermissions } from '../../../common/form-permissions-util';
import { defaultLinkedRecordGridSetting } from '../../dynamic-page/default-grid-settings';
import { useGetSummaryRecordsLinkedQuery } from '../../../redux/services/record';

interface SummaryRecordLinkFieldProps {
  formFields: Array<DynamicFormField>;
  summaryRecordLink: SummaryRecordLinkResult;
  fieldsToInclude: Array<number>;
  formId: number;
  readOnly?: boolean;
  recordId: number;
  id: number;
}

const mapLinkedRecordIds = (linkedRecordIds?: Array<string | number>) => {
  return linkedRecordIds?.map((linkedRecordId) => {
    return Number(linkedRecordId);
  });
};

function SummaryRecordLinkField({
  formFields,
  summaryRecordLink,
  fieldsToInclude,
  formId,
  id,
  recordId,
}: SummaryRecordLinkFieldProps) {
  const { data: modules, isLoading: areModulesLoading } = useModulesQuery({});
  const { activeRecordReference } = useActiveRecordRef();

  const [gridOptions, setGridOptions] = useState<OdinDataRetrievalOptions>();

  const recordIds = useMemo(() => {
    return summaryRecordLink.items?.map((item) => item.id.toString()) ?? [];
  }, [summaryRecordLink.items]);

  const { data: summaryLinkedRecords, isFetching } = useGetSummaryRecordsLinkedQuery(
    { recordId, fieldId: id, linkedRecordIds: mapLinkedRecordIds(recordIds), sorts: gridOptions?.sortedFields },
    {
      skip: recordId === undefined || id === undefined || isNaN(id) || recordId === 0 || recordIds?.length === 0,
    }
  );

  const gridSubscriber = useRef<OdinDataSender<JsonDataWrapper>>();
  const gridRef = useRef<DataGridRef>();

  const { formPermissions, isLoading: isFormPermissionsLoading } = useFormPermissions(formId);
  const { data: workflowSteps } = useGetWorkflowStepsQuery(formId!, {
    skip: formId === undefined,
  });

  const summaryRecordPermissions = useMemo(() => {
    return extractLinkedRecordsPermissions(formPermissions);
  }, [formPermissions]);

  const gridColumns = useMemo(() => {
    return extractRecordLinkColumns(formFields, summaryRecordLink, fieldsToInclude);
  }, [formFields, summaryRecordLink, fieldsToInclude]);

  const rowTemplate: RowTemplateType = OdinDataGridRowTemplate({
    modules: modules,
    activeRecordReference: activeRecordReference.current,
    recordFields: LinkedRecordRowProps,
    isClickable: summaryRecordPermissions?.read,
  });
  const rowIndicator = useMemo(() => {
    return {
      indicator: (data?: JsonDataItem) => {
        const backgroundColor = workflowSteps?.find(
          (step: ExtendedDynamicFormWorkflowStep) => step.label === data?.status
        )?.backgroundColor;
        return <div className="h-4 w-4 rounded" style={{ backgroundColor: backgroundColor }} />;
      },
    };
  }, [workflowSteps]);

  const gridData = useMemo<OdinDataRetrieval>(() => {
    return {
      getSubscriber: (subscriber: OdinDataSender<JsonDataWrapper>, fieldType?: DynamicFormFieldType) => {
        if (fieldType !== 'COMBOBOX') {
          gridSubscriber.current = subscriber;
        }
      },
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        //This API endpoint is not paginated so only page 1 is fetched
        if (options?.fieldType !== 'COMBOBOX' && options?.page === 1) {
          setGridOptions(options);
        } else {
          subscriber.sendData();
        }
      },
    };
  }, []);

  useEffect(() => {
    if (summaryLinkedRecords && !isFetching) {
      if (summaryLinkedRecords.length > 0) {
        gridSubscriber.current?.sendData({
          data: forceAssert<JsonData>(summaryLinkedRecords[0].items),
          requestId: gridOptions?.requestId,
        });
      } else {
        gridSubscriber.current?.sendData();
      }
    }
  }, [summaryLinkedRecords, gridOptions]);

  const gridSettings = {
    ...defaultLinkedRecordGridSetting,
    rowIsClickable: summaryRecordPermissions?.read || false,
    allowCellScrollBar: true,
    autoSizeColumns: true,
    columns: gridColumns,
  };

  const onDataGridRefCreated = (dataGridRef: DataGridRef) => {
    gridRef.current = dataGridRef;
  };

  const canShowGrid =
    summaryRecordPermissions?.create ||
    summaryRecordPermissions?.read ||
    (!summaryRecordPermissions?.create && !summaryRecordPermissions?.read);

  return (
    <>
      {!areModulesLoading && gridColumns && gridColumns.length > 0 && summaryRecordLink?.items && canShowGrid && (
        <div key={summaryRecordLink.fieldId} className="mt-3">
          <SummaryFieldTitle moduleName={summaryRecordLink.moduleName} formName={summaryRecordLink.formName} />
          <div className="my-2 flex max-h-[40rem]">
            <DataGrid
              ref={(gridRef) => gridRef && onDataGridRefCreated(gridRef)}
              data={gridData}
              gridSettings={gridSettings}
              rowTemplate={rowTemplate}
              showSettings={false}
              rowIndicator={rowIndicator}
            />
          </div>
        </div>
      )}
      {(areModulesLoading || isFormPermissionsLoading) && (
        <FormLoader key={summaryRecordLink.fieldId} width="xl" height="lg" />
      )}
    </>
  );
}

export default React.memo(SummaryRecordLinkField);
