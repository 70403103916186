import { Key, useEffect, useRef, useState } from 'react';
import {
  JsonData,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  OdinDataSender,
  TwinColumnSelectRef,
} from '@myosh/odin-components';
import { LookupType } from '../../../@types/forms';
import { forceAssert } from '../../../common/common-functions';
import useLookupTypeField from '../../../hooks/use-lookup-type-field';
import { FieldOptionsApiProps, LookupTypeFieldApiProps } from '../../../@types/options';
import { Control } from 'react-hook-form';
import useFetchPersonFieldOptions from '../person/use-fetch-person-field-options';
import { useLazyFieldOptionsQuery } from '../../../redux/services/field';

/**
 * A hook that handles all aspects for fetching the twin columns fields data.
 *
 * @param textFieldKey the fields 'text' key propery (i.e. 'text')
 * @param id the 'id' of the field in the form
 * @param lookupType the defined lookup type, currently only `WITH_FIELD_KEY` and `WITH_HIERARCHY_FIELD` are supported
 * @param lookupKeyId the 'id' of the form field from which the value should be read.
 * @param lookupHierarchyId the 'id' of the hierarchy from which the value should be read.
 *
 */
const useSupportTwinColumnSelectFunctionalities = (
  textFieldKey: string,
  id?: Key,
  control?: Control,
  lookupType?: LookupType,
  lookupKeyId?: number,
  lookupHierarchyId?: number,
  formId?: number
) => {
  const [options, setOptions] = useState<OdinDataRetrievalOptions>();
  const subscriberRef = useRef<OdinDataSender<JsonDataWrapper>>();
  const twinColumnRef = useRef<TwinColumnSelectRef>(null);
  const dataRequestedRef = useRef<boolean>();

  const [hierarchyFieldId, setHierarchyFieldId] = useState<number>();
  const [keyValues, setKeyValues] = useState<string>();

  const fetchPersonFieldOptions = useFetchPersonFieldOptions(textFieldKey);
  const [getFieldOptions] = useLazyFieldOptionsQuery();

  const {
    shouldUseLookup,
    hierarchyFieldId: resolvedHierarchyFieldId,
    keyValue: resolvedKeyValue,
  } = useLookupTypeField(control, lookupType, lookupKeyId, lookupHierarchyId);

  useEffect(() => {
    if (shouldUseLookup && hierarchyFieldId !== resolvedHierarchyFieldId) {
      twinColumnRef.current?.clearOptionValues();
      twinColumnRef.current?.clearCache();
      setHierarchyFieldId(resolvedHierarchyFieldId);
    }
  }, [shouldUseLookup, resolvedHierarchyFieldId]);

  useEffect(() => {
    if (shouldUseLookup && keyValues !== resolvedKeyValue) {
      twinColumnRef.current?.clearOptionValues();
      twinColumnRef.current?.clearCache();
      setKeyValues(resolvedKeyValue);
    }
  }, [shouldUseLookup, resolvedKeyValue]);

  useEffect(() => {
    if (id && options && options.fieldSubType === 'TWINCOLUMNSELECTPERSONFIELD') {
      if (shouldUseLookup) {
        if (hierarchyFieldId || keyValues) {
          fetchAndSetPersonFieldData(id, { ...options, hierarchyFieldId, keyValue: keyValues });
        } else {
          subscriberRef.current?.sendData();
          dataRequestedRef.current = false;
        }
      } else {
        fetchAndSetPersonFieldData(id, options);
      }
    }
  }, [id, options, shouldUseLookup, keyValues, resolvedHierarchyFieldId]);

  useEffect(() => {
    if (options?.fieldId && options.fieldSubType === 'TWINCOLUMNSELECT') {
      if (options.fieldFilters && options.fieldFilters.text) {
        options.fieldFilters['caption'] = options.fieldFilters.text;
        delete options.fieldFilters.text;
      }
      const _options = {
        queryId: Number(options.fieldId),
        formId: formId ?? 0,
        limit: options.getAllData ? -1 : options.pageSize || 50,
        dependsOn: options.fieldFilters?.parentField?.value as number,
        filters: options.fieldFilters,
        next: options.customProperties?.next as string,
      };
      if (shouldUseLookup) {
        fetchAndSetOptionsData({
          ..._options,
          keyValue: keyValues,
          hierarchyFieldId: resolvedHierarchyFieldId,
        });
      } else {
        fetchAndSetOptionsData(_options);
      }
    }
  }, [options, shouldUseLookup, resolvedHierarchyFieldId, keyValues, formId]);

  const fetchAndSetPersonFieldData = (id: Key, options: OdinDataRetrievalOptions & LookupTypeFieldApiProps) => {
    if (options.fieldSubType === 'TWINCOLUMNSELECTPERSONFIELD') {
      fetchPersonFieldOptions(id, options)
        .then((data) => {
          if (data) {
            subscriberRef.current?.sendData({ data: forceAssert<JsonData>(data), requestId: options.requestId });
          } else {
            subscriberRef.current?.sendData();
          }
        })
        .catch(() => subscriberRef.current?.sendData())
        .finally(() => (dataRequestedRef.current = false));
    }
  };

  const fetchAndSetOptionsData = (fieldOptions: FieldOptionsApiProps) => {
    if (
      options?.fieldSubType === 'TWINCOLUMNSELECT' &&
      (options.page === 1 || options?.customProperties?.next || options?.getAllData)
    ) {
      getFieldOptions(fieldOptions, true)
        .unwrap()
        .then((result) => {
          const data = result.items ?? [];
          const formattedData = data.map((item) => {
            return {
              value: item.id,
              text: item.caption,
            };
          });
          subscriberRef.current?.sendData({
            data: forceAssert<JsonData>(formattedData),
            requestId: options?.requestId,
            customProperties: { next: result.next },
          });
        })
        .catch(() => subscriberRef.current?.sendData());
    } else {
      subscriberRef.current?.sendData();
    }
  };

  return {
    setOptions,
    subscriberRef,
    dataRequestedRef,
    twinColumnRef,
  };
};

export default useSupportTwinColumnSelectFunctionalities;
