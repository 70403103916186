import React from 'react';
import { DynamicFormField } from '@myosh/odin-components';

interface FormGroupNavigationProps {
  formGroups: Array<DynamicFormField>;
  onGroupItemClicked: (id: string, fieldType: string) => void;
}

export const FormGroupNavigation = ({ formGroups, onGroupItemClicked }: FormGroupNavigationProps) => {
  return (
    <div className="text-mono-2 custom-scroll sticky top-0 mr-2 h-full w-48 flex-shrink-0 self-start overflow-y-auto bg-gray-4 hidden sm:block">
      {formGroups.map((group) => (
        <React.Fragment key={group.id}>
          {!group.hidden && (
            <div
              key={`group_${group.fieldName}`}
              className="cursor-pointer py-2 pl-2 pr-4 hover:bg-primary-3 hover:text-mono-1"
              onClick={() => onGroupItemClicked(group.id.toString(), group.fieldType)}
            >
              {group.caption}
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
