import React from 'react';
import { FormLoader } from '@myosh/odin-components';

export const FormLoading = () => {
  return (
    <div className="absolute z-[1000] h-full w-full bg-gray-5">
      <FormLoader width="xl" height="lg" />
      <FormLoader width="xl" height="lg" />
      <FormLoader width="xl" height="lg" />
      <FormLoader width="xl" height="lg" />
    </div>
  );
};
