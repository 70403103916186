import React, { HTMLAttributeAnchorTarget, useCallback, useEffect, useState } from 'react';
import { useGetHomePageSummaryQuery } from '../../redux/services/api';
import { QuickLink } from '../../@types/home-page-data';
import { ElementNavigator, OdinIcon, OdinIconSize, OdinIconType, Tooltip } from '@myosh/odin-components';
import { isTextTruncated } from '../../common/common-functions';

const QuickLinkComponent = ({ linkData, target }: { linkData: QuickLink; target: HTMLAttributeAnchorTarget }) => {
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

  useEffect(() => {
    setTooltipVisible(isTextTruncated(linkId));
  }, []);

  const linkId = `quicklink-${linkData.id}`;
  const linkCaption = linkData.caption.translations[0].value;

  return (
    <Tooltip
      key={linkData.id}
      description={linkCaption}
      tooltipClassName="bg-mono-1"
      debounceTime={200}
      wrapperClassName="flex justify-center m-1"
      disabled={!tooltipVisible}
    >
      <a
        href={linkData.url}
        target={target}
        rel="noopener noreferrer"
        className="hover:bg-gray-5 text-gray-1 rounded border transition-colors duration-200 border-gray-3  focus:ring-gray-3 py-1  px-5 focus:ring-offset-2 focus:ring-2"
      >
        <div className="flex gap-2 items-center">
          <OdinIcon size={OdinIconSize.Medium} type={OdinIconType.Line} icon="ExternalLink" className="w-4" />
          <span
            id={linkId}
            className="truncate block max-w-[250px] overflow-hidden overflow-ellipsis whitespace-nowrap"
          >
            {linkCaption}
          </span>
        </div>
      </a>
    </Tooltip>
  );
};

const QuickLinks = () => {
  const { data, isFetching } = useGetHomePageSummaryQuery({});
  const [quickLinks, setQuickLinks] = useState<Array<QuickLink>>();

  const getTargetUrl = useCallback((newWindow: boolean) => (newWindow ? '_blank' : '_self'), []);

  useEffect(() => {
    if (!isFetching && data) {
      setQuickLinks(data.quickLinks);
    }
  }, [data]);

  return quickLinks && quickLinks.length > 0 ? (
    <div className="h-min py-2">
      <ElementNavigator>
        {quickLinks?.map((link) => {
          return <QuickLinkComponent key={link.id} linkData={link} target={getTargetUrl(link.openInNewWindow)} />;
        })}
      </ElementNavigator>
    </div>
  ) : undefined;
};

export default QuickLinks;
