import i18next from '../../../i18n';
import { HierarchyType } from '../../../@types/hierarchy-fields';
import { UserDataGridColumnSettings } from '../../../@types/users';
import { booleanSearchValues } from '../admin-utils';

export const getUsersColumnConfig = (
  hierarchyTypes?: Array<HierarchyType>
): Record<string, UserDataGridColumnSettings> => {
  const getEntityId = (key: string) => {
    let id = undefined;
    if (hierarchyTypes) {
      id = hierarchyTypes.find((hierarhy) => hierarhy.caption === key)?.id;
    }
    return id ? { entityId: id } : {};
  };

  let userColumns: Record<string, UserDataGridColumnSettings> = {
    id: { visible: false, isIdField: true, type: 1, title: i18next.t('id'), searchType: 'Integer' },
    lastName: { searchType: 'TextSearch', title: i18next.t('last-name') },
    firstName: { searchType: 'TextSearch', title: i18next.t('first-name') },
    userId: { searchType: 'TextSearch', title: i18next.t('username') },
    groups: {
      type: 7,
      searchType: 'MultiSelect',
      dropDownText: 'text',
      dropDownValue: 'value',
      title: i18next.t('groups'),
      customDataProperties: { entityType: 'ADMIN_FIELD' },
      showBlankAction: true,
    },
    manager: {
      type: 4,
      searchType: 'MultiSelect',
      dropDownText: 'text',
      dropDownValue: 'value',
      title: i18next.t('manager'),
      customDataProperties: { entityType: 'ADMIN_FIELD' },
      showBlankAction: true,
    },
    archived: {
      type: 5,
      searchType: 'MultiSelect',
      dropDownText: 'text',
      dropDownValue: 'value',
      searchValue: [booleanSearchValues[1]],
      searchComparison: 1,
      title: i18next.t('archived'),
    },
    occupation: {
      type: 4,
      searchType: 'MultiSelect',
      dropDownText: 'text',
      dropDownValue: 'value',
      title: i18next.t('occupation'),
      customDataProperties: { entityType: 'ADMIN_FIELD' },
      showBlankAction: true,
    },
    secondaryOccupations: {
      type: 7,
      searchType: 'MultiSelect',
      dropDownText: 'text',
      dropDownValue: 'value',
      title: i18next.t('secondary-occupation'),
      customDataProperties: { entityType: 'ADMIN_FIELD' },
      showBlankAction: true,
    },
    loginRequired: {
      type: 5,
      searchType: 'SingleSelect',
      dropDownText: 'text',
      dropDownValue: 'value',
      title: i18next.t('login-required'),
    },
    affiliation: {
      valuePath: 'affiliation.value',
      searchType: 'MultiSelect',
      dropDownText: 'text',
      dropDownValue: 'value',
      title: i18next.t('affiliation'),
      customDataProperties: { entityType: 'ADMIN_FIELD', entityPropertyName: 'affiliation' },
      showBlankAction: true,
    },
    externalUser: {
      type: 5,
      searchType: 'SingleSelect',
      dropDownText: 'text',
      dropDownValue: 'value',
      title: i18next.t('external-user'),
    },
    activeExternalUser: {
      type: 5,
      searchType: 'SingleSelect',
      dropDownText: 'text',
      dropDownValue: 'value',
      title: i18next.t('registration-status'),
    },
    occasionalExternalUser: {
      type: 5,
      visible: false,
      searchType: 'SingleSelect',
      dropDownText: 'text',
      dropDownValue: 'value',
      title: i18next.t('occasional-external-user'),
    },
    employeeId: {
      visible: false,
      searchType: 'TextSearch',
      title: i18next.t('employee-id'),
    },

    phone: { visible: false, searchType: 'TextSearch', title: i18next.t('phone') },
  };
  if (hierarchyTypes) {
    for (let i = 0; i < hierarchyTypes.length; i++) {
      const caption = hierarchyTypes[i].caption as string;
      userColumns = {
        ...userColumns,
        [caption]: {
          visible: false,
          searchType: 'MultiSelect',
          dropDownText: 'caption',
          dropDownValue: 'id',
          title: caption,
          showBlankAction: true,
          customDataProperties: {
            entityType: 'HIERARCHY_TYPE',
            ...getEntityId(caption),
          },
        },
      };
    }
  }
  return userColumns;
};
