import React from 'react';
import { LoginIdp } from '@myosh/myosh-login';
import { useNavigate } from 'react-router-dom';
import Meta from '../features/meta/meta';
import { useTranslation } from 'react-i18next';
import { LoginResult } from '@myosh/myosh-login/dist/types/components/login.component';
import ReactGA from 'react-ga4';

export default function LoginPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const sendSchemaLoginEvent = (schemaName: string) => {
    ReactGA.event({
      category: 'User',
      action: 'schema_login',
      label: schemaName,
    });
  };

  const onLoginSuccess = (result: LoginResult) => {
    sendSchemaLoginEvent(result.selectedSchema);
    const redirectPath = localStorage.getItem('postLogInRedirect');
    if (redirectPath) {
      localStorage.removeItem('postLogInRedirect');
      return navigate(redirectPath);
    } else {
      return navigate('/');
    }
  };

  return (
    <>
      <Meta title={t('myosh-login')} />
      <LoginIdp onLoginSuccess={onLoginSuccess} />
    </>
  );
}
