import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface DataObject {
  typeId: number;
  id: number;
}

interface DataState {
  selectedHierarchies: Record<number, number[]>;
  matchingOption: string;
}

const initialState: DataState = {
  selectedHierarchies: {},
  matchingOption: '',
};

const globalHierarchyFiltersSlice = createSlice({
  name: 'globalHierarchyFilters',
  initialState,
  reducers: {
    setSelectedHierarchies: (state, action: PayloadAction<Record<number, number[]>>) => {
      state.selectedHierarchies = action.payload;
    },
    setMatchingOption: (state, action: PayloadAction<string>) => {
      state.matchingOption = action.payload;
    },
    resetGlobalHierarchyFilters: (state) => {
      state.selectedHierarchies = {};
      state.matchingOption = '';
    },
  },
});

export const { setSelectedHierarchies, setMatchingOption, resetGlobalHierarchyFilters } =
  globalHierarchyFiltersSlice.actions;
export const selectedGlobalHierarchies = (state: RootState) => state.globalHierarchyFilters.selectedHierarchies;
export const selectedMatchingOption = (state: RootState) => state.globalHierarchyFilters.matchingOption;

export default globalHierarchyFiltersSlice.reducer;
