import { cloneDeep } from 'lodash';
import { OccupationItem } from '../@types/occupations';
import { JsonDataWrapper, OdinDataRetrievalOptions, OdinDataSender } from '@myosh/odin-components';
import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import { isInstanceOfValueTextType } from './common-functions';
import { getGroups } from './common-administration-utils';

export type ExtendedOccupationItem = Omit<OccupationItem, keyof OccupationItem> & { [key: string]: unknown };

export function createOccupationData(occupationResponse: OccupationItem) {
  const pureOccupation: OccupationItem = {
    ...occupationResponse,
    roles: occupationResponse?.roles?.filter((item) => !item?.archived) || [],
  };

  const modifiedOccupationResponse: ExtendedOccupationItem = cloneDeep(pureOccupation);
  const keys = Object.keys(modifiedOccupationResponse);
  for (let i = 0; i < keys.length; i++) {
    const dataKey = keys[i] as keyof OccupationItem;
    const dataValue = pureOccupation[dataKey];
    if (typeof dataValue === 'object') {
      if (Array.isArray(dataValue)) {
        if (dataKey === 'hierarchyFields') {
          const hierarchyFields: Record<string, unknown> = {};
          for (let j = 0; j < dataValue.length; j++) {
            const hierarchy = dataValue[j];
            const hierarchyItemKey = hierarchy['hierarchyTypeCaption'];
            const hierarchyItemValue = hierarchy['caption'];
            hierarchyFields[hierarchyItemKey] = hierarchyItemValue;
          }
          modifiedOccupationResponse['hierarchies'] = hierarchyFields;
          delete modifiedOccupationResponse['hierarchyFields'];
        }
        if (dataKey === 'roles') {
          const rolesToDrowpdownData = dataValue.map((roleItem) => ({
            text: roleItem.caption,
            value: roleItem.id,
          }));
          modifiedOccupationResponse[dataKey] = rolesToDrowpdownData;
        }
      }
    }
  }

  return modifiedOccupationResponse;
}

export function updateOccupationData(patchData: Partial<OccupationItem>) {
  const dataKeys = Object.keys(patchData);
  for (let i = 0, length = dataKeys.length; i < length; i++) {
    const dataKey = dataKeys[i];

    const dataValue = patchData[dataKey];
    if (dataValue) {
      setOccupationPropertyValue(patchData, dataKey as string);
    }
  }
}

function setOccupationPropertyValue<T extends keyof OccupationItem>(
  patchData: Partial<OccupationItem>,
  key: keyof OccupationItem
) {
  const dataValue = patchData[key];
  if (typeof dataValue === 'object') {
    if (Array.isArray(dataValue)) {
      const newDataValue = [];
      for (let j = 0; j < dataValue.length; j++) {
        const newDataValueItem: Record<string, unknown> = {};
        if (isInstanceOfValueTextType(dataValue[j])) {
          newDataValueItem.id = dataValue[j].value;
          newDataValue.push(newDataValueItem);
        }
      }
      patchData[key] = newDataValue as OccupationItem[T];
    }
  } else {
    patchData[key] = dataValue as OccupationItem[T];
  }
}

export const getOccupationFieldOptionsData = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  isExternal?: boolean
) => {
  switch (options?.fieldId?.toString()) {
    case 'roles':
      await getGroups(subscriber, options, dispatch, isExternal);
      break;
    default:
      subscriber.sendData();
  }
};
