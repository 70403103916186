import React, { useMemo } from 'react';
import { DataGridDataCell } from '@myosh/odin-components';
import DataCellLoading from './data-cell-loading';
import { LinkedRecordResult } from '../../@types/linked-records-field';
import { CombinedFieldType } from '../../@types/forms';

interface RecordlinkBaseDataCellProps {
  records: LinkedRecordResult[];
  valueKeys: string[];
  loading: boolean;
  width?: number;
  type?: CombinedFieldType;
}

const RecordlinkBaseDataCell = ({ records, valueKeys, loading, width, type }: RecordlinkBaseDataCellProps) => {
  const displayValue = useMemo(() => {
    const total = records.length;
    if (total > 0) {
      const _values: Array<string> = [];
      for (let i = 0; i < total; i++) {
        const value = resolveValue(records[i] as unknown as Record<string, string>, valueKeys);
        if (value !== undefined) {
          _values.push(String(value));
        }
      }

      return shouldUseVerticalBarSeparator(type)
        ? _values.sort((a, b) => a.localeCompare(b)).join(' | ')
        : _values.sort((a, b) => a.localeCompare(b));
    }
    return '';
  }, [records]);

  const groupAndCount = useMemo(
    () =>
      (displayValue: string | string[]): string | string[] => {
        if (Array.isArray(displayValue)) {
          const countValues: Record<string, number> = {};
          for (const value of displayValue) {
            countValues[value] = (countValues[value] || 0) + 1;
          }
          return Object.entries(countValues).map(([value, count]) => (count > 1 ? `${value} (x${count})` : value));
        }
        return displayValue;
      },
    [displayValue]
  );

  if (loading) {
    return <DataCellLoading />;
  } else if (!loading && records.length > 0) {
    return <DataGridDataCell value={groupAndCount(displayValue)} width={width} />;
  } else {
    return null;
  }
};

export default RecordlinkBaseDataCell;

const shouldUseVerticalBarSeparator = (type?: CombinedFieldType) => {
  switch (type) {
    case 'TEXTFIELD':
    case 'TEXTAREA':
    case 'MULTISELECTCHECKBOX':
    case 'MULTISELECTPERSONFIELD':
    case 'MULTISELECTFIELD':
    case 'READERS':
    case 'DROPDOWNMULTISELECT':
      return true;
    default:
      return false;
  }
};

const resolveValue = (record: Record<string, string>, keys: string[]) => {
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] in record) {
      return record[keys[i]];
    }
  }
};
