// API user fields (https://myosh.com/api-doc/internal#/users-controller/getUser)
export enum UserFields {
  id = 'id',
  firstName = 'firstName',
  lastName = 'lastName',
  fullName = 'fullName',
  employeeId = 'employeeId',
  affiliation = 'affiliation',
  phone = 'phone',
  occupation = 'occupation',
  occupationId = 'occupationId',
  secondaryOccupations = 'secondaryOccupations',
  email = 'email',
  manager = 'manager',
  managerId = 'managerId',
  managerFirstName = 'managerFirstName',
  managerLastName = 'managerLastName',
  loginRequired = 'loginRequired',
  vikingAnalytics = 'vikingAnalytics',
  vikingAnalyticsContributor = 'vikingAnalyticsContributor',
  userId = 'userId',
  archived = 'archived',
  customerId = 'customerId',
  externalUser = 'externalUser',
  activeExternalUser = 'activeExternalUser',
  ssoUser = 'ssoUser',
  initialPasswordChanged = 'initialPasswordChanged',
  superuser = 'superuser',
  version = 'version',
  lastChange = 'lastChange',
  localeId = 'localeId',
  localeTag = 'localeTag',
  dateFormat = 'dateFormat',
  userHierarchy = 'userHierarchy',
  userHierarchyAccess = 'userHierarchyAccess',
  userHierarchyFilter = 'userHierarchyFilter',
  userFile = 'userFile',
  device = 'device',
  groups = 'groups',
  avatar = 'avatar',
  adminAccess = 'adminAccess',
  unidDominoKey = 'unidDominoKey',
  splitPosition = 'splitPosition',
  occasionalExternalUser = 'occasionalExternalUser',
  password = 'password',
  retypePassword = 'retypePassword',
  oldPassword = 'oldPassword',
  isVikingAnalyticsEnabled = 'isVikingAnalyticsEnabled',
}

export enum UserHierarchyField {
  company = 'Company',
  division = 'Division',
  department = 'Department',
  site = 'Site',
}
