import { useModalDialogContext } from '@myosh/odin-components';
import React, { useEffect, useState } from 'react';
import { RecordResult } from '../../../../@types/records';
import { forceAssert } from '../../../../common/common-functions';
import Record, { RecordSaveState } from '../../../record/record.component';

interface RecordWrapperProps {
  formId: number;
  onClose: () => void;
  onLinkedRecordCreated?: (recordId: number) => void;
  fieldId: number;
  parentRecordId?: number;
  isTextualQuestionnaireLinkedRecords?: boolean;
}

const RecordWrapper = ({
  formId,
  onClose,
  onLinkedRecordCreated,
  fieldId,
  parentRecordId,
  isTextualQuestionnaireLinkedRecords,
}: RecordWrapperProps) => {
  const [reverseRecordLinkData, setReverseRecordLinkData] = useState<{
    reverseLinkedRecords: Array<RecordResult>;
    formId: number;
  }>();
  const dialogContext = useModalDialogContext();

  useEffect(() => {
    if (dialogContext.data) {
      const recordResult = forceAssert<RecordResult>(dialogContext.data);
      if (recordResult.id) {
        setReverseRecordLinkData({
          reverseLinkedRecords: [recordResult],
          formId: recordResult.formId,
        });
      }
    }
  }, [dialogContext.data]);

  return (
    <Record
      formId={formId}
      reverseLinkedRecords={reverseRecordLinkData?.reverseLinkedRecords}
      reverseRecordLinkFormId={reverseRecordLinkData?.formId}
      onClose={onClose}
      recordState={RecordSaveState.New}
      isLinkedRecord={true}
      onLinkedRecordCreated={onLinkedRecordCreated}
      parentLinkedRecordId={parentRecordId}
      parentLinkedFieldId={fieldId}
      isTextualQuestionnaireLinkedRecords={isTextualQuestionnaireLinkedRecords}
    />
  );
};

export default RecordWrapper;
