import React, { useState } from 'react';
import Joyride, { CallBackProps, STATUS } from 'react-joyride';
import { useUpdateFeatureToursMutation } from '../../../../redux/services/api';
import { showError } from '../../../../services/notification.service';
import { FeatureTours } from '../../../../@types/feature-tours';
import i18next from '../../../../i18n';
import { useTranslation } from 'react-i18next';
import useCustomJoyrideButtonsStyling from '../../../../hooks/use-custom-joyride-buttons-styling';

const steps = [
  {
    target: '.record-navigator',
    content: i18next.t('feature-tour-record-sidebar-step-one'),
    popover: {
      placement: 'left',
    },
    disableBeacon: true,
    spotlightClicks: false,
  },
  {
    target: '.close-all-button',
    content: i18next.t('feature-tour-record-sidebar-step-two'),
    popover: {
      placement: 'left',
    },
    disableBeacon: true,
    spotlightClicks: false,
  },
  {
    target: '.expand-navigator',
    content: i18next.t('feature-tour-record-sidebar-step-three'),
    popover: {
      placement: 'left',
    },
    disableBeacon: true,
    spotlightClicks: false,
  },
];

export interface FeatureTourProps {
  featureTourData: FeatureTours;
}

const RecordSideBarFeatureTour = ({ featureTourData }: FeatureTourProps) => {
  const [runTour, setRunTour] = useState(true);

  const [setFeatureTourAsSeen] = useUpdateFeatureToursMutation();
  const { t } = useTranslation();
  const { primaryButton, textButton, closeIconButton } = useCustomJoyrideButtonsStyling();

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    if (action === 'close') {
      setRunTour(false);
    } else if (finishedStatuses.includes(status)) {
      if (data) {
        setFeatureTourAsSeen({ ...featureTourData, hasRecordsSidebarTourRan: true })
          .unwrap()
          .then()
          .catch(() => showError(t('feature-tour-save-fail')));
      }
    }
  };

  return (
    <Joyride
      steps={steps}
      run={runTour}
      continuous
      showProgress
      callback={handleJoyrideCallback}
      styles={{
        options: {
          zIndex: 10001,
        },
        buttonNext: primaryButton,
        buttonBack: textButton,
        buttonClose: closeIconButton,
      }}
      locale={{
        back: t('back'),
        close: t('close'),
        last: t('finish'),
        next: t('next'),
      }}
    />
  );
};

export default RecordSideBarFeatureTour;
