import React, { useMemo } from 'react';
import { DataGridCellRendererProps } from '@myosh/odin-components';
import { forceAssert } from '../../common/common-functions';
import { EntityReference } from '../../@types/views';
import useFetchLinkedRecords from '../../hooks/use-fetch-linked-records';
import { linkedFieldValueKeys } from '../../common/data-grid-column-utils';
import RecordlinkBaseDataCell from './recordlink-base-data-cell';

/** A custom grid data cell that resolves the correct value to display when the column is 'RECORD_LINK' type */
const RecordlinkDataCell = ({ column, rowData }: DataGridCellRendererProps) => {
  const columnEntityReference = forceAssert<EntityReference>(column.customDataProperties);
  const { entityId, entityPropertyName, linkedFieldType } = columnEntityReference;
  const valueKeys = linkedFieldValueKeys(entityPropertyName);

  const recordIds = useMemo(
    () => forceAssert<Record<string, Array<string>>>(rowData?.fields)?.[entityId] || [],
    [rowData.fields]
  );

  const recordId = Number(rowData['id']);
  const { linkedRecords: records, isFetching } = useFetchLinkedRecords(recordId, entityId, recordIds);

  return (
    <RecordlinkBaseDataCell
      records={records}
      valueKeys={valueKeys}
      loading={isFetching}
      width={column.width}
      type={linkedFieldType}
    />
  );
};

export default RecordlinkDataCell;
