import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuthAndUrlEncoding } from './api';
import {
  GlobalHierarchyFilterStructure,
  GlobalHierarchyFiltersResponse,
  HierarchyType,
  HierarchyTypesApiProps,
  HierarchyTypesResult,
  HierarchyValuesApiProps,
  HierarchyValuesResult,
} from '../../@types/hierarchy-fields';
import { createFieldFilterText } from '../../common/common-functions';
import { formatGlobalHierarchyFiltersData } from '../../common/hierarchy-functions';

export const hierarchyApi = createApi({
  reducerPath: 'hierarchy',
  baseQuery: baseQueryWithReAuthAndUrlEncoding,
  tagTypes: ['GlobalHierarchyFilters'],
  endpoints: (builder) => ({
    hierarchyTypes: builder.query<Array<HierarchyType>, HierarchyTypesApiProps>({
      query: (args) => {
        const url = '/hierarchy-types/simple';
        let queryParams = '?';
        if (args?.archived) {
          queryParams += `&filter=archived:eq:${args.archived}`;
        }
        return url + (queryParams.length > 1 ? queryParams : '');
      },
      transformResponse: (response: HierarchyTypesResult) => {
        return response.result;
      },
    }),
    hierarchyValues: builder.query<HierarchyValuesResult, HierarchyValuesApiProps>({
      query: ({ page, pageSize, sort, filters }) => {
        let url = `/hierarchy-values/simple?page=${page}&pageSize=${pageSize}${createFieldFilterText({ filters })}`;
        if (sort) {
          url += `&sort=${sort}`;
        }
        return url;
      },
      transformResponse: (response: HierarchyValuesResult) => {
        return { ...response, items: response?.items ?? [] };
      },
    }),
    getGlobalHierarchyFilters: builder.query<GlobalHierarchyFilterStructure, void>({
      query: () => '/global-hierarchy-filters',
      transformResponse: (response: GlobalHierarchyFiltersResponse) =>
        formatGlobalHierarchyFiltersData(response.result),
      providesTags: ['GlobalHierarchyFilters'],
    }),
    updateGlobalHierarchyFilters: builder.mutation<void, Omit<GlobalHierarchyFilterStructure, 'id'>>({
      query: (body) => ({
        url: '/global-hierarchy-filters',
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['GlobalHierarchyFilters'],
    }),
    deleteGlobalHierarchyFilters: builder.mutation<void, void>({
      query: () => ({
        url: '/global-hierarchy-filters',
        method: 'DELETE',
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),
      invalidatesTags: ['GlobalHierarchyFilters'],
    }),
  }),
});

export const {
  useHierarchyTypesQuery,
  useLazyHierarchyTypesQuery,
  useHierarchyValuesQuery,
  useLazyHierarchyValuesQuery,
  useGetGlobalHierarchyFiltersQuery,
  useUpdateGlobalHierarchyFiltersMutation,
  useDeleteGlobalHierarchyFiltersMutation,
} = hierarchyApi;
