import React, { useRef } from 'react';
import { ModalDialog } from '@myosh/odin-components';
import UserDetails from '../../../admin/user/user-details.component';
import { LinkedUser } from '../../../../@types/users';

interface CreateUserDialogProps {
  header: string;
  visible: boolean;
  hidden: () => void;
  onUserCreated: (user: LinkedUser) => void;
  isExternal?: boolean;
}

export default function CreateUserDialog({
  header,
  visible,
  hidden,
  onUserCreated,
  isExternal,
}: CreateUserDialogProps) {
  const linkedUser = useRef<LinkedUser>();

  const onClose = () => {
    if (linkedUser.current) {
      onUserCreated(linkedUser.current);
      linkedUser.current = undefined;
    }
    hidden();
  };

  const handleLinkedUserCreated = (id: number, firstName: string, lastName: string) => {
    linkedUser.current = { value: id, text: lastName.toUpperCase() + ' ' + firstName };
  };

  return (
    <ModalDialog header={header} visible={visible} hidden={hidden} fullScreen>
      <UserDetails
        isLinkedUser={true}
        isExternal={isExternal}
        onLinkedUserCreated={handleLinkedUserCreated}
        onClose={onClose}
        isNewUser={true}
      />
    </ModalDialog>
  );
}
