import { cloneDeep } from 'lodash';
import { GroupItem, GroupStructure } from '../@types/groups';
import { JsonDataItem } from '@myosh/odin-components';

export function createGroupData(groupResponse: GroupItem) {
  const modifiedGroupResponse: GroupItem = cloneDeep(groupResponse);
  const data: GroupStructure = {
    original: {
      ...groupResponse,
    },
    ...modifiedGroupResponse,
  };

  return data;
}

export function createGroupPatch(data: JsonDataItem, fields: JsonDataItem) {
  const dirtyFields = Object.keys(fields);
  const patch: JsonDataItem = {};
  for (let index = 0, length = dirtyFields.length; index < length; index++) {
    const field = dirtyFields[index];
    patch[field] = data[field];
  }
  return patch;
}
