import {
  DataGridSettingsMenuItem,
  DynamicFormButtonSetting,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import i18next from '../../i18n';
import { t } from 'i18next';

export const booleanSearchValues = [
  { value: true, text: i18next.t('true') },
  { value: false, text: i18next.t('false') },
];

export const getComboboxData = (subscriber: OdinDataSender<JsonDataWrapper>, options: OdinDataRetrievalOptions) => {
  if (options?.page === 1) {
    subscriber.sendData({ data: booleanSearchValues, requestId: options.requestId });
  } else {
    subscriber.sendData();
  }
};

export const archieveButton: DynamicFormButtonSetting = {
  name: 'archive',
  text: i18next.t('archive'),
  htmlType: 'button',
  type: 'default',
  variant: 'alternative',
};

export const restoreButton: DynamicFormButtonSetting = {
  name: 'restore',
  text: i18next.t('restore'),
  htmlType: 'button',
  type: 'default',
  variant: 'alternative',
  disabled: false,
};

export const saveButton: DynamicFormButtonSetting = {
  name: 'save',
  text: i18next.t('save'),
  htmlType: 'submit',
  type: 'primary',
  variant: 'alternative',
};

export const getArchiveRestoreMessages = (archive: boolean) => {
  return archive
    ? {
        loading: i18next.t('archiving'),
        success: i18next.t('archived'),
        error: i18next.t('save-failed'),
      }
    : {
        loading: i18next.t('restoring'),
        success: i18next.t('restored'),
        error: i18next.t('save-failed'),
      };
};

// The Administration Section Tabs
export const enum AdminTabs {
  USERS = 'users',
  GROUPS = 'groups',
  OCCUPATIONS = 'occupations',
  HIERARCHIES = 'hierarchies',
  TRAINING_MANAGEMENT_COMPETENCY = 'training-management/competency',
  TRAINING_MANAGEMENT_COMPETENCY_GROUP = 'training-management/competency-group',
  TRAINING_MANAGEMENT_OCCUPATIONAL_REQUIREMENT = 'training-management/occupational-requirement',
  FORMS = 'forms',
  NOTIFICATIONS = 'notifications',
  SCHEDULERS = 'schedulers',
  RULES = 'rules',
  HOME = 'home',
  THEME = 'theme',
  SYSTEM_SETTINGS = 'settings',
  RISK_MATRIX = 'risk-matrix',
  MODULES = 'modules',
}

export const viewGridSettingsOptions: Array<DataGridSettingsMenuItem> = [
  {
    label: t('restore-view', 'Restore View'),
    key: 'restore',
  },
  {
    key: 'separator',
    isSeparator: true,
  },
  {
    label: t('save-current-view', 'Save Current View'),
    key: 'save',
  },
  {
    label: t('manage-views', 'Manage Views'),
    key: 'manage',
  },
  {
    key: 'viewSeparator',
    isSeparator: true,
  },
];
export const recordGridSettingsOptions: Array<DataGridSettingsMenuItem> = [
  {
    label: t('import-records', 'Import Records'),
    key: 'import',
  },
  {
    label: t('export-records', 'Export Records'),
    key: 'export',
  },
  {
    key: 'recordSeparator',
    isSeparator: true,
  },
];
