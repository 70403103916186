import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  CheckboxChangeEventType,
  DropDownResult,
  FileUpload,
  ModalDialog,
  OdinDataRetrievalOptions,
  DataGridRef,
  ModalDialogButtonSetting,
  HelpComponent,
  DomElementAlignment,
  DomTargetPosition,
} from '@myosh/odin-components';
import { recordApi, useConfirmImportJobMutation } from '../../../redux/services/record';
import { useTranslation } from 'react-i18next';
import {
  useCreateImportJobMutation,
  useGetImportJobDetailsQuery,
  useStartImportJobMutation,
} from '../../../redux/services/record';
import { Subject, takeUntil } from 'rxjs';
import { getDataGridReference } from '../../../services/data-grid.service';
import { useDispatch } from 'react-redux';
import { dismiss, showError, showInfo, showSuccess } from '../../../services/notification.service';
import ConfigurationSection, { ImportRecordsModalRef } from './configuration-section';
import { templateHelpMessage, transformConfigurationData } from './record-import-settings';
import { submitExportJob } from '../../../redux/slices/export-slice';
import { ImportJobDetailsResult } from '../../../@types/background-jobs';
import { useFormSettingsQuery } from '../../../redux/services/api';
import useImportAttachments from './use-import-attachments';
import ImportAttachments from './import-attachments';

interface ExportRecordListTemplatesModalProps {
  visible: boolean;
  onClose: () => void;
  selectedForm?: DropDownResult;
  columns?: string;
  gridOptions?: OdinDataRetrievalOptions;
}

const maxUploadFileSize = 10485760; // equal to 10 MB

const ImportRecordsModal = ({
  selectedForm,
  columns,
  gridOptions,
  visible,
  onClose,
}: ExportRecordListTemplatesModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const gridRef = useRef<DataGridRef>();
  const configurationSection = useRef<ImportRecordsModalRef>(null);
  const destroySubject = useRef<Subject<void>>();
  const infoToastId = useRef<string>();

  const [recordTemplateFile, setRecordTemplateFile] = useState<Array<File>>([]);
  const [includeRecords, setIncludeRecords] = useState<boolean>(false);
  const [loadingImport, setLoadingImport] = useState<boolean>(false);
  const [importRecordsJobId, setImportRecordsJobId] = useState<number>(0);
  const [importRecordsJobDetails, setImportRecordsJobDetails] = useState(false);
  const [importJobData, setImportJobData] = useState<ImportJobDetailsResult>();
  const [unSupportedImportFields, setUnSupportedImportFields] = useState<string>();

  const [recordAttachmentsFile, setRecordAttachmentsFile] = useState<Array<File>>();
  const [isImportingNewRecords, setIsImportingNewRecords] = useState(false);

  const { data: importJobDetails } = useGetImportJobDetailsQuery(importRecordsJobId, {
    skip: !importRecordsJobId || !visible || !importRecordsJobDetails,
    pollingInterval: 6000,
  });

  const { data: formSettingsData } = useFormSettingsQuery((selectedForm?.value as number) ?? 0, {
    skip: selectedForm?.value === undefined,
  });

  const [createImportJob] = useCreateImportJobMutation();
  const [startImportJob] = useStartImportJobMutation();
  const [confirmImportJob] = useConfirmImportJobMutation();

  const { startImportAttachmentsJob } = useImportAttachments();

  useEffect(() => {
    destroySubject.current = new Subject<void>();
    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((dataGridRef) => {
        if (dataGridRef) {
          gridRef.current = dataGridRef;
        }
      });

    return () => {
      destroySubject.current?.next();
      destroySubject.current?.complete();
    };
  }, []);

  useEffect(() => {
    if (formSettingsData) {
      const unsupportedFieldsList = formSettingsData.fields
        .flatMap(
          (field) => field.fields?.filter((item) => item.importEnabled === false).map((item) => item.fieldType) || []
        )
        .join(', ');

      const cleanedFieldsList = unsupportedFieldsList.replace(/_/g, ' ');
      setUnSupportedImportFields(cleanedFieldsList);
    }
  }, [formSettingsData]);

  useEffect(() => {
    if (importJobDetails && importJobDetails?.status !== 'FINISHED') {
      setImportJobData(importJobDetails);
    }
    if (importJobDetails?.status === 'VALIDATED') {
      dismiss(infoToastId.current);
      setLoadingImport(false);
      setImportRecordsJobDetails(false);
    } else if (importJobDetails?.status === 'FINISHED') {
      dismiss(infoToastId.current);
      setLoadingImport(false);
      setImportRecordsJobDetails(false);
      showSuccess(t('import-records-success'));
      gridRef?.current?.api.data.getDataOverwritePageCache();
      dispatch(
        recordApi.util.invalidateTags([
          { type: 'Record', id: 'LIST' },
          'TotalRecordCount',
          { type: 'AllFormsRecord', id: 'LIST' },
        ])
      );

      if (recordAttachmentsFile && recordAttachmentsFile.length > 0) {
        startImportAttachmentsJob(recordAttachmentsFile[0], isImportingNewRecords ? 'RECORD_SOURCE_ID' : 'RECORD_ID');
      }

      handleModalClose();
    }
  }, [importJobDetails]);

  const handeTemplateDownload = (format: string = includeRecords ? 'TEMPLATE_AND_RECORDS' : 'TEMPLATE') => {
    dispatch(
      submitExportJob({
        format,
        moduleId: undefined,
        formId: selectedForm?.value as string,
        formName: selectedForm?.text as string,
        columns,
        gridOptions,
      })
    );
  };

  const handleImportError = () => {
    dismiss(infoToastId.current);
    setLoadingImport(false);
    showError(t('import-records-failed'));
  };

  const recordsImportHandler = () => {
    const configurationsForm = configurationSection.current?.getConfigurationValues();
    if (!configurationsForm?.timeZone) return;

    const configurationPayload = transformConfigurationData(configurationsForm);

    if (selectedForm?.value) {
      setLoadingImport(true);
      infoToastId.current = showInfo(t('import-file-validating'), { duration: Infinity });
      createImportJob(selectedForm.value as number)
        .unwrap()
        .then((response) => {
          fetch(response.presignedUrl, {
            method: 'PUT',
            body: recordTemplateFile[0],
          })
            .then(() => {
              startImportJob({ id: response.id, ...configurationPayload })
                .unwrap()
                .then(() => {
                  setImportRecordsJobId(response.id);
                  setImportRecordsJobDetails(true);
                })
                .catch(() => {
                  handleImportError();
                });
            })
            .catch(() => {
              handleImportError();
            });
        })
        .catch(() => {
          handleImportError();
        });
    }
  };

  const onImportJobConfirmation = () => {
    if (importRecordsJobId) {
      confirmImportJob(importRecordsJobId)
        .unwrap()
        .then(() => {
          setImportRecordsJobDetails(true);
          setLoadingImport(true);
          infoToastId.current = showInfo(t('import-records-in-progress'), { duration: Infinity });
        })
        .catch(() => handleImportError());
    }
  };

  const handleModalClose = () => {
    setLoadingImport(false);
    setImportRecordsJobId(0);
    setRecordTemplateFile([]);
    setImportRecordsJobDetails(false);
    setImportJobData(undefined);
    dismiss(infoToastId.current);
    onClose();
  };

  const buttons: Array<ModalDialogButtonSetting> = [
    {
      name: 'import',
      text: t('import'),
      type: 'primary',
      disabled: loadingImport || !Boolean(recordTemplateFile.length),
      closeModalOnClick: false,
      onClick: recordsImportHandler,
    },
  ];

  const importConfirmationDialogButtons: Array<ModalDialogButtonSetting> = [
    {
      name: 'confirm',
      text: t('confirm'),
      type: 'primary',
      onClick: onImportJobConfirmation,
    },
    {
      name: 'cancel',
      text: t('cancel'),
      onClick: handleModalClose,
    },
  ];

  const onUploadRecordTemplateChange = (value?: File[]) => {
    setRecordTemplateFile(value || []);
  };

  const onUploadRecordAttachmentsChange = (value?: File[]) => {
    setRecordAttachmentsFile(value);
  };

  return (
    <>
      <ModalDialog
        header={
          importJobData?.status === 'RUNNING' || !importJobData ? t('import-records') : t('import-summary-review')
        }
        visible={visible}
        hidden={handleModalClose}
        minDialogWidth={810}
        maxDialogWidth={1000}
        closeOnEscape={false}
        buttonsCloseModal={false}
        buttons={importJobData?.status === 'RUNNING' || !importJobData ? buttons : importConfirmationDialogButtons}
      >
        {importJobData?.status === 'RUNNING' || !importJobData ? (
          <>
            <div className="odin-relative">
              <label htmlFor="download-template" className="text-sm font-bold">
                {`1. ${t('template')}`}
              </label>
              <HelpComponent
                className="help-component-default"
                description={templateHelpMessage()}
                tooltipZIndexCheck
                tooltipAlignment={DomElementAlignment.TopRight}
                tooltipTargetPosition={DomTargetPosition.TopLeft}
              />

              <div className="flex h-12 items-center justify-start pl-2">
                <div id="download-template" className="flex items-center">
                  <Checkbox
                    name="include-records"
                    label={t('include-records')}
                    value={'true'}
                    initialChecked={includeRecords}
                    onChange={(event?: CheckboxChangeEventType) => {
                      if (event) {
                        setIncludeRecords(event.checked);
                      }
                    }}
                  />
                </div>

                <div className="ml-auto mr-auto">
                  <Button onClick={() => handeTemplateDownload()} variant="alternative">
                    {t('download-template')}
                  </Button>
                </div>
              </div>
            </div>
            <div className="text-sm whitespace-wrap p-2">
              {t('unsupported-import-fields', { unSupportedImportFields: unSupportedImportFields })}
            </div>
            <ConfigurationSection ref={configurationSection} />
            <label htmlFor="download-template" className="text-sm font-bold">
              {`3. ${t('upload')}`}
            </label>
            <FileUpload
              disabled={loadingImport}
              classNames="mb-2 mt-2"
              maxUploadFiles={1}
              maxUploadFileSize={maxUploadFileSize}
              readOnly={false}
              thumbnails={true}
              onChange={onUploadRecordTemplateChange}
            />
            <ImportAttachments
              onChange={onUploadRecordAttachmentsChange}
              label={`4. ${t('attachments')}`}
              onAttachmentsLinkedWithSourceIDChange={setIsImportingNewRecords}
            />
          </>
        ) : (
          <>
            <h2 className="text-md font-bold">{t('import-file-validation-result')}</h2>
            <pre className="mt-5 font-sans text-md leading-5 max-h-96 overflow-y-auto custom-scroll">
              {importJobData?.jobDetails}
            </pre>
          </>
        )}
        {loadingImport && <div className="absolute inset-0 z-[105] cursor-not-allowed" />}
      </ModalDialog>
    </>
  );
};

export default ImportRecordsModal;
