import React, { Ref, forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from 'react';
import { FormTitle } from '../../form/form-title.component';
import { OccupationsPermissions } from '../../../common/user.permissions';
import useProfileData from '../../../hooks/use-profile-data';
import { getOccupationFormSettings } from './occupation-form-settings';
import { cloneDeep, debounce } from 'lodash';
import { v4 } from 'uuid';
import {
  DataGridRef,
  DynamicFormButtonSetting,
  DynamicForm,
  DynamicFormRef,
  JsonDataItem,
  JsonDataWrapper,
  ModalDialogRef,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  ModalDialog,
} from '@myosh/odin-components';
import { ActiveRecordContextProps } from '../../active-record/active-record.component';
import { FormLoading } from '../../form/form-loading.component';
import { BehaviorSubject, Subject, skip, take, takeUntil } from 'rxjs';
import { getDataGridReference } from '../../../services/data-grid.service';
import { ExtendedDynamicFormSettings, forceAssert } from '../../../common/common-functions';
import { OccupationItem, OccupationMutationResponse } from '../../../@types/occupations';
import {
  updateOccupationData,
  getOccupationFieldOptionsData,
  ExtendedOccupationItem,
} from '../../../common/occupation-functions';
import { useAppDispatch } from '../../../redux/hooks';
import {
  useGetOccupationByIdQuery,
  useUpdateOccupationMutation,
  useAddOccupationMutation,
  occupationApi,
} from '../../../redux/services/occupation';
import { FormChangedFields } from '../../../@types/common';
import { getChangedFieldsData, saveModalButtons } from '../../../common/common-administration-utils';
import { archieveButton, getArchiveRestoreMessages, restoreButton, saveButton } from '../../../pages/admin/admin-utils';
import usePerformingSaveToast from '../../../hooks/use-performing-save-toast';
import { promiseToast, showError, showSuccess } from '../../../services/notification.service';
import { useTranslation } from 'react-i18next';
import useDynamicFormNotifier from '../../../hooks/use-dynamic-form-notifier';
import CloseActiveItemButton from '../../common/close-active-item-button';
import { useUserAccess } from '../../../hooks/use-user-access';

export interface OccupationDetailsProps {
  occupationId: number;
  title: string;
  isNewOccupation?: boolean;
  onClose: (id: string) => void;
  panelSettings?: BehaviorSubject<ActiveRecordContextProps>;
  onCloseActiveRecordConfirmationModalRef?: ModalDialogRef | null;
}
interface OccupationDetailsRef {
  submitForm?: () => void;
}

enum TypeOfError {
  DUPLICATED = 'DUPLICATED',
  FAILED = 'FAILED',
}

const OccupationDetails = (props: OccupationDetailsProps, ref: Ref<OccupationDetailsRef>) => {
  const {
    occupationId,
    title,
    isNewOccupation = false,
    onClose,
    panelSettings,
    onCloseActiveRecordConfirmationModalRef,
  } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [occupation, setOccupation] = useState<ExtendedOccupationItem>();
  const [panelContextProps, setPanelContextProps] = useState<ActiveRecordContextProps>();
  const [formButtons, setFormButtons] = useState<Array<DynamicFormButtonSetting>>();
  const [formSettings, setFormSettings] = useState<ExtendedDynamicFormSettings>();

  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const saveModalReference = useRef<ModalDialogRef>(null);
  const dataGridRef = useRef<DataGridRef>();
  const destroySubject = useRef(new Subject<void>());
  const dynamicFormId = useRef(v4());
  const closeOnModalSaveRef = useRef(false);

  const { profileData: { user: userData } = {} } = useProfileData();
  const { data, isLoading, isFetching } = useGetOccupationByIdQuery(occupationId, {
    skip: occupationId === undefined,
  });

  const { readOnly, editAccess, setReadOnly } = useUserAccess({
    initialAccess: isNewOccupation,
    userData,
    permissions: {
      MODIFY: [OccupationsPermissions.OCCUPATION_MODIFY, OccupationsPermissions.OCCUPATION_CREATE],
      READ: OccupationsPermissions.OCCUPATION_READ,
    },
  });

  const [addOccupation] = useAddOccupationMutation();
  const [updateOccupation] = useUpdateOccupationMutation();

  const { notifyDirty, notifySaveSucceeded, notifySaveFailed } = useDynamicFormNotifier(dynamicFormId.current);

  const { showPerformingSaveToast, hidePerformingSaveToast } = usePerformingSaveToast({
    shouldFreezeActiveRecord: true,
    disabled: panelContextProps?.batchSaveInProgress,
  });

  useEffect(() => {
    if (!isFetching) {
      setOccupation(cloneDeep(data));
      const newFormButtons: Array<DynamicFormButtonSetting> = [];
      if (data?.archived) {
        newFormButtons.push({ ...restoreButton, onClick: () => onArchieveOccupation(false) });
      } else {
        newFormButtons.push(saveButton);
        if (!isNewOccupation) {
          newFormButtons.push({ ...archieveButton, onClick: () => onArchieveOccupation(true) });
        }
      }
      if (!editAccess) {
        setFormButtons(newFormButtons.map((button) => ({ ...button, disabled: true })));
      } else {
        setFormButtons(newFormButtons);
      }
    }
  }, [data, isFetching, editAccess]);

  useEffect(() => {
    panelSettings?.pipe(skip(1), takeUntil(destroySubject.current)).subscribe((settings) => {
      setPanelContextProps(settings);
    });

    onCloseActiveRecordConfirmationModalRef
      ?.subscribeToDialogResult()
      ?.pipe(takeUntil(destroySubject.current))
      .subscribe((result) => {
        if (result.buttonName === 'save' && dynamicFormReference.current?.formIsDirty()) {
          dynamicFormReference.current?.submitForm(true);
        } else if (result.buttonName === 'discard') {
          onClose(dynamicFormId.current);
        }
      });

    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((gridRef) => {
        if (gridRef) {
          dataGridRef.current = gridRef;
        }
      });

    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    submitForm: dynamicFormReference.current?.submitForm,
  }));

  const onArchieveOccupation = (archive: boolean) => {
    if (occupationId) {
      promiseToast(
        updateOccupation({ id: occupationId, archived: archive })
          .then(() => {
            onClose(dynamicFormId.current);
            dataGridRef.current?.api.data.getDataOverwritePageCache();
            dispatch(occupationApi.util.invalidateTags([{ type: 'Occupation', id: 'LIST' }]));
          })
          .catch(() => showErrorNotificationToast(TypeOfError.FAILED)),
        getArchiveRestoreMessages(archive)
      );
    }
  };

  useEffect(() => {
    if (occupation?.archived) {
      setReadOnly(true);
    }
  }, [occupation]);

  useEffect(() => {
    setFormSettings(getOccupationFormSettings(readOnly));
  }, [readOnly]);

  const showSaveToast = () => {
    if (!panelContextProps?.batchSaveInProgress) {
      showSuccess(t('record-saved'));
    }
    notifySaveSucceeded(dynamicFormId.current);
    closeOnModalSaveRef.current = true;
    if (!occupationId || closeOnModalSaveRef.current) {
      onClose(dynamicFormId.current);
    }
  };

  function occupationErrorType(response: OccupationMutationResponse) {
    const errorResult = response.error.data.validation.errors[0];
    const startIndex = errorResult.indexOf(':') + 2;
    if (errorResult.substring(startIndex) === 'Duplicate caption on Occupation!') {
      return 'DUPLICATED';
    } else {
      return 'FAILED';
    }
  }

  const showErrorNotificationToast = (convertedResponse: OccupationMutationResponse) => {
    if (!panelContextProps?.batchSaveInProgress) {
      const errorType = occupationErrorType(convertedResponse);
      if (errorType === TypeOfError.DUPLICATED) {
        showError(t('duplicate-occupation-error'));
      } else {
        showError(t('occupation-details-save-failed'));
      }
    }

    notifySaveFailed(dynamicFormId.current);
  };

  const createOccupation = useCallback((data: JsonDataItem) => {
    updateOccupationData(data);
    saveOccupationData(data, (response) => {
      const convertedResponse = forceAssert<OccupationMutationResponse>(response);
      if (convertedResponse?.data?.result) {
        hidePerformingSaveToast();
        showSaveToast();
        refreshGrid();
      } else if (convertedResponse?.error) {
        hidePerformingSaveToast();
        showErrorNotificationToast(convertedResponse);
      }
    });
  }, []);

  const onFormSubmit = (data: JsonDataItem) => {
    if (occupationId && occupation) {
      const dirtyFields = dynamicFormReference.current?.formDirtyFields() || {};
      const patchData = getChangedFieldsData(forceAssert<FormChangedFields<OccupationItem>>(dirtyFields), data);
      updateOccupationData(patchData);
      saveOccupationData({ id: occupationId, ...patchData }, (response) => {
        const convertedResponse = forceAssert<OccupationMutationResponse>(response);
        if (convertedResponse?.data?.result) {
          dataGridRef.current?.api.data.recordChanged(occupationId);
          hidePerformingSaveToast();
          showSaveToast();
        } else if (convertedResponse?.error) {
          hidePerformingSaveToast();
          showErrorNotificationToast(convertedResponse);
        }
      });
    } else {
      createOccupation(data);
    }
  };

  const refreshGrid = () => {
    dataGridRef.current?.api.data.getDataOverwritePageCache();
  };

  const saveOccupationData = (
    occupationData: Partial<OccupationItem>,
    successCallback: (response?: unknown) => void
  ) => {
    showPerformingSaveToast();
    if (occupationData.id) {
      updateOccupation(occupationData)
        .then(successCallback)
        .catch(() => {
          showErrorNotificationToast(TypeOfError.FAILED);
        });
    } else {
      addOccupation([occupationData] as Array<OccupationItem>)
        .then(successCallback)
        .catch(() => {
          showErrorNotificationToast(TypeOfError.FAILED);
        });
    }
  };

  const onCloseOccupationDetails = () => {
    if (!dynamicFormReference.current?.formIsDirty()) {
      onClose(dynamicFormId.current);
    } else {
      saveModalReference.current
        ?.show()
        ?.pipe(take(1))
        .subscribe((result) => {
          if ('save' === result.buttonName) {
            const data = dynamicFormReference.current?.getData() || {};
            if (occupationId) {
              const dirtyFields = dynamicFormReference.current?.formDirtyFields() || {};
              const patchData = getChangedFieldsData(forceAssert<FormChangedFields<OccupationItem>>(dirtyFields), data);
              updateOccupationData(patchData);
              saveOccupationData({ id: occupationId, ...patchData }, (response) => {
                const convertedResponse = forceAssert<OccupationMutationResponse>(response);
                if (convertedResponse?.data?.result) {
                  dataGridRef.current?.api.data.recordChanged(occupationId);
                  hidePerformingSaveToast();
                  showSaveToast();
                } else if (convertedResponse?.error) {
                  hidePerformingSaveToast();
                  showErrorNotificationToast(convertedResponse);
                }
              });
            } else if (!occupationId) {
              createOccupation(data);
            }
          } else if ('discard' === result.buttonName) {
            onClose(dynamicFormId.current);
          }
        });
    }
  };

  const dataRetrieval: OdinDataRetrieval = {
    getData: (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
      if ('COMBOBOX' === options?.fieldType) {
        getOccupationFieldOptionsData(subscriber, options, dispatch, userData?.isExternal ?? false);
      } else {
        subscriber.sendData();
      }
    },
  };

  const onFieldChanged = debounce(() => notifyDirty(dynamicFormReference.current), 500);

  return (
    <>
      <div className="flex flex-shrink-0 flex-col">
        <div className="flex">
          <FormTitle title={title} />
          {!isLoading && (
            <div className="flex min-w-min justify-end">
              <CloseActiveItemButton onClick={onCloseOccupationDetails} />
            </div>
          )}
        </div>
      </div>
      <div className="admin-form-default">
        {!formSettings && <FormLoading />}
        {formSettings && (
          <DynamicForm
            ref={dynamicFormReference}
            dynamicFormId={dynamicFormId.current}
            data={occupation ?? {}}
            settings={formSettings}
            dataRetrieval={dataRetrieval}
            buttons={formButtons}
            buttonsLocation={0}
            readOnly={readOnly}
            buttonsPosition={1}
            onSubmit={onFormSubmit}
            onFieldChanged={onFieldChanged}
          />
        )}
      </div>
      <ModalDialog ref={saveModalReference} header={t('save-unsaved-changes')} buttons={saveModalButtons}>
        <div>{t('save-message')}</div>
      </ModalDialog>
    </>
  );
};
export default forwardRef(OccupationDetails);
