import React, { forwardRef, ReactNode, Ref, useEffect, useImperativeHandle, useReducer, useRef, useState } from 'react';
import {
  DomElementAlignment,
  DomTargetPosition,
  IconButton,
  iconMap,
  ModalDialog,
  ModalDialogButtonSetting,
  ModalDialogRef,
  OdinIcon,
  OdinIconSize,
  Tooltip,
  useTestTag,
} from '@myosh/odin-components';
import { BehaviorSubject, Subject, take, takeUntil } from 'rxjs';
import { v4 } from 'uuid';
import cx from 'classnames';
import ActiveRecordPanel, { RecordPanelInfo } from './active-record-panel.component';
import reducer, { ActiveRecordActionType, ActiveRecordChildSetting } from './active-record.reducer';
import i18next from '../../i18n';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/hooks';
import { setActivePanelEmpty, setActivePanelFilled } from '../../redux/slices/layout';
import useActiveRecordBatchSave from './hooks/use-active-record-batch-save';
import { getDefaultRecordWidth, MIN_ALLOWED_PANEL_WIDTH } from './active-record-helper';
import { throttle } from 'lodash';

const confirmationDialogButtons: Array<ModalDialogButtonSetting> = [
  {
    name: 'save',
    text: i18next.t('save'),
    type: 'primary',
  },
  {
    name: 'discard',
    text: i18next.t('discard'),
  },
];

export interface ActiveRecordChildRenderProps {
  onCloseActiveRecord: (id: string) => void;
  settingsSubject: BehaviorSubject<ActiveRecordContextProps>;
  /**
   * Used to pass settings through the navigator to use when adding an item to the navigator.
   */
  extraSettings?: unknown;
  onCloseActiveRecordConfirmationModalRef: ModalDialogRef | null;
}

interface ActiveRecordProps {
  isCollapsed: boolean;
  defaultRecordWidth?: number;
  children: (props: ActiveRecordChildRenderProps) => ReactNode | ReactNode[] | JSX.Element;
  useConfirmationOnClose?: boolean;
  onClose: () => void;
  onActiveRecordChanged: (childSetting: ActiveRecordChildSetting) => void;
  onLastActiveRecordClosed: () => void;
  onFullScreenChanged: (fullScreen: boolean) => void;
  fullScreen?: boolean;
  hidden?: boolean;
}

export interface ActiveRecordContextProps {
  fullScreen: boolean;
  isNavCollapsed: boolean;
  batchSaveInProgress?: boolean;
  numberOfDirtyItems?: number;
  numberOfFailedItems?: number;
}

export interface ActiveRecordRef {
  /**
   * Adds (or navigates to) an active record .
   *
   * @param {string} recordKey - The active record key.
   * @param {string} newIcon - An optional icon to set
   * @param {string | JSX.Element} details - Optional details to set
   * @param {boolean} fullscreen - Sets the item in full screen mode.
   * @param extraSettings - Optional extra settings
   */
  addActiveRecord: (
    recordKey: string,
    icon: string,
    details: string | JSX.Element,
    fullscreen?: boolean,
    extraSettings?: unknown
  ) => void;
  /**
   * Updates an existing active record.
   *
   * @param {string} recordKey - The existing (old) active record key.
   * @param {string} newRecordKey - The new record key to set for the active record.
   * @param {string} newIcon - An optional new icon to set
   * @param {string | JSX.Element} newDetails - Optional new details to set
   * @param {boolean} newFullScreen - Sets the item in full screen mode.
   * @param newExtraSettings - Optional new extra settings
   * @param {boolean} forceRender - Force a render of the active record item by changing the existing renderKey
   */
  updateActiveRecord: (
    recordKey: string,
    newRecordKey: string,
    newIcon?: string,
    newDetails?: string | JSX.Element,
    newFullScreen?: boolean,
    newExtraSettings?: unknown,
    forceRender?: boolean
  ) => void;
  /**
   * @returns The number of items in the active record panel.
   */
  currentRecordCount: () => number;
  /**
   * Display an overlay over the active record panel and disable field editing.
   *
   * @param {boolean} frozen - Set the value to disable/enable the active record panel.
   */
  setActiveRecordPanelFrozen: (frozen: boolean) => void;
  setItemDirty: (id: string) => void;
  clearItemDirty: (id: string) => void;
  setItemSaveFailed: (id: string) => void;
  /** Returns whether the active record panel is currently displayed in full screen mode. */
  isFullScreen: () => boolean;
}

function ActiveRecord(
  {
    isCollapsed,
    defaultRecordWidth = getDefaultRecordWidth(),
    children,
    useConfirmationOnClose = true, // always use confirmation on close
    onClose,
    onActiveRecordChanged,
    onLastActiveRecordClosed,
    onFullScreenChanged,
    fullScreen = false,
    hidden = false,
  }: ActiveRecordProps,
  ref: Ref<ActiveRecordRef>
) {
  const dispatch = useAppDispatch();
  const tagCreator = useTestTag('active-record');
  const { t } = useTranslation();
  const [recordWidth, setRecordWidth] = useState<number>(defaultRecordWidth);

  const [state, dispatcher] = useReducer(reducer, {
    displayCollapsed: isCollapsed,
    childSettings: [],
    currentFullScreen: fullScreen,
    recordWidth: defaultRecordWidth,
    minimiseActiveRecord: true,
    frozen: false,
  });

  const settingsSubject = useRef(
    new BehaviorSubject<ActiveRecordContextProps>({
      fullScreen,
      isNavCollapsed: true,
    })
  );
  const onCloseActiveRecordConfirmationModalRef = useRef<ModalDialogRef>(null);
  const childKeysRef = useRef<Array<string>>([]);
  const destroySubject = useRef(new Subject<void>());

  const { setItemDirty, clearItemDirty, setItemSaveFailed, hasDirtyItems, showBatchSaveInProgress } =
    useActiveRecordBatchSave(settingsSubject.current, setActiveRecordPanelFrozen, closeAll);

  useEffect(() => {
    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
    };
  }, []);

  useEffect(() => {
    if (state.childSettings.length === 0) {
      dispatch(setActivePanelEmpty());
    } else {
      dispatch(setActivePanelFilled());
    }
  }, [state.childSettings.length]);

  useEffect(() => {
    if (state.activeRecordSetting) {
      onActiveRecordChanged?.(state.activeRecordSetting);
    }
  }, [state.activeRecordSetting]);

  //Resizing browser's window should update active records' width
  useEffect(() => {
    const resizeWindow = throttle(() => {
      const updatedWidth = window.innerWidth / 2;
      const panelWidth = Math.max(MIN_ALLOWED_PANEL_WIDTH, updatedWidth);
      dispatcher({
        type: ActiveRecordActionType.UpdateRecordWidth,
        recordWidth: panelWidth,
      });
      setRecordWidth(panelWidth);
    }, 70);

    window.addEventListener('resize', resizeWindow);
    return () => {
      window.removeEventListener('resize', resizeWindow);
    };
  }, []);

  // Once we bring the active record panels into this component then this will be used to eliminate the need for the context
  const onRecordPanelChanged = (info: RecordPanelInfo) => {
    if (info.fullScreen !== undefined) {
      dispatcher({
        type: ActiveRecordActionType.SetFullScreen,
        currentFullScreen: info.fullScreen,
        displayCollapsed: state.displayCollapsed,
      });

      settingsSubject.current.next({
        ...settingsSubject.current.value,
        fullScreen: info.fullScreen,
        isNavCollapsed: state.displayCollapsed,
      });

      onFullScreenChanged(info.fullScreen);
    }

    if (info.recordWidth) {
      dispatcher({
        type: ActiveRecordActionType.UpdateRecordWidth,
        recordWidth: info.recordWidth,
      });
      setRecordWidth(info.recordWidth);
    }
  };

  const addActiveRecord = (
    recordKey: string,
    icon: string,
    details: string | JSX.Element,
    fullscreen?: boolean,
    extraSettings?: unknown
  ) => {
    settingsSubject.current.next({
      ...settingsSubject.current.value,
      fullScreen: fullscreen || false,
      isNavCollapsed: state.displayCollapsed,
    });

    dispatcher({
      type: ActiveRecordActionType.AddActiveRecord, //adds or "navigates to" item if already present
      activeRecordSetting: {
        key: recordKey,
        renderKey: v4(),
        icon,
        details,
        extraSettings,
      },
      currentFullScreen: fullscreen || false,
    });

    if (useConfirmationOnClose) {
      childKeysRef.current = [...childKeysRef.current, recordKey];
    }
  };

  const closeActiveRecord = (recordKey: string) => {
    if (state.childSettings.length === 1) {
      onLastActiveRecordClosed?.();
    }

    dispatcher({
      type: ActiveRecordActionType.CloseActiveRecord,
      recordKey,
    });

    if (useConfirmationOnClose) {
      childKeysRef.current = childKeysRef.current.filter((keys) => keys !== recordKey);
    }
  };

  const updateActiveRecord = (
    recordKey: string,
    newRecordKey: string,
    newIcon?: string,
    newDetails?: string | JSX.Element,
    newFullScreen?: boolean,
    newExtraSettings?: unknown,
    forceRender?: boolean
  ) => {
    dispatcher({
      type: ActiveRecordActionType.UpdateActiveRecord,
      recordKey: recordKey,
      newRecordSetting: {
        key: newRecordKey,
        renderKey: forceRender ? v4() : undefined, // when set, explicitly triggers the render of this item
        icon: newIcon,
        details: newDetails,
        extraSettings: newExtraSettings,
      },
      currentFullScreen: newFullScreen || false,
    });

    if (useConfirmationOnClose) {
      const index = childKeysRef.current?.indexOf(recordKey);
      if (index !== -1) {
        childKeysRef.current[index] = newRecordKey;
      }
    }
  };

  function setActiveRecordPanelFrozen(frozen: boolean) {
    dispatcher({ type: ActiveRecordActionType.SetActiveRecordPanelFrozen, frozen });
  }

  const currentRecordCount = () => {
    return state.childSettings.length;
  };

  const isFullScreen = () => {
    return state.currentFullScreen;
  };

  useImperativeHandle(ref, () => ({
    addActiveRecord,
    updateActiveRecord,
    currentRecordCount,
    setActiveRecordPanelFrozen,
    setItemDirty,
    clearItemDirty,
    setItemSaveFailed,
    isFullScreen,
  }));

  const onActiveRecordMinimised = (recordKey: string) => {
    if (!state.minimiseActiveRecord) {
      return true;
    } else if (recordKey === state.activeRecordSetting?.key) {
      return false;
    }

    return true;
  };

  const onRecordClicked = (recordKey: string) => {
    dispatcher({
      type: ActiveRecordActionType.SetActiveRecord,
      recordKey,
      minimiseActiveRecord: onActiveRecordMinimised(recordKey),
    });
  };

  function closeAll() {
    dispatcher({
      type: ActiveRecordActionType.CloseAllRecords,
    });
    onClose?.();

    if (useConfirmationOnClose) {
      childKeysRef.current = [];
    }
  }

  const onCloseAllClick = () => {
    if (useConfirmationOnClose && hasDirtyItems()) {
      if (!settingsSubject.current.value.batchSaveInProgress) {
        onCloseActiveRecordConfirmationModalRef.current
          ?.show()
          ?.pipe(take(1), takeUntil(destroySubject.current))
          .subscribe((result) => {
            if (result.buttonName === 'save') {
              showBatchSaveInProgress();
            }
            // the 'discard' is handled in each component, this is to ensure the close is done correctly
          });
      }
    } else {
      closeAll();
    }
  };

  const renderActiveRecordsNav = () => {
    return state.childSettings.map((childSetting, index) => {
      // record-navigator is used for Feature Tours
      const recordStyles = cx('cursor-pointer h-10 border-b border-gray-2 record-navigator', {
        'bg-primary-2 text-mono-1': state.activeRecordSetting?.key === childSetting.key,
        'border-t mt-2': index === 0,
      });

      const displayText = (childSetting.extraSettings as Record<string, unknown>)['displayText'] as string;
      const recordInfo =
        !displayText || displayText === 'n/a' ? (
          childSetting.details
        ) : (
          <p className="truncate" title={displayText}>
            {displayText}
          </p>
        );
      const RecordInfo = () => <div className="flex-1 truncate text-xs min-w-0">{recordInfo}</div>;

      return (
        <li
          key={`panel_item_${childSetting.key}`}
          className={recordStyles}
          onClick={() => onRecordClicked(childSetting.key)}
        >
          <a className="flex items-center justify-start">
            <Tooltip
              arrow={true}
              description={<RecordInfo />}
              tooltipAlignment={DomElementAlignment.TopRight}
              tooltipTargetPosition={DomTargetPosition.Left}
              tooltipClassName={'h-10 flex justify-start items-center bg-gray-1 text-mono-0'}
              disabled={!state.displayCollapsed}
            >
              <OdinIcon
                className="text-inherit m-3 shrink"
                size={OdinIconSize.ExtraSmall}
                icon={iconMap[childSetting.icon] || 'Survey'}
              />
            </Tooltip>
            {!state.displayCollapsed && (
              <>
                <RecordInfo />
                <IconButton
                  classNames="mx-2"
                  onClick={(event) => {
                    closeActiveRecord(childSetting.key);
                    event.stopPropagation();
                  }}
                >
                  <OdinIcon icon="Close" size={OdinIconSize.ExtraSmall} />
                </IconButton>
              </>
            )}
          </a>
        </li>
      );
    });
  };

  const renderActiveRecords = () => {
    return state.childSettings.map((childSetting) => {
      const panelCloseActiveRecord = (id: string) => {
        closeActiveRecord(childSetting.key);
        clearItemDirty(id);
      };
      const active = childSetting.key === state.activeRecordSetting?.key;

      return (
        <ActiveRecordPanel
          key={`panel_${childSetting.renderKey}`}
          opened={state.minimiseActiveRecord && active}
          fullScreen={state.currentFullScreen}
          isNavCollapsed={state.displayCollapsed}
          recordWidth={recordWidth}
          defaultRecordWidth={defaultRecordWidth}
          onPanelInfoChanged={onRecordPanelChanged}
          hidden={hidden}
        >
          {children({
            onCloseActiveRecord: panelCloseActiveRecord,
            settingsSubject: settingsSubject.current,
            extraSettings: childSetting.extraSettings,
            onCloseActiveRecordConfirmationModalRef: onCloseActiveRecordConfirmationModalRef.current,
          })}
        </ActiveRecordPanel>
      );
    });
  };

  const navigationStyles = cx(
    'flex flex-col fixed pt-16 z-40 lg:max-h-screen bg-gray-4 transition-all top-0 right-0 bottom-0 shadow-left-2',
    {
      'w-10': state.displayCollapsed,
      'w-full sm:!w-80': !state.displayCollapsed,
    }
  );

  // expand-navigator is used for Feature Tours
  const expandButtonStyles = cx(
    'fixed w-4 z-50 top-4 border-2 border-transparent !bg-gray-4 rounded-l shadow-left-2 focus:outline-none transition-all expand-navigator',
    {
      'right-10': state.displayCollapsed,
      'max-sm:left-0 sm:right-80': !state.displayCollapsed,
    }
  );

  const panelFrozenStyles = cx('absolute top-0 right-0 z-[9999] h-screen w-full bg-gray-5 opacity-50', {
    invisible: !state.frozen,
  });

  const getPanelWidth = () => {
    return state.currentFullScreen ? '100%' : `${state.recordWidth}px`;
  };

  const canRenderActiveRecords =
    state.childSettings.length > 0 &&
    (!useConfirmationOnClose || (useConfirmationOnClose && onCloseActiveRecordConfirmationModalRef.current));

  return (
    <>
      {canRenderActiveRecords ? (
        <div hidden={hidden}>
          <div style={{ width: getPanelWidth() }} className={panelFrozenStyles} />
          <IconButton
            classNames={expandButtonStyles}
            onClick={() => {
              dispatcher({
                type: ActiveRecordActionType.SetDisplayCollapsed,
                displayCollapsed: !state.displayCollapsed,
              });
            }}
          >
            <OdinIcon
              icon={state.displayCollapsed ? 'ArrowLeftS' : 'ArrowRightS'}
              showFocus={false}
              className="relative -left-1.5"
            />
          </IconButton>
          <div className={navigationStyles}>
            <span
              className="absolute left-2 right-3 top-5 cursor-pointer whitespace-nowrap text-right text-sm leading-7 text-primary-2 close-all-button" //close-all-button is used for Feature Tours
              onClick={onCloseAllClick}
              ref={tagCreator('close-all-icon')}
            >
              {state.displayCollapsed ? (
                <Tooltip
                  description={t('close-all')}
                  debounceTime={200}
                  tooltipClassName="bg-mono-1"
                  tooltipAlignment={DomElementAlignment.TopRight}
                >
                  <OdinIcon size={OdinIconSize.Medium} icon="Close" className="relative -left-1 w-8" />
                </Tooltip>
              ) : (
                <span className="underline">Close All</span>
              )}
            </span>
            <ul className="custom-scroll grow list-none overflow-y-auto">{renderActiveRecordsNav()}</ul>
          </div>
          {renderActiveRecords()}
        </div>
      ) : null}
      {useConfirmationOnClose && (
        <ModalDialog
          ref={onCloseActiveRecordConfirmationModalRef}
          header={t('records-unsaved-changes')}
          buttons={confirmationDialogButtons}
        >
          {t('save-message')}
        </ModalDialog>
      )}
    </>
  );
}

export default forwardRef(ActiveRecord);
