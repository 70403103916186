import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { LoginCallback, LogoutCallback, LoginIdp } from '@myosh/myosh-login';
import { usePageTracking } from './hooks/use-page-tracking';
import { useServiceWorker } from './hooks/use-service-worker';
import LazyLoadedPage from './components/layout/routes/lazy-loaded-page.component';
import HomePage from './pages/home.page';
import DynamicPageRoute from './components/layout/routes/dynamic-page-route.component';
import PrivateNavigationAccessRoute from './components/layout/routes/private-navigation-access-route.component';
import PrivateLayoutRoute from './components/layout/routes/private-layout-route.component';
import LoginPage from './pages/login.page';
import DefaultRedirectRoute from './components/layout/routes/default-redirect-route.component';
import ActivitiesPage from './pages/activities.page';
import RiskMatrixNavigatorRoute from './components/layout/routes/risk-matrix-navigator-route.component';
import SimpleRecordView from './pages/SimpleRecordView.page';
import VisitorSignInPage from './pages/visitor-sign-in.page';
import VisitorSignOutPage from './pages/visitor-sign-out.page';

const AdminAccessRoute = lazy(() => import('./components/layout/routes/admin-access-route.component'));
const UserNavigatorRoute = lazy(() => import('./components/layout/routes/user-navigator-route.component'));
const ModuleNavigatorRoute = lazy(() => import('./components/layout/routes/module-navigator-route.component'));
const CompetencyGroupNavigatorRoute = lazy(
  () => import('./components/layout/routes/competency-group-navigator-route.component')
);
const CompetencyNavigatorRoute = lazy(() => import('./components/layout/routes/competency-navigator-route.component'));
const OccupationNavigatorRoute = lazy(() => import('./components/layout/routes/occupation-navigator-route.component'));
const GroupNavigatorRoute = lazy(() => import('./components/layout/routes/group-navigator-route.component'));
const HierarchyNavigatorRoute = lazy(() => import('./components/layout/routes/hierarchy-navigator-route.component'));
const OccupationalRequirementsNavigatorRoute = lazy(
  () => import('./components/layout/routes/occupational-requirement-navigator-route.component')
);
const SchedulerNavigatorRoute = lazy(() => import('./components/layout/routes/scheduler-navigator-route.component'));
const RuleNavigatorRoute = lazy(() => import('./components/layout/routes/rule-navigator-route.component'));
const NotificationNavigatorRoute = lazy(
  () => import('./components/layout/routes/notification-navigator-route.component')
);
const EmbeddedPageRoute = lazy(() => import('./components/layout/routes/embedded-page-route.component'));
const VikingAnalyticsRoute = lazy(() => import('./components/layout/routes/viking-analytics-route.component'));
const DashboardEmbeddedPage = lazy(() => import('./pages/dashboard-embedded.page'));

function App() {
  usePageTracking();
  useServiceWorker();

  return (
    <>
      <Routes>
        <Route path="/auth/cb/logout" element={<LogoutCallback />} />
        <Route path="/auth/cb/silent" element={<div>Silent renewal callback</div>} />
        <Route path="/auth/cb" element={<LoginCallback />} />
        <Route path="/login">
          <Route path=":idp" element={<LoginIdp redirectPath="/" />} />
          <Route path="" element={<LoginPage />} />
        </Route>
        <Route path="*" element={null} />
      </Routes>
      <Routes>
        <Route path="/" element={<PrivateLayoutRoute />}>
          <Route index element={<DefaultRedirectRoute />} />
          <Route element={<PrivateNavigationAccessRoute />}>
            <Route path="home" element={<HomePage />} />
            <Route path="dashboard" element={<LazyLoadedPage page={<DashboardEmbeddedPage />} />} />
            <Route path="viking-analytics" element={<LazyLoadedPage page={<VikingAnalyticsRoute />} />} />
          </Route>
          <Route path="embedded/:slug" element={<LazyLoadedPage page={<EmbeddedPageRoute />} />} />
          <Route path=":schema">
            <Route element={<PrivateNavigationAccessRoute />}>
              <Route path="my-activities" element={<ActivitiesPage />} />
            </Route>
            <Route path="modules/:viewId/forms?/:formId?/*" element={<DynamicPageRoute />} />
            <Route path="administration" element={<LazyLoadedPage page={<AdminAccessRoute />} />}>
              <Route path="users/:id" element={<LazyLoadedPage page={<UserNavigatorRoute />} />} />
              <Route path="modules/:id" element={<LazyLoadedPage page={<ModuleNavigatorRoute />} />} />
              <Route path="occupations/:id" element={<LazyLoadedPage page={<OccupationNavigatorRoute />} />} />
              <Route path="risk-matrix/:id" element={<LazyLoadedPage page={<RiskMatrixNavigatorRoute />} />} />
              <Route path="groups/:id" element={<LazyLoadedPage page={<GroupNavigatorRoute />} />} />
              <Route path="hierarchies/:id" element={<LazyLoadedPage page={<HierarchyNavigatorRoute />} />} />
              <Route path="rules/:id" element={<LazyLoadedPage page={<RuleNavigatorRoute />} />} />
              <Route path="schedulers/:id" element={<LazyLoadedPage page={<SchedulerNavigatorRoute />} />} />
              <Route path="notifications/:id" element={<LazyLoadedPage page={<NotificationNavigatorRoute />} />} />
              <Route
                path="training-management/competency-group/:id"
                element={<LazyLoadedPage page={<CompetencyGroupNavigatorRoute />} />}
              />
              <Route
                path="training-management/competency/:id"
                element={<LazyLoadedPage page={<CompetencyNavigatorRoute />} />}
              />
              <Route
                path="training-management/occupational-requirement/:id"
                element={<OccupationalRequirementsNavigatorRoute />}
              />
            </Route>
          </Route>
        </Route>
        <Route path="visitor-sign-in" element={<VisitorSignInPage />} />
        <Route path="visitor-sign-out" element={<VisitorSignOutPage />} />
        <Route path=":schema/modules/:viewId/forms/:formId/basic-record/:recordId?" element={<SimpleRecordView />} />
        <Route path="*" element={null} />
      </Routes>
    </>
  );
}

export default React.memo(App);
