import React from 'react';
import { DynamicFieldComponentProps, OdinController } from '@myosh/odin-components';
import { Control } from 'react-hook-form';
import NotificationTargetField from './notification-target-field';

interface HfNotificationTargetFieldProps extends DynamicFieldComponentProps<string> {
  control: Control;
}

export default function HfNotificationTargetField({
  control,
  name = 'notificationType',
  label,
  readOnly,
  required,
  onChange,
}: HfNotificationTargetFieldProps) {
  return (
    <OdinController
      name={name}
      control={control}
      onChange={onChange}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <NotificationTargetField
          name={name}
          label={label}
          value={value}
          error={error?.message}
          readOnly={readOnly}
          onChange={onChange}
          required={required}
        />
      )}
    />
  );
}
