import { DropDownResult, HfDynamicFieldComponentProps, OdinController } from '@myosh/odin-components';
import React from 'react';
import MultiselectPersonField from './multiselect-person-field.component';
import { LookupTypeFieldProps } from '../../../@types/forms';

interface HfMultiselectPersonFieldProps extends HfDynamicFieldComponentProps<DropDownResult[]>, LookupTypeFieldProps {
  shouldUseCreatePortal?: boolean;
}

export default function HfMultiselectPersonField(props: HfMultiselectPersonFieldProps) {
  const { name, control, value, onChange, shouldUseCreatePortal = false, ...rest } = props;
  return (
    <OdinController
      name={name as string}
      control={control}
      defaultValue={value}
      onChange={onChange}
      render={({ field: { ref: fieldRef, onChange, value }, fieldState: { error } }) => (
        <MultiselectPersonField
          {...rest}
          control={control}
          value={value}
          onChange={onChange}
          shouldUseCreatePortal={shouldUseCreatePortal}
          error={error?.message}
          inputRef={fieldRef}
        />
      )}
    />
  );
}
