import React from 'react';
import Record, { RecordSaveState } from '../components/record/record.component';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormSettingsQuery } from '../redux/services/api';

function SimpleRecordView() {
  const { formId, recordId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: formSettingsData } = useFormSettingsQuery(parseInt(formId ?? ''), {
    skip: formId === undefined || isNaN(parseInt(formId)),
  });

  const getHideSectionsParam = () => {
    const searchParams = new URLSearchParams(document.location.search);
    return searchParams.get('hideSections') === 'true';
  };

  const onRecordCreated = (id: string) => {
    if (!recordId) {
      const path = getHideSectionsParam() ? `${id}?hideSections=true` : id;
      navigate(path);
    }
  };

  const recordState = recordId ? RecordSaveState.Update : RecordSaveState.New;
  const recordTitle =
    recordState === RecordSaveState.New ? t('form-new-record', { formName: formSettingsData?.caption }) : undefined;
  const canRenderRecord = formId && !isNaN(parseInt(formId)) && formSettingsData;

  return (
    <div className="h-full w-full p-8 bg-gray-5">
      <div className="flex flex-col h-full max-w-screen-lg m-auto overflow-y-auto">
        {canRenderRecord && (
          <Record
            formId={parseInt(formId)}
            recordId={recordId}
            recordState={recordState}
            title={recordTitle}
            onClose={() => {}}
            showFormGroupsNavigation={!getHideSectionsParam()}
            onSimpleViewRecordCreated={onRecordCreated}
            isSimpleView
          />
        )}
      </div>
    </div>
  );
}

export default SimpleRecordView;
