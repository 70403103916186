import { useState, useEffect } from 'react';
import { useGetRecordPermissionsQuery } from '../redux/services/record';
import { FormPermissionsResults } from '../@types/form-permissions';

/**
 * A hook that provides record permissions for logged-in user.
 * @param {number} recordId
 * @returns {{isLoading: boolean, recordPermissions: FormPermissionsResults | undefined}}
 */
const useRecordPermissions = (recordId?: number) => {
  const [recordPermissions, setRecordPermissions] = useState<FormPermissionsResults>();

  const {
    data: recordPermissionsResponse,
    isLoading: isRecordLoading,
    isFetching: isRecordFetching,
    refetch: refetchRecordPermissions,
  } = useGetRecordPermissionsQuery(
    {
      recordId: recordId,
    },
    { skip: recordId === undefined }
  );

  useEffect(() => {
    if (recordPermissionsResponse && !isRecordLoading && !isRecordFetching) {
      const permissionResults: FormPermissionsResults = {
        id: recordPermissionsResponse.formId,
        formCaption: recordPermissionsResponse.formCaption,
        moduleId: recordPermissionsResponse.moduleId,
        canCreateRecord: recordPermissionsResponse.canCreateRecord,
        canReadRecord: recordPermissionsResponse.canReadRecord,
        canEditRecord: recordPermissionsResponse.canEditRecord,
        canDeleteRecord: recordPermissionsResponse.canDeleteRecord,
        canImportRecord: recordPermissionsResponse.canImportRecord,
        canExportRecord: recordPermissionsResponse.canExportRecord,
        canRecurrenceRecord: recordPermissionsResponse.canRecurrenceRecord,
        canViewRecordAudit: recordPermissionsResponse.canViewRecordAudit,
        canShowNewRecordButton: recordPermissionsResponse.canShowNewRecordButton,
        canProgressRecord: recordPermissionsResponse.canProgressRecord,
      };
      setRecordPermissions(permissionResults);
    }
  }, [recordPermissionsResponse]);

  return {
    recordPermissions,
    isLoading: isRecordLoading || isRecordFetching,
    refetchRecordPermissions,
  };
};

export default useRecordPermissions;
