import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuthAndUrlEncoding } from './api';
import {
  GroupAuditLogData,
  GroupAuditLogResponse,
  GroupItem,
  GroupStructure,
  GroupWraper,
  GroupsResponse,
  GroupApiProps,
} from '../../@types/groups';
import { createGroupData } from '../../common/group-functions';
import { createCustomSortWithOrderBy, createFieldFilterText } from '../../common/common-functions';

export const groupApi = createApi({
  reducerPath: 'group',
  baseQuery: baseQueryWithReAuthAndUrlEncoding,
  tagTypes: ['Group', 'GroupAuditLog'],
  endpoints: (builder) => ({
    getGroups: builder.query<Array<GroupStructure>, GroupApiProps>({
      query: (args) =>
        `/groups/simple?externalUserSignupRoles=${args.externalUserSignupRoles}${createFieldFilterText({
          filters: args.filters,
        })}${createCustomSortWithOrderBy(args.sortedFields)}`,
      transformResponse: (response: GroupsResponse) => response?.items?.map((item) => createGroupData(item)) || [],
      providesTags: (result) =>
        result
          ? [
              ...result.map((item) => ({ type: 'Group' as const, id: item.original.id })),
              { type: 'Group' as const, id: 'LIST' },
            ]
          : [{ type: 'Group' as const, id: 'LIST' }],
    }),
    getGroupById: builder.query<GroupStructure, unknown>({
      query: (id: string) => `/groups/simple/${id}`,
      transformResponse: (response: GroupWraper) => createGroupData(response.result),
      providesTags: (result) => (result ? [{ type: 'Group' as const, id: result.original.id }] : ['Group']),
    }),
    updateGroup: builder.mutation<void, Pick<GroupItem, 'id'> & Partial<GroupItem>>({
      query: ({ id, ...patch }) => ({
        url: `/groups/simple/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result) {
          return [
            { type: 'Group', id: arg.id },
            { type: 'GroupAuditLog', id: `LIST-${arg.id}` },
          ];
        } else {
          return [];
        }
      },
    }),
    getGroupAuditLog: builder.query<Array<GroupAuditLogData>, string>({
      query: (id: string) => `/groups/${id}/audit-log`,
      transformResponse: (response: GroupAuditLogResponse) => response.result ?? [],
      providesTags: (result, _error, id) =>
        result
          ? [
              ...result.map((item) => ({ type: 'GroupAuditLog' as const, id: `${id}-${item.id}` })),
              { type: 'GroupAuditLog' as const, id: `LIST-${id}` },
            ]
          : [{ type: 'GroupAuditLog' as const, id: `LIST-${id}` }],
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useGetGroupByIdQuery,
  useLazyGetGroupByIdQuery,
  useUpdateGroupMutation,
  useLazyGetGroupAuditLogQuery,
} = groupApi;
