import React, { useEffect, useState } from 'react';
import { DropDownResult, DynamicFieldComponentProps } from '@myosh/odin-components';
import cx from 'classnames';
import { Control, FieldError } from 'react-hook-form';
import { ExtendedDynamicFormHierarchySettings, forceAssert } from '../../../common/common-functions';
import { HierarchyType, TransformedFormHierarchyTypes } from '../../../@types/hierarchy-fields';
import HierarchyFieldAdmin from './hierarchy-field-admin.component';

type RecordHierarchyFields = TransformedFormHierarchyTypes | HierarchyType;

export interface HierarchyFieldGroupAdminProps
  extends DynamicFieldComponentProps<Record<string, Array<DropDownResult>>> {
  hierarchySettings?: ExtendedDynamicFormHierarchySettings;
  hierarchyTypes?: Array<TransformedFormHierarchyTypes | HierarchyType>;
  errorObject?: FieldError;
  control?: Control;
}

export function HierarchyFieldGroupAdmin({
  errorObject,
  value = {},
  hierarchyTypes,
  readOnly,
  onChange,
  hierarchySettings,
}: HierarchyFieldGroupAdminProps) {
  const [hierarchyFieldsState, setHierarchyFieldsState] = useState<Array<RecordHierarchyFields>>();

  useEffect(() => {
    if (hierarchyTypes?.length) {
      setHierarchyFieldsState(forceAssert(hierarchyTypes));
    }
  }, [hierarchyTypes]);

  const hierarchyStyles = cx({ hidden: hierarchySettings?.hiddenInReadMode && readOnly });

  return (
    <>
      <div className={hierarchyStyles}>
        {hierarchyFieldsState?.map((hierarchyField) => {
          return (
            <HierarchyFieldAdmin
              key={hierarchyField.id}
              value={value}
              hierarchyField={hierarchyField}
              readOnly={readOnly}
              errorObject={errorObject}
              onChange={onChange}
            />
          );
        })}
      </div>
    </>
  );
}
