import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { throttle } from 'lodash';
import { IconButton, OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';
import { useTestTag } from '@myosh/odin-components';
import { MIN_ALLOWED_PANEL_WIDTH, getDefaultRecordWidth } from '../../../active-record/active-record-helper';

interface RecordPreviewPanelProps {
  fullScreen?: boolean;
  recordWidth?: number;
  children: React.ReactNode | React.ReactNode[];
}

export default function RecordPreviewPanel({
  fullScreen = false,
  recordWidth = getDefaultRecordWidth(),
  children,
}: RecordPreviewPanelProps) {
  const [currentFullScreen, setCurrentFullScreen] = useState(fullScreen);
  const [currentRecordWidth, setCurrentRecordWidth] = useState(recordWidth);

  useEffect(() => {
    if (fullScreen !== undefined) {
      setCurrentFullScreen(fullScreen);
    }
  }, [fullScreen]);

  const tagCreator = useTestTag('record-preview');

  const toggleFullScreen = () => {
    const newFullScreen = !currentFullScreen;
    setCurrentFullScreen(newFullScreen);
  };

  const resizeStart = throttle((event) => {
    const windowWidth = window.innerWidth;
    const cursorOffset = event.x;
    const panelWidth = Math.max(MIN_ALLOWED_PANEL_WIDTH, Math.min(windowWidth - cursorOffset, windowWidth));
    setCurrentRecordWidth(panelWidth);
  }, 50);

  const resizeEnd = () => {
    document.removeEventListener('mousemove', resizeStart);
    document.removeEventListener('mouseup', resizeEnd);
  };

  const resizeHandler = () => {
    document.addEventListener('mousemove', resizeStart);
    document.addEventListener('mouseup', resizeEnd);
  };

  const panelStyles = cx(
    'fixed z-20 flex flex-col top-0 bottom-0 right-0 shadow-left-1 bg-gray-5 max-w-full transition-all',
    {
      'w-full': currentFullScreen,
      [`max-sm:w-full`]: !currentFullScreen,
    }
  );

  const gePanelInlineStyles = useCallback(() => {
    return currentFullScreen ? {} : { width: `${currentRecordWidth}px` };
  }, [currentRecordWidth]);

  return (
    <div className={panelStyles} style={gePanelInlineStyles()} ref={tagCreator('toggle-screen-icon')}>
      <div className="max-sm:hidden sm:contents">
        <IconButton classNames="absolute p-1 bg-gray-4 rounded-br-lg" onClick={() => toggleFullScreen()}>
          <OdinIcon
            icon={currentFullScreen ? 'FullscreenExit' : 'Fullscreen'}
            size={OdinIconSize.ExtraSmall}
            type={OdinIconType.Line}
          />
        </IconButton>
      </div>
      <div
        className="absolute bottom-0 left-0 top-0 w-1 cursor-col-resize select-none max-sm:hidden"
        onMouseDown={() => resizeHandler()}
      />
      <div className="flex w-full flex-grow flex-col overflow-y-auto pl-10 pr-6 pt-5">{children}</div>
    </div>
  );
}
