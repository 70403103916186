import { AuthService } from '@myosh/myosh-login';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  settingsToDynamicForm,
  ExtendedDynamicFormSettings,
  transformNewsEntries,
  processWorkflowSteps,
  ExtendedDynamicFormWorkflowStep,
  transformModuleForms,
  createFieldFilterText,
  createFieldFilterTextAsFirstQueryParameter,
  transformModules,
} from '../../common/common-functions';
import {
  DynamicFormSettings,
  DynamicFormSettingsWrapper,
  GetWorkflowStepsResponse,
  VisitorSignInFormResponse,
} from '../../@types/forms';
import {
  FormAccessControlResponse,
  FormAccessControlResult,
  FormPermissionsResponse,
  FormPermissionsResults,
} from '../../@types/form-permissions';
import { LocaleItem, LocalesResponse, MetaDataResponse, ResultValidationResponse } from '../../@types/common';
import {
  Module,
  ModuleApiProps,
  ModuleForms,
  ModuleGroup,
  ModuleGroups,
  Modules,
  ModuleWorkflowStepsReponse,
} from '../../@types/modules';
import { NewsResult } from '../../@types/news';
import toast from 'react-hot-toast';
import { HomePageSummary, HomePageSummaryResponse } from '../../@types/home-page-data';
import i18next from '../../i18n';
import {
  NewsApiProps,
  NewsEntryProps,
  NewsItemDismissed,
  NewsItemSeen,
} from '../../components/home-page/news-entry.component';
import { isObject, isString } from 'lodash';
import { NavigationItem, NavigationItemsApiResponse } from '../../@types/navigation';
import { log } from '../../common/log-util';
import { LicencesInfo, LicencesWrapper } from '../../@types/licences';
import { SchemaDetails, SchemaDetailsResponse, SchemaInfo } from '../../@types/schema';
import { USER_TIME_ZONE } from '../../common/date-util';
import { FeatureTourResponse, FeatureTours } from '../../@types/feature-tours';

const notifyUserAndLogout = (id: string, message: string) => {
  toast.error(message, { id, duration: 3000 }); // id prevents duplicate toasts

  const authService = new AuthService();
  setTimeout(() => authService.logout(), 3200);
};

const urlEncodeParams = (url: string) => {
  const delimeter = url.indexOf('?');
  if (delimeter !== -1) {
    const baseUrl = url.substring(0, delimeter);
    const queryString = encodeURI(url.substring(delimeter + 1) ?? '');

    return `${baseUrl}?${queryString}`;
  }

  return url;
};

const baseQuery = (apiUrl: string, apiKey: string) =>
  fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: (headers) => {
      if (localStorage.getItem('UserState')) {
        const userState = JSON.parse(localStorage.getItem('UserState') || '{}');
        const schema = userState?.user?.currentSchema;
        const idToken = userState?.tokens?.id_token;
        const superUser = userState?.user?.superuser === 'true';
        const controlledUsername = userState?.user?.controlledUsername;

        if (idToken) {
          headers.set('authorization', `Bearer ${idToken}`);
        }
        if (schema) {
          headers.set('Myosh-Schema', schema);
        }
        if (superUser && controlledUsername) {
          headers.set('Controlled-Username', controlledUsername);
        }
      }
      headers.set('x-api-key', apiKey);
      headers.set('Myosh-TimeZone', USER_TIME_ZONE);

      return headers;
    },
  });

export const baseQueryWithReAuthAndUrlEncoding: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const clusterConfig = JSON.parse(localStorage.getItem('ClusterConfig') || '{}');
  if (clusterConfig.apiBaseUrl && clusterConfig.apiKey) {
    const apiUrl = `${clusterConfig.apiBaseUrl}v4/`;
    const apiKey = clusterConfig.apiKey;

    let encodedUrl;
    if (isObject(args) && args.url) {
      encodedUrl = { ...args, url: urlEncodeParams(args.url) };
    } else if (isString(args)) {
      encodedUrl = urlEncodeParams(args);
    } else {
      encodedUrl = args;
    }

    let result = await baseQuery(apiUrl, apiKey)(encodedUrl, api, extraOptions);

    // handle re-auth with refreshToken
    if (result.error) {
      log(result.error); // log error to the API

      if (result.error.status === 401) {
        const authService = new AuthService();
        const refreshAuth = await authService.signinSilent();
        if (refreshAuth.refresh_token) {
          const userState = JSON.parse(localStorage.getItem('UserState') || '{}');
          userState.tokens = {
            ...userState.tokens,
            id_token: refreshAuth.id_token,
            access_token: refreshAuth.access_token,
            refresh_token: refreshAuth.refresh_token,
            expires_at: refreshAuth.expires_at,
            auth_time: Date.now(),
          };
          localStorage.setItem('UserState', JSON.stringify(userState));
          result = await baseQuery(apiUrl, apiKey)(args, api, extraOptions);
        } else {
          notifyUserAndLogout('401', i18next.t('error-401'));
        }
      }
    }

    return result;
  } else {
    log({ message: 'The request cannot be served as the cluster configuration information is missing' });
    notifyUserAndLogout('400', i18next.t('error-loading-configuration'));

    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'Missing application configuration',
      },
    };
  }
};

export const providesList = <R extends { id: string | number }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T
) => {
  return resultsWithIds
    ? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
    : [{ type: tagType, id: 'LIST' }];
};

export const api = createApi({
  baseQuery: baseQueryWithReAuthAndUrlEncoding,
  tagTypes: ['Theme', 'News', 'Licenses', 'FeatureTours'],
  endpoints: (builder) => ({
    modules: builder.query<Array<Module>, { includeFormList?: boolean }>({
      query: (args) => {
        let modulesUrl = '/modules/simple';
        if (args.includeFormList) {
          modulesUrl += '?includeFormList=true';
        }
        return modulesUrl;
      },
      // API returns items sorted by module group AND position. FE should rely on that order
      transformResponse: (modules: Modules) => transformModules(modules),
    }),
    getModuleById: builder.query<Module, ModuleApiProps>({
      query: ({ moduleId }) => `/modules/simple/${moduleId}`,
      transformResponse: (module: ModuleForms) => module.result,
    }),
    moduleWorkflowSteps: builder.query<Array<string>, ModuleApiProps>({
      query: ({ moduleId, filters }) =>
        `/modules/${moduleId}/workflow-steps${createFieldFilterTextAsFirstQueryParameter(filters, true)}`,
      transformResponse: (response: ModuleWorkflowStepsReponse) => response.result ?? [],
    }),
    moduleForms: builder.query<ModuleForms, ModuleApiProps>({
      query: (args: ModuleApiProps) =>
        `/modules/simple/${args.moduleId}?${createFieldFilterText({ filters: args.filters, isFirstParam: true })}`,
      transformResponse: (response: ModuleForms) => transformModuleForms(response),
    }),
    modulesInModuleGroups: builder.query<Array<Module>, void>({
      query: () => `/module-groups/simple`,
      transformResponse: (moduleGroups: ModuleGroups) =>
        moduleGroups.items
          .sort((moduleGroup1, moduleGroup2) => moduleGroup1.position - moduleGroup2.position)
          .map((moduleGroup) => {
            moduleGroup.modules?.sort((module1, module2) => module1.position - module2.position);
            return moduleGroup;
          })
          .map((moduleGroup) => moduleGroup.modules || [])
          .flat(),
    }),
    moduleGroups: builder.query<Array<ModuleGroup>, void>({
      query: () => `/module-groups/simple`,
      transformResponse: (moduleGroups: ModuleGroups) => moduleGroups.items,
    }),
    formPermissions: builder.query<FormPermissionsResults, number>({
      query: (formId) => `forms/${formId}/permissions`,
      transformResponse: (response: FormPermissionsResponse) => {
        return response.result;
      },
    }),
    formSettings: builder.query<ExtendedDynamicFormSettings, number>({
      query: (formId: number) => `/forms/${formId}`,
      transformResponse: (response: DynamicFormSettingsWrapper) => {
        return settingsToDynamicForm(response.result);
      },
    }),
    formAccessControl: builder.query<FormAccessControlResult, number>({
      query: (formId: number) => `forms/${formId}/access-control`,
      transformResponse: (response: FormAccessControlResponse) => response.result,
    }),
    getLocales: builder.query<Array<LocaleItem>, void>({
      query: () => `/locales`,
      transformResponse: (response: LocalesResponse) => response?.items || [],
    }),
    getTheme: builder.query<Record<string, string>, void>({
      query: () => `/theme`,
      providesTags: ['Theme'],
    }),
    updateTheme: builder.mutation<void, string>({
      query: (theme) => ({
        url: `/theme?companyTheme=${theme}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Theme'],
    }),
    getNews: builder.query<Array<NewsEntryProps>, NewsApiProps>({
      query: ({ dismissed, type, markAsSeen, publishState }: NewsApiProps) => {
        let newsUrl = '/news';
        if (dismissed !== undefined) {
          newsUrl += `?filter=dismissed:eq:${dismissed}`;
        }
        if (type) {
          newsUrl += `&filter=type:eq:${type}`;
        }
        if (markAsSeen !== undefined) {
          newsUrl += `&markAsSeen=${markAsSeen}`;
        }
        if (publishState) {
          newsUrl += `&filter=publishState:eq:${publishState}`;
        }
        return newsUrl;
      },
      transformResponse: (response: NewsResult) => {
        return transformNewsEntries(response);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ impressionId, id }) => ({ type: 'News' as const, id: impressionId ?? id })),
              { type: 'News', id: 'LIST' },
            ]
          : [{ type: 'News', id: 'LIST' }],
    }),
    getWorkflowSteps: builder.query<Array<ExtendedDynamicFormWorkflowStep>, number>({
      query: (formId) => `/forms/${formId}/workflow-steps?filter=archived:eq:false`,
      transformResponse: (response: GetWorkflowStepsResponse) => {
        return processWorkflowSteps(response.result);
      },
    }),
    getLicences: builder.query<LicencesInfo, void>({
      query: () => '/licences',
      transformResponse: (response: LicencesWrapper) => {
        return response.result;
      },
      providesTags: ['Licenses'],
    }),
    updateLicences: builder.mutation<void, Partial<LicencesInfo>>({
      query: (args) => ({
        url: '/licences',
        method: 'PATCH',
        body: args,
      }),
      invalidatesTags: ['Licenses'],
    }),
    getHomePageSummary: builder.query<HomePageSummary, { ignoreUserHierarchyRestrictions?: boolean }>({
      query: (args) => {
        let homePageUrl = '/homepage/summary';
        if (args.ignoreUserHierarchyRestrictions) {
          homePageUrl += '?ignoreUserHierarchyRestrictions=true';
        }
        return homePageUrl;
      },
      transformResponse: (response: HomePageSummaryResponse) => {
        return response.result;
      },
    }),
    getNavigationItems: builder.query<Array<NavigationItem>, void>({
      query: () => '/navigation-items',
      transformResponse: (response: NavigationItemsApiResponse) => response.result ?? [],
    }),
    getSchemaDetails: builder.query<Array<SchemaDetails>, void>({
      query: () => '/schemas/details',
      transformResponse: (response: SchemaDetailsResponse) => {
        return response.result ?? [];
      },
    }),
    getCurrentSchemaInfo: builder.query<SchemaInfo, void>({
      query: () => '/schemas/current',
    }),
    updateNewsItemDismissed: builder.mutation<number, NewsItemDismissed>({
      query: ({ id, dismissed }) => ({
        url: `/news/news-item-impression/${id}`,
        method: 'PUT',
        body: { dismissed },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'News', id: arg.id }],
    }),
    updateNewsItemSeen: builder.mutation<number, NewsItemSeen>({
      query: (args) => ({
        url: `/news/news-item-impression/`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'News', id: arg.newsItemId }],
    }),
    getVisitorForms: builder.query<Array<DynamicFormSettings>, void>({
      query: () => '/visitor-forms/simple',
      transformResponse: (response: VisitorSignInFormResponse) => response.result,
    }),
    getMetaData: builder.query<ResultValidationResponse<Array<MetaDataResponse>>, void>({
      query: () => '/metadata/simple',
    }),
    getFeatureTours: builder.query<FeatureTours, void>({
      query: () => '/feature-tours',
      providesTags: ['FeatureTours'],
      transformResponse: (response: FeatureTourResponse) => response.result ?? {},
    }),
    updateFeatureTours: builder.mutation<void, FeatureTours>({
      query: (args) => ({ url: '/feature-tours', method: 'POST', body: args }),
      invalidatesTags: ['FeatureTours'],
    }),
  }),
});

export const {
  useModulesQuery,
  useLazyModulesQuery,
  useGetModuleByIdQuery,
  useLazyGetModuleByIdQuery,
  useModuleWorkflowStepsQuery,
  useLazyModuleWorkflowStepsQuery,
  useModuleFormsQuery,
  useModulesInModuleGroupsQuery,
  useLazyModulesInModuleGroupsQuery,
  useModuleGroupsQuery,
  useFormPermissionsQuery,
  useFormSettingsQuery,
  useLazyFormSettingsQuery,
  useFormAccessControlQuery,
  useGetThemeQuery,
  useUpdateThemeMutation,
  useUpdateNewsItemSeenMutation,
  useUpdateNewsItemDismissedMutation,
  useGetNewsQuery,
  useGetWorkflowStepsQuery,
  useGetLicencesQuery,
  useGetNavigationItemsQuery,
  useGetHomePageSummaryQuery,
  useLazyGetSchemaDetailsQuery,
  useGetCurrentSchemaInfoQuery,
  useUpdateLicencesMutation,
  useGetVisitorFormsQuery,
  useGetMetaDataQuery,
  useGetFeatureToursQuery,
  useUpdateFeatureToursMutation,
} = api;
