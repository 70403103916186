import {
  ModalDialog,
  ModalDialogButtonSetting,
  fieldValidationErrorMessage,
  HfInput,
  HfRichTextArea,
  DropDownChip,
  DynamicFormRequiredType,
  ErrorLabel,
  Tooltip,
  HfCheckbox,
  FieldLabel,
  RequiredIndicator,
} from '@myosh/odin-components';

import React, { useState, useEffect, useCallback, useRef } from 'react';
import i18next from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import WrapperStyledButtonField from '../../common/wrapper-styled-button-field';
import EmailThisAddressModal from './email-this-address.component';
import { EmailAddressTemplate } from './email-this-address.component';
import { EmailThisButtonProps } from './email-this-button.component';
import { useSendEmailThisMutation } from '../../../redux/services/record';
import { dismiss, showError, showInfo, showSuccess } from '../../../services/notification.service';
import cx from 'classnames';

export interface EmailThisModalProps extends EmailThisButtonProps {
  fieldId?: number;
  visible?: boolean;
  showCloseIcon?: boolean;
  hidden: () => void;
}
//format sent to API
export interface EmailThisData {
  sendToEmailAddresses?: Array<string>;
  sendToUserIds?: Array<number>;
  sendToGroupIds?: Array<number>;
  ccMe: boolean;
  subject: string;
  body: string;
}

export interface EmailThisTemplate {
  recordId: number | undefined;
  fieldId: number | undefined;
  emailData: EmailThisData;
}

function EmailThisModal({
  visible,
  hidden,
  emailThisDefaultEmailSubject,
  emailThisDefaultEmailBody,
  fieldId,
  recordId,
  readOnly,
}: EmailThisModalProps) {
  const [showAddressDialog, setShowAddressDialog] = useState(false);
  const [emailRecipients, setEmailRecipients] = useState<Array<EmailAddressTemplate>>([]);
  const [stateError, setStateError] = useState<string | undefined>(undefined);
  const toastId = useRef<string>();
  const [sendEmailThis, { isLoading: isRecordSending }] = useSendEmailThisMutation();
  const { t } = useTranslation();

  useEffect(() => {
    if (emailRecipients.length > 0) {
      setStateError(undefined);
    }
  }, [emailRecipients]);

  useEffect(() => {
    isRecordSending
      ? (toastId.current = showInfo(t('sending-email'), { duration: Infinity }))
      : dismiss(toastId.current);
  }, [isRecordSending]);

  const schema = yup.object().shape({
    subject: yup.string().required(fieldValidationErrorMessage(t('subject'))),
    body: yup.string().required(fieldValidationErrorMessage(t('body'))),
  });

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      subject: emailThisDefaultEmailSubject,
      body: emailThisDefaultEmailBody,
      ccme: false,
      emailRecipients: emailRecipients,
    } as FieldValues,
  });

  const openDialog = () => {
    setShowAddressDialog(true);
  };

  const onError = (data: FieldValues) => console.error(data);

  const handleRemove = (typeOfRecipient: string, id: string) => () => {
    handleDeleteRecipient(typeOfRecipient, id);
  };

  const renderOneRecipient = (recipient: EmailAddressTemplate) => {
    return (
      <DropDownChip
        key={recipient.id}
        value={recipient}
        onRemove={handleRemove(recipient.typeOfRecipient, recipient.id)}
      />
    );
  };

  const modalButtons: ModalDialogButtonSetting = {
    name: 'formSubmitButton',
    text: i18next.t('send'),
    type: 'primary',
    variant: 'alternative',
    closeModalOnClick: true,
    disabled: isRecordSending,
    form: 'emailThisForm',
  };

  const renderRecipients = useCallback(() => {
    return (
      <div className="flex flex-1 text-xs flex-wrap pt-1">
        {emailRecipients.map((recipient) => {
          return renderOneRecipient(recipient);
        })}
      </div>
    );
  }, [emailRecipients, stateError]);

  const handleDeleteRecipient = (recipientType: string, idToDelete: string) => {
    setEmailRecipients((prevRecipients) =>
      prevRecipients.filter(
        (recipient) => !(recipient.id === idToDelete && recipient.typeOfRecipient === recipientType)
      )
    );
  };

  const handleAddRecipients = (newRecipients: Array<EmailAddressTemplate>) => {
    setEmailRecipients((prevRecipients) => {
      // Create a map to store unique recipients by their id
      const uniqueMap = new Map<string, EmailAddressTemplate>();

      // Add previous recipients to the map
      for (let i = 0; i < prevRecipients.length; i++) {
        const recipient = prevRecipients[i];
        uniqueMap.set(recipient.id, recipient);
      }

      // Add new recipients to the map (overwriting any duplicates)
      for (let i = 0; i < newRecipients.length; i++) {
        const recipient = newRecipients[i];
        uniqueMap.set(recipient.id, recipient);
      }

      return Array.from(uniqueMap.values());
    });
  };

  const onNewAddressAdded = (data: Array<EmailAddressTemplate>) => {
    setShowAddressDialog(false);
    handleAddRecipients(data);
  };

  const convertEmailRecipientsToEmailThisData = useCallback(
    (emailRecipients: Array<EmailAddressTemplate>, ccme: boolean, subject: string, body: string): EmailThisData => {
      const sendToAddesses = new Set<string>();
      const sendToUserIds = new Set<number>();
      const sendToGroupIds = new Set<number>();

      for (let i = 0; i < emailRecipients.length; i++) {
        const recipient = emailRecipients[i];

        if (recipient.typeOfRecipient === 'email') {
          sendToAddesses.add(recipient.recipientId);
        } else if (recipient.typeOfRecipient === 'users') {
          const userId = parseInt(recipient.recipientId, 10);
          if (!isNaN(userId)) {
            sendToUserIds.add(userId);
          }
        } else if (recipient.typeOfRecipient === 'groups') {
          const groupId = parseInt(recipient.recipientId, 10);
          if (!isNaN(groupId)) {
            sendToGroupIds.add(groupId);
          }
        }
      }

      return {
        sendToEmailAddresses: sendToAddesses.size > 0 ? Array.from(sendToAddesses) : [],
        sendToUserIds: sendToUserIds.size > 0 ? Array.from(sendToUserIds) : [],
        sendToGroupIds: sendToGroupIds.size > 0 ? Array.from(sendToGroupIds) : [],
        ccMe: ccme ? true : false,
        subject,
        body,
      };
    },
    [emailRecipients]
  );

  const onSubmitModal = (data: FieldValues) => {
    if (emailRecipients.length === 0) {
      setStateError(`'${t('send-to')}' is a required field.`);
    } else {
      setStateError(undefined);
      const sendData = convertEmailRecipientsToEmailThisData(emailRecipients, data.ccme, data.subject, data.body);
      const emailTemplate: EmailThisTemplate = {
        recordId: recordId,
        fieldId: fieldId,
        emailData: sendData,
      };
      sendEmailThis(emailTemplate)
        .unwrap()
        .then((value) => {
          //email is sent
          if (value) {
            reset({});
            setEmailRecipients([]);
            showSuccess(t('email-sent'));
            hidden();
          }
        })
        .catch(() => {
          showError(t('error-sending-email', { count: emailRecipients?.length }));
        });
    }
  };
  const onCloseAddressModal = () => {
    setShowAddressDialog(false);
  };

  const panelFrozenStyles = cx(
    'absolute top-[5%] left-[5%] z-[9999] w-[600px] h-[550px] bg-gray-5 opacity-50 rounded-md',
    {
      invisible: !isRecordSending,
    }
  );

  return (
    <>
      <ModalDialog
        header={''}
        minDialogWidth={600}
        maxDialogWidth={600}
        minDialogHeight={550}
        maxDialogHeight={550}
        buttonType="submit"
        buttonsCloseModal={false}
        hidden={hidden}
        visible={visible}
        showCloseIcon={true}
        buttons={[modalButtons]}
      >
        <div className={panelFrozenStyles} />
        <form id="emailThisForm" onSubmit={handleSubmit(onSubmitModal, onError)}>
          {
            <>
              <div className="flex">
                <FieldLabel label={t('send-to')} />
                <RequiredIndicator readOnly={readOnly} required={DynamicFormRequiredType.True} />
              </div>
              {renderRecipients()}
              {stateError && <ErrorLabel className="w-full">{stateError}</ErrorLabel>}
              <div className="mb-3">
                <Tooltip arrow={false} description={'Click to add recipient'} tooltipZIndexCheck={true}>
                  <WrapperStyledButtonField onClick={openDialog} hidden={false} buttonCaption={t('add-recipients')} />
                </Tooltip>
              </div>
              <div className="mb-3">
                <HfCheckbox control={control} name="ccme" label={t('send-me-a-copy')} />
              </div>
              <div className="mb-3">
                <HfInput
                  control={control}
                  name="subject"
                  label={t('subject')}
                  required={DynamicFormRequiredType.True}
                  readOnly={false}
                  labelStyles={'block text-sm font-bold mb-1'}
                />
              </div>
              <HfRichTextArea
                control={control}
                name="body"
                label={t('body')}
                required={DynamicFormRequiredType.True}
                readOnly={false}
              />
              <EmailThisAddressModal
                hidden={onCloseAddressModal}
                visible={showAddressDialog}
                onNewAddressAdded={onNewAddressAdded}
              ></EmailThisAddressModal>
            </>
          }
        </form>
      </ModalDialog>
    </>
  );
}

export default EmailThisModal;
