import {
  DataGridDataRowTemplateProps,
  DataGridRef,
  DropDownResult,
  Notification,
  RowTemplateType,
} from '@myosh/odin-components';
import React, { useCallback, useEffect, useRef } from 'react';
import { Subject, takeUntil } from 'rxjs';
import { Module } from '../@types/modules';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { selectActiveRecordId } from '../redux/slices/layout';
import {
  processLastEditedFilterForContinousScroller,
  processRecordFilterForContinuousScroller,
} from '../common/dynamic-page-utils';
import { useUser } from '@myosh/myosh-login';
import { clearSelectedForm, dynamicPageState } from '../redux/slices/dynamic-page-slice';
import { getDataGridReference } from '../services/data-grid.service';
import AllFormsGrid from '../components/dynamic-page/all-forms-grid';
import SingleFormGrid from '../components/dynamic-page/single-form-grid';
import useActiveRecordRef from '../hooks/use-active-record-ref';
import { RecordTitle } from '../components/record/record-title.component';
import { constructRecordTitle } from '../components/record/record-component-util';
import useDoubleClick from '../hooks/use-double-click';
import { t } from 'i18next';

interface DynamicPageProps {
  selectedForm: DropDownResult;
  module: Module;
  onViewChange?: (hasChanged: boolean) => void;
}

export default function DynamicPage({ selectedForm, module, onViewChange }: DynamicPageProps) {
  const gridRef = useRef<DataGridRef>();
  const destroySubject = useRef<Subject<void>>();

  const dispatch = useAppDispatch();
  const { selectedFilters } = useAppSelector(dynamicPageState);
  const isAllForms = selectedForm.value === 0;
  const activeRecordId = useAppSelector(selectActiveRecordId);

  const { state: userState } = useUser();
  const { activeRecordReference } = useActiveRecordRef();
  const { rowClickHandler } = useDoubleClick();

  useEffect(() => {
    if (activeRecordId) {
      gridRef.current?.api.data.addSelectedRows([{ id: activeRecordId }], true);
    }
  }, [activeRecordId]);

  const onDataLoadCompleted = () => {
    if (activeRecordId) {
      gridRef.current?.api.data.addSelectedRows([{ id: activeRecordId }], true);
    }
  };

  useEffect(() => {
    destroySubject.current = new Subject<void>();
    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((dataGridRef) => {
        if (dataGridRef) {
          gridRef.current = dataGridRef;
        }
      });

    return () => {
      destroySubject.current?.next();
      destroySubject.current?.complete();

      dispatch(clearSelectedForm());
    };
  }, []);

  useEffect(() => {
    if (gridRef.current && selectedFilters.records) {
      gridRef.current.api.filters.addFilter(processRecordFilterForContinuousScroller(selectedFilters.records));
    }
  }, [selectedFilters.records]);

  useEffect(() => {
    if (gridRef.current && !isAllForms && selectedFilters.lastEdited) {
      gridRef.current.api.filters.addFilter(processLastEditedFilterForContinousScroller(selectedFilters.lastEdited));
    }
  }, [selectedFilters.lastEdited, isAllForms]);

  const rowTemplate: RowTemplateType = useCallback(
    (props: DataGridDataRowTemplateProps, children: React.ReactNode | Array<React.ReactNode>) => {
      const handleClick = (isFullScreen: boolean) => {
        const recordId = props.data.id;
        const docNo = String(props.data.docNo);
        const status = String(props.data.status);
        const formName = isAllForms ? String(props.data.moduleForm) : String(props.data.formName);
        const date = props.data.creationDate ? new Date(props.data.creationDate as string) : undefined;
        const title = constructRecordTitle(formName, docNo, status);
        const details = <RecordTitle title={title} date={date} />;

        activeRecordReference.current?.addActiveRecord(String(recordId), module.icon, details, isFullScreen, {
          title,
          recordId,
          viewId: module.viewId,
          moduleIcon: module.icon,
          formId: props.data.formId,
          formName: formName,
          docNo,
          status,
          vikingLinkUrl: props.data.vikingLinkUrl,
          displayText: props.data.displayText,
        });
      };

      const handleRowClick = () => {
        rowClickHandler(handleClick);
      };

      return (
        <div className="flex w-full" onClick={handleRowClick}>
          <div className="flex flex-grow items-center" onClick={props.onRowClicked}>
            {children}
          </div>
        </div>
      );
    },
    [selectedForm?.value, module.viewId, userState.user?.currentSchema, selectedFilters.records]
  );

  return (
    <>
      {module.forms?.length ? (
        <>
          {isAllForms ? (
            <AllFormsGrid
              rowTemplate={rowTemplate}
              menuItems={[]}
              selectedForm={selectedForm}
              moduleId={module.id}
              onDataLoadCompleted={onDataLoadCompleted}
              onViewChange={onViewChange}
            />
          ) : (
            <SingleFormGrid
              rowTemplate={rowTemplate}
              menuItems={[]}
              selectedForm={selectedForm}
              moduleId={module.id}
              onDataLoadCompleted={onDataLoadCompleted}
              onViewChange={onViewChange}
            />
          )}
        </>
      ) : (
        <div className="flex w-full justify-center pt-16">
          <Notification key="errorNotification" description={t('no-forms')} type="error" />
        </div>
      )}
    </>
  );
}
