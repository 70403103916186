import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Loader } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/hooks';
import { selectNavigationPaths } from '../../../redux/slices/layout';
import { Outlet } from 'react-router-dom';

/**
 * Check if the hardcoded route exists in the navigation-items endpoint.
 * If it doesn´t exist, it navigates to the default route.
 */
function PrivateNavigationAccessRoute() {
  const location = useLocation();
  const { t } = useTranslation();
  const navigationItemsPaths = useAppSelector(selectNavigationPaths);

  if (navigationItemsPaths && navigationItemsPaths.some((item) => item === location.pathname)) {
    return <Outlet />;
  } else if (
    navigationItemsPaths &&
    navigationItemsPaths.length > 0 &&
    !navigationItemsPaths.some((item) => item === location.pathname)
  ) {
    return <Navigate to={navigationItemsPaths[0]} />;
  } else {
    return <Loader title={t('loading-data')} />;
  }
}

export default PrivateNavigationAccessRoute;
