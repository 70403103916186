import React from 'react';
import { Control } from 'react-hook-form';
import { OdinController } from '@myosh/odin-components';
import { HierarchyFieldGroupAdmin, HierarchyFieldGroupAdminProps } from './hierarchy-field-group-admin.component';

interface HfHierarchyFielAdmindProps extends HierarchyFieldGroupAdminProps {
  control?: Control;
  name?: string;
}

export function HfHierarchyFieldAdmin({
  control,
  name = 'hierarchies',
  value,
  readOnly,
  onChange,
  hierarchySettings,
  hierarchyTypes,
}: HfHierarchyFielAdmindProps) {
  return (
    <OdinController
      name={name}
      control={control}
      defaultValue={value}
      onChange={onChange}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <HierarchyFieldGroupAdmin
          errorObject={error}
          value={value}
          hierarchyTypes={hierarchyTypes}
          readOnly={readOnly}
          onChange={onChange}
          hierarchySettings={hierarchySettings}
          control={control}
        />
      )}
    />
  );
}
