import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  DataGrid,
  DataGridColumnSettings,
  DataGridRef,
  DataGridSettings,
  DataSearchLocation,
  DynamicFormFieldType,
  JsonData,
  JsonDataWrapper,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  RowTemplateType,
} from '@myosh/odin-components';
import { defer, from, mergeMap } from 'rxjs';
import {
  AssignedRecordsCaptions,
  assignedRecordsColumnsConfig,
  AssignedRecordsFields,
} from '../common/assigned-records-util';
import {
  buildSortedMyActivitiesUrl,
  dateFormatBasedOnFilter,
  forceAssert,
  processFieldFilters,
} from '../common/common-functions';
import {
  ActivititesPageRecordRowProps,
  OdinDataGridRowTemplate,
} from '../components/dynamic-page/odin-data-grid-row-template.component';
import Meta from '../features/meta/meta';
import useActiveRecordRef from '../hooks/use-active-record-ref';
import {
  useLazyModulesInModuleGroupsQuery,
  useLazyModulesQuery,
  useLazyModuleWorkflowStepsQuery,
} from '../redux/services/api';
import { Module } from '../@types/modules';
import { AssignedRecordItem } from '../@types/records';
import { useTranslation } from 'react-i18next';
import { setDataGridReference } from '../services/data-grid.service';
import { RecordTitle } from '../components/record/record-title.component';
import { constructRecordTitle } from '../components/record/record-component-util';
import { useGetMyActivitiesQuery } from '../redux/services/record';

interface OptionValue {
  name?: string;
  caption: string;
}

const assignedRecordsColumns: DataGridColumnSettings[] = Object.keys(assignedRecordsColumnsConfig).map(
  (field, index) => {
    const columnConfig = assignedRecordsColumnsConfig[field as AssignedRecordsFields];

    return {
      id: index,
      field: field,
      title: AssignedRecordsCaptions[field as AssignedRecordsFields],
      type: columnConfig?.type || 0,
      searchType: columnConfig?.searchType,
      isIdField: columnConfig?.isIdField,
      visible: columnConfig?.visible !== false,
      dropDownValue: columnConfig?.dropDownValue,
      dropDownText: columnConfig?.dropDownText,
      width: columnConfig?.width,
      customDataProperties: {
        entityType: columnConfig?.entityType || '',
        sortField: columnConfig?.sortField || '',
      },
    } as DataGridColumnSettings;
  }
);

const removeDuplicatedItems = (arr: Array<string>) => {
  return arr.reduce(
    (accumulator: Array<string>, value: string) =>
      accumulator.includes(value) ? accumulator : [...accumulator, value],
    []
  );
};

export default function ActivitiesPage() {
  const { activeRecordReference } = useActiveRecordRef();
  const [modules, setModules] = useState<Array<Module>>();
  const [gridOptions, setGridOptions] = useState<OdinDataRetrievalOptions>();

  const gridRef = useRef<DataGridRef>();
  const gridSubscriber = useRef<OdinDataSender<JsonDataWrapper>>();

  const {
    data: recordsData,
    isFetching: areRecordsFetching,
    error: recordsError,
  } = useGetMyActivitiesQuery(
    {
      page: gridOptions?.page,
      pageSize: gridOptions?.pageSize,
      filters: processFieldFilters(gridOptions?.fieldFilters, dateFormatBasedOnFilter),
      sortDirective: buildSortedMyActivitiesUrl(gridOptions?.sortedFields, gridRef?.current?.api.data.columns),
    },
    { skip: gridOptions === undefined || gridOptions?.page === undefined || gridOptions?.pageSize === undefined }
  );

  const [getModules] = useLazyModulesQuery();
  const [moduleWorkflowSteps] = useLazyModuleWorkflowStepsQuery();
  const [getModulesInModuleGroups] = useLazyModulesInModuleGroupsQuery();

  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    getModules({ includeFormList: true }, true)
      .unwrap()
      .then((modules) => {
        setModules(modules);
      });
  }, []);

  useEffect(() => {
    const item = location?.state?.item as AssignedRecordItem;
    const module = item && modules && modules.find((module) => module.caption === item.moduleName);
    if (item && module) {
      const title = constructRecordTitle(item.formName, item.docNo, item.status);
      const date = item.lastModifiedDateTime ? new Date(item.lastModifiedDateTime) : undefined;
      const details = <RecordTitle title={title} date={date} />;

      activeRecordReference.current?.addActiveRecord(String(item.id), module.icon, details, undefined, {
        title,
        ...item,
        recordKey: item.id,
        recordId: item.id,
        moduleIcon: module.icon,
        viewId: module.viewId,
      });
    }
  }, [location, modules]);

  useEffect(() => {
    if (recordsError) {
      gridSubscriber.current?.sendData();
    } else if (recordsData && !areRecordsFetching) {
      if (recordsData.length > 0) {
        gridSubscriber.current?.sendData({
          data: forceAssert<JsonData>(recordsData),
          requestId: gridOptions?.requestId,
        });
      } else {
        gridSubscriber.current?.sendData();
      }
    }
  }, [recordsData, areRecordsFetching, recordsError, gridOptions]);

  const initializeGridRef = (ref: DataGridRef) => {
    if (!gridRef.current) {
      gridRef.current = ref;

      const item = location?.state?.item as AssignedRecordItem;
      if (item) {
        gridRef.current.api.data.addSelectedRows([{ ...item }], true);
      }

      setDataGridReference(ref);
    }
  };

  const filterDropdownOptions = (options: OptionValue[], filter: string) => {
    return options.filter((data) => data.caption.toLocaleLowerCase().includes(String(filter).toLocaleLowerCase()));
  };

  const dataRetrieval = useMemo<OdinDataRetrieval>(() => {
    return {
      getSubscriber: (subscriber, fieldType?: DynamicFormFieldType) => {
        if (fieldType !== 'COMBOBOX') {
          gridSubscriber.current = subscriber;
        }
      },
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if (options?.fieldType === 'COMBOBOX') {
          if (options.customProperties?.entityType === 'MODULE_NAME' && options?.page === 1) {
            getModulesInModuleGroups(undefined, true)
              .unwrap()
              .then((moduleGroups) => {
                const moduleOptionValues = moduleGroups.map((moduleItem) => {
                  return { name: moduleItem?.caption, caption: moduleItem?.caption };
                });
                if (moduleOptionValues) {
                  if (options?.fieldFilters) {
                    subscriber.sendData({
                      data: forceAssert<JsonData>(
                        filterDropdownOptions(moduleOptionValues, options?.fieldFilters?.caption?.value as string)
                      ),
                      requestId: options.requestId,
                    });
                  } else {
                    subscriber.sendData({ data: moduleOptionValues, requestId: options.requestId });
                  }
                } else {
                  subscriber.sendData();
                }
              })
              .catch(() => {
                subscriber.sendData();
              });
          } else if (options.customProperties?.entityType === 'FORM_NAME' && options?.page === 1) {
            getModules({ includeFormList: true }, true)
              .unwrap()
              .then((modules) => {
                const formOptionValues = modules
                  .map((module) => {
                    return module?.forms ?? [];
                  })
                  .flat();

                if (options?.fieldFilters) {
                  subscriber.sendData({
                    data: forceAssert<JsonData>(
                      filterDropdownOptions(
                        formOptionValues as OptionValue[],
                        options?.fieldFilters?.caption?.value as string
                      )
                    ),
                    requestId: options.requestId,
                  });
                } else {
                  subscriber.sendData({ data: forceAssert<JsonData>(formOptionValues), requestId: options.requestId });
                }
              })
              .catch(() => {
                subscriber.sendData();
              });
          } else if (options.customProperties?.entityType === 'STATUS' && options?.page === 1) {
            getModules({ includeFormList: true }, true)
              .unwrap()
              .then(async (modules) => {
                const MAX_PARALLEL_QUERIES = 25;
                let allResults: Array<string> = [];

                //In the next line will be generated an array that contains an Observable for each module Module Workflow Steps request
                const observables = modules.map((module) =>
                  defer(() => moduleWorkflowSteps({ moduleId: module?.id }, true))
                );

                //Parallel requests for every observable on the array passed will be made with a limit of reqeusts at a time based on MAX_PARALLEL_QUERIES
                from(observables)
                  .pipe(mergeMap((observable) => observable, MAX_PARALLEL_QUERIES))
                  .subscribe(
                    (partialResults) => {
                      //Every time an Observable will be resolved we add the value to "allResults" variable
                      allResults = allResults.concat(partialResults?.data ?? []);
                    },
                    () => {
                      // handle error
                    },
                    () => {
                      //this unnamed function will be triggered when all the requests will be resolved successfully
                      const workflowSteps = removeDuplicatedItems(allResults)
                        .sort((item1, item2) => item1.localeCompare(item2))
                        .map((step) => {
                          return { name: step, caption: step };
                        });
                      if (options?.fieldFilters) {
                        subscriber.sendData({
                          data: forceAssert<JsonData>(
                            filterDropdownOptions(workflowSteps, options?.fieldFilters?.caption?.value as string)
                          ),
                          requestId: options.requestId,
                        });
                      } else {
                        subscriber.sendData({ data: workflowSteps, requestId: options.requestId });
                      }
                    }
                  );
              })
              .catch(() => {
                subscriber.sendData();
              });
          } else {
            subscriber.sendData();
          }
        } else {
          setGridOptions(options);
        }
      },
    };
  }, []);

  const gridSettings = useRef<DataGridSettings>({
    filterLocation: DataSearchLocation.Api,
    columns: assignedRecordsColumns,
    fullHeight: true,
    stopRequestsOnNoData: true,
  });

  const rowTemplate: RowTemplateType = OdinDataGridRowTemplate({
    modules: modules,
    activeRecordReference: activeRecordReference.current,
    recordFields: ActivititesPageRecordRowProps,
  });

  return (
    <>
      <Meta title={`myosh - ${t('my-activities')}`} />
      <div className="-ml-10 flex h-full w-full flex-col pb-5">
        <DataGrid
          data={dataRetrieval}
          gridSettings={gridSettings.current}
          rowTemplate={rowTemplate}
          showSettings={false}
          ref={(ref) => ref && initializeGridRef(ref)}
        />
      </div>
    </>
  );
}
