import React from 'react';
import { HfRadioGroup } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';

import { deliveryMethod } from '../../../record-notification/notifications-utils';
import { NotificationTypeFieldProps } from './notification-properties-utils';
import WeekdaysTimeField from './weekdays-time-field';
import TriggerWorkflowSteps from './trigger-workflow-steps';

const NotificationTimedField = ({ control, value, readOnly }: NotificationTypeFieldProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-y-0.5">
      <HfRadioGroup
        control={control}
        name="notificationDelivery"
        id="notificationDelivery"
        textField="text"
        valueField="value"
        data={deliveryMethod}
        orientation="HORIZONTAL"
        label={t('delivery-method')}
        readOnly={readOnly}
      />
      <WeekdaysTimeField control={control} readOnly={readOnly} />
      <TriggerWorkflowSteps control={control} value={value} readOnly={readOnly} />
    </div>
  );
};

export default NotificationTimedField;
