import React from 'react';
import cx from 'classnames';
import GlobalHierarchiesFilter from '../../dynamic-page/global-hierarchies-filter.component';
import { useTranslation } from 'react-i18next';
import ProfileMenu from './profile-menu.component';
import { useAppSelector } from '../../../redux/hooks';
import { selectIsActivePanelEmpty } from '../../../redux/slices/layout';
import ExportProcessPanel from '../../export-process-panel/export-process-panel.component';
import { useScreenSize } from '@myosh/odin-components';

interface OdinHeaderProps {
  isSideNavigationCollaped: boolean;
}

function OdinHeader({ isSideNavigationCollaped }: OdinHeaderProps) {
  const { isMobile } = useScreenSize();
  const { t } = useTranslation();
  const isActiveRecordPanelHidden = useAppSelector(selectIsActivePanelEmpty);

  const headerStyles = cx('relative flex items-center justify-between text-sm shadow-md h-10 pl-5', {
    'sm:ml-52': !isSideNavigationCollaped,
    'sm:ml-14': isSideNavigationCollaped,
  });

  const profileMenuWrapperStyles = cx('h-full flex items-center', {
    'mr-14': !isActiveRecordPanelHidden,
  });

  return (
    <header className={headerStyles}>
      {!isMobile && <GlobalHierarchiesFilter />}
      <div className="flex items-center justify-center font-medium">
        {t('new-version-info')}
        <a
          href="https://www.myosh.com/viking-2-0-roadmap"
          rel="noopener noreferrer"
          target="_blank"
          className="cursor-pointer underline pl-1"
        >
          {t('click-here-more-information')}
        </a>
      </div>
      <div className={profileMenuWrapperStyles}>
        <ExportProcessPanel />
        <ProfileMenu />
      </div>
    </header>
  );
}

export default OdinHeader;
