import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuthAndUrlEncoding, providesList } from './api';
import { createFieldFilterText, createCustomSortWithOrderBy } from '../../common/common-functions';
import {
  CompetencyGroupBaseApiProps,
  CompetencyGroupItem,
  CompetencyGroupsApiProps,
  CompetencyGroupsResponse,
} from '../../@types/competency-groups';
import {
  CompetenciesItem,
  CompetenciesResponse,
  CompetencyPatch,
  CompetencyTagsResult,
} from '../../@types/competencies';
import { IdCaptionType } from '../../@types/common';
import { CompetenciesConfigurationResponse } from '../../@types/competencies-configuration';

export const competencyApi = createApi({
  reducerPath: 'competency',
  baseQuery: baseQueryWithReAuthAndUrlEncoding,
  tagTypes: ['CompetencyGroup', 'Competency'],
  endpoints: (builder) => ({
    getCompetencyGroups: builder.query<Array<CompetencyGroupItem>, CompetencyGroupsApiProps>({
      query: (args) =>
        `/competency-groups?page=${args.page}&pageSize=${args.pageSize}${createCustomSortWithOrderBy(
          args.sortedFields
        )}${createFieldFilterText({
          filters: args.filters,
        })}`,
      transformResponse: (response: CompetencyGroupsResponse) => response?.items || [],
      providesTags: (result) => providesList(result, 'CompetencyGroup'),
    }),
    updateCompetencyGroup: builder.mutation<void, Pick<CompetencyGroupItem, 'id'> & Partial<CompetencyGroupItem>>({
      query: ({ id, ...patch }) => ({
        url: `/competency-groups/${id}`,
        method: 'PUT',
        body: patch,
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),

      invalidatesTags: (result, error, arg) => {
        if (result) {
          return [{ type: 'CompetencyGroup', id: arg.id }];
        } else {
          return [];
        }
      },
    }),
    getCompetencies: builder.query<Array<CompetenciesItem>, CompetencyGroupBaseApiProps>({
      query: (args) =>
        `/competencies/simple?page=${args.page}&pageSize=${args.pageSize}${createCustomSortWithOrderBy(
          args.sortedFields
        )}${createFieldFilterText({
          filters: args?.filters,
        })}`,
      transformResponse: (response: CompetenciesResponse) => response?.items || [],
      providesTags: (result) => providesList(result, 'Competency'),
    }),
    updateCompetency: builder.mutation<void, Pick<CompetencyGroupItem, 'id'> & Partial<CompetencyPatch>>({
      query: (patch) => ({
        url: `/competencies/${patch.id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result) {
          return [{ type: 'Competency', id: arg.id }];
        } else {
          return [];
        }
      },
    }),
    getCompetencyTags: builder.query<Array<IdCaptionType>, void>({
      query: () => `/competencies/tags`,
      transformResponse: (response: CompetencyTagsResult) => response?.result || [],
    }),
    getCompetenciesConfiguration: builder.query<CompetenciesConfigurationResponse, void>({
      query: () => `/competencies/configuration`,
      transformResponse: (response: CompetenciesConfigurationResponse) => response?.result || {},
    }),
    updateCompetenciesConfiguration: builder.mutation<void, Partial<CompetenciesConfigurationResponse>>({
      query: (patch) => ({
        url: `/competencies/configuration`,
        method: 'PATCH',
        body: patch,
      }),
    }),
  }),
});

export const {
  useGetCompetencyGroupsQuery,
  useLazyGetCompetencyGroupsQuery,
  useUpdateCompetencyGroupMutation,
  useGetCompetenciesQuery,
  useLazyGetCompetenciesQuery,
  useUpdateCompetencyMutation,
  useLazyGetCompetenciesConfigurationQuery,
  useUpdateCompetenciesConfigurationMutation,
} = competencyApi;
