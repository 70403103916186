import React, { createContext, useContext, useState, useEffect } from 'react';
import { Loader, applyBeeSoftTheme, createBeeSoftTheme } from '@myosh/odin-components';
import { useGetThemeQuery } from '../redux/services/api';
import { useTranslation } from 'react-i18next';

type Theme = Record<string, string>;

interface ThemeContextProps {
  children: React.ReactNode;
}

const ThemeContext = createContext<Theme | undefined>(undefined);

export const ThemeProvider: React.FC<ThemeContextProps> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>();
  const [loaded, setLoaded] = useState(false);
  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useGetThemeQuery();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      if (data) {
        setTheme(data);
      } else {
        // unable to fetch theme, continue with default
        console.warn('Unable to load user theme, continuing with default app theme.');
        setLoaded(true);
      }
    }
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    if (theme) {
      createComponentTheme();

      const keys = Object.keys(theme);
      for (let i = 0, length = keys.length; i < length; i++) {
        const color = theme[keys[i]];
        if (CSS.supports('color', color)) {
          document.documentElement.style.setProperty(`--${keys[i]}`, color);
        } else {
          console.warn(`The CSS color '${color}' is not supported.`);
        }
      }
      setLoaded(true);
    }
  }, [theme]);

  const createComponentTheme = () => {
    if (theme) {
      const beeSoftTheme = {
        primary1: theme['primary-1'],
        primary2: theme['primary-2'],
        primary3: theme['primary-3'],
        primary4: theme['primary-4'],
        primary5: theme['primary-5'],
        gray1: theme['gray-1'],
        gray2: theme['gray-2'],
        gray3: theme['gray-3'],
        gray4: theme['gray-4'],
        gray5: theme['gray-5'],
        monoDark1: theme['mono-0'],
        monoLight1: theme['mono-1'],
        info: theme['info'],
        success: theme['success'],
        warning: theme['warning'],
        error: theme['error'],
      };

      applyBeeSoftTheme(createBeeSoftTheme(beeSoftTheme));
    }
  };

  return (
    <ThemeContext.Provider value={theme}>
      {loaded ? (
        children
      ) : (
        <div className="mx-auto flex h-screen w-full flex-col items-center justify-center">
          <Loader title={t('loading-theme')} />
        </div>
      )}
    </ThemeContext.Provider>
  );
};

// context consumer hook
export const useThemeContext = () => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error('useThemeContext must be used within ThemeProvider');
  }

  return context;
};
