import React, { useEffect, useState } from 'react';
import { SchedulerRuleField } from '../../../@types/scheduler-record';
import {
  DropDownResult,
  DynamicFormRequiredType,
  ErrorLabel,
  IconButton,
  Input,
  OdinIcon,
  OdinIconSize,
  OdinIconType,
} from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import OdinDropDownComponent from '../custom-drop-downs/odin-drop-down.component';
import cx from 'classnames';
import { DynamicFieldDataTypes } from '@myosh/odin-components/dist/types/components/dynamic-form/dynamic-form.interfaces';
import { forceAssert } from '../../../common/common-functions';

type FieldRuleFieldProps = {
  fieldRule?: SchedulerRuleField;
  className?: string;
  componentOptions?: DynamicFieldDataTypes;
  errorObject?: Record<string, unknown>;
  onChange?: (value: SchedulerRuleFieldState, type: string) => void;
  onFieldDelete?: (value: SchedulerRuleFieldState) => void;
};

export interface SchedulerRuleFieldState extends Omit<SchedulerRuleField, 'conditionField'> {
  conditionField: {
    text: string;
    value: number;
  };
}

const FieldRuleField = ({
  fieldRule,
  componentOptions,
  errorObject,
  className,
  onChange,
  onFieldDelete,
}: FieldRuleFieldProps) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState<SchedulerRuleFieldState>({} as SchedulerRuleFieldState);

  useEffect(() => {
    if (fieldRule) {
      const { conditionFieldId, fieldCaption, firstOccurrence, repeatEvery, id, type, displayName, ...rest } =
        fieldRule;

      const initialFormState = {
        ...rest,
        conditionFieldId: conditionFieldId,
        fieldCaption: fieldCaption,
        conditionField: { text: fieldCaption?.translations[0].value, value: conditionFieldId },
        firstOccurrence: firstOccurrence ?? 0,
        repeatEvery: repeatEvery ?? 1,
        id,
        type,
        displayName,
      };
      setFormState(initialFormState);
      if (String(id).includes('new')) {
        onChange?.(initialFormState, 'field-condition');
      }
    }
  }, []);

  const handleDropDownChange = (name: keyof typeof formState) => (result: DropDownResult) => {
    if (formState?.id) {
      const updatedState = {
        ...formState,
        [name]: result && 'value' in result ? result.originalData : undefined,
      };
      onChange?.(updatedState, 'field-condition');
      setFormState(updatedState);
    }
  };

  const handleInputChange = (name: keyof typeof formState) => (value?: string | number) => {
    const updatedState = {
      ...formState,
      [name]: value,
    };
    onChange?.(updatedState, 'field-condition');
    setFormState(updatedState);
  };

  const onDeleteHandler = () => {
    onFieldDelete?.(formState);
  };
  const ruleCardStyles = cx('odin-flex-row space-x-2', className, {
    'odin-border-gray-4': !errorObject,
    'odin-border-error': errorObject,
  });

  return (
    <>
      <div className={ruleCardStyles} style={{ alignItems: 'end' }}>
        <OdinDropDownComponent
          value={formState.conditionField}
          valueField="value"
          textField="text"
          className="self-end"
          label={t('component')}
          data={componentOptions}
          readOnly={false}
          error={forceAssert<string>(Boolean(errorObject?.conditionField))}
          required={DynamicFormRequiredType.True}
          onChange={(value) => handleDropDownChange('conditionField')(value as DropDownResult)}
        />
        <Input
          label={t('first-occurence')}
          name="firstOccurrence"
          value={String(formState?.firstOccurrence)}
          type="number"
          readOnly={false}
          error={forceAssert<string>(Boolean(errorObject?.firstOccurrence))}
          required={DynamicFormRequiredType.True}
          onChange={handleInputChange('firstOccurrence')}
        />
        <Input
          label={t('repeat-every')}
          name="repeatEvery"
          value={String(formState?.repeatEvery)}
          type="number"
          readOnly={false}
          error={forceAssert<string>(Boolean(errorObject?.repeatEvery))}
          onChange={handleInputChange('repeatEvery')}
        />
        <IconButton onClick={onDeleteHandler}>
          <OdinIcon size={OdinIconSize.Medium} type={OdinIconType.Line} icon="DeleteBin" className="w-6" />
        </IconButton>
      </div>
      {errorObject && <ErrorLabel>{t('record-validation-message')}</ErrorLabel>}
    </>
  );
};

export default FieldRuleField;
