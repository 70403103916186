import React, { useState } from 'react';
import {
  DomElementAlignment,
  DomTargetPosition,
  DynamicFieldComponentProps,
  FieldLabel,
  Tooltip,
} from '@myosh/odin-components';
import { RiskLevelsDataResponse, RiskMatrixRatingValue } from '../../../@types/risk-matrix-field';
import { useRiskMatrixRiskLevelsQuery } from '../../../redux/services/risk-matrix';
import PrimaryViewActionButton from '../../common/primary-view-action-button';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import RiskLevelModal from './risk-level-modal.component';

export interface RiskLevelProps extends DynamicFieldComponentProps<RiskMatrixRatingValue> {
  riskMatrixId: number;
  readOnly?: boolean;
}

const RiskLevel = ({ riskMatrixId, readOnly, label = '' }: RiskLevelProps) => {
  const { t } = useTranslation();
  const [selectedRiskLevel, setSelectedRiskLevel] = useState<RiskLevelsDataResponse>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { data: riskLevelsData } = useRiskMatrixRiskLevelsQuery(riskMatrixId);

  const handleModalOpen = () => {
    setSelectedRiskLevel(undefined);
    setModalVisible(true);
  };

  const handleModalEdit = (riskLevel: RiskLevelsDataResponse) => {
    setSelectedRiskLevel(riskLevel);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setSelectedRiskLevel(undefined);
    setModalVisible(false);
  };

  const addNewRiskLevelStyles = cx('w-full ml-1', {
    'pointer-events-none select-none opacity-50 cursor-not-allowed': readOnly,
  });

  const riskLevelTableStyles = cx('flex flex-col bg-mono-1 p-4 text-sm', {
    'pointer-events-none select-none opacity-70 cursor-not-allowed': readOnly,
  });

  return (
    <div className="flex flex-col gap-4">
      {label && (
        <div className="flex">
          <FieldLabel label={label} />
        </div>
      )}
      <div className={addNewRiskLevelStyles}>
        <PrimaryViewActionButton onClick={handleModalOpen}>{`${t('add')} ${t('risk-level')}`}</PrimaryViewActionButton>
      </div>

      <div className={riskLevelTableStyles}>
        <div className="grid grid-cols-[80px_100px_1fr] gap-3 border-t border-gray-4 whitespace-nowrap truncate items-center p-2">
          <div>{t('category')}</div>
          <div>{t('name')}</div>
          <div>{t('description')}</div>
        </div>
        {riskLevelsData?.map((riskLevel) => (
          <div
            key={riskLevel.id}
            onClick={() => handleModalEdit(riskLevel)}
            style={{ backgroundColor: riskLevel.backgroundColor, color: riskLevel.textColor }}
            className={`grid grid-cols-[80px_100px_1fr] gap-3 border-t border-gray-4 whitespace-nowrap truncate items-center p-2 cursor-pointer`}
          >
            <div>{riskLevel?.category}</div>
            <div>{riskLevel?.caption}</div>
            <div className="truncate">
              <Tooltip
                description={riskLevel?.descriptionCaption}
                tooltipTargetPosition={DomTargetPosition.BottomLeft}
                tooltipAlignment={DomElementAlignment.TopLeft}
                tooltipClassName="bg-mono-1"
                debounceTime={200}
                dependOnChildren
                tooltipZIndexCheck
              >
                <div className="w-full">
                  <p className="truncate">{riskLevel?.descriptionCaption}</p>
                </div>
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
      <RiskLevelModal
        riskMatrixId={riskMatrixId}
        handleModalClose={handleModalClose}
        visible={modalVisible}
        selectedRiskLevel={selectedRiskLevel}
      />
    </div>
  );
};

export default RiskLevel;
