import React from 'react';
import { Signal } from '@preact/signals-react';
import { Control } from 'react-hook-form';
import DependentDropDown, { DependentDropDownType } from './dependent-drop-down.component';
import { DropDownMultiSelectProps } from '@myosh/odin-components/dist/types/components/fields/multi-select-drop-down/drop-down-multi-select.component';
import { DynamicFormParentFieldValue } from '@myosh/odin-components/dist/types/components/dynamic-form/dynamic-form.interfaces';
import { DropDownMultiSelect, OdinController } from '@myosh/odin-components';

interface HfDropDownMultiSelectProps extends DropDownMultiSelectProps {
  control: Control;
  name: string;
  parentFieldId?: string;
  parentValue?: Signal<Array<DynamicFormParentFieldValue>>;
  parentLabel?: string;
}

export default function HfDropDownMultiSelect(props: HfDropDownMultiSelectProps) {
  const {
    control,
    name,
    parentFieldId,
    parentValue,
    parentLabel,
    id,
    textField,
    valueField,
    label,
    value,
    data,
    allowClear,
    allowSearch,
    preloadData = false,
    multipleMessageCount,
    multipleMessageText,
    useChips,
    placeholder,
    filterLocation,
    actionItems,
    onActionItemChanged,
    readOnly,
    required,
    className,
    containerStyles,
    labelStyles,
    dropDownStyles,
    dropDownIconSize,
    dropDownButtonStyles,
    clearButtonStyles,
    onDoubleClick,
    onChange,
    fieldValueConverter,
    disabled,
    shouldUseCreatePortal,
  } = props;

  return (
    <OdinController
      name={name}
      defaultValue={value}
      control={control}
      onChange={onChange}
      fieldValueConverter={fieldValueConverter}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return !parentFieldId || !parentValue ? (
          <DropDownMultiSelect
            id={id || 0}
            textField={textField}
            valueField={valueField}
            label={label}
            value={value}
            data={data}
            error={error?.message}
            allowClear={allowClear}
            allowSearch={allowSearch}
            preloadData={preloadData}
            multipleMessageCount={multipleMessageCount}
            multipleMessageText={multipleMessageText}
            useChips={useChips}
            placeholder={placeholder}
            filterLocation={filterLocation}
            actionItems={actionItems}
            onActionItemChanged={onActionItemChanged}
            readOnly={readOnly}
            required={required}
            onChange={onChange}
            className={className}
            containerStyles={containerStyles}
            labelStyles={labelStyles}
            dropDownStyles={dropDownStyles}
            dropDownIconSize={dropDownIconSize}
            dropDownButtonStyles={dropDownButtonStyles}
            clearButtonStyles={clearButtonStyles}
            onDoubleClick={onDoubleClick}
            disabled={disabled}
            shouldUseCreatePortal={shouldUseCreatePortal}
          />
        ) : (
          <DependentDropDown
            parentFieldId={parentFieldId}
            parentLabel={parentLabel || ''}
            parentValue={parentValue}
            dropDownType={DependentDropDownType.MultiSelectDropDown}
            id={id || 0}
            textField={textField}
            valueField={valueField}
            label={label}
            value={value}
            data={data}
            error={error?.message}
            allowClear={allowClear}
            allowSearch={allowSearch}
            shouldUseCreatePortal={shouldUseCreatePortal}
            preloadData={preloadData}
            multipleMessageCount={multipleMessageCount}
            multipleMessageText={multipleMessageText}
            useChips={useChips}
            placeholder={placeholder}
            filterLocation={filterLocation}
            readOnly={readOnly}
            required={required}
            onChange={onChange}
            className={className}
            containerStyles={containerStyles}
            labelStyles={labelStyles}
            dropDownStyles={dropDownStyles}
            dropDownIconSize={dropDownIconSize}
            dropDownButtonStyles={dropDownButtonStyles}
            clearButtonStyles={clearButtonStyles}
            onDoubleClick={onDoubleClick}
            disabled={disabled}
          />
        );
      }}
    />
  );
}
