import React from 'react';
import cx from 'classnames';
import { useImageFieldUrlQuery } from '../../../redux/services/field';
import { QuestionImageProps } from '../../../@types/questionnaire';

interface QuestionnaireImageProps {
  image: QuestionImageProps;
  fieldId: number;
  readOnly?: boolean;
}

const QuestionnaireImage = ({ fieldId, image, readOnly }: QuestionnaireImageProps) => {
  const imageId = image.id;
  const imageName = image.fileName.split('.').slice(0, -1).join('.');
  const { data: url, isFetching } = useImageFieldUrlQuery(
    { fieldId, imageId },
    {
      skip: fieldId === undefined,
    }
  );

  const wrapperClassNames = cx('flex -mt-2 mb-4', {
    'items-start justify-start': url,
    'h-40 justify-center items-center': isFetching,
    'opacity-70': readOnly,
  });

  return (
    <div className={wrapperClassNames}>
      {isFetching ? (
        <div className="animate-form-loading h-full w-full bg-gradient-to-r from-gray-4 via-gray-5 to-gray-4" />
      ) : (
        <img src={url} alt={imageName} className="block max-w-full" />
      )}
    </div>
  );
};

export default QuestionnaireImage;
