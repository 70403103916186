import React, { useEffect, useMemo } from 'react';
import BadgeTooltip from '../components/badge-tooltip.component';
import { useGetCurrentSchemaInfoQuery, useGetNavigationItemsQuery } from '../../../redux/services/api';
import { useGetAssignedRecordsCountQuery } from '../../../redux/services/record';
import { AssignedRecordsCount } from '../../../@types/records';
import { NavigationItem } from '../../../@types/navigation';
import { useUser } from '@myosh/myosh-login';
import { useTranslation } from 'react-i18next';
import useUserSchemas from '../../../hooks/use-user-schemas';
import { useAppDispatch } from '../../../redux/hooks';
import { setNavigationPaths } from '../../../redux/slices/layout';
import { BadgeItemProps, SideNavigationItem } from '@myosh/odin-components';
import { resolveDashboardUrl } from '../../../pages/dashboard-embedded.page';

const useNavigationItems = (itemClickHandler: (() => () => void | undefined) | undefined) => {
  const { state: userState } = useUser();
  const isAuthenticated = userState.auth;
  const { t } = useTranslation();
  const { currentSchema } = useUserSchemas(userState);
  const dispatch = useAppDispatch();

  const { data: { system_hostname: systemHostname } = {}, isFetching: isFetchingSystemHostname } =
    useGetCurrentSchemaInfoQuery(undefined, { skip: !isAuthenticated });

  const {
    data: navigationItemsData,
    isLoading: isLoadingNavigationItemsData,
    isFetching: isFetchingNavigationItemsData,
    isError,
  } = useGetNavigationItemsQuery(undefined, {
    skip: !isAuthenticated,
  });

  const {
    data: assginedRecordsCount = [],
    isLoading: areAssignedRecordCountLoading,
    isFetching: areAssignedRecordCountFetching,
  } = useGetAssignedRecordsCountQuery();

  const navigationPaths: Array<string> = [];
  const navigationItems = useMemo(() => {
    if (
      navigationItemsData &&
      !isLoadingNavigationItemsData &&
      assginedRecordsCount &&
      !areAssignedRecordCountLoading &&
      currentSchema?.value &&
      !isFetchingSystemHostname
    ) {
      return navigationItemsData
        .map((navigationItem) => {
          if ('EMBEDDED_URL' === navigationItem.type) {
            const embeddedNavItem = buildEmbeddedNavItem(navigationItem);
            if (embeddedNavItem) {
              navigationPaths.push(embeddedNavItem.to);
            }

            return embeddedNavItem;
          } else if ('MODULE' === navigationItem.type) {
            if ('MODULE' === navigationItem.itemType && navigationItem.viewId) {
              const path = `/${userState.user?.currentSchema}/modules/${navigationItem.viewId}`;
              navigationPaths.push(path);
              return {
                label: navigationItem.caption,
                icon: navigationItem.icon,
                to: path,
                badge: {
                  badgeNumber: getAssignedRecordCountForModule(navigationItem.id, assginedRecordsCount),
                  description: React.createElement(BadgeTooltip, {
                    id: navigationItem.id,
                    viewId: navigationItem.viewId,
                    name: navigationItem.caption,
                  }),
                  closeTooltipOnClick: true,
                  lazyLoad: true,
                } as BadgeItemProps,
                onClick: itemClickHandler,
              };
            } else if ('HOMEPAGE' === navigationItem.itemType) {
              navigationPaths.push('/home');
              return {
                label: t('home'),
                to: '/home',
                icon: 'HOME',
                onClick: itemClickHandler,
              };
            } else if ('DASHBOARD' === navigationItem.itemType) {
              if (userState.isDefaultIdp) {
                navigationPaths.push('/dashboard');
                return {
                  label: t('dashboard'),
                  to: '/dashboard',
                  icon: 'BAR_CHART_O',
                  onClick: itemClickHandler,
                };
              } else {
                // explicitly avoid pushing a navigationPaths entry
                return buildEmbeddedNavItem({
                  ...navigationItem,
                  caption: t('dashboard'),
                  icon: 'BAR_CHART_O',
                  sourceUrl: resolveDashboardUrl(systemHostname),
                  target: 'NEW_WINDOW',
                });
              }
            } else if ('VIKING_ANALYTICS' === navigationItem.itemType) {
              navigationPaths.push('/viking-analytics');
              return {
                label: t('viking-analytics'),
                to: '/viking-analytics',
                icon: 'BAR_CHART_O',
                onClick: itemClickHandler,
              };
            } else if ('MY_ACTIVITIES' === navigationItem.itemType) {
              const path = `/${currentSchema.value}/my-activities`;
              navigationPaths.push(path);
              return {
                label: t('my-activities'),
                to: path,
                icon: 'CHECK',
                onClick: itemClickHandler,
              };
            }
            return null;
          } else {
            return null;
          }
        })
        .filter((navigationItem) => navigationItem !== null) as SideNavigationItem[];
    }

    return [];
  }, [
    navigationItemsData,
    isLoadingNavigationItemsData,
    assginedRecordsCount,
    areAssignedRecordCountLoading,
    itemClickHandler,
    currentSchema?.value,
    systemHostname,
    isFetchingSystemHostname,
  ]);

  useEffect(() => {
    if (navigationItems.length > 0) {
      dispatch(setNavigationPaths(navigationPaths));
    }
  }, [navigationItems]);

  return {
    navigationItems,
    isLoading: isLoadingNavigationItemsData || areAssignedRecordCountLoading,
    isFetching: isFetchingNavigationItemsData || areAssignedRecordCountFetching,
    isError,
  };
};

export default useNavigationItems;

// utils
const buildEmbeddedNavItem = (navigationItem: NavigationItem) => {
  if (navigationItem.sourceUrl) {
    if ('NEW_WINDOW' === navigationItem.target) {
      const url = new URL(navigationItem.sourceUrl);
      return {
        label: navigationItem.caption,
        icon: navigationItem.icon,
        to: url.href,
        target: '_blank',
      };
    } else if ('CURRENT_WINDOW' === navigationItem.target) {
      return {
        label: navigationItem.caption,
        icon: navigationItem.icon,
        to: `/embedded/${navigationItem.viewId}`,
        state: {
          embeddedUrl: navigationItem.sourceUrl,
          title: navigationItem.caption,
        },
      };
    }
  }
  return null;
};

const getAssignedRecordCountForModule = (moduleId: number, assginedRecordsCount: AssignedRecordsCount[]) => {
  const currentModule = assginedRecordsCount.find((recordsCount) => recordsCount.moduleId === moduleId);
  return currentModule?.count;
};
