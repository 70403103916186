import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const FULL_SCREEN_QUERY_PARAM = 'fullScreen';

// A hook to resolve if the 'fullScreen' URL paramter is present in the current URL.
const useFullScreenQueryParam = () => {
  const { search } = useLocation();

  const fullScreen = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.has(FULL_SCREEN_QUERY_PARAM, 'true');
  }, [search]);

  return { fullScreen };
};

export default useFullScreenQueryParam;
