import { DropDownResult } from '@myosh/odin-components';
import {
  CurrentRecordPermisions,
  FormPermissionsResults,
  FormWorkflowStepAccessControl,
  WorkflowSectionPermissions,
} from '../@types/form-permissions';
import {
  DynamicFormAccessRights,
  DynamicFormGroupPersonFieldPermission,
  DynamicFormPermissions,
} from '../@types/forms';
import { RecordStructure } from '../@types/records';
import { OdinUser } from '../@types/users';
import { forceAssert } from './common-functions';
import { FormGroupPermissionType, FormGroupPersonFieldPermissionType } from './form-permissions';

/**
 * @param formPermissions - form permission response
 * @returns current form permissions
 */
export function extractFormPermissions(formPermissions?: FormPermissionsResults) {
  if (formPermissions) {
    const canCreateNewRecord = formPermissions.canCreateRecord && formPermissions.canReadRecord;
    const canDisplayNewRecordButton = formPermissions.canShowNewRecordButton;
    return {
      create: canCreateNewRecord,
      showCreateButton: canDisplayNewRecordButton,
    };
  }
}

// Extracts form permissions to Create/Select a linked record
export function extractLinkedRecordsPermissions(formPermissions?: FormPermissionsResults) {
  if (formPermissions) {
    return {
      create: formPermissions.canCreateRecord,
      read: formPermissions.canReadRecord,
    };
  }
}

/**
 *
 * @param recordPermissions - record permissions response
 * @returns current record permissions
 */

export function extractRecordPermissions(
  recordPermissions?: FormPermissionsResults
): CurrentRecordPermisions | undefined {
  if (recordPermissions) {
    const canEditRecord = recordPermissions.canEditRecord && recordPermissions.canReadRecord;
    const canDeleteRecord = recordPermissions.canDeleteRecord && recordPermissions.canReadRecord;
    const canProgressToNextStep = recordPermissions.canProgressRecord;
    const canViewRecordAudit = recordPermissions.canViewRecordAudit;
    return {
      edit: canEditRecord,
      delete: canDeleteRecord,
      progressToNextStep: canProgressToNextStep,
      viewRecordAudit: canViewRecordAudit,
    };
  }
}

/**
 * Returns the current workflow step from the form permissions data based on the current record status.
 *
 * If the `currentFormStatus` is not specified it defaults to the first workflow step.
 *
 * @param [formPermissions] {FormPermissionsResults} - The form permissions object
 * @param [currentFormStatus] {string} - The current status of the record (workflow step name)
 *
 * @return The current workflow step if found, `undefined` otherwise.
 */
export function currentWorkflowStep(formPermissions?: FormPermissionsResults, currentFormStatus?: string) {
  if (formPermissions) {
    // default to first step
    return currentFormStatus
      ? formPermissions.workflowSteps?.find((workflowStep) => workflowStep.label === currentFormStatus)
      : formPermissions.workflowSteps?.[0];
  }
}

// Extracts permissions to View/Edit (custom)form groups in the current workflow step
export function formGroupPermissions(
  formSectionPermissions?: Array<WorkflowSectionPermissions>,
  formGroupPersonFieldPermissions?: Array<DynamicFormGroupPersonFieldPermission>,
  fields?: Record<string, unknown>,
  customProperty?: keyof WorkflowSectionPermissions,
  fieldGroupId?: number | string,
  userId?: number
) {
  let formGroupPermissions: { read: boolean; edit: boolean } = {
    read: false,
    edit: false,
  };

  if (formSectionPermissions) {
    const permissions = formSectionPermissions.find((sectionPermission) => {
      return customProperty ? sectionPermission[customProperty] : sectionPermission.fieldGroupId === fieldGroupId;
    });

    formGroupPermissions = {
      read: permissions ? permissions.canRead : false,
      edit: permissions ? permissions.canEdit : false,
    };
  }

  if (formGroupPersonFieldPermissions && fields) {
    for (let i = 0; i < formGroupPersonFieldPermissions.length; i++) {
      const personFieldPermission = formGroupPersonFieldPermissions[i];
      for (let j = 0; j < personFieldPermission.personFieldIds.length; j++) {
        const personFieldId = personFieldPermission.personFieldIds[j];
        const personFieldValues = forceAssert<Array<DropDownResult> | DropDownResult | undefined>(
          fields[personFieldId]
        );

        let hasPersonSelected = false;
        if (Array.isArray(personFieldValues)) {
          hasPersonSelected = personFieldValues?.some((fieldValue) => fieldValue.value === userId);
        } else {
          hasPersonSelected = personFieldValues?.value === userId;
        }

        if (hasPersonSelected) {
          formGroupPermissions = {
            read:
              personFieldPermission.permissionType === FormGroupPermissionType.Read ? true : formGroupPermissions.read,
            edit:
              personFieldPermission.permissionType === FormGroupPermissionType.Edit ? true : formGroupPermissions.edit,
          };
          break;
        }
      }
    }
  }

  return formGroupPermissions;
}

export function customPropertyOfFormGroups(fieldName?: string) {
  switch (fieldName) {
    case 'hierarchyValues':
      return 'hierarchySection';
    case 'attachments':
      return 'attachmentSection';
    case 'version':
      return 'versionSection';
    case 'invitation':
      return 'invitationSection';
    default:
      return undefined;
  }
}

function personFieldFormGroupRolePermissionType(fieldName: string) {
  switch (fieldName) {
    case 'hierarchies':
      return {
        read: FormGroupPersonFieldPermissionType.PERSONFIELD_HIERARCHY_READ_ROLE,
        edit: FormGroupPersonFieldPermissionType.PERSONFIELD_HIERARCHY_EDIT_ROLE,
      };
    case 'attachments':
      return {
        read: FormGroupPersonFieldPermissionType.PERSONFIELD_ATTACHMENT_READ_ROLE,
        edit: FormGroupPersonFieldPermissionType.PERSONFIELD_ATTACHMENT_EDIT_ROLE,
      };
    case 'version':
      return {
        read: FormGroupPersonFieldPermissionType.PERSONFIELD_VERSION_READ_ROLE,
        edit: FormGroupPersonFieldPermissionType.PERSONFIELD_VERSION_EDIT_ROLE,
      };
    case 'invitation':
      return {
        read: FormGroupPersonFieldPermissionType.PERSONFIELD_VERSION_READ_ROLE,
        edit: FormGroupPersonFieldPermissionType.PERSONFIELD_INVITATION_EDIT_ROLE,
      };
    default:
      return {
        read: FormGroupPersonFieldPermissionType.PERSONFIELD_READ_ROLE,
        edit: FormGroupPersonFieldPermissionType.PERSONFIELD_EDIT_ROLE,
      };
  }
}

export function formGroupPersonFieldPermissions(
  groupId: string,
  workflowStepId?: number,
  currentStepRolePermissions?: Array<DynamicFormPermissions>,
  formGroupAccessRights?: Array<DynamicFormAccessRights>
) {
  const formGroupPersonFieldPermissions: Array<DynamicFormGroupPersonFieldPermission> = [];

  const permissionType = personFieldFormGroupRolePermissionType(groupId);
  if (currentStepRolePermissions) {
    for (let index = 0; index < currentStepRolePermissions.length; index++) {
      const permission = currentStepRolePermissions[index];
      if (
        permission.personFieldIds &&
        ((permission.fieldGroupId && permission.fieldGroupId === Number(groupId)) || !permission.fieldGroupId)
      ) {
        if (String(permission.permissionType) === permissionType.read) {
          formGroupPersonFieldPermissions.push({
            permissionType: FormGroupPermissionType.Read,
            personFieldIds: permission.personFieldIds,
          });
        } else if (String(permission.permissionType) === permissionType.edit) {
          formGroupPersonFieldPermissions.push({
            permissionType: FormGroupPermissionType.Edit,
            personFieldIds: permission.personFieldIds,
          });
        }
      }
    }
  }

  if (formGroupAccessRights) {
    for (let index = 0; index < formGroupAccessRights.length; index++) {
      const accessRight = formGroupAccessRights[index];
      if (accessRight.workflowStepId === workflowStepId && accessRight.personFieldIds) {
        if (String(accessRight.permissionType) === permissionType.read) {
          formGroupPersonFieldPermissions.push({
            permissionType: FormGroupPermissionType.Read,
            personFieldIds: accessRight.personFieldIds,
          });
        } else if (String(accessRight.permissionType) === permissionType.edit) {
          formGroupPersonFieldPermissions.push({
            permissionType: FormGroupPermissionType.Edit,
            personFieldIds: accessRight.personFieldIds,
          });
        }
      }
    }
  }

  return formGroupPersonFieldPermissions;
}

export function workflowStepCanProgressPermission(
  workflowSteps: Array<FormWorkflowStepAccessControl>,
  defaultRecordData: RecordStructure,
  currentWorkflowStepId: number,
  userData: OdinUser
) {
  let canProgress = false;

  for (let i = 0; i < workflowSteps.length && !canProgress; i++) {
    const workflowStep = workflowSteps[i];
    if (workflowStep.id === currentWorkflowStepId) {
      const workflowStepConfig = workflowStep.configs.find((step) => step.type === 'progress');

      // person field condition
      if (workflowStepConfig?.personFields) {
        for (let j = 0; j < workflowStepConfig.personFields.length; j++) {
          const personField = workflowStepConfig.personFields[j];
          const personFieldHasDefaultData = defaultRecordData.flat[String(personField.id)] as
            | DropDownResult
            | undefined;
          if (personFieldHasDefaultData && personFieldHasDefaultData.value === userData.id) {
            canProgress = true;
            break;
          }
        }
      }

      // OR group condition
      if (!canProgress && workflowStepConfig?.roles && userData.groups) {
        // check the users roles and see if they match the roles in the workflow step
        // FIXME: Once the API starts sending us translated data change the code below
        const groups = userData.groups.map((group) => group.caption.translations[0].value);
        const roles = workflowStepConfig.roles.map((role) => role.caption);
        canProgress = groups.some((group) => roles.includes(group));
      }
    }
  }

  return canProgress;
}
