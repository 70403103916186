import { JsonData, JsonDataItem } from '@myosh/odin-components';

export function createOccupationalRequirementsPatch(data: JsonDataItem, fields: JsonDataItem) {
  const dirtyFields = Object.keys(fields);
  const patch: JsonDataItem = {};

  for (let index = 0, length = dirtyFields.length; index < length; index++) {
    const fieldKey = dirtyFields[index];
    const fieldValue = data[fieldKey];
    if (!fieldValue) {
      continue;
    }
    switch (fieldKey) {
      case 'desirableCompetencies':
      case 'mandatoryCompetencies':
        if (Array.isArray(fieldValue)) {
          patch['desirableCompetencies'] = transformCompetencies(data['desirableCompetencies'] as JsonData);
          patch['mandatoryCompetencies'] = transformCompetencies(data['mandatoryCompetencies'] as JsonData);
        }
        break;

      case 'hierarchyFields':
        const hierarchyFields = fieldValue as Record<string, JsonData>;
        const keys = Object.keys(hierarchyFields);
        const transformedHierarchyFields = [];
        for (let i = 0; i < keys.length; i++) {
          const field = keys[i];
          const fieldValues = hierarchyFields[field]?.map((hierarchyValue) => ({ id: hierarchyValue.value }));

          if (fieldValues) {
            transformedHierarchyFields.push(...fieldValues);
          }
        }
        patch[fieldKey] = transformedHierarchyFields;
        break;
      case 'occupation':
        patch[fieldKey] = { id: (fieldValue as JsonDataItem).value };
        break;
      case 'hierarchyMatchingOption':
        patch[fieldKey] = (fieldValue as JsonDataItem).value;
        break;
      default:
        patch[fieldKey] = fieldValue;
        break;
    }
  }

  return patch;
}

const transformCompetencies = (competencies: JsonData) => {
  return competencies.reduce((mandatoryCompetencies: JsonData, item) => {
    if (item.value) {
      mandatoryCompetencies.push({ id: item.value });
    }
    return mandatoryCompetencies;
  }, []);
};
