import React from 'react';
import { DynamicFieldComponentProps, DynamicFieldDataProps, MakeRequired, RadioGroup } from '@myosh/odin-components';
import { LookupTypeFieldProps } from '../../../@types/forms';
import { Control } from 'react-hook-form';
import useOptionFieldOptions from '../multiselect-checkbox/use-option-field-options';

interface OptionGroupResult {
  value: unknown;
  text: string;
}

export interface OptionGroupProps
  extends MakeRequired<DynamicFieldComponentProps<OptionGroupResult>, 'name'>,
    DynamicFieldDataProps,
    LookupTypeFieldProps {
  control: Control;
  formId: number;
}

export default function OptionGroup({
  id,
  name,
  control,
  textField,
  valueField,
  formId,
  lookupType,
  lookupKeyId,
  lookupHierarchyId,
  ...rest
}: OptionGroupProps) {
  const { scrollerRef, getOptionsData } = useOptionFieldOptions(
    formId,
    control,
    lookupType,
    lookupKeyId,
    lookupHierarchyId
  );

  return (
    <RadioGroup
      {...rest}
      ref={scrollerRef}
      id={id}
      textField={textField}
      valueField={valueField}
      data={getOptionsData}
      name={name}
    />
  );
}
