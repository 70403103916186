import React from 'react';
import OdinDropDown from '../../fields/custom-drop-downs/odin-drop-down.component';

export type SchemaDropdownItem = {
  value: string;
  text: string;
};

interface SchemaSelectorProps {
  schemas: SchemaDropdownItem[];
  currentSchema: SchemaDropdownItem;
  updateCurrentSchema: (schema: SchemaDropdownItem) => void;
}

const SchemaSelector = ({ schemas, currentSchema, updateCurrentSchema }: SchemaSelectorProps) => {
  return (
    <div className="relative">
      <OdinDropDown
        valueField="value"
        textField="text"
        allowSearch={true}
        allowClear={false}
        setDefaultValue={true}
        data={schemas}
        value={currentSchema}
        onChange={(value) => {
          updateCurrentSchema(value as SchemaDropdownItem);
        }}
      />
    </div>
  );
};

export default SchemaSelector;
