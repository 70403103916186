import { DynamicFormRequiredType, DynamicFormSettings, JsonData } from '@myosh/odin-components';
import { ModuleFields } from '../../../common/module-config';
import i18next from '../../../i18n';

export const getModuleTypeData = (value?: string): JsonData => {
  const data = [
    {
      text: i18next.t('module'),
      value: 'MODULE',
    },
    {
      text: i18next.t('embedded-url'),
      value: 'EMBEDDED_URL',
    },
  ];

  if (value) {
    const foundData = data.find((item) => item.value === value);
    return foundData ? [foundData] : [];
  }

  return data;
};

export const getModuleTargetData = (value?: string): JsonData => {
  const data = [
    {
      text: i18next.t('current-window'),
      value: 'CURRENT_WINDOW',
    },
    {
      text: i18next.t('open-in-new-window'),
      value: 'NEW_WINDOW',
    },
  ];

  if (value) {
    const foundData = data.find((item) => item.value === value);
    return foundData ? [foundData] : [];
  }

  return data;
};

export const getModuleFormSettings = (): DynamicFormSettings => {
  return {
    id: 0,
    caption: '',
    fields: [
      {
        id: 100,
        fieldType: 'GROUP',
        fieldName: 'Module Details',
        caption: i18next.t('module-details'),
        position: 0,
        startOpen: true,
        permissions: { read: true, edit: true },
        fields: [
          {
            id: ModuleFields.viewId,
            fieldType: 'TEXTFIELD',
            fieldName: ModuleFields.viewId,
            caption: i18next.t('view-id'),
            required: DynamicFormRequiredType.True,
            position: 0,
          },
          {
            id: ModuleFields.caption,
            fieldType: 'TEXTFIELD',
            fieldName: ModuleFields.caption,
            caption: i18next.t('caption'),
            required: DynamicFormRequiredType.True,
            position: 1,
          },
          {
            id: ModuleFields.type,
            fieldType: 'OPTIONGROUP',
            fieldName: ModuleFields.type,
            caption: i18next.t('module-type'),
            dataSettings: {
              textField: 'text',
              valueField: 'value',
              data: getModuleTypeData(),
            },
            required: DynamicFormRequiredType.True,
            position: 2,
          },
          {
            id: ModuleFields.showAllFormsFirst,
            fieldType: 'CHECKBOX',
            fieldName: ModuleFields.showAllFormsFirst,
            caption: i18next.t('show-all-forms-first'),
            required: DynamicFormRequiredType.False,
            position: 3,
          },
          {
            id: ModuleFields.icon,
            fieldType: 'ICON_SELECTOR',
            fieldName: ModuleFields.icon,
            caption: i18next.t('icon'),
            required: DynamicFormRequiredType.True,
            position: 4,
          },
          {
            id: ModuleFields.hidden,
            fieldType: 'CHECKBOX',
            fieldName: ModuleFields.hidden,
            caption: i18next.t('hidden'),
            required: DynamicFormRequiredType.False,
            position: 5,
          },
          {
            id: ModuleFields.target,
            fieldType: 'OPTIONGROUP',
            fieldName: ModuleFields.target,
            caption: i18next.t('target'),
            dataSettings: {
              textField: 'text',
              valueField: 'value',
              data: getModuleTargetData(),
            },
            required: DynamicFormRequiredType.True,
            position: 6,
          },
          {
            id: ModuleFields.displayInMobile,
            fieldType: 'CHECKBOX',
            fieldName: ModuleFields.displayInMobile,
            caption: i18next.t('display-in-mobile'),
            required: DynamicFormRequiredType.False,
            position: 7,
          },
        ],
      },
    ],
  };
};
