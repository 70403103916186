import React from 'react';
import {
  AnswerItemConfiguration,
  AnswersConfig,
  QuestionConfigurationItem,
  QuestionnaireValue,
} from '../../../@types/questionnaire';
import { useTranslation } from 'react-i18next';
import { forceAssert } from '../../../common/common-functions';

interface QuestionnaireScoreProps {
  answersConfig: AnswersConfig;
  answers: QuestionnaireValue;
  questions: Array<QuestionConfigurationItem>;
}

const QuestionnaireScore = ({ answersConfig, answers, questions }: QuestionnaireScoreProps) => {
  const { t } = useTranslation();
  let totalScore = 0;
  const answerGroups: { [key: string]: number } = {};

  forceAssert<AnswerItemConfiguration[]>(answersConfig).forEach(
    (answerConfig) => (answerGroups[answerConfig.caption] = 0)
  ); // initiate group map

  forceAssert<AnswerItemConfiguration[]>(answersConfig).forEach((answerConfig) => {
    answers.forEach((answer) => {
      if (answer.id === answerConfig.id) {
        if (answerGroups.hasOwnProperty(answerConfig.caption)) {
          answerGroups[answerConfig.caption] = answerGroups[answerConfig.caption] + 1;
        } else {
          answerGroups[answerConfig.caption] = 1;
        }
      }
      questions.forEach((question) => {
        if (answer.id === answerConfig.id && question.id === answer.questionId) {
          totalScore += answerConfig.score * question.weight;
        }
      });
    });
  });
  const averageScore = answers.length > 0 ? totalScore / answers.length : 0;

  const scoreStyles = 'text-sm';

  return (
    <div className={scoreStyles}>
      {answers.length > 0 && (
        <>
          {Object.keys(answerGroups)
            .filter((key) => answerGroups[key] > 0)
            .map((key) => `${key.toUpperCase()}: ${answerGroups[key]} - `)
            .join('')}
        </>
      )}
      {`${t('score-total')}: ${totalScore.toFixed(2)} - ${t('score-average')}: ${averageScore.toFixed(2)}`}
    </div>
  );
};

export default QuestionnaireScore;
