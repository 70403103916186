import { useCallback, useState } from 'react';

const useShowRecordUnavailable = () => {
  const [unavailableAfterSaveFieldList, setUnavailableAfterSaveFieldList] = useState<Array<string>>([]);

  const showRecordWillBecomeUnavailableMessage = useCallback((field: string) => {
    setUnavailableAfterSaveFieldList((prevState) => [...prevState, field]);
  }, []);

  const hideRecordWillBecomeUnavailableMessage = useCallback((field: string | string[]) => {
    setUnavailableAfterSaveFieldList((prevState) =>
      prevState.filter((_field) => (Array.isArray(field) ? !field.includes(_field) : _field !== field))
    );
  }, []);

  return {
    isRecordAvailableAfterSave: unavailableAfterSaveFieldList.length === 0,
    showRecordWillBecomeUnavailableMessage,
    hideRecordWillBecomeUnavailableMessage,
  };
};

export default useShowRecordUnavailable;
