import React, { useMemo } from 'react';
import {
  DataGrid,
  JsonDataWrapper,
  OdinDataRetrieval,
  OdinDataSender,
  OdinDataRetrievalOptions,
  JsonData,
} from '@myosh/odin-components';
import { forceAssert } from '../../../common/common-functions';
import { useLazyGetNotificationLogQuery } from '../../../redux/services/record';
import { datagridNotificationLogColumns } from './datagrid-columns-config';
interface NotificationProps {
  recordId?: string;
}

const NotificationLog = ({ recordId }: NotificationProps) => {
  const [getNotificationLog] = useLazyGetNotificationLogQuery();

  const gridData = useMemo<OdinDataRetrieval>(() => {
    return {
      getData: (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if (options?.page === 1 && recordId) {
          getNotificationLog({ recordId })
            .unwrap()
            .then((data) => {
              subscriber.sendData({ data: forceAssert<JsonData>(data), requestId: options.requestId });
            })
            .catch(() => {
              subscriber.sendData();
            });
        } else {
          subscriber.sendData();
        }
      },
    };
  }, []);

  return <DataGrid data={gridData} gridSettings={datagridNotificationLogColumns} customSettingsMenuItems={[]} />;
};

export default NotificationLog;
