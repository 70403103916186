import React from 'react';
import { Control, Controller } from 'react-hook-form';
import TargetRecord, { TargerRecordProps } from './target-record.component';

export interface HfTargerRecordLinkProps extends TargerRecordProps {
  control?: Control;
  name?: string;
}

export default function HfTargetRecord({
  control,
  name,
  formId,
  label,
  value,
  readOnly,
  required,
  showSelectButton,
  ...rest
}: HfTargerRecordLinkProps) {
  return (
    <Controller
      name={name as string}
      control={control}
      defaultValue={value}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TargetRecord
          formId={formId}
          label={label}
          value={value}
          error={error?.message}
          onChange={onChange}
          readOnly={readOnly}
          required={required}
          showSelectButton={showSelectButton}
          {...rest}
        />
      )}
    />
  );
}
