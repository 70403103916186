import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { showInfo, dismiss, showSuccess, showError } from '../services/notification.service';

const useFileGenerationToast = (isFetching: boolean, isSuccess?: boolean, isError?: boolean) => {
  const toastId = useRef<string>();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dismiss(toastId.current);
    };
  }, []);

  useEffect(() => {
    if (isFetching) {
      toastId.current = showInfo(t('performing-file-generation-message'), {
        duration: Infinity,
      });
    } else if (isSuccess) {
      dismiss(toastId.current);
      showSuccess(t('file-generation-success-message'));
    } else if (isError) {
      dismiss(toastId.current);
      showError(t('file-generation-failed'));
    } else {
      dismiss(toastId.current);
    }
  }, [isFetching, isSuccess, isError]);

  return {};
};

export default useFileGenerationToast;
