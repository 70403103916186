import React, { useState, useEffect, ReactNode, ReactNodeArray } from 'react';
import {
  DataGrid,
  DataGridDataRowTemplateProps,
  DataGridSettings,
  DataGridRowIndicator,
  RowTemplateType,
  DataGridColumnSettings,
  JsonData,
} from '@myosh/odin-components';
import { forceAssert } from '../../../../common/common-functions';
import useGridSettings from '../../../../hooks/use-grid-settings';
import useFetchRecords from '../../../../hooks/use-fetch-records';
import useRenderDataGrid from '../../../../hooks/use-render-data-grid';
import { DataGridLoader } from '../../../dynamic-page/data-grid-loader.component';

interface LinkedRecordsGridProps {
  recordIds: Array<string>;
  formId?: number;
  configId?: number;
  defaultGridSettings: DataGridSettings;
  columnConfigurator: (configColumn: DataGridColumnSettings) => DataGridColumnSettings;
  rowIndicator?: DataGridRowIndicator;
  onRemoved: (id: string) => void;
}

// Displays all records that are already linked to the field
export default function LinkedRecordsGrid({
  recordIds,
  formId,
  configId,
  defaultGridSettings,
  columnConfigurator,
  rowIndicator,
  onRemoved,
}: LinkedRecordsGridProps) {
  const [gridData, setGridData] = useState<JsonData>([]);
  const [linkedRecordsGridSettings, setLinkedRecordsGridSettings] = useState<DataGridSettings>();

  const {
    settings: gridSettings,
    error: gridSettingsError,
    loading: gridSettingsLoading,
  } = useGridSettings(defaultGridSettings, columnConfigurator, formId, configId);
  const { records } = useFetchRecords(recordIds, true);

  useEffect(() => {
    setGridData(forceAssert<JsonData>(records));
  }, [records]);

  useEffect(() => {
    if (!gridSettingsLoading && gridSettings?.columns) {
      setLinkedRecordsGridSettings({
        ...gridSettings,
        initialFieldFilters: [],
        initialSortedFields: [],
      });
    }
  }, [gridSettings, gridSettingsLoading]);

  const removeLinkedRecord = (id: string) => onRemoved(id);

  const linkedRowTemplate: RowTemplateType = (
    props: DataGridDataRowTemplateProps,
    children: ReactNode | ReactNodeArray
  ) => (
    // eslint-disable-next-line react/prop-types
    <div className="flex" onClick={() => removeLinkedRecord(String(props.data['id']))}>
      {children}
    </div>
  );

  const canRenderDataGrid = !gridSettingsLoading && !!gridSettings?.columns && !!linkedRecordsGridSettings?.columns;
  const { renderDataGrid } = useRenderDataGrid(canRenderDataGrid);

  return (
    <div className="flex h-full flex-col gap-4">
      {!renderDataGrid && !gridSettingsError && <DataGridLoader />}
      {canRenderDataGrid && (
        <>
          <DataGrid
            data={gridData}
            gridSettings={linkedRecordsGridSettings}
            rowIndicator={rowIndicator}
            rowTemplate={linkedRowTemplate}
            showGridMenu={false}
          />
          <div className="px-10 font-semibold">
            {gridData.length}/{gridData.length}
          </div>
        </>
      )}
    </div>
  );
}
