import {
  RiskMatrixData,
  RiskMatrixDataItem,
  RiskMatrixDataResponse,
  RiskMatrixCellDataProps,
  CategoryRateItem,
} from '../../../@types/risk-matrix-field';
import { RiskRatingValueDisplayKey } from '../../../@types/forms';
import i18next from '../../../i18n';
import { Caption } from '../../../@types/common';
import { showError, showInfo, showSuccess } from '../../../services/notification.service';

// given the fact that we need to render consequences as columns, and likelihood as rows,
// we need to extract the values for each of them, and avoid the duplicates ones
export function uniqueRiskMatrixColumns(consequenceColumns: RiskMatrixData, riskMatrixElement: RiskMatrixDataResponse) {
  if (consequenceColumns) {
    return consequenceColumns.find((consequenceColumn: RiskMatrixDataItem) => {
      if (consequenceColumn) {
        return consequenceColumn.id === riskMatrixElement?.consequence?.id;
      }
    });
  }
}

// sorting likelihood rows, to match the risk rating matrix in Viking
// Body's cells must be sorted by rating
// Header's cells must be sorted by position (a header's cell doesn't have a `rating`, but a `position`)
export function riskMatrixCellsSorted(cellPlaceholder: RiskMatrixCellDataProps) {
  return Object.values(cellPlaceholder).map((placeholder: RiskMatrixData, index) => {
    if (index === 0) {
      return placeholder.sort(
        (firstElement: RiskMatrixDataItem, secondElement: RiskMatrixDataItem) =>
          firstElement && secondElement && Number(firstElement.position) - Number(secondElement.position)
      );
    }
    return placeholder.sort(
      (firstElement: RiskMatrixDataItem, secondElement: RiskMatrixDataItem) =>
        firstElement &&
        secondElement &&
        Number(firstElement.consequencePosition) - Number(secondElement.consequencePosition)
    );
  });
}

export function findRiskMatrixCaption(riskMatrixCellData?: Caption) {
  if (riskMatrixCellData) {
    //FIXME: Once the API provides us with the users translation change this code
    return riskMatrixCellData.translations[0]?.value;
  }
}

export function additionalRiskCellItems(riskMatrixElement: RiskMatrixDataResponse) {
  if (riskMatrixElement) {
    return {
      rating: riskMatrixElement.rating,
      ratingId: riskMatrixElement.id,
      category: riskMatrixElement.riskLevel?.category,
    };
  }
}

export function additionalRiskLevelCaptions(riskMatrixElement: RiskMatrixDataResponse) {
  const itemCaptions = {
    consequenceCaption: findRiskMatrixCaption(riskMatrixElement.consequence.caption),
    consequenceDescription: findRiskMatrixCaption(riskMatrixElement.consequence.descriptionCaption),
    likelihoodCaption: findRiskMatrixCaption(riskMatrixElement.likelihood.caption),
    likelihoodDescription: findRiskMatrixCaption(riskMatrixElement.likelihood.descriptionCaption),
  };
  return {
    consequenceCaption: itemCaptions.consequenceCaption,
    consequenceDescription: itemCaptions.consequenceDescription,
    likelihoodCaption: itemCaptions.likelihoodCaption,
    likelihoodDescription: itemCaptions.likelihoodDescription,
  };
}

export function findSelectedRiskRating(ratingData: Array<RiskMatrixData>, ratingId: number) {
  for (let i = 0, length = ratingData.length; i < length; i++) {
    const ratingDataRow = ratingData[i];
    for (let j = 0, rowLength = ratingDataRow.length; j < rowLength; j++) {
      if (ratingDataRow[j].ratingId === ratingId) {
        return ratingDataRow[j];
      }
    }
  }
}

export function formatRiskRatingDisplayValue(
  riskLevelCells?: RiskMatrixDataItem,
  valueDisplayConfig?: Array<RiskRatingValueDisplayKey>
) {
  if (valueDisplayConfig) {
    const ratingCaptions = {
      riskLevel: findRiskMatrixCaption(riskLevelCells?.caption),
      likelihood: riskLevelCells?.riskLevelCaptions?.likelihoodCaption,
      consequence: riskLevelCells?.riskLevelCaptions?.consequenceCaption,
    };

    const displayValueMap: Record<RiskRatingValueDisplayKey, CategoryRateItem> = {
      CATEGORY: {
        label: i18next.t('category'),
        value: riskLevelCells?.category,
      },
      RATING_NAME: {
        label: i18next.t('rating'),
        value: riskLevelCells?.rating,
      },
      RISK_LEVEL_NAME: {
        label: i18next.t('risk-level'),
        value: ratingCaptions.riskLevel,
      },
      LIKELIHOOD_NAME: {
        label: i18next.t('likelihood'),
        value: ratingCaptions.likelihood,
      },
      CONSEQUENCE_NAME: {
        label: i18next.t('consequence'),
        value: ratingCaptions.consequence,
      },
    };

    return valueDisplayConfig
      .map((item) => `${displayValueMap[item].label}: ${displayValueMap[item].value}`)
      .join(', ');
  }
}

export const handleRiskMatrixApiResponse = (promise: Promise<void>) => {
  showInfo(i18next.t('performing-save'));
  return promise
    .then(() => {
      showSuccess(i18next.t('record-saved'));
    })
    .catch(() => {
      showError(i18next.t('save-failed'));
    });
};
