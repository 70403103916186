import React from 'react';
import { Navigate } from 'react-router-dom';
import { selectDefaultModulePath } from '../../../redux/slices/layout';
import { useAppSelector } from '../../../redux/hooks';

/* Navigates to the default module */
function DefaultRedirectRoute() {
  const defaultModulePath = useAppSelector(selectDefaultModulePath);
  return defaultModulePath ? <Navigate to={defaultModulePath} /> : null;
}

export default DefaultRedirectRoute;
