import {
  DropDownResult,
  DynamicFormRequiredType,
  fieldValidationErrorMessage,
  HfInput,
  HfRichTextArea,
  ModalDialog,
  ModalDialogButtonSetting,
} from '@myosh/odin-components';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  useEditRecordCollaboratorMutation,
  useLazyGetCollaboratorEmailQuery,
  useLazyGetExternalUserEmailTemplateQuery,
  useSendInvitationEmailMutation,
} from '../../../redux/services/record';
import { FormLoading } from '../../form/form-loading.component';
import useProfileData from '../../../hooks/use-profile-data';
import { useGetExternalUsersQuery } from '../../../redux/services/user';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Invitation } from '../invitation-field/invitation-field.component';
import HfDropDownMultiSelect from '../custom-drop-downs/hf-drop-down-multi-select.component';
import { showError, showInfo, showSuccess } from '../../../services/notification.service';

export interface ExternalUserEmailTemplate {
  id?: number;
  body: string;
  subject: string;
  recipient?: string;
  externalUserIds?: Array<number>;
}

export interface ExternalEmailInvitation {
  recordId: number;
  template: ExternalUserEmailTemplate;
}

interface InviteDialogProps {
  fieldId?: number;
  invitationEmail?: Invitation;
  recordId: number;
  visible: boolean;
  hide: () => void;
}

function InviteDialog({ fieldId, invitationEmail, recordId, visible, hide }: InviteDialogProps) {
  const { t } = useTranslation();
  const [externalUserEmailTemplate, setExternalUserEmailTemplate] = useState<ExternalUserEmailTemplate>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [getCollaboratorEmail] = useLazyGetCollaboratorEmailQuery();
  const [getExternaluserEmailTemplate] = useLazyGetExternalUserEmailTemplateQuery();

  useEffect(() => {
    if (visible) {
      if (invitationEmail?.collaboratorEmailId) {
        setIsLoading(true);
        getCollaboratorEmail(invitationEmail.collaboratorEmailId)
          .unwrap()
          .then((response) => {
            setExternalUserEmailTemplate(response);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(true);
        getExternaluserEmailTemplate(null)
          .unwrap()
          .then((response) => {
            const updatedBody = updateEmailTemplateUserInMessage(response.body);
            setExternalUserEmailTemplate({ ...response, body: updatedBody });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [visible]);

  const { data: externalUsers } = useGetExternalUsersQuery(undefined, {
    skip: !visible || invitationEmail !== undefined,
  });

  const [sendInvitation, { isLoading: postInviteLoading }] = useSendInvitationEmailMutation();
  const [editRecordCollaborator, { isLoading: editInviteLoading }] = useEditRecordCollaboratorMutation();

  const {
    profileData: { user },
  } = useProfileData();

  const schema = yup.object().shape({
    externalUsers: yup.array().nullable(),
    email: yup.string().when('externalUsers', {
      is: (value: Array<DropDownResult> | undefined) => (value && value.length > 0) ?? false,
      then: (schema) => schema,
      otherwise: (schema) =>
        schema.email(t('email-validation')).required(fieldValidationErrorMessage(t('email-address'))),
    }),
    subject: yup.string().required(fieldValidationErrorMessage(t('subject'))),
    body: yup.string().required(fieldValidationErrorMessage(t('message'))),
  });

  const { control, handleSubmit, reset, watch, clearErrors, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      email: invitationEmail?.email,
      subject: '',
      body: '',
    } as FieldValues,
  });

  useEffect(() => {
    if (externalUserEmailTemplate) {
      reset(externalUserEmailTemplate);
    }
  }, [externalUserEmailTemplate]);

  useEffect(() => {
    if (invitationEmail) {
      setValue('email', invitationEmail?.email);
    }
  }, [invitationEmail]);

  useEffect(() => {
    const formSelectedExternalUsers = watch('externalUsers');
    if (formSelectedExternalUsers && formSelectedExternalUsers.length > 0) {
      clearErrors('email');
    }
  }, [watch('externalUsers')]);

  const updateEmailTemplateUserInMessage = (message: string) => {
    return message.replaceAll('{Current user}', `${user?.lastName} ${user?.firstName}`);
  };

  const onSubmit = (data: FieldValues) => {
    if (invitationEmail) {
      const invitationData: ExternalUserEmailTemplate = {
        id: invitationEmail.id,
        recipient: data['email'],
        subject: data['subject'],
        body: data['body'],
      };
      showInfo(t('invitation-in-progress'));
      editRecordCollaborator(invitationData)
        .unwrap()
        .then(() => {
          showSuccess(t('invitation-sent'));
          hide();
        })
        .catch(() => {
          showError(t('error-re-sending-invitation'));
          hide();
        });
    } else {
      const externalUsers: Array<DropDownResult> | undefined = data['externalUsers'];
      const externalUserIds = externalUsers?.map((user) => Number(user.value));
      const invitationData: ExternalEmailInvitation = {
        recordId: recordId,
        template: {
          id: fieldId,
          recipient: data['email'],
          externalUserIds: externalUserIds,
          subject: data['subject'],
          body: data['body'],
        },
      };
      showInfo(t('invitation-in-progress'));
      sendInvitation(invitationData)
        .unwrap()
        .then((value) => {
          //invitation is sent
          if (value === true) {
            showSuccess(t('invitation-sent'));
            reset(externalUserEmailTemplate);
            hide();
          }
        })
        .catch(() => {
          showError(t('error-sending-invitation', { count: externalUserIds?.length }));
          hide();
        });
    }
  };

  const getSortedExternalUsers = useCallback(() => {
    const sortedExternalUsers = orderBy(externalUsers, (user) => user.lastName?.toLowerCase());
    return sortedExternalUsers.map((user) => {
      return { value: user.id, text: `${user.lastName} ${user.firstName}` };
    });
  }, [externalUsers]);

  const onError = (data: FieldValues) => console.error(data);

  const hideModal = () => {
    reset(externalUserEmailTemplate);
    hide();
  };

  const modalButtons: ModalDialogButtonSetting[] = [
    {
      name: 'invite',
      text: invitationEmail ? t('resend-invitation') : t('invite'),
      form: 'inviteForm',
      type: 'primary',
      disabled: isLoading || postInviteLoading || editInviteLoading,
    },
  ];

  return (
    <ModalDialog
      header={t('invite-modal-header')}
      visible={visible}
      minDialogWidth={600}
      maxDialogWidth={600}
      minDialogHeight={550}
      maxDialogHeight={550}
      buttons={modalButtons}
      buttonType="submit"
      buttonsCloseModal={false}
      hidden={hideModal}
    >
      <form id="inviteForm" onSubmit={handleSubmit(onSubmit, onError)}>
        {!isLoading && (
          <>
            <HfInput
              control={control}
              name="email"
              label={t('email-address')}
              readOnly={false}
              required={DynamicFormRequiredType.True}
            />

            {!invitationEmail && (
              <HfDropDownMultiSelect
                control={control}
                name="externalUsers"
                data={getSortedExternalUsers()}
                textField="text"
                valueField="value"
                label={t('external-users-label')}
              />
            )}
            <HfInput control={control} name="subject" label={t('subject')} />
            <HfRichTextArea control={control} name="body" label={t('message')} />
          </>
        )}
        {isLoading && (
          // <FormLoading /> has 'position: absolute', so this needs to have 'position: relative'
          <div className="mt-2 relative">
            <FormLoading />
          </div>
        )}
      </form>
    </ModalDialog>
  );
}

export default InviteDialog;
