import {
  Button,
  DataGrid,
  DataGridColumnSettings,
  DataGridRef,
  DataGridSettings,
  DynamicFieldComponentProps,
  DynamicFormFieldType,
  FieldLabel,
  JsonData,
  JsonDataWrapper,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  RequiredIndicator,
} from '@myosh/odin-components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Observable } from 'rxjs';
import { useLazyHierarchyValuesQuery } from '../../../redux/services/hierarchy';
import { forceAssert } from '../../../common/common-functions';
import i18next from 'i18next';
export interface HierarchyValuesTableProps extends DynamicFieldComponentProps<unknown> {
  isPreview?: boolean;
  hierarchyId: number;
  formId: number;
  saveRecord?: () => Observable<unknown>;
}

const comboboxData = [
  { value: true, text: i18next.t('true') },
  { value: false, text: i18next.t('false') },
];

const hierarchyValuesColumns: Array<DataGridColumnSettings> = [
  {
    id: 0,
    field: 'id',
    title: i18next.t('id'),
    searchType: 'Integer',
    visible: true,
    disableSort: true,
  },
  {
    id: 1,
    field: 'caption',
    title: i18next.t('name'),
    searchType: 'TextSearch',
    visible: true,
    disableSort: true,
  },
  {
    id: 2,
    field: 'hierarchyFieldParentCaption',
    searchType: 'TextSearch',
    title: i18next.t('parent'),
    visible: true,
    disableSort: true,
  },
  {
    id: 3,
    field: 'archived',
    title: i18next.t('archived'),
    visible: true,
    disableSort: true,
    searchType: 'SingleSelect',
    searchValue: comboboxData[1],
    dropDownText: 'text',
    dropDownValue: 'value',
    type: 5,
    searchComparison: 1,
  },
];

const HierarchyValuesTable = ({ hierarchyId, label, readOnly, required }: HierarchyValuesTableProps) => {
  const { t } = useTranslation();

  const [gridOptions, setGridOptions] = useState<OdinDataRetrievalOptions>();

  const gridSubscriber = useRef<OdinDataSender<JsonDataWrapper>>();
  const gridRef = useRef<DataGridRef>();

  const [getHierarchyValues] = useLazyHierarchyValuesQuery();

  useEffect(() => {
    getHierarchyValues({
      page: gridOptions?.page || 1,
      pageSize: gridOptions?.pageSize || 50,
      filters: {
        ...gridOptions?.fieldFilters,
        typeId: {
          value: hierarchyId,
          comparison: '=',
        },
      },
    })
      .unwrap()
      .then((res) => {
        if (res.items.length > 0) {
          gridSubscriber.current?.sendData({
            data: forceAssert<JsonData>(res.items),
            requestId: gridOptions?.requestId,
          });

          if (res.availablePages === gridOptions?.page) {
            gridSubscriber.current?.sendData();
          }
        } else {
          gridSubscriber.current?.sendData();
        }
      });
  }, [gridOptions]);

  const dataRetrieval: OdinDataRetrieval = useMemo(() => {
    return {
      getData: (dataResult: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if (options?.fieldType === 'COMBOBOX') {
          if (options?.page === 1 || options.getAllData === true) {
            dataResult.sendData({ data: comboboxData, requestId: options.requestId });
          } else {
            dataResult.sendData();
          }
        } else {
          setGridOptions(options);
        }
      },
      getSubscriber: (subscriber: OdinDataSender<JsonDataWrapper>, fieldType?: DynamicFormFieldType) => {
        if (fieldType !== 'COMBOBOX') {
          gridSubscriber.current = subscriber;
        }
      },
    };
  }, []);

  const onDataGridRefCreated = (dataGridRef: DataGridRef) => {
    gridRef.current = dataGridRef;
  };

  const gridSettings: DataGridSettings = {
    columns: hierarchyValuesColumns,
    autoSizeColumns: true,
    fullHeight: true,
    filterLocation: 1,
  };

  return (
    <div>
      {label && (
        <div className="flex mb-3">
          <FieldLabel label={label} />
          <RequiredIndicator readOnly={readOnly} required={required} />
        </div>
      )}
      {!readOnly && (
        <div className="'my-2 flex flex-row gap-2 ">
          <Button variant="alternative" classNames="bg-gray-5">
            {t('add')}
          </Button>
        </div>
      )}

      <div className="my-2 flex max-h-[40rem]">
        <DataGrid
          ref={(gridRef) => gridRef && onDataGridRefCreated(gridRef)}
          data={dataRetrieval}
          gridSettings={gridSettings}
          showSettings={false}
        />
      </div>
    </div>
  );
};

export default HierarchyValuesTable;
