import React, { ReactNode } from 'react';
import { RowTemplateType, DataGridDataRowTemplateProps } from '@myosh/odin-components';
import { ActiveRecordRef } from '../active-record/active-record.component';
import { forceAssert } from '../../common/common-functions';
import { Module } from '../../@types/modules';
import { RecordTitle } from '../record/record-title.component';
import { constructRecordTitle } from '../record/record-component-util';
import useDoubleClick from '../../hooks/use-double-click';
import { showWarning } from '../../services/notification.service';
import { useTranslation } from 'react-i18next';

export enum LinkedRecordRowProps {
  id = 'id',
  moduleId = 'moduleId',
  formName = 'formName',
  docNo = 'docNo',
  status = 'status',
  date = 'lastModifiedDate',
}

export enum ActivititesPageRecordRowProps {
  id = 'id',
  moduleId = 'moduleId',
  formName = 'formName',
  docNo = 'docNo',
  status = 'status',
  date = 'lastModifiedDateTime',
}

interface OdinDataGridRowTemplateProps {
  modules?: Module[];
  activeRecordReference?: ActiveRecordRef | null;
  // FIXME: Make this a real type
  recordFields?: Record<string, unknown>;
  isClickable?: boolean;
  isReverseRecordLinkField?: boolean;
  listOfPermittedRecordIds?: string[];
}

export function OdinDataGridRowTemplate({
  modules,
  activeRecordReference,
  recordFields = LinkedRecordRowProps || ActivititesPageRecordRowProps,
  isClickable = true,
  isReverseRecordLinkField = false,
  listOfPermittedRecordIds,
}: OdinDataGridRowTemplateProps) {
  const { rowClickHandler } = useDoubleClick();
  const { t } = useTranslation();

  const rowTemplate: RowTemplateType = (
    props: DataGridDataRowTemplateProps,
    children: ReactNode | Array<ReactNode>
  ) => {
    const module = modules?.find(
      (item: unknown) => forceAssert<{ id: number }>(item).id === props.data[recordFields?.moduleId as number]
    );

    const hasPermissionsToRead = listOfPermittedRecordIds?.includes(String(props.data.id));
    const canOpenReverseRecordLinkRecord = isReverseRecordLinkField ? hasPermissionsToRead : true;

    if (module && isClickable && canOpenReverseRecordLinkRecord) {
      const date = new Date(props.data[recordFields.date as string] as string | Date);
      const title = returnRecordTitle(props, recordFields);
      const details = <RecordTitle title={title} date={date} />;

      const handleClick = (isFullScreen: boolean) => {
        const recordId = props.data[recordFields.id as number];
        activeRecordReference?.addActiveRecord(String(recordId), module.icon, details, isFullScreen, {
          title,
          recordId: recordId,
          viewId: module.viewId,
          moduleIcon: module.icon,
          formId: props.data.formId,
          formName: props.data[recordFields.formName as string],
          docNo: props.data[recordFields.docNo as string],
          status: props.data[recordFields.status as string],
          vikingLinkUrl: recordFields.vikingLinkUrl ? props.data[recordFields.vikingUrl as string] : undefined,
          displayText: recordFields.displayText ? props.data[recordFields.displayText as string] : undefined,
        });
      };

      const handleRowClick = () => {
        rowClickHandler(handleClick);
      };

      return (
        <div className="flex w-full cursor-pointer" onClick={handleRowClick}>
          {children}
        </div>
      );
    } else {
      const handleRowClickWithNoPermissions = () => {
        showWarning(t('no-record-permissions'));
      };
      return (
        <div className="flex w-full cursor-pointer" onClick={handleRowClickWithNoPermissions}>
          {children}
        </div>
      );
    }
  };

  return rowTemplate;
}

const returnRecordTitle = (props: DataGridDataRowTemplateProps, recordFields: Record<string, unknown>) => {
  const formName = String(props.data[recordFields.formName as string]);
  const docNo = String(props.data[recordFields.docNo as string]);
  const status = String(props.data[recordFields.status as string]);

  return constructRecordTitle(formName, docNo, status);
};
