import {
  DataGridRef,
  DropDownNoSelection,
  DropDownOptionTemplateProps,
  DropDownResult,
  fieldValidationErrorMessage,
  IconButton,
  isDropDownNoSelection,
  isDropDownResult,
  JsonData,
  ModalDialog,
  ModalDialogButtonSetting,
  OdinIcon,
  OdinIconSize,
  OdinIconType,
  Tooltip,
  useScreenSize,
  useTestTag,
} from '@myosh/odin-components';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { forceAssert } from '../../common/common-functions';
import {
  defaultLastEditedFilterOption,
  defaultRecordFilterOption,
  lastEditedFilterOptions,
  recordFilterOptions,
  resolveLastEditedFilterConfigValue,
  resolveRecordFilterConfigValue,
} from '../../common/dynamic-page-utils';
import useViewConfigs from '../../hooks/use-view-configs';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import { recordApi } from '../../redux/services/record';
import {
  dynamicPageState,
  setSelectedForm,
  setSelectedLastEditedFilter,
  setSelectedRecordsFilter,
  setSelectedViewConfig,
} from '../../redux/slices/dynamic-page-slice';
import { Module, ModuleForm } from '../../@types/modules';
import { ViewConfig } from '../../@types/views';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateViewConfigMutation } from '../../redux/services/view';
import { Subject, takeUntil } from 'rxjs';
import { getDataGridReference } from '../../services/data-grid.service';
import { userApi } from '../../redux/services/user';
import PrimaryViewActionButton from '../common/primary-view-action-button';
import { fileApi } from '../../redux/services/file';
import HfDropDown from '../fields/custom-drop-downs/hf-drop-down.component';
import OdinDropDown from '../fields/custom-drop-downs/odin-drop-down.component';
import useActiveRecordRef from '../../hooks/use-active-record-ref';
import { RecordTitle } from '../record/record-title.component';
import { RecordSaveState } from '../record/record.component';
import { FormPermissionsResults } from '../../@types/form-permissions';
import DataGridMenu from './menu/data-grid-menu';
import classNames from 'classnames';
import GlobalHierarchiesFilter from './global-hierarchies-filter.component';

interface DynamicPageHeaderProps {
  selectedForm: DropDownResult;
  module: Module;
  forms: ModuleForm[];
  viewHasChanged?: boolean;
  onFormChange: (form: DropDownResult) => void;
  permissions?: { create?: boolean; showCreateButton?: boolean };
  formPermissions?: FormPermissionsResults;
}

function DynamicPageHeader({
  selectedForm,
  module,
  forms,
  viewHasChanged,
  onFormChange,
  permissions,
  formPermissions,
}: DynamicPageHeaderProps) {
  const [isSelectFormModalVisible, setIsSelectFormModalVisible] = useState(false);

  const gridRef = useRef<DataGridRef>();
  const destroySubject = useRef<Subject<void>>();

  const { selectedFilters } = useAppSelector(dynamicPageState);
  const isAllForms = selectedForm.value === 0;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { activeRecordReference } = useActiveRecordRef();
  const { isMobile } = useScreenSize();

  const [updateLastUsedView] = useUpdateViewConfigMutation();

  const { data: viewConfigs, lastUsed: lastUsedView } = useViewConfigs(
    selectedForm?.value as number,
    true,
    module.id,
    isAllForms
  );

  useEffect(() => {
    destroySubject.current = new Subject<void>();
    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((dataGridRef) => {
        if (dataGridRef) {
          gridRef.current = dataGridRef;
        }
      });

    return () => {
      destroySubject.current?.next();
      destroySubject.current?.complete();
    };
  }, []);

  useEffect(() => {
    if (lastUsedView) {
      onViewConfigChange({ value: lastUsedView.id, text: lastUsedView.name }, false);
    }
  }, [lastUsedView]);

  const tagCreator = useTestTag('dynamic-page-header');

  const updateSelectedForm = (selectedForm: DropDownResult) => {
    dispatch(setSelectedForm({ viewId: module.viewId, form: selectedForm }));
  };

  const onSelectedFormChange = debounce((value?: DropDownResult | Array<DropDownResult> | DropDownNoSelection) => {
    if (value && !isDropDownNoSelection(value)) {
      if (!Array.isArray(value)) {
        onFormChange(value);
        updateSelectedForm(value);
      } else {
        updateSelectedForm(value[0]);
      }
    }
  }, 5);

  const openNewRecord = (selectedForm: DropDownResult) => {
    const panelCount = activeRecordReference.current?.currentRecordCount() ?? 0;
    const title = t('form-new-record', { formName: selectedForm.text });
    const details = <RecordTitle title={title} />;

    activeRecordReference.current?.addActiveRecord(`new_${panelCount + 1}`, module.icon, details, false, {
      title,
      recordState: RecordSaveState.New,
      viewId: module.viewId,
      formId: selectedForm.value,
      formName: selectedForm.text,
      moduleIcon: module.icon,
    });
  };

  const onAddRecordClick = () => {
    if (isAllForms) {
      setIsSelectFormModalVisible(true);
    } else if (selectedForm && !isDropDownNoSelection(selectedForm)) {
      openNewRecord(selectedForm);
    }
  };

  const onViewConfigChange = debounce(
    (config?: DropDownResult | Array<DropDownResult> | DropDownNoSelection, updateView = true) => {
      if (config && !isDropDownNoSelection(config) && !Array.isArray(config)) {
        dispatch(setSelectedViewConfig(isDropDownResult(config) ? config : undefined));

        // set the saved/default value for the view filters
        const viewConfig = viewConfigs?.find((item: ViewConfig) => item.id === config.value);

        const selectedRecordsFilter = resolveRecordFilterConfigValue(viewConfig?.archivedType);
        dispatch(setSelectedRecordsFilter(selectedRecordsFilter));

        if (!isAllForms) {
          const selectedLastEditedFilter = resolveLastEditedFilterConfigValue(viewConfig?.dateRange);
          dispatch(setSelectedLastEditedFilter(selectedLastEditedFilter));
        }

        if (updateView && viewConfig) {
          updateLastUsedView({ id: viewConfig.id, lastUsed: new Date().toISOString() });
        }
      }
    },
    5
  );

  const onRecordsFilterChange = debounce((value?: DropDownResult | Array<DropDownResult> | DropDownNoSelection) => {
    if (value) {
      let selectedRecordsFilter: DropDownResult;

      if (isDropDownNoSelection(value)) {
        selectedRecordsFilter = defaultRecordFilterOption;
      } else {
        selectedRecordsFilter = !Array.isArray(value) ? value : value[0];
      }

      dispatch(setSelectedRecordsFilter(selectedRecordsFilter));
    }
  }, 5);

  const onLastEditedFilterChange = debounce((value?: DropDownResult | Array<DropDownResult> | DropDownNoSelection) => {
    if (value && !isAllForms) {
      let selectedLastEditedFilter: DropDownResult;

      if (isDropDownNoSelection(value)) {
        selectedLastEditedFilter = defaultLastEditedFilterOption;
      } else {
        selectedLastEditedFilter = !Array.isArray(value) ? value : value[0];
      }

      dispatch(setSelectedLastEditedFilter(selectedLastEditedFilter));
    }
  }, 5);

  const hideSelectFormModal = () => {
    reset();
    setIsSelectFormModalVisible(false);
  };

  const onFormModalSubmit = (data: FieldValues) => {
    openNewRecord(data['form']);
  };
  const onError = (data: FieldValues) => console.error(data);

  const selectFormModalButtons: Array<ModalDialogButtonSetting> = [
    {
      name: 'select',
      text: t('select'),
      form: 'selectFormModal',
      type: 'primary',
    },
  ];

  const schema = yup.object().shape({
    form: yup.object().required(fieldValidationErrorMessage(t('form'))),
  });

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const headerContainerCx = 'flex flex-col flex-shrink-0 items-start pr-2 transition-transform';
  const formDropdownCx = '!text-2xl !text-mono-0 !transition-transform !tracking-wide !rounded-t';
  const configDropdownCx = '!transition-transform sm:!w-48 max-sm:grow';

  const refreshDataGrid = () => {
    gridRef?.current?.api.data.getDataOverwritePageCache();
    dispatch(
      recordApi.util.invalidateTags([
        'Record',
        'LinkedRecord',
        'ReverseLinkedRecord',
        'SummaryLinkedRecord',
        'TotalRecordCount',
        'RecordVersion',
        'Collaborator',
        'AssignedRecordsCount',
        'AllFormsRecord',
      ])
    );
    dispatch(fileApi.util.invalidateTags(['File']));
    dispatch(userApi.util.invalidateTags(['LinkedUser']));
  };

  const viewDropDownOptionTemplate = ({ text, onItemSelected, isSelected }: DropDownOptionTemplateProps) => {
    const optionStyles = classNames(
      'odin-truncate odin-py-1 odin-px-2 odin-cursor-pointer hover:odin-bg-gray-4 hover:odin-text-mono-0',
      { 'odin-bg-gray-2 odin-text-mono-1': isSelected }
    );

    return (
      <p className={optionStyles} onClick={onItemSelected} title={text} ref={tagCreator('view-dropdown-option')}>
        {text}
      </p>
    );
  };

  return (
    <>
      <div className={headerContainerCx}>
        <div
          className="flex max-w-full items-center gap-4 pb-4 pt-2 max-sm:flex-col max-sm:w-full sm:w-96 sm:flex-row"
          ref={tagCreator()}
        >
          <OdinDropDown
            textField="caption"
            valueField="id"
            data={forceAssert<JsonData>(forms)}
            allowSearch={true}
            allowClear={false}
            className={formDropdownCx}
            dropDownButtonStyles="!bg-transparent"
            size="xlg"
            onChange={onSelectedFormChange}
            value={selectedForm}
            useInputStyle={true}
          />
          <div className="flex w-full items-center justify-between gap-3 sm:w-auto sm:flex-none sm:w-unset">
            {permissions?.create && permissions?.showCreateButton && (
              <PrimaryViewActionButton onClick={onAddRecordClick}>{t('add-record')}</PrimaryViewActionButton>
            )}
            {isMobile && <GlobalHierarchiesFilter />}
          </div>
        </div>
        <div className="flex gap-3 w-full items-center max-sm:justify-between">
          <div
            className="flex gap-3 overflow-x-auto custom-scroll py-1 max-sm:grow"
            ref={tagCreator('action-dropdowns')}
          >
            <OdinDropDown
              textField="name"
              valueField="id"
              data={viewConfigs && viewConfigs?.length > 0 ? forceAssert<JsonData>(viewConfigs) : []}
              allowSearch={false}
              allowClear={false}
              value={selectedFilters.viewConfig}
              className={configDropdownCx}
              label={t('view')}
              size="md"
              onChange={onViewConfigChange}
              optionListPanelShouldMatchTargetWidth={false}
              optionListPanelStyles="max-w-sm"
              optionTemplate={viewDropDownOptionTemplate}
            />
            {!isAllForms && (
              <OdinDropDown
                textField="text"
                valueField="value"
                data={lastEditedFilterOptions}
                allowSearch={false}
                allowClear={false}
                setDefaultValue={false}
                className={configDropdownCx}
                label={t('last-edited')}
                size="md"
                onChange={onLastEditedFilterChange}
                value={selectedFilters.lastEdited}
              />
            )}
            <OdinDropDown
              textField="text"
              valueField="value"
              data={recordFilterOptions}
              allowSearch={false}
              allowClear={false}
              setDefaultValue={false}
              className={configDropdownCx}
              label={t('show-records')}
              size="md"
              onChange={onRecordsFilterChange}
              value={selectedFilters.records}
            />
          </div>
          <div className="flex">
            <Tooltip
              description={t('refresh')}
              tooltipClassName="bg-mono-1"
              debounceTime={200}
              wrapperClassName="flex justify-center max-sm:hidden"
            >
              <IconButton classNames="mx-2 h-9" onClick={refreshDataGrid}>
                <OdinIcon size={OdinIconSize.Medium} type={OdinIconType.Line} icon="Refresh" className="w-6" />
              </IconButton>
            </Tooltip>
            <DataGridMenu
              selectedForm={selectedForm}
              formPermissions={formPermissions}
              module={module}
              isAllForms={isAllForms}
              viewHasChanged={viewHasChanged ?? false}
              isMobile={isMobile}
            />
          </div>
        </div>
      </div>
      <ModalDialog
        header={t('select-form')}
        visible={isSelectFormModalVisible}
        hidden={hideSelectFormModal}
        buttons={selectFormModalButtons}
        buttonType="submit"
      >
        <form id="selectFormModal" onSubmit={handleSubmit(onFormModalSubmit, onError)}>
          <HfDropDown
            control={control}
            name="form"
            textField="caption"
            valueField="id"
            data={forceAssert<JsonData>(forms.filter((form) => form.id !== 0))}
            allowClear={false}
            allowSearch={false}
            setDefaultValue={true}
          />
        </form>
      </ModalDialog>
    </>
  );
}

export default DynamicPageHeader;
