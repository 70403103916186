import React from 'react';
import { Control } from 'react-hook-form';
import EmailThisButton, { EmailThisButtonProps } from './email-this-button.component';
import { OdinController } from '@myosh/odin-components';

interface HfEmailThisButtonProps extends EmailThisButtonProps {
  control?: Control;
  name?: string;
  hint?: string;
}

function HfEmailThisButton({ name = 'email_this_button', control, ...rest }: HfEmailThisButtonProps) {
  return <OdinController name={name} control={control} render={() => <EmailThisButton {...rest} />} />;
}

export default HfEmailThisButton;
