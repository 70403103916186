import React, { forwardRef, Ref } from 'react';
import { DataGridCellRendererProps, DataGridDataCell } from '@myosh/odin-components';
import { forceAssert } from '../../common/common-functions';
import { EntityReference } from '../../@types/views';
import { EntitySubType } from '../../@types/linked-records-field';

interface QuestionDataProps {
  maxPossible: number;
  totalScore: number;
  percentage: string;
  averageScore: number;
}

interface QuestionnareFieldEntityReference extends EntityReference {
  questionRecordListFieldTypes: EntitySubType;
}

export function transformQuestionnaireCellData(
  questionRecordListFieldTypes: EntitySubType,
  questionData?: QuestionDataProps
) {
  let transformedData;
  if (questionData) {
    switch (questionRecordListFieldTypes) {
      case 'MAX_SCORE_POSSIBLE':
        transformedData = questionData.maxPossible?.toFixed(2);
        break;
      case 'TOTAL_SCORE':
        transformedData = questionData.totalScore?.toFixed(2);
        break;
      case 'AVERAGE_SCORE':
        transformedData = questionData.averageScore?.toFixed(2);
        break;
      case 'PERCENT_SCORE':
        transformedData = questionData.percentage;
        break;
      default:
        transformedData = '';
    }
  }
  return transformedData ?? '';
}

const QuestionnaireDataCell = ({ column, rowData }: DataGridCellRendererProps, forwardRef: Ref<HTMLDivElement>) => {
  const columnEntityReference = forceAssert<QuestionnareFieldEntityReference>(column.customDataProperties);
  const { entityId, questionRecordListFieldTypes } = columnEntityReference;
  const displayValue = rowData[entityId]
    ? transformQuestionnaireCellData(
        questionRecordListFieldTypes,
        forceAssert<{ result: QuestionDataProps }>(rowData[entityId]).result
      )
    : '';

  return <DataGridDataCell ref={forwardRef} value={displayValue} width={column.width} />;
};

export default forwardRef(QuestionnaireDataCell);
