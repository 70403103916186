import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '@myosh/odin-translations/translations/en.json';

export const resources = {
  en: {
    translation: enTranslation,
  },
};

i18next.use(initReactI18next).init({
  lng: 'en',
  resources: resources,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18next;
