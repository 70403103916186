import { CheckboxChangeEventType, DynamicFormRequiredType, DynamicFormSettings } from '@myosh/odin-components';
import i18next from '../../../i18n';
import { forceAssert } from '../../../common/common-functions';
import HfDropDownMultiSelect from '../../fields/custom-drop-downs/hf-drop-down-multi-select.component';
import HfRiskMatrixAdmin from '../../fields/risk-rating/hf-risk-matrix-admin.component';
import HfRiskLevel from '../../fields/risk-level/hf-risk-level.component';

export const getRiskMatrixFormSettings = (readOnly: boolean, riskMatrixId?: number): DynamicFormSettings => {
  return {
    id: 0,
    caption: '',
    fields: [
      {
        id: 'caption',
        fieldType: 'TEXTFIELD',
        fieldName: 'caption',
        caption: i18next.t('name'),
        position: 0,
        required: DynamicFormRequiredType.True,
        permissions: { read: true, edit: !readOnly },
      },
      {
        id: 'systemDefault',
        fieldType: 'CHECKBOX',
        fieldName: 'systemDefault',
        caption: i18next.t('default'),
        position: 1,
        permissions: { read: true, edit: false },
      },
      {
        id: 'archived',
        fieldType: 'CHECKBOX',
        fieldName: 'archived',
        caption: i18next.t('archived'),
        position: 2,
        permissions: { read: true, edit: false },
      },
      {
        id: 'riskMatrix',
        fieldType: 'CUSTOM',
        customFieldType: 'RISKMATRIX',
        caption: i18next.t('risk-matrix'),
        fieldName: 'riskMatrix',
        position: 3,
        permissions: { read: true, edit: !readOnly },
        dynamicProperties: {
          riskMatrixId: riskMatrixId,
        },
      },
      {
        id: 'riskLevel',
        fieldType: 'CUSTOM',
        customFieldType: 'RISKLEVEL',
        caption: i18next.t('risk-level'),
        fieldName: 'riskLevel',
        position: 4,
        permissions: { read: true, edit: !readOnly },
        dynamicProperties: {
          riskMatrixId: riskMatrixId,
        },
      },
    ],
    fieldValueConverters: {
      CHECKBOX: (value) => {
        const convertedValue = forceAssert<CheckboxChangeEventType>(value);
        return convertedValue.checked;
      },
    },
    customFieldComponents: {
      DROPDOWNMULTISELECT: HfDropDownMultiSelect,
      RISKMATRIX: HfRiskMatrixAdmin,
      RISKLEVEL: HfRiskLevel,
    },
  };
};
