import React, { useEffect, useState } from 'react';
import { DropDownResult, DynamicFormRequiredType, HfInput, HfRadioGroup } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import {
  dateChangeType,
  intervalsNotification,
  selectionDataSettings,
} from '../../../record-notification/notifications-utils';
import { useWatch } from 'react-hook-form';
import { NotificationTypeFieldProps, getTriggerFieldsForType } from '../property-section/notification-properties-utils';
import { useFormSettingsQuery } from '../../../../redux/services/api';
import OdinDropDownComponent from '../../custom-drop-downs/odin-drop-down.component';

const DateFieldOn = ({ value, control, readOnly }: NotificationTypeFieldProps) => {
  const { t } = useTranslation();

  const [adjustmentUnit, setAdjustmentUnit] = useState<DropDownResult>();
  const [dateField, setDateField] = useState<DropDownResult>();
  const { data: formSettingsData } = useFormSettingsQuery(value?.moduleForm?.id ?? 0, {
    skip: value?.moduleForm?.id === undefined,
  });

  const changeType: DropDownResult = useWatch({
    control,
    name: 'changeType',
    exact: true,
  });

  useEffect(() => {
    if (value?.notificationPropertiesDTO?.notificationEventDetailDTO) {
      const field = value.notificationPropertiesDTO.notificationEventDetailDTO.triggerDateField;
      const unit = value.notificationPropertiesDTO.notificationEventDetailDTO.adjustmentUnit ?? '';
      setDateField({ text: field?.caption ?? '', value: field?.id });
      setAdjustmentUnit({ text: t(unit.toLowerCase()), value: unit });
    }
  }, [value]);

  return (
    <div className="flex flex-col gap-y-2">
      <OdinDropDownComponent
        size="sm"
        label={t('date-field')}
        value={dateField}
        name="triggerDateField"
        readOnly={readOnly}
        required={DynamicFormRequiredType.True}
        data={getTriggerFieldsForType(formSettingsData, ['DATEFIELD'])}
        {...selectionDataSettings}
      />
      <HfRadioGroup
        control={control}
        name="changeType"
        label={t('date-notification-on-after')}
        data={dateChangeType}
        orientation="HORIZONTAL"
        readOnly={readOnly}
        required={DynamicFormRequiredType.True}
        {...selectionDataSettings}
      />
      {changeType?.value !== undefined && changeType.value !== 'ON_DATE' && (
        <div className="flex flex-row gap-x-2">
          <HfInput
            control={control}
            id="notificationPropertiesDTO.notificationEventDetailDTO.adjustmentValue"
            name="notificationPropertiesDTO.notificationEventDetailDTO.adjustmentValue"
            type="number"
            readOnly={readOnly}
            required={DynamicFormRequiredType.True}
          />
          <OdinDropDownComponent
            size="sm"
            value={adjustmentUnit}
            data={intervalsNotification}
            readOnly={readOnly}
            required={DynamicFormRequiredType.True}
            {...selectionDataSettings}
          />
        </div>
      )}
    </div>
  );
};

export default DateFieldOn;
