import { DataGridColumnSettings, DynamicFormField, JsonDataItem } from '@myosh/odin-components';
import { forceAssert } from '../../../common/common-functions';
import { CombinedFieldType } from '../../../@types/forms';
import { RecordLinkEntity } from '../../../@types/linked-records-field';
import { SummaryRecordLinkResult } from '../../../@types/summary-record-link-field';
import { transformRecordLinkColumns } from '../linked-records/linked-records-functions';

// Field types that are included in the summary recordlink field
export const summaryRecordLinkFieldTypes = [
  'RECORDLINK',
  'TEXTUAL_QUESTIONNAIRE',
  'REVERSE_RECORD_LINK',
  'NUMERIC_QUESTIONNAIRE',
];

/**
 * Returns the summary recordlink field column configuration for the internal data grids.
 *
 * In order to display summary record link data-grids, `recordLinkFormat` configuration needs to be extracted from the fields, as that will be used as a config for the columns.
 *
 * @param formFields  - Current form fields configurations, that are used for retrieveing the `recordLinkFormat` for the recordlink fields.
 * @param summaryRecords - Records to populate summary record link data-grid
 * @param fieldsToInclude - An optional list of Record link field IDs that should be displayed
 *
 * @returns `columns` for one summary record link field.
 */
export function extractRecordLinkColumns(
  formFields: Array<DynamicFormField>,
  summaryRecords: SummaryRecordLinkResult,
  fieldsToInclude: Array<number>
) {
  let columns: DataGridColumnSettings[] = [];
  const includedFields = findRecordLinkFields(formFields, fieldsToInclude);
  for (let i = 0; i < includedFields.length; i++) {
    if (summaryRecords.fieldId === includedFields[i].id) {
      const recordLinkFormat = getRecordLinkFormat(
        includedFields[i].fieldType as CombinedFieldType,
        includedFields[i]?.dynamicProperties
      );
      if (recordLinkFormat) {
        columns = transformRecordLinkColumns(recordLinkFormat);
      }
      break;
    }
  }
  return columns;
}

/**
 * Returns the form field IDs to include as part of the summary recordlink field.
 *
 * The summary recordlink field has a configuration parameter called 'fieldsToInclude' which if set, will contain the IDs of the
 * record form fields to include as part of the summary recordlink field. If this parameter is not set, then the expected behavior is to include all recordlink fields.
 *
 * @param formFields - Current form fields configurations
 * @param fieldsToInclude - List of field IDs that should be included in the summary recordlink field.
 *
 * @returns List of fields to include as part of the summary recordlink field.
 */
function findRecordLinkFields(formFields: Array<DynamicFormField>, fieldsToInclude: Array<number>) {
  if (fieldsToInclude.length > 0) {
    return formFields.filter((formField) => fieldsToInclude.indexOf(formField.id as number) > -1);
  }

  return formFields;
}

/**
 * Returns the `recordLinkFormat` based on the field type.
 *
 * @param fieldType - RECORDLINK | REVERSE_RECORD_LINK | TEXTUAL_QUESTIONNAIRE have a `recordLinkFormat` configuration
 * @param fieldDynamicProperties - Dynamic Properties, where `recordLinkFormat` is found
 *
 * @returns recordLinkFormat - The recordlink format which is used as a column configuration for the data grids, or undefined if not found or allowed.
 */
function getRecordLinkFormat(fieldType: CombinedFieldType, fieldDynamicProperties?: JsonDataItem) {
  if (fieldDynamicProperties) {
    if (['RECORDLINK', 'REVERSE_RECORD_LINK'].includes(fieldType)) {
      return fieldDynamicProperties.recordLinkFormat as Array<RecordLinkEntity>;
    } else if (
      ['TEXTUAL_QUESTIONNAIRE', 'NUMERIC_QUESTIONNAIRE'].includes(fieldType) &&
      forceAssert<{ allowRecordLinks: boolean }>(fieldDynamicProperties.configuration)?.allowRecordLinks
    ) {
      return forceAssert<{ recordLink?: { recordLinkFormat: Array<RecordLinkEntity> } }>(
        fieldDynamicProperties.configuration
      )?.recordLink?.recordLinkFormat;
    }
  }
}
