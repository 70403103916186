import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

interface RecordUnavailableMessageProps {
  invisible: boolean;
}

export default function RecordUnavailableMessage({ invisible }: RecordUnavailableMessageProps) {
  const { t } = useTranslation();

  return (
    <div className="flex w-full items-center">
      <span
        className={cx('mx-1 line-clamp-1 w-full rounded bg-info px-2 text-center text-sm text-mono-1', {
          invisible: invisible,
        })}
        title={t('record-will-become-unavailable-after-save')}
      >
        {t('record-will-become-unavailable-after-save')}
      </span>
    </div>
  );
}
