import { BehaviorSubject } from 'rxjs';
import { DataGridRef } from '@myosh/odin-components';

const dataGridSubject = new BehaviorSubject<DataGridRef | null>(null);

export function getDataGridReference() {
  return dataGridSubject.asObservable();
}

// Setting the grid reference will notify all active observers.
// This is usually used to keep track of the form records data grids reference
export function setDataGridReference(dataGridRef: DataGridRef) {
  dataGridSubject.next(dataGridRef);
}
