import cx from 'classnames';
import React from 'react';

interface RecordStatusProps {
  status?: string;
  color?: string;
}

export default function RecordStatus({ status, color }: RecordStatusProps) {
  const wrapperStyles = cx(
    `flex items-center font-bold h-6 capitalize cursor-default min-w-min text-sm flex-nowrap shrink-0`,
    {
      hidden: !status,
    }
  );

  const iconStyles = cx(`flex rounded h-4 w-4 mr-1 text-mono-1`);

  return (
    <div className={wrapperStyles}>
      <div
        className={iconStyles}
        style={{ backgroundColor: color ?? 'var(--status-0)', color: color ?? 'var(--status-0)' }}
      />
      {status}
    </div>
  );
}
