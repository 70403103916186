import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  forwardRef,
  Ref,
  useImperativeHandle,
  Key,
  useCallback,
} from 'react';
import {
  DataGridRef,
  DynamicFormButtonSetting,
  DynamicFormFieldType,
  DynamicForm,
  DynamicFormRef,
  JsonDataItem,
  JsonDataWrapper,
  ModalDialogRef,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  OdinDataUpdate,
  PostSubmitHandler,
  PreSubmitHandler,
  ModalDialog,
} from '@myosh/odin-components';
import { useAuth, useUser } from '@myosh/myosh-login';
import { BehaviorSubject, Subject, skip, take, takeUntil } from 'rxjs';
import { ActiveRecordContextProps } from '../../active-record/active-record.component';
import cx from 'classnames';
import { v4 } from 'uuid';
import { forceAssert } from '../../../common/common-functions';
import {
  getUsersFieldOptionsData,
  createUserData,
  updateOriginalUserData,
  getChangedFieldsData,
} from '../../../common/user-functions';
import { getUserFormSettings, getUserFormGroups } from './user-form-settings';
import {
  useAddUserMutation,
  useChangePasswordMutation,
  useGetCurrentUserQuery,
  useGetUserByIdQuery,
  userApi,
  useUpdateUserMutation,
} from '../../../redux/services/user';
import { useHierarchyTypesQuery } from '../../../redux/services/hierarchy';
import { CurrentUserPasswordUpdate, UserMutationResponse, UserResult, UserStructure } from '../../../@types/users';
import { useAppDispatch } from '../../../redux/hooks';
import { CurrentUserPasswordChangeResponse } from '../../../@types/users';
import { UsersPageFields } from '../../../pages/admin/users-page/users.page';
import { FormLoading } from '../../form/form-loading.component';
import { FormGroupNavigation } from '../../form/form-group-navigation.component';
import { FormTitle } from '../../form/form-title.component';
import { cloneDeep, debounce } from 'lodash';
import { UserFields } from '../../../common/user-config';
import { UsersPermissions } from '../../../common/user.permissions';
import { getDataGridReference } from '../../../services/data-grid.service';
import { useApiLogger } from '../../../hooks/use-api-logger';
import { saveModalButtons } from '../../../common/common-administration-utils';
import { promiseToast, showError, showSuccess } from '../../../services/notification.service';
import usePerformingSaveToast from '../../../hooks/use-performing-save-toast';
import { useTranslation } from 'react-i18next';
import useDynamicFormNotifier from '../../../hooks/use-dynamic-form-notifier';
import CloseActiveItemButton from '../../common/close-active-item-button';
import { archieveButton, getArchiveRestoreMessages, restoreButton, saveButton } from '../../../pages/admin/admin-utils';
import UsernameConfirmationModal, {
  UsernameConfirmationModalRef,
  UsernameConfirmationModalResult,
} from './username-confirmation-modal.component';
import { DynamicFormError } from '@myosh/odin-components/dist/types/components/dynamic-form/dynamic-form.interfaces';
import { GenericErrorResponse } from '../../../@types/common';
import { useGetCurrentSchemaInfoQuery } from '../../../redux/services/api';
import { useUserAccess } from '../../../hooks/use-user-access';

export interface UserDetailsRef {
  submitForm?: () => void;
}

export interface UserDetailsProps {
  userId?: number;
  title?: string;
  editable?: boolean;
  isLinkedUser?: boolean;
  isExternal?: boolean;
  isNewUser?: boolean;
  isSettingsView?: boolean;
  onLinkedUserCreated?: (id: number, firstName: string, lastName: string) => void;
  onLinkedUserSuccessCallback?: () => void;
  onClose: (id: string) => void;
  panelSettings?: BehaviorSubject<ActiveRecordContextProps>;
  onCloseActiveRecordConfirmationModalRef?: ModalDialogRef | null;
}

function UserDetails(props: UserDetailsProps, ref: Ref<UserDetailsRef>) {
  const {
    userId, // the user entity id, not the username
    title,
    editable = false,
    isLinkedUser = false,
    isExternal = false,
    isNewUser = false,
    isSettingsView = false,
    onLinkedUserCreated,
    onLinkedUserSuccessCallback,
    onClose,
    panelSettings,
    onCloseActiveRecordConfirmationModalRef,
  } = props;

  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const dynamicFormId = useRef(v4());
  const dynamicFormData = useRef<JsonDataItem>();
  const destroySubject = useRef(new Subject<void>());
  const saveModalReference = useRef<ModalDialogRef>(null);
  const dataGridRef = useRef<DataGridRef>();

  const logoutUserAfterSave = useRef<boolean>(false);
  const loggedInUserActive = useRef<boolean>(false);
  const usernameConfirmationModalRef = useRef<UsernameConfirmationModalRef>(null);
  const batchSaveInProgressRef = useRef<boolean | undefined>();

  const [user, setUser] = useState<UserStructure>();
  const [panelContextProps, setPanelContextProps] = useState<ActiveRecordContextProps>();
  const [loginRequiredValue, setLoginRequiredValue] = useState<boolean>(false);
  const [formButtons, setFormButtons] = useState<Array<DynamicFormButtonSetting>>([]);
  const [controledErrors, setControlledErrors] = useState<Record<string, DynamicFormError>>({});

  const { data, isLoading, isFetching } = useGetUserByIdQuery(
    { id: userId ?? 0, fields: UsersPageFields },
    {
      skip: isSettingsView === true || userId === undefined || userId === 0,
    }
  );

  const { data: hierarchyTypes } = useHierarchyTypesQuery({ archived: 'false' });
  const { data: { system_hostname } = {} } = useGetCurrentSchemaInfoQuery();

  const [addUser] = useAddUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [changePassword] = useChangePasswordMutation();
  const dispatch = useAppDispatch();
  const { state: { user: { prefered_username: loggedInUserUsername } = {} } = {} } = useUser();
  const { logout } = useAuth();
  const log = useApiLogger();
  const { t } = useTranslation();

  const {
    data: userData,
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
  } = useGetCurrentUserQuery(
    {
      fields: [
        UserFields.adminAccess,
        UserFields.externalUser,
        UserFields.localeId,
        UserFields.manager,
        UserFields.loginRequired,
        UserFields.vikingAnalytics,
        UserFields.vikingAnalyticsContributor,
        UserFields.isVikingAnalyticsEnabled,
      ],
    },
    { skip: loggedInUserUsername === undefined }
  );

  const { notifyDirty, notifySaveSucceeded, notifySaveFailed } = useDynamicFormNotifier(dynamicFormId.current);

  const { showPerformingSaveToast, hidePerformingSaveToast } = usePerformingSaveToast({
    shouldFreezeActiveRecord: true,
    disabled: panelContextProps?.batchSaveInProgress,
  });

  useEffect(() => {
    panelSettings?.pipe(skip(1), takeUntil(destroySubject.current)).subscribe((settings) => {
      setPanelContextProps(settings);
      batchSaveInProgressRef.current = settings.batchSaveInProgress;

      // Handles the case when the username change is part of a batch save
      if (loggedInUserActive.current) {
        if (settings.batchSaveInProgress === true && dynamicFormReference.current?.formIsDirty()) {
          if (settings.numberOfDirtyItems === 1) {
            if (settings.numberOfFailedItems && settings.numberOfFailedItems > 0) {
              // discard current save and allow user to fix other failures before we save the user details
              notifySaveFailed(dynamicFormId.current);
            } else {
              // continue with user details changes
              handleSaveBtnClick(true);
            }
          }
        } else if (settings.batchSaveInProgress === false) {
          checkStatusAndLogoutUser();
        }
      }
    });

    onCloseActiveRecordConfirmationModalRef
      ?.subscribeToDialogResult()
      ?.pipe(takeUntil(destroySubject.current))
      .subscribe((result) => {
        if (
          result.buttonName === 'save' &&
          dynamicFormReference.current?.formIsDirty() &&
          !loggedInUserActive.current
        ) {
          // logged in user batch save is triggered when the user record is last to be saved
          handleSaveBtnClick(true);
        } else if (result.buttonName === 'discard') {
          onClose(dynamicFormId.current);
        }
      });

    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((gridRef) => {
        if (gridRef) {
          dataGridRef.current = gridRef;
        }
      });

    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
    };
  }, []);

  const onArchiveUser = (archive: boolean) => {
    if (userId) {
      const data = dynamicFormReference.current?.getData() || {};
      const dirtyFields = dynamicFormReference.current?.formDirtyFields() || {};
      const changedFieldsData = getChangedFieldsData(data, dirtyFields, userId);
      updateOriginalUserData(changedFieldsData, changedFieldsData);
      const patchData = { changedFieldsData, archived: archive };
      promiseToast(
        updateUser({ ...patchData, id: userId })
          .unwrap()
          .then(() => {
            onClose(dynamicFormId.current);
            dataGridRef.current?.api.data.getDataOverwritePageCache();
            dispatch(userApi.util.invalidateTags([{ type: 'User', id: 'LIST' }]));
          }),
        getArchiveRestoreMessages(archive)
      );
    }
  };

  useEffect(() => {
    if (data && !isFetching) {
      const _user = cloneDeep(data);

      if (!_user.hasOwnProperty('hierarchies')) {
        // hierarchies needs to be an empty object so validation will work as expected.
        _user.hierarchies = {};
      }

      if (loggedInUserUsername && _user.original.userId === loggedInUserUsername) {
        loggedInUserActive.current = true;
      }

      setUser(_user);
    } else if (userData && isSettingsView && !isLoadingUser && !isFetchingUser) {
      if (loggedInUserUsername && userData.userId === loggedInUserUsername) {
        loggedInUserActive.current = true;
      }

      setUser(cloneDeep(createUserData(userData as UserResult)));
    }
  }, [data, isFetching, userData, isSettingsView, isLoadingUser, isFetchingUser, loggedInUserUsername]);

  const { readOnly, editAccess, setReadOnly } = useUserAccess({
    initialAccess: isNewUser ?? editable,
    userData: forceAssert(userData),
    permissions: {
      MODIFY: [UsersPermissions.USERS_MODIFY, UsersPermissions.USERS_CREATE],
      READ: UsersPermissions.USERS_READ,
    },
  });

  useEffect(() => {
    if (isSettingsView || (editAccess && !user?.original.archived)) {
      setReadOnly(false);
    } else {
      setReadOnly(true);
    }
  }, [editAccess, userId, user?.original.archived]);

  const handleSaveBtnClick = useCallback((skipValidation?: boolean) => {
    dynamicFormData.current = dynamicFormReference.current?.getData();

    const loggedInUserUsernameChanged = Boolean(
      loggedInUserActive.current &&
        dynamicFormData.current?.userId &&
        loggedInUserUsername &&
        dynamicFormData.current.userId !== loggedInUserUsername
    );

    if (loggedInUserUsernameChanged === true) {
      usernameConfirmationModalRef.current?.show().then((result: UsernameConfirmationModalResult) => {
        if ('continue' === result) {
          dynamicFormReference.current?.submitForm(skipValidation ?? false);
          logoutUserAfterSave.current = true;
        } else if ('cancel' === result || 'dismiss' === result) {
          logoutUserAfterSave.current = false;
          if (batchSaveInProgressRef.current === true) {
            notifySaveFailed(dynamicFormId.current);
          }
        }
      });
    } else {
      dynamicFormReference.current?.submitForm(skipValidation ?? false);
    }
  }, []);

  useEffect(() => {
    if (!isFetching && !isSettingsView) {
      const newFormButtons: Array<DynamicFormButtonSetting> = [];
      if (data?.original.archived) {
        const restore = cloneDeep(restoreButton);
        newFormButtons.push(restore);
        restore.onClick = () => onArchiveUser(false);
        restore.disabled = !editAccess;
      } else {
        const _saveButton = cloneDeep(saveButton);
        _saveButton.onClick = handleSaveBtnClick;
        _saveButton.htmlType = 'button';
        newFormButtons.push(_saveButton);

        if (!isNewUser) {
          const archive = cloneDeep(archieveButton);
          newFormButtons.push(archive);
          archive.onClick = () => onArchiveUser(true);
        }
      }
      setFormButtons(newFormButtons);
    }
  }, [data, isFetching, editAccess, isNewUser, isSettingsView, handleSaveBtnClick]);

  useImperativeHandle(ref, () => ({
    submitForm: handleSaveBtnClick,
  }));

  const existingUser = isSettingsView ? Boolean(userData?.loginRequired) : Boolean(data?.loginRequired);

  const isVikingAnalyticsEnabled = Boolean(userData?.isVikingAnalyticsEnabled);

  const userFormSettings = useMemo(() => {
    const hasSystemHostName = system_hostname ? true : false;
    if (userId && user && hierarchyTypes) {
      return getUserFormSettings(
        user?.externalUser as boolean,
        editAccess,
        existingUser,
        loginRequiredValue,
        hierarchyTypes,
        isSettingsView,
        userId,
        isVikingAnalyticsEnabled,
        hasSystemHostName
      );
    } else if (!userId && hierarchyTypes) {
      return getUserFormSettings(
        isExternal,
        editAccess,
        existingUser,
        loginRequiredValue,
        hierarchyTypes,
        isSettingsView,
        userId,
        isVikingAnalyticsEnabled,
        hasSystemHostName
      );
    }
  }, [
    isExternal,
    editAccess,
    existingUser,
    loginRequiredValue,
    hierarchyTypes,
    isSettingsView,
    user,
    userId,
    isVikingAnalyticsEnabled,
  ]);

  const userFormGroups = useMemo(() => {
    return getUserFormGroups(isExternal, editAccess, existingUser, loginRequiredValue);
  }, [isExternal, editAccess, existingUser, loginRequiredValue]);

  const onFieldChanged = debounce(
    (fieldId: Key, fieldName: string, fieldType: DynamicFormFieldType, fieldValue: unknown) => {
      if (UserFields.email === fieldName && !userId) {
        dynamicFormReference.current?.loadData({ userId: fieldValue });
      }

      if (UserFields.oldPassword === fieldName) {
        setControlledErrors((prevErrors) => {
          return Object.fromEntries(Object.entries(prevErrors).filter(([key]) => key !== fieldName));
        });
      }

      if (fieldId === UserFields.loginRequired) {
        setLoginRequiredValue(Boolean(fieldValue));
      }

      notifyDirty(dynamicFormReference.current);
    },
    500
  );

  const showErrorToast = () => {
    if (!batchSaveInProgressRef.current) {
      showError(t('user-details-save-failed'));
    }
    notifySaveFailed(dynamicFormId.current);
  };

  const saveUserData = (userData: Partial<UserResult>, successCallback: (response?: unknown) => void) => {
    showPerformingSaveToast();
    if (userData.id) {
      return updateUser({ id: userData.id, ...userData })
        .unwrap()
        .then(successCallback)
        .catch(handleSaveFailedNotifications);
    } else {
      return addUser(userData).unwrap().then(successCallback).catch(handleSaveFailedNotifications);
    }
  };

  const saveCurrentUserData = (
    currentUserChanges: CurrentUserPasswordUpdate,
    successCallback: (response?: unknown) => void
  ) => {
    showPerformingSaveToast();
    changePassword(currentUserChanges)
      .unwrap()
      .then(successCallback)
      .catch((error) => handleSaveFailedNotifications(error, false));
  };

  const onCloseUserDetails = () => {
    if (!dynamicFormReference.current?.formIsDirty()) {
      onClose(dynamicFormId.current);
    } else {
      saveModalReference.current
        ?.show()
        ?.pipe(take(1))
        .subscribe((result) => {
          if ('save' === result.buttonName) {
            handleSaveBtnClick();
          } else if (result.buttonName === 'discard') {
            onClose(dynamicFormId.current);
          }
        });
    }
  };

  const saveNewUserCallBack = useCallback(
    (sender: OdinDataSender<Key | undefined>, userId: number, firstName: string, lastName: string) => {
      if (userId) {
        sender.sendData(userId);
        if (isLinkedUser) {
          onLinkedUserCreated?.(userId, firstName, lastName);
        } else {
          dataGridRef.current?.api.data.getDataOverwritePageCache();
        }
      }
    },
    [isLinkedUser, onLinkedUserCreated, dataGridRef]
  );

  const showSaveToast = () => {
    if (!batchSaveInProgressRef.current) {
      showSuccess(t('record-saved'));
      checkStatusAndLogoutUser();
    }

    notifySaveSucceeded(dynamicFormId.current);

    onClose(dynamicFormId.current);
  };

  const handleSaveFailedNotifications = useCallback((error: unknown, showToast = true) => {
    hidePerformingSaveToast();
    if (showToast) {
      showErrorToast();
    }
    log('Failed to update user', { error: error });
    (error as GenericErrorResponse)?.data?.validation?.responseErrors?.map((errorItem) => {
      if (errorItem?.key === UserFields.oldPassword) {
        setControlledErrors({
          oldPassword: {
            message: t('old-password-does-not-match'),
          },
        });
        dynamicFormReference.current?.scrollFieldIntoView(UserFields.oldPassword);
      }
    });
    logoutUserAfterSave.current = false;
  }, []);

  const onFormSubmit = useCallback(
    (data: JsonDataItem, sender: OdinDataSender<Key | undefined>) => {
      if (!dynamicFormReference.current?.formIsDirty()) {
        showSaveToast();
      } else {
        if (userId && user?.original && editAccess) {
          const dirtyFields = dynamicFormReference.current?.formDirtyFields() || {};
          const patchData = getChangedFieldsData(data, dirtyFields, userId);
          updateOriginalUserData(patchData, patchData);
          saveUserData(patchData, (response) => {
            const convertedResponse = forceAssert<UserMutationResponse>(response);
            if (convertedResponse?.result) {
              dataGridRef.current?.api.data.recordChanged(userId);
              onLinkedUserSuccessCallback?.();
              hidePerformingSaveToast();
              showSaveToast();
            } else if (convertedResponse?.error) {
              handleSaveFailedNotifications(convertedResponse.error);
            }
          }).catch(handleSaveFailedNotifications);
        } else if (!userId && editAccess) {
          const userData = { [UserFields.externalUser]: isExternal };
          updateOriginalUserData(data, userData);
          saveUserData(userData, (response) => {
            const convertedResponse = forceAssert<UserMutationResponse>(response);
            if (convertedResponse?.result) {
              saveNewUserCallBack(sender, convertedResponse?.result, data.firstName as string, data.lastName as string);
            } else if (convertedResponse?.error) {
              handleSaveFailedNotifications(convertedResponse.error);
            }
          });
        } else if (userId && !editAccess) {
          const currentUserChanges = {
            oldPassword: data.oldPassword,
            newPassword: data.password,
            retypeNewPassword: data.retypePassword,
          };

          saveCurrentUserData(currentUserChanges as CurrentUserPasswordUpdate, (response) => {
            const convertedResponse = forceAssert<CurrentUserPasswordChangeResponse>(response);
            const possibleError = convertedResponse.data?.validation?.errors[0];
            if (possibleError) {
              handleSaveFailedNotifications(possibleError);
            } else {
              hidePerformingSaveToast();
              showSaveToast();
            }
          });
        }
      }
    },
    [
      userId,
      user,
      editAccess,
      isExternal,
      dataGridRef,
      saveNewUserCallBack,
      hidePerformingSaveToast,
      showSaveToast,
      showErrorToast,
    ]
  );

  const onPreSubmit = useCallback<PreSubmitHandler>((_preSubmitData, formData) => {
    // Ensure onFormSubmit is called with the 'form data' and not the 'data proxy raw data'
    // Should be removed when MYOSH-5887 is implemented
    return dynamicFormData.current ?? formData;
  }, []);

  const onPostSubmit = useCallback<PostSubmitHandler>((id?: Key) => {
    if (id) {
      hidePerformingSaveToast();
      showSaveToast();
    }
  }, []);

  const dataRetrieval: OdinDataRetrieval & OdinDataUpdate<JsonDataWrapper> = {
    getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
      if ('COMBOBOX' === options?.fieldType) {
        await getUsersFieldOptionsData(subscriber, options, dispatch, isExternal);
      } else {
        subscriber.sendData();
      }
    },
  };

  const onGroupItemClicked = (groupId: string) => dynamicFormReference.current?.moveGroupToTop(groupId);

  const onGetPreSubmitData = () => {
    showPerformingSaveToast();
  };

  const checkStatusAndLogoutUser = useCallback(() => {
    if (loggedInUserActive.current === true && logoutUserAfterSave.current === true) {
      setTimeout(() => logout(), 3000);
    }
  }, []);

  const formBodyStyles = cx(
    'relative flex flex-grow flex-row',
    { 'mt-6 overflow-y-hidden': !isLinkedUser },
    { 'h-full pb-12': isLinkedUser }
  );

  const emptyUser = loginRequiredValue ? { hierarchies: {}, loginRequired: true } : { hierarchies: {} };

  return (
    <>
      <div className="flex flex-shrink-0 flex-col">
        <div className="flex">
          <FormTitle title={title} />
          {!isLoading && !isLinkedUser && !isSettingsView && (
            <div className="flex min-w-min justify-end">
              <CloseActiveItemButton onClick={onCloseUserDetails} />
            </div>
          )}
        </div>
      </div>
      <div className={formBodyStyles}>
        {(panelContextProps?.fullScreen || isLinkedUser) && (
          <FormGroupNavigation formGroups={userFormGroups} onGroupItemClicked={onGroupItemClicked} />
        )}
        {isLoading && !userFormSettings && <FormLoading />}
        {!isLoading && userFormSettings && (
          <DynamicForm
            ref={dynamicFormReference}
            dynamicFormId={dynamicFormId.current}
            data={user ?? emptyUser}
            settings={userFormSettings}
            dataRetrieval={dataRetrieval}
            readOnly={readOnly}
            errors={controledErrors}
            buttons={formButtons}
            buttonsLocation={0}
            buttonsPosition={1}
            onSubmit={onFormSubmit}
            onPreSubmit={onPreSubmit}
            onPostSubmit={onPostSubmit}
            onGetPreSubmitData={onGetPreSubmitData}
            onFieldChanged={onFieldChanged}
          />
        )}
      </div>
      <UsernameConfirmationModal ref={usernameConfirmationModalRef} />
      <ModalDialog ref={saveModalReference} header={t('save-unsaved-changes')} buttons={saveModalButtons}>
        <div>{t('save-message')}</div>
      </ModalDialog>
    </>
  );
}

export default forwardRef(UserDetails);
