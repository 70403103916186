import { Loader } from '@myosh/odin-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { HomePageProps } from './home-utility';
import QuickLinks from '../../components/home-page/quick-links.component';
import { useGetHomePageSummaryQuery } from '../../redux/services/api';
import CustomActionScroll from '../custom-actions-scroll';
import classNames from 'classnames';

export default function HomePageDesktop({ isLoading, data, alertNews, getNewsPanel }: HomePageProps) {
  const { t } = useTranslation();

  const { data: homePageData } = useGetHomePageSummaryQuery({});

  const { supportEmailContact, supportPhoneContact } = useMemo(() => {
    if (homePageData) {
      return {
        supportEmailContact: homePageData.emailContact,
        supportPhoneContact: homePageData.phoneContact,
      };
    }
    return {};
  }, [homePageData]);

  const infoPanel = useMemo(
    () =>
      supportEmailContact || supportPhoneContact ? (
        <div className="space-between flex w-full flex-row justify-center mb-5">
          <span className="font-bold">{t('contact-support-via')}</span>
          {supportEmailContact && (
            <a href="mailto:support@myosh.com" className="mx-2 text-primary-2 hover:text-primary-3 hover:underline">
              {supportEmailContact}
            </a>
          )}
          {supportEmailContact && supportPhoneContact && <span className="font-bold">{t('or')}</span>}
          {supportPhoneContact && <span className="mx-2">{supportPhoneContact}</span>}
        </div>
      ) : undefined,
    [supportEmailContact, supportPhoneContact]
  );

  const alertNewsClasses = classNames('mb-4 flex flex-col gap-4 custom-scroll', {
    'overflow-y-auto max-h-[148px]': alertNews.length > 2,
  });

  return (
    <div className="homePage h-full w-full flex flex-col max-sm:hidden">
      <>
        {!isLoading && !isEmpty(alertNews) && <div className={alertNewsClasses}>{alertNews}</div>}
        <QuickLinks />
      </>
      {!data && isLoading && (
        <div className="flex grow items-center justify-center">
          <Loader title={t('loading-news')} />
        </div>
      )}
      {!isLoading && (
        <div className="flex w-full h-full grow flex-row gap-4 overflow-hidden pb-2 [&_a]:text-url [&_a]:underline">
          {<CustomActionScroll />}
          {getNewsPanel()}
        </div>
      )}
      {infoPanel}
    </div>
  );
}
