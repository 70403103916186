import React, { useState } from 'react';
import { FileUpload, HelpComponent, RadioGroup } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import { RadioGroupResult } from '@myosh/odin-components';
import { forceAssert } from '../../../common/common-functions';
import { JsonData } from '@myosh/odin-components';
import i18next from '../../../i18n';

interface ImportAttachmentsProps {
  label?: string;
  onChange: (attachmentsFile: File[] | undefined) => void;
  onAttachmentsLinkedWithSourceIDChange?: (isLinkedWithSourceID: boolean) => void;
}

const maxUploadFileSize = 1048576000; // equal to 1 GB

const linkOptions: Array<RadioGroupResult> = [
  { text: i18next.t('import-attachments-link-option-2'), value: 'RECORD_ID' },
  {
    text: i18next.t('import-attachments-link-option-1'),
    value: 'SOURCE_ID',
  },
];

function ImportAttachments({ label, onChange, onAttachmentsLinkedWithSourceIDChange }: ImportAttachmentsProps) {
  const [attachmentLinkOptions, setAttachmentLinkOptions] = useState<RadioGroupResult>(linkOptions[0]);

  const { t } = useTranslation();

  const onUploadRecordAttachmentsChange = (value?: File[]) => {
    onChange(value);
  };

  const handleOnAttachmentsLinkedWithSourceIDChange = (value: RadioGroupResult | undefined) => {
    setAttachmentLinkOptions(value ?? linkOptions[0]);
    onAttachmentsLinkedWithSourceIDChange?.(value?.value === 'SOURCE_ID');
  };

  return (
    <>
      <div className="flex justify-between items-center mt-4">
        <span className="text-sm font-bold">{label}</span>
        <HelpComponent
          description={`<span class="whitespace-pre-line">${t('import-attachments-info-text')}</span>`}
          tooltipZIndexCheck
        />
      </div>
      <RadioGroup
        name="link-indentifier"
        textField="text"
        valueField="value"
        data={forceAssert<JsonData>(linkOptions)}
        value={attachmentLinkOptions}
        label={t('import-attachments-identifier-label')}
        onChange={(value) => handleOnAttachmentsLinkedWithSourceIDChange(value)}
      />
      <FileUpload
        classNames="mb-2 mt-2"
        maxUploadFiles={1}
        maxUploadFileSize={maxUploadFileSize}
        allowedFileTypes={['zip']}
        onChange={onUploadRecordAttachmentsChange}
        thumbnails
      />
    </>
  );
}

export default ImportAttachments;
