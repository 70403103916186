import React from 'react';
import { DataGridDataCell } from '@myosh/odin-components';
import DataCellLoading from '../../../data-grid-data-cells/data-cell-loading';
import useLinkedRecordsDisplayValue from '../use-linked-records-display-value';

interface RecordlinkTableCellProps {
  recordIds: Array<string>;
  width: number;
}

const RecordlinkTableCell = ({ recordIds, width }: RecordlinkTableCellProps) => {
  const { value, isLoading } = useLinkedRecordsDisplayValue(recordIds);
  return isLoading ? <DataCellLoading /> : <DataGridDataCell value={value} width={width} />;
};

export default RecordlinkTableCell;
