import React, { forwardRef, Ref } from 'react';
import { DataGridCellRendererProps, DataGridDataCell } from '@myosh/odin-components';
import { RecordLinkEntity } from '../../../../@types/linked-records-field';
import { resolveFieldDisplayValue } from '../../../../common/field-property-util';

function UserRecordLinkPropertyNameCell({ column, rowData }: DataGridCellRendererProps, ref: Ref<HTMLDivElement>) {
  const _column = column as RecordLinkEntity;

  return (
    <DataGridDataCell
      ref={ref}
      value={resolveFieldDisplayValue(rowData[_column.entityPropertyName])}
      width={column.width}
    />
  );
}

export default forwardRef(UserRecordLinkPropertyNameCell);
