import { useEffect, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { DropDownResult } from '@myosh/odin-components';
import { useLazyGetUserByIdQuery } from '../../../redux/services/user';
import { UserFields } from '../../../common/user-config';
import { HierarchyDropdownValue } from '../../../@types/hierarchy-fields';

interface HierarchyFromPersonProps {
  control?: Control;
  parentFieldId?: number;
}

const useGetPersonHierarchies = ({ control, parentFieldId }: HierarchyFromPersonProps) => {
  const [hierarchies, setHierarchies] = useState<Record<string, HierarchyDropdownValue>>();
  const [getUserById] = useLazyGetUserByIdQuery();

  const targetFieldValue: DropDownResult = useWatch({
    control,
    name: String(parentFieldId),
    disabled: !parentFieldId,
    exact: true,
  });

  useEffect(() => {
    if (targetFieldValue && Number(targetFieldValue.value)) {
      getUserById(
        {
          id: Number(targetFieldValue.value),
          fields: `${UserFields.occupation},${UserFields.userHierarchy}`,
        },
        true
      )
        .unwrap()
        .then((user) => {
          if (user.hierarchies) {
            setHierarchies(user.hierarchies as Record<string, HierarchyDropdownValue>);
          } else {
            setHierarchies(undefined);
          }
        })
        .catch(() => setHierarchies(undefined));
    } else {
      setHierarchies(undefined);
    }
  }, [targetFieldValue]);

  return hierarchies;
};

export default useGetPersonHierarchies;
