import React from 'react';
import { NavLink } from 'react-router-dom';
import { OdinIcon, OdinIconSize } from '@myosh/odin-components';
import MyoshLogo from '../../../assets/myosh-logo.svg';
import cx from 'classnames';
import { useUser } from '@myosh/myosh-login';
import { useTranslation } from 'react-i18next';

interface AdministrationSection {
  hasUserAdminAccess: boolean;
  isCollapsed: boolean;
}

export default function AdministrationSection({ hasUserAdminAccess, isCollapsed }: AdministrationSection) {
  const { state: userState } = useUser();
  const { t } = useTranslation();

  const navItemTextStyles = cx(
    'flex-1  ml-2 leading-5 text-sm overflow-ellipsis overflow-hidden whitespace-nowrap transition-opacity duration-300',
    {
      'opacity-0': isCollapsed,
    }
  );

  return (
    <div>
      <img
        src={MyoshLogo}
        alt="myosh logo"
        className={cx('mb-2 flex max-sm:h-14 sm:max-h-full', {
          'px-1': isCollapsed,
          'sm:max-w-3/4 m-auto w-1/2 mt-2': !isCollapsed,
        })}
      />
      {hasUserAdminAccess && (
        <div className="max-sm:hidden">
          <NavLink
            to={`/${userState.user?.currentSchema}/administration`}
            className="mb-2 flex items-center py-3 pl-5 pr-4 transition-colors duration-300 hover:bg-gray-4 dark:hover:bg-gray-3"
          >
            <OdinIcon icon={'Settings2'} size={OdinIconSize.ExtraSmall} />
            <span className={navItemTextStyles}>{t('administration')}</span>
          </NavLink>
        </div>
      )}
    </div>
  );
}
