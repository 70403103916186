import React from 'react';
import {
  DropDownNoSelection,
  DropDownResult,
  DynamicFieldComponentProps,
  DynamicFieldDataProps,
} from '@myosh/odin-components';
import { LookupTypeFieldProps } from '../../../@types/forms';
import { Control } from 'react-hook-form';
import DisabledDropdownWrapper from '../custom-drop-downs/disabled-dropdown-wrapper.component';
import ExtendedMultiSelect from '../custom-drop-downs/extended-multi-select-drop-down.component';
import { useTranslation } from 'react-i18next';
import useSupportComboboxFunctionalities from './use-support-combobox-functionalities';

export interface ComboboxMultiselectProps
  extends DynamicFieldComponentProps<DropDownResult | Array<DropDownResult> | DropDownNoSelection>,
    DynamicFieldDataProps,
    LookupTypeFieldProps {
  control: Control;
  formId: number;
  parentFieldId: string;
  parentLabel: string;
  isMultiselect?: boolean;
  subType?: string;
}

export default function ComboboxMultiselect({
  control,
  formId,
  lookupType,
  lookupKeyId,
  lookupHierarchyId,
  parentFieldId,
  parentLabel,
  readOnly,
  subType,
  ...rest
}: ComboboxMultiselectProps) {
  const { t } = useTranslation();
  const { dropDownRef, getOptionsData, onSelectorVisible, showOverlay } = useSupportComboboxFunctionalities({
    control,
    formId,
    lookupType,
    lookupKeyId,
    lookupHierarchyId,
    parentFieldId,
    parentLabel,
    readOnly,
    ...rest,
    isMultiselect: true,
  });

  return (
    <DisabledDropdownWrapper
      visible={showOverlay && !readOnly}
      message={t('dependent-field-message', { fieldName: parentLabel })}
      {...rest}
    >
      <ExtendedMultiSelect
        {...rest}
        subType={subType}
        ref={dropDownRef}
        data={getOptionsData}
        allowDuplicateCall
        onSelectorVisible={onSelectorVisible}
        readOnly={readOnly}
      />
    </DisabledDropdownWrapper>
  );
}
