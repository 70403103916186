import React from 'react';
import {
  DynamicFieldComponentProps,
  FieldLabel,
  Input,
  RequiredIndicator,
  EmptyFieldLabel,
} from '@myosh/odin-components';
import { LinkProps } from '../../../@types/forms';

export interface LinkFieldProps extends DynamicFieldComponentProps<string>, LinkProps {}

function LinkField({
  name = 'link',
  label,
  value,
  inputRef,
  readOnly,
  required,
  onChange,
  error,
  target,
}: LinkFieldProps) {
  return (
    <div className="truncate">
      {label && (
        <div className="flex">
          <FieldLabel label={label} />
          <RequiredIndicator readOnly={readOnly} required={required} />
        </div>
      )}
      {readOnly ? (
        <>
          <a
            href={value}
            target={target === 'NEW' ? '_blank' : '_self'}
            rel="noreferrer"
            className="text-sm text-url underline "
          >
            {value}
          </a>
          {(!value || value.length === 0) && <EmptyFieldLabel />}
        </>
      ) : (
        <Input inputRef={inputRef} name={name} onChange={onChange} value={value} readOnly={readOnly} error={error} />
      )}
    </div>
  );
}

export default LinkField;
