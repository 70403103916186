import React, { useEffect, useState } from 'react';
import { DropDownResult, HelpComponent, JsonDataItem } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import { NotificationTypeFieldProps } from '../property-section/notification-properties-utils';
import HfDropDown from '../../custom-drop-downs/hf-drop-down.component';
import { selectionDataSettings } from '../../../record-notification/notifications-utils';
import useModuleWorkflowSteps from '../../../../hooks/use-module-wokflow-steps';
import { useWatch } from 'react-hook-form';

const TriggerStatusChanged = ({ control, value, readOnly }: NotificationTypeFieldProps) => {
  const { t } = useTranslation();
  const allSteps = useModuleWorkflowSteps(value?.moduleForm.id);
  const [destinationSteps, setDestinationSteps] = useState<JsonDataItem[] | undefined>();
  const [sourceSteps, setSourceSteps] = useState<JsonDataItem[] | undefined>();

  const destinationStep: DropDownResult = useWatch({
    control,
    name: 'destinationStatus',
    exact: true,
  });

  const sourceStep: DropDownResult = useWatch({
    control,
    name: 'sourceStatus',
    exact: true,
  });

  useEffect(() => {
    if (allSteps) {
      setDestinationSteps(allSteps.filter((step) => step.value !== sourceStep?.value));
    }
  }, [sourceStep, allSteps]);

  useEffect(() => {
    if (allSteps) {
      setSourceSteps(allSteps.filter((step) => step.value !== destinationStep?.value));
    }
  }, [destinationStep, allSteps]);

  return (
    <div className="flex flex-row gap-x-2">
      <div className="flex flex-row gap-x-1 w-1/2">
        <HfDropDown
          control={control}
          label={t('status-record-comes-from')}
          id="sourceStatus"
          name="sourceStatus"
          readOnly={readOnly}
          {...selectionDataSettings}
          data={sourceSteps}
        />
        <HelpComponent description={t('status-record-comes-from-tooltip')} />
      </div>
      <div className="flex flex-row gap-x-1 w-1/2">
        <HfDropDown
          control={control}
          label={t('status-record-goes-to')}
          id="destinationStatus"
          name="destinationStatus"
          readOnly={readOnly}
          {...selectionDataSettings}
          data={destinationSteps}
        />
        <HelpComponent description={t('status-record-goes-to-tooltip')} />
      </div>
    </div>
  );
};

export default TriggerStatusChanged;
