import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface LayoutState {
  isActiveRecordsPanelRendered: boolean;
  isActivePanelEmpty: boolean;
  navigationPaths?: Array<string>;
  activeRecordId?: number;
}
const initialState = {
  isActiveRecordsPanelRendered: false,
  isActivePanelEmpty: true,
} as LayoutState;

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    showActiveRecordsPanel: (state) => {
      state.isActiveRecordsPanelRendered = true;
    },
    hideActiveRecordsPanel: (state) => {
      state.isActiveRecordsPanelRendered = false;
    },
    setActiveRecordId: (state, action: PayloadAction<number | undefined>) => {
      state.activeRecordId = action.payload;
    },
    clearActiveRecordId: (state) => {
      state.activeRecordId = undefined;
    },
    setActivePanelEmpty: (state) => {
      state.isActivePanelEmpty = true;
    },
    setActivePanelFilled: (state) => {
      state.isActivePanelEmpty = false;
    },
    setNavigationPaths: (state, action: PayloadAction<Array<string>>) => {
      state.navigationPaths = action.payload;
    },
  },
});

export const {
  showActiveRecordsPanel,
  hideActiveRecordsPanel,
  setActiveRecordId,
  clearActiveRecordId,
  setActivePanelEmpty,
  setActivePanelFilled,
  setNavigationPaths,
} = layoutSlice.actions;
export const selectIsActiveRecordsPanelRendered = (state: RootState) => state.layout.isActiveRecordsPanelRendered;
export const selectIsActivePanelEmpty = (state: RootState) => state.layout.isActivePanelEmpty;
export const selectActiveRecordId = (state: RootState) => state.layout.activeRecordId;
export const selectNavigationPaths = (state: RootState) => state.layout.navigationPaths;
export const selectDefaultModulePath = (state: RootState) => state.layout.navigationPaths?.[0];
export default layoutSlice.reducer;
