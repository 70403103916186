import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormLoader, NavigationItems } from '@myosh/odin-components';
import { IconButton, OdinIcon, SideNavigationProps } from '@myosh/odin-components';
import AdministrationSection from './administration-section.component';
import MyoshLogo from '../../../assets/myosh-logo.svg';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

interface OdinSideNavigationProps extends SideNavigationProps {
  loading?: boolean;
  isNavigationItemsError?: boolean;
  hasUserAdminAccess?: boolean;
}

/* A customisation over the SideNavigation which includes the `AdministrationSection` */
const OdinSideNavigation = ({
  items,
  isItemCollapsed = false,
  setItemCollapsed,
  companyLogo,
  loading,
  isNavigationItemsError,
  hasUserAdminAccess,
}: OdinSideNavigationProps) => {
  const { t } = useTranslation();

  const collapseButtonStyles = cx(
    'inline-block fixed w-4 z-30 top-4 border-x-2 border-y-4 border-transparent transition-all !bg-gray-5 dark:bg-gray-5 sm:rounded-r sm:shadow-right-2',
    {
      'max-sm:left-0 sm:left-14 max-sm:rounded-r max-sm:shadow-right-2': isItemCollapsed,
      'max-sm:right-0 sm:left-52 max-sm:rounded-l max-sm:shadow-left-2 ': !isItemCollapsed,
    }
  );
  const containerStyles = cx(
    'z-20 flex flex-col fixed h-full bg-gray-5 dark:bg-gray-5 dark:text-mono-0 flex-shrink-0 overflow-hidden transition-all shadow-right-2',
    {
      'w-0 sm:w-14': isItemCollapsed,
      'w-screen sm:w-52': !isItemCollapsed,
    }
  );
  const logoContainerStyles = cx(
    'flex flex-shrink-0 w-full px-2 items-center justify-center overflow-hidden transition-all',
    {
      'max-sm:h-14 sm:h-24 py-3': !companyLogo?.isLoading && !isItemCollapsed,
      'h-14': !companyLogo?.isLoading && isItemCollapsed,
    }
  );
  const navStyles = 'flex-grow flex flex-col pt-2 overflow-y-auto overflow-x-hidden custom-scroll';
  const navItemsErrorTextStyles = cx('py-2 pl-5 pr-4 font-bold transition-all', {
    '[writing-mode:vertical-rl]': isItemCollapsed,
  });

  return (
    <>
      <IconButton onClick={() => setItemCollapsed(!isItemCollapsed)} classNames={collapseButtonStyles}>
        <OdinIcon icon={isItemCollapsed ? 'ArrowRightS' : 'ArrowLeftS'} className="relative -left-1.5" />
      </IconButton>
      <div className={containerStyles}>
        <div className="flex h-full flex-col">
          {loading ? (
            <OdinSideNavigationLoader />
          ) : (
            <>
              <NavLink to="/" className={logoContainerStyles}>
                {!companyLogo?.isLoading && (
                  <img
                    className={cx('flex max-h-full max-w-full')}
                    src={companyLogo?.url || MyoshLogo}
                    alt="Company logo"
                  />
                )}
              </NavLink>
              <nav className={navStyles}>
                {(isNavigationItemsError || !items || items.length === 0) && (
                  <p className={navItemsErrorTextStyles}>{t('no-modules-to-display')}</p>
                )}
                {items && items.length > 0 && !isNavigationItemsError && (
                  <NavigationItems items={items} isItemCollapsed={isItemCollapsed} />
                )}
              </nav>
              <AdministrationSection isCollapsed={isItemCollapsed} hasUserAdminAccess={hasUserAdminAccess ?? false} />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default OdinSideNavigation;

// Side navigation loader indicator
const OdinSideNavigationLoader = () => {
  return (
    <>
      <div className="flex h-24 w-full items-center justify-center overflow-hidden px-2 py-3">
        <FormLoader width="xl" height="lg" />
      </div>
      <div className="flex w-full flex-col items-center justify-center pt-2">
        <FormLoader width="lg" height="sm" />
        <FormLoader width="lg" height="sm" />
        <FormLoader width="lg" height="sm" />
        <FormLoader width="lg" height="sm" />
        <FormLoader width="lg" height="sm" />
      </div>
    </>
  );
};
