import { useState, useEffect } from 'react';
import { useFormPermissionsQuery } from '../redux/services/api';
import { FormPermissionsResults } from '../@types/form-permissions';

/**
 * A hook that provides form permissions for logged-in user
 * @param {number} formId
 * @returns {{isLoading: boolean, formPermissions: FormPermissionsResults | undefined}}
 */
const useFormPermissions = (formId?: number) => {
  const [formPermissions, setFormPermissions] = useState<FormPermissionsResults>();

  const { data: formPermissionsResponse, isFetching: isFormFetching } = useFormPermissionsQuery(formId ?? 0, {
    skip: formId === undefined || formId === 0,
  });

  useEffect(() => {
    if (formPermissionsResponse && !isFormFetching) {
      setFormPermissions(formPermissionsResponse);
    }
  }, [formPermissionsResponse]);

  return {
    formPermissions,
    isLoading: isFormFetching,
  };
};

export default useFormPermissions;
