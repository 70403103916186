import { DynamicFormField } from '@myosh/odin-components';
import { CombinedFieldType } from '../@types/forms';

export function extractFormGroupFieldsByFieldType(
  formGroupFields?: Array<DynamicFormField>,
  fieldTypes?: Array<CombinedFieldType>
) {
  if (formGroupFields && fieldTypes) {
    return formGroupFields
      .map((formGroupField) =>
        formGroupField.fields?.filter((field) => fieldTypes.includes(field.fieldType as CombinedFieldType))
      )
      .flat();
  }

  return [];
}
