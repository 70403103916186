import React, { useEffect, useState } from 'react';
import OdinDropDown from '../custom-drop-downs/odin-drop-down.component';
import { useGetWorkflowStepsQuery } from '../../../redux/services/api';
import { DynamicFieldDataTypes } from '@myosh/odin-components/dist/types/components/dynamic-form/dynamic-form.interfaces';
import { DynamicFormRequiredType } from '@myosh/odin-components';
import { forceAssert } from '../../../common/common-functions';
import { CloneWizardState } from './clone-record-button-field.component';
import { useTranslation } from 'react-i18next';

interface FirstStepProps {
  formId: number;
  setCloneWizardState: React.Dispatch<React.SetStateAction<CloneWizardState>>;
  moduleFormCloneTargetId?: number;
  error: boolean;
  setFirstStepError: (value: boolean) => void;
}

const FirstStep = ({
  formId,
  setCloneWizardState,
  moduleFormCloneTargetId,
  error,
  setFirstStepError,
}: FirstStepProps) => {
  const [statusList, setStatusList] = useState<DynamicFieldDataTypes>([]);
  const { data: workflowSteps } = useGetWorkflowStepsQuery(moduleFormCloneTargetId ?? formId);

  const { t } = useTranslation();

  useEffect(() => {
    if (workflowSteps) {
      const statusListDropDownData = workflowSteps.map((step) => {
        return {
          text: step.label,
          value: step.id,
        };
      });
      setStatusList(statusListDropDownData);
    }
  }, [workflowSteps]);

  return (
    <>
      <OdinDropDown
        valueField="value"
        textField="text"
        data={statusList}
        allowClear={false}
        label={t('clone-records-status')}
        required={DynamicFormRequiredType.True}
        onChange={(e) => {
          setCloneWizardState((prevState: CloneWizardState) => ({
            ...prevState,
            status: forceAssert(e),
          }));
          setFirstStepError(false);
        }}
      />
      {error && <span className="text-error">{t('clone-records-status-mandatory')}</span>}
    </>
  );
};

export default FirstStep;
