import { Loader } from '@myosh/odin-components';
import React, { ReactNode, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

interface CustomLoadersProps {
  page: ReactNode;
}

const LazyLoadedPage = ({ page }: CustomLoadersProps) => {
  const { t } = useTranslation();
  return (
    <Suspense
      fallback={
        <div className="mx-auto flex h-screen w-full flex-col items-center justify-center">
          <Loader title={`${t('loading')}`} />
        </div>
      }
    >
      {page}
    </Suspense>
  );
};

export default LazyLoadedPage;
