import React from 'react';
import { DynamicFieldComponentProps, OdinController } from '@myosh/odin-components';
import { Control } from 'react-hook-form';
import UserPictureField from './user-picture-field.component';

interface HFUserPictureFieldProps extends DynamicFieldComponentProps<string> {
  control?: Control;
  name?: string;
  userId?: number;
}

export function HFUserPictureField({
  control,
  name = 'avatar',
  label,
  userId,
  readOnly,
  registerPreSubmitHandler,
  registerPostSubmitHandler,
}: HFUserPictureFieldProps) {
  return (
    <OdinController
      name={name}
      control={control}
      render={({ fieldState: { error } }) => (
        <UserPictureField
          label={label}
          name={name}
          userId={userId}
          readOnly={readOnly}
          error={error?.message}
          exportAsSquare
          registerPreSubmitHandler={registerPreSubmitHandler}
          registerPostSubmitHandler={registerPostSubmitHandler}
        />
      )}
    />
  );
}
