import { useCallback, useEffect, useRef } from 'react';
import useActiveRecordRef from './use-active-record-ref';
import { useTranslation } from 'react-i18next';
import { showInfo, dismiss } from '../services/notification.service';
import useTimeoutRef from './use-timeout-ref';

/*
 * A helper hook that can be used in conjuction with a 'Performing save...' toast notification.
 *
 * This hooks handles various aspects of showing/hiding the toast notification.
 *
 * Additionally, if the save operation is taking too long (default 3sec) it will show a closing icon on the notification.
 * This can be useful in situations when then end user wants to take some action, i.e. show a close icon on the toast notification.
 *
 * @param shouldFreezeActiveRecord - If set to `true`, then while the notification is visible, the currently active component in the record navigator is 'frozen'.
 * @param performingSaveTimeout - A number in ms that specifies the amount of time after which it can be considered that the save is taking 'too long'. Defaults to 3 sec.
 * @param disabled - If set to `true`, then the hook is diabled and notificatoins are not shown
 */
const usePerformingSaveToast = ({
  shouldFreezeActiveRecord = false,
  performingSaveTimeout = 3000,
  disabled = false,
}) => {
  const { activeRecordReference } = useActiveRecordRef();
  const { timeoutRef, clearTimeoutRef } = useTimeoutRef();
  const toastId = useRef<string>();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dismiss(toastId.current);
    };
  }, []);

  const showPerformingSaveToast = useCallback(() => {
    if (!disabled) {
      dismiss(toastId.current);

      toastId.current = showInfo(t('performing-save'), { duration: Infinity });

      if (shouldFreezeActiveRecord && activeRecordReference.current) {
        activeRecordReference.current.setActiveRecordPanelFrozen(true);
      }

      clearTimeoutRef();

      timeoutRef.current = setTimeout(() => {
        showInfo(t('performing-save'), {
          id: toastId.current,
          duration: Infinity,
          showCloseIcon: true,
          onCloseClick: () => hidePerformingSaveToast(),
        });
      }, performingSaveTimeout);
    }
  }, [disabled]);

  const hidePerformingSaveToast = useCallback(() => {
    if (!disabled) {
      if (shouldFreezeActiveRecord && activeRecordReference.current) {
        activeRecordReference.current.setActiveRecordPanelFrozen(false);
      }

      clearTimeoutRef();
      dismiss(toastId.current);
      toastId.current = undefined;
    }
  }, [disabled]);

  return {
    showPerformingSaveToast,
    hidePerformingSaveToast,
  };
};

export default usePerformingSaveToast;
