import React, { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetRecordVersionsQuery } from '../../../redux/services/record';
import { DataGridColumnSettings, DataGridDataCell } from '@myosh/odin-components';

interface VersionGridActiveCellProps {
  column: DataGridColumnSettings;
  version: number;
  recordId?: number;
}

function VersionGridActiveCell(
  { column, version, recordId }: VersionGridActiveCellProps,
  forwardRef: Ref<HTMLDivElement>
) {
  const { t } = useTranslation();

  const { data: recordVersions } = useGetRecordVersionsQuery(
    { recordId: recordId ?? 0 },
    { skip: recordId === undefined }
  );

  if (recordVersions?.[0].version === version) {
    return <DataGridDataCell ref={forwardRef} value={t('active')} width={column.width} />;
  }

  return null;
}

export default forwardRef(VersionGridActiveCell);
