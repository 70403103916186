import {
  CheckboxChangeEventType,
  DataGridRef,
  DataSearchLocation,
  DynamicFormButtonSetting,
  DynamicFormField,
  DynamicFormRequiredType,
  DynamicForm,
  DynamicFormRef,
  FieldChangedFunction,
  IconButton,
  JsonDataItem,
  JsonDataWrapper,
  ModalDialogButtonSetting,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  OdinDataUpdate,
  OdinIcon,
  OdinIconSize,
  OdinIconType,
  PostSubmitHandler,
  PreSubmitHandler,
  Tooltip,
  useTestTag,
  ModalDialogRef,
  JsonData,
  CurrentTimeButton,
  OdinExpandIcon,
  OdinCollapseIcon,
  ModalDialog,
} from '@myosh/odin-components';
import { ActiveRecordContextProps } from '../active-record/active-record.component';
import cx from 'classnames';
import { cloneDeep, debounce, isArray, uniq } from 'lodash';
import React, {
  forwardRef,
  Key,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BehaviorSubject, firstValueFrom, Subject, take, takeUntil } from 'rxjs';
import { v4 } from 'uuid';
import { AttachmentsAdd, AttachmentsRemove } from '../../@types/attachments';
import { CombinedFieldType } from '../../@types/forms';
import { QuestionnaireFieldValue } from '../../@types/questionnaire';
import { PatchRecordResponse, PostRecordResponse, RecordResult, RecordStructure } from '../../@types/records';
import {
  buildGlobalHierarchyFiltersUrl,
  ExtendedDynamicFormNextWorkflowStep,
  ExtendedDynamicFormSettings,
  ExtendedDynamicFormWorkflowStep,
  forceAssert,
} from '../../common/common-functions';
import { extractFormGroupFieldsByFieldType } from '../../common/dynamic-form-functions';
import {
  currentWorkflowStep,
  customPropertyOfFormGroups,
  formGroupPermissions,
  formGroupPersonFieldPermissions,
  workflowStepCanProgressPermission,
} from '../../common/form-permissions-util';
import useProfileData from '../../hooks/use-profile-data';
import i18next from '../../i18n';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  useFormAccessControlQuery,
  useFormSettingsQuery,
  useGetCurrentSchemaInfoQuery,
  useGetFeatureToursQuery,
  useGetWorkflowStepsQuery,
} from '../../redux/services/api';
import {
  recordApi,
  useAddRecordMutation,
  useGetDefaultRecordValuesQuery,
  useGetRecordByIdQuery,
  useGetRecordLinkFieldsDefaultValuesQuery,
  useLazyGetRecordByIdQuery,
  useUpdateRecordMutation,
} from '../../redux/services/record';
import { getDataGridReference } from '../../services/data-grid.service';
import { getRecordReference } from '../../services/record.service';
import { HfAttachmentsField } from '../fields/attachments-field/hf-attachments-field.component';
import HfCloneRecordButtonField from '../fields/clone-record-button-field/hf-clone-record-button-field.component';
import HfEmailThisButton from '../fields/email-this-button/hf-email-this-button.component';
import HfExportButtonField from '../fields/export-button-field/hf-export-button-field.component';
import { HfHierarchyField } from '../fields/hierarchy/hf-hierarchy-field.component';
import HfInvitationField from '../fields/invitation-field/hf-invitation-field.component';
import HfInviteButtonField from '../fields/invite-button-field/hf-invite-button-field.component';
import HfLinkField from '../fields/link/hf-link-field.component';
import HfLinkedRecords from '../fields/linked-records/hf-linked-records.component';
import HfMultiselectPersonField from '../fields/person/hf-multiselect-person-field.component';
import HfReadersField from '../fields/person/hf-readers-field.component';
import HfPersonField from '../fields/person/hf-person-field.component';
import HfQuestionnaire from '../fields/questionnaire/hf-questionnaire.component';
import HfReverseLinkedRecords from '../fields/reverse-linked-records/hf-reverse-linked-records.component';
import HfRiskRating from '../fields/risk-rating/hf-risk-rating.component';
import HfSummaryRecordLink from '../fields/summary-record-link/hf-summary-record-link.component';
import { summaryRecordLinkFieldTypes } from '../fields/summary-record-link/summary-record-link.functions';
import HfTrainingField from '../fields/training/hf-training-field.component';
import HfUserRecordLink from '../fields/user-record-link/hf-user-record-link.component';
import HfVersionField from '../fields/version/hf-version-field.component';
import HfOptionGroup from '../fields/option-group/hf-option-group.component';
import { FormGroupNavigation } from '../form/form-group-navigation.component';
import { FormLoading } from '../form/form-loading.component';
import { FormTitle } from '../form/form-title.component';
import ConflictsModal, { ConflictResolution, ConflictsModalRef } from './conflicts-modal/conflicts-modal.component';
import {
  addAccessControlConditionsAsVisibilityConditions,
  buildWorkflowStepVisibilityConditions,
  cleanupRecordData,
  createPatchData,
  createSaveData,
  extractHistoryFields,
  findFieldRecursiveSearch,
  findMergeConflicts,
  getConstructedRecordTitle,
  recordErrorType,
  resolveRecordStatus,
  transformHistoryFieldsValue,
  updateOriginalData,
} from './record-component-util';
import { validateByFieldType } from './record-fields-validation';
import { getRecordFieldOptions } from './record-field-options';
import { RecordMenu } from './record-menu.component';
import RecordStatus from './record-status.component';
import RecordUnavailableMessage from './record-unavailable-message.component';
import WorkflowStepModal, { WorkflowStepModalRef } from './workflowstep-modal/workflowstep-modal.component';
import usePerformingSaveToast from '../../hooks/use-performing-save-toast';
import useRecordPermissionsAndMode from './hooks/use-record-permissions-and-mode';
import useShowRecordUnavailable from './hooks/use-show-record-unavailable';
import { useApiLogger } from '../../hooks/use-api-logger';
import { showSuccess, showWarning, showError, showInfo } from '../../services/notification.service';
import { fileApi } from '../../redux/services/file';
import { HierarchyDropdownValue } from '../../@types/hierarchy-fields';
import useDynamicFormNotifier from '../../hooks/use-dynamic-form-notifier';
import HfDropDownMultiSelect from '../fields/custom-drop-downs/hf-drop-down-multi-select.component';
import HfCombobox from '../fields/combobox/hf-combobox.component';
import HfComboboxMultiselect from '../fields/combobox/hf-combobox-multiselect.component';
import HfMultiselectCheckbox from '../fields/multiselect-checkbox/hf-multiselect-checkbox.component';
import CloseActiveItemButton from '../common/close-active-item-button';
import { RecordTitle } from './record-title.component';
import useActiveRecordRef from '../../hooks/use-active-record-ref';
import { GenericErrorResponse } from '../../@types/common';
import { USER_TIME_ZONE } from '../../common/date-util';
import { selectedGlobalHierarchies, selectedMatchingOption } from '../../redux/slices/global-hierarchies';
import HfTwinColumnSelect from '../fields/twin-column-select/hf-twin-column-select-field.component';
import RecordSideBarFeatureTour from '../layout/components/feature-tour-guides/record-sidebar-feature-tour';

const saveModalButtons: Array<ModalDialogButtonSetting> = [
  {
    name: 'save',
    text: i18next.t('save'),
    type: 'primary',
    variant: 'alternative',
  },
  {
    name: 'discard',
    text: i18next.t('discard'),
    variant: 'alternative',
  },
];

export enum RecordSaveState {
  Update,
  New,
}

export interface RecordRef {
  scrollFieldIntoView: (scrollId: string) => void;
}

export interface RecordProps {
  recordId?: string;
  formId: number;
  title?: string;
  vikingLinkUrl?: string;
  recordState?: RecordSaveState;
  viewId?: string;
  moduleIcon?: string;
  openInEditMode?: boolean;

  onClose: (id: string) => void;
  panelSettings?: BehaviorSubject<ActiveRecordContextProps>;
  onCloseActiveRecordConfirmationModalRef?: ModalDialogRef | null;

  isLinkedRecord?: boolean;
  isTextualQuestionnaireLinkedRecords?: boolean;
  reverseLinkedRecords?: Array<RecordResult>;
  reverseRecordLinkFormId?: number;
  isPreview?: boolean;
  isReadOnly?: boolean;
  isSimpleView?: boolean;
  showFormGroupsNavigation?: boolean;
  onLinkedRecordCreated?: (recordId: number) => void;
  onSimpleViewRecordCreated?: (recordId: string) => void;
  parentLinkedRecordId?: number;
  parentLinkedFieldId?: number;
}

function Record(
  {
    recordId,
    formId,
    title,
    vikingLinkUrl,
    recordState = RecordSaveState.Update,
    viewId,
    moduleIcon,
    openInEditMode = false,
    onClose,
    panelSettings,
    onCloseActiveRecordConfirmationModalRef,
    isLinkedRecord,
    reverseLinkedRecords,
    reverseRecordLinkFormId,
    isPreview,
    isReadOnly = false,
    isSimpleView = false,
    showFormGroupsNavigation = false,
    onLinkedRecordCreated,
    onSimpleViewRecordCreated,
    parentLinkedRecordId,
    parentLinkedFieldId,
    isTextualQuestionnaireLinkedRecords,
  }: RecordProps,
  ref: Ref<RecordRef>
) {
  const [formGroups, setFormGroups] = useState<Array<DynamicFormField>>();
  const [formButtonsState, setFormButtonsState] = useState<Array<DynamicFormButtonSetting>>([]);
  const [newRecordId, setNewRecordId] = useState<number>();
  const [isRecordSaved, setIsRecordSaved] = useState(true);
  const [recordTitle, setRecordTitle] = useState(title);
  const [showExpandButton, setShowExpandButton] = useState(true);
  const [formSettingState, setFormSettingState] = useState<ExtendedDynamicFormSettings>();
  const [recordDataState, setRecordDataState] = useState<RecordStructure>();
  const [recordSaveState, setRecordSaveState] = useState<RecordSaveState>(recordState);
  const [newRecordOldId, setNewRecordOldId] = useState<string>('');
  const recordDataOriginal = recordDataState?.original;
  const recordDataFlat = recordDataState?.flat;

  // Additional ref needed due to onPostSubmit creating a closure which cannot be updated
  const [panelContextProps, setPanelContextProps] = useState<ActiveRecordContextProps>();
  const panelContextPropsRef = useRef<ActiveRecordContextProps>();

  const { activeRecordReference } = useActiveRecordRef();
  const closeOnModalSaveRef = useRef(false);
  const lastEditedFieldId = useRef<Key>();
  const currentWorkflowRef = useRef<ExtendedDynamicFormWorkflowStep>();
  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const dynamicFormId = useRef(v4());
  const destroySubject = useRef(new Subject<void>());
  const saveDataSubject = useRef<Subject<RecordResult>>();
  const saveModalReference = useRef<ModalDialogRef>(null);

  const nextWorkflowStepModalReference = useRef<WorkflowStepModalRef>(null);
  const conflictsModalRef = useRef<ConflictsModalRef>(null);
  const dataGridRef = useRef<DataGridRef>();
  const recordRef = useRef<RecordRef>();
  const existingRecordWasSavedProgressedOrRefreshedRef = useRef(false);
  const nextWorkflowStepRef = useRef<ExtendedDynamicFormNextWorkflowStep>();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const globalFilters = useAppSelector(selectedGlobalHierarchies);
  const globalFiltersMatchingOption = useAppSelector(selectedMatchingOption);
  const globalHierarchyFilters = buildGlobalHierarchyFiltersUrl(globalFilters, globalFiltersMatchingOption);

  const { viewId: moduleViewId } = useParams();
  const { profileData: { user: userData } = {}, isLoadingUser } = useProfileData();
  const isSuperUser = userData?.superUser;
  const log = useApiLogger();

  const { notifyDirty, notifySaveSucceeded, notifySaveFailed } = useDynamicFormNotifier(dynamicFormId.current);

  const { showPerformingSaveToast, hidePerformingSaveToast } = usePerformingSaveToast({
    shouldFreezeActiveRecord: true,
    disabled: panelContextProps?.batchSaveInProgress,
  });

  const { isRecordAvailableAfterSave, showRecordWillBecomeUnavailableMessage, hideRecordWillBecomeUnavailableMessage } =
    useShowRecordUnavailable();

  const [updateRecord] = useUpdateRecordMutation();
  const [addRecord] = useAddRecordMutation();

  const { data: formSettingsData, isFetching: isFormSettingsDataFetching } = useFormSettingsQuery(formId ?? 0, {
    skip: formId === undefined || formId <= 0,
  });

  const { data: featureTourData } = useGetFeatureToursQuery();

  const {
    data: singleRecordData,
    isFetching: isRecordFetching,
    isError: hasRecordDataError,
    error: recordDataError,
  } = useGetRecordByIdQuery(
    {
      keyTypeId: true,
      id: recordId || newRecordId || 0, // the id will never be set to 0 it's just to stop typescript from complaining
      fetchLinkedRecords: true,
    },
    {
      skip:
        !isRecordSaved || (recordId === undefined && newRecordId === undefined) || formId === undefined || formId <= 0,
    }
  );

  const { data: defaultRecordData } = useGetDefaultRecordValuesQuery(
    {
      keyTypeId: true,
      id: formId,
    },
    {
      skip:
        formId === undefined ||
        recordId !== undefined ||
        formId <= 0 ||
        (isLinkedRecord && !isTextualQuestionnaireLinkedRecords),
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: defaultLinkedRecordData } = useGetRecordLinkFieldsDefaultValuesQuery(
    {
      recordId: parentLinkedRecordId ?? 0,
      fieldId: parentLinkedFieldId ?? 0,
    },
    {
      skip:
        !parentLinkedFieldId ||
        !formId ||
        formId <= 0 ||
        !parentLinkedRecordId ||
        isTextualQuestionnaireLinkedRecords ||
        !isLinkedRecord,
    }
  );

  const [getRecordById] = useLazyGetRecordByIdQuery();

  const { data: workflowSteps, isFetching: isWorkflowStepsFetching } = useGetWorkflowStepsQuery(formId, {
    skip: formId === undefined || formId <= 0,
  });

  const { data: formAccessControl, isFetching: isFormAccessControlFetching } = useFormAccessControlQuery(formId, {
    skip: formId === undefined || formId <= 0,
  });

  const { data: schemaInfo } = useGetCurrentSchemaInfoQuery();

  const schemaTimeZone = useMemo(() => {
    return schemaInfo?.['time-zone'];
  }, [schemaInfo]);

  const {
    recordPanelReadOnlyMode,
    canEditRecord,
    formPermissions,
    isFormPermissionsLoading,
    recordPermissions,
    isRecordPermissionLoading,
    currentRecordPermissions,
    onRecordsEdit,
  } = useRecordPermissionsAndMode(recordId, formId, openInEditMode);

  useImperativeHandle(ref, () => ({
    scrollFieldIntoView: (fieldId: string) => dynamicFormReference.current?.scrollFieldIntoView(fieldId),
  }));

  const updateDataGridOnRecordUpdate = () => {
    dataGridRef.current?.api.data.getDataOverwritePageCache();
    dispatch(recordApi.util.invalidateTags(['TotalRecordCount']));
    existingRecordWasSavedProgressedOrRefreshedRef.current = false;
  };

  const showErrorNotification = () => {
    if (!panelContextPropsRef.current?.batchSaveInProgress) {
      showError(t('record-save-failed'));
    }
    notifySaveFailed(dynamicFormId.current);
  };

  const showAutomaticVersioningErrorNotification = () => {
    if (!panelContextPropsRef.current?.batchSaveInProgress) {
      showError(t('automatic-versioning-error'));
    }
    notifySaveFailed(dynamicFormId.current);
  };

  const showAutomaticVersioningFieldValueExistsNotification = (errorMessage: string) => {
    if (!panelContextPropsRef.current?.batchSaveInProgress) {
      showError(
        t('save-failed-automatic-versioning-field-error', { apiErrorMessage: errorMessage }) ?? t('record-save-failed')
      );
    }
    notifySaveFailed(dynamicFormId.current);
  };

  const showSaveNotificationAndCloseRecord = (id?: string) => {
    if (!panelContextPropsRef.current?.batchSaveInProgress && !isSimpleView) {
      showSuccess(t('record-saved'), { id: id ?? String(singleRecordData?.original?.id ?? recordId) });
    }
    notifySaveSucceeded(dynamicFormId.current);

    if (
      nextWorkflowStepRef.current?.submissionBehaviour === 'NAVIGATE_TO_URL' &&
      nextWorkflowStepRef.current.navigationUrl
    ) {
      const url = nextWorkflowStepRef.current.navigationUrl;
      isSimpleView ? showSuccess(t('sign-in-successful')) : showInfo(t('redirect-to-url', { url }));
      setTimeout(() => window.open(url, '_self'), 3000);
    } else if (closeOnModalSaveRef.current || nextWorkflowStepRef.current?.closeOnSave) {
      // immediattely close record if applicable (MYOSH-5368)
      if (viewId === moduleViewId || window.location.href.includes(String(viewId))) {
        updateDataGridOnRecordUpdate();
      }
      closeAndRefresh();
    }
  };

  const onSaveButtonClick = () => {
    if (recordSaveState === RecordSaveState.Update && !dynamicFormReference.current?.formIsDirty()) {
      showSaveNotificationAndCloseRecord();
    } else {
      dynamicFormReference.current?.submitForm(true);

      if (isLinkedRecord) {
        closeOnModalSaveRef.current = true;
      }
    }
  };

  useEffect(() => {
    if (recordSaveState !== recordState) {
      setRecordSaveState(recordState);
    }
  }, [recordState, singleRecordData]);

  useEffect(() => {
    panelSettings?.pipe(takeUntil(destroySubject.current)).subscribe((settings) => {
      panelContextPropsRef.current = settings;
      setPanelContextProps(settings);
    });

    onCloseActiveRecordConfirmationModalRef
      ?.subscribeToDialogResult()
      ?.pipe(takeUntil(destroySubject.current))
      .subscribe((result) => {
        if (result.buttonName === 'save' && dynamicFormReference.current?.formIsDirty()) {
          closeOnModalSaveRef.current = true;
          dynamicFormReference.current?.submitForm(true);
        } else if (result.buttonName === 'discard') {
          closeAndRefresh();
        }
      });

    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((gridRef) => {
        if (gridRef) {
          dataGridRef.current = gridRef;
        }
      });

    getRecordReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((rRef) => {
        if (rRef) {
          recordRef.current = rRef;
        }
      });

    saveDataSubject.current = new Subject<RecordResult>();

    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
      saveDataSubject.current?.complete();
    };
  }, []);

  useEffect(() => {
    if (
      Array.isArray(reverseLinkedRecords) &&
      reverseRecordLinkFormId &&
      recordSaveState === RecordSaveState.New &&
      isLinkedRecord === true &&
      formSettingState
    ) {
      const draftWorkflowStep = workflowSteps?.find((step) => step.draft === true);

      const recordData: RecordStructure = recordDataState ||
        defaultRecordData ||
        defaultLinkedRecordData || {
          original: {
            formId,
            fields: {},
            hierarchies: {},
            status: draftWorkflowStep?.label || 'Draft',
          },
          flat: {},
        };

      // use the form settings to find the reverse record link field and load the reverse record link ids into it
      // I'm not a fan of using the dynamic properties to find the correct dynamic field, but in this case we control
      // both ends of the code. In the past my dislike was because it was passing something from odin-web and using that
      // value in odin-components which would be writing odin-web specific logic in odin-components.
      const reverseRecordLinkField = findFieldRecursiveSearch(
        formSettingState.fields,
        (field) =>
          field.fieldType === 'CUSTOM' &&
          field.customFieldType === 'REVERSE_RECORD_LINK' &&
          field.dynamicProperties?.targetModuleFormId !== undefined &&
          Number(field.dynamicProperties.targetModuleFormId) === reverseRecordLinkFormId
      );
      if (reverseRecordLinkField && reverseRecordLinkField.id) {
        recordData.flat[reverseRecordLinkField.id] = reverseLinkedRecords;
        recordData.original.fields[reverseRecordLinkField.id] = reverseLinkedRecords;
        setRecordDataState(recordData);
      }
    }
  }, [
    isLinkedRecord,
    reverseLinkedRecords,
    reverseRecordLinkFormId,
    recordSaveState,
    recordDataState,
    formSettingState,
    defaultLinkedRecordData,
  ]);

  //workflow step and form buttons
  useEffect(() => {
    if (
      workflowSteps &&
      formSettingState &&
      formPermissions &&
      (recordSaveState === RecordSaveState.New || (recordSaveState === RecordSaveState.Update && recordPermissions))
    ) {
      const initialStep = workflowSteps.find((step) => step.id === formSettingState.initialStep?.id);
      const currentStep = currentWorkflowStep(
        formPermissions,
        singleRecordData?.original?.status ?? initialStep?.label
      );
      currentWorkflowRef.current = workflowSteps.find((step) => step.id === currentStep?.id);

      if (recordSaveState === RecordSaveState.New || (currentWorkflowRef.current?.editModeByDefault && canEditRecord)) {
        onRecordsEdit();
      }

      const nextStepButtons: Array<DynamicFormButtonSetting> = [];
      if (currentWorkflowRef.current?.nextSteps) {
        for (let i = 0, length = currentWorkflowRef.current?.nextSteps.length; i < length; i++) {
          const nextStep = currentWorkflowRef.current?.nextSteps[i];
          const nextStepButton: DynamicFormButtonSetting = {
            name: nextStep.label,
            text: nextStep.buttonCaption ?? '',
            htmlType: 'button', // workflow buttons should validate (MYOSH-1565), but they can be configured to skip validation (MYOSH-2277)
            id: `${nextStep.id}_${i}`,
            type: 'primary',
            variant: 'alternative',
            hidden: nextStep.hidden,
            className: 'flex-none',
            onClick: () => handleWorkflowStepButtonClick(nextStep),
          };

          if (recordState === RecordSaveState.Update && (currentStep || currentRecordPermissions)) {
            const canProgress = currentStep?.canProgress || currentRecordPermissions?.progressToNextStep || false;

            nextStepButtons.push({
              ...nextStepButton,
              hidden: !canProgress,
            });
          } else if (
            recordSaveState === RecordSaveState.New &&
            (defaultRecordData || defaultLinkedRecordData) &&
            formAccessControl &&
            userData
          ) {
            const defaultRecordFormData = (defaultRecordData || defaultLinkedRecordData) as RecordStructure;
            const canProgressRecord = workflowStepCanProgressPermission(
              formAccessControl.workflowSteps,
              defaultRecordFormData,
              currentWorkflowRef.current.id,
              userData
            );

            nextStepButtons.push({
              ...nextStepButton,
              hidden: !canProgressRecord,
            });
          }
        }
      }

      if (currentWorkflowRef.current?.hideRecordSaveButton === true) {
        setFormButtonsState([...nextStepButtons]);
      } else {
        const saveButton: DynamicFormButtonSetting = {
          name: 'save',
          text: t('save'),
          htmlType: 'button', // saving a record should not validate the form (https://myosh.atlassian.net/browse/MYOSH-1565?focusedCommentId=17471)
          type: 'text',
          onClick: onSaveButtonClick,
        };
        setFormButtonsState([...nextStepButtons, saveButton]);
      }
    }
  }, [
    workflowSteps,
    formSettingState,
    singleRecordData,
    recordSaveState,
    formPermissions,
    recordPermissions,
    formAccessControl,
    defaultRecordData,
    defaultLinkedRecordData,
    userData,
  ]);

  useEffect(() => {
    if (hasRecordDataError && recordDataError) {
      const errorReponse = recordDataError as GenericErrorResponse;
      if (errorReponse.status === 404 && errorReponse.data.validation.errors[0].includes('DeletedRecordException')) {
        showError(t('deleted-record-message'));
        dataGridRef.current?.api.data.removeRowById(Number(recordId));
        dispatch(recordApi.util.invalidateTags(['TotalRecordCount']));
        onClose(dynamicFormId.current);
      } else if (errorReponse.status === 403) {
        showError(t('record-toast-message'));
        dataGridRef.current?.api.data.removeRowById(Number(recordId));
        dispatch(recordApi.util.invalidateTags(['TotalRecordCount']));
        closeAndRefresh();
      }
    } else if (
      singleRecordData &&
      !isRecordFetching &&
      recordSaveState === RecordSaveState.Update &&
      !hasRecordDataError
    ) {
      setRecordTitle(getConstructedRecordTitle(singleRecordData.original));

      const newRecordData: RecordStructure = {
        original: {
          ...singleRecordData.original,
        },
        flat: {
          ...singleRecordData.flat,
        },
      };

      if (dynamicFormReference.current?.formIsDirty() || existingRecordWasSavedProgressedOrRefreshedRef.current) {
        dynamicFormReference.current?.resetFormState({ ...newRecordData.flat });
      }

      setRecordDataState(newRecordData);
    } else if (
      singleRecordData &&
      !isRecordFetching &&
      !hasRecordDataError &&
      !isRecordPermissionLoading &&
      recordSaveState === RecordSaveState.New &&
      !isLinkedRecord
    ) {
      const title = getConstructedRecordTitle(singleRecordData.original);
      const details = (
        <RecordTitle
          title={title}
          date={singleRecordData.original.creationDate ? new Date(singleRecordData.original.creationDate) : undefined}
        />
      );

      activeRecordReference.current?.updateActiveRecord(
        newRecordOldId,
        String(singleRecordData.original.id),
        moduleIcon,
        details,
        false,
        {
          title,
          recordId: singleRecordData.original.id,
          viewId,
          formId,
          formName: formSettingState?.caption,
          docNo: singleRecordData.original.docNo,
          status: singleRecordData.original.status,
          vikingLinkUrl: singleRecordData.original.vikingLinkUrl,
          displayText: singleRecordData.original.displayText,
        }
      );
    } else if (
      !singleRecordData &&
      !isRecordFetching &&
      recordSaveState === RecordSaveState.New &&
      (((!isLinkedRecord || (isLinkedRecord && isTextualQuestionnaireLinkedRecords)) && defaultRecordData) ||
        (isLinkedRecord && !isTextualQuestionnaireLinkedRecords && defaultLinkedRecordData))
    ) {
      const defaultRecordFormData = (defaultRecordData || defaultLinkedRecordData) as RecordStructure;
      const clonedDefaultRecordData = cloneDeep(defaultRecordFormData);
      const defaultDataKeys = Object.keys(defaultRecordFormData.flat);
      for (let i = 0; i < defaultDataKeys.length; i++) {
        const field = findFieldRecursiveSearch(
          formSettingsData?.fields ?? [],
          (field) => field.id.toString() === defaultDataKeys[i]
        );

        if (field?.fieldType === 'TIMEFIELD') {
          // The default value for a TIMEFIELD is retuned in this format: T02:45:03.000+08
          clonedDefaultRecordData.flat[defaultDataKeys[i]] = (
            defaultRecordFormData?.flat[defaultDataKeys[i]] as string
          ).slice(1, 6);
        }
      }

      setRecordDataState(clonedDefaultRecordData);
    }
  }, [
    defaultRecordData,
    defaultLinkedRecordData,
    singleRecordData,
    isRecordFetching,
    recordPermissions,
    recordDataError,
  ]);

  useEffect(() => {
    if (
      formSettingsData &&
      !isFormSettingsDataFetching &&
      workflowSteps &&
      !isWorkflowStepsFetching &&
      formPermissions &&
      !isFormPermissionsLoading &&
      userData &&
      !isLoadingUser &&
      formAccessControl &&
      !isFormAccessControlFetching &&
      (singleRecordData || recordSaveState === RecordSaveState.New) &&
      !isRecordFetching
    ) {
      const clonedFormSettingsData = cloneDeep(formSettingsData);
      const initialStep = workflowSteps.find((step) => step.id === formSettingsData.initialStep?.id);
      const currentStep = currentWorkflowStep(
        formPermissions,
        singleRecordData?.original?.status ?? initialStep?.label
      );
      const extractedSummaryFormFields = extractFormGroupFieldsByFieldType(
        formSettingsData?.fields,
        summaryRecordLinkFieldTypes as CombinedFieldType[]
      );
      const pathname = window.location.pathname;
      const oldId = pathname.substring(pathname.lastIndexOf('/') + 1);
      setNewRecordOldId(oldId);

      clonedFormSettingsData.customFieldComponents = {
        HIERARCHY: HfHierarchyField,
        PERSONFIELD: HfPersonField,
        TRAINING: HfTrainingField,
        MULTISELECTPERSONFIELD: HfMultiselectPersonField,
        ATTACHMENTS: HfAttachmentsField,
        TEXTUAL_QUESTIONNAIRE: HfQuestionnaire,
        NUMERIC_QUESTIONNAIRE: HfQuestionnaire,
        RISKRATING: HfRiskRating,
        RECORDLINK: HfLinkedRecords,
        REVERSE_RECORD_LINK: HfReverseLinkedRecords,
        USER_RECORDLINK: HfUserRecordLink,
        READERS: HfReadersField,
        SUMMARY_RECORD_LINK: HfSummaryRecordLink,
        VERSION: HfVersionField,
        INVITATION: HfInvitationField,
        EMAIL_THIS_BUTTON: HfEmailThisButton,
        INVITE_BUTTON: HfInviteButtonField,
        PDF_EXPORT: HfExportButtonField,
        WORD_EXPORT: HfExportButtonField,
        CLONERECORD: HfCloneRecordButtonField,
        LINK: HfLinkField,
        RECORD_OPTIONGROUP: HfOptionGroup,
        DROPDOWNMULTISELECT: HfDropDownMultiSelect,
        RECORD_MULTISELECTFIELD: HfComboboxMultiselect,
        RECORD_COMBOBOX: HfCombobox,
        RECORD_MULTISELECTCHECKBOX: HfMultiselectCheckbox,
        RECORD_TWINCOLUMNSELECT: HfTwinColumnSelect,
      };

      clonedFormSettingsData.fieldValueConverters = {
        CHECKBOX: (value) => {
          const convertedValue = forceAssert<CheckboxChangeEventType>(value);
          return convertedValue.checked;
        },
        DATEFIELD: (value) => {
          if (value !== undefined) {
            return value;
          } else {
            return null;
          }
        },
      };

      clonedFormSettingsData.fields.unshift({
        id: 'hierarchyValues',
        caption: t('hierarchy'),
        fieldName: 'hierarchyValues',
        fieldType: 'CUSTOM',
        position: 0,
        dynamicProperties: {
          hierarchyTypes: clonedFormSettingsData?.hierarchySettings?.hierarchyTypes,
          isExternal: false,
          showRecordWillBecomeUnavailableMessage,
          hideRecordWillBecomeUnavailableMessage,
        },
        required: DynamicFormRequiredType.True,
        customFieldType: 'HIERARCHY',
        wrapInGroup: true,
        customGroupTitle: t('hierarchy'),
        hidden: clonedFormSettingsData.hierarchySettings?.hiddenInReadMode && recordPanelReadOnlyMode,
        startOpen: clonedFormSettingsData.hierarchySettings?.isExpanded,
        validationFunc: validateByFieldType(t('hierarchy'), 'HIERARCHY', {
          hierarchyTypes: clonedFormSettingsData?.hierarchySettings?.hierarchyTypes,
          isExternal: false,
        }),
      });

      clonedFormSettingsData.fields.push({
        id: 'version',
        caption: t('version'),
        fieldName: 'version',
        fieldType: 'CUSTOM',
        position: clonedFormSettingsData.fields.length,
        customFieldType: 'VERSION',
        dynamicProperties: {
          version: singleRecordData?.flat['version'],
          locationState: {
            moduleIcon,
            viewId,
            formId,
            formName: clonedFormSettingsData.caption,
            vikingLinkUrl,
          },
        },
        wrapInGroup: true,
        customGroupTitle: t('version'),
        startOpen: true,
        hidden: clonedFormSettingsData.versionType === 0, //versionType is 0 when versioning is not enabled for the form https://myosh.atlassian.net/browse/MYOSH-7726
      });

      clonedFormSettingsData.fields.push({
        id: 'invitation',
        caption: t('invitations'),
        fieldName: 'invitation',
        fieldType: 'CUSTOM',
        position: clonedFormSettingsData.fields.length,
        customFieldType: 'INVITATION',
        wrapInGroup: true,
        customGroupTitle: t('invitations'),
        startOpen: true,
      });

      clonedFormSettingsData.fields.push({
        id: 'attachments', // Odin specific ID
        caption: t('attachments'),
        fieldName: 'attachments',
        fieldType: 'CUSTOM',
        position: clonedFormSettingsData.fields.length,
        customFieldType: 'ATTACHMENTS',
        dynamicProperties: {
          files: singleRecordData?.flat?.files ?? [],
          images: singleRecordData?.flat?.images ?? [],
          isAccordion: true,
        },
        wrapInGroup: true,
        customGroupTitle: t('attachments'),
        startOpen: true,
      });

      const currentWorkflowStepRolesPermissions = workflowSteps?.find(
        (step) => step.label === singleRecordData?.original.status
      )?.rolesPermissions;

      //modifies components into having a fieldType: CUSTOM
      clonedFormSettingsData.fields = clonedFormSettingsData.fields.map((field) => {
        const personFieldPermissions = formGroupPersonFieldPermissions(
          String(field.id),
          currentStep?.id,
          currentWorkflowStepRolesPermissions,
          clonedFormSettingsData.accessRights?.[field.id]
        );

        //attaches permissions to view or edit a form group
        const groupFieldPermissions = formGroupPermissions(
          currentStep?.sectionPermissions,
          personFieldPermissions,
          singleRecordData?.flat?.fields as Record<string, unknown>,
          customPropertyOfFormGroups(field.fieldName),
          field.id,
          userData.id
        );

        field = {
          ...field,
          permissions: groupFieldPermissions,
        };

        if (field.fieldType === 'GROUP' && field.fields) {
          field.fields = field.fields.map((fieldItem) => {
            const fieldType: CombinedFieldType = fieldItem.fieldType as CombinedFieldType;
            if (
              [
                'PERSONFIELD',
                'TRAINING',
                'MULTISELECTPERSONFIELD',
                'RISKRATING',
                'RECORDLINK',
                'TEXTUAL_QUESTIONNAIRE',
                'NUMERIC_QUESTIONNAIRE',
                'REVERSE_RECORD_LINK',
                'USER_RECORDLINK',
                'READERS',
                'SUMMARY_RECORD_LINK',
                'INVITE_BUTTON',
                'EMAIL_THIS_BUTTON',
                'PDF_EXPORT',
                'WORD_EXPORT',
                'CLONERECORD',
                'LINK',
                'RECORD_OPTIONGROUP',
                'RECORD_COMBOBOX',
                'RECORD_MULTISELECTFIELD',
                'RECORD_MULTISELECTCHECKBOX',
                'RECORD_TWINCOLUMNSELECT',
              ].includes(fieldType)
            ) {
              return {
                ...fieldItem,
                customFieldType: fieldType,
                fieldType: 'CUSTOM',
                validationFunc: validateByFieldType(fieldItem.caption, fieldType, fieldItem?.dynamicProperties),
                dynamicProperties: {
                  ...fieldItem.dynamicProperties,
                  saveRecord: () => {
                    dynamicFormReference.current?.submitForm(true);
                    return saveDataSubject.current?.asObservable();
                  },
                  recordSaveState,
                  summaryRecordLinkFormFields: fieldType === 'SUMMARY_RECORD_LINK' ? extractedSummaryFormFields : [],
                  showRecordWillBecomeUnavailableMessage,
                  hideRecordWillBecomeUnavailableMessage,
                  resetField: (name: string, value?: unknown) =>
                    dynamicFormReference.current?.resetFormField(name, value),
                },
              };
            } else if (fieldType === 'TIMEFIELD') {
              return {
                ...fieldItem,
                dynamicProperties: {
                  ...fieldItem.dynamicProperties,
                  currentTimeBtn: {
                    ...(fieldItem.dynamicProperties?.currentTimeBtn as CurrentTimeButton),
                    timeZone:
                      fieldItem.dynamicProperties?.['ignoreUserTimezone'] && schemaTimeZone
                        ? schemaTimeZone
                        : USER_TIME_ZONE,
                  } as CurrentTimeButton,
                },
              };
            }

            return fieldItem;
          });
        }
        return field;
      });

      clonedFormSettingsData.globalProperties = {
        isPreview,
        recordId: singleRecordData?.flat.id ?? recordId,
        formId,
        filterLocation: DataSearchLocation.Api,
        hierarchySettings: clonedFormSettingsData.hierarchySettings,
      };

      clonedFormSettingsData.userGroups = isSuperUser
        ? [
            {
              id: -1,
              caption: t('super-user'),
            },
          ]
        : userData.groups?.map((group) => {
            return {
              id: group.id,
              caption: group.caption.translations[0].value,
            };
          });

      // build workflow step visibility conditions
      const activeWorkflowStep = workflowSteps?.find((step) => step.id === currentStep?.id);
      const workflowStepVisibilityConditions = buildWorkflowStepVisibilityConditions(activeWorkflowStep);

      // ensure access control conditions are also applied dynamically
      if (formAccessControl && userData && activeWorkflowStep) {
        addAccessControlConditionsAsVisibilityConditions(
          workflowStepVisibilityConditions,
          formAccessControl.workflowSteps,
          activeWorkflowStep,
          userData.id
        );
      }

      clonedFormSettingsData.workflowStepVisibilityConditions = workflowStepVisibilityConditions;
      setFormSettingState(clonedFormSettingsData);

      if (clonedFormSettingsData.fields.length > 0) {
        const isHierarchiesHidden =
          recordPanelReadOnlyMode && clonedFormSettingsData.hierarchySettings?.hiddenInReadMode;

        setVisibleGroups(clonedFormSettingsData.fields, isHierarchiesHidden);
      }
    }
  }, [
    formSettingsData,
    isFormSettingsDataFetching,
    workflowSteps,
    isWorkflowStepsFetching,
    formPermissions,
    isFormPermissionsLoading,
    userData,
    isLoadingUser,
    singleRecordData,
    isRecordFetching,
    formAccessControl,
    isFormAccessControlFetching,
    recordPanelReadOnlyMode,
    recordSaveState,
  ]);

  const tagCreator = useTestTag('record');

  const setVisibleGroups = (fields: Array<DynamicFormField>, isHierarchiesHidden = false) => {
    const formGroups: DynamicFormField[] = [];
    // currently in the API all top level fields are groups, however, it is possible in the future that fields could
    // be at the top level without being in a group (dynamic form supports this already) so to make this code less
    // brittle I am filtering out the top level non-group fields
    const groupFields = fields.filter(
      (field) =>
        (field.fieldType === 'GROUP' && field.permissions?.read === true) ||
        (field.fieldType === 'CUSTOM' && field.wrapInGroup === true && field.permissions?.read === true)
    );
    for (let i = 0; i < groupFields.length; i++) {
      const recordGroup = groupFields[i];
      if (recordGroup.id === 'hierarchyValues' && !isHierarchiesHidden) {
        formGroups.push(recordGroup);
      } else if (!recordGroup.hidden && recordGroup.id !== 'hierarchyValues') {
        formGroups.push(recordGroup);
      }
    }

    setFormGroups(formGroups);
  };

  const dataRetrieval = useMemo<OdinDataRetrieval & OdinDataUpdate<JsonDataWrapper>>(() => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        await getRecordFieldOptions(formId, dispatch, subscriber, options, globalHierarchyFilters);
      },
      saveData: (
        data: JsonDataItem | JsonData,
        returnResult: OdinDataSender<JsonDataWrapper>,
        options?: OdinDataRetrievalOptions
      ) => {
        if (!isArray(data) && options?.customFieldType === 'ADDNEWMODAL' && options.fieldId) {
          returnResult.sendData({ data: [data], requestId: options.requestId });
        }
      },
    };
  }, [formId, globalHierarchyFilters]);

  const onRefreshRecord = useCallback(() => {
    //When creating a new linked record and the modal closes it doesn´t have a recordId
    if (recordId) {
      dispatch(
        recordApi.util.invalidateTags([
          { type: 'Record', id: recordId } as const,
          { type: 'LinkedRecord', id: `LIST-${recordId}` } as const,
          { type: 'ReverseLinkedRecord', id: `LIST-${recordId}` } as const,
          { type: 'SummaryLinkedRecord', id: `LIST-${recordId}` } as const,
          { type: 'AllFormsRecord', id: 'LIST' } as const,
          { type: 'RecordVersion', id: 'LIST' } as const,
          { type: 'Collaborator', id: 'LIST' } as const,
        ])
      );
      dispatch(fileApi.util.invalidateTags([{ type: 'File', id: `${recordId}-LIST` } as const]));
      existingRecordWasSavedProgressedOrRefreshedRef.current = true;
    }
  }, [recordId]);

  const closeAndRefresh = useCallback(() => {
    onClose(dynamicFormId.current);
    onRefreshRecord();
  }, [onClose, onRefreshRecord]);

  const handleWorkflowStepButtonClick = useCallback((nextStep: ExtendedDynamicFormNextWorkflowStep) => {
    if (nextStep?.promptBeforeAdvance) {
      nextWorkflowStepModalReference.current?.show(nextStep).then((result) => {
        if (result && result.id === nextStep?.id) {
          workflowStepButtonClick(nextStep);
        }
      });
    } else {
      workflowStepButtonClick(nextStep);
    }
  }, []);

  const workflowStepButtonClick = useCallback((nextStep: ExtendedDynamicFormNextWorkflowStep) => {
    nextWorkflowStepRef.current = nextStep;
    const skipValidation = nextStep.skipMandatoryFieldValidation === true;
    dynamicFormReference.current?.submitForm(skipValidation);
  }, []);

  const onRecordClose = () => {
    if (!dynamicFormReference.current?.formIsDirty()) {
      closeAndRefresh();
    } else {
      saveModalReference.current
        ?.show()
        ?.pipe(take(1))
        .subscribe((result) => {
          if (result.buttonName === 'save') {
            if (recordDataOriginal) {
              closeOnModalSaveRef.current = true;
              dynamicFormReference.current?.submitForm(true);
            } else {
              closeAndRefresh();
            }
          } else if (result.buttonName === 'discard') {
            closeAndRefresh();
          }
        });
    }
  };

  const onGroupItemClicked = (groupId: string, fieldType: string) => {
    if (fieldType === 'GROUP' || fieldType === 'CUSTOM') {
      dynamicFormReference.current?.moveGroupToTop(groupId);
    }
  };

  // Handles data grid updates when saving, progressing through the workflow steps or refreshing an existing record
  // if the record is from the module you're on
  useEffect(() => {
    if (hasRecordDataError) {
      existingRecordWasSavedProgressedOrRefreshedRef.current = false;
    } else if (
      (viewId === moduleViewId || window.location.href.includes(String(viewId))) &&
      recordSaveState === RecordSaveState.Update &&
      existingRecordWasSavedProgressedOrRefreshedRef.current &&
      !isRecordFetching &&
      singleRecordData
    ) {
      updateDataGridOnRecordUpdate();
    }
  }, [singleRecordData, recordSaveState, isRecordFetching, hasRecordDataError]);

  const _onUpdateRecordSuccess = useCallback(() => {
    setIsRecordSaved(true);
    showSaveNotificationAndCloseRecord();
    // scroll to last edited field, after timeout, to ensure the form has rendered
    setTimeout(
      () => dynamicFormReference.current?.scrollFieldIntoView((lastEditedFieldId.current || '') as string),
      3000
    );
  }, [closeAndRefresh, showSaveNotificationAndCloseRecord]);

  const saveRecord = useCallback(
    (saveData: RecordResult, successCallback: (response: PatchRecordResponse | PostRecordResponse) => void) => {
      if (recordSaveState === RecordSaveState.Update && saveData.id) {
        updateRecord({ id: saveData.id, patch: saveData, applyDefaultValues: false }).then((response) => {
          successCallback(response as PatchRecordResponse);
          existingRecordWasSavedProgressedOrRefreshedRef.current = true;
        });
      } else {
        addRecord({ body: saveData, applyDefaultValues: false })
          .then((response) => successCallback(response as PostRecordResponse))
          .then(() => {
            dataGridRef?.current?.api.data.getDataOverwritePageCache();
          });
      }
    },
    [recordSaveState, updateRecord, addRecord]
  );

  const _saveConflictResolvedRecord = useCallback(
    (record: RecordResult) => {
      saveRecord(record, (response: PatchRecordResponse | PostRecordResponse) => {
        const patchResponse = response as PatchRecordResponse;
        hidePerformingSaveToast();
        if (patchResponse?.data?.result) {
          _onUpdateRecordSuccess();
        } else {
          showErrorNotification();
          log('Failed to save conflict resolved record', { formId, error: patchResponse?.error });
        }
      });
    },
    [saveRecord, _onUpdateRecordSuccess, showErrorNotification]
  );

  const _resolveRecordMergeConflicts = useCallback(
    async (initialServerRecord: RecordResult, localRecord: RecordResult) => {
      try {
        // // resolve the record merge conflicts, explicitly avoid query cache
        const result = await getRecordById({ id: recordId as string, keyTypeId: true, cache: false }).unwrap();
        const serverRecord = result.original;
        const conflicts = findMergeConflicts(
          initialServerRecord,
          localRecord,
          serverRecord,
          dynamicFormReference.current?.formDirtyFields() || {},
          formSettingState as ExtendedDynamicFormSettings
        );

        if (conflicts.length > 0) {
          hidePerformingSaveToast();

          if (conflictsModalRef.current) {
            // await on value to avoid stale closures issues
            const conflictResolution: ConflictResolution = await firstValueFrom(
              conflictsModalRef.current.show(conflicts).pipe(take(1))
            );
            const { fields } = conflictResolution;
            if (fields && fields.length > 0) {
              // write resolved values
              const fieldsLength = fields.length;
              for (let i = 0; i < fieldsLength; i++) {
                if ('hierarchyValues' === fields[i].id) {
                  localRecord.hierarchyValues = fields[i].value as Record<string, HierarchyDropdownValue>;
                } else {
                  localRecord.fields[fields[i].id] = fields[i].value;
                }
              }

              // consolidated files/images
              localRecord.files = [...(serverRecord.files ?? [])];
              localRecord.images = [...(serverRecord.images ?? [])];

              // update lastChange and status value to allow save
              localRecord.lastChange = serverRecord.lastChange;
              localRecord.status = serverRecord.status;

              // save the record
              showPerformingSaveToast();
              _saveConflictResolvedRecord(localRecord);
            }
          }
        } else {
          // update lastChange and status value to allow save
          localRecord.lastChange = serverRecord.lastChange;
          localRecord.status = serverRecord.status;

          _saveConflictResolvedRecord(localRecord);
        }
      } catch (exception) {
        hidePerformingSaveToast();
        showErrorNotification();
        log('Conflict resolution failed', { formId, exception });
      }
    },
    [getRecordById, formSettingState, _saveConflictResolvedRecord, showErrorNotification]
  );

  const saveNewRecordCallBack = useCallback(
    (sender: OdinDataSender<Key | undefined>, response: PostRecordResponse) => {
      const postResponse = response as PostRecordResponse;
      const result = postResponse?.data?.result?.[0];
      if (result?.success === true && result?.id) {
        // called to complete the onSubmit...the id needs to be returned so onPostSubmit will run
        sender.sendData(result.id);
      } else if (postResponse?.error) {
        hidePerformingSaveToast();
        const errorType = recordErrorType(postResponse);
        if (errorType === 'automaticVersioningError') {
          showAutomaticVersioningErrorNotification();
          log('Failed to save new record due to automatic versioning rules', { formId, error: postResponse?.error });
        } else {
          showErrorNotification();
          log('Failed to save new record', { formId, error: postResponse?.error });
        }
      }
    },
    [closeAndRefresh, showErrorNotification]
  );

  const onError = useCallback(() => {
    if (!panelContextPropsRef.current?.batchSaveInProgress) {
      showWarning(t('record-validation-message'));
    }
    notifySaveFailed(dynamicFormId.current);
    setShowExpandButton(false);
    nextWorkflowStepRef.current = undefined; // clear nextWorkflowStepRef on validation error
  }, []);

  const onGetPreSubmitData = () => {
    showPerformingSaveToast();
  };

  const onPreSubmit = useCallback<PreSubmitHandler>((preSubmitData, formData) => {
    let _addedFiles: Array<string> = [];
    let _removedFiles: Array<string> = [];

    preSubmitData
      .filter((item) => item !== undefined)
      .forEach((item) => {
        if (item.added) {
          const added = item.added as AttachmentsAdd;
          if (added.questionId) {
            // attachment field in questionnaire
            const questionValue = (formData[added.id as number] as QuestionnaireFieldValue).value;
            questionValue?.forEach((value) => {
              if (value.questionId === added.questionId) {
                delete value?.attachments; // remove the 'validation' only attachment list
                value.files = [...(value.files ?? []), ...added.newAttachmentIds];
              }
            });
          }

          _addedFiles = [..._addedFiles, ...added.newAttachmentIds];
        }

        if (item.removed) {
          const removed = item.removed as AttachmentsRemove;
          if (removed.questionId) {
            // attachment field in questionnaire
            const questionValue = (formData[removed.id as number] as QuestionnaireFieldValue).value;
            questionValue?.forEach((value) => {
              if (value.questionId === removed.questionId) {
                delete value?.attachments; // remove the 'validation' only attachment list
              }
            });
          }

          _removedFiles = [..._removedFiles, ...removed.deletedAttachmentIds];
        }
      });

    if (_addedFiles.length > 0) {
      // Based on the https://myosh.atlassian.net/browse/MYOSH-2122?focusedCommentId=21041
      // we need to include all file IDs under the top level 'files' property as well
      formData.files = [..._addedFiles, ...((formData.files as Array<string>) ?? [])];
    }

    if (_removedFiles.length > 0) {
      const _removed = uniq(_removedFiles);
      if (formData.files) {
        formData.files = (formData.files as Array<string>).filter((fileId) => _removed.indexOf(fileId) === -1);
      }
      if (formData.images) {
        formData.images = (formData.images as Array<string>).filter((imageId) => _removed.indexOf(imageId) === -1);
      }

      // include the removed files/images as 'deletedFiles' in the payload (https://myosh.atlassian.net/browse/MYOSH-6113?focusedCommentId=53410)
      formData.deletedFiles = [..._removed];
    }

    return formData;
  }, []);

  const onFormSubmit = useCallback(
    (data: JsonDataItem, sender: OdinDataSender<Key | undefined>) => {
      // cleanup properties that cause issues with the API
      cleanupRecordData(data);
      // history field value updates need special handling
      const historyFields = extractHistoryFields(formSettingState?.fields || [], data);
      if (recordDataOriginal) {
        const initialServerRecord = recordDataOriginal;
        if (recordSaveState === RecordSaveState.Update) {
          setIsRecordSaved(false);
          const status = resolveRecordStatus(nextWorkflowStepRef.current?.label, initialServerRecord.status);
          const patchData: RecordResult = createPatchData(
            formId,
            status,
            data,
            dynamicFormReference.current?.formDirtyFields() || {}
          );
          const recordChanges = {
            ...patchData,
            id: initialServerRecord.id,
            lastChange: initialServerRecord.lastChange,
          };
          const updatedRecord = transformHistoryFieldsValue(recordChanges, historyFields);

          saveRecord(updatedRecord, async (response: PatchRecordResponse | PostRecordResponse) => {
            const patchResponse = response as PatchRecordResponse;

            if (patchResponse?.data?.result) {
              hidePerformingSaveToast();
              saveDataSubject.current?.next(updatedRecord);
              _onUpdateRecordSuccess();
              sender.sendData(patchResponse.data.result); // called to complete the onSubmit
            } else if (patchResponse?.error && patchResponse.error.status === 900) {
              if (!panelContextPropsRef.current?.batchSaveInProgress) {
                _resolveRecordMergeConflicts(initialServerRecord, updatedRecord);
              }
              notifySaveFailed(dynamicFormId.current);
            } else {
              const patchResponseError = patchResponse?.error?.data?.validation?.responseErrors;
              if (
                patchResponseError &&
                patchResponseError[0].message.includes(
                  'Automatic versioning is enabled and a record already exists with the same'
                )
              ) {
                hidePerformingSaveToast();
                showAutomaticVersioningFieldValueExistsNotification(patchResponseError[0].message);
                log('Failed to update record due to automatic versioning rules', {
                  formId,
                  error: patchResponse?.error,
                });
              } else {
                hidePerformingSaveToast();
                showErrorNotification();
                log(`Failed to update record with id: ${updatedRecord.id}`, { formId, error: patchResponse?.error });
              }
            }
          });
        } else if (recordSaveState === RecordSaveState.New) {
          const originalRecord = cloneDeep(initialServerRecord);
          updateOriginalData(data, originalRecord);
          const status = resolveRecordStatus(
            nextWorkflowStepRef.current?.label,
            formSettingState?.initialStep?.label.translations[0].value
          );
          const createdRecord = { ...transformHistoryFieldsValue(originalRecord, historyFields), status };
          saveRecord(createdRecord, async (response: PatchRecordResponse | PostRecordResponse) => {
            const finalResponse = response as PostRecordResponse;

            saveNewRecordCallBack(sender, finalResponse);
            if (finalResponse?.data?.result?.[0].success) {
              saveDataSubject.current?.next({
                ...createdRecord,
                id: finalResponse.data.result[0].id,
              });
            }
          });
        }
      } else if (!defaultRecordData && !defaultLinkedRecordData && recordSaveState === RecordSaveState.New) {
        const status = resolveRecordStatus(
          nextWorkflowStepRef.current?.label,
          formSettingState?.initialStep?.label.translations[0].value
        );
        const saveData = createSaveData(formId, status, data);
        const createdRecord = transformHistoryFieldsValue(saveData, historyFields);

        saveRecord(createdRecord, async (response: PatchRecordResponse | PostRecordResponse) => {
          const finalResponse = response as PostRecordResponse;
          saveNewRecordCallBack(sender, finalResponse);

          if (finalResponse?.data?.result?.[0].success) {
            saveDataSubject.current?.next({
              ...createdRecord,
              id: finalResponse.data.result[0].id,
            });
          }
        });
      }
    },
    [
      recordDataOriginal,
      recordSaveState,
      formId,
      _onUpdateRecordSuccess,
      _resolveRecordMergeConflicts,
      defaultRecordData,
      defaultLinkedRecordData,
      formSettingState,
      saveRecord,
      saveNewRecordCallBack,
      showErrorNotification,
      hidePerformingSaveToast,
    ]
  );

  const onPostSubmit = useCallback<PostSubmitHandler>(
    (id?: Key) => {
      if (id) {
        hidePerformingSaveToast();
        if (isLinkedRecord) {
          onLinkedRecordCreated?.(id as number);
          // when a new linked record is created, close the record (MYOSH-8580)
          closeOnModalSaveRef.current = true;
        }

        isSimpleView && onSimpleViewRecordCreated?.(String(id));
        showSaveNotificationAndCloseRecord(String(id));

        setNewRecordId(id as number);
        if (isLinkedRecord) {
          setRecordSaveState(RecordSaveState.Update);
        } else {
          // scroll to last edited field, after timeout, to ensure the form has rendered
          setTimeout(() => recordRef.current?.scrollFieldIntoView((lastEditedFieldId.current || '') as string), 3000);
        }
      }
    },
    [isLinkedRecord, onLinkedRecordCreated, showSaveNotificationAndCloseRecord, onSimpleViewRecordCreated]
  );

  const onFieldChanged = useCallback(
    debounce<FieldChangedFunction>((fieldId: Key) => {
      lastEditedFieldId.current = fieldId;
      notifyDirty(dynamicFormReference.current);
    }, 750),
    []
  );

  const onFormFieldsChanged = useCallback(
    (fields: Array<DynamicFormField>) => {
      const isHierarchiesHidden = recordPanelReadOnlyMode && formSettingState?.hierarchySettings?.hiddenInReadMode;
      const visibleSections = fields.filter((field) => field.hidden !== true);
      setVisibleGroups(visibleSections, isHierarchiesHidden);
    },
    [recordPanelReadOnlyMode, formSettingState]
  );

  const onAllGroupsExpanded = useCallback(() => {
    setShowExpandButton(false);
  }, []);

  const onAllGroupsCollapsed = useCallback(() => {
    setShowExpandButton(true);
  }, []);

  const onExpandCollapseSectionsClick = () => {
    if (showExpandButton) {
      dynamicFormReference.current?.expandAllGroups();
    } else {
      dynamicFormReference.current?.collapseAllGroups();
    }

    setShowExpandButton(!showExpandButton);
  };

  const editIconStyle = cx('mx-1 h-9 flex items-center hover:text-primary-2', {
    'text-primary-2': !recordPanelReadOnlyMode,
    'hidden ': isReadOnly,
  });

  const recordButtonStyles = cx({ 'bg-mono-1': isLinkedRecord });

  return (
    <>
      <div className="z-20 flex flex-shrink-0 flex-col">
        <div className="flex">
          <FormTitle title={recordTitle} />
          <div className={cx('flex', { 'w-full justify-end': !recordTitle })} ref={tagCreator('all-icon')}>
            <Tooltip
              tooltipClassName="bg-mono-1"
              description={showExpandButton ? String(t('expand-all')) : String(t('collapse-all'))}
              debounceTime={200}
              tooltipZIndexCheck
            >
              <IconButton
                onClick={onExpandCollapseSectionsClick}
                classNames="mx-1 h-9 w-6 flex items-center hover:text-primary-2"
              >
                {showExpandButton ? <OdinExpandIcon /> : <OdinCollapseIcon />}
              </IconButton>
            </Tooltip>
            {recordSaveState !== RecordSaveState.New && !isLinkedRecord && (
              <>
                {!isSimpleView && (
                  <div className="group relative" ref={tagCreator('more-icon')}>
                    {!panelContextProps?.fullScreen && (
                      <IconButton classNames="mx-1 h-9 flex items-center group-hover:text-primary-2 ">
                        <OdinIcon size={OdinIconSize.Medium} icon="More" className="w-6" />
                      </IconButton>
                    )}

                    <RecordMenu
                      recordId={recordId}
                      recordTitle={recordTitle}
                      panelContextProps={panelContextProps}
                      hasDeletePermission={currentRecordPermissions?.delete ?? false}
                      hasViewRecordAuditPermission={currentRecordPermissions?.viewRecordAudit ?? false}
                      closeAndRefresh={closeAndRefresh}
                      onClose={onClose}
                      onRefreshRecordClick={onRefreshRecord}
                      isRecordReadOnly={recordPanelReadOnlyMode}
                      formId={formId}
                      vikingLinkUrl={vikingLinkUrl}
                    />
                  </div>
                )}
                {canEditRecord && (
                  <Tooltip description={String(t('edit'))} tooltipClassName="bg-mono-1" debounceTime={200}>
                    <IconButton classNames={editIconStyle} onClick={onRecordsEdit}>
                      <OdinIcon size={OdinIconSize.Medium} type={OdinIconType.Line} icon="Pencil" className="w-5" />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            {!isLinkedRecord && !isSimpleView && <CloseActiveItemButton onClick={onRecordClose} />}
          </div>
        </div>
        <div className="flex">
          <RecordStatus
            status={singleRecordData?.original.status}
            color={currentWorkflowRef.current?.backgroundColor}
          />
          <RecordUnavailableMessage invisible={isRecordAvailableAfterSave} />
        </div>
      </div>
      <div
        className={cx(
          'relative flex flex-grow flex-row',
          { 'mt-6 overflow-y-hidden': !isLinkedRecord },
          { 'h-full pb-12': isLinkedRecord }
        )}
      >
        {formGroups && (panelContextProps?.fullScreen || isLinkedRecord || showFormGroupsNavigation) && (
          <FormGroupNavigation formGroups={formGroups} onGroupItemClicked={onGroupItemClicked} />
        )}
        {isRecordFetching && <FormLoading />}
        {formSettingState && formButtonsState && recordDataFlat && (
          <DynamicForm
            ref={dynamicFormReference}
            dynamicFormId={dynamicFormId.current}
            settings={formSettingState}
            dataRetrieval={dataRetrieval}
            data={recordDataFlat}
            onGetPreSubmitData={onGetPreSubmitData}
            onPreSubmit={onPreSubmit}
            onSubmit={onFormSubmit}
            onPostSubmit={onPostSubmit}
            onError={onError}
            showSubmitButtons={true}
            readOnly={recordPanelReadOnlyMode}
            buttons={formButtonsState}
            buttonStyles={recordButtonStyles}
            buttonsPosition={1}
            onFieldChanged={onFieldChanged}
            onFormFieldsChanged={onFormFieldsChanged}
            onAllGroupsExpanded={onAllGroupsExpanded}
            onAllGroupsCollapsed={onAllGroupsCollapsed}
          />
        )}
      </div>
      <WorkflowStepModal ref={nextWorkflowStepModalReference} />
      <ConflictsModal ref={conflictsModalRef} recordId={recordId} />
      <ModalDialog ref={saveModalReference} header={t('save-unsaved-changes')} buttons={saveModalButtons}>
        <div>{t('save-message')}</div>
      </ModalDialog>
      {featureTourData &&
        !featureTourData.hasRecordsSidebarTourRan &&
        featureTourData.hasFormSettingsTourRan &&
        featureTourData.hasSettingsMenuTourRan && <RecordSideBarFeatureTour featureTourData={featureTourData} />}
    </>
  );
}

export default forwardRef(Record);
