import React, { Key, useEffect, useRef, useState } from 'react';
import {
  DynamicFieldComponentProps,
  DynamicFormRequiredType,
  EmptyFieldLabel,
  FileRef,
  FileUpload,
  JsonDataItem,
  OdinDataSender,
} from '@myosh/odin-components';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { forceAssert } from '../../../common/common-functions';
import {
  useAddFileByRecordIdAndQuestionIdMutation,
  useAddFileByRecordIdMutation,
  useAddFileToStagingMutation,
  useDeleteAttachmentMutation,
  useGetFilesByRecordIdAndQuestionIdQuery,
  useGetFilesByRecordIdQuery,
  useLazyGetPresignedUrlQuery,
} from '../../../redux/services/file';
import { AttachmentItem, DeletedAttachmentItem } from '../../../@types/attachments';
import OdinLoadingIndicator from '../../common/odin-loading-indicator.component';
import AttachmentList, { AttachmentFileProps } from './attachment-list/attachment-list.component';
import { showError } from '../../../services/notification.service';

export interface AttachmentsFieldProps extends DynamicFieldComponentProps<Array<File> | DeletedAttachmentItem> {
  recordId?: string;
  questionId?: number;
  labelStyle?: string;
  onAttachmentsLoaded?: (attachments: AttachmentItem[]) => void;
  thumbnails?: boolean;
  files?: Array<string>; // existing file attachments
  images?: Array<string>; // existing image attachments
}

interface PresignedUrlResult {
  fileName: string;
  presignedUrl: string;
}

const maxUploadFileSize = 1048576000; // equal to 1 GB

const AttachmentsField = ({
  id,
  label,
  labelStyle,
  error,
  recordId,
  registerPreSubmitHandler,
  registerPostSubmitHandler,
  onChange,
  readOnly,
  questionId,
  onAttachmentsLoaded,
  thumbnails = false,
  permissions,
  required = DynamicFormRequiredType.False,
}: AttachmentsFieldProps) => {
  const { t } = useTranslation();

  const attachmentRef = useRef<Array<File>>([]);
  const fileRef = useRef<FileRef>(null);
  const deletedAttachmentsRef = useRef<Array<number>>([]);
  //Used as a guard to prevent duplicate handler registration in strict mode
  const preSubmitHandlerRegistered = useRef<boolean>(false);
  const postSubmitHandlerRegistered = useRef<boolean>(false);

  const {
    data: filesWithQuestion,
    isFetching: isFilesWithQuestionFetching,
    isLoading: isFilesWithQuestionsLoading,
  } = useGetFilesByRecordIdAndQuestionIdQuery(
    { recordId: recordId ?? '', questionId: questionId ?? -1 },
    { skip: !recordId || !questionId }
  );
  const {
    data: files,
    isFetching: isFilesFetching,
    isLoading: isFilesLoading,
  } = useGetFilesByRecordIdQuery(
    { recordId: recordId ?? '' },
    { skip: !recordId || (questionId && !isNaN(questionId)) || !permissions?.read }
  );

  const [getPresignedUrl] = useLazyGetPresignedUrlQuery();
  const [addFileToStaging] = useAddFileToStagingMutation();
  const [addFileByRecordId] = useAddFileByRecordIdMutation();
  const [addFileByRecordIdAndQuestionId] = useAddFileByRecordIdAndQuestionIdMutation();
  const [deleteAttachment] = useDeleteAttachmentMutation();

  const [attachments, setAttachments] = useState<Array<AttachmentItem>>();

  useEffect(() => {
    let attachmentsNew = undefined;
    if (questionId && filesWithQuestion && !isFilesWithQuestionFetching) {
      attachmentsNew = cloneDeep(filesWithQuestion);
    } else if (!questionId && files && !isFilesFetching) {
      attachmentsNew = cloneDeep(files);
    }
    if (attachmentsNew) {
      setAttachments(attachmentsNew);
      onAttachmentsLoaded?.(attachmentsNew);
    }
  }, [filesWithQuestion, isFilesWithQuestionFetching, files, isFilesFetching, questionId]);

  useEffect(() => {
    if (recordId && registerPreSubmitHandler && !preSubmitHandlerRegistered.current) {
      registerPreSubmitHandler((sender) => beforeSubmitFormHandler(Number(recordId), sender));
      preSubmitHandlerRegistered.current = true;
    }
  }, [recordId, registerPreSubmitHandler]);

  useEffect(() => {
    // when there is no recordId, we are dealing with a 'new' unsaved record
    if (!recordId && registerPostSubmitHandler && !postSubmitHandlerRegistered.current) {
      registerPostSubmitHandler((id: Key) => (sender) => afterSubmitFormHandler(Number(id), sender));
      postSubmitHandlerRegistered.current = true;
    }
  }, [recordId, registerPostSubmitHandler]);

  const uploadAttachments = async (recordId: number, isNewRecord?: boolean) => {
    const savedItems: Array<AttachmentItem> = [];
    if (questionId) {
      for (let i = 0, length = attachmentRef.current?.length; i < length; i++) {
        const formData = new FormData();
        formData.append('file', attachmentRef.current[i], attachmentRef.current[i].name);
        try {
          const objectKey = await addFileToStaging({ formData }).unwrap();

          if (objectKey) {
            const attachmentItem = await addFileByRecordIdAndQuestionId({
              questionId,
              objectKey,
              recordId,
            }).unwrap();
            if (attachmentItem) {
              const url = attachmentItem.url ?? window.URL.createObjectURL(attachmentRef.current[i]);
              savedItems.push({ ...attachmentItem, url });
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      Promise.allSettled(
        attachmentRef.current.map((attachment, index) => {
          fileRef.current?.resetFiles();
          return getPresignedUrl(index) //index used to invalidate cache
            .unwrap()
            .then((result: string) => {
              return fetch(result, {
                method: 'PUT',
                body: attachment,
              })
                .then(() => ({ presignedUrl: result, fileName: attachment.name }))
                .catch((error) => {
                  throw { error, fileName: attachment.name };
                });
            });
        })
      ).then(async (settledResults: PromiseSettledResult<PresignedUrlResult>[]) => {
        const fulfilledResults = settledResults
          .filter(
            (res): res is PromiseFulfilledResult<PresignedUrlResult> =>
              res.status === 'fulfilled' && !('error' in res.value)
          )
          .map((res) => res.value);

        if (fulfilledResults.length > 0) {
          try {
            const attachmentItems = await addFileByRecordId({
              confirmations: fulfilledResults,
              recordId,
              updateRecordLog: isNewRecord === true,
            }).unwrap();
            if (attachmentItems) {
              attachmentItems.forEach((attachmentItem) => {
                savedItems.push({ ...attachmentItem, url: attachmentItem.url });
              });
              setAttachments(attachments?.concat(attachmentItems));
            }
          } catch (error) {
            showError(String(error));
          }
        }
      });
    }
    return savedItems;
  };

  const processUploadedAttachments = (savedItems: Array<AttachmentItem>) => {
    return new Promise<JsonDataItem | undefined>((resolve) => {
      let result;

      if (savedItems.length > 0) {
        const attachmentIds = savedItems.map((item) => {
          // TQ files should use the 'attachmentId' https://myosh.atlassian.net/browse/MYOSH-2122?focusedCommentId=21097
          if (questionId && item.attachmentId) {
            return item.attachmentId.toString();
          }
          return item.id.toString();
        });
        // store updates
        result = {
          id, // field id
          questionId,
          newAttachmentIds: attachmentIds, // newly uploaded attachment IDs
        };

        fileRef.current?.resetFiles();
      }

      resolve(result);
    });
  };

  const deleteAttachments = async (recordId: number) => {
    const deletedItems: Array<DeletedAttachmentItem> = [];

    for (let i = 0, length = deletedAttachmentsRef.current?.length; i < length; i++) {
      try {
        await deleteAttachment({
          id: deletedAttachmentsRef.current[i],
          recordId,
          questionId,
        }).unwrap();

        deletedItems.push({ id: deletedAttachmentsRef.current[i] });
      } catch (error) {
        console.error(error);
      }
    }
    deletedAttachmentsRef.current = [];
    return deletedItems;
  };

  const processDeletedAttachments = (deletedItems: Array<DeletedAttachmentItem>) => {
    return new Promise<JsonDataItem | undefined>((resolve) => {
      let result;

      if (deletedItems.length > 0) {
        const deleteItemIds = deletedItems.map((item) => String(item.id));
        // store updates
        result = {
          id, // field id
          questionId,
          deletedAttachmentIds: deleteItemIds, // deleted attachment IDs
        };
      }

      resolve(result);
    });
  };

  const beforeSubmitFormHandler = (recordId: number, sender: OdinDataSender<JsonDataItem>) => {
    const _upload: Promise<JsonDataItem | undefined> = uploadAttachments(recordId).then(processUploadedAttachments);
    const _delete: Promise<JsonDataItem | undefined> = deleteAttachments(recordId).then(processDeletedAttachments);

    Promise.allSettled([_upload, _delete])
      .then((results) => {
        let attachments = {};
        if (results[0].status === 'fulfilled' && results[0].value) {
          attachments = { ...attachments, added: results[0].value };
        }
        if (results[1].status === 'fulfilled' && results[1].value) {
          attachments = { ...attachments, removed: results[1].value };
        }

        // notify before submit subscriber
        sender.sendFinalData(attachments);
      })
      .finally(() => {
        sender.sendFinalData();
      });
  };

  const afterSubmitFormHandler = (recordId: number, sender: OdinDataSender<void>) => {
    uploadAttachments(Number(recordId), true)
      .then((savedItems) => {
        if (savedItems.length > 0) {
          setAttachments(attachments?.concat(savedItems));
          fileRef.current?.resetFiles();
        }
      })
      .finally(() => {
        sender.sendFinalData();
      });
  };

  const handleAttachmentDelete = (id: string) => {
    deletedAttachmentsRef.current.push(Number(id));
    onChange?.({ id: Number(id) });
  };

  const handleChange = (newValue?: Array<File>) => {
    attachmentRef.current = newValue || [];
    onChange?.(newValue);
  };

  return (
    <>
      <FileUpload
        classNames="mb-2"
        maxUploadFiles={20}
        maxUploadFileSize={maxUploadFileSize}
        label={label}
        error={error}
        readOnly={readOnly}
        onChange={handleChange}
        ref={fileRef}
        labelStyle={labelStyle}
        thumbnails={thumbnails}
        required={required}
        containerStyles={questionId ? 'min-h-[100px]' : ''}
      />
      {!attachments && (isFilesWithQuestionsLoading || isFilesLoading) && <OdinLoadingIndicator label={t('loading')} />}
      {readOnly && attachments?.length === 0 && <EmptyFieldLabel />}
      {attachments && (
        <AttachmentList
          attachments={forceAssert<Array<AttachmentFileProps>>(attachments)}
          thumbnails={thumbnails}
          readOnly={readOnly as boolean}
          onAttachmentDelete={handleAttachmentDelete}
        />
      )}
    </>
  );
};

export default AttachmentsField;
