import { createApi } from '@reduxjs/toolkit/query/react';
import { OccupationsResponse, OccupationItem, OccupationWraper, OccupationsApiProps } from '../../@types/occupations';
import { ExtendedOccupationItem, createOccupationData } from '../../common/occupation-functions';
import {
  createCustomSortWithOrderBy,
  createFieldFilterText,
  transformOccupationRequirements,
} from '../../common/common-functions';
import {
  OccupationalRequirementsApiProps,
  OccupationalRequirementsItem,
  OccupationalRequirementsResponse,
  OccupationalRequirementsStructure,
  OccupationalRequirementsWraper,
} from '../../@types/occupational-requirements';
import { baseQueryWithReAuthAndUrlEncoding, providesList } from './api';
import { createOccupationalRequirementsData } from '../../common/training-management-functions';

export const occupationApi = createApi({
  reducerPath: 'occupation',
  baseQuery: baseQueryWithReAuthAndUrlEncoding,
  tagTypes: ['Occupation', 'OccupationalRequirement'],
  endpoints: (builder) => ({
    getOccupationalRequirements: builder.query<Array<OccupationalRequirementsItem>, OccupationalRequirementsApiProps>({
      query: (args) =>
        `/occupational-requirements?page=${args.page}&pageSize=${args.pageSize}${createCustomSortWithOrderBy(
          args.sort
        )}${createFieldFilterText({ filters: args.filters })}`,
      transformResponse: (response: OccupationalRequirementsResponse) =>
        transformOccupationRequirements(response?.items ?? []),
      providesTags: (result) => providesList(result, 'OccupationalRequirement'),
    }),
    getOccupationalRequirementsById: builder.query<OccupationalRequirementsStructure, number>({
      query: (id: number) => `/occupational-requirements/${id}?filter=hierarchyFields:archived:eq:false`,
      transformResponse: (response: OccupationalRequirementsWraper) =>
        createOccupationalRequirementsData(response.result),
      providesTags: (result) =>
        result ? [{ type: 'OccupationalRequirement' as const, id: result.id as number }] : ['OccupationalRequirement'],
    }),
    updateOccupationalRequirement: builder.mutation<void, Partial<OccupationalRequirementsItem>>({
      query: ({ id, ...patch }) => ({
        url: `/occupational-requirements/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, _error, { id }) => {
        if (result) {
          return [{ type: 'OccupationalRequirement', id }];
        } else {
          return [];
        }
      },
    }),
    getOccupations: builder.query<Array<OccupationItem>, OccupationsApiProps>({
      query: (args) =>
        `/occupations/simple?page=${args.page}&pageSize=${args.pageSize}${createFieldFilterText({
          filters: args.filters,
        })}${createCustomSortWithOrderBy(args.sort)}&excludeAllOccupations=${args.excludeAllOccupations ?? false}`,
      transformResponse: (response: OccupationsResponse) => response?.items || [],
      providesTags: (result) => providesList(result, 'Occupation'),
    }),
    getOccupationById: builder.query<ExtendedOccupationItem, number>({
      query: (id) => `/occupations/simple/${id}`,
      transformResponse: (response: OccupationWraper) => createOccupationData(response.result),
      providesTags: (result) => (result ? [{ type: 'Occupation' as const, id: result.id as number }] : ['Occupation']),
    }),
    addOccupation: builder.mutation<void, Omit<Array<OccupationItem>, 'id'>>({
      query: (body) => ({
        url: `/occupations/simple`,
        method: 'POST',
        body: body,
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),
      invalidatesTags: (result) => {
        if (result) {
          return [{ type: 'Occupation', id: 'LIST' }];
        } else {
          return [];
        }
      },
    }),
    updateOccupation: builder.mutation<void, Partial<OccupationItem>>({
      query: ({ id, ...patch }) => ({
        url: `/occupations/simple/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result) {
          return [{ type: 'Occupation', id: arg.id }];
        } else {
          return [];
        }
      },
    }),
  }),
});

export const {
  useGetOccupationsQuery,
  useGetOccupationByIdQuery,
  useAddOccupationMutation,
  useUpdateOccupationMutation,
  useGetOccupationalRequirementsByIdQuery,
  useLazyGetOccupationalRequirementsByIdQuery,
  useGetOccupationalRequirementsQuery,
  useUpdateOccupationalRequirementMutation,
  useLazyGetOccupationByIdQuery,
} = occupationApi;
