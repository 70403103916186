import { RadioGroup } from '@myosh/odin-components';
import React from 'react';
import { CloneWizardState } from './clone-record-button-field.component';
import { useTranslation } from 'react-i18next';

interface ThirdStepProps {
  setCloneWizardState: React.Dispatch<React.SetStateAction<CloneWizardState>>;
  cloneWizardState: CloneWizardState;
}

const attachementsOptions = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

const ThirdStep = ({ setCloneWizardState, cloneWizardState }: ThirdStepProps) => {
  const { t } = useTranslation();

  return (
    <RadioGroup
      name="attachements"
      id="attachements"
      textField="text"
      valueField="value"
      data={attachementsOptions}
      value={cloneWizardState.attachmentOption}
      orientation="HORIZONTAL"
      label={t('clone-records-attachments-label')}
      onChange={(value) =>
        setCloneWizardState((prevState: CloneWizardState) => ({
          ...prevState,
          attachmentOption: value,
        }))
      }
    />
  );
};

export default ThirdStep;
