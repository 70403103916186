import { useEffect, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { DropDownResult } from '@myosh/odin-components';
import { DefaultPersonConfiguration } from '../../../@types/forms';
import { useLazyGetUserByIdQuery } from '../../../redux/services/user';
import { UserFields } from '../../../common/user-config';
import { personFieldDeselectValue } from './person-field.component';

interface ParentPersonManagerProps {
  control: Control;
  fieldId: number;
  config: DefaultPersonConfiguration;
}

interface TargetFieldValueOriginalData {
  managerId?: number;
  managerText?: string;
  text?: string;
  value?: unknown;
  _uniqueId?: string;
}

interface TargetFieldValue {
  originalData?: TargetFieldValueOriginalData;
  text?: string;
  value?: unknown;
}

interface ManagerValue extends DropDownResult {
  managerId?: number;
  managerText?: string;
}

const useGetPersonFieldDefaultValue = ({ control, config }: ParentPersonManagerProps) => {
  const [manager, setManager] = useState<ManagerValue>();
  const [getUserById] = useLazyGetUserByIdQuery();

  const targetFieldName = String(config?.parentField?.id);
  const isDefaultValueAnotherPersonFieldsManager = config?.defaultValue === '[AnotherPersonFieldsManager]';

  const targetFieldValue: TargetFieldValue | undefined = useWatch({
    control,
    name: targetFieldName,
    disabled: !isDefaultValueAnotherPersonFieldsManager,
    exact: true,
  });

  useEffect(() => {
    if (isDefaultValueAnotherPersonFieldsManager) {
      if (targetFieldValue && targetFieldValue.value !== personFieldDeselectValue.value) {
        const { managerId, managerText } = targetFieldValue?.originalData ?? {};
        if (managerText && managerId) {
          setManager({ value: managerId, text: managerText });
        } else if (Number(targetFieldValue.value)) {
          // get the users' manager if not present in field value
          getUserById({ id: Number(targetFieldValue.value), fields: UserFields.manager }, true)
            .unwrap()
            .then((user) => {
              if (user.manager) {
                setManager({ ...(user.manager as DropDownResult) });
              } else {
                setManager(personFieldDeselectValue);
              }
            })
            .catch(() => setManager(personFieldDeselectValue));
        }
      } else {
        setManager(personFieldDeselectValue);
      }
    }
  }, [targetFieldValue]);

  return manager;
};

export default useGetPersonFieldDefaultValue;
