import {
  DataSearchLocation,
  DropDown,
  DropDownFeatures,
  DropDownNoSelection,
  DropDownOptionTemplateType,
  DropDownRef,
  DropDownResult,
  DynamicFieldComponentProps,
  DynamicFieldDataProps,
} from '@myosh/odin-components';
import { TypeOrArray } from '@myosh/odin-components/dist/types/components/common-interfaces';
import { ActionSectionItem } from '@myosh/odin-components/dist/types/components/fields/drop-down/drop-down-action-section.component';
import cx from 'classnames';
import React, { forwardRef, Ref } from 'react';

interface OdinDropDownProps
  extends DynamicFieldComponentProps<TypeOrArray<DropDownResult> | DropDownNoSelection>,
    DynamicFieldDataProps {
  labelInsideDropDown?: boolean;
  allowClear?: boolean;
  allowSearch?: boolean;
  setDefaultValue?: boolean;
  showAddNewOption?: boolean;
  /**
   * If true this will cause the data to load when the component is created instead of when the drop-down-old list is opened
   * (default: false).
   */
  preloadData?: boolean;
  filterLocation?: DataSearchLocation;
  actionItems?: Array<ActionSectionItem>;
  onActionItemChanged?: (item: ActionSectionItem, features: DropDownFeatures) => void;
  className?: string;
  dropDownButtonStyles?: string;
  size?: 'sm' | 'md' | 'lg' | 'xlg';
  deselectValue?: DropDownResult | DropDownNoSelection;
  onDoubleClick?: (value: DropDownResult) => void;
  useInputStyle?: boolean;
  shouldUseCreatePortal?: boolean;
  allowDuplicateCall?: boolean;
  onSelectorVisible?: (visible: boolean) => void;
  clearButtonStyles?: string;
  labelStyles?: string;
  optionListStyle?: string;
  optionHoverStyle?: string;
  optionSelectedStyle?: string;
  optionListPanelStyles?: string;
  optionListPanelShouldMatchTargetWidth?: boolean;
  optionTemplate?: DropDownOptionTemplateType;
  showDataOnSearch?: boolean;
  minimumSearchCharacters?: number;
}

const OdinDropDown = (props: OdinDropDownProps, ref: Ref<DropDownRef>) => {
  const {
    id,
    textField,
    valueField,
    label,
    value,
    error,
    labelInsideDropDown = false,
    allowClear = true,
    allowSearch = true,
    setDefaultValue = false,
    showAddNewOption = false,
    preloadData = false,
    placeholder,
    filterLocation = DataSearchLocation.Local,
    actionItems,
    onActionItemChanged,
    data,
    className,
    dropDownButtonStyles,
    readOnly = false,
    required,
    size = 'sm',
    useInputStyle = false,
    onChange,
    inputRef,
    deselectValue,
    onDoubleClick,
    disabled,
    shouldUseCreatePortal = false,
    allowDuplicateCall,
    onSelectorVisible,
    clearButtonStyles,
    labelStyles,
    optionHoverStyle,
    optionSelectedStyle,
    optionListStyle,
    optionListPanelStyles,
    optionListPanelShouldMatchTargetWidth = true,
    optionTemplate,
    showDataOnSearch,
    minimumSearchCharacters,
  } = props;

  const odinLabelStyles =
    labelStyles ??
    cx({
      'text-sm font-bold': size === 'sm',
      'text-xxs flex pl-3 leading-3 pt-0.5 pr-2 whitespace-nowrap border-transparent border-t bg-gray-5 rounded-t':
        size === 'md',
      'text-xxs flex pl-3 leading-3 pt-1 font-bold': size === 'lg',
      'text-sm flex': size === 'xlg',
    });

  const dropDownStyles = cx(
    {
      '!border-b !bg-gray-5 !px-2 !py-0.5': size === 'xlg',
      '!border-0 !border-b !bg-gray-5': size === 'lg' || size === 'md',
      '!leading-4 !py-1 !text-sm': readOnly,
    },
    {
      '!border-gray-2': !error && (size === 'lg' || size === 'md'),
    }
  );

  const inputStyles = cx('!grow !placeholder-gray-3', {
    '!text-3xl !py-px !font-bold !leading-9': size === 'xlg',
    '!text-sm !py-px !leading-5': size === 'lg',
    '!text-sm !py-px !font-medium !leading-3': size === 'md',
    '!text-sm': size === 'sm' && !readOnly,
    '!overflow-x-hidden !max-w-lg': useInputStyle,
    '!text-gray-3 !pointer-events-none': disabled,
  });

  const dropDownButtonFinalStyles = cx(
    {
      '-my-1 -mr-1': size === 'xlg',
      '-my-px': size === 'md',
      'h-6': size === 'sm',
      'ml-1': size === 'sm' && !readOnly,
    },
    dropDownButtonStyles
  );

  return (
    <DropDown
      ref={ref}
      id={id}
      textField={textField}
      valueField={valueField}
      label={label}
      value={value}
      error={error}
      labelInsideDropDown={labelInsideDropDown}
      allowClear={allowClear}
      allowSearch={allowSearch}
      setDefaultValue={setDefaultValue}
      showAddNewOption={showAddNewOption}
      preloadData={preloadData}
      placeholder={placeholder}
      filterLocation={filterLocation}
      actionItems={actionItems}
      onActionItemChanged={onActionItemChanged}
      data={data}
      className={className}
      readOnly={readOnly}
      required={required}
      onChange={onChange}
      inputRef={inputRef}
      deselectValue={deselectValue}
      labelStyles={odinLabelStyles}
      dropDownStyles={dropDownStyles}
      selectorStyles={inputStyles}
      dropDownButtonStyles={dropDownButtonFinalStyles}
      clearButtonStyles={clearButtonStyles}
      onDoubleClick={onDoubleClick}
      disabled={disabled}
      shouldUseCreatePortal={shouldUseCreatePortal}
      allowDuplicateCall={allowDuplicateCall}
      onSelectorVisible={onSelectorVisible}
      optionSelectedStyle={optionSelectedStyle}
      optionHoverStyle={optionHoverStyle}
      optionListStyle={optionListStyle}
      optionListPanelShouldMatchTargetWidth={optionListPanelShouldMatchTargetWidth}
      optionListPanelStyles={optionListPanelStyles}
      optionTemplate={optionTemplate}
      showDataOnSearch={showDataOnSearch}
      minimumSearchCharacters={minimumSearchCharacters}
    />
  );
};

export default forwardRef(OdinDropDown);
