import React, { forwardRef, Ref, useEffect, useMemo, useRef, useState, useImperativeHandle } from 'react';
import {
  DataGridRef,
  DynamicFormButtonSetting,
  DynamicFormRequiredType,
  DynamicFormSettings,
  DynamicForm,
  DynamicFormRef,
  JsonDataItem,
  ModalDialogRef,
  ModalDialog,
} from '@myosh/odin-components';
import { ActiveRecordContextProps } from '../../../active-record/active-record.component';
import { cloneDeep, debounce } from 'lodash';
import { BehaviorSubject, Subject, skip, take, takeUntil } from 'rxjs';
import { v4 } from 'uuid';
import {
  competencyApi,
  useGetCompetencyGroupsQuery,
  useUpdateCompetencyGroupMutation,
} from '../../../../redux/services/competency';
import { FormLoading } from '../../../form/form-loading.component';
import { FormTitle } from '../../../form/form-title.component';
import useProfileData from '../../../../hooks/use-profile-data';
import { getDataGridReference } from '../../../../services/data-grid.service';
import { processFieldFilters, dateFormatBasedOnFilter, forceAssert } from '../../../../common/common-functions';
import { CompetencyGroupItem, CompetencyGroupMutationResponse } from '../../../../@types/competency-groups';
import useTrainingManagementEditAccess from '../../../../hooks/use-training-management-edit-access';
import { saveModalButtons } from '../../../../common/common-administration-utils';
import usePerformingSaveToast from '../../../../hooks/use-performing-save-toast';
import { promiseToast, showError, showSuccess } from '../../../../services/notification.service';
import i18next from '../../../../i18n';
import { useTranslation } from 'react-i18next';
import useDynamicFormNotifier from '../../../../hooks/use-dynamic-form-notifier';
import CloseActiveItemButton from '../../../common/close-active-item-button';
import {
  archieveButton,
  getArchiveRestoreMessages,
  restoreButton,
  saveButton,
} from '../../../../pages/admin/admin-utils';
import { useAppDispatch } from '../../../../redux/hooks';

interface CompetencyGroupsRef {
  submitForm?: () => void;
}

export interface CompetencyGroupsDetailsProps {
  competencyGroupId: number;
  title?: string;
  editable?: boolean;
  onClose: (id: string) => void;
  panelSettings?: BehaviorSubject<ActiveRecordContextProps>;
  onCloseActiveRecordConfirmationModalRef?: ModalDialogRef | null;
}

const getCompetencyGroupFormSettngs = (readOnly?: boolean): DynamicFormSettings => {
  return {
    id: 0,
    caption: '',
    fields: [
      {
        id: 0,
        fieldType: 'TEXTFIELD',
        fieldName: 'name',
        caption: i18next.t('competency-group-name'),
        required: DynamicFormRequiredType.True,
        position: 0,
        permissions: { read: true, edit: !readOnly },
      },
    ],
  };
};

function CompetencyGroupsDetails(props: CompetencyGroupsDetailsProps, ref: Ref<CompetencyGroupsRef>) {
  const { title, competencyGroupId, onClose, panelSettings, onCloseActiveRecordConfirmationModalRef } = props;

  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const dynamicFormId = useRef(v4());
  const destroySubject = useRef(new Subject<void>());
  const saveModalReference = useRef<ModalDialogRef>(null);
  const closeOnModalSaveRef = useRef(false);
  const dataGridRef = useRef<DataGridRef>();
  const [readOnly, setReadOnly] = useState<boolean>();
  const [formButtons, setFormButtons] = useState<Array<DynamicFormButtonSetting>>([]);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [competencyGroup, setCompetencyGroup] = useState<CompetencyGroupItem>();
  const [panelContextProps, setPanelContextProps] = useState<ActiveRecordContextProps>();

  const {
    data: competencyGroupData,
    isLoading,
    isFetching,
  } = useGetCompetencyGroupsQuery(
    {
      filters: processFieldFilters({ id: { value: competencyGroupId, comparison: '=' } }, dateFormatBasedOnFilter),
      page: 1,
      pageSize: 50,
    },
    { skip: competencyGroupId === undefined }
  );

  const [updateCompetencyGroup] = useUpdateCompetencyGroupMutation();
  const { profileData: { user: userData } = {} } = useProfileData();
  const isEditDisabled = useTrainingManagementEditAccess(userData);

  useEffect(() => {
    if (!isEditDisabled && !competencyGroup?.archived) {
      setReadOnly(false);
    } else {
      setReadOnly(true);
    }
  }, [isEditDisabled, competencyGroup?.archived]);

  const { notifyDirty, notifySaveSucceeded, notifySaveFailed } = useDynamicFormNotifier(dynamicFormId.current);

  const { showPerformingSaveToast, hidePerformingSaveToast } = usePerformingSaveToast({
    shouldFreezeActiveRecord: true,
    disabled: panelContextProps?.batchSaveInProgress,
  });

  useEffect(() => {
    panelSettings?.pipe(skip(1), takeUntil(destroySubject.current)).subscribe((settings) => {
      setPanelContextProps(settings);
    });

    onCloseActiveRecordConfirmationModalRef
      ?.subscribeToDialogResult()
      ?.pipe(takeUntil(destroySubject.current))
      .subscribe((result) => {
        if (result.buttonName === 'save' && dynamicFormReference.current?.formIsDirty()) {
          dynamicFormReference.current?.submitForm(true);
        } else if (result.buttonName === 'discard') {
          onClose(dynamicFormId.current);
        }
      });

    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((gridRef) => {
        if (gridRef) {
          dataGridRef.current = gridRef;
        }
      });
    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
    };
  }, []);

  const showErrorToast = () => {
    if (!panelContextProps?.batchSaveInProgress) {
      showError(t('user-details-save-failed'));
    }
    notifySaveFailed(dynamicFormId.current);
  };

  const onArchiveCompetency = (archive: boolean) => {
    if (competencyGroupId) {
      const data = forceAssert<CompetencyGroupItem>(dynamicFormReference.current?.getData() || {});
      promiseToast(
        updateCompetencyGroup({ ...data, id: competencyGroupId, archived: archive })
          .then(() => {
            onClose(dynamicFormId.current);
            dataGridRef.current?.api.data.getDataOverwritePageCache();
            dispatch(competencyApi.util.invalidateTags([{ type: 'CompetencyGroup', id: 'LIST' }]));
          })
          .catch(() => showErrorToast()),
        getArchiveRestoreMessages(archive)
      );
    }
  };
  useEffect(() => {
    if (competencyGroupData && !isFetching) {
      const data = cloneDeep(competencyGroupData[0]);
      setCompetencyGroup(data);
      const newFormButtons: Array<DynamicFormButtonSetting> = [];
      if (data.archived) {
        const restore = cloneDeep(restoreButton);
        newFormButtons.push(restore);
        restore.onClick = () => onArchiveCompetency(false);
      } else {
        const archieve = cloneDeep(archieveButton);
        archieve.onClick = () => onArchiveCompetency(true);
        newFormButtons.push(cloneDeep(saveButton), archieve);
      }
      if (isEditDisabled) {
        setFormButtons(newFormButtons.map((button) => ({ ...button, disabled: true })));
      } else {
        setFormButtons(newFormButtons);
      }
    }
  }, [competencyGroupData, isFetching, isEditDisabled]);

  const competencyGroupsSettings = useMemo(() => {
    if (competencyGroup) {
      return getCompetencyGroupFormSettngs(readOnly);
    }
  }, [competencyGroup, readOnly]);

  useImperativeHandle(ref, () => ({
    submitForm: dynamicFormReference.current?.submitForm,
  }));

  const showErrorNotification = () => {
    if (!panelContextProps?.batchSaveInProgress) {
      showError(t('competency-group-details-save-failed'));
    }
    notifySaveFailed(dynamicFormId.current);
  };

  const saveCompetencyGroupData = (
    competencyGroupData: CompetencyGroupItem,
    successCallback: (response: CompetencyGroupMutationResponse) => void
  ) => {
    showPerformingSaveToast();
    if (competencyGroupData.id) {
      updateCompetencyGroup(competencyGroupData)
        .then((response) => successCallback(response as CompetencyGroupMutationResponse))
        .catch(() => {
          showErrorNotification();
        });
    }
  };

  const onCloseCompetencyGroupDetails = () => {
    if (!dynamicFormReference.current?.formIsDirty()) {
      onClose(dynamicFormId.current);
    } else {
      saveModalReference.current
        ?.show()
        ?.pipe(take(1))
        .subscribe((result) => {
          if ('save' === result.buttonName) {
            const data = forceAssert<CompetencyGroupItem>(dynamicFormReference.current?.getData() || {});
            if (competencyGroupId) {
              saveCompetencyGroupData(data, (response) => {
                if (response?.data?.result) {
                  hidePerformingSaveToast();
                  showSaveToast();
                } else if (response?.error) {
                  hidePerformingSaveToast();
                  showErrorNotification();
                }
              });

              return;
            }
          }

          onClose(dynamicFormId.current);
        });
    }
  };

  const showSaveToast = () => {
    if (!panelContextProps?.batchSaveInProgress) {
      showSuccess(t('record-saved'));
    }
    notifySaveSucceeded(dynamicFormId.current);
    closeOnModalSaveRef.current = true;

    if (!competencyGroupId || closeOnModalSaveRef.current) {
      onClose(dynamicFormId.current);
    }
  };

  const onFormSubmit = (data: JsonDataItem) => {
    if (competencyGroupId) {
      saveCompetencyGroupData(forceAssert<CompetencyGroupItem>(data), (response) => {
        if (response?.data?.result) {
          dataGridRef.current?.api.data.getDataOverwritePageCache();
          hidePerformingSaveToast();
          showSaveToast();
        } else if (response?.error) {
          hidePerformingSaveToast();
          showErrorNotification();
        }
      });
    }
  };

  const onFieldChanged = debounce(() => notifyDirty(dynamicFormReference.current), 500);

  return (
    <>
      <div className="flex flex-shrink-0 flex-col">
        <div className="flex">
          <FormTitle title={`${t('competency-group-name')} - ${title}`} />

          <div className="flex min-w-min justify-end">
            <CloseActiveItemButton onClick={onCloseCompetencyGroupDetails} />
          </div>
        </div>
      </div>
      <div className="admin-form-default">
        {isLoading && !competencyGroupsSettings && <FormLoading />}
        {!isLoading && competencyGroupsSettings && (
          <DynamicForm
            ref={dynamicFormReference}
            dynamicFormId={dynamicFormId.current}
            data={forceAssert<JsonDataItem>(competencyGroup) ?? {}}
            settings={competencyGroupsSettings}
            readOnly={readOnly}
            buttons={formButtons}
            buttonsLocation={0}
            buttonsPosition={1}
            onSubmit={onFormSubmit}
            onFieldChanged={onFieldChanged}
          />
        )}
      </div>
      <ModalDialog ref={saveModalReference} header={t('save-unsaved-changes')} buttons={saveModalButtons}>
        <div>{t('save-message')}</div>
      </ModalDialog>
    </>
  );
}

export default forwardRef(CompetencyGroupsDetails);
