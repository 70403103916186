import React, { useId } from 'react';
import { debounce } from 'lodash';
import { DynamicFormRequiredType, TextArea } from '@myosh/odin-components';

import { useTranslation } from 'react-i18next';

interface QuestionnaireObservationsProps {
  readOnly?: boolean;
  value?: string;
  onChange: (value?: string) => void;
  error?: string;
  required?: DynamicFormRequiredType;
}

const QuestionnaireObservations = ({ readOnly, value, onChange, error, required }: QuestionnaireObservationsProps) => {
  const { t } = useTranslation();
  const observationsId = useId();

  const handleChange = debounce(onChange, 500);
  return (
    <TextArea
      label={t('observations')}
      rows={3}
      name={observationsId}
      readOnly={readOnly}
      required={required}
      value={value}
      onChange={handleChange}
      error={error}
    />
  );
};

export default QuestionnaireObservations;
