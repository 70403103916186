import React, { useEffect, useState } from 'react';
import { SchedulerRuleScheduler } from '../../../@types/scheduler-record';
import {
  DropDownResult,
  DynamicFormRequiredType,
  ErrorLabel,
  IconButton,
  Input,
  OdinIcon,
  OdinIconSize,
  OdinIconType,
} from '@myosh/odin-components';
import OdinDropDown from '../custom-drop-downs/odin-drop-down.component';
import { IntervalType, timeIntervals, dayValues, getAllTimezones, monthValues } from './scheduler-rules-utils';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { forceAssert } from '../../../common/common-functions';
import { USER_TIME_ZONE } from '../../../common/date-util';

export type IntervalFieldRuleProps = {
  intervalFieldRule?: SchedulerRuleScheduler;
  className?: string;
  errorObject?: Record<string, unknown>;
  onChange?: (value: IntervalFieldRuleState, type: string) => void;
  onFieldDelete?: (value: IntervalFieldRuleState) => void;
};

export interface IntervalFieldRuleState {
  interval?: IntervalType;
  month?: IntervalType;
  dayOfMonth?: number;
  dayOfWeek?: IntervalType;
  timeZone?: DropDownResult;
  hourOfDay?: number;
  id?: number | string;
  type?: string;
}

const timezones = getAllTimezones();
const IntervalFieldRule = ({
  intervalFieldRule,
  className,
  errorObject,
  onChange,
  onFieldDelete,
}: IntervalFieldRuleProps) => {
  const { t } = useTranslation();

  const [formState, setFormState] = useState<IntervalFieldRuleState>({});

  useEffect(() => {
    if (intervalFieldRule) {
      const { timeZone, interval, month, dayOfMonth, dayOfWeek, hourOfDay, id, type } = intervalFieldRule;

      const initialFormState = {
        interval: interval ? timeIntervals.find((item) => item.value === interval) : timeIntervals[2],
        month: monthValues.find((item) => item.value === month),
        dayOfMonth: dayOfMonth ?? 1,
        dayOfWeek: dayValues.find((item) => item.value === dayOfWeek),
        timeZone: timezones.find((item) => (timeZone ? item.value === timeZone : item.value === USER_TIME_ZONE)),
        hourOfDay: hourOfDay ?? 0,
        id,
        type,
      };
      setFormState(initialFormState);
      if (String(id).includes('new')) {
        onChange?.(initialFormState, 'periodic-schedule');
      }
    }
  }, []);

  const handleDropDownChange = (name: keyof typeof formState) => (result: DropDownResult) => {
    const updatedState = {
      ...formState,
      [name]: result && 'value' in result ? result.originalData : undefined,
    };
    onChange?.(updatedState, 'periodic-schedule');
    setFormState(updatedState);
  };

  const handleInputChange = (name: keyof typeof formState) => (value?: string | number) => {
    const updatedState = {
      ...formState,
      [name]: value,
    };
    onChange?.(updatedState, 'periodic-schedule');
    setFormState(updatedState);
  };

  const onDeleteHandler = () => {
    onFieldDelete?.(formState);
  };

  const { interval, dayOfWeek, month, dayOfMonth, timeZone, hourOfDay } = formState;

  const ruleCardStyles = cx('flex-col', className, {
    'odin-border-gray-4': !errorObject,
    'odin-border-error': errorObject,
  });

  return (
    <>
      <div className={ruleCardStyles}>
        <div className="flex flex-row mt-2 space-x-2">
          <div className="flex flex-row items-end h-12 mt-0.5">
            <OdinDropDown
              value={interval}
              data={timeIntervals}
              valueField="value"
              textField="text"
              readOnly={false}
              error={forceAssert<string>(Boolean(errorObject?.interval))}
              required={DynamicFormRequiredType.True}
              onChange={(value) => handleDropDownChange('interval')(value as DropDownResult)}
            />
          </div>

          {interval?.displayWeek === true && (
            <OdinDropDown
              label={t('day-of-week')}
              value={dayOfWeek}
              className="self-end"
              data={dayValues}
              valueField="value"
              textField="text"
              readOnly={false}
              error={forceAssert<string>(Boolean(errorObject?.dayOfWeek))}
              required={DynamicFormRequiredType.True}
              onChange={(value) => handleDropDownChange('dayOfWeek')(value as DropDownResult)}
            />
          )}
          {interval?.displayMonth === true && (
            <OdinDropDown
              value={month}
              data={monthValues}
              className="self-end"
              label={t('month')}
              valueField="value"
              textField="text"
              readOnly={false}
              error={forceAssert<string>(Boolean(errorObject?.month))}
              required={DynamicFormRequiredType.True}
              onChange={(value) => handleDropDownChange('month')(value as DropDownResult)}
            />
          )}
          {/** Day number */}
          {interval?.displayDayOfMonth === true && (
            <Input
              label={t('day-of-month')}
              name="dayMonth"
              type="number"
              value={String(dayOfMonth)}
              readOnly={false}
              validations={[
                { pattern: '^(?:[1-9]|[1-9][0-9]*)?$', errorLabel: `${t('min-value')}: 1` },
                { pattern: '^(?:[1-9]|1\\d|2[0-8])?$', errorLabel: `${t('max-value')}: 28` },
              ]}
              error={forceAssert<string>(Boolean(errorObject?.dayOfMonth))}
              required={DynamicFormRequiredType.True}
              onChange={handleInputChange('dayOfMonth')}
              className="w-10"
            />
          )}
          <IconButton className="self-end pl-2" onClick={onDeleteHandler}>
            <OdinIcon size={OdinIconSize.Medium} type={OdinIconType.Line} icon="DeleteBin" className="w-6" />
          </IconButton>
        </div>
        <div className="flex flex-row mt-3 mb-2 space-x-3">
          <Input
            label={t('hour')}
            name="hour"
            type="number"
            value={String(hourOfDay)}
            readOnly={false}
            validations={[
              { pattern: '^(?:[0-9]|[1-9][0-9]*)?$', errorLabel: `${t('min-value')}: 0` },
              { pattern: '^(?:[0-9]|1\\d|2[0-3])?$', errorLabel: `${t('max-value')}: 23` },
            ]}
            error={forceAssert<string>(Boolean(errorObject?.hourOfDay))}
            required={DynamicFormRequiredType.True}
            onChange={handleInputChange('hourOfDay')}
          />
          <OdinDropDown
            label={t('time-zone')}
            value={timeZone as DropDownResult}
            data={timezones}
            valueField="value"
            textField="text"
            className="self-end ml-2"
            readOnly={false}
            error={forceAssert<string>(Boolean(errorObject?.timeZone))}
            required={DynamicFormRequiredType.True}
            onChange={(value) => handleDropDownChange('timeZone')(value as DropDownResult)}
          />
        </div>
      </div>
      {errorObject && <ErrorLabel>{t('record-validation-message')}</ErrorLabel>}
    </>
  );
};

export default IntervalFieldRule;
