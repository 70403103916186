import { useEffect, useMemo, useState } from 'react';
import { useAuth, useUser } from '@myosh/myosh-login';
import { SchemaDropdownItem } from '../components/layout/components/schema-selector.component';
import { useGetCurrentUserQuery } from '../redux/services/user';
import { UserFields } from '../common/user-config';
import { CurrentUserResult, OdinUser } from '../@types/users';
import { useTranslation } from 'react-i18next';
import { hasAdminAccess, transformUserHierarchies } from '../common/user-functions';

export type ProfileNavItem = {
  name: string;
  action?: () => void;
  authorized?: boolean;
  icon?: string;
  className?: string;
};

type ProfileData = {
  user?: OdinUser;
  items: Array<ProfileNavItem>;
};

function userResponseToProfileUser(userResponse: CurrentUserResult) {
  return {
    id: userResponse.superUser ? userResponse.userId : userResponse.id,
    firstName: userResponse.superUser ? userResponse.userId : userResponse.firstName,
    lastName: userResponse.superUser ? '' : userResponse.lastName,
    hasAdminAccess: hasAdminAccess(userResponse),
    groups: userResponse.groups ?? [],
    adminAccess: userResponse.adminAccess ?? [],
    superUser: Boolean(userResponse.superUser),
    hierarchyAccessRestrictions: userResponse.userHierarchyAccessRestriction ?? [],
    isExternal: userResponse.externalUser,
    userHierarchies: transformUserHierarchies(userResponse.userHierarchy),
    fullName: userResponse.fullName,
    passwordChangeRequired: userResponse.passwordChangeRequired,
    ssoUser: userResponse.ssoUser,
  } as OdinUser;
}

const useProfileData = (schemas?: SchemaDropdownItem[]) => {
  const { logout } = useAuth();
  const { state: userState } = useUser();

  // this hook needs to use `isLoading`
  const { data, isLoading: isLoadingUser } = useGetCurrentUserQuery(
    {
      fields: [
        UserFields.adminAccess,
        UserFields.externalUser,
        UserFields.groups,
        UserFields.superuser,
        UserFields.userHierarchyAccess,
        UserFields.userHierarchy,
        UserFields.ssoUser,
      ],
    },
    { skip: userState === undefined, refetchOnMountOrArgChange: 300 /* 5 minutes */ }
  );

  const { t } = useTranslation();

  const [isAboutInfoOpen, setAboutInfoOpen] = useState(false);
  const [isSelectSchemaOpen, setSelectSchemaOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [profileData, setProfileData] = useState<ProfileData>({ items: [] });

  const userData = useMemo(() => {
    return data ? userResponseToProfileUser(data) : undefined;
  }, [data]);

  const handleLogout = () => logout();
  const openAboutInfo = () => setAboutInfoOpen(true);
  const openSelectSchema = () => setSelectSchemaOpen(true);
  const openSettingsModal = () => setIsSettingsModalOpen(true);

  const defaultItems: Array<ProfileNavItem> = [
    {
      name: t('about'),
      action: openAboutInfo,
      icon: 'Information',
      className: 'about-menu-item', // about-menu-item is used for Feature Tours
    },
    {
      name: t('select-company', 'Select Company'),
      action: openSelectSchema,
      authorized: schemas && schemas.length > 1 ? true : false,
      icon: 'MenuFold',
    },
    {
      name: t('settings'),
      action: openSettingsModal,
      authorized: !userData?.superUser,
      icon: 'Settings2',
      className: 'settings-menu-item', // settings-menu-item is used for Feature Tours
    },
  ];

  const logOutItem: ProfileNavItem = {
    name: t('log-out'),
    action: handleLogout,
    icon: 'LogoutBox',
    className: 'logout-menu-item', // logout-menu-item is used for Feature Tours
  };

  useEffect(() => {
    if (!isLoadingUser) {
      const superUser = userState?.user?.superuser === 'true';
      const user = userData ?? (superUser ? defaultSuperUser : undefined);

      setProfileData((prevState) => {
        // this code was creating duplicate items, so we need to remove the default items from the previous state
        const filteredPreviousItems = prevState.items
          .filter((item) => !defaultItems.some((defaultItem) => defaultItem.name === item.name))
          .filter((item) => logOutItem.name !== item.name);

        let _items = [...defaultItems, ...filteredPreviousItems];
        if (userState.isDefaultIdp) {
          // only default IdP users have the 'log out' menu option
          _items = [..._items, logOutItem];
        }

        return {
          ...prevState,
          user,
          items: _items,
        };
      });
    }
  }, [userData, isLoadingUser, schemas]);

  return {
    isAboutInfoOpen,
    setAboutInfoOpen,
    isSelectSchemaOpen,
    setSelectSchemaOpen,
    isSettingsModalOpen,
    setIsSettingsModalOpen,
    profileData,
    isLoadingUser,
  };
};

export default useProfileData;

const defaultSuperUser: OdinUser = {
  id: -1, // virtual user
  firstName: 'Super',
  lastName: 'User',
  hasAdminAccess: true,
  passwordChangeRequired: false,
};
