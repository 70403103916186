import {
  OdinDataSender,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  isDropDownNoSelection,
  DropDownResult,
  DropDownNoSelection,
  JsonData,
  DynamicFormField,
} from '@myosh/odin-components';
import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import { api } from '../redux/services/api';
import { hierarchyApi } from '../redux/services/hierarchy';
import { fieldApi } from '../redux/services/field';
import { DropDownValue } from '../@types/common';
import { CombinedFieldType } from '../@types/forms';
import { ExtendedDynamicFormSettings, ExtendedDynamicFormWorkflowStep, dataProps } from './common-functions';
import { extractFieldName } from './data-grid-column-utils';
import { gridCheckboxFilterOptions } from './dynamic-page-utils';
import { TrainingDropDownValue } from '../@types/training-field';
import {
  PersonOptionApiProps,
  LinkedPersonOptionApiProps,
  PersonOption,
  FieldOptionsApiProps,
  LinkedFieldOptionsApiProps,
} from '../@types/options';
import { findFieldRecursiveSearch } from '../components/record/record-component-util';
import { cloneDeep } from 'lodash';

interface FilterWorkflowStep {
  id: number | string;
  label: string;
}

interface CustomFilterProperties {
  selectedForm?: DropDownResult | DropDownNoSelection;
  workflowSteps?: Array<FilterWorkflowStep>;
}

export const getDataGridComboboxFilters = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  isAllForms: boolean,
  customFilterProperties?: CustomFilterProperties,
  formSettings?: ExtendedDynamicFormSettings,
  moduleId?: number
) => {
  switch (options.customProperties?.entityType) {
    case 'FIELD':
      isAllForms
        ? await getAllFormsFieldKeyFilters(subscriber, options, dispatch)
        : await getFieldKeyFilters(subscriber, options, dispatch, customFilterProperties);
      break;
    case 'KEYWORD':
      await getKeywordKeyFilter(subscriber, options, dispatch, customFilterProperties, moduleId);
      break;
    case 'HIERARCHY_TYPE':
      await getHierarchyKeyFilter(subscriber, options, dispatch);
      break;
    case 'RECORD_LINK':
    case 'REVERSE_RECORD_LINK':
      await getRecordLinkKeyFilter(subscriber, options, dispatch, customFilterProperties, formSettings);
      break;
    default:
      subscriber.sendData();
  }
};

const getFieldKeyFilters = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  customFilterProperties?: CustomFilterProperties
) => {
  if (
    getOptionsByFieldType(options.customProperties?.fieldType as CombinedFieldType) === 'FIELD_OPTIONS' &&
    (options.getAllData ||
      (options.page && (options.page === 1 || (options.page > 1 && options.customProperties?.next))))
  ) {
    const formId = isDropDownNoSelection(customFilterProperties?.selectedForm)
      ? customFilterProperties?.selectedForm.noSelectionValue
      : customFilterProperties?.selectedForm?.value;
    return getFieldOptions(subscriber, options, dispatch, formId as number);
  } else if (
    getOptionsByFieldType(options.customProperties?.fieldType as CombinedFieldType) === 'PERSON_OPTIONS' &&
    (options.getAllData ||
      (options.page && (options.page === 1 || (options.page > 1 && options.customProperties?.next))))
  ) {
    return getPersonfieldOptions(subscriber, options, dispatch);
  } else if (getOptionsByFieldType(options.customProperties?.fieldType as CombinedFieldType) === 'CHECKBOX_OPTIONS') {
    if (options.page === 1 || options.getAllData) {
      subscriber.sendData({ data: gridCheckboxFilterOptions, requestId: options.requestId });
    } else {
      subscriber.sendData();
    }
  } else if (
    getOptionsByFieldType(options.customProperties?.fieldType as CombinedFieldType) === 'COMPETENCIES_OPTIONS'
  ) {
    return getTrainingFieldOptions(subscriber, options, dispatch);
  } else {
    subscriber.sendData();
  }
};

const getAllFormsFieldKeyFilters = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch
) => {
  if (
    getOptionsByFieldType(options.customProperties?.fieldType as CombinedFieldType) === 'FIELD_OPTIONS' &&
    options.page
  ) {
    modifyOptionsArchivedFilter(options, true);
    const optionDispatch = dispatch(
      fieldApi.endpoints.allFormsFieldOptions.initiate({
        queryId: options.customProperties?.entityId as number,
        filters: options.fieldFilters,
        pageSize: options.getAllData ? -1 : options.pageSize || 50,
        page: options.page,
      })
    );
    const optionResult = await optionDispatch;
    if (optionResult.status === 'fulfilled' && optionResult.data.items && optionResult.data.items.length > 0) {
      subscriber.sendData({
        data: optionResult.data?.items as unknown as JsonData,
        requestId: options.requestId,
      });
    } else {
      subscriber.sendData();
    }

    optionDispatch.unsubscribe();
  } else if (
    getOptionsByFieldType(options.customProperties?.fieldType as CombinedFieldType) === 'PERSON_OPTIONS' &&
    options.page
  ) {
    if (options?.fieldFilters?.caption) {
      options.fieldFilters['name'] = options.fieldFilters.caption;
      delete options.fieldFilters.caption;
    }
    modifyOptionsArchivedFilter(options);
    const personDispatch = dispatch(
      fieldApi.endpoints.allFormsPersonFieldOptions.initiate({
        queryId: (options.customProperties?.entityId || 0) as number,
        pageSize: options.getAllData ? -1 : options.pageSize || 50,
        page: options.page,
        filters: options.fieldFilters,
      })
    );
    const personResult = await personDispatch;
    if (personResult.status === 'fulfilled' && personResult.data.items && personResult.data.items?.length > 0) {
      const personValues = personResult.data.items.map((el: DropDownValue) => {
        return dataProps.archivedField
          ? {
              [dataProps?.valueField]: el.id,
              [dataProps?.textField]: el.archived ? addArchviedSuffix(el.value) : el.value,
              [dataProps?.archivedField]: el.archived,
            }
          : {
              [dataProps?.valueField]: el.id,
              [dataProps?.textField]: el.archived ? addArchviedSuffix(el.value) : el.value,
            };
      });
      subscriber.sendData({
        data: personValues,
        requestId: options.requestId,
      });
    } else {
      subscriber.sendData();
    }
    personDispatch.unsubscribe();
  } else {
    subscriber.sendData();
  }
};

const getKeywordKeyFilter = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  customFilterProperties?: CustomFilterProperties,
  moduleId?: number
) => {
  if (
    options.customProperties?.field === 'status' &&
    customFilterProperties?.workflowSteps &&
    (options.page === 1 || options.getAllData)
  ) {
    const workflowValues = customFilterProperties.workflowSteps
      .filter((workflowStep) =>
        workflowStep.label
          .toLowerCase()
          .includes(options.fieldFilters ? options.fieldFilters['caption'].value.toString().toLowerCase() : '')
      )
      .map((workflowStep) => {
        return {
          [dataProps.valueField]: workflowStep.id,
          [dataProps.textField]: workflowStep.label,
        };
      });

    subscriber.sendData({ data: workflowValues, requestId: options.requestId });
  } else if (options.customProperties?.field === 'accountable' && (options.getAllData || options.page)) {
    const formId = isDropDownNoSelection(customFilterProperties?.selectedForm)
      ? customFilterProperties?.selectedForm.noSelectionValue
      : customFilterProperties?.selectedForm?.value;

    return getKeywordAccountableOptions(subscriber, options, dispatch, formId as number);
  } else if (
    options.customProperties?.field === 'moduleForm' &&
    moduleId &&
    (options.getAllData || options.page === 1)
  ) {
    const modulesDispatch = dispatch(api.endpoints.modules.initiate({ includeFormList: true }));

    const modulesResult = await modulesDispatch;

    if (modulesResult.status === 'fulfilled' && modulesResult.data.length > 0) {
      const module = modulesResult.data.find((module) => module.id === moduleId);
      if (module) {
        let forms = module.forms ?? [];
        const filter = (options.fieldFilters?.['caption']?.value as string)?.toLowerCase() ?? undefined;
        if (filter) {
          forms = forms.filter((forms) => forms.caption.toLowerCase().includes(filter));
        }
        const _forms = forms.map((form) => {
          return {
            [dataProps.valueField]: form.id,
            [dataProps.textField]: form.caption,
          };
        });

        subscriber.sendData({ data: _forms, requestId: options.requestId });
      } else {
        subscriber.sendData();
      }
    } else {
      subscriber.sendData();
    }

    modulesDispatch.unsubscribe();
  } else if (options.customProperties?.field === 'author' && (options.page === 1 || options.getAllData)) {
    const formId = isDropDownNoSelection(customFilterProperties?.selectedForm)
      ? customFilterProperties?.selectedForm.noSelectionValue
      : customFilterProperties?.selectedForm?.value;
    return getAuthorOptions(subscriber, options, dispatch, formId as number);
  } else {
    subscriber.sendData();
  }
};

export const getHierarchyKeyFilter = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  typeId?: number // if `typeId` specified, it's used for fetching the hierarchies, otherwise the `entityId` is used
) => {
  if (options && options.customProperties) {
    modifyOptionsArchivedFilter(options); // include the 'archived' filter

    const filters = cloneDeep(options.fieldFilters ?? {});
    filters!['typeId'] = { value: typeId ?? Number(options.customProperties.entityId), comparison: '=' };

    const hierarchyDispatch = dispatch(
      hierarchyApi.endpoints.hierarchyValues.initiate({
        page: options?.page ?? 1,
        pageSize: options.getAllData ? -1 : (options?.pageSize ?? 50),
        sort: 'caption',
        filters,
      })
    );
    const hierarchyResponse = await hierarchyDispatch;
    if (hierarchyResponse.data && hierarchyResponse.status === 'fulfilled' && hierarchyResponse.data?.items) {
      const hierarchyItems = hierarchyResponse.data?.items.map((item) => {
        return {
          ...item,
          [dataProps.textField]: item.archived ? addArchviedSuffix(item.caption) : item.caption,
        };
      });

      subscriber.sendData({ data: hierarchyItems, requestId: options.requestId });

      if (hierarchyResponse.data.page === hierarchyResponse.data.availablePages) {
        subscriber.sendData();
      }
    } else {
      subscriber.sendData();
    }

    hierarchyDispatch.unsubscribe();
  } else {
    subscriber.sendData();
  }
};

const getRecordLinkKeyFilter = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  customFilterProperties?: CustomFilterProperties,
  formSettings?: ExtendedDynamicFormSettings
) => {
  if (options?.customProperties?.linkedEntityType === 'HIERARCHY_TYPE') {
    return getHierarchyKeyFilter(subscriber, options, dispatch, Number(options.customProperties.linkedFieldId));
  } else if (options?.customProperties?.linkedEntityType === 'KEYWORD' && formSettings) {
    const keywordField = extractFieldName(options.customProperties?.field as string);

    if (
      options.customProperties &&
      keywordField.toLowerCase() === 'status' &&
      (options.page === 1 || options.getAllData)
    ) {
      let statusOptionsDispatch,
        isAllModules = false;
      const targetFormId = getTargetFormId(formSettings.fields, options?.customProperties?.entityId);
      if (targetFormId) {
        statusOptionsDispatch = dispatch(api.endpoints.getWorkflowSteps.initiate(targetFormId));
      } else if (!targetFormId && options?.customProperties?.entityId) {
        // If there is no targetFormId, then the keyword field is most likely configured with 'All Modules',
        //  so we need to use the fieldId to retrieve the options
        isAllModules = true;
        const fieldId = options.customProperties.entityId as number;
        statusOptionsDispatch = dispatch(fieldApi.endpoints.fieldWorkflowSteps.initiate(fieldId));
      }

      if (statusOptionsDispatch) {
        const statusOptions = await statusOptionsDispatch;

        if (statusOptions.status === 'fulfilled' && statusOptions.data.length > 0) {
          const statusValues = statusOptions.data.map((status) => ({
            [dataProps.valueField]: isAllModules === true ? status : (status as ExtendedDynamicFormWorkflowStep).id,
            [dataProps.textField]: isAllModules === true ? status : (status as ExtendedDynamicFormWorkflowStep).label,
          }));

          subscriber.sendData({ data: statusValues, requestId: options.requestId });
        } else {
          subscriber.sendData();
        }

        statusOptionsDispatch.unsubscribe();
      } else {
        subscriber.sendData();
      }
    } else if (options.customProperties && keywordField.toLowerCase() === 'accountable') {
      const targetFormId = getTargetFormId(formSettings.fields, options?.customProperties?.entityId);
      if (targetFormId) {
        return getKeywordAccountableOptions(subscriber, options, dispatch, targetFormId);
      } else if (!targetFormId && options?.customProperties?.entityId) {
        // If there is no targetFormId, then the keyword field is most likely configured with 'All Modules',
        //  so we need to use the fieldId to retrieve the options
        const fieldId = options.customProperties.entityId as number;
        return getKeywordAccountableOptions(subscriber, options, dispatch, fieldId, true);
      } else {
        subscriber.sendData();
      }
    } else if (options.customProperties && keywordField.toLowerCase() === 'author') {
      return getAuthorOptions(subscriber, options, dispatch, undefined, Number(options.customProperties.entityId));
    } else {
      subscriber.sendData();
    }
  } else if (
    options?.customProperties?.linkedEntityType === 'FIELD' &&
    getOptionsByFieldType(options.customProperties?.linkedFieldType as CombinedFieldType) === 'PERSON_OPTIONS' &&
    options.page &&
    (options.page === 1 || (options.page > 1 && options.customProperties?.next))
  ) {
    return getPersonfieldOptions(subscriber, options, dispatch);
  } else if (
    options?.customProperties?.linkedEntityType === 'FIELD' &&
    getOptionsByFieldType(options?.customProperties?.linkedFieldType as CombinedFieldType) === 'FIELD_OPTIONS' &&
    options.page &&
    (options.page === 1 || options.getAllData === true || (options.page > 1 && options.customProperties?.next)) &&
    formSettings
  ) {
    // Should use the formId of the currently opened module/form
    const formId = isDropDownNoSelection(customFilterProperties?.selectedForm)
      ? customFilterProperties?.selectedForm.noSelectionValue
      : customFilterProperties?.selectedForm?.value;

    return getFieldOptions(subscriber, options, dispatch, formId as number);
  } else if (
    options?.customProperties?.linkedEntityType === 'FIELD' &&
    getOptionsByFieldType(options.customProperties?.linkedFieldType as CombinedFieldType) === 'CHECKBOX_OPTIONS'
  ) {
    if (options.page === 1 || options.getAllData) {
      subscriber.sendData({ data: gridCheckboxFilterOptions, requestId: options.requestId });
    } else {
      subscriber.sendData();
    }
  } else if (
    options?.customProperties?.linkedEntityType === 'FIELD' &&
    getOptionsByFieldType(options?.customProperties?.linkedFieldType as CombinedFieldType) === 'COMPETENCIES_OPTIONS' &&
    options.page
  ) {
    return getTrainingFieldOptions(subscriber, options, dispatch);
  } else {
    subscriber.sendData();
  }
};

export const getOptionsByFieldType = (fieldType: CombinedFieldType) => {
  switch (fieldType) {
    case 'PERSONFIELD':
    case 'MULTISELECTPERSONFIELD':
    case 'READERS':
    case 'USER_RECORDLINK':
    case 'TWINCOLUMNSELECTPERSONFIELD':
      return 'PERSON_OPTIONS';
    case 'MULTISELECTCHECKBOX':
    case 'OPTIONGROUP':
    case 'COMBOBOX':
    case 'MULTISELECTFIELD':
    case 'TWINCOLUMNSELECT':
      return 'FIELD_OPTIONS';
    case 'TRAINING':
      return 'COMPETENCIES_OPTIONS';
    case 'RECORDLINK':
      return 'RECORD_LINK_OPTIONS';
    case 'CHECKBOX':
      return 'CHECKBOX_OPTIONS';
    default:
      return 'FIELD_OPTIONS';
  }
};

const getFieldOptions = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  formId: number
) => {
  modifyOptionsArchivedFilter(options, true); // include the 'archived' filter with exceptional formatting

  const queryConfig: FieldOptionsApiProps | LinkedFieldOptionsApiProps = {
    queryId: options.customProperties?.entityId as number,
    limit: options.getAllData ? -1 : options.pageSize || 50,
    filters: options.fieldFilters,
    next: options.customProperties?.next as string,
    linkedFieldId: options?.customProperties?.linkedFieldId
      ? Number(options.customProperties.linkedFieldId)
      : undefined,
    formId,
    formRecordsOnly: hasLookupType(options),
  };

  const optionDispatch = dispatch(fieldApi.endpoints.fieldOptions.initiate(queryConfig));

  const optionResult = await optionDispatch;
  if (optionResult.status === 'fulfilled' && optionResult.data.items && optionResult.data.items.length > 0) {
    const _options = optionResult.data.items.map((item) => {
      return {
        ...item,
        [dataProps.textField]: item.archived === true ? addArchviedSuffix(item.caption) : item.caption,
      };
    });

    subscriber.sendData({
      data: _options,
      requestId: options.requestId,
      customProperties: { next: optionResult.data?.next },
    });
  } else {
    subscriber.sendData();
  }

  optionDispatch.unsubscribe();
};

const getTrainingFieldOptions = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch
) => {
  if (options?.fieldFilters?.caption) {
    options.fieldFilters['name'] = options.fieldFilters.caption;
    delete options.fieldFilters.caption;
  }

  modifyOptionsArchivedFilter(options); // include the 'archived' filter

  const dispatchResult = dispatch(
    fieldApi.endpoints.trainingFieldOptions.initiate({
      page: options.page || 1,
      pageSize: options.getAllData ? -1 : options.pageSize || 50,
      filters: options.fieldFilters,
    })
  );

  const result = await dispatchResult;

  if ('fulfilled' === result.status && result.data && result.data.length > 0) {
    const trainingFieldOptions = result.data?.map((item: TrainingDropDownValue) => {
      return dataProps.archivedField
        ? {
            ...item,
            [dataProps.valueField]: item.id,
            [dataProps.textField]: item.archived ? addArchviedSuffix(item.name) : item.name,
            [dataProps.archivedField]: item.archived,
          }
        : {
            ...item,
            [dataProps.valueField]: item.id,
            [dataProps.textField]: item.archived ? addArchviedSuffix(item.name) : item.name,
          };
    });

    subscriber.sendData({ data: trainingFieldOptions, requestId: options.requestId });
  } else {
    subscriber.sendData();
  }

  dispatchResult.unsubscribe();
};

const getPersonfieldOptions = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch
) => {
  if (options && options.customProperties) {
    if (options?.fieldFilters?.caption) {
      options.fieldFilters['value'] = options.fieldFilters.caption;
      delete options.fieldFilters.caption;
    }

    modifyOptionsArchivedFilter(options, true); // include the 'archived' filter

    const queryConfig: PersonOptionApiProps | LinkedPersonOptionApiProps = {
      queryId: (options.customProperties?.entityId || 0) as number,
      limit: options.getAllData ? -1 : options.pageSize || 50,
      filters: options.fieldFilters,
      next: options.customProperties?.next as string,
      formRecordsOnly: true,
    };

    if ('linkedFieldId' in options.customProperties) {
      (queryConfig as LinkedPersonOptionApiProps).linkedFieldId = Number(options.customProperties.linkedFieldId);
    }

    const personDispatch = dispatch(fieldApi.endpoints.personFieldOptions.initiate(queryConfig));

    const personResult = await personDispatch;

    if (personResult.status === 'fulfilled' && personResult.data.items && personResult.data.items?.length > 0) {
      const personValues = personResult.data.items.map((el: PersonOption) => {
        return dataProps?.archivedField
          ? {
              [dataProps?.valueField]: el.id,
              [dataProps?.textField]: el.archived ? addArchviedSuffix(el.value) : el.value,
              [dataProps?.archivedField]: el.archived,
            }
          : {
              [dataProps?.valueField]: el.id,
              [dataProps?.textField]: el.archived ? addArchviedSuffix(el.value) : el.value,
            };
      });
      subscriber.sendData({
        data: personValues,
        requestId: options.requestId,
        customProperties: { next: personResult.data?.next },
      });
    } else {
      subscriber.sendData();
    }

    personDispatch.unsubscribe();
  } else {
    subscriber.sendData();
  }
};

const getKeywordAccountableOptions = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  queryId: number, // formId or fieldId
  isRecordLink?: boolean // when true, data is fetched via a different endpoint
) => {
  if (options?.fieldFilters?.caption) {
    options.fieldFilters['name'] = options.fieldFilters.caption;
    delete options.fieldFilters.caption;
  }

  let personDispatch;

  if (isRecordLink === true) {
    personDispatch = dispatch(
      fieldApi.endpoints.fieldAccountablePersonOptions.initiate({
        queryId,
        pageSize: options.pageSize ?? 50,
        page: options.page ?? 1,
        filters: options.fieldFilters,
      })
    );
  } else {
    personDispatch = dispatch(
      fieldApi.endpoints.accountablePersonFieldOptions.initiate({
        queryId,
        pageSize: options.getAllData ? -1 : options.pageSize || 50,
        page: options.page || -1,
        filters: options.fieldFilters,
      })
    );
  }

  if (personDispatch) {
    const personResult = await personDispatch;

    if (personResult.status === 'fulfilled' && personResult.data.items && personResult.data.items.length > 0) {
      const personValues = personResult.data.items.map((person) => {
        return {
          [dataProps.valueField]: person.id,
          [dataProps.textField]: person.value,
        };
      });
      subscriber.sendData({ data: personValues, requestId: options.requestId });
    } else {
      subscriber.sendData();
    }

    personDispatch.unsubscribe();
  } else {
    subscriber.sendData();
  }
};

const getAuthorOptions = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  formId?: number,
  linkedFieldId?: number
) => {
  if (options?.fieldFilters?.caption) {
    options.fieldFilters['name'] = options.fieldFilters.caption;
    delete options.fieldFilters.caption;
  }

  modifyOptionsArchivedFilter(options); // include the 'archived' filter

  const dispatchResult = dispatch(
    fieldApi.endpoints.authorOptions.initiate({
      page: options.page || 1,
      pageSize: options.getAllData ? -1 : options.pageSize || 50,
      filters: options.fieldFilters,
      formId,
      linkedFieldId,
    })
  );

  const result = await dispatchResult;

  if ('fulfilled' === result.status && result.data && result.data.items && result.data.items.length > 0) {
    const authorFieldOptions = result.data.items.map((item) => {
      return dataProps?.archivedField
        ? {
            [dataProps?.valueField]: item.id,
            [dataProps?.textField]: item.archived ? addArchviedSuffix(item.value) : item.value,
            [dataProps?.archivedField]: item.archived,
          }
        : {
            [dataProps?.valueField]: item.id,
            [dataProps?.textField]: item.archived ? addArchviedSuffix(item.value) : item.value,
          };
    });

    subscriber.sendData({ data: authorFieldOptions, requestId: options.requestId });
  } else {
    subscriber.sendData();
  }

  dispatchResult.unsubscribe();
};

const getTargetFormId = (fields: Array<DynamicFormField>, entityId?: unknown) => {
  const formField = findFieldRecursiveSearch(fields ?? [], (field) => field.id === entityId);
  if (formField && formField.dynamicProperties) {
    return formField.dynamicProperties.targetModuleFormId as number;
  }
};

// Modifies the options to set/remove the 'archived' filter based on the underlying value
const modifyOptionsArchivedFilter = (options: OdinDataRetrievalOptions, exceptionalFormatting?: boolean) => {
  // For option columns the archived filter foramt is different than other columns: https://myosh.atlassian.net/browse/MYOSH-6554?focusedCommentId=50531
  if (
    exceptionalFormatting === true &&
    options.fieldFilters &&
    options.fieldFilters.archived &&
    options.fieldFilters.archived.value === true
  ) {
    // When checked then filter needs to be set to 'all' to include both 'archived' and 'non-archived' options
    options.fieldFilters.archived.value = 'all';
  } else if (options.fieldFilters && options.fieldFilters.archived && options.fieldFilters.archived.value === true) {
    // Archived action behavior: https://myosh.atlassian.net/browse/MYOSH-4362?focusedCommentId=48362
    // When checked then both non-archived and archived options are shown (default API behaviour)
    delete options.fieldFilters.archived;
  }
};

// Appends '[Archived]' to the option display value
const addArchviedSuffix = (value: string) => `${value} [Archived]`;

// Check if lookup type is configured for the column field
const hasLookupType = (options?: OdinDataRetrievalOptions) => {
  return Boolean(
    options &&
      options.customProperties &&
      'lookupType' in options.customProperties &&
      options.customProperties.lookupType !== 'NONE'
  );
};
