import {
  DropDownResult,
  DataGridRef,
  OdinDataRetrievalOptions,
  IconButton,
  OdinIcon,
  OdinIconSize,
  OdinIconType,
  Tooltip,
  DomElementAlignment,
  DomTargetPosition,
} from '@myosh/odin-components';
import React, { useState, useRef, useEffect } from 'react';
import { FormPermissionsResults } from '../../../@types/form-permissions';
import ImportRecordsModal from '../record-import/import-records-modal';
import {
  getVisibleColumnsForFileExport,
  getVisibleColumnsForFileExportAllForms,
} from '../../../common/dynamic-page-utils';
import { getDataGridReference } from '../../../services/data-grid.service';
import { Subject, takeUntil } from 'rxjs';
import { useAppDispatch } from '../../../redux/hooks';
import ManageViewsModal from '../manage-views-modal.component';
import { Module } from '../../../@types/modules';
import SaveViewModal from '../save-view-modal.component';
import ExportRecordListTemplatesModal from '../export-record-list-templates';
import { submitExportJob } from '../../../redux/slices/export-slice';
import classNames from 'classnames';
import { useGetFormAddOnsQuery } from '../../../redux/services/view';
import { useTranslation } from 'react-i18next';
import useAddonQuery from '../../../hooks/use-addon-query';
import {
  useGetPinnedRecordListMenuItemsQuery,
  usePinRecordListMenuItemMutation,
  useUnpinRecordListMenuItemMutation,
} from '../../../redux/services/record-list-menu';
import {
  convertToFieldFilters,
  convertToSortedFields,
  DataGridSettingsMenuItem,
  RecordListMenuItemType,
} from './data-grid-menu-utils';
import { RecordListMenuItem, RecordListMenuItemValue } from '../../../@types/record-list-menu';
import PinnableMenu from './pinnable-menu.component';
import { sortBy } from 'lodash';
import FormSettingsFeatureTour from '../../layout/components/feature-tour-guides/form-settings-tour';
import { useGetFeatureToursQuery } from '../../../redux/services/api';
import ImportAttachmentsModal from '../record-import/import-attachments-modal';
import { useLocation } from 'react-router-dom';

interface DataGridSettingsMenuProps {
  formPermissions?: FormPermissionsResults;
  isAllForms: boolean;
  selectedForm: DropDownResult;
  module: Module;
  isMobile?: boolean;
  viewHasChanged?: boolean;
  getRecordsIds?: () => string;
}

export default function DataGridMenu({
  formPermissions,
  selectedForm,
  isAllForms,
  viewHasChanged,
  module,
  isMobile,
  getRecordsIds,
}: DataGridSettingsMenuProps) {
  const [showImportRecordsModal, setShowImportRecordsModal] = useState(false);
  const [showManageViewsModal, setShowManageViewsModal] = useState<boolean>(false);
  const [showSaveViewModal, setShowSaveViewModal] = useState(false);
  const [exportRecordsTemplatesModal, setExportRecordsTemplatesModal] = useState(false);
  const [isMoreOptionsActive, setIsMoreOptionsActive] = useState(false);
  const [isImportAttachmentsModalVisible, setIsImportAttachmentsModalVisible] = useState(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const queryParams = useAddonQuery();

  const { pathname } = useLocation();
  const isRecordOpen = pathname.includes('/records');

  const gridRef = useRef<DataGridRef>();
  const destroySubject = useRef(new Subject<void>());
  const moreOptionsRef = useRef<HTMLDivElement>(null);

  const getColumns = () => gridRef.current?.api.data.columns ?? [];
  const getFilters = () => gridRef.current?.api.filters.getFilters() ?? [];
  const getSortedFields = () => gridRef.current?.api.sortedFields.getSortedFields() ?? [];
  const convertedFilters = convertToFieldFilters(getFilters());
  const convertedSorts = convertToSortedFields(getSortedFields());

  const onCloseImportRecordsModal = () => setShowImportRecordsModal(false);
  const onCloseExportRecordsTemplatesModal = () => setExportRecordsTemplatesModal(false);
  const onCloseManageViewsModal = () => setShowManageViewsModal(false);
  const onCloseSaveViewModal = () => setShowSaveViewModal(false);

  const resetView = () => {
    gridRef?.current?.api.data.resetView();
  };

  const gridOptions: OdinDataRetrievalOptions = {
    fieldFilters: convertedFilters,
    sortedFields: convertedSorts,
  };

  const { data: pinnedMenuItems } = useGetPinnedRecordListMenuItemsQuery();
  const [pinMenuItem] = usePinRecordListMenuItemMutation();
  const [unpinMenuItem] = useUnpinRecordListMenuItemMutation();

  const { data: addonsData, isFetching: areAddonsFetching } = useGetFormAddOnsQuery(selectedForm?.value as number, {
    skip: selectedForm === undefined || selectedForm.value === undefined || isAllForms === true,
  });

  const { data: featureTourData } = useGetFeatureToursQuery();

  useEffect(() => {
    destroySubject.current = new Subject<void>();
    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((dataGridRef) => {
        if (dataGridRef) {
          gridRef.current = dataGridRef;
        }
      });

    return () => {
      destroySubject.current?.next();
      destroySubject.current?.complete();
    };
  }, []);

  useEffect(() => {
    if (isMoreOptionsActive) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMoreOptionsActive]);

  const doExport = (event: string, allForms: boolean) => {
    const gridFilterAndSort: OdinDataRetrievalOptions = {
      fieldFilters: convertToFieldFilters(getFilters()),
      sortedFields: convertToSortedFields(getSortedFields()),
    };

    const commonPayload = {
      format: event,
      formName: selectedForm?.text as string,
      columns: allForms
        ? getVisibleColumnsForFileExportAllForms(getColumns())
        : getVisibleColumnsForFileExport(getColumns()),
      gridOptions: gridFilterAndSort,
    };

    const specificPayload = allForms
      ? { moduleId: module.id, formName: selectedForm?.text as string }
      : { formId: selectedForm?.value as string, moduleId: undefined };

    dispatch(submitExportJob({ ...commonPayload, ...specificPayload }));
  };

  const onPinMenuItemClick = (key: RecordListMenuItemValue, isPinned: boolean, addonId?: number) => {
    const updatePinStatus = (item: RecordListMenuItem) => (isPinned ? unpinMenuItem(item) : pinMenuItem(item));

    updatePinStatus({
      value: key,
      addonId,
    });
  };

  const isMenuItemPinned = (key: RecordListMenuItemType) => {
    if (!pinnedMenuItems) {
      return false;
    }

    return pinnedMenuItems.some((item) => item.value === key);
  };

  const handleMenuToggle = () => {
    setIsMoreOptionsActive((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      moreOptionsRef.current &&
      !moreOptionsRef.current.contains(event.target as Node) &&
      featureTourData?.hasFormSettingsTourRan
    ) {
      setIsMoreOptionsActive(false);
    }
  };

  const menuItems: Array<DataGridSettingsMenuItem> = [
    {
      label: t('restore-current-view'),
      key: RecordListMenuItemType.Restore,
      icon: 'ArrowGoBack',
      onClick: resetView,
      disabled: !viewHasChanged,
      isPinned: isMenuItemPinned(RecordListMenuItemType.Restore),
      isPinIconHidden: isMobile,
      className: 'restore-view', // restore-view is used for Feature Tours
    },
    {
      label: t('save-current-view'),
      key: RecordListMenuItemType.SaveView,
      icon: 'Save',
      onClick: () => setShowSaveViewModal(true),
      isPinned: isMenuItemPinned(RecordListMenuItemType.SaveView),
      isPinIconHidden: isMobile,
      className: 'save-view', // save-view is used for Feature Tours
    },
    {
      label: t('manage-views'),
      key: RecordListMenuItemType.ManageView,
      icon: 'ListSettings',
      onClick: () => setShowManageViewsModal(true),
      visible: !isMobile,
      isPinned: isMenuItemPinned(RecordListMenuItemType.ManageView),
      isPinIconHidden: isMobile,
      className: 'manage-views', // manage-views is used for Feature Tours
    },
    {
      key: 'separator1',
      isSeparator: true,
    },
    {
      key: RecordListMenuItemType.ImportAttachments,
      label: t('import-attachments'),
      icon: 'Upload',
      onClick: () => setIsImportAttachmentsModalVisible(true),
      visible: !!formPermissions?.canImportRecord && !isAllForms,
      isPinIconHidden: true,
    },
    {
      key: RecordListMenuItemType.Import,
      label: t('import-records'),
      icon: 'Upload',
      onClick: () => setShowImportRecordsModal(true),
      visible: !!formPermissions?.canImportRecord && !isAllForms && !isMobile,
      isPinned: isMenuItemPinned(RecordListMenuItemType.Import),
      isPinIconHidden: isMobile,
      className: 'import-records', // import-records is used for Feature Tours
    },
    {
      key: RecordListMenuItemType.Export,
      label: t('export-records'),
      icon: 'Download',
      isPinned: isMenuItemPinned(RecordListMenuItemType.Export),
      isPinIconHidden: isMobile,
      visible: !!formPermissions?.canExportRecord,
      className: 'export-records', // export-records is used for Feature Tours
      subMenuItems: [
        {
          label: 'CSV',
          key: 'CSV',
          onClick: () => doExport('CSV', isAllForms),
          visible: !isAllForms,
          isPinIconHidden: true,
        },
        {
          label: 'Excel',
          key: 'EXCEL',
          onClick: () => doExport('EXCEL', isAllForms),
          visible: !!formPermissions?.canExportRecord || isAllForms,
          isPinIconHidden: true,
        },
        {
          label: 'PDF',
          key: 'PDF',
          onClick: () => doExport('PDF', isAllForms),
          visible: !isAllForms,
          isPinIconHidden: true,
        },
        {
          label: t('template'),
          key: 'TEMPLATE',
          onClick: () => setExportRecordsTemplatesModal(true),
          visible: !isAllForms,
          isPinIconHidden: true,
        },
      ],
    },
    {
      key: 'separator2',
      isSeparator: true,
      visible:
        !!addonsData &&
        addonsData.length > 0 &&
        !isAllForms &&
        (!!formPermissions?.canExportRecord || !!formPermissions?.canImportRecord),
    },
  ];

  const addOnItems = () => {
    if (isAllForms) {
      return [];
    }

    const isAddonPinned = (id: number) => {
      if (!pinnedMenuItems) {
        return false;
      }

      return pinnedMenuItems.some((item) => item.value === RecordListMenuItemType.Addon && item.addonId === id);
    };

    return (
      addonsData
        ?.filter(
          (addonItem) =>
            addonItem.success === true && addonItem.result.displayType === 'RECORD_LIST' && addonItem.result
        )
        .map((addonItem) => {
          const addOn = addonItem.result;
          //TODO recordsIds are yet to be implemented
          const records = getRecordsIds?.();
          const url = addOn.url + queryParams + (records ? `&records=${records}` : '');

          return {
            key: RecordListMenuItemType.Addon,
            addonId: addOn.id,
            label: addOn.caption,
            icon: addOn.icon ?? 'ExternalLink',
            url: url,
            target: addOn.target,
            isPinned: isAddonPinned(addOn.id),
            isPinIconHidden: isMobile,
          } as DataGridSettingsMenuItem;
        }) ?? []
    );
  };

  const combinedMenuItems = [...menuItems, ...sortBy(addOnItems(), (item) => item.label)];
  const pinnedMenuItemOptions = (combinedMenuItems ?? []).filter(
    (item) =>
      item.isPinned &&
      (formPermissions?.canImportRecord || item.key !== RecordListMenuItemType.Import) &&
      (formPermissions?.canExportRecord || item.key !== RecordListMenuItemType.Export)
  );
  const menuStyles = classNames('py-1 text-sm rounded-md shadow-md bg-mono-1 absolute right-0 z-50', {
    hidden: !isMoreOptionsActive,
    'pin-feature': isMoreOptionsActive, // pin-feature is used for Feature Tours
  });

  return (
    <>
      {pinnedMenuItemOptions.length > 0 && !isMobile && (
        <div className="flex">
          {pinnedMenuItemOptions.map((pinnedMenuItem, index) => {
            const pinnedMenuItemButtonStyle = classNames('mx-2 h-9 grid place-content-center cursor-pointer', {
              'text-gray-3 pointer-events-none !cursor-default': pinnedMenuItem.disabled,
            });

            return (
              <Tooltip
                description={pinnedMenuItem.label}
                key={`${pinnedMenuItem.key}_${pinnedMenuItem.label}_${index}`}
                wrapperClassName="group/pinnedmenu-button relative"
                tooltipAlignment={DomElementAlignment.BottomLeft}
                tooltipTargetPosition={DomTargetPosition.TopLeft}
              >
                <>
                  <a
                    className={pinnedMenuItemButtonStyle}
                    href={pinnedMenuItem.url}
                    target={pinnedMenuItem.target}
                    onClick={pinnedMenuItem.onClick}
                  >
                    <OdinIcon
                      size={OdinIconSize.Medium}
                      icon={pinnedMenuItem.icon ?? 'ExternalLink'}
                      type={OdinIconType.Line}
                      className="w-6"
                    />
                  </a>
                  {pinnedMenuItem.subMenuItems && (
                    <div className={`${menuStyles} group-hover/pinnedmenu-button:block`}>
                      <PinnableMenu items={pinnedMenuItem.subMenuItems} />
                    </div>
                  )}
                </>
              </Tooltip>
            );
          })}
        </div>
      )}
      <div ref={moreOptionsRef} className="relative" onClick={handleMenuToggle}>
        {/*form-settings-menu is used for Feature Tours*/}
        <IconButton classNames="mr-2 ml-2 h-9 grid place-content-center max-sm:mr-0 form-settings-menu">
          <OdinIcon size={OdinIconSize.Medium} icon="More" className="w-6" />
        </IconButton>
        <div className={menuStyles}>
          <PinnableMenu items={combinedMenuItems} onPinMenuItemClick={onPinMenuItemClick} isModalMenu={true} />
        </div>
      </div>
      <ImportRecordsModal
        selectedForm={selectedForm}
        columns={getVisibleColumnsForFileExport(gridRef?.current?.api.data.columns)}
        gridOptions={gridOptions}
        visible={showImportRecordsModal}
        onClose={onCloseImportRecordsModal}
      />
      <ExportRecordListTemplatesModal
        selectedForm={selectedForm}
        columns={getVisibleColumnsForFileExport(gridRef?.current?.api.data.columns)}
        gridOptions={gridOptions}
        visible={exportRecordsTemplatesModal}
        onClose={onCloseExportRecordsTemplatesModal}
      />
      <SaveViewModal
        getColumns={getColumns}
        moduleId={module.id}
        selectedForm={selectedForm}
        visible={showSaveViewModal}
        onCloseModal={onCloseSaveViewModal}
        getFilters={getFilters}
        getSortedFields={getSortedFields}
        isAllForms={isAllForms}
      />
      <ManageViewsModal
        visible={showManageViewsModal}
        onClose={onCloseManageViewsModal}
        moduleId={module.id}
        selectedForm={selectedForm}
      />
      <ImportAttachmentsModal
        visible={isImportAttachmentsModalVisible}
        onClose={() => setIsImportAttachmentsModalVisible(false)}
      />
      {featureTourData &&
        !isRecordOpen &&
        !featureTourData.hasFormSettingsTourRan &&
        featureTourData.hasSettingsMenuTourRan &&
        !areAddonsFetching && (
          <FormSettingsFeatureTour
            isMenuOpen={isMoreOptionsActive}
            featureTourData={featureTourData}
            setIsMoreOptionsActive={setIsMoreOptionsActive}
          />
        )}
    </>
  );
}
