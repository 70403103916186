import React, { useState } from 'react';
import { OdinIcon } from '@myosh/odin-components';
import ImageThumbnail from './image-thumbnail.component';
import { ThumbnailActions } from './thumbnail-actions.component';
import cx from 'classnames';
import { formatDisplayDate, formatDisplayTime } from '../../../../common/date-util';

interface AttachmentThumbnailProps {
  id: string;
  name: string;
  url: string;
  readOnly: boolean;
  attachmentId?: string;
  person: string;
  lastModified: string;
  onDelete?: (id: string) => void;
}

export default function AttachmentThumbnail({
  id,
  name,
  url,
  readOnly,
  attachmentId,
  person,
  lastModified,
  onDelete,
}: AttachmentThumbnailProps) {
  const [hidden, setHidden] = useState(false);

  const imageFileExtension = name?.match(/(jpg|jpeg|png|gif)$/i);
  const isImage = Boolean(imageFileExtension);

  const thumbnailStyles = cx(
    'group bg-gray-4 relative flex cursor-default items-center justify-center border border-solid border-primary-2 text-primary-2',
    { hidden: hidden }
  );

  const handleDelete = (id: string) => {
    setHidden(true);
    onDelete?.(id);
  };

  return (
    <div style={{ width: '140px', height: '140px' }} className={thumbnailStyles}>
      <>
        {isImage && (
          <ImageThumbnail
            id={id}
            name={name}
            url={url}
            extension={imageFileExtension?.[0]}
            defaultThumbnail={<DefaultFileThumbnail name={name} person={person} lastModified={lastModified} />}
            readOnly={readOnly}
            attachmentId={attachmentId}
            onDelete={handleDelete}
          />
        )}

        {!isImage && (
          <>
            <DefaultFileThumbnail name={name} person={person} lastModified={lastModified} />
            <ThumbnailActions
              id={id}
              name={name}
              downloadUrl={url}
              readOnly={readOnly}
              attachmentId={attachmentId}
              onDelete={handleDelete}
            />
          </>
        )}
      </>
    </div>
  );
}

// Temporary workaround to ensure the date formatting is correct.
const _appendUTCTimeZoneIdentifier = (dateString: string) => {
  if (dateString.endsWith('Z')) {
    return dateString;
  } else {
    return `${dateString}Z`;
  }
};

const DefaultFileThumbnail = ({
  name,
  person,
  lastModified,
}: {
  name: string;
  person: string;
  lastModified: string;
}) => {
  const fileIcon = getFileIcon(name);
  const _lastModifiedDateStringUTC = _appendUTCTimeZoneIdentifier(lastModified);
  const lastModifiedDate = new Date(_lastModifiedDateStringUTC);

  return (
    <div className="flex flex-col truncate">
      {fileIcon && (
        <div style={{ color: getIconColor(fileIcon) }} className="mb-8 flex items-center">
          <OdinIcon icon={fileIcon} className="mx-1" />
        </div>
      )}
      <div className="absolute bottom-0 right-0 flex w-full flex-col bg-gray-5 pl-2">
        <span className="truncate text-sm font-bold" title={name}>
          {name}
        </span>
        <span className="truncate text-xs" title={person}>
          {person}
        </span>
        <span
          className="truncate text-xs"
          title={`${formatDisplayDate(lastModifiedDate)} ${formatDisplayTime(lastModifiedDate)}`}
        >
          {`${formatDisplayDate(lastModifiedDate)} ${formatDisplayTime(lastModifiedDate)}`}
        </span>
      </div>
    </div>
  );
};

const getFileIcon = (file: string): string | undefined => {
  const fileExtension = file.split('.').pop()?.toLowerCase();

  switch (fileExtension) {
    case 'doc':
    case 'docx':
      return 'FileWord2';
    case 'xls':
    case 'xlsx':
      return 'FileExcel2';
    case 'pdf':
      return 'FilePdf';
  }
};

const getIconColor = (icon: string): string => {
  switch (icon) {
    case 'FileWord2':
      return '#1B5EBE';
    case 'FileExcel2':
      return '#1D6F42';
    case 'FilePdf':
      return '#F01616';
    default:
      return '#727275';
  }
};
