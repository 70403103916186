import React from 'react';
import { Control } from 'react-hook-form';
import { OdinController } from '@myosh/odin-components';
import { HierarchyFieldGroup, HierarchyFieldError, HierarchyFieldGroupProps } from './hierarchy-field-group.component';

interface HfHierarchyFieldProps extends HierarchyFieldGroupProps {
  control?: Control;
  name?: string;
}

export function HfHierarchyField({
  recordId,
  control,
  name = 'hierarchies',
  value,
  readOnly,
  onChange,
  hierarchySettings,
  hierarchyTypes,
  isUserHierarchy,
  isExternal = false,
  isCompetencyHierarchy,
  ignoreHierarchyAccess,
  usesMultiSelectDropdowns = false,
  showRecordWillBecomeUnavailableMessage,
  hideRecordWillBecomeUnavailableMessage,
}: HfHierarchyFieldProps) {
  return (
    <OdinController
      name={name}
      control={control}
      defaultValue={value}
      onChange={onChange}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <HierarchyFieldGroup
          recordId={recordId}
          errorObject={error as unknown as HierarchyFieldError}
          value={value}
          hierarchyTypes={hierarchyTypes}
          readOnly={readOnly}
          onChange={onChange}
          hierarchySettings={hierarchySettings}
          isUserHierarchy={isUserHierarchy}
          isExternal={isExternal}
          isCompetencyHierarchy={isCompetencyHierarchy}
          ignoreHierarchyAccess={ignoreHierarchyAccess}
          showRecordWillBecomeUnavailableMessage={showRecordWillBecomeUnavailableMessage}
          hideRecordWillBecomeUnavailableMessage={hideRecordWillBecomeUnavailableMessage}
          control={control}
          usesMultiSelectDropdowns={usesMultiSelectDropdowns}
        />
      )}
    />
  );
}
