import { DataGridColumnSettings } from '@myosh/odin-components';
import i18next from '../../../../i18n';
import { HierarchyType } from '../../../../@types/hierarchy-fields';
import { UserFields } from '../../../../common/user-config';
import { getUsersColumnConfig } from '../../../../pages/admin/users-page/users-utils';

export const usersDataGridColumns = (hierarchyTypes?: Array<HierarchyType>) => {
  const datagridColumns = getUsersColumnConfig(hierarchyTypes);

  return Object.keys(datagridColumns).map((field) => {
    const columnConfig = datagridColumns[field];
    return {
      id: field,
      field: field,
      title: columnConfig?.title ?? i18next.t(field),
      type: columnConfig?.type || 0,
      isIdField: columnConfig?.isIdField,
      visible: columnConfig?.visible !== false,
      cellRenderer: columnConfig?.cellRenderer,
      valuePath: columnConfig?.valuePath,
      searchType: columnConfig?.searchType,
      dropDownText: columnConfig?.dropDownText,
      dropDownValue: columnConfig?.dropDownValue,
      searchValue: columnConfig?.searchValue,
      customDataProperties: columnConfig?.customDataProperties,
      allowSelectAll: columnConfig.customDataProperties?.entityType !== 'HIERARCHY_TYPE',
    } as DataGridColumnSettings;
  });
};

// The data to be retrieved when fetching user record link information
export const UserRecordLinkFields = usersDataGridColumns()
  .map((column) => column.id)
  .concat(UserFields.userHierarchy);
