import { Key, useCallback, useEffect, useRef, useState } from 'react';
import {
  DropDownRef,
  JsonData,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import { LookupType } from '../../../@types/forms';
import { forceAssert } from '../../../common/common-functions';
import useLookupTypeField from '../../../hooks/use-lookup-type-field';
import { FieldOptionsApiProps } from '../../../@types/options';
import { Control } from 'react-hook-form';
import { useLazyFieldOptionsQuery } from '../../../redux/services/field';

/**
 * A hook that handles all aspects for fetching the person fields data.
 *
 * @param formId the form id where the field is used
 * @param control the hook form 'control' object
 * @param lookupType the defined lookup type, currently only `WITH_FIELD_KEY` and `WITH_HIERARCHY_FIELD` are supported
 * @param lookupKeyId the 'id' of the form field from which the value should be read.
 * @param lookupHierarchyId the 'id' of the hierarchy from which the value should be read.
 * @param dependsOn an optional query string parameter
 *
 */
const useComboboxFieldOptions = (
  formId: number,
  control?: Control,
  lookupType?: LookupType,
  lookupKeyId?: number,
  lookupHierarchyId?: number,
  dependsOn?: Key
) => {
  const [options, setOptions] = useState<OdinDataRetrievalOptions>();
  const subscriberRef = useRef<OdinDataSender<JsonDataWrapper>>();
  const dropDownRef = useRef<DropDownRef>(null);
  const dataRequestedRef = useRef<boolean>();
  const hierarchyFieldIdRef = useRef<number>();

  const [getFieldOptions] = useLazyFieldOptionsQuery();

  const {
    shouldUseLookup,
    hierarchyFieldId: resolvedHierarchyFieldId,
    keyValue,
  } = useLookupTypeField(control, lookupType, lookupKeyId, lookupHierarchyId);

  useEffect(() => {
    if (shouldUseLookup) {
      hierarchyFieldIdRef.current = resolvedHierarchyFieldId;
    }
  }, [shouldUseLookup, resolvedHierarchyFieldId]);

  useEffect(() => {
    if (options) {
      const _options = {
        queryId: Number(options.fieldId),
        formId,
        filters: options.fieldFilters,
        dependsOn: dependsOn,
        next: options.customProperties?.next as string,
        limit: options.pageSize || 50,
      };

      if (shouldUseLookup) {
        if (hierarchyFieldIdRef.current || keyValue) {
          fetchAndSetData({
            ..._options,
            keyValue,
            hierarchyFieldId: hierarchyFieldIdRef.current,
          });
        } else {
          subscriberRef.current?.sendData();
          dataRequestedRef.current = false;
        }
      } else {
        fetchAndSetData(_options);
      }
    }
  }, [options, shouldUseLookup, keyValue]);

  const fetchAndSetData = useCallback((fieldOptions: FieldOptionsApiProps) => {
    getFieldOptions(fieldOptions, true)
      .unwrap()
      .then((result) => {
        const data = result.items ?? [];
        subscriberRef.current?.sendData({
          data: forceAssert<JsonData>(data),
          requestId: options?.requestId,
          customProperties: { next: result.next },
        });
      })
      .catch(() => subscriberRef.current?.sendData())
      .finally(() => (dataRequestedRef.current = false));
  }, []);

  const onSelectorVisible = (open: boolean) => {
    if (shouldUseLookup || dependsOn !== undefined) {
      if (!open) {
        dropDownRef.current?.resetDropDownData();
      } else if (open && dataRequestedRef.current === false) {
        dropDownRef.current?.getDataOverwritePageCache();
      }
    }
  };

  return {
    setOptions,
    onSelectorVisible,
    subscriberRef,
    dataRequestedRef,
    dropDownRef,
  };
};

export default useComboboxFieldOptions;
