import { HfDynamicFieldComponentProps, OdinController } from '@myosh/odin-components';
import React from 'react';
import ReverseLinkedRecords, { ReverseLinkedRecordsProps } from './reverse-linked-records.components';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { RecordResult } from '../../../@types/records';

interface HfReverseLinkedRecordsProps
  extends HfDynamicFieldComponentProps<Array<RecordResult>>,
    Omit<ReverseLinkedRecordsProps, 'name'> {}

/* eslint-disable react/prop-types */
export default function HfReverseLinkedRecords({
  control,
  name,
  id,
  label,
  value,
  recordLinkFormat,
  onChange,
  isPreview,
  readOnly,
  required,
  formId,
  targetModuleFormId,
  recordId,
}: HfReverseLinkedRecordsProps) {
  /* eslint-enable react/prop-types */

  // This is done to try and address the intermittent issue from MYOSH-3860: https://myosh.atlassian.net/browse/MYOSH-3860?focusedCommentId=39541
  const _render = ({ field, fieldState }: { field: ControllerRenderProps; fieldState: ControllerFieldState }) => {
    return (
      <ReverseLinkedRecords
        id={id}
        label={label}
        value={field.value}
        recordLinkFormat={recordLinkFormat}
        isPreview={isPreview}
        onChange={field.onChange}
        error={fieldState.error?.message}
        readOnly={readOnly}
        required={required}
        formId={formId}
        targetModuleFormId={targetModuleFormId}
        recordId={recordId}
      />
    );
  };

  return (
    <OdinController
      name={name as string}
      control={control}
      defaultValue={value}
      onChange={onChange}
      render={(args) => _render(args)}
    />
  );
}
