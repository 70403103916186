import React, { FormEvent, useState } from 'react';
import { ModalDialog, ModalDialogButtonSetting } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import useImportAttachments from './use-import-attachments';
import ImportAttachments from './import-attachments';
import { showError } from '../../../services/notification.service';

interface ImportAttachmentsModalProps {
  visible: boolean;
  onClose: () => void;
}

function ImportAttachmentsModal({ visible, onClose }: ImportAttachmentsModalProps) {
  const [importFiles, setImportFiles] = useState<Array<File>>();
  const [isLinkedWithSourceID, setIsLinkedWithSourceID] = useState(false);

  const { t } = useTranslation();

  const onModalClose = () => {
    setImportFiles(undefined);
    onClose();
  };

  const { startImportAttachmentsJob } = useImportAttachments(onModalClose);

  const uploadOnChange = (value?: File[]) => {
    setImportFiles(value);
  };

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (importFiles) {
      startImportAttachmentsJob(importFiles[0], isLinkedWithSourceID ? 'RECORD_SOURCE_ID' : 'RECORD_ID');
    } else {
      showError(t('import-attachments-failed'));
    }
  };

  const modalButtons: Array<ModalDialogButtonSetting> = [
    {
      text: t('submit'),
      name: 'submit',
      form: 'importAttachmentsForm',
      type: 'primary',
      disabled: !Boolean(importFiles?.length),
    },
  ];

  return (
    <ModalDialog
      header={t('import-attachments')}
      visible={visible}
      hidden={onClose}
      maxDialogWidth={1000}
      buttonType="submit"
      buttons={modalButtons}
      buttonsCloseModal={false}
    >
      <form onSubmit={onFormSubmit} id="importAttachmentsForm">
        <ImportAttachments onChange={uploadOnChange} onAttachmentsLinkedWithSourceIDChange={setIsLinkedWithSourceID} />
      </form>
    </ModalDialog>
  );
}

export default ImportAttachmentsModal;
