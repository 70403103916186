import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BeeSoftProvider, Localization, TestTagContext } from '@myosh/odin-components';
import reportWebVitals from './report-web-vitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import { AuthProvider, UserProvider } from '@myosh/myosh-login';
import { BrowserRouter } from 'react-router-dom';
import Meta from './features/meta/meta';
import Notifications from './components/notifications/notifications.component';
import * as serviceWorkerRegistration from './service-worker-registration';
import i18next from './i18n';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/layout/routes/error-fallback.component';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Provider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <AuthProvider>
            <UserProvider>
              <Localization i18n={i18next}>
                <BeeSoftProvider isValidScrollableElement={(element) => !Boolean(element.dataset.skipScrollElement)}>
                  <TestTagContext.Provider value={{ isTesting: Boolean(process.env.REACT_APP_GENERATE_TEST_TAG) }}>
                    <Meta />
                    <App />
                    <Notifications />
                  </TestTagContext.Provider>
                </BeeSoftProvider>
              </Localization>
            </UserProvider>
          </AuthProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// We register a service worker to serve as an auth relay (MYOSH-1167)
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
