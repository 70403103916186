import React, { useEffect, useMemo, useRef, useState } from 'react';
import MyoshLogo from '../assets/myosh-logo.svg';
import { useTranslation } from 'react-i18next';
import {
  DropDownResult,
  JsonData,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import { useGetVisitorFormsQuery } from '../redux/services/api';
import { useLazyGetRecordsQuery, useUpdateRecordMutation } from '../redux/services/record';
import usePerformingSaveToast from '../hooks/use-performing-save-toast';
import { showError, showSuccess } from '../services/notification.service';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import OdinDropDownComponent from '../components/fields/custom-drop-downs/odin-drop-down.component';
import { forceAssert } from '../common/common-functions';

function VisitorSignOutPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data } = useGetVisitorFormsQuery();
  const [updateRecord] = useUpdateRecordMutation();
  const { showPerformingSaveToast, hidePerformingSaveToast } = usePerformingSaveToast({
    shouldFreezeActiveRecord: true,
  });
  const [newRecordData] = useLazyGetRecordsQuery();

  const [selectedPerson, setSelectedPerson] = useState<DropDownResult>();
  const [options, setOptions] = useState<OdinDataRetrievalOptions>();
  const subscriberRef = useRef<OdinDataSender<JsonDataWrapper>>();
  const fullNameFieldId = data?.[0].fullNameFieldId;

  const logOutStep = useMemo(() => {
    const signOutButton = data?.[0]?.workflowSteps?.find((item) => item.id === data?.[0]?.signOutWorkflowStepId);
    return signOutButton?.label;
  }, [data]);

  const getOptionsData = useMemo(() => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        subscriberRef.current = subscriber;
        setOptions(options);
      },
    };
  }, []);

  useEffect(() => {
    if (options) {
      newRecordData(
        {
          keyTypeId: true,
          formId: data?.[0].id as number,
          page: options.page,
          pageSize: options.pageSize,
          filters: {},
          globalHierarchyFilters: '',
          sortDirective: '',
        },
        true
      )
        .unwrap()
        .then((result) => {
          if (result) {
            const dataItems = result
              .map((item) => {
                return {
                  text: item.original.fields[Number(fullNameFieldId)] as string,
                  value: item.original.id,
                };
              })
              .sort((a, b) => a.text.localeCompare(b.text));
            subscriberRef.current?.sendData({ data: forceAssert<JsonData>(dataItems), requestId: options.requestId });
          } else {
            subscriberRef.current?.sendData();
          }
        })
        .catch(() => subscriberRef.current?.sendData());
    }
  }, [options]);

  const onSubmit = () => {
    if (selectedPerson && data) {
      const saveData = {
        formId: data?.[0].id,
        id: selectedPerson.value,
        status: String(logOutStep),
      };
      showPerformingSaveToast();
      updateRecord({ id: selectedPerson.value as number, patch: saveData, applyDefaultValues: false })
        .unwrap()
        .then(() => {
          hidePerformingSaveToast();
          showSuccess(t('sign-out-successful'));
          setTimeout(() => navigate(`/visitor-sign-in`), 1000);
        })
        .catch(() => {
          hidePerformingSaveToast();
          showError(t('sign-out-failed'));
        });
    }
  };

  const buttonStyles = cx(
    'text-[1em] font-semibold rounded shadow-sm py-2 px-16 hover:opacity-90 focus-visible:outline-2 focus-visible:outline-offset-4 focus-visible:outline-mono-0 border-solid border-2 border-error text-error',
    { invisible: !selectedPerson?.text }
  );

  return (
    <div className="h-full max-w-screen-md m-auto grid  p-4 place-items-center overflow-y-auto">
      <div className="flex flex-col gap-y-12 p-8 border rounded-2xl border-gray-4 shadow-xl items-center ">
        <p className="text-[4em] font-bold uppercase">{t('visitor-sign-out')}</p>
        <OdinDropDownComponent
          textField="text"
          valueField="value"
          data={getOptionsData}
          onChange={(value) => {
            setSelectedPerson(value as DropDownResult);
          }}
          label={t('sign-out-visitor-dropdown-label')}
          minimumSearchCharacters={2}
          showDataOnSearch={true}
        />

        <button className={buttonStyles} onClick={onSubmit}>
          {t('sign-out')}
        </button>

        <div className="overflow-hidden">
          <img src={MyoshLogo} alt="myosh-logo" className="aspect-video w-64 h-32" />
        </div>
      </div>
    </div>
  );
}

export default VisitorSignOutPage;
