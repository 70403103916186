import { useEffect, useState } from 'react';
import { OdinUser } from '../@types/users';
import { TrainingManagementPermissions } from '../common/user.permissions';

const useTrainingManagementEditAccess = (userData?: OdinUser) => {
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    if (
      userData?.superUser ||
      (userData?.adminAccess &&
        (userData?.adminAccess.includes(TrainingManagementPermissions.TRAINING_MANAGEMENT_MODIFY) ||
          userData?.adminAccess.includes(TrainingManagementPermissions.TRAINING_MANAGEMENT_CREATE)))
    ) {
      setReadOnly(false);
    }
  }, [userData]);

  return readOnly;
};

export default useTrainingManagementEditAccess;
