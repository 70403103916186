import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { JobDetails, JobStatus, selectSubmittedJobs, setExportJobStatus } from '../redux/slices/export-slice';
import {
  useCreateAllFormsExportJobMutation,
  useCreateExportJobMutation,
  useLazyGetExportJobDetailsQuery,
} from '../redux/services/record';
import { getFileExtention } from '../common/dynamic-page-utils';
import { dateFormatBasedOnFilter, processFieldFilters } from '../common/common-functions';

const useAsyncExportQueue = () => {
  //export from All Forms
  const [createExportAllFormsJob] = useCreateAllFormsExportJobMutation();
  //export from a reqular view
  const [createFormExportJob] = useCreateExportJobMutation();
  const [getJobStatus] = useLazyGetExportJobDetailsQuery();

  const submittedJobs = useAppSelector(selectSubmittedJobs);

  const jobsToCheckIds = useRef<Array<number>>([]);
  const dispatch = useAppDispatch();

  const checkJobStatus = (job: JobDetails) => {
    getJobStatus(job.backgroundJobId)
      .unwrap()
      .then((exportJobDetails) => {
        if (exportJobDetails?.status === 'FINISHED') {
          jobsToCheckIds.current = jobsToCheckIds.current.filter(
            (backgroundJobId) => backgroundJobId !== job.backgroundJobId
          );

          //https://stackoverflow.com/questions/78208139/javascript-anchor-file-download-override-file-name-from-content-disposition-he
          fetch(exportJobDetails.presignedUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = job.fileName;
              a.click();

              handleJobStatusUpdate(job.id, JobStatus.Success, job.fileName, url);
            });
        }
      })
      .catch(() => {
        jobsToCheckIds.current = jobsToCheckIds.current.filter(
          (backgroundJobId) => backgroundJobId !== job.backgroundJobId
        );
        handleJobStatusUpdate(job.id, JobStatus.Failed, job.fileName);
      })
      .finally(() => {
        if (jobsToCheckIds.current.some((backgroundJobId) => backgroundJobId === job.backgroundJobId)) {
          setTimeout(() => checkJobStatus(job), 6000);
        }
      }).catch;
  };

  useEffect(() => {
    //Make sure the same job is not submitted multiple times
    const applicableNewJobs = submittedJobs.filter((job) => job.status === JobStatus.NotStarted);

    if (applicableNewJobs.length > 0) {
      applicableNewJobs.forEach((exportJobDetails) => {
        const { id, moduleId, formId, format, formName, gridOptions, columns } = exportJobDetails;
        const fileName = `${formName}${getFileExtention(format)}`;

        handleJobStatusUpdate(id, JobStatus.InProgress, fileName);

        const jobIdResponse = moduleId
          ? createExportAllFormsJob({
              moduleId,
              format,
              columns,
              filters: processFieldFilters(gridOptions?.fieldFilters, dateFormatBasedOnFilter),
              sorts: gridOptions?.sortedFields,
            })
          : createFormExportJob({
              formId: formId ?? '0',
              format,
              columns,
              filters: processFieldFilters(gridOptions?.fieldFilters, dateFormatBasedOnFilter),
              sorts: gridOptions?.sortedFields,
            });

        jobIdResponse
          .unwrap()
          .then((response) => {
            const newJob = { backgroundJobId: response.backgroundJobId, id: id ?? '', fileName, formName };

            jobsToCheckIds.current.push(newJob.backgroundJobId);
            checkJobStatus(newJob);
          })
          .catch(() => {
            handleJobStatusUpdate(id, JobStatus.Failed, fileName);
          });
      });
    }
  }, [submittedJobs]);

  const handleJobStatusUpdate = (
    id: string | undefined,
    status: JobStatus,
    fileName?: string,
    fileDownloadUrl?: string
  ) => {
    if (id) {
      const completedDate = status !== JobStatus.InProgress ? new Date().toISOString() : undefined;
      dispatch(setExportJobStatus({ id, status, fileName, fileDownloadUrl, completedDate }));
    }
  };
};

export default useAsyncExportQueue;
