import React from 'react';

interface FormTitleProps {
  title?: string;
}

export const FormTitle = ({ title }: FormTitleProps) => {
  return (
    <>
      {title && (
        <h2
          className="mb-2 flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap text-3xl font-bold text-mono-0"
          title={title}
        >
          {title}
        </h2>
      )}
    </>
  );
};
