import { useState, useEffect } from 'react';
import { JsonDataItem } from '@myosh/odin-components';
import { useGetWorkflowStepsQuery } from '../redux/services/api';

const useModuleWorkflowSteps = (formId?: number) => {
  const [steps, setSteps] = useState<Array<JsonDataItem>>();

  const { data: workflowSteps, isFetching: areWorkflowStepsFetching } = useGetWorkflowStepsQuery(formId ?? 0, {
    skip: formId === undefined,
  });

  useEffect(() => {
    if (!areWorkflowStepsFetching) {
      setSteps(
        workflowSteps
          ?.filter((step) => !step.draft)
          .map((step) => {
            return { text: step.label, value: step.id, originalData: step };
          })
      );
    }
  }, [workflowSteps, areWorkflowStepsFetching]);

  return steps;
};

export default useModuleWorkflowSteps;
