import React from 'react';
import { formatDisplayDate } from '../../common/date-util';

interface RecordTitleProps {
  title: string;
  date?: Date;
}

export const RecordTitle = ({ title, date }: RecordTitleProps) => {
  return (
    <div title={title} className="flex">
      <div className="flex-grow overflow-hidden overflow-ellipsis whitespace-nowrap pr-1">{title}</div>
      {date && <div className="flex-shrink">{formatDisplayDate(date)}</div>}
    </div>
  );
};
