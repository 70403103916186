import React, { useEffect, useState, useMemo } from 'react';
import {
  DynamicFieldComponentProps,
  DataGrid,
  RowTemplateType,
  ErrorLabel,
  FieldLabel,
  JsonData,
  RequiredIndicator,
  EmptyFieldLabel,
  JsonDataItem,
  ContinuousScrollerSort,
  DataGridSettings,
} from '@myosh/odin-components';
import { ExtendedDynamicFormWorkflowStep, forceAssert } from '../../../common/common-functions';
import { RecordLinkProps } from '../../../@types/linked-records-field';
import { useGetWorkflowStepsQuery, useModulesQuery } from '../../../redux/services/api';
import useActiveRecordRef from '../../../hooks/use-active-record-ref';
import {
  LinkedRecordRowProps,
  OdinDataGridRowTemplate,
} from '../../dynamic-page/odin-data-grid-row-template.component';
import {
  getColumnSortPath,
  linkedRecordColumnValuePaths,
  linkedRecordsCellRenderer,
  linkedRecordsSortFunction,
  transformLinkedRecordDataWithKeyTypeId,
  transformRecordLinkColumns,
} from '../linked-records/linked-records-functions';
import {
  customLinkedRecordsComponents,
  defaultLinkedRecordGridSetting,
} from '../../dynamic-page/default-grid-settings';
import { RecordResult } from '../../../@types/records';
import { extractLinkedRecordsPermissions } from '../../../common/form-permissions-util';
import useFormPermissions from '../../../hooks/use-form-permissions';
import useFetchReverseLinkedRecords from '../../../hooks/use-fetch-reverse-linked-records';

export interface ReverseLinkedRecordsProps extends DynamicFieldComponentProps<Array<RecordResult>>, RecordLinkProps {
  isPreview?: boolean;
  formId: number;
  recordId?: number;
}

export default function ReverseLinkedRecords({
  id,
  recordId,
  label,
  value,
  recordLinkFormat,
  isPreview,
  formId,
  error,
  readOnly,
  required,
  targetModuleFormId,
}: ReverseLinkedRecordsProps) {
  const [reverseLinkedRecords, setReverseLinkedRecords] = useState<Array<RecordResult>>([]);
  const [listOfPermittedRecordIds, setListOfPermittedRecordIds] = useState<string[]>([]); //list of record ids you have permissions to access

  const { reverseLinkedRecordsData } = useFetchReverseLinkedRecords(
    recordId,
    Number(id),
    reverseLinkedRecords.map((record) => String(record.id))
  );

  useEffect(() => {
    if (reverseLinkedRecordsData && reverseLinkedRecords) {
      const fieldReverseLinkedRecordIds = reverseLinkedRecordsData.map((record) => String(record.id));
      const recordReverseLinkedRecordsIds = reverseLinkedRecords.map((record) => String(record.id));
      const repeatedIds = recordReverseLinkedRecordsIds.filter((id) => fieldReverseLinkedRecordIds.includes(id));
      setListOfPermittedRecordIds(repeatedIds);
    }
  }, [reverseLinkedRecordsData, reverseLinkedRecords]);

  const { activeRecordReference } = useActiveRecordRef();
  const { data: modules, isLoading: areModulesLoading } = useModulesQuery({});

  const { data: workflowSteps } = useGetWorkflowStepsQuery(formId!, {
    skip: formId === undefined,
  });

  const { formPermissions } = useFormPermissions(targetModuleFormId);

  const reverseLinkedRecordsPermissions = useMemo(() => {
    return extractLinkedRecordsPermissions(formPermissions);
  }, [formPermissions]);

  const canReadRecords = reverseLinkedRecordsPermissions?.create || reverseLinkedRecordsPermissions?.read;
  const canOnlySeeDataGrid = !reverseLinkedRecordsPermissions?.create && !reverseLinkedRecordsPermissions?.read;

  const isRowClickable = useMemo(() => {
    return !isPreview && reverseLinkedRecordsPermissions?.read;
  }, [isPreview, reverseLinkedRecordsPermissions]);

  useEffect(() => {
    if (Array.isArray(value) && (canReadRecords || canOnlySeeDataGrid)) {
      setReverseLinkedRecords(value);
    }
  }, [value, canReadRecords, canOnlySeeDataGrid]);

  const gridColumns = useMemo(() => {
    return recordLinkFormat
      ? transformRecordLinkColumns(
          recordLinkFormat,
          linkedRecordColumnValuePaths,
          getColumnSortPath,
          linkedRecordsCellRenderer
        )
      : [];
  }, [recordLinkFormat]);

  const gridSettings: DataGridSettings = {
    ...defaultLinkedRecordGridSetting,
    allowCellScrollBar: true,
    columns: gridColumns,
    autoSizeColumns: true,
    sortFunction: (data: JsonData, sorts: Array<ContinuousScrollerSort>) =>
      linkedRecordsSortFunction(data, sorts, gridColumns),
    components: {
      ...defaultLinkedRecordGridSetting.components,
      ...customLinkedRecordsComponents,
    },
  };

  const rowTemplate: RowTemplateType = OdinDataGridRowTemplate({
    modules: modules,
    activeRecordReference: activeRecordReference.current,
    isClickable: isRowClickable,
    recordFields: LinkedRecordRowProps,
    isReverseRecordLinkField: true,
    listOfPermittedRecordIds: listOfPermittedRecordIds,
  });

  const rowIndicator = useMemo(() => {
    return {
      indicator: (data?: JsonDataItem) => {
        const backgroundColor = workflowSteps?.find(
          (step: ExtendedDynamicFormWorkflowStep) => step.label === data?.status
        )?.backgroundColor;
        return <div className="h-4 w-4 rounded" style={{ backgroundColor: backgroundColor }} />;
      },
    };
  }, [workflowSteps]);

  const canShowGrid = !areModulesLoading && (canReadRecords || canOnlySeeDataGrid) && reverseLinkedRecords.length > 0;

  return (
    <>
      {label && (
        <div className="flex">
          <FieldLabel label={label} />
          <RequiredIndicator readOnly={readOnly} required={required} />
        </div>
      )}
      {readOnly && reverseLinkedRecords.length === 0 && <EmptyFieldLabel />}
      {canShowGrid && (
        <div className="my-2 flex  max-h-[40rem]">
          <DataGrid
            data={transformLinkedRecordDataWithKeyTypeId(forceAssert<JsonData>(reverseLinkedRecords))}
            gridSettings={gridSettings}
            rowTemplate={rowTemplate}
            showSettings={false}
            rowIndicator={rowIndicator}
          />
        </div>
      )}
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </>
  );
}
