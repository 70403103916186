import {
  ContinuousScrollerFilter,
  ContinuousScrollerSort,
  convertComparisonTypeToString,
} from '@myosh/odin-components';
import { FieldFilters, FieldSorts } from '../../../@types/common';
import { FilterCustomProperties, forceAssert } from '../../../common/common-functions';

//API returned values for menu items
export enum RecordListMenuItemType {
  Restore = 'RESTORE_CURRENT_VIEW',
  SaveView = 'SAVE_CURRENT_VIEW',
  ManageView = 'MANAGE_VIEW',
  ImportAttachments = 'IMPORT_ATTACHMENTS',
  Import = 'IMPORT_RECORDS',
  Export = 'EXPORT_RECORDS',
  Addon = 'ADD_ON',
}

export interface DataGridSettingsMenuItem {
  key: string;
  label?: string;
  icon?: string;
  url?: string;
  target?: string;
  subMenuItems?: Array<DataGridSettingsMenuItem>;
  isSeparator?: boolean;
  visible?: boolean;
  disabled?: boolean;
  isPinned?: boolean;
  isPinIconHidden?: boolean;
  addonId?: number;
  onClick?: () => void;
  className?: string;
}

export const convertToSortedFields = (arrOfSorts: ContinuousScrollerSort[]): FieldSorts => {
  const result: FieldSorts = {};

  arrOfSorts.forEach((item) => {
    const field = item.scrollerId;
    const direction = item.sortDirection === 0 ? 'asc' : 'desc';
    const order = item.order ?? 0;
    const customDataProperties = item.customDataProperties;

    result[field] = {
      direction,
      order,
      customDataProperties,
    };
  });

  return result;
};

export const convertToFieldFilters = (filters: ContinuousScrollerFilter[]): FieldFilters => {
  const fieldFilters: FieldFilters = {};

  filters.forEach((filter) => {
    const { customDataProperties = {} } = filter;
    const { entityPropertyName, entityType } = forceAssert<FilterCustomProperties>(customDataProperties);
    if (filter.value !== undefined && filter.value !== null) {
      let comparison = '';
      const key = entityType === 'KEYWORD' ? entityPropertyName : filter.scrollerId;
      if (filter.comparison !== undefined && filter.comparison !== null) {
        comparison = convertComparisonTypeToString(filter.comparison);
      }
      if (Array.isArray(filter.value)) {
        const sanitizedArray = filter.value.filter((item) => item !== undefined && item !== null);
        if (sanitizedArray.length > 0) {
          fieldFilters[key] = {
            value: sanitizedArray,
            comparison,
            customDataProperties: filter.customDataProperties,
          };
        }
      } else if (filter.value instanceof Date && !isNaN(filter.value.getTime())) {
        fieldFilters[key] = {
          value: filter.value,
          comparison,
          customDataProperties: filter.customDataProperties,
        };
      } else {
        fieldFilters[key] = {
          value: filter.value,
          comparison,
          customDataProperties: filter.customDataProperties,
        };
      }
    }
  });

  return fieldFilters;
};
