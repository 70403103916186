import { DropDownResult } from '@myosh/odin-components';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface LastUsedForm {
  viewId: string;
  form: DropDownResult;
}

interface DynamicPageFilters {
  viewConfig?: DropDownResult;
  records?: DropDownResult;
  lastEdited?: DropDownResult;
}

interface DynamicPageState {
  lastUsedForms: Record<string, DropDownResult>;
  selectedFilters: DynamicPageFilters;
}

const initialState: DynamicPageState = {
  lastUsedForms: {},
  selectedFilters: {},
};

const dynamicPageSlice = createSlice({
  name: 'dynamicPage',
  initialState,
  reducers: {
    setSelectedForm: (state, action: PayloadAction<LastUsedForm>) => {
      state.lastUsedForms[action.payload.viewId] = action.payload.form;
      state.selectedFilters = {};
    },
    clearSelectedForm: (state) => {
      state.selectedFilters = {};
    },
    setSelectedLastEditedFilter: (state, action: PayloadAction<DropDownResult>) => {
      state.selectedFilters.lastEdited = action.payload;
    },
    setSelectedRecordsFilter: (state, action: PayloadAction<DropDownResult>) => {
      state.selectedFilters.records = action.payload;
    },
    setSelectedViewConfig: (state, action: PayloadAction<DropDownResult | undefined>) => {
      state.selectedFilters.viewConfig = action.payload;
    },
  },
});

export const {
  setSelectedForm,
  clearSelectedForm,
  setSelectedLastEditedFilter,
  setSelectedRecordsFilter,
  setSelectedViewConfig,
} = dynamicPageSlice.actions;
export const dynamicPageState = (state: RootState) => state.dynamicPage;
export default dynamicPageSlice.reducer;
