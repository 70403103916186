export const MIN_ALLOWED_PANEL_WIDTH = 560;
const MAX_DEFAULT_PANEL_WIDTH = 960;

/**
 * Default precalculated width of a record is in the range [MIN_ALLOWED_PANEL_WIDTH,
 *  MAX_DEFAULT_PANEL_WIDTH].
 *
 * A user can resize a record to make it wider, but the width less than
 * `MIN_ALLOWED_PANEL_WIDTH` is not allowed.
 *
 * @returns Default width of a record in the active panel
 */
export const getDefaultRecordWidth = () => {
  const storageValue = localStorage.getItem('recordWidth');
  if (storageValue && !isNaN(Number(storageValue))) {
    return Number(storageValue);
  }

  const minLimit = Math.max(window.innerWidth / 2, MIN_ALLOWED_PANEL_WIDTH);
  const maxLimit = Math.min(MAX_DEFAULT_PANEL_WIDTH, minLimit);

  return maxLimit;
};
