import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuthAndUrlEncoding } from './api';
import { Rule, RuleResponse, RuleResult, RulesResponse } from '../../@types/rules';
import { transformRuleResponse } from '../../components/admin/rule/rule-utils';

export const ruleApi = createApi({
  reducerPath: 'rule',
  baseQuery: baseQueryWithReAuthAndUrlEncoding,
  tagTypes: ['Rule'],
  endpoints: (builder) => ({
    getRules: builder.query<Array<RuleResult>, number>({
      query: (moduleId) => `/rule/module/${moduleId}/auto-rules`,
      transformResponse: (response: RulesResponse) => {
        return response.result;
      },
    }),
    getRuleById: builder.query<Rule, number>({
      query: (id) => {
        return `/rule/auto-rules/simple/${id}`;
      },
      transformResponse: (response: RuleResponse) => transformRuleResponse(response),
      providesTags: (result) => (result ? [{ type: 'Rule' as const, id: result.id as number }] : ['Rule']),
    }),
  }),
});

export const { useGetRulesQuery, useGetRuleByIdQuery, useLazyGetRuleByIdQuery } = ruleApi;
