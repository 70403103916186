import { cloneDeep, isArray, isEqual } from 'lodash';
import {
  TransformedFormHierarchyTypes,
  HierarchyType,
  HierarchyValue,
  HierarchyDropdownValue,
} from '../../../@types/hierarchy-fields';
import { RecordHierarchyFields } from './hierarchy-field-group.component';
import { hierarchyDeselectValue } from './hierarchy-field.component';
import { FieldValues } from './hierarchies.reducer';

export const getHierarchyFieldValue = (
  hierarchyValues: Record<string, HierarchyDropdownValue | HierarchyDropdownValue[]>,
  hierarchyField: RecordHierarchyFields,
  fieldValue: HierarchyDropdownValue | HierarchyDropdownValue[],
  dependentHierarchyTypes: Record<string, string>,
  usesMultiSelectDropdowns?: boolean
) => {
  const parentHierarchyValue = hierarchyValues[dependentHierarchyTypes[hierarchyField.caption]];
  if (
    hierarchyValues?.[hierarchyField.caption] &&
    dependentHierarchyTypes &&
    !(hierarchyValues?.[hierarchyField.caption] as HierarchyDropdownValue).archived
  ) {
    // Parent related logic is not needed when using multiselect dropdowns
    if (hierarchyField.dependsOnParent && !usesMultiSelectDropdowns) {
      if (!isArray(parentHierarchyValue) && !isArray(fieldValue)) {
        return {
          ...(fieldValue?.parentId === parentHierarchyValue?.id ? fieldValue : hierarchyDeselectValue),
          selectedParentId: parentHierarchyValue?.id,
        };
      }
    } else {
      return fieldValue;
    }
  } else {
    return usesMultiSelectDropdowns
      ? []
      : {
          ...hierarchyDeselectValue,
          selectedParentId: !isArray(parentHierarchyValue) ? parentHierarchyValue?.id : undefined,
        };
  }
};

export const getInitialHierarchyFieldsConfigurations = (
  hierarchyTypes: Array<TransformedFormHierarchyTypes | HierarchyType>,
  hierachyTypesAll: Array<HierarchyType>,
  isUserHierarchy?: boolean,
  isCompetencyHierarchy?: boolean,
  isExternal?: boolean,
  isTreeHierarchy?: boolean
) => {
  let visibleHierarchies;
  const typePairs: Record<string, string> = {};

  if (!isUserHierarchy && !isCompetencyHierarchy) {
    visibleHierarchies = hierarchyTypes;
  } else {
    visibleHierarchies = filterExternalHierarchies(hierarchyTypes as HierarchyType[], isExternal).filter(
      ({ archived }) => !archived
    );
  }

  hierarchyTypes.forEach((hierarchyType) => {
    if (hierarchyType.dependsOnParent) {
      if (isTreeHierarchy) {
        const parentHierarchy = hierachyTypesAll.find(({ id, caption }) =>
          hierarchyType?.parentId ? id === hierarchyType.parentId : caption === hierarchyType.caption
        );
        if (parentHierarchy) {
          typePairs[hierarchyType.caption as string] = parentHierarchy.caption as string;
        }
      } else {
        const itemIndex = hierachyTypesAll.findIndex((hierarchy) => hierarchy.caption === hierarchyType.caption);
        if (itemIndex > 0) {
          typePairs[hierarchyType.caption as string] = hierachyTypesAll[itemIndex - 1].caption as string;
        }
      }
    }
  });

  return {
    dependentHierarchyTypes: typePairs,
    hierarchyFieldsState: visibleHierarchies,
  };
};

const filterExternalHierarchies = (hierarchyTypes: Array<HierarchyType>, isExternal = false) => {
  return hierarchyTypes.filter((hierarchyValue) => hierarchyValue.external === isExternal);
};

export const isHierarchyMandatory = (
  hierarchyField: RecordHierarchyFields,
  isUserHierarchy = false,
  isCompetencyHierarchy = false
) => {
  if (isUserHierarchy) {
    return hierarchyField.mandatoryForUserRecords;
  } else if (isCompetencyHierarchy) {
    return true;
  } else {
    const fieldWithType = hierarchyField as TransformedFormHierarchyTypes;
    return fieldWithType.mandatoryTypeConfig;
  }
};

export const hierarchyToDropDown = (hierarchyValues: Array<HierarchyValue>): Array<HierarchyDropdownValue> => {
  return hierarchyValues.map((hierarchy) => {
    return {
      id: hierarchy.id,
      caption: hierarchy.caption,
      hierarchyTypeId: hierarchy.hierarchyTypeId,
      parentId: hierarchy?.parentId,
    };
  });
};

/**
 * Checks if the hierarchy value ids are equal when only a single hierarchy can be selected.
 * The objects had different properties which made the normal isEqual function to return true to often.
 * @param newValues New hierarchy values
 * @param oldValues Old hierarchy values
 * @returns Boolean if values are equal
 */
export function isHierarchyKeyValueEqual(
  newValues: Record<string, HierarchyDropdownValue | HierarchyDropdownValue[]>,
  oldValues: Record<string, HierarchyDropdownValue | HierarchyDropdownValue[]>
) {
  let isValueEqual = false;

  const newKeys = Object.keys(newValues);
  const oldKeys = Object.keys(oldValues);

  if (newKeys.length !== oldKeys.length) {
    return false;
  }

  for (let i = 0; i < newKeys.length; i++) {
    const hierarchyKey = newKeys[i];
    const newHierarchyValue = newValues[hierarchyKey];
    const oldHierarchyValue = oldValues[hierarchyKey];

    if (!Array.isArray(newHierarchyValue) && !Array.isArray(oldHierarchyValue)) {
      isValueEqual = isEqual(newHierarchyValue.id, oldHierarchyValue.id);
    } else if (Array.isArray(newHierarchyValue) && Array.isArray(oldHierarchyValue)) {
      isValueEqual = isEqual(newHierarchyValue, oldHierarchyValue);
    }

    if (!isValueEqual) {
      break;
    }
  }

  return isValueEqual;
}

export const mergeIgnoreHierarchyAccess = (
  baseValues: Record<string, HierarchyDropdownValue | HierarchyDropdownValue[]>,
  existingFieldsValues?: FieldValues
): FieldValues => {
  return {
    ...baseValues,
    ...(existingFieldsValues?.ignoreHierarchyAccess
      ? { ignoreHierarchyAccess: existingFieldsValues.ignoreHierarchyAccess }
      : {}),
  };
};

/**
 * A mandatory hierarchy field cannot be cleared after it already has a value!
 *
 * If a mandatory hierarchy field already has a value and the newly selected parent field value would empty the hierarchy field value,
 * the existing hierarchy field value needs to stay as selected, even if the parent field would clear the value.
 */
export const setNewParentPersonHierarchyValues = (
  personHierarchies: Record<string, HierarchyDropdownValue> | undefined,
  hierarchyFieldsState: Array<RecordHierarchyFields> | undefined,
  fieldsValues: FieldValues | undefined,
  isUserHierarchy: boolean,
  isCompetencyHierarchy: boolean
) => {
  let personHierarchiesCopy = cloneDeep(personHierarchies);

  if (hierarchyFieldsState) {
    for (let i = 0; i < hierarchyFieldsState.length; i++) {
      const hierarchyField = hierarchyFieldsState[i];
      const isHierarchyFieldMandatory = isHierarchyMandatory(hierarchyField, isUserHierarchy, isCompetencyHierarchy);

      if (isHierarchyFieldMandatory) {
        //Check if the new value from the parent field would clear the hierarchy field value
        const willMakeFieldEmpty = !personHierarchiesCopy || !personHierarchiesCopy[hierarchyField.caption]?.id;

        //Check if the hierarchy field already has a value
        const currentValues = fieldsValues?.[hierarchyField.caption];
        const fieldValue = !Array.isArray(currentValues) ? currentValues?.caption : undefined;

        //Keep value as selected if the new value would be empty and the field already has a value
        if (fieldValue && !Array.isArray(currentValues) && currentValues && willMakeFieldEmpty) {
          if (!personHierarchiesCopy) {
            personHierarchiesCopy = {};
          }

          personHierarchiesCopy[hierarchyField.caption] = currentValues;
        }
      }
    }
  }

  return personHierarchiesCopy;
};
