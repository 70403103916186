import React, { useRef, useState } from 'react';
import Avatar from '../../../assets/icons/avatar.svg';
import useProfileData from '../../../hooks/use-profile-data';
import SchemaSelector, { SchemaDropdownItem } from './schema-selector.component';
import {
  AboutInfoModal,
  Button,
  DomElementAlignment,
  DomTargetPosition,
  ModalDialog,
  OdinIcon,
  OdinIconType,
  OverlayPanel,
  useScreenSize,
} from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import UserSettingsModal from '../../admin/user/user-settings-modal.component';
import { api, useGetCurrentSchemaInfoQuery, useGetFeatureToursQuery } from '../../../redux/services/api';
import { useAppDispatch } from '../../../redux/hooks';
import { UserActionTypes, useUser } from '@myosh/myosh-login';
import useUserSchemas from '../../../hooks/use-user-schemas';
import { useGetUserAvatarQuery } from '../../../redux/services/user';
import { setIsManualSchemaUpdate } from '../../../redux/slices/schema';
import { OdinUser } from '../../../@types/users';
import SettingsMenuFeatureTour from './feature-tour-guides/settings-menu-tour';
import { useLocation } from 'react-router-dom';

function ProfileMenu() {
  const { state: userState, dispatch: userDispatch } = useUser();
  const { isMobile } = useScreenSize();
  const { schemas, currentSchema, isControllingSuperuser } = useUserSchemas(userState);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    isAboutInfoOpen,
    setAboutInfoOpen,
    isSelectSchemaOpen,
    setSelectSchemaOpen,
    isSettingsModalOpen,
    setIsSettingsModalOpen,
    profileData,
    profileData: { user: userData } = {},
  } = useProfileData(schemas);
  const isSuperUser = userData?.superUser;

  const [selectSchema, setSelectSchema] = useState(currentSchema);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuButtonRef = useRef(null);

  const { pathname } = useLocation();
  const isRecordOpen = pathname.includes('/records');

  const { data: featureTourData } = useGetFeatureToursQuery();

  const { data: schemaInfo } = useGetCurrentSchemaInfoQuery();
  const { data: userAvatar } = useGetUserAvatarQuery(profileData?.user?.id || 0, {
    skip: !profileData?.user?.id,
  });

  const updateCurrentSchema = (schema: SchemaDropdownItem) => {
    setSelectSchema(schema);
  };

  const submitCurrentSchemaSelection = () => {
    setSelectSchemaOpen(false);

    if (selectSchema) {
      dispatch(setIsManualSchemaUpdate(true));
      userDispatch({ type: UserActionTypes.UpdateCurrentSchema, payload: selectSchema.value });
      dispatch(api.util.resetApiState());
      window.location.replace('/');
    }
  };

  const resolveUserValue = (user: OdinUser) => {
    if (user.fullName) {
      return user.fullName;
    } else {
      return `${user.firstName} ${user.lastName}`;
    }
  };

  const onMenuIconClick = () => setIsMenuOpen(!isMenuOpen);
  const onHideMenu = () => setIsMenuOpen(false);

  const onMenuItemClick = (action?: () => void) => {
    action?.();
    setIsMenuOpen(false);
  };

  const schemaSelectorfooter = (
    <div className="flex justify-end">
      <Button type="primary" onClick={submitCurrentSchemaSelection}>
        Change Company
      </Button>
    </div>
  );

  return (
    <>
      <div className="flex items-center justify-end gap-3 px-4 py-2 max-h-full">
        <img src={userAvatar || Avatar} height={24} width={24} alt="profile image" className="rounded-full" />
        {!isMobile && <span className="font-medium">{profileData.user && resolveUserValue(profileData.user)}</span>}
        {/*settings-menu-button is used for Feature Tours*/}
        <button ref={menuButtonRef} onClick={onMenuIconClick} className="settings-menu-button">
          <OdinIcon icon="Menu" type={OdinIconType.Line} />
        </button>
      </div>
      <OverlayPanel
        visible={isMenuOpen}
        target={menuButtonRef.current}
        targetPosition={DomTargetPosition.BottomRight}
        elementAlignment={DomElementAlignment.TopRight}
        hidden={onHideMenu}
        className="min-w-[200px] bg-mono-1 rounded"
        shouldCheckZIndex={featureTourData?.hasSettingsMenuTourRan}
        keepOverlayOpen={!featureTourData?.hasSettingsMenuTourRan}
      >
        <ul className="py-3">
          {isMobile && (
            <li className="font-medium px-4 py-1">{profileData.user && resolveUserValue(profileData.user)}</li>
          )}
          {profileData.items.map((menuItem) => {
            return (
              <li
                key={menuItem.name}
                onClick={() => onMenuItemClick(menuItem.action)}
                hidden={menuItem.authorized === false}
                className={`flex gap-3 cursor-pointer px-3 py-1 hover:bg-primary-2 hover:text-mono-1 ${menuItem.className ?? ''}`}
              >
                <span className="w-6">
                  {menuItem.icon && <OdinIcon icon={menuItem.icon} type={OdinIconType.Line} />}
                </span>
                <span>{menuItem.name}</span>
              </li>
            );
          })}
        </ul>
      </OverlayPanel>
      <AboutInfoModal
        copyright={`${t('copyright')} ${new Date().getFullYear()}`}
        currentSchema={{ text: currentSchema?.text || 'n/a' }}
        isAboutInfoOpen={isAboutInfoOpen}
        onClose={() => setAboutInfoOpen(false)}
        showLogoutButton={false}
        userTimeZone={schemaInfo?.['time-zone']}
        {...schemaInfo}
        controllingSuperuser={isControllingSuperuser ? userState?.user?.prefered_username : undefined}
        version={process.env.REACT_APP_VERSION}
      />
      <ModalDialog
        key="schema-selector"
        header={t('select-company', 'Select Company')}
        minDialogWidth={340}
        footer={schemaSelectorfooter}
        visible={isSelectSchemaOpen}
        hidden={() => setSelectSchemaOpen(false)}
      >
        {schemas && currentSchema && (
          <SchemaSelector updateCurrentSchema={updateCurrentSchema} schemas={schemas} currentSchema={currentSchema} />
        )}
      </ModalDialog>
      <UserSettingsModal
        userData={userData}
        visible={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
      />
      {!isSuperUser &&
        !isRecordOpen &&
        !profileData?.user?.ssoUser &&
        profileData?.items?.find((item) => item.className === 'logout-menu-item') &&
        featureTourData &&
        !featureTourData.hasSettingsMenuTourRan && (
          <SettingsMenuFeatureTour
            isMenuOpen={isMenuOpen}
            featureTourData={featureTourData}
            setIsMenuOpen={setIsMenuOpen}
          />
        )}
    </>
  );
}

export default ProfileMenu;
