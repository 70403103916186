import React, { useEffect, useState } from 'react';
import RiskRatingMatrix from './risk-rating-matrix.component';
import { RiskMatrixCellDataProps, RiskMatrixData, RiskMatrixDataItem } from '../../../@types/risk-matrix-field';
import {
  additionalRiskCellItems,
  additionalRiskLevelCaptions,
  riskMatrixCellsSorted,
  uniqueRiskMatrixColumns,
} from './risk-rating-functions';
import { cloneDeep } from 'lodash';
import { FieldLabel, Loader } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import {
  useCreateNewMatrixConsequenceMutation,
  useCreateNewMatrixLikelyhoodMutation,
  useRiskRatingMatrixConfigQuery,
} from '../../../redux/services/risk-matrix';
import RiskRatingModal from './rating-modal.component';
import RiskHeadersModal, { RiskMatrixHederValue } from './risk-headers-modal.component';

interface RiskMatrixAdminProps {
  label?: string;
  riskMatrixId: number;
  readOnly?: boolean;
}

const RiskMatrixAdmin = ({ riskMatrixId, label, readOnly }: RiskMatrixAdminProps) => {
  const { t } = useTranslation();

  const [riskMatrixCells, setRiskMatrixCells] = useState<RiskMatrixData[]>([]);
  const [selectedRiskMatrixCell, setSelectedRiskMatrixCell] = useState<RiskMatrixDataItem>();
  const [selectedRiskMatrixHeader, setSelectedRiskMatrixHeader] = useState<RiskMatrixHederValue>();
  const { data: riskMatrixData, isFetching } = useRiskRatingMatrixConfigQuery(riskMatrixId);
  const [createNewLikelihood] = useCreateNewMatrixLikelyhoodMutation();
  const [createNewConsequence] = useCreateNewMatrixConsequenceMutation();

  useEffect(() => {
    // The first item is a header row
    const cellPlaceholder: RiskMatrixCellDataProps = {
      //1:1 cell should be empty
      header: [{}],
    };

    const initialRiskLevels: Record<string, RiskMatrixDataItem> = {};

    if (riskMatrixData && riskMatrixData.length > 0) {
      const matrixDataByLikelihood = cloneDeep(riskMatrixData).sort(
        (riskMatrix1, riskMatrix2) => (riskMatrix1.likelihood.position ?? 0) - (riskMatrix2.likelihood.position ?? 0)
      );
      for (const riskMatrixElement of matrixDataByLikelihood) {
        const riskLevelKey = String(riskMatrixElement?.riskLevel?.category) as string;
        if (riskLevelKey) {
          initialRiskLevels[riskLevelKey] = riskMatrixElement.riskLevel;
        }

        const likelihoodRowKey =
          (riskMatrixElement?.likelihood?.caption?.translations[0].value as string) +
          String(riskMatrixElement?.likelihood?.id);

        const consequenceColumns = uniqueRiskMatrixColumns(cellPlaceholder['header'], riskMatrixElement);
        const riskCellItems = additionalRiskCellItems(riskMatrixElement);
        const riskLevelCaptions = additionalRiskLevelCaptions(riskMatrixElement);

        const riskLevelProperties = {
          ratingId: riskCellItems?.ratingId,
          rating: riskCellItems?.rating,
          category: riskCellItems?.category,
          consequencePosition: riskMatrixElement.consequence.position,
          cellId: riskMatrixElement.id,
          riskLevelCaptions: {
            ...riskMatrixElement.riskLevel.riskLevelCaptions,
            consequenceCaption: riskLevelCaptions.consequenceCaption,
            consequenceDescription: riskLevelCaptions.consequenceDescription,
            likelihoodCaption: riskLevelCaptions.likelihoodCaption,
            likelihoodDescription: riskLevelCaptions.likelihoodDescription,
          },
        };

        if (!consequenceColumns) {
          cellPlaceholder['header'] = [...cellPlaceholder['header'], riskMatrixElement.consequence];
        }

        if (!cellPlaceholder[likelihoodRowKey]) {
          cellPlaceholder[likelihoodRowKey] = [
            riskMatrixElement.likelihood,
            {
              ...riskMatrixElement.riskLevel,
              ...riskLevelProperties,
            },
          ];
        } else {
          cellPlaceholder[likelihoodRowKey] = [
            ...cellPlaceholder[likelihoodRowKey],
            {
              ...riskMatrixElement.riskLevel,
              ...riskLevelProperties,
            },
          ];
        }
      }
      const transformedRiskMatrixCells = riskMatrixCellsSorted(cellPlaceholder);

      setRiskMatrixCells(transformedRiskMatrixCells);
    }
  }, [riskMatrixData]);

  const handleRatingChange = (riskMatrixCellData: RiskMatrixDataItem) => {
    setSelectedRiskMatrixCell(riskMatrixCellData);
  };

  const handleAddConsequence = () => {
    if (riskMatrixId) {
      createNewConsequence(riskMatrixId);
    }
  };

  const handleAddLikelihood = () => {
    if (riskMatrixId) {
      createNewLikelihood(riskMatrixId);
    }
  };

  const handleRiskRatingModalClose = () => {
    setSelectedRiskMatrixCell(undefined);
  };

  const handleRiskHeadersModalClose = () => {
    setSelectedRiskMatrixHeader(undefined);
  };

  const handleConsequenceValueChange = (riskMatrixCell: RiskMatrixDataItem) => {
    setSelectedRiskMatrixHeader({ type: 'consequence', value: riskMatrixCell });
  };

  const handleLikelyhoodValueChange = (riskMatrixCell: RiskMatrixDataItem) => {
    setSelectedRiskMatrixHeader({ type: 'likelihood', value: riskMatrixCell });
  };

  const riskMatrixStyles = cx('min-w-[200px] min-h-[80px] overflow-auto ', {
    'pointer-events-none select-none opacity-70 cursor-not-allowed': readOnly,
  });

  const addNewLikelyHoodStyles = cx('text-primary-1 cursor-pointer', {
    'pointer-events-none select-none opacity-50 cursor-not-allowed': readOnly,
  });

  const addNewConsequenceStyles = cx(
    'w-16 whitespace-nowrap text-primary-1  rotate-90 flex items-center justify-center cursor-pointer',
    {
      'pointer-events-none select-none opacity-50 cursor-not-allowed': readOnly,
    }
  );

  return (
    <div className="flex flex-col gap-4">
      {label && (
        <div className="flex">
          <FieldLabel label={label} />
        </div>
      )}

      {isFetching ? (
        <div className="mx-auto flex h-52 w-96 flex-col items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="flex flex-col w-fit bg-mono-1">
            <div className="h-16 whitespace-nowrap w-full flex items-center justify-center">
              <span className="text-primary-1">{t('consequence')}</span>
            </div>
            <div className="flex items-center justify-center">
              <div className="h-full flex items-center justify-center">
                <span className="w-16 align-center text-primary-1 -rotate-90 flex items-center justify-center">
                  {t('likelihood')}
                </span>
              </div>
              <div className={riskMatrixStyles}>
                {riskMatrixCells && (
                  <RiskRatingMatrix
                    riskMatrixVisible={true}
                    riskMatrixData={riskMatrixCells}
                    handleRatingChange={handleRatingChange}
                    handleConsequenceValueChange={handleConsequenceValueChange}
                    handleLikelyhoodValueChange={handleLikelyhoodValueChange}
                  />
                )}
              </div>
              <div className="h-full flex items-center justify-center">
                <span onClick={handleAddConsequence} className={addNewConsequenceStyles}>
                  + {`${t('add')} ${t('consequence')}`}
                </span>
              </div>
            </div>
            <div className="h-16 w-full flex items-center justify-center">
              <span onClick={handleAddLikelihood} className={addNewLikelyHoodStyles}>
                + {`${t('add')} ${t('likelihood')}`}
              </span>
            </div>
          </div>
        </>
      )}
      <RiskRatingModal
        riskMatrixId={riskMatrixId}
        selectedRiskMatrixCell={selectedRiskMatrixCell}
        visible={Boolean(selectedRiskMatrixCell)}
        handleModalClose={handleRiskRatingModalClose}
      />
      <RiskHeadersModal
        selectedRiskMatrixHeader={selectedRiskMatrixHeader}
        visible={Boolean(selectedRiskMatrixHeader)}
        handleModalClose={handleRiskHeadersModalClose}
      />
    </div>
  );
};

export default RiskMatrixAdmin;
