import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuthAndUrlEncoding } from './api';
import {
  CurrentUserPasswordUpdate,
  UserApiProps,
  UserAvatarConfirmApiProps,
  CurrentUserResult,
  UserResult,
  UsersResponse,
  UserStructure,
} from '../../@types/users';
import {
  fileToSrc,
  appendOptionalUserQueryArguments,
  sortingUsersApiUrl,
  createFieldFilterText,
} from '../../common/common-functions';
import { createUserData } from '../../common/user-functions';
import { UserFields } from '../../common/user-config';
import { GetFieldImageUrlResponse, FieldFilters } from '../../@types/common';

export const userApi = createApi({
  reducerPath: 'user',
  baseQuery: baseQueryWithReAuthAndUrlEncoding,
  tagTypes: ['User', 'LinkedUser', 'UserAvatar'],
  endpoints: (builder) => ({
    getUsers: builder.query<Array<UserStructure>, UserApiProps>({
      query: (args: UserApiProps) => {
        return `/users?page=${args.page}&pageSize=${args.pageSize}${appendOptionalUserQueryArguments(args)}`;
      },
      transformResponse: (response: UsersResponse) => {
        return response.items && response.items.length > 0 ? response.items.map(createUserData) : [];
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map((item) => ({ type: 'User' as const, id: item.original.id })),
              { type: 'User' as const, id: 'LIST' },
            ]
          : [{ type: 'User' as const, id: 'LIST' }],
    }),
    getUserById: builder.query<UserStructure, { id: number; fields: string; filters?: FieldFilters }>({
      query: (args: { id: number; fields: string; filters?: FieldFilters }) => {
        if (args.filters) {
          const filteredFields = Object.fromEntries(
            Object.entries(args?.filters).filter(([key]) => key !== 'occupation')
          );
          return `/users?filter=id:eq:${args.id}&fields=${args.fields}${
            args.filters?.occupation ? `&filter=occupationId:in:${args.filters?.occupation.value}` : ''
          }${createFieldFilterText({ filters: filteredFields })}`;
        } else {
          return `/users?filter=id:eq:${args.id}&fields=${args.fields}${createFieldFilterText({
            filters: args.filters,
          })}`;
        }
      },
      transformResponse: (response: UsersResponse) =>
        response.items && response.items.length > 0 ? createUserData(response.items[0]) : ({} as UserStructure),
      providesTags: (result) => (result ? [{ type: 'User' as const, id: result.original.id }] : ['User']),
    }),
    getLinkedUsersById: builder.query<UserStructure[], UserApiProps>({
      query: (args: UserApiProps) =>
        `/users?page=${args.page}&pageSize=${args.pageSize}&filter=id:id_in:${args.linkedRecordIds}&fields=${
          args.fields
        }${sortingUsersApiUrl(args.sort)}`,
      transformResponse: (response: UsersResponse) => {
        return response.items && response.items.length > 0 ? response.items.map(createUserData) : [];
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map((item) => ({ type: 'LinkedUser' as const, id: item.original.id })),
              { type: 'LinkedUser' as const, id: 'LIST' },
            ]
          : [{ type: 'LinkedUser' as const, id: 'LIST' }],
    }),
    getCurrentUser: builder.query<CurrentUserResult, { fields: UserFields[] }>({
      query: (args: { fields: UserFields[] }) => `/users/current-user?fields=${args.fields.join()}`,
      transformResponse: (response: { result: CurrentUserResult }) => response.result,
      providesTags: (result) => (result ? [{ type: 'User' as const, id: result.id }] : ['User']),
    }),
    getExternalUsers: builder.query<Array<UserResult>, void>({
      query: () => `/users?filter=activeExternalUser:eq:true&filter=loginRequired:eq:true&filter=archived:eq:false`,
      transformResponse: (response: { items?: Array<UserResult> }) => response.items ?? [],
    }),
    addUser: builder.mutation<void, Omit<Partial<UserResult>, 'id'>>({
      query: (body) => ({
        url: `/users?holdEmail=true`,
        method: 'POST',
        body: body,
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),
      invalidatesTags: (result) => {
        if (result) {
          return [{ type: 'User', id: 'LIST' }];
        } else {
          return [];
        }
      },
    }),
    updateUser: builder.mutation<void, Pick<UserResult, 'id'> & Omit<Partial<UserResult>, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/users/${id}`,
        method: 'PATCH',
        body: patch,
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result) {
          return [
            { type: 'User', id: arg.id },
            { type: 'LinkedUser', id: arg.id },
          ];
        } else {
          return [];
        }
      },
    }),
    changePassword: builder.mutation<void, CurrentUserPasswordUpdate>({
      query: ({ ...patch }) => ({
        url: `/users/current-user/change-password`,
        method: 'POST',
        body: patch,
      }),
    }),
    getCompanyLogo: builder.query<string, number>({
      query: (id: number) => ({
        url: `/users/${id}/logo`,
        responseHandler: (response: Response) => {
          if (response.status === 200) {
            return response.blob();
          }
          return response.json();
        },
      }),
      transformResponse: (response: Blob) => fileToSrc(response),
    }),
    getUserAvatar: builder.query<string, number>({
      query: (id: number) => ({
        url: `/users/${id}/avatar`,
        responseHandler: (response: Response) => {
          if (response.status === 200) {
            return response.blob();
          }
          return Promise.resolve('');
        },
        //if there is any other status than 200, we can use empty string directly
        validateStatus: () => true,
      }),
      providesTags: (result, error, id) => [{ type: 'UserAvatar', id }],
      transformResponse: (response: Blob) => {
        if (!response || response.size === 0) {
          return '';
        }
        return fileToSrc(response);
      },
    }),
    userAvatarConfirm: builder.mutation<string, UserAvatarConfirmApiProps>({
      query: ({ id, confirmations }) => ({
        url: `/users/avatar/confirm?userId=${id}`,
        method: 'POST',
        body: confirmations,
      }),
      transformResponse: (response: GetFieldImageUrlResponse) => response.result,
      invalidatesTags: (result, error, arg) => {
        if (result) {
          return [{ type: 'UserAvatar', id: arg.id }];
        } else {
          return [];
        }
      },
    }),
    addUserToNotificationsGroup: builder.mutation<void, void>({
      query: () => ({
        url: '/users/current-user/add-notification-group',
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useGetLinkedUsersByIdQuery,
  useGetCurrentUserQuery,
  useGetExternalUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useChangePasswordMutation,
  useGetCompanyLogoQuery,
  useGetUserAvatarQuery,
  useUserAvatarConfirmMutation,
  useAddUserToNotificationsGroupMutation,
} = userApi;
