import {
  DropDownNoSelection,
  DropDownResult,
  HfDynamicFieldComponentProps,
  OdinController,
} from '@myosh/odin-components';
import React from 'react';
import TrainingField from './training-field.component';

export default function HfTrainingField({
  name,
  control,
  label,
  value,
  readOnly,
  required,
  onChange,
}: HfDynamicFieldComponentProps<DropDownResult | DropDownNoSelection>) {
  return (
    <OdinController
      name={name as string}
      control={control}
      defaultValue={value}
      onChange={onChange}
      render={({ field: { ref: fieldRef, onChange, value }, fieldState: { error } }) => (
        <TrainingField
          label={label}
          value={value}
          readOnly={readOnly}
          required={required}
          onChange={onChange}
          error={error?.message}
          inputRef={fieldRef}
        />
      )}
    />
  );
}
