import React, { Key, useEffect, useRef } from 'react';
import {
  Avatar,
  FieldLabel,
  JsonDataItem,
  OdinDataSender,
  RegisterPostSubmitFunction,
  RegisterPreSubmitFunction,
  RequiredIndicator,
} from '@myosh/odin-components';
import { AvatarProps } from '@myosh/odin-components/dist/types/components/avatar/avatar.component';
import { useGetUserAvatarQuery, useUserAvatarConfirmMutation } from '../../../redux/services/user';
import OdinLoadingIndicator from '../../common/odin-loading-indicator.component';
import { useTranslation } from 'react-i18next';
import { useAddFileToStagingMutation } from '../../../redux/services/file';

interface UserPictureFieldProps extends AvatarProps {
  userId?: number;
  registerPreSubmitHandler?: RegisterPreSubmitFunction;
  registerPostSubmitHandler?: RegisterPostSubmitFunction;
}

const UserPictureField = ({
  userId,
  readOnly,
  required,
  label,
  name,
  error,
  registerPreSubmitHandler,
  registerPostSubmitHandler,
}: UserPictureFieldProps) => {
  const { t } = useTranslation();
  const handlerRegistered = useRef<boolean>(false);
  const attachmentRef = useRef<string>('');

  const { data, isFetching } = useGetUserAvatarQuery(userId || 0, { skip: !userId });
  const [addFileToStaging] = useAddFileToStagingMutation();
  const [userAvatarConfirm] = useUserAvatarConfirmMutation();

  const uploadAttachment = async (userId: number) => {
    const attachment = attachmentRef.current;
    if (attachment) {
      const fileName = 'user-avatar';
      const file = base64StringToFile(attachment, 'user-avatar');
      const formData = new FormData();
      formData.append('file', file, fileName);
      const fileStaging = await addFileToStaging({ formData }).unwrap();
      if (fileStaging) {
        const avatarConfirm = await userAvatarConfirm({
          id: userId,
          confirmations: { fileName: 'user-avatar', objectKey: fileStaging },
        }).unwrap();
        return avatarConfirm;
      }
    }
  };

  const submitFormHandler = (userId: number, sender: OdinDataSender<JsonDataItem | void>) => {
    uploadAttachment(userId)
      .then((res) => {
        sender.sendFinalData({
          profilePicture: res,
          userId,
        });
      })
      .finally(() => {
        sender.sendFinalData();
      });
  };

  useEffect(() => {
    if (userId && registerPreSubmitHandler && !handlerRegistered.current) {
      registerPreSubmitHandler((sender) => submitFormHandler(userId, sender));
      handlerRegistered.current = true;
    }
  }, [userId, registerPreSubmitHandler]);

  useEffect(() => {
    if (!userId && registerPostSubmitHandler && !handlerRegistered.current) {
      registerPostSubmitHandler((id: Key) => (sender) => submitFormHandler(Number(id), sender));
      handlerRegistered.current = true;
    }
  }, [userId, registerPostSubmitHandler]);

  const onChangeHandler = (data?: string) => {
    attachmentRef.current = data ?? '';
  };

  return (
    <div className="mb-2 mt-2">
      {label && (
        <div className="flex">
          <FieldLabel label={label} name={name} />
          <RequiredIndicator readOnly={readOnly} required={required} />
        </div>
      )}
      {isFetching ? (
        <>{<OdinLoadingIndicator label={t('loading')} />}</>
      ) : (
        <Avatar
          readOnly={readOnly}
          url={data}
          onChange={onChangeHandler}
          error={error}
          name={name}
          exportAsSquare={true}
        />
      )}
    </div>
  );
};

export default UserPictureField;

// This function transforms a base64 string into a File.
function base64StringToFile(
  base64String: string,
  fileName: string = '',
  mimeType: string = 'application/octet-stream'
): File {
  const cleanBase64String = base64String.replace(/^data:[^;]+;base64,/, '');
  const binaryString = window.atob(cleanBase64String);
  const byteArray = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([byteArray], { type: mimeType });
  return new File([blob], fileName, { type: mimeType });
}
