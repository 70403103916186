import { HfDynamicFieldComponentProps, OdinController } from '@myosh/odin-components';
import React from 'react';
import LinkedRecords, { LinkedRecordsProps } from './linked-records.component';
import { LinkedRecordResult } from '../../../@types/linked-records-field';

interface HfLinkedRecordsProps
  extends HfDynamicFieldComponentProps<Array<LinkedRecordResult>, Array<string>>,
    Omit<LinkedRecordsProps, 'name'> {}

export default function HfLinkedRecords(props: HfLinkedRecordsProps) {
  const { name, control, value, onChange, ...rest } = props;
  return (
    <OdinController
      name={name as string}
      control={control}
      defaultValue={value}
      onChange={onChange}
      render={({ field: { onChange: fieldOnChange, value: fieldValue }, fieldState: { error } }) => (
        <LinkedRecords {...rest} value={fieldValue} onChange={fieldOnChange} error={error?.message} />
      )}
    />
  );
}
