import {
  Button,
  ContinuousScrollerSort,
  DataGrid,
  DataGridCellRendererProps,
  DataGridRef,
  DataGridSettings,
  DynamicFieldComponentProps,
  EmptyFieldLabel,
  ErrorLabel,
  FieldLabel,
  JsonData,
  JsonDataItem,
  ModalDialogRef,
  RequiredIndicator,
  RowTemplateType,
} from '@myosh/odin-components';
import cx from 'classnames';
import React, { Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Observable, skipWhile, Subject, take, takeUntil } from 'rxjs';
import { LinkedRecordResult, RecordLinkProps } from '../../../@types/linked-records-field';
import { RecordResult } from '../../../@types/records';
import { ExtendedDynamicFormWorkflowStep, forceAssert, isEmptyObject } from '../../../common/common-functions';
import { extractLinkedRecordsPermissions } from '../../../common/form-permissions-util';
import useActiveRecordRef from '../../../hooks/use-active-record-ref';
import useFormPermissions from '../../../hooks/use-form-permissions';
import { useGetWorkflowStepsQuery, useModulesQuery } from '../../../redux/services/api';
import {
  LinkedRecordRowProps,
  OdinDataGridRowTemplate,
} from '../../dynamic-page/odin-data-grid-row-template.component';
import CreateLinkedRecord from './components/create-linked-record';
import LinkedRecordsSelecting from './components/linked-records-selecting';
import {
  linkedRecordColumnValuePaths,
  transformRecordLinkColumns,
  transformLinkedRecordDataWithKeyTypeId,
  linkedRecordsSortFunction,
  getColumnSortPath,
  linkedRecordsCellRenderer,
} from './linked-records-functions';
import {
  customLinkedRecordsComponents,
  defaultLinkedRecordGridSetting,
} from '../../dynamic-page/default-grid-settings';
import LinkedRecordsActionsCell from './components/linked-records-action-cell';
import { useSaveLinkedRecordsLayoutMutation } from '../../../redux/services/record';
import { showError, showSuccess } from '../../../services/notification.service';
import { useLazyGetRecordByIdQuery } from '../../../redux/services/record';
import HistoryFieldDataCell from '../../data-grid-data-cells/history-field-data-cell';

export interface LinkedRecordsProps
  extends DynamicFieldComponentProps<Array<LinkedRecordResult>, Array<string>>,
    RecordLinkProps {
  isPreview?: boolean;
  recordId?: number;
  isTextualQuestionnaireLinkedRecords?: boolean;
  saveRecord?: () => Observable<RecordResult>;
}

export default function LinkedRecords({
  id,
  label,
  value,
  recordLinkFormat,
  targetModuleFormId,
  buttonCaption,
  autoPopulateEnabled = false,
  showSelectButton = false,
  readOnly,
  required,
  onChange,
  isPreview,
  recordId,
  saveRecord,
  error,
  recordLinkRecordCreationDisabled,
  sourceModuleFormId,
  recordLinkType,
  isTextualQuestionnaireLinkedRecords = false,
}: LinkedRecordsProps) {
  const [linkedRecords, setLinkedRecords] = useState<Array<LinkedRecordResult>>([]);
  const [selectLinkedRecordsDialogVisible, setSelectLinkedRecordsDialogVisible] = useState<boolean>(false);
  const [createLinkedRecordDialogVisible, setCreateLinkedRecordDialogVisible] = useState<boolean>(false);

  const createDialogRef = useRef<ModalDialogRef>(null);
  const createDialogData = useRef<JsonDataItem>();
  const destroySubject = useRef(new Subject<void>());
  const linkedRecordIdsRef = useRef<string[]>();
  const gridRef = useRef<DataGridRef>();

  const { activeRecordReference } = useActiveRecordRef();
  const { data: modules, isLoading: areModulesLoading } = useModulesQuery({});
  const [saveLayout, { isLoading }] = useSaveLinkedRecordsLayoutMutation();
  const { formPermissions } = useFormPermissions(targetModuleFormId);
  const { t } = useTranslation();
  const { data: linkedWorkflowSteps } = useGetWorkflowStepsQuery(targetModuleFormId!, {
    skip: targetModuleFormId === undefined,
  });
  const [getRecordById] = useLazyGetRecordByIdQuery();

  useEffect(() => {
    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
    };
  }, []);

  const linkedRecordsPermissions = useMemo(() => {
    return extractLinkedRecordsPermissions(formPermissions);
  }, [formPermissions]);

  const canCreateOrReadRecord = linkedRecordsPermissions?.create || linkedRecordsPermissions?.read;
  const canOnlySeeDataGrid = !linkedRecordsPermissions?.create && !linkedRecordsPermissions?.read;

  useEffect(() => {
    if (canCreateOrReadRecord || canOnlySeeDataGrid) {
      const newValue = value ?? [];
      if (newValue.length > 0 && newValue[0].id) {
        linkedRecordIdsRef.current = newValue.map((item) => item.id.toString());
        setLinkedRecords(newValue);
      } else if (newValue.length === 0) {
        linkedRecordIdsRef.current = [];
        setLinkedRecords(newValue);
      }
    }
  }, [value, canCreateOrReadRecord, canOnlySeeDataGrid]);

  const isRowClickable = useMemo(() => {
    return !isPreview && linkedRecordsPermissions?.read;
  }, [isPreview, linkedRecordsPermissions]);

  const gridColumns = useMemo(() => {
    return recordLinkFormat
      ? transformRecordLinkColumns(
          recordLinkFormat,
          linkedRecordColumnValuePaths,
          getColumnSortPath,
          linkedRecordsCellRenderer
        )
      : [];
  }, [recordLinkFormat]);

  const gridColumnsWithActions = [
    ...gridColumns,
    {
      id: 0,
      field: '',
      title: '',
      visible: !readOnly,
      cellRenderer: 'LINKED_RECORDS_ACTIONS',
      type: 6,
      disableSort: true,
    },
  ];

  const gridSettings: DataGridSettings = {
    ...defaultLinkedRecordGridSetting,
    rowIsClickable: isRowClickable,
    columns: gridColumnsWithActions,
    autoSizeColumns: true,
    allowCellScrollBar: true,
    sortFunction: (data: JsonData, sorts: Array<ContinuousScrollerSort>) =>
      linkedRecordsSortFunction(data, sorts, gridColumnsWithActions),
    components: {
      ...defaultLinkedRecordGridSetting.components,
      ...customLinkedRecordsComponents,
      LINKED_RECORDS_ACTIONS: {
        CellComponent: (props: DataGridCellRendererProps) => {
          return (
            <LinkedRecordsActionsCell rowId={String(props.rowData.id)} removeLinkedRecord={handleRemoveLinkedRecord} />
          );
        },
      },
      HISTORY: {
        CellComponent: (props: DataGridCellRendererProps, ref: Ref<HTMLDivElement>) => (
          <HistoryFieldDataCell {...props} ref={ref} allowCellScrollBar />
        ),
      },
    },
  };

  const rowTemplate: RowTemplateType = OdinDataGridRowTemplate({
    modules: modules,
    activeRecordReference: activeRecordReference.current,
    isClickable: isRowClickable,
    recordFields: LinkedRecordRowProps,
  });

  const onSelectLinkedRecordsDialogOpen = () => setSelectLinkedRecordsDialogVisible(true);
  const onSelectLinkedRecordsDialogHidden = () => setSelectLinkedRecordsDialogVisible(false);

  const onCreateLinkedRecordDialogOpen = () => {
    if (recordId) {
      //Using cached data
      getRecordById({ id: recordId, keyTypeId: true }, true)
        .unwrap()
        .then((data) => {
          // if it's an existing record, there is no need to save before opening the create linked record dialog
          createDialogData.current = forceAssert<JsonDataItem>(data.original);
          setCreateLinkedRecordDialogVisible(true);
        });
    } else {
      const resultObservable = saveRecord?.();
      if (resultObservable) {
        resultObservable
          .pipe(
            skipWhile((result) => isEmptyObject(forceAssert<Record<string, unknown>>(result))),
            take(1),
            takeUntil(destroySubject.current)
          )
          .subscribe((recordResult: RecordResult) => {
            createDialogData.current = forceAssert<JsonDataItem>(recordResult);
            setCreateLinkedRecordDialogVisible(true);
          });
      }
    }
  };

  const onSaveLayout = () => {
    if (recordId && id) {
      const sortedRecordIds = gridRef.current?.api?.data?.staticData?.map((record) => record.id as number);
      saveLayout({
        recordId,
        linkedRecordsFieldId: id as number,
        linkedRecordIds: sortedRecordIds ?? [],
      })
        .unwrap()
        .then(() => {
          showSuccess(t('save-order-success'));
        })
        .catch(() => showError(t('save-order-failed')));
    }
  };

  const onCreateLinkedRecordDialogHidden = () => setCreateLinkedRecordDialogVisible(false);

  const handleAddLinkedRecords = useCallback(
    (updatedLinkedRecordIds: Array<string>) => {
      onChange?.(updatedLinkedRecordIds);
      saveRecord?.(); // save the Record when a linked record is selected/de-selected (MYOSH-1557)
    },
    [onChange, saveRecord]
  );

  const handleRecordCreated = useCallback(
    (addedRecordId: number) => {
      handleAddLinkedRecords([...(linkedRecordIdsRef.current ?? []), addedRecordId.toString()]);
    },
    [handleAddLinkedRecords]
  );

  const handleRemoveLinkedRecord = useCallback(
    (rowId: string) => {
      const filteredList: string[] = linkedRecordIdsRef.current?.filter((id) => id !== rowId) ?? [];
      onChange?.(filteredList);
      saveRecord?.(); // save the Record when a linked record is removed (MYOSH-5074)
    },
    [onChange, saveRecord]
  );

  const createLinkedRecordShown = useCallback(() => {
    if (createDialogData.current && !isEmptyObject(createDialogData.current)) {
      createDialogRef.current?.dataSender?.sendData({ data: createDialogData.current });
    }
  }, []);

  const transformedData = !isTextualQuestionnaireLinkedRecords
    ? forceAssert<JsonData>(linkedRecords)
    : transformLinkedRecordDataWithKeyTypeId(forceAssert<JsonData>(linkedRecords));

  const hasSelectButton = showSelectButton && linkedRecordsPermissions?.read && !autoPopulateEnabled;
  const hasCreateButton =
    recordLinkRecordCreationDisabled !== true && linkedRecordsPermissions?.create && !autoPopulateEnabled;

  const buttonsContainerStyles = cx('my-2 flex justify-between mx-1 gap-2', {
    'border border-error': error && !readOnly,
  });

  const rowIndicator = useMemo(() => {
    return {
      indicator: (data?: JsonDataItem) => {
        const backgroundColor = linkedWorkflowSteps?.find(
          (step: ExtendedDynamicFormWorkflowStep) => step.label === data?.status
        )?.backgroundColor;
        return <div className="h-4 w-4 rounded" style={{ backgroundColor: backgroundColor }} />;
      },
    };
  }, [linkedWorkflowSteps]);

  const onDataGridRefCreated = (dataGridRef: DataGridRef) => {
    gridRef.current = dataGridRef;
  };

  const canShowGrid = !areModulesLoading && (canCreateOrReadRecord || canOnlySeeDataGrid) && linkedRecords.length > 0;

  return (
    <>
      {label && (
        <div className="flex">
          <FieldLabel label={label} />
          <RequiredIndicator readOnly={readOnly} required={required} />
        </div>
      )}
      {!readOnly && !isPreview && (
        <div className={buttonsContainerStyles}>
          <div className="flex gap-2">
            {hasSelectButton && (
              <Button variant="alternative" classNames="bg-gray-5" onClick={onSelectLinkedRecordsDialogOpen}>
                {t('select')}
              </Button>
            )}
            {hasCreateButton && (
              <Button variant="alternative" classNames="bg-gray-5" onClick={onCreateLinkedRecordDialogOpen}>
                {buttonCaption || t('create')}
              </Button>
            )}
          </div>
          {linkedRecords.length > 0 && !isTextualQuestionnaireLinkedRecords && (
            <div
              className="text-primary-1 leading-5 font-medium bg-transparent cursor-pointer flex items-center justify-center"
              onClick={onSaveLayout}
            >
              {isLoading ? (
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-primary-1"></div>
              ) : (
                t('save-order')
              )}
            </div>
          )}
        </div>
      )}
      {readOnly && (!linkedRecords || linkedRecords.length === 0) && <EmptyFieldLabel />}
      {canShowGrid && (
        <div className="my-2 flex max-h-[40rem]">
          <DataGrid
            ref={(gridRef) => gridRef && onDataGridRefCreated(gridRef)}
            data={transformedData}
            gridSettings={gridSettings}
            rowTemplate={rowTemplate}
            showSettings={false}
            rowIndicator={rowIndicator}
          />
        </div>
      )}
      {selectLinkedRecordsDialogVisible && (
        <LinkedRecordsSelecting
          visible={true}
          hidden={onSelectLinkedRecordsDialogHidden}
          applyCallback={handleAddLinkedRecords}
          sourceModuleFormId={sourceModuleFormId}
          targetModuleFormId={targetModuleFormId}
          recordLinkType={recordLinkType}
          value={linkedRecordIdsRef.current ?? []}
          linkedWorkflowSteps={linkedWorkflowSteps}
        />
      )}
      {targetModuleFormId && (
        <CreateLinkedRecord
          ref={createDialogRef}
          header={t('new-sufix', { sufix: formPermissions?.formCaption })}
          visible={createLinkedRecordDialogVisible}
          formId={targetModuleFormId}
          shown={createLinkedRecordShown}
          hidden={onCreateLinkedRecordDialogHidden}
          onRecordCreated={handleRecordCreated}
          fieldId={id as number}
          parentRecordId={recordId}
          isTextualQuestionnaireLinkedRecords={isTextualQuestionnaireLinkedRecords}
        />
      )}
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </>
  );
}
