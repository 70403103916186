import {
  DynamicForm,
  DynamicFormButtonSetting,
  DynamicFormRef,
  DynamicFormRequiredType,
  DynamicFormSettings,
  JsonDataItem,
  ModalDialog,
} from '@myosh/odin-components';
import i18next from 'i18next';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { RiskLevelsDataResponse } from '../../../@types/risk-matrix-field';
import {
  useCreateNewRiskLevelMutation,
  useDeleteRiskLevelMutation,
  useEditRiskLevelMutation,
} from '../../../redux/services/risk-matrix';
import { forceAssert, intNumberPattern } from '../../../common/common-functions';
import { handleRiskMatrixApiResponse } from '../risk-rating/risk-rating-functions';
import { createCaptionObj } from '../../../common/caption-util';

interface RiskLevelModalProps {
  visible: boolean;
  riskMatrixId: number;
  selectedRiskLevel?: RiskLevelsDataResponse;
  handleModalClose: () => void;
}

const formSettings: DynamicFormSettings = {
  id: 0,
  caption: '',
  fields: [
    {
      id: 'caption',
      fieldType: 'TEXTFIELD',
      fieldName: 'caption',
      caption: i18next.t('name'),
      position: 0,
      required: DynamicFormRequiredType.True,
      permissions: { read: true, edit: true },
    },
    {
      id: 'descriptionCaption',
      fieldType: 'TEXTFIELD',
      fieldName: 'descriptionCaption',
      caption: i18next.t('description'),
      position: 1,
      required: DynamicFormRequiredType.True,
      permissions: { read: true, edit: true },
    },
    {
      id: 'backgroundColor',
      fieldType: 'TEXTFIELD',
      fieldName: 'backgroundColor',
      caption: i18next.t('color'),
      position: 2,
      dynamicProperties: {
        type: 'color',
        inputStyles: '!flex-none',
      },
      required: DynamicFormRequiredType.True,
      permissions: { read: true, edit: true },
    },
    {
      id: 'textColor',
      fieldType: 'TEXTFIELD',
      fieldName: 'textColor',
      caption: i18next.t('text-color'),
      position: 3,
      dynamicProperties: {
        type: 'color',
        inputStyles: '!flex-none',
      },
      required: DynamicFormRequiredType.True,
      permissions: { read: true, edit: true },
    },
    {
      id: 'category',
      fieldType: 'INTFIELD',
      fieldName: 'category',
      dynamicProperties: {
        validations: [
          {
            pattern: intNumberPattern,
            errorLabel: i18next.t('integer-validation-message'),
          },
        ],
      },
      caption: i18next.t('category'),
      position: 4,
      required: DynamicFormRequiredType.True,
      permissions: { read: true, edit: true },
    },
  ],
};

const RiskLevelModal = ({ selectedRiskLevel, riskMatrixId, visible, handleModalClose }: RiskLevelModalProps) => {
  const { t } = useTranslation();
  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const dynamicFormId = useRef(v4());

  const [editRiskLevel] = useEditRiskLevelMutation();
  const [createNewRiskLevel] = useCreateNewRiskLevelMutation();
  const [deleteRiskLevel] = useDeleteRiskLevelMutation();

  const onFormSubmit = (data: JsonDataItem) => {
    const payload = {
      ...data,
      matrixId: riskMatrixId,
      caption: createCaptionObj(data.caption as string),
      descriptionCaption: createCaptionObj(data.descriptionCaption as string),
    };
    selectedRiskLevel
      ? handleRiskMatrixApiResponse(editRiskLevel(forceAssert(payload)).unwrap())
      : handleRiskMatrixApiResponse(createNewRiskLevel(forceAssert(payload)).unwrap());
    handleModalClose();
  };

  const handleDelete = () => {
    if (selectedRiskLevel?.id) handleRiskMatrixApiResponse(deleteRiskLevel(selectedRiskLevel.id).unwrap());
    handleModalClose();
  };

  const confirmationDialogButtons: Array<DynamicFormButtonSetting> = [
    {
      name: 'save',
      text: i18next.t('save'),
      htmlType: 'submit',
      type: 'primary',
      variant: 'alternative',
    },
    {
      name: 'delete',
      text: i18next.t('delete'),
      htmlType: 'button',
      type: 'default',
      variant: 'alternative',
      onClick: handleDelete,
    },
    {
      name: 'discard',
      text: i18next.t('discard'),
      htmlType: 'button',
      type: 'default',
      variant: 'alternative',
      onClick: handleModalClose,
    },
  ];

  return (
    <ModalDialog
      header={`${selectedRiskLevel ? t('edit') : t('new')} ${t('risk-level')} ${selectedRiskLevel?.caption ? ' - ' + selectedRiskLevel?.caption : ''}`}
      visible={visible}
      hidden={handleModalClose}
      minDialogWidth={400}
      closeOnEscape={false}
      buttonsCloseModal={false}
    >
      <DynamicForm
        ref={dynamicFormReference}
        dynamicFormId={dynamicFormId.current}
        data={{
          ...selectedRiskLevel,
        }}
        settings={formSettings}
        readOnly={false}
        buttons={confirmationDialogButtons}
        buttonsLocation={1}
        buttonsPosition={1}
        onSubmit={onFormSubmit}
        validationEnabled={true}
      />
    </ModalDialog>
  );
};

export default RiskLevelModal;
