import React from 'react';
import { Control } from 'react-hook-form';
import { OdinController } from '@myosh/odin-components';
import UserRecordLink, { UserRecordLinkProps } from './user-record-link.component';

interface HfUserRecordLinkProps extends UserRecordLinkProps {
  control?: Control;
  name?: string;
}

export default function HfUserRecordLink({
  control,
  name,
  id,
  label,
  value,
  readOnly,
  required,
  recordLinkFormat,
  buttonCaption,
  showSelectButton = false,
  createExternalUsers = false,
  onChange,
  saveRecord,
}: HfUserRecordLinkProps) {
  return (
    <OdinController
      name={name as string}
      control={control}
      defaultValue={value}
      onChange={onChange}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <UserRecordLink
          id={id}
          label={label}
          value={value}
          readOnly={readOnly}
          required={required}
          recordLinkFormat={recordLinkFormat}
          buttonCaption={buttonCaption}
          showSelectButton={showSelectButton}
          createExternalUsers={createExternalUsers}
          onChange={onChange}
          saveRecord={saveRecord}
          error={error?.message}
        />
      )}
    />
  );
}
