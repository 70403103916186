/**
 * Executes promises in batch requests of 'batchSize' (batchSize=5 by default).
 *
 * All promises resolve, before this method returns.
 *
 * @param promises array of promises to execute
 * @param batchSize the number of promises per batch
 * @return array of promise resolutions.
 */
export async function batchPromises<T>(promises: Array<() => Promise<T>>, batchSize = 5) {
  const total = promises.length;
  const numberOfBatches = Math.ceil(total / batchSize);
  const results = [];

  for (let i = 0; i < numberOfBatches; i++) {
    const batch = [];
    for (let j = 0; j < batchSize && batchSize * i + j < total; j++) {
      const currentPromise = promises[batchSize * i + j];
      batch.push(currentPromise());
    }
    const batchResult = await Promise.allSettled(batch);
    results.push(...batchResult);
  }

  return results;
}

/**
 * Makes a Promise cancellable.
 *
 * As defined by https://github.com/facebook/react/issues/5465#issuecomment-157888325
 *
 * @param promise The Promise to make 'cancellable'
 *
 * @returns A cancelable promise.
 */
export const makeCancelable = (promise: Promise<unknown>) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)));
    promise.catch((error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)));
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};
