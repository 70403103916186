import React from 'react';
import { DynamicFieldComponentProps, OdinController } from '@myosh/odin-components';
import { Control } from 'react-hook-form';
import ScheduleRulesField from './schedule-rules-field';
import { SchedulerRule } from '../../../@types/scheduler-record';
import { DynamicFieldDataTypes } from '@myosh/odin-components/dist/types/components/dynamic-form/dynamic-form.interfaces';

interface HfSchedulerRuleProps extends DynamicFieldComponentProps<SchedulerRule> {
  control: Control;
  formIntFields?: DynamicFieldDataTypes;
}

export default function HfScheduleRules({
  control,
  name = 'rules',
  label,
  readOnly,
  formIntFields,
  onChange,
}: HfSchedulerRuleProps) {
  return (
    <OdinController
      name={name}
      control={control}
      defaultValue={[]}
      onChange={onChange}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <ScheduleRulesField
          name={name}
          label={label}
          value={value}
          error={error as unknown as Array<Record<string, unknown>>}
          readOnly={readOnly}
          formIntFields={formIntFields}
          onChange={onChange}
        />
      )}
    />
  );
}
