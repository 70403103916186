import { DataGridColumnType } from '@myosh/odin-components';
import i18next from '../i18n';

export enum AssignedRecordsFields {
  id = 'id',
  moduleName = 'moduleName',
  formName = 'formName',
  docNo = 'docNo',
  description = 'description',
  status = 'status',
}

export const AssignedRecordsCaptions: { [key in AssignedRecordsFields]: string } = {
  id: i18next.t('id'),
  moduleName: i18next.t('module-name'),
  formName: i18next.t('form-name'),
  docNo: i18next.t('doc-no'),
  description: i18next.t('description'),
  status: i18next.t('status'),
};

type ColumnEntityType = 'MODULE_NAME' | 'FORM_NAME' | 'STATUS';
interface AssignedRecordsColumnSettings {
  type?: DataGridColumnType;
  visible?: boolean;
  isIdField?: boolean;
  searchType?: string;
  dropDownValue?: string;
  dropDownText?: string;
  entityType?: ColumnEntityType;
  width?: number;
  sortField?: string;
}

const multiselectFilterConfig = {
  searchType: 'MultiSelect',
  dropDownValue: 'id',
  dropDownText: 'caption',
};

const textSearchFilterConfig = { type: 1, searchType: 'TextSearch' };

export const assignedRecordsColumnsConfig: { [key in AssignedRecordsFields]?: AssignedRecordsColumnSettings } = {
  moduleName: { ...multiselectFilterConfig, dropDownValue: 'name', entityType: 'MODULE_NAME', sortField: 'moduleName' },
  formName: { ...multiselectFilterConfig, entityType: 'FORM_NAME', sortField: 'formName' },
  docNo: { ...textSearchFilterConfig, sortField: 'docNo' },
  description: { ...textSearchFilterConfig, width: 300, sortField: 'description' },
  status: { ...multiselectFilterConfig, dropDownValue: 'name', entityType: 'STATUS', sortField: 'status' },
  id: { type: 1, visible: false, isIdField: true },
};
