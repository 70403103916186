import { useState, useEffect } from 'react';
import { useGetLinkedUsersByIdQuery } from '../redux/services/user';
import { UserStructure } from '../@types/users';
import { FieldSorts } from '../@types/common';

const mapLinkedRecordIds = (linkedRecordIds?: Array<string | number>) => {
  return linkedRecordIds?.map((linkedRecordId) => {
    return Number(linkedRecordId);
  });
};

/**
 * A hook that fetches records of linked user fields, by linkedRecordIds and fields.
 * @param {string} fields
 * @param {number} page
 * @param {number} pageSize
 * @param {Array<number>} linkedRecordIds
 * @param {FieldSorts} sort
 * @returns {{linkedRecords: Array<UserResult>, isFetching: boolean}}
 */
const useFetchLinkedUsers = (
  fields: string,
  page: number,
  pageSize: number,
  linkedRecordIds?: Array<number>,
  sort?: FieldSorts
) => {
  const [linkedUsersRecords, setLinkedUsersRecords] = useState<Array<UserStructure>>([]);

  const { data: linkedUsersData, isFetching } = useGetLinkedUsersByIdQuery(
    { fields, linkedRecordIds: mapLinkedRecordIds(linkedRecordIds), page, pageSize, sort },
    {
      skip:
        linkedRecordIds === undefined ||
        linkedRecordIds.length === 0 ||
        fields === undefined ||
        page === undefined ||
        pageSize === undefined,
    }
  );

  useEffect(() => {
    if (linkedUsersData && !isFetching) {
      setLinkedUsersRecords(linkedUsersData);
    }
  }, [linkedUsersData, isFetching]);

  return {
    linkedUsersRecords,
    isFetching,
  };
};

export default useFetchLinkedUsers;
