import React, { Ref, forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from 'react';
import { FormTitle } from '../../form/form-title.component';
import { RiskMatrixPermissions } from '../../../common/user.permissions';
import useProfileData from '../../../hooks/use-profile-data';
import { cloneDeep, debounce } from 'lodash';
import { v4 } from 'uuid';
import {
  DataGridRef,
  DynamicFormButtonSetting,
  DynamicForm,
  DynamicFormRef,
  JsonDataItem,
  ModalDialogRef,
  ModalDialog,
} from '@myosh/odin-components';
import { ActiveRecordContextProps } from '../../active-record/active-record.component';
import { FormLoading } from '../../form/form-loading.component';
import { BehaviorSubject, Subject, skip, take, takeUntil } from 'rxjs';
import { getDataGridReference } from '../../../services/data-grid.service';
import { ExtendedDynamicFormSettings, forceAssert } from '../../../common/common-functions';
import { saveModalButtons } from '../../../common/common-administration-utils';
import { archieveButton, getArchiveRestoreMessages, restoreButton, saveButton } from '../../../pages/admin/admin-utils';
import usePerformingSaveToast from '../../../hooks/use-performing-save-toast';
import { promiseToast, showError, showSuccess } from '../../../services/notification.service';
import { useTranslation } from 'react-i18next';
import useDynamicFormNotifier from '../../../hooks/use-dynamic-form-notifier';
import CloseActiveItemButton from '../../common/close-active-item-button';
import { getRiskMatrixFormSettings } from './risk-matrix-form-settings';
import { useAppDispatch } from '../../../redux/hooks';
import { RiskMatrixItem, RiskMatrixMutationResponse } from '../../../@types/risk-matrix';
import {
  riskMatrixApi,
  useGetRiskMatrixByIdQuery,
  useUpdateRiskMatrixMutation,
} from '../../../redux/services/risk-matrix';
import { useApiLogger } from '../../../hooks/use-api-logger';
import { useUserAccess } from '../../../hooks/use-user-access';

export interface RiskMatrixDetailsProps {
  riskMatrixId: number;
  title: string;
  isNewRiskMatrix?: boolean;
  onClose: (id: string) => void;
  panelSettings?: BehaviorSubject<ActiveRecordContextProps>;
  onCloseActiveRecordConfirmationModalRef?: ModalDialogRef | null;
}
interface RiskMatrixDetailsRef {
  submitForm?: () => void;
}

const RiskMatrixDetails = (props: RiskMatrixDetailsProps, ref: Ref<RiskMatrixDetailsRef>) => {
  const {
    riskMatrixId,
    title,
    isNewRiskMatrix = false,
    onClose,
    panelSettings,
    onCloseActiveRecordConfirmationModalRef,
  } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const log = useApiLogger();

  const [riskMatrix, setRiskMatrix] = useState<RiskMatrixItem>();
  const [panelContextProps, setPanelContextProps] = useState<ActiveRecordContextProps>();
  const [formButtons, setFormButtons] = useState<Array<DynamicFormButtonSetting>>();
  const [formSettings, setFormSettings] = useState<ExtendedDynamicFormSettings>();

  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const saveModalReference = useRef<ModalDialogRef>(null);
  const dataGridRef = useRef<DataGridRef>();
  const destroySubject = useRef(new Subject<void>());
  const dynamicFormId = useRef(v4());
  const closeOnModalSaveRef = useRef(false);

  const { profileData: { user: userData } = {} } = useProfileData();

  const { readOnly, editAccess, setReadOnly } = useUserAccess({
    initialAccess: isNewRiskMatrix,
    userData,
    permissions: {
      MODIFY: [RiskMatrixPermissions.RISK_MATRIX_MODIFY],
      READ: RiskMatrixPermissions.RISK_MATRIX_READ,
    },
  });

  const { data, isLoading, isFetching } = useGetRiskMatrixByIdQuery(riskMatrixId, {
    skip: riskMatrixId === undefined,
  });
  const [updateRiskMatrix] = useUpdateRiskMatrixMutation();

  const { notifyDirty, notifySaveSucceeded, notifySaveFailed } = useDynamicFormNotifier(dynamicFormId.current);

  const { showPerformingSaveToast, hidePerformingSaveToast } = usePerformingSaveToast({
    shouldFreezeActiveRecord: true,
    disabled: panelContextProps?.batchSaveInProgress,
  });

  useEffect(() => {
    if (!isFetching) {
      setRiskMatrix(cloneDeep(data));
      const newFormButtons: Array<DynamicFormButtonSetting> = [];
      if (data?.archived) {
        newFormButtons.push({ ...restoreButton, onClick: () => onArchieveRiskMatrix(false) });
      } else {
        newFormButtons.push(saveButton);
        if (!isNewRiskMatrix) {
          newFormButtons.push({ ...archieveButton, onClick: () => onArchieveRiskMatrix(true) });
        }
      }
      if (!editAccess) {
        setFormButtons(newFormButtons.map((button) => ({ ...button, disabled: true })));
      } else {
        setFormButtons(newFormButtons);
      }
    }
  }, [data, isFetching, editAccess]);

  useEffect(() => {
    panelSettings?.pipe(skip(1), takeUntil(destroySubject.current)).subscribe((settings) => {
      setPanelContextProps(settings);
    });

    onCloseActiveRecordConfirmationModalRef
      ?.subscribeToDialogResult()
      ?.pipe(takeUntil(destroySubject.current))
      .subscribe((result) => {
        if (result.buttonName === 'save' && dynamicFormReference.current?.formIsDirty()) {
          dynamicFormReference.current?.submitForm(true);
        } else if (result.buttonName === 'discard') {
          onClose(dynamicFormId.current);
        }
      });

    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((gridRef) => {
        if (gridRef) {
          dataGridRef.current = gridRef;
        }
      });

    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    submitForm: dynamicFormReference.current?.submitForm,
  }));

  const showErrorToast = () => {
    showError(t('user-details-save-failed'));
    notifySaveFailed(dynamicFormId.current);
  };

  const handleSaveFailedNotifications = useCallback((error: unknown) => {
    hidePerformingSaveToast();
    showErrorToast();
    log('Failed to update risk matrix', { error: error });
  }, []);

  const onArchieveRiskMatrix = useCallback(
    (archive: boolean) => {
      if (data) {
        promiseToast(
          updateRiskMatrix({ ...data, archived: archive })
            .unwrap()
            .then(() => {
              onClose(dynamicFormId.current);
              dataGridRef.current?.api.data.getDataOverwritePageCache();
              dispatch(riskMatrixApi.util.invalidateTags([{ type: 'RiskMatrix', id: 'LIST' }]));
            })
            .catch((error) => handleSaveFailedNotifications(error)),
          getArchiveRestoreMessages(archive)
        );
      }
    },
    [data]
  );

  useEffect(() => {
    if (riskMatrix) {
      setFormSettings(getRiskMatrixFormSettings(readOnly, riskMatrix.id));
      if (riskMatrix.archived) {
        setReadOnly(true);
      }
    }
  }, [readOnly, riskMatrix]);

  const showSaveToast = () => {
    if (!panelContextProps?.batchSaveInProgress) {
      showSuccess(t('record-saved'));
    }
    notifySaveSucceeded(dynamicFormId.current);
    closeOnModalSaveRef.current = true;
    if (!riskMatrixId || closeOnModalSaveRef.current) {
      onClose(dynamicFormId.current);
    }
  };

  const onFormSubmit = (data: JsonDataItem) => {
    if (riskMatrixId && riskMatrix) {
      saveRiskMatrixData({ id: riskMatrixId, ...data }, (response) => {
        const convertedResponse = forceAssert<RiskMatrixMutationResponse>(response);
        if (convertedResponse?.data?.result) {
          dataGridRef.current?.api.data.recordChanged(riskMatrixId);
          hidePerformingSaveToast();
          showSaveToast();
        } else if (convertedResponse?.error) {
          hidePerformingSaveToast();
          handleSaveFailedNotifications(convertedResponse);
        }
      });
    }
  };

  const saveRiskMatrixData = (
    riskMatrixData: Partial<RiskMatrixItem>,
    successCallback: (response?: unknown) => void
  ) => {
    showPerformingSaveToast();
    if (riskMatrixData.id) {
      updateRiskMatrix(riskMatrixData)
        .then(successCallback)
        .catch((error) => {
          handleSaveFailedNotifications(error);
        });
    }
  };

  const onCloseRiskMatrixDetails = () => {
    if (!dynamicFormReference.current?.formIsDirty()) {
      onClose(dynamicFormId.current);
    } else {
      saveModalReference.current
        ?.show()
        ?.pipe(take(1))
        .subscribe((result) => {
          if ('save' === result.buttonName) {
            const data = dynamicFormReference.current?.getData() || {};
            if (riskMatrixId) {
              saveRiskMatrixData({ id: riskMatrixId, ...data }, (response) => {
                const convertedResponse = forceAssert<RiskMatrixMutationResponse>(response);
                if (convertedResponse?.data?.result) {
                  dataGridRef.current?.api.data.recordChanged(riskMatrixId);
                  hidePerformingSaveToast();
                  showSaveToast();
                } else if (convertedResponse?.error) {
                  handleSaveFailedNotifications(convertedResponse?.error);
                }
              });
            }
          } else if ('discard' === result.buttonName) {
            onClose(dynamicFormId.current);
          }
        });
    }
  };

  const onFieldChanged = debounce(() => notifyDirty(dynamicFormReference.current), 500);

  return (
    <>
      <div className="flex flex-shrink-0 flex-col">
        <div className="flex">
          <FormTitle title={title} />
          {!isLoading && (
            <div className="flex min-w-min justify-end">
              <CloseActiveItemButton onClick={onCloseRiskMatrixDetails} />
            </div>
          )}
        </div>
      </div>
      <div className="admin-form-default">
        {!formSettings && <FormLoading />}
        {formSettings && (
          <DynamicForm
            ref={dynamicFormReference}
            dynamicFormId={dynamicFormId.current}
            data={forceAssert<JsonDataItem>(riskMatrix) || {}}
            settings={formSettings}
            buttons={formButtons}
            buttonsLocation={0}
            readOnly={readOnly}
            buttonsPosition={1}
            onSubmit={onFormSubmit}
            onFieldChanged={onFieldChanged}
          />
        )}
      </div>
      <ModalDialog ref={saveModalReference} header={t('save-unsaved-changes')} buttons={saveModalButtons}>
        <div>{t('save-message')}</div>
      </ModalDialog>
    </>
  );
};
export default forwardRef(RiskMatrixDetails);
