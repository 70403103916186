import React from 'react';
import { Button } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import { RecordResult } from '../../../@types/records';

interface CustomRemoveCellProps {
  rowId: string;
  removeRecord: (rowId: string) => void;
  version?: number;
  recordVersions?: RecordResult[];
}

const RemoveVersionCell = ({ rowId, removeRecord, version, recordVersions }: CustomRemoveCellProps) => {
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    removeRecord(rowId);
  };

  return (
    // NOTE: if modifying the padding 'pl-2', please remember to update all other cells
    recordVersions?.[0].version === version ? (
      <></>
    ) : (
      <div className="flex h-full items-center justify-center py-1 pl-2">
        <Button type="text" onClick={handleClick} classNames="hover:bg-primary-5">
          {t('delete')}
        </Button>
      </div>
    )
  );
};

export default RemoveVersionCell;
