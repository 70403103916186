import { OdinIcon, OdinIconType } from '@myosh/odin-components';
import React from 'react';

interface OdinLoadingIndicatorProps {
  label?: string;
}

/** An alternative loading indicator */
const OdinLoadingIndicator = ({ label }: OdinLoadingIndicatorProps) => {
  return (
    <div className="flex w-full items-center justify-center overflow-hidden">
      <OdinIcon type={OdinIconType.Line} icon="Refresh" spin={true} />
      {label && <span className="pl-1">{label}</span>}
    </div>
  );
};

export default OdinLoadingIndicator;
