import React from 'react';
import {
  DataSearchLocation,
  DropDownMultiSelect,
  DropDownResult,
  DynamicFieldComponentProps,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import { LookupTypeFieldProps } from '../../../@types/forms';
import usePersonFieldOptions from './use-person-field-options';
import { Control } from 'react-hook-form';

const TEXT_FIELD = 'text';
const VALUE_FIELD = 'value';

export interface MultiselectPersonFieldProps
  extends DynamicFieldComponentProps<DropDownResult[]>,
    LookupTypeFieldProps {
  control?: Control;
  shouldUseCreatePortal?: boolean;
}

export default function MultiselectPersonField({
  id,
  control,
  onChange,
  label,
  value,
  readOnly,
  required,
  error,
  inputRef,
  shouldUseCreatePortal = false,
  lookupType,
  lookupKeyId,
  lookupHierarchyId,
}: MultiselectPersonFieldProps) {
  const { setOptions, onSelectorVisible, subscriberRef, dataRequestedRef, dropDownRef } = usePersonFieldOptions(
    TEXT_FIELD,
    id,
    control,
    lookupType,
    lookupKeyId,
    lookupHierarchyId
  );

  const getOptionsData = {
    getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
      dataRequestedRef.current = true;
      subscriberRef.current = subscriber;
      setOptions(options);
    },
  };

  const onValueChange = (fieldValue: DropDownResult[]) => {
    if (onChange && !!fieldValue) {
      onChange(fieldValue);
    }
  };

  return (
    <DropDownMultiSelect
      ref={dropDownRef}
      label={label}
      textField={TEXT_FIELD}
      valueField={VALUE_FIELD}
      value={value ?? []}
      data={getOptionsData}
      onChange={(value) => onValueChange(value as DropDownResult[])}
      readOnly={readOnly}
      required={required}
      allowSearch={true}
      shouldUseCreatePortal={shouldUseCreatePortal}
      filterLocation={DataSearchLocation.Api}
      useChips={true}
      error={error}
      inputRef={inputRef}
      allowDuplicateCall={true}
      onSelectorVisible={onSelectorVisible}
    />
  );
}
