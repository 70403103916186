import React from 'react';
import { DataGridCellRendererProps, DataGridDataCell, useTestTag } from '@myosh/odin-components';
import { useRiskRatingMatrixConfigQuery } from '../../redux/services/risk-matrix';
import { RiskMatrixDataItem, RiskMatrixDataResponse } from '../../@types/risk-matrix-field';
import { forceAssert } from '../../common/common-functions';
import { EntityReference } from '../../@types/views';

interface RiskMatrixRowData {
  id: number;
  consequence: RiskMatrixDataItem;
  likelihood: RiskMatrixDataItem;
  riskLevel: RiskMatrixDataItem;
  category: number;
  displayValue: string;
}

const getRiskRatingCellAttributes = (data: RiskMatrixDataResponse[], id?: number) => {
  for (let i = 0; i < data.length; i++) {
    const element = data[i];
    if (element.id === id) {
      return {
        color: element.riskLevel.textColor,
        backgroundColor: element.riskLevel.backgroundColor,
      };
    }
  }
};

const RiskRatingDataCell = ({ column, rowData }: DataGridCellRendererProps) => {
  const columnEntityReference = forceAssert<EntityReference>(column.customDataProperties);
  const { entityId, displayRecordListColour } = columnEntityReference;
  const { data: riskMatrixData } = useRiskRatingMatrixConfigQuery();
  const riskMatrixRowData = forceAssert<RiskMatrixRowData>(rowData[entityId]);

  const tagCreator = useTestTag('risk-rating-cell');
  return riskMatrixRowData?.displayValue ? (
    <div
      ref={tagCreator('')}
      style={
        displayRecordListColour
          ? riskMatrixData && getRiskRatingCellAttributes(riskMatrixData, riskMatrixRowData?.id)
          : { backgroundColor: 'transparent' }
      }
    >
      <DataGridDataCell value={riskMatrixRowData?.displayValue} width={column.width} />
    </div>
  ) : null;
};

export default RiskRatingDataCell;
