import { AnySchema, Lazy, Reference } from 'yup';
import * as yup from 'yup';
import {
  DynamicFormRequiredType,
  fieldValidationErrorMessage,
  JsonDataItem,
  RadioGroupResult,
} from '@myosh/odin-components';
import { CombinedFieldType, DynamicFormHierarchyType } from '../../@types/forms';
import { QuestionnaireFieldValue } from '../../@types/questionnaire';
import i18next from '../../i18n';

export function validateByFieldType(
  fieldLabel: string,
  fieldType: CombinedFieldType,
  fieldDynamicProperties?: JsonDataItem
): AnySchema | Reference | Lazy<AnySchema, unknown> | undefined {
  switch (fieldType) {
    case 'LINK':
      return yup.string().url(i18next.t('url-validation-message')).required(fieldValidationErrorMessage(fieldLabel));
    case 'PERSONFIELD':
    case 'TRAINING':
      return yup
        .object()
        .required(fieldValidationErrorMessage(fieldLabel))
        .test(
          'has-value',
          () => fieldValidationErrorMessage(fieldLabel),
          (value) =>
            value
              ? 'value' in value && 'text' in value && typeof value.text === 'string' && value.value !== null
              : false
        );
    case 'MULTISELECTPERSONFIELD':
      return yup
        .array()
        .required(fieldValidationErrorMessage(fieldLabel))
        .min(1, fieldValidationErrorMessage(fieldLabel));
    case 'READERS':
    case 'RECORDLINK':
    case 'REVERSE_RECORD_LINK':
    case 'USER_RECORDLINK':
      return yup
        .array()
        .required(fieldValidationErrorMessage(fieldLabel))
        .min(1, fieldValidationErrorMessage(fieldLabel))
        .label(fieldLabel);
    case 'RISKRATING':
      return yup.object().required(fieldValidationErrorMessage(fieldLabel));
    case 'HIERARCHY':
      let hierarchyTypes = (fieldDynamicProperties?.hierarchyTypes as Array<DynamicFormHierarchyType>) ?? [];
      if (fieldDynamicProperties) {
        hierarchyTypes = hierarchyTypes.filter((hierarchy) => hierarchy?.mandatoryTypeConfig);
      }
      return yup.object(
        hierarchyTypes.reduce(
          (schema, key) => ({
            ...schema,
            [key.caption]: yup.object({
              id: yup.number().required(i18next.t('is-mandatory-hierarchy', { hierarchy: `'${key.caption}'` })),
            }),
          }),
          {}
        )
      );
    case 'TEXTUAL_QUESTIONNAIRE':
    case 'NUMERIC_QUESTIONNAIRE':
      return yup
        .object({
          value: yup.array().of(
            yup.object({
              observations: yup
                .string()
                .test('has-observations', i18next.t('observations-validation-message'), (value, context) => {
                  const parent = context.parent;
                  const fieldValidations = _getQuestionnaireFieldValidationsConfig(fieldDynamicProperties);
                  for (let i = 0, length = fieldValidations.length; i < length; i++) {
                    if (fieldValidations[i].allowObservations && fieldValidations[i].answerId === parent.id) {
                      const valid = Boolean(value && value.length > 0);
                      return valid
                        ? valid
                        : context.createError({
                            message: {
                              questionId: parent.questionId,
                              message: i18next.t('observations-validation-message'),
                            },
                          });
                    }
                  }
                  return true;
                }),
              attachments: yup
                .array()
                .test('has-attachments', i18next.t('attachments-validation-message'), (value, context) => {
                  const parent = context.parent; // the answer value
                  const fieldValidations = _getQuestionnaireFieldValidationsConfig(fieldDynamicProperties);
                  for (let i = 0, length = fieldValidations.length; i < length; i++) {
                    if (fieldValidations[i].allowMedia && fieldValidations[i].answerId === parent.id) {
                      const valid =
                        Boolean(value && value.length > 0) ||
                        // Due to the dynamic form data proxy, the validation for a newly saved record is missing the 'attachments' property.
                        // Therefore, we need to also check the answer files/images explicitly to ensure a correct validation status.
                        Boolean(parent.files && parent.files.length > 0) ||
                        Boolean(parent.images && parent.images.length > 0);

                      return valid
                        ? valid
                        : context.createError({
                            message: {
                              questionId: parent.questionId,
                              message: i18next.t('attachments-validation-message'),
                            },
                          });
                    }
                  }
                  return true;
                }),
              linkedRecords: yup
                .array()
                .test('has-linked-records', i18next.t('linked-records-validation-message'), (value, context) => {
                  const parent = context.parent;
                  const fieldValidations = _getQuestionnaireFieldValidationsConfig(fieldDynamicProperties);
                  for (let i = 0, length = fieldValidations.length; i < length; i++) {
                    if (fieldValidations[i].allowLinkedRecords && fieldValidations[i].answerId === parent.id) {
                      const valid = Boolean(value && value.length > 0);
                      return valid
                        ? valid
                        : context.createError({
                            message: {
                              questionId: parent.questionId,
                              message: i18next.t('linked-records-validation-message'),
                            },
                          });
                    }
                  }
                  return true;
                }),
            })
          ),
        })
        .test('has-answer', fieldValidationErrorMessage(fieldLabel), (value: unknown) => {
          const questionnaireFieldConfiguration = fieldDynamicProperties?.configuration as JsonDataItem;

          if (questionnaireFieldConfiguration.required === DynamicFormRequiredType.Partial) {
            // If the Questionnaire field is partially required, it means that some of the more detail fields are requried,
            // so the 'value' validation can be skipped as it's not needed
            return true;
          }

          if (value) {
            const questionnaireValue = value as QuestionnaireFieldValue;
            const questions = questionnaireFieldConfiguration?.questions as [];
            return questions?.length === questionnaireValue?.value?.length;
          }

          return false;
        })
        .nullable(); //ensure you dont have to complete TQ field when not mandatory
    case 'RECORD_OPTIONGROUP':
      return yup.object<RadioGroupResult>().required(fieldValidationErrorMessage(fieldLabel));
    case 'RECORD_COMBOBOX':
      return yup
        .object()
        .required(fieldValidationErrorMessage(fieldLabel))
        .test(
          'has-value',
          () => fieldValidationErrorMessage(fieldLabel),
          (value) => (value ? 'text' in value && typeof value.text === 'string' : false)
        );
    case 'RECORD_MULTISELECTFIELD':
      return yup
        .array()
        .required(fieldValidationErrorMessage(fieldLabel))
        .min(1, fieldValidationErrorMessage(fieldLabel));
    case 'RECORD_MULTISELECTCHECKBOX':
      return yup
        .array()
        .required(fieldValidationErrorMessage(fieldLabel))
        .min(1, fieldValidationErrorMessage(fieldLabel));
    case 'RECORD_TWINCOLUMNSELECT':
      return yup
        .array()
        .required(fieldValidationErrorMessage(fieldLabel))
        .min(1, fieldValidationErrorMessage(fieldLabel))
        .label(fieldLabel);
  }
}

const _getQuestionnaireFieldValidationsConfig = (fieldDynamicProperties?: JsonDataItem) => {
  const questionnaireFieldConfiguration = fieldDynamicProperties?.configuration as JsonDataItem;

  const fieldValidations =
    questionnaireFieldConfiguration?.fieldValidations && Array.isArray(questionnaireFieldConfiguration.fieldValidations)
      ? Array.from(questionnaireFieldConfiguration.fieldValidations)
      : [];

  return fieldValidations;
};
