import { useEffect, useState } from 'react';
import { useGetAllFormsUserViewConfigurationsQuery, useGetViewConfigsQuery } from '../redux/services/view';
import { ViewConfig } from '../@types/views';

// A hook that provides the user view configurations
const useViewConfigs = (formId?: number, isVisible = true, moduleId?: number, isAllForms = false) => {
  const [viewConfigs, setViewConfigs] = useState<Array<ViewConfig>>();
  const [lastUsed, setLastUsed] = useState<ViewConfig>();

  const { data, isLoading, isFetching } = useGetViewConfigsQuery(
    {
      formId: formId,
    },
    { skip: formId === undefined || formId === 0 || !isVisible || isAllForms === true }
  );

  const {
    data: allFormsData,
    isLoading: isAllFormsLoading,
    isFetching: isAllFormsFetching,
  } = useGetAllFormsUserViewConfigurationsQuery(Number(moduleId), {
    skip: moduleId === undefined || isAllForms === false,
  });

  useEffect(() => {
    if (data && !isLoading && !isFetching && !isAllForms) {
      setViewConfigs(data);
      const sortedViews = [...data].sort((a, b) => new Date(b.lastUsed).getTime() - new Date(a.lastUsed).getTime());
      setLastUsed(sortedViews[0]);
    }
  }, [data, isLoading, isFetching, isAllForms]);

  useEffect(() => {
    if (allFormsData && !isAllFormsLoading && !isAllFormsFetching && isAllForms) {
      setViewConfigs(allFormsData);
      const sortedAllFormsViews = [...allFormsData].sort(
        (a, b) => new Date(b.lastUsed).getTime() - new Date(a.lastUsed).getTime()
      );
      setLastUsed(sortedAllFormsViews[0]);
    }
  }, [isAllFormsFetching, isAllFormsLoading, allFormsData, isAllForms]);

  return {
    data: viewConfigs,
    isLoading: isLoading || isFetching || isAllFormsFetching || isAllFormsLoading,
    lastUsed: lastUsed,
  };
};

export default useViewConfigs;
