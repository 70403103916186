import React, { useEffect, useMemo, useRef, useState, forwardRef, useImperativeHandle, Key, Ref } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { BehaviorSubject, Subject, skip, take, takeUntil } from 'rxjs';
import { t } from 'i18next';

import useProfileData from '../../../hooks/use-profile-data';
import usePerformingSaveToast from '../../../hooks/use-performing-save-toast';
import useDynamicFormNotifier from '../../../hooks/use-dynamic-form-notifier';
import { useGetRuleByIdQuery } from '../../../redux/services/rule';

import { DropDownValue } from '../../../@types/common';
import { RulesPermissions } from '../../../common/user.permissions';
import CloseActiveItemButton from '../../common/close-active-item-button';
import { getRuleFormSettings } from './rule-form-settings';
import {
  DataGridRef,
  DynamicFormButtonSetting,
  DynamicForm,
  DynamicFormRef,
  ModalDialogRef,
  OdinDataRetrieval,
  OdinDataSender,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  JsonDataItem,
  DynamicFormFieldType,
  ModalDialog,
} from '@myosh/odin-components';
import { ActiveRecordContextProps } from '../../active-record/active-record.component';
import { FormLoading } from '../../form/form-loading.component';
import { FormTitle } from '../../form/form-title.component';
import { getDataGridReference } from '../../../services/data-grid.service';
import { showSuccess } from '../../../services/notification.service';
import { Rule } from '../../../@types/rules';
import { getRulesFieldOptionsData } from './rule-utils';
import { saveModalButtons } from '../../../common/common-administration-utils';
import { useUserAccess } from '../../../hooks/use-user-access';

export interface RuleDetailsProps {
  ruleId: number;
  title: string;
  moduleId: number;
  editable?: boolean;
  onClose: (id: string) => void;
  panelSettings?: BehaviorSubject<ActiveRecordContextProps>;
  onCloseActiveRecordConfirmationModalRef?: ModalDialogRef | null;
}

export interface RuleDetailsRef {
  submitForm?: () => void;
}

const formButtons: Array<DynamicFormButtonSetting> = [
  {
    name: 'save',
    text: t('save'),
    htmlType: 'submit',
    type: 'primary',
    variant: 'alternative',
  },
];

const RuleDetails = (
  { ruleId, title, onClose, panelSettings, onCloseActiveRecordConfirmationModalRef }: RuleDetailsProps,
  ref: Ref<RuleDetailsRef>
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [rule, setRule] = useState<Rule>();
  const [ruleType, setRuleType] = useState<JsonDataItem>();
  const [triggerType, setTriggerType] = useState<JsonDataItem>();
  const [moduleFormId, setModuleFormId] = useState<number>();
  const [moduleId, setModuleId] = useState<number>();
  const [panelContextProps, setPanelContextProps] = useState<ActiveRecordContextProps>();

  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const saveModalReference = useRef<ModalDialogRef>(null);
  const dataGridRef = useRef<DataGridRef>();
  const destroySubject = useRef(new Subject<void>());
  const dynamicFormId = useRef(v4());
  const closeOnModalSaveRef = useRef(false);

  const { profileData: { user: userData } = {} } = useProfileData();

  const { data, isLoading, isFetching } = useGetRuleByIdQuery(ruleId);

  const { notifyDirty, notifySaveSucceeded } = useDynamicFormNotifier(dynamicFormId.current);
  const { hidePerformingSaveToast } = usePerformingSaveToast({
    shouldFreezeActiveRecord: true,
    disabled: panelContextProps?.batchSaveInProgress,
  });

  const { readOnly, editAccess } = useUserAccess({
    userData,
    permissions: {
      MODIFY: [RulesPermissions.AUTO_RULE],
    },
  });

  useEffect(() => {
    if (data && !isLoading && !isFetching) {
      setRule(data);
      setRuleType(data.autoRuleType);
      setTriggerType(data.autoRuleTrigger);
      setModuleFormId(data.moduleForm?.value as number);
      setModuleId(data.module?.value as number);
    }
  }, [data, isLoading, isFetching]);

  const formSettings = useMemo(() => {
    return getRuleFormSettings(editAccess, moduleFormId, ruleType, triggerType);
  }, [editAccess, moduleFormId, ruleType, triggerType]);

  useEffect(() => {
    panelSettings?.pipe(skip(1), takeUntil(destroySubject.current)).subscribe((settings) => {
      setPanelContextProps(settings);
    });

    onCloseActiveRecordConfirmationModalRef
      ?.subscribeToDialogResult()
      ?.pipe(takeUntil(destroySubject.current))
      .subscribe((result) => {
        if (result.buttonName === 'save' && dynamicFormReference.current?.formIsDirty()) {
          dynamicFormReference.current?.submitForm(true);
        } else if (result.buttonName === 'discard') {
          onClose(dynamicFormId.current);
        }
      });

    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((gridRef) => {
        if (gridRef) {
          dataGridRef.current = gridRef;
        }
      });

    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    submitForm: dynamicFormReference.current?.submitForm,
  }));

  const dataRetrieval: OdinDataRetrieval = useMemo(() => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if ('OPTIONGROUP' === options?.fieldType || 'COMBOBOX' === options?.fieldType) {
          options.customProperties = {
            ...options.customProperties,
            moduleId: moduleId,
            formId: moduleFormId,
          };
          await getRulesFieldOptionsData(subscriber, options, dispatch);
        } else {
          subscriber.sendData();
        }
      },
    };
  }, [moduleId]);

  const onFormSubmit = () => {
    showSaveToast();
  };

  const onCloseRuleDetails = () => {
    if (!dynamicFormReference.current?.formIsDirty()) {
      onClose(dynamicFormId.current);
    } else {
      saveModalReference.current
        ?.show()
        ?.pipe(take(1))
        .subscribe((result) => {
          if ('save' === result.buttonName) {
            hidePerformingSaveToast();
            showSaveToast();
          } else if ('discard' === result.buttonName) {
            onClose(dynamicFormId.current);
          }
        });
    }
  };

  const showSaveToast = () => {
    if (!panelContextProps?.batchSaveInProgress) {
      showSuccess(t('record-saved'));
    }
    notifySaveSucceeded(dynamicFormId.current);
    closeOnModalSaveRef.current = true;
    if (!ruleId || closeOnModalSaveRef.current) {
      onClose(dynamicFormId.current);
    }
  };

  const onFieldChanged = (fieldId: Key, fieldName: string, fieldType: DynamicFormFieldType, fieldValue: unknown) => {
    if (fieldId === 'module') {
      setModuleId(Number((fieldValue as DropDownValue).value));
    } else if (fieldId === 'autoRuleType') {
      setRuleType(fieldValue as JsonDataItem);
    } else if (fieldId === 'autoRuleTrigger') {
      setTriggerType(fieldValue as JsonDataItem);
    }

    notifyDirty(dynamicFormReference.current);
  };

  return (
    <>
      <div className="flex flex-shrink-0 flex-col">
        <div className="flex">
          <FormTitle title={title} />
          <div className="flex min-w-min justify-end">
            <CloseActiveItemButton onClick={onCloseRuleDetails} />
          </div>
        </div>
      </div>
      <div className="admin-form-default">
        {!rule && <FormLoading />}
        {formSettings && (
          <DynamicForm
            ref={dynamicFormReference}
            dynamicFormId={dynamicFormId.current}
            data={rule}
            dataRetrieval={dataRetrieval}
            settings={formSettings}
            buttons={formButtons}
            buttonsLocation={0}
            readOnly={readOnly}
            showSubmitButtons={editAccess}
            buttonsPosition={1}
            onSubmit={onFormSubmit}
            onFieldChanged={onFieldChanged}
          />
        )}
      </div>
      <ModalDialog ref={saveModalReference} header={t('save-unsaved-changes')} buttons={saveModalButtons}>
        <div>{t('save-message')}</div>
      </ModalDialog>
    </>
  );
};
export default forwardRef(RuleDetails);
