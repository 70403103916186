import React, { useEffect, useRef, useState, useMemo } from 'react';
import { HierarchyFieldGroupProps, RecordHierarchyFields } from './hierarchy-field-group.component';
import {
  DropDownResult,
  JsonData,
  DynamicFormRequiredType,
  OdinDataSender,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  DropDownRef,
  DropDownMultiSelect,
  DataSearchLocation,
} from '@myosh/odin-components';
import { hierarchyToDropDown, isHierarchyMandatory } from './hierarchy-functions';
import { useLazyHierarchyValuesQuery } from '../../../redux/services/hierarchy';
import { forceAssert } from '../../../common/common-functions';
import { HierarchyAccessRestriction, HierarchyDropdownValue } from '../../../@types/hierarchy-fields';
import OdinDropDown from '../custom-drop-downs/odin-drop-down.component';
import { isArray } from 'lodash';

interface HierarchyFieldProps extends Omit<HierarchyFieldGroupProps, 'onChange'> {
  userHierarchies?: Record<string, HierarchyDropdownValue>;
  hierarchyRestrictions?: Record<string, HierarchyAccessRestriction>;
  hierarchyField: RecordHierarchyFields;
  onChange: (
    hierarchyField: RecordHierarchyFields,
    fieldValue: HierarchyDropdownValue | HierarchyDropdownValue[]
  ) => void;
}

// The hierarchy field specific blank value
export const hierarchyDeselectValue: DropDownResult = {
  value: '',
  text: '',
  originalData: undefined,
};

const HierarchyField = ({
  recordId,
  hierarchyField,
  hierarchySettings = {},
  readOnly,
  value,
  onChange,
  errorObject,
  isUserHierarchy,
  isCompetencyHierarchy,
  userHierarchies,
  hierarchyRestrictions,
  usesMultiSelectDropdowns,
}: HierarchyFieldProps) => {
  const key = useRef(hierarchyField.caption);
  const subscriberRef = useRef<OdinDataSender<JsonDataWrapper>>();
  const dropDownRef = useRef<DropDownRef>(null);
  const userHierarchyValueRef = useRef<HierarchyDropdownValue>();
  const parentId = useRef<number>();
  const isHierarchyFieldMandatory = isHierarchyMandatory(hierarchyField, isUserHierarchy, isCompetencyHierarchy);

  const { autoPopulateFromUserHierarchy } = hierarchySettings;
  const hierarchyRestriction = hierarchyRestrictions?.[key.current];

  const userHierarchyValue = useMemo(() => {
    if (userHierarchies) {
      return userHierarchies[key.current];
    }
  }, [userHierarchies]);

  useEffect(() => {
    if (
      !recordId && // User hierarchy value should be added only for New records
      userHierarchyValue &&
      hierarchyRestriction &&
      hierarchyRestriction.hierarchyTypeId === userHierarchyValue.hierarchyTypeId &&
      autoPopulateFromUserHierarchy
    ) {
      userHierarchyValueRef.current = userHierarchyValue;
    }
  }, [userHierarchyValue, hierarchyRestriction, autoPopulateFromUserHierarchy]);

  const [options, setOptions] = useState<OdinDataRetrievalOptions>();
  const [dropDownValue, setDropDownValue] = useState<DropDownResult | DropDownResult[]>();

  const [getHierarchyValues] = useLazyHierarchyValuesQuery();

  useEffect(() => {
    if (hierarchyField.id && options) {
      if (hierarchyField.dependsOnParent && !parentId.current && !usesMultiSelectDropdowns) {
        subscriberRef.current?.sendData();
      } else {
        getHierarchyValues(
          {
            page: options.page ?? 1,
            pageSize: options.pageSize ?? 50,
            sort: 'caption',
            filters: {
              typeId: { value: hierarchyField.id, comparison: '=' },
              ...(parentId.current ? { parentId: { value: parentId.current, comparison: '=' } } : {}),
              archived: { value: false, comparison: '=' },
              ...options.fieldFilters,
            },
          },
          true
        )
          .unwrap()
          .then((result) => {
            if (result?.items) {
              const dataItems = hierarchyToDropDown(result.items);
              if (userHierarchyValueRef.current) {
                const isUserHierarchyValuePresent = dataItems.some(
                  (item) => item.id === userHierarchyValueRef.current?.id
                );

                if (!isUserHierarchyValuePresent) {
                  dataItems.push(userHierarchyValueRef.current);
                  dataItems.sort((a, b) => a.caption.localeCompare(b.caption));
                }
              }
              subscriberRef.current?.sendData({ data: forceAssert<JsonData>(dataItems), requestId: options.requestId });
              if (result.page === result.availablePages) {
                subscriberRef.current?.sendData();
              }
            } else {
              subscriberRef.current?.sendData();
            }
          })
          .catch(() => subscriberRef.current?.sendData());
      }
    }
  }, [hierarchyField?.id, hierarchyField?.dependsOnParent, options]);

  useEffect(() => {
    if (value) {
      const currentHierarchyValue = value[key.current] ?? value.ignoreHierarchyAccess;

      if (hierarchyField.dependsOnParent && !isArray(currentHierarchyValue) && !usesMultiSelectDropdowns) {
        parentId.current = currentHierarchyValue?.selectedParentId;
        if (
          currentHierarchyValue.parentId === currentHierarchyValue?.selectedParentId &&
          currentHierarchyValue.hasOwnProperty('parentId')
        ) {
          setDropDownValue({
            value: currentHierarchyValue.id,
            text: currentHierarchyValue.caption,
            originalData: currentHierarchyValue,
          });
        } else {
          setDropDownValue(hierarchyDeselectValue);
          dropDownRef.current?.resetDropDownData();
          dropDownRef.current?.getDataOverwritePageCache();
        }
      } else if (!isArray(currentHierarchyValue) && !usesMultiSelectDropdowns) {
        setDropDownValue({
          value: currentHierarchyValue.id,
          text: currentHierarchyValue.caption,
          originalData: currentHierarchyValue,
        });
      } else if (isArray(currentHierarchyValue) && usesMultiSelectDropdowns) {
        const dropdownValues: DropDownResult[] = currentHierarchyValue.map((item) => {
          return {
            value: item.id,
            text: item.caption,
            originalData: item,
          };
        });
        setDropDownValue(dropdownValues);
      }
    }
  }, [value]);

  const getOptionsData = useMemo(() => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        subscriberRef.current = subscriber;
        setOptions(options);
      },
    };
  }, []);

  const onValueChange = (fieldValue: DropDownResult | DropDownResult[]) => {
    if (onChange && !!fieldValue) {
      const fieldOriginalData = isArray(fieldValue)
        ? (fieldValue.map((item) => {
            return item.originalData;
          }) as HierarchyDropdownValue[])
        : (fieldValue.originalData as HierarchyDropdownValue);
      onChange(hierarchyField, fieldOriginalData);
    }
  };

  return (
    <div key={key.current} className={'mb-3'}>
      {usesMultiSelectDropdowns ? (
        <DropDownMultiSelect
          ref={dropDownRef}
          key={`dd_${key.current}`}
          textField="caption"
          valueField="id"
          value={dropDownValue}
          error={errorObject?.[key.current]?.id?.message}
          label={key.current}
          data={getOptionsData}
          readOnly={readOnly}
          onChange={(value) => onValueChange(value as DropDownResult[])}
          filterLocation={DataSearchLocation.Api}
        />
      ) : (
        <OdinDropDown
          ref={dropDownRef}
          key={`dd_${key.current}`}
          textField="caption"
          valueField="id"
          value={dropDownValue}
          error={errorObject?.[key.current]?.id?.message}
          label={key.current}
          data={getOptionsData}
          readOnly={readOnly}
          required={isHierarchyFieldMandatory ? DynamicFormRequiredType.True : DynamicFormRequiredType.False}
          onChange={(value) => onValueChange(value as DropDownResult)}
          deselectValue={hierarchyDeselectValue}
          allowClear={!isHierarchyFieldMandatory}
          filterLocation={DataSearchLocation.Api}
        />
      )}
    </div>
  );
};

export default HierarchyField;
