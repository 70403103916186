import { ActiveRecordRef } from '../components/active-record/active-record.component';
import { BehaviorSubject } from 'rxjs';

const activeRecordSubject = new BehaviorSubject<ActiveRecordRef | null>(null);

export function getActiveRecordReference() {
  return activeRecordSubject.asObservable();
}

export function setActiveRecordReference(reference: ActiveRecordRef | null) {
  activeRecordSubject.next(reference);
}
