import React, { forwardRef, Ref } from 'react';
import dot from 'dot-object';
import { DataGridCellRendererProps, DataGridDataCell, CommentElementProps } from '@myosh/odin-components';
import { stripHTML } from '../../common/common-functions';
import { formatDisplayDate, formatDisplayTime } from '../../common/date-util';

const parseFieldValue = (value: string) => {
  let parsedValue = [];
  try {
    parsedValue = JSON.parse(value);
  } catch (error) {
    parsedValue = [];
  }
  return parsedValue;
};

const formatValue = (comments: Array<CommentElementProps>) => {
  return comments
    .map((comment) => {
      const date = new Date(comment.date);
      const formattedDate = formatDisplayDate(date);
      const formattedTime = formatDisplayTime(date);
      return `${formattedDate} ${formattedTime} ${comment.user} ${stripHTML(comment.message)}`;
    })
    .join(' - ');
};

interface HistoryFieldDataCellProps extends DataGridCellRendererProps {
  allowCellScrollBar?: boolean;
}

const HistoryFieldDataCell = (
  { column, rowData, allowCellScrollBar }: HistoryFieldDataCellProps,
  forwardRef: Ref<HTMLDivElement>
) => {
  const value = (column.valuePath ? dot.pick(column.valuePath, rowData) : '') ?? '';
  const comments: Array<CommentElementProps> = value ? parseFieldValue(value) : [];
  const displayValue = formatValue(comments);

  return (
    <DataGridDataCell
      ref={forwardRef}
      value={displayValue}
      width={column.width}
      allowCellScrollBar={allowCellScrollBar}
    />
  );
};

export default forwardRef(HistoryFieldDataCell);
