import { useEffect, useState } from 'react';
import {
  ContinuousScrollerFilter,
  DataGridColumnSettings,
  DataGridSettings,
  DynamicFormSettings,
} from '@myosh/odin-components';
import { findFieldRecursive, forceAssert } from '../common/common-functions';
import { useFormSettingsQuery } from '../redux/services/api';
import useGridColumnsSettings from './use-grid-columns-settings';
import { FieldInfo, ViewColumnType } from '../@types/views';
import { CombinedFieldType } from '../@types/forms';
import {
  processLastEditedFilterForContinousScroller,
  processRecordFilterForContinuousScroller,
  processSortedFiltersForContinuousScroller,
} from '../common/dynamic-page-utils';
import { useAppSelector } from '../redux/hooks';
import { dynamicPageState } from '../redux/slices/dynamic-page-slice';

interface GridSettings {
  settings?: DataGridSettings;
  error: boolean;
  loading: boolean;
}

const defaultState = { loading: true, error: false };
const errorState = { loading: false, error: true };

/**
 * A hook that provides the complete grid settings for a `Records` data grid.
 *
 * @param {DataGridSettings} defaultGridSettings - The grid settings to use as default values.
 * @param {(configColumn: DataGridColumnSettings, fieldInfo?: FieldInfo) => DataGridColumnSettings} columnConfigurator - A function used to configure the columns. This is an entry point for callers to further customize the column definitions.
 * @param {number} formId - The forms settings we need to retrieve.
 * @param {number} configId - The view configuration id.
 * @param {number} moduleId - The module id, passed when viewing the 'all forms'.
 * @param {boolean} isAllForms - Indicates if the view is the special 'all forms' view.

 * @returns {{settings: DataGridSettings | undefined, error: boolean}} Returns the data grid settings.
 */
const useGridSettings = (
  defaultGridSettings: DataGridSettings,
  columnConfigurator: (configColumn: DataGridColumnSettings, fieldInfo?: FieldInfo) => DataGridColumnSettings,
  formId?: number,
  configId?: number,
  moduleId?: number,
  isAllForms = false
) => {
  const { selectedFilters } = useAppSelector(dynamicPageState);

  const { data: formSettings } = useFormSettingsQuery(formId ?? 0, {
    skip: formId === undefined || formId === 0 || isAllForms,
  });

  const {
    data: gridColumnsSettings,
    error: gridColumnsSettingsError,
    loading: gridColumnsSettingsLoading,
  } = useGridColumnsSettings(isAllForms, formId, configId, moduleId, formSettings?.hideFirstColumn);

  const [settings, setSettings] = useState<GridSettings>(defaultState);

  useEffect(() => {
    if (gridColumnsSettingsLoading) {
      setSettings((settings) => {
        return { ...settings, loading: true, error: false };
      });
    } else if (gridColumnsSettingsError) {
      setSettings(errorState);
    } else if (gridColumnsSettings) {
      if (!isAllForms && formSettings) {
        const columns = configureColumnTypes(gridColumnsSettings, formSettings).reduce<Array<DataGridColumnSettings>>(
          (result, setting) => {
            if (setting.customDataProperties && setting.customDataProperties.entityId) {
              const foundField = findFieldRecursive(
                formSettings.fields,
                setting.customDataProperties.entityId.toString()
              );

              if (foundField) {
                result.push(columnConfigurator(setting, { field: foundField }));
              } else {
                result.push(columnConfigurator(setting));
              }
            } else {
              result.push(columnConfigurator(setting));
            }

            return result;
          },
          []
        );

        let initialFieldFilters: ContinuousScrollerFilter[] = [];
        if (selectedFilters.records && selectedFilters.lastEdited) {
          const showRecordsFilter = processRecordFilterForContinuousScroller(selectedFilters.records);
          const lastEditedFilter = processLastEditedFilterForContinousScroller(selectedFilters.lastEdited);
          initialFieldFilters = [showRecordsFilter, lastEditedFilter];
        }
        const initialSortedFields = processSortedFiltersForContinuousScroller(columns);
        setSettings({
          loading: false,
          error: false,
          settings: { ...defaultGridSettings, columns, initialFieldFilters, initialSortedFields },
        });
      } else if (isAllForms && selectedFilters.records) {
        const columns = configureColumnTypes(gridColumnsSettings).map((column) =>
          columnConfigurator(column, { isAllForms })
        );
        const showRecordsFilter = processRecordFilterForContinuousScroller(selectedFilters.records);
        const initialFieldFilters = [showRecordsFilter];
        const initialSortedFields = processSortedFiltersForContinuousScroller(columns);
        setSettings({
          loading: false,
          error: false,
          settings: { ...defaultGridSettings, columns, initialFieldFilters, initialSortedFields },
        });
      }
    }
  }, [
    gridColumnsSettings,
    gridColumnsSettingsLoading,
    gridColumnsSettingsError,
    formSettings,
    isAllForms,
    selectedFilters,
  ]);

  return settings;
};

export default useGridSettings;

// Helper methods
const configureColumnTypes = (
  columns: DataGridColumnSettings[],
  formSettingsData?: DynamicFormSettings
): Array<DataGridColumnSettings> => {
  return columns.map((column: DataGridColumnSettings) => {
    let type, cellRenderer;
    const entityType = column.customDataProperties?.entityType;

    if ('FIELD' === entityType) {
      if (formSettingsData) {
        if ('DATEFIELD' === column.customDataProperties?.fieldType && column.customDataProperties) {
          type = findFieldTypeByName(formSettingsData, String(column.customDataProperties?.entityId));
        } else {
          type = findFieldTypeByName(formSettingsData, column.field);
        }
      } else {
        type = getFieldColumnTypeId(
          forceAssert<{ fieldType: CombinedFieldType }>(column.customDataProperties)?.fieldType
        );
      }

      if ('RISKRATING' === column.customDataProperties?.fieldType) {
        cellRenderer = 'RISK_RATING_DATA_CELL';
      } else if ('HISTORY' === column.customDataProperties?.fieldType) {
        cellRenderer = 'HISTORY_FIELD_DATA_CELL';
      } else if ('RICHTEXTAREA' === column.customDataProperties?.fieldType) {
        cellRenderer = 'RICHTEXTAREA_DATA_CELL';
      }
    } else if ('RECORD_LINK' === entityType) {
      cellRenderer = 'RECORDLINK_DATA_CELL';
      type = 6; //Custom
    } else if ('REVERSE_RECORD_LINK' === entityType) {
      cellRenderer = 'REVERSE_RECORDLINK_DATA_CELL';
      type = 6; //Custom
    } else if ('attachments' === column.customDataProperties?.entityPropertyName) {
      cellRenderer = 'ATTACHMENTS_DATA_CELL';
      type = 6; //Custom
    } else if ('GRAND_SCORE' === entityType) {
      cellRenderer = 'GRAND_SCORE_DATA_CELL';
      type = 6;
    } else if ('QUESTIONNAIRE' === entityType) {
      cellRenderer = 'QUESTIONNAIRE_DATA_CELL';
      type = 6;
    } else if ('WORKFLOW_STEP' === entityType) {
      type = getColumnTypeId(forceAssert<{ filterType: ViewColumnType }>(column.customDataProperties)?.filterType);
    } else if (['HIERARCHY_TYPE', 'HIERARCHY_FIELD'].includes(entityType as string)) {
      type = 0; // Text
    } else if ('KEYWORD' === entityType) {
      // special handling as KEYWORD is a bit inconsistent
      const filterType = column.customDataProperties?.filterType;
      if (['MULTISELECTRANGE', 'STRING'].includes(filterType as string)) {
        type = 6; // Custom
      } else {
        type = getColumnTypeId(forceAssert<{ filterType: ViewColumnType }>(column.customDataProperties)?.filterType);
      }
    } else {
      type = 6; // Custom
    }

    return {
      ...column,
      type,
      cellRenderer,
    };
  });
};

const findFieldTypeByName = (obj: DynamicFormSettings, fieldName: string) => {
  let typeId = 6; // default to Custom
  const field = findFieldRecursive(obj?.fields, fieldName);
  if (field) {
    typeId = getFieldColumnTypeId(field.fieldType);
  }

  return typeId;
};

// The `filterType` can be used to determine the column type (https://myosh.atlassian.net/browse/MYOSH-1815?focusedCommentId=17860)
const getColumnTypeId = (filterType?: ViewColumnType) => {
  // Text     = 0,
  // Number   = 1,
  // Date     = 2,
  // Time     = 3,
  // Select   = 4,
  // Boolean  = 5,
  // Custom   = 6
  // MultiSelect = 7
  switch (filterType) {
    case 'BOOLEAN':
      return 5;
    case 'MULTISELECTRANGE':
      return 7;
    case 'NUMBER':
      return 1;
    case 'DATE':
      return 2;
    case 'STRING':
      return 0;
    default:
      6;
  }
};

/**
 * Resolves the data grid cell type based on the field type.
 *
 * @param fieldType the type of the filed.
 *
 * @returns The resolved data grid cell type.
 */
const getFieldColumnTypeId = (fieldType?: CombinedFieldType) => {
  // Text     = 0,
  // Number   = 1,
  // Date     = 2,
  // Time     = 3,
  // Select   = 4,
  // Boolean  = 5,
  // Custom   = 6
  // MultiSelect = 7
  switch (fieldType) {
    case 'TEXTFIELD':
    case 'TEXTAREA':
      return 0;
    case 'INTFIELD':
    case 'DOUBLEFIELD':
      return 1;
    case 'DATEFIELD':
    case 'DATEFIELD_COMPETENCY':
      return 2;
    case 'TIMEFIELD':
      return 3;
    case 'COMBOBOX':
    case 'PERSONFIELD':
    case 'OPTIONGROUP':
    case 'TRAINING':
      return 4;
    case 'LABEL':
    case 'HISTORY':
    case 'RECORDLINK':
    case 'REVERSE_RECORD_LINK':
    case 'USER_RECORDLINK':
    case 'RISKRATING':
    case 'SUMMARY_RECORD_LINK':
      return 6;
    case 'MULTISELECTCHECKBOX':
    case 'MULTISELECTFIELD':
    case 'MULTISELECTPERSONFIELD':
    case 'TWINCOLUMNSELECTPERSONFIELD':
    case 'TWINCOLUMNSELECT':
      return 7;
    default:
      return 6; // default to Custom
  }
};
