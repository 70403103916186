import { useCallback, useEffect, useMemo, useState } from 'react';

const useNavigationItemClickHandler = (setCollapsed: (collapsed: boolean) => void) => {
  const [itemClickHandler, setItemClickHandler] = useState<() => () => void | undefined>();
  const itemClick = useCallback(() => () => setCollapsed(true), []);

  const mediaMaxWidthListener = useMemo(
    () => (event: MediaQueryListEvent) => {
      if (event.matches) {
        setItemClickHandler(itemClick);
      } else {
        setItemClickHandler(undefined);
      }
    },
    []
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 600px)');
    mediaQuery.matches ? setItemClickHandler(itemClick) : setItemClickHandler(undefined);
    mediaQuery.addEventListener('change', mediaMaxWidthListener);
    return () => mediaQuery.removeEventListener('change', mediaMaxWidthListener);
  }, []);

  return itemClickHandler;
};

export default useNavigationItemClickHandler;
