import { useState, useEffect } from 'react';
import { GetUserAccessParams } from '../@types/common';
import { OdinUser } from '../@types/users';

interface UserAccessProps {
  initialAccess?: boolean;
  userData?: OdinUser;
  permissions?: GetUserAccessParams;
}

export const useUserAccess = ({ initialAccess = false, userData, permissions }: UserAccessProps) => {
  const [readOnly, setReadOnly] = useState(!initialAccess);
  const [editAccess, setEditAccess] = useState(initialAccess);

  const updatePermissions = (canAccess: boolean) => {
    setReadOnly(!canAccess);
    setEditAccess(canAccess);
  };

  useEffect(() => {
    if (userData && permissions) {
      const { adminAccess, superUser } = userData;

      if (superUser) {
        updatePermissions(true);
        return;
      }

      const canModify = permissions?.MODIFY?.some((p) => adminAccess?.includes(p)) ?? false;
      const canView = permissions?.READ ? adminAccess?.includes(permissions.READ) : false;

      setReadOnly(Boolean(!canModify || !canView));
      setEditAccess(canModify);
    }
  }, [userData]);

  return { readOnly, editAccess, setReadOnly, setEditAccess };
};
