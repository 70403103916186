import { getDefaultRecordWidth } from './active-record-helper';

export interface ActiveRecordChildSetting {
  key: string;
  /**
   * Used to determine when the active record panel should be recreated. This should stop the issue where starting with
   * a new record and then saving that record will cause the active record panel to be recreated.
   */
  renderKey: string;
  icon: string;
  details: string | JSX.Element;
  extraSettings?: unknown;
}

interface ActiveRecordState {
  activeRecordSetting?: ActiveRecordChildSetting;
  displayCollapsed: boolean;
  childSettings: Array<ActiveRecordChildSetting>;
  currentFullScreen: boolean;
  recordWidth: number;
  minimiseActiveRecord?: boolean;
  frozen?: boolean;
}

export enum ActiveRecordActionType {
  SetFullScreen,
  AddActiveRecord, //adds or "navigates to" item if already present
  SetActiveRecord,
  CloseActiveRecord,
  CloseAllRecords,
  SetDisplayCollapsed,
  UpdateActiveRecord,
  UpdateRecordWidth,
  SetActiveRecordPanelFrozen,
}

interface ActiveRecordChildSettingUpdate {
  key: string;
  /**
   * Used to determine when the active record panel should be recreated.
   * Should only be used when an explicit re-render is needed.
   */
  renderKey?: string;
  icon?: string;
  details?: string | JSX.Element;
  extraSettings?: unknown;
}

interface ActiveRecordReducerAction {
  type: ActiveRecordActionType;
  activeRecordSetting?: ActiveRecordChildSetting;
  displayCollapsed?: boolean;
  childSettings?: Array<ActiveRecordChildSetting>;
  currentFullScreen?: boolean;
  recordKey?: string;
  newRecordSetting?: ActiveRecordChildSettingUpdate;
  recordWidth?: number;
  minimiseActiveRecord?: boolean;
  frozen?: boolean;
}

export default function reducer(state: ActiveRecordState, action: ActiveRecordReducerAction): ActiveRecordState {
  switch (action.type) {
    case ActiveRecordActionType.SetFullScreen:
      return {
        ...state,
        currentFullScreen: action.currentFullScreen !== undefined ? action.currentFullScreen : state.currentFullScreen,
        displayCollapsed: action.displayCollapsed !== undefined ? action.displayCollapsed : state.displayCollapsed,
      };
    case ActiveRecordActionType.AddActiveRecord:
      if (action.activeRecordSetting) {
        const keyIndex = state.childSettings.findIndex((setting) => setting.key === action.activeRecordSetting?.key);
        if (keyIndex === -1) {
          return {
            ...state,
            currentFullScreen: action.currentFullScreen ?? state.currentFullScreen,
            activeRecordSetting: action.activeRecordSetting,
            childSettings: [...state.childSettings, action.activeRecordSetting],
            minimiseActiveRecord: true,
          };
        } else {
          // navigate to existing item
          return {
            ...state,
            activeRecordSetting: action.activeRecordSetting,
            currentFullScreen: action.currentFullScreen ?? state.currentFullScreen,
            minimiseActiveRecord: true,
          };
        }
      }

      return state;
    case ActiveRecordActionType.SetActiveRecord:
      if (action.recordKey) {
        return {
          ...state,
          activeRecordSetting:
            state.childSettings.find((setting) => setting.key === action.recordKey) || state.activeRecordSetting,
          minimiseActiveRecord: action.minimiseActiveRecord,
        };
      }

      return state;
    case ActiveRecordActionType.CloseActiveRecord:
      if (action.recordKey) {
        const childIndex = state.childSettings.findIndex((setting) => setting.key === action.recordKey);
        if (childIndex !== -1) {
          const childSettings = [
            ...state.childSettings.slice(0, childIndex),
            ...(childIndex + 1 < state.childSettings.length ? state.childSettings.slice(childIndex + 1) : []),
          ];

          return {
            ...state,
            activeRecordSetting:
              childSettings.length > 0
                ? childIndex > childSettings.length - 1
                  ? childSettings[childSettings.length - 1]
                  : childSettings[childIndex]
                : childSettings[0],
            childSettings: childSettings,
            currentFullScreen: childSettings.length > 0 ? state.currentFullScreen : false,
            recordWidth: childSettings.length > 0 ? state.recordWidth : getDefaultRecordWidth(),
            displayCollapsed: childSettings.length > 0 ? state.displayCollapsed : true,
          };
        }
      }

      return state;
    case ActiveRecordActionType.CloseAllRecords:
      return {
        displayCollapsed: true,
        childSettings: [],
        currentFullScreen: false,
        recordWidth: getDefaultRecordWidth(),
      };
    case ActiveRecordActionType.SetDisplayCollapsed:
      return {
        ...state,
        displayCollapsed: action.displayCollapsed !== undefined ? action.displayCollapsed : state.displayCollapsed,
      };
    case ActiveRecordActionType.UpdateActiveRecord:
      if (action.recordKey && action.newRecordSetting) {
        const keyIndex = state.childSettings.findIndex((setting) => setting.key === action.recordKey);
        if (keyIndex !== -1) {
          const { key, renderKey, icon, details, extraSettings } = action.newRecordSetting;

          const newChildSettings = state.childSettings.map((setting) => {
            if (setting.key === action.recordKey) {
              return {
                key,
                renderKey: renderKey || setting.renderKey,
                icon: icon || setting.icon,
                details: details || setting.details,
                extraSettings: extraSettings || setting.extraSettings,
              };
            } else {
              return setting;
            }
          });

          const newActiveRecordSetting =
            state?.activeRecordSetting?.key === action.recordKey
              ? newChildSettings[keyIndex]
              : state.activeRecordSetting;

          return {
            ...state,
            activeRecordSetting: newActiveRecordSetting,
            childSettings: [...newChildSettings],
          };
        }
      }
      return state;
    case ActiveRecordActionType.UpdateRecordWidth:
      return {
        ...state,
        recordWidth: action.recordWidth || state.recordWidth,
      };
    case ActiveRecordActionType.SetActiveRecordPanelFrozen:
      return {
        ...state,
        frozen: action.frozen,
      };
    default:
      return state;
  }
}
