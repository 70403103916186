import React, { ReactNode, useEffect, useMemo, useRef, useCallback } from 'react';
import {
  DataGrid,
  DataGridDataRowProps,
  DataGridRef,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  OdinDataSender,
  RowTemplateType,
  DataGridSettingsMenuItem,
  OdinDataRetrieval,
  DataSearchLocation,
  DynamicFormFieldType,
} from '@myosh/odin-components';
import { UserFields } from '../../../../common/user-config';
import { useLazyGetUsersQuery } from '../../../../redux/services/user';
import { LinkedUser } from '../../../../@types/users';
import { linkedUsersCustomComponents } from '../user-record-link.component';
import { UserRecordLinkFields, usersDataGridColumns } from './user-recordlink-columns-config';
import { dateFormatBasedOnFilter, processFieldFilters } from '../../../../common/common-functions';
import { getUsersFieldOptionsData } from '../../../../common/user-functions';
import { useAppDispatch } from '../../../../redux/hooks';
import { useHierarchyTypesQuery } from '../../../../redux/services/hierarchy';

const mapHighlightedRows = (highlightedRows: Array<LinkedUser>) =>
  highlightedRows?.map((item) => {
    return { id: Number(item.value) };
  });

interface AllUsersProps {
  highlightedRows: Array<LinkedUser>;
  onAdded: (value: LinkedUser) => void;
  menuGridSettings?: DataGridSettingsMenuItem[];
}

function AllUsersGrid({ highlightedRows, onAdded, menuGridSettings }: AllUsersProps) {
  const gridRef = useRef<DataGridRef>();
  const [getUsers] = useLazyGetUsersQuery();
  const { data: hierarchyTypes } = useHierarchyTypesQuery({ archived: 'false' });
  const dispatch = useAppDispatch();
  const gridSubscriber = useRef<OdinDataSender<JsonDataWrapper>>();

  useEffect(() => {
    if (highlightedRows.length > 0) {
      gridRef.current?.api.data.addHighlightedRows(mapHighlightedRows(highlightedRows), true);
    } else {
      gridRef.current?.api.data.removeHighlightedRows();
    }
  }, [highlightedRows]);

  const fetchUsersData = useCallback(
    async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
      if (options?.page && options?.pageSize) {
        try {
          const data = await getUsers({
            fields: UserRecordLinkFields.join(),
            page: options.page,
            pageSize: options.pageSize,
            sortedField: UserFields.lastName,
            sort: options.sortedFields,
            filters: processFieldFilters(options?.fieldFilters, dateFormatBasedOnFilter),
          }).unwrap();

          subscriber.sendData({ data, requestId: options.requestId });
        } catch (_) {
          subscriber.sendData();
        }
      } else {
        subscriber.sendData();
      }
    },
    []
  );

  const gridSettings = useMemo(() => {
    if (hierarchyTypes) {
      return {
        columns: usersDataGridColumns(hierarchyTypes),
        components: linkedUsersCustomComponents,
        fullHeight: true,
        filterLocation: DataSearchLocation.Api,
      };
    }
  }, [hierarchyTypes]);

  const addLinkedUser = (value: LinkedUser) => onAdded(value);

  const rowTemplate: RowTemplateType = (props: DataGridDataRowProps, children: ReactNode | Array<ReactNode>) => (
    <div
      className="flex"
      onClick={() =>
        addLinkedUser({
          // eslint-disable-next-line react/prop-types
          value: props.data[UserFields.id] as number,
          // eslint-disable-next-line react/prop-types
          text: props.data[UserFields.lastName] + ' ' + props.data[UserFields.firstName],
        })
      }
    >
      {children}
    </div>
  );

  const initializeGridRef = (ref: DataGridRef) => {
    if (!gridRef.current) {
      gridRef.current = ref;

      if (highlightedRows.length > 0) {
        gridRef.current.api.data.addHighlightedRows(mapHighlightedRows(highlightedRows));
      }
    }
  };

  const gridData = useMemo<OdinDataRetrieval>(() => {
    return {
      getData: (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if (options?.fieldType === 'COMBOBOX') {
          getUsersFieldOptionsData(subscriber, options, dispatch);
        } else {
          fetchUsersData(subscriber, options);
        }
      },
      getSubscriber: (subscriber, fieldType?: DynamicFormFieldType) => {
        if (fieldType !== 'COMBOBOX') {
          gridSubscriber.current = subscriber;
        }
      },
    };
  }, []);

  return (
    <div className="flex h-full flex-col gap-4">
      {gridSettings && (
        <DataGrid
          ref={(ref) => ref && initializeGridRef(ref)}
          data={gridData}
          gridSettings={gridSettings}
          rowTemplate={rowTemplate}
          customSettingsMenuItems={menuGridSettings}
          showGridMenu={false}
        />
      )}
    </div>
  );
}

export default AllUsersGrid;
