import React, { useRef, useState } from 'react';
import { Button, DataGrid, DynamicFieldComponentProps } from '@myosh/odin-components';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { SchedulerRule, SchedulerRuleField, SchedulerRuleScheduler } from '../../../@types/scheduler-record';
import FieldRuleField from './field-rule-field';
import IntervalFieldRule, { IntervalFieldRuleState } from './interval-rule-field';
import { v4 } from 'uuid';
import { DynamicFieldDataTypes } from '@myosh/odin-components/dist/types/components/dynamic-form/dynamic-form.interfaces';

interface SchedulerRulesFieldProps extends Omit<DynamicFieldComponentProps<Array<SchedulerRule>>, 'error'> {
  formIntFields?: DynamicFieldDataTypes;
  error: Array<Record<string, unknown>>;
}

const ScheduleRulesField = ({
  value = [],
  label,
  error,
  readOnly,
  formIntFields,
  onChange,
}: SchedulerRulesFieldProps) => {
  const { t } = useTranslation();
  const [rules, setRules] = useState<Array<SchedulerRule>>(value);

  const configuredRulesGridSettings = useRef({
    columns: [
      {
        field: 'displayName',
        valuePath: 'displayName.translations[0].value',
        visible: true,
        id: 'caption',
        title: label,
        width: 300,
      },
    ],
  });

  const addNewRule = (type: string) => {
    const uniqueId = v4();
    const updatedRules = [...value, { id: 'new' + uniqueId, type: type }] as Array<SchedulerRule>;
    onChange?.(updatedRules);
    setRules(updatedRules);
  };

  const onFieldChange = (data: IntervalFieldRuleState) => {
    const updatedRules = value.map((rule) => {
      if (rule.id === data.id) {
        return {
          ...rule,
          ...data,
        };
      }
      return rule;
    });
    onChange?.(updatedRules as Array<SchedulerRule>);
  };

  const onFieldDelete = (data: IntervalFieldRuleState) => {
    const updatedRules = value.filter((rule) => {
      return rule.id !== data.id;
    });
    onChange?.(updatedRules);
    setRules(updatedRules);
  };

  const addNewPeriodicSchedule = () => {
    addNewRule('periodic-schedule');
  };

  const addNewFieldRule = () => {
    addNewRule('field-condition');
  };

  const classNames = cx('rounded-md bg-mono-1 px-2 border border-gray-3 my-2 py-4 flex flex-1');
  const buttonsStyles = cx('flex justify-start gap-2', { hidden: readOnly, 'm-2': !readOnly });

  return readOnly ? (
    <div>
      <DataGrid data={rules} gridSettings={configuredRulesGridSettings.current} showSettings={false} />
    </div>
  ) : (
    <div className="flex flex-col">
      <div className={buttonsStyles}>
        <Button type="default" variant="alternative" onClick={addNewPeriodicSchedule}>
          {t('schedule-rule')}
        </Button>
        <Button type="default" variant="alternative" classNames="ml-2" onClick={addNewFieldRule}>
          {t('field-rule')}
        </Button>
      </div>
      <div className="flex flex-col">
        {rules?.map((rule, index) => {
          if (rule.type === 'periodic-schedule') {
            return (
              <IntervalFieldRule
                key={rule.id}
                intervalFieldRule={rule as SchedulerRuleScheduler}
                className={classNames}
                onChange={onFieldChange}
                onFieldDelete={onFieldDelete}
                errorObject={error?.[index]}
              />
            );
          } else {
            return (
              <FieldRuleField
                key={rule?.id}
                fieldRule={rule as SchedulerRuleField}
                className={classNames}
                componentOptions={formIntFields}
                onChange={onFieldChange}
                onFieldDelete={onFieldDelete}
                errorObject={error?.[index]}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default ScheduleRulesField;
