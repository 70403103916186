import { DataGridSettings } from '@myosh/odin-components';
import i18next from '../../../i18n';

export const datagridNotificationLogColumns: DataGridSettings = {
  columns: [
    {
      id: 112,
      title: i18next.t('id'),
      field: 'id',
      visible: false,
      isIdField: true,
    },
    {
      id: 113,
      title: i18next.t('sender'),
      field: 'sender',
      visible: false,
      type: 0,
      width: 250,
    },
    {
      id: 114,
      title: i18next.t('recipients'),
      field: 'recipients',
      visible: true,
      type: 0,
      width: 250,
    },
    {
      id: 115,
      title: i18next.t('date-time'),
      field: 'dateTime',
      visible: true,
      width: 250,
      type: 8,
    },
    {
      id: 116,
      title: i18next.t('copy-sender'),
      field: 'copySender',
      visible: false,
      width: 250,
      type: 5,
    },
    {
      id: 117,
      title: i18next.t('hold'),
      field: 'hold',
      visible: false,
      width: 250,
      type: 5,
    },
    {
      id: 118,
      title: i18next.t('successful'),
      field: 'successful',
      visible: true,
      width: 250,
      type: 5,
    },
    {
      id: 119,
      title: i18next.t('retries'),
      field: 'retries',
      visible: false,
      width: 250,
      type: 1,
    },
    {
      id: 120,
      title: i18next.t('notification-name'),
      field: 'notificationName',
      visible: true,
      width: 250,
      type: 0,
    },
    {
      id: 121,
      title: i18next.t('notification-type'),
      field: 'notificationType',
      visible: true,
      width: 250,
      type: 0,
    },
    {
      id: 122,
      title: i18next.t('exception'),
      field: 'exception',
      visible: false,
      width: 250,
      type: 0,
    },
    {
      id: 123,
      title: i18next.t('copy-to'),
      field: 'copyTo',
      visible: true,
      width: 250,
      type: 0,
    },
    {
      id: 124,
      title: i18next.t('blind-copy-to'),
      field: 'blindCopyTo',
      visible: true,
      width: 250,
      type: 0,
    },
  ],
};
