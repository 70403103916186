import React from 'react';
import cx from 'classnames';
import { FormLoader } from '@myosh/odin-components';

interface DynamicPageLoaderProps {
  isAllForms?: boolean;
}

const DynamicPageLoader = ({ isAllForms = false }: DynamicPageLoaderProps) => {
  const containerStyles = cx('z-20 absolute h-full w-full bg-transparent', { 'pl-6': !isAllForms });

  return (
    <div className={containerStyles}>
      <div className="mb-5">
        <div className="w-96 h-12 mt-2 mb-4">
          <FormLoader width="xl" height="lg" />
        </div>
        <div className="flex flex-row gap-2 h-12">
          <div className="w-48">
            <FormLoader width="xl" height="md" />
          </div>
          <div className="w-48">
            <FormLoader width="xl" height="md" />
          </div>
          <div className="w-48">
            <FormLoader width="xl" height="md" />
          </div>
        </div>
      </div>
      <div className="h-14">
        <FormLoader width="xl" height="lg" />
      </div>
      <div className="pt-2">
        <FormLoader width="xl" height="md" />
        <FormLoader width="xl" height="md" />
        <FormLoader width="xl" height="md" />
      </div>
    </div>
  );
};

export default DynamicPageLoader;
