import React, { forwardRef, Ref, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import { Button, ModalDialog, ModalDialogRef } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';

export type UsernameConfirmationModalResult = 'continue' | 'cancel' | 'dismiss';

export interface UsernameConfirmationModalRef {
  show: () => Promise<UsernameConfirmationModalResult>;
}

const UsernameConfirmationModal = (_: unknown, ref: Ref<UsernameConfirmationModalRef>) => {
  const { t } = useTranslation();
  const modalRef = useRef<ModalDialogRef>(null);

  const promiseResolveRef =
    useRef<(value: UsernameConfirmationModalResult | PromiseLike<UsernameConfirmationModalResult>) => void>();

  const show = () => {
    modalRef.current?.show();

    return new Promise<UsernameConfirmationModalResult>((resolve) => {
      promiseResolveRef.current = resolve;
    });
  };

  useImperativeHandle(ref, () => ({
    show,
  }));

  const handleDialogHidden = useCallback(() => {
    if (promiseResolveRef.current) {
      promiseResolveRef.current('dismiss');
      promiseResolveRef.current = undefined;
    }
  }, []);

  const handleContinue = useCallback(() => {
    if (promiseResolveRef.current) {
      promiseResolveRef.current('continue');
      promiseResolveRef.current = undefined;
    }
    modalRef.current?.close();
  }, []);

  const handleCancel = useCallback(() => {
    if (promiseResolveRef.current) {
      promiseResolveRef.current('cancel');
      promiseResolveRef.current = undefined;
    }
    modalRef.current?.close();
  }, []);

  const footer = useMemo(
    () => (
      <div className="flex gap-2 justify-end">
        <Button type="primary" variant="alternative" onClick={handleContinue}>
          {t('continue')}
        </Button>
        <Button variant="alternative" onClick={handleCancel}>
          {t('cancel')}
        </Button>
      </div>
    ),
    [handleContinue, handleCancel]
  );

  return (
    <ModalDialog
      ref={modalRef}
      header={t('warning')}
      hidden={handleDialogHidden}
      footer={footer}
      showCloseIcon
      renderChildrenLazily
      shouldCheckZIndex
    >
      <h1>{t('change-username-confirmation')}</h1>
    </ModalDialog>
  );
};

export default forwardRef(UsernameConfirmationModal);
