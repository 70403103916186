import { DropDownResult, OdinDataFilter, OdinDataRetrievalOptions } from '@myosh/odin-components';
import { isEqual } from 'lodash';
import React, { useRef } from 'react';
import { LookupTypeFieldApiProps, PersonOptionApiProps } from '../../../@types/options';
import { useLazyPersonFieldOptionsQuery } from '../../../redux/services/field';
import { useAppSelector } from '../../../redux/hooks';
import { selectedGlobalHierarchies, selectedMatchingOption } from '../../../redux/slices/global-hierarchies';
import { buildGlobalHierarchyFiltersUrl } from '../../../common/common-functions';

// A custom hook that provides a trigger to fetch person field options.
const useFetchPersonFieldOptions = (textField: string) => {
  const globalFilters = useAppSelector(selectedGlobalHierarchies);
  const globalFiltersMatchingOption = useAppSelector(selectedMatchingOption);
  const nextRef = useRef<string>();
  const fieldFiltersRef = useRef<Record<string, OdinDataFilter>>();

  const [getPersonFieldOptions] = useLazyPersonFieldOptionsQuery();

  const fetchPersonFieldOptions = async (
    id: React.Key,
    options: OdinDataRetrievalOptions & LookupTypeFieldApiProps
  ) => {
    const canPerformRequest = id && options && (options.page === 1 || nextRef.current || options.getAllData);

    if (canPerformRequest) {
      const haveFiltersChanged = !isEqual(options?.fieldFilters, fieldFiltersRef.current);
      if (haveFiltersChanged) {
        nextRef.current = undefined;
      }
      fieldFiltersRef.current = options?.fieldFilters;

      if (options?.fieldFilters?.[textField]) {
        options.fieldFilters.value = options.fieldFilters[textField];
        delete options.fieldFilters[textField];
      }

      const personOptions: PersonOptionApiProps = {
        queryId: Number(id),
        limit: options.getAllData ? -1 : (options.pageSize ?? 50),
        filters: options.fieldFilters,
        next: nextRef.current,
        keyValue: options.keyValue,
        hierarchyFieldId: options.hierarchyFieldId,
        globalHierarchyFilters: buildGlobalHierarchyFiltersUrl(globalFilters, globalFiltersMatchingOption),
      };

      try {
        // prefer cache value to limit amount of requests sent out
        const data = await getPersonFieldOptions(personOptions, true).unwrap();
        nextRef.current = data.next;

        const personFieldOptions: DropDownResult[] | undefined = data.items?.map((item) => {
          return {
            value: item.id,
            text: item.value,
            managerId: item?.managerId,
            managerText: item?.managerValue,
          };
        });

        return personFieldOptions;
      } catch (_) {
        nextRef.current = undefined;
      }
    }
  };

  return fetchPersonFieldOptions;
};

export default useFetchPersonFieldOptions;
