import React from 'react';
import { Control, Controller } from 'react-hook-form';
import ExportButtonField, { ExportButtonFieldProps } from './export-button-field.component';

interface HfExportButtonFieldProps extends ExportButtonFieldProps {
  control?: Control;
  name?: string;
}

function HfExportButtonField({
  id,
  name = 'export_button',
  control,
  buttonCaption,
  fieldType,
  recordId,
  readOnly,
}: HfExportButtonFieldProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <ExportButtonField
          id={id}
          buttonCaption={buttonCaption}
          fieldType={fieldType}
          recordId={recordId}
          readOnly={readOnly}
        />
      )}
    />
  );
}

export default HfExportButtonField;
