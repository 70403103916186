import React, { useRef } from 'react';
import { ModalDialog, ModalDialogButtonSetting } from '@myosh/odin-components';
import { OdinUser } from '../../../@types/users';
import UserDetails, { UserDetailsRef } from './user-details.component';
import { useTranslation } from 'react-i18next';

interface UserSettingsModalProps {
  onClose: () => void;
  userData: OdinUser | undefined;
  visible: boolean;
}

export default function UserSettingsModal({ onClose, userData, visible }: UserSettingsModalProps) {
  const userDetailsRef = useRef<UserDetailsRef>(null);
  const { t } = useTranslation();

  const onSubmit = () => {
    userDetailsRef.current?.submitForm?.();
  };

  const modalButton: ModalDialogButtonSetting = {
    name: 'save',
    text: t('save'),
    type: 'primary',
    closeModalOnClick: false,
    onClick: onSubmit,
  };

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  return (
    <ModalDialog
      header={t('settings')}
      minDialogWidth={500}
      minDialogHeight={700}
      maxDialogHeight={700}
      buttons={[modalButton]}
      visible={visible}
      hidden={onClose}
      fullScreen={(windowWidth <= 550 || windowHeight <= 550) ?? true}
    >
      <UserDetails ref={userDetailsRef} userId={userData?.id} editable={true} isSettingsView={true} onClose={onClose} />
    </ModalDialog>
  );
}
