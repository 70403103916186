import { ModuleDetailsProps, ModuleDetailsRef } from './module-details.props';
import React, {
  forwardRef,
  Key,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DataGridRef,
  DynamicForm,
  DynamicFormButtonSetting,
  DynamicFormRef,
  DynamicFormSettings,
  JsonDataItem,
  ModalDialog,
  ModalDialogRef,
  OdinDataSender,
  RadioGroupResult,
} from '@myosh/odin-components';
import { v4 } from 'uuid';
import { skip, Subject, take, takeUntil } from 'rxjs';
import { useGetModuleByIdQuery } from '../../../redux/services/api';
// import { ActiveRecordContextProps } from '../../active-record/active-record.component';
import { getDataGridReference } from '../../../services/data-grid.service';
import { cloneDeep } from 'lodash';
import { ModuleFormData } from '../../../@types/modules';
import { getModuleFormSettings, getModuleTargetData, getModuleTypeData } from './module-form-settings';
import { saveModalButtons } from '../../../common/common-administration-utils';
import { useTranslation } from 'react-i18next';
import { FormTitle } from '../../form/form-title.component';
import CloseActiveItemButton from '../../common/close-active-item-button';
import { FormLoading } from '../../form/form-loading.component';
import useProfileData from '../../../hooks/use-profile-data';
import { ModulesPermissions } from '../../../common/user.permissions';
import { saveButton } from '../../../pages/admin/admin-utils';
import { forceAssert } from '../../../common/common-functions';
import { useUserAccess } from '../../../hooks/use-user-access';

const ModuleDetails = (props: ModuleDetailsProps, ref: Ref<ModuleDetailsRef>) => {
  const {
    moduleId,
    title,
    isNewModule = false,
    onClose,
    panelSettings,
    onCloseActiveRecordConfirmationModalRef,
  } = props;

  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const dynamicFormId = useRef(v4());
  const dynamicFormData = useRef<JsonDataItem>();
  const destroySubject = useRef(new Subject<void>());
  const saveModalReference = useRef<ModalDialogRef>(null);
  const dataGridRef = useRef<DataGridRef>();
  const batchSaveInProgressRef = useRef<boolean>();

  const [moduleData, setModuleData] = useState<ModuleFormData>();
  // const [panelContextProps, setPanelContextProps] = useState<ActiveRecordContextProps>();
  const [formButtons, setFormButtons] = useState<Array<DynamicFormButtonSetting>>([]);

  const { t } = useTranslation();
  const { profileData: { user: userData } = {} } = useProfileData();

  const { data, isLoading, isFetching } = useGetModuleByIdQuery(
    { moduleId: moduleId ?? 0 },
    {
      skip: !moduleId,
    }
  );

  const { readOnly, editAccess, setReadOnly } = useUserAccess({
    initialAccess: isNewModule,
    userData,
    permissions: {
      MODIFY: [ModulesPermissions.MODULES_MODIFY, ModulesPermissions.MODULES_CREATE],
      READ: ModulesPermissions.MODULES_READ,
    },
  });

  useEffect(() => {
    panelSettings?.pipe(skip(1), takeUntil(destroySubject.current)).subscribe((settings) => {
      // setPanelContextProps(settings);
      batchSaveInProgressRef.current = settings.batchSaveInProgress;
    });

    onCloseActiveRecordConfirmationModalRef
      ?.subscribeToDialogResult()
      ?.pipe(takeUntil(destroySubject.current))
      .subscribe((result) => {
        if (result.buttonName === 'save' && dynamicFormReference.current?.formIsDirty()) {
          // handleSaveBtnClick(true);
        } else if (result.buttonName === 'discard') {
          onClose(dynamicFormId.current);
        }
      });

    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((gridRef) => {
        if (gridRef) {
          dataGridRef.current = gridRef;
        }
      });

    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
    };
  }, []);

  useEffect(() => {
    if (data && !isFetching) {
      const moduleModified = cloneDeep<ModuleFormData>(data);
      moduleModified.type = forceAssert<RadioGroupResult>(getModuleTypeData(data.type)[0]);
      moduleModified.target = forceAssert<RadioGroupResult>(getModuleTargetData(data.target)[0]);

      setModuleData(moduleModified);
    }
  }, [data, isFetching]);

  const handleSaveBtnClick = useCallback((skipValidation?: boolean) => {
    dynamicFormData.current = dynamicFormReference.current?.getData();
    dynamicFormReference.current?.submitForm(skipValidation ?? false);
  }, []);

  const onCloseUserDetails = () => {
    if (!dynamicFormReference.current?.formIsDirty()) {
      onClose(dynamicFormId.current);
    } else {
      saveModalReference.current
        ?.show()
        ?.pipe(take(1))
        .subscribe((result) => {
          if (result.buttonName === 'save') {
            handleSaveBtnClick();
          } else if (result.buttonName === 'discard') {
            onClose(dynamicFormId.current);
          }
        });
    }
  };

  useEffect(() => {
    if (editAccess) {
      setReadOnly(false);
    } else {
      setReadOnly(true);
    }
  }, [editAccess]);

  useEffect(() => {
    if (!isFetching) {
      const _saveButton = cloneDeep(saveButton);
      _saveButton.onClick = handleSaveBtnClick;
      _saveButton.htmlType = 'button';

      setFormButtons([_saveButton]);
    }
  }, [isFetching, handleSaveBtnClick]);

  const onFormSubmit = useCallback(
    (data: JsonDataItem, sender: OdinDataSender<Key | undefined>) => {
      console.log('data', data, 'sender', sender);
    },
    [moduleId, moduleData, editAccess, dataGridRef]
  );

  useImperativeHandle(ref, () => ({
    submitForm: handleSaveBtnClick,
  }));

  const moduleFormSettings = useMemo<DynamicFormSettings>(() => {
    return getModuleFormSettings();
  }, []);

  return (
    <>
      <div className="flex flex-shrink-0 flex-col">
        <div className="flex">
          <FormTitle title={title} />
          {!isLoading && (
            <div className="flex min-w-min justify-end">
              <CloseActiveItemButton onClick={onCloseUserDetails} />
            </div>
          )}
        </div>
      </div>
      <div className="relative flex flex-grow flex-row">
        {isLoading && <FormLoading />}
        {!isLoading && (
          <DynamicForm
            ref={dynamicFormReference}
            dynamicFormId={dynamicFormId.current}
            data={forceAssert<JsonDataItem>(moduleData)}
            settings={moduleFormSettings}
            // dataRetrieval={dataRetrieval}
            readOnly={readOnly}
            // errors={controlledErrors}
            buttons={formButtons}
            buttonsLocation={0}
            buttonsPosition={1}
            onSubmit={onFormSubmit}
            // onPreSubmit={onPreSubmit}
            // onPostSubmit={onPostSubmit}
            // onGetPreSubmitData={onGetPreSubmitData}
            // onFieldChanged={onFieldChanged}
          />
        )}
      </div>
      <ModalDialog ref={saveModalReference} header={t('save-unsaved-changes')} buttons={saveModalButtons}>
        <div>{t('save-message')}</div>
      </ModalDialog>
    </>
  );
};

export default forwardRef(ModuleDetails);
