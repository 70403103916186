import React from 'react';
import { useTranslation } from 'react-i18next';

interface ConflictsHeaderProps {
  lastModifiedBy?: string;
}

const ConflictsHeader = ({ lastModifiedBy }: ConflictsHeaderProps) => {
  const { t } = useTranslation();

  return (
    <>
      {t('warning')}
      <p className="pb-1 pt-3 font-normal">
        {lastModifiedBy
          ? t('record-resolve-conflicts-warning-message-one', { username: lastModifiedBy })
          : t('record-resolve-conflicts-warning-message-one-no-user')}
      </p>
      <p className="pb-3 pt-1 font-normal">{t('record-resolve-conflicts-warning-message-two')}</p>
    </>
  );
};

export default ConflictsHeader;
