import React, { Key, useEffect, useRef, useState } from 'react';
import {
  DataGridRef,
  DropDownResult,
  DynamicFieldComponentProps,
  ModalDialog,
  ModalDialogButtonSetting,
  ModalDialogRef,
  RadioGroupResult,
} from '@myosh/odin-components';
import {
  recordApi,
  useCreateRecordCloneJobMutation,
  useGetRecordCloneJobDetailsQuery,
  useLazyGetRecordByIdQuery,
} from '../../../redux/services/record';
import { showInfo, dismiss, showError, showSuccess } from '../../../services/notification.service';
import { useTranslation } from 'react-i18next';
import useActiveRecordRef from '../../../hooks/use-active-record-ref';
import { constructRecordTitle } from '../../record/record-component-util';
import { RecordTitle } from '../../record/record-title.component';
import { useModulesQuery } from '../../../redux/services/api';
import { useParams } from 'react-router-dom';
import { Subject, takeUntil, take } from 'rxjs';
import { getDataGridReference } from '../../../services/data-grid.service';
import i18next from '../../../i18n';
import { RecordSaveState } from '../../record/record.component';
import CloneRecordWizzard from './clone-record-wizard.component';
import WrapperStyledButtonField from '../../common/wrapper-styled-button-field';
import { CloneRecordsApiProps } from '../../../@types/records';
import { useAppDispatch } from '../../../redux/hooks';

export interface CloneRecordButtonFieldProps extends DynamicFieldComponentProps<unknown> {
  id?: Key;
  buttonCaption?: string;
  recordId?: number;
  formId: number;
  saveRecord?: () => void;
  recordSaveState?: RecordSaveState;
  shouldUseCloneWizzard?: boolean;
  cloneWizardTargetPersonField?: number;
  cloneWizardTargetWorkflowStep?: number;
  allowUserAttachmentCloning?: boolean;
  moduleFormCloneTargetId?: number;
}

const confirmationDialogButtons: Array<ModalDialogButtonSetting> = [
  {
    name: 'save',
    text: i18next.t('save'),
    type: 'primary',
  },
  {
    name: 'discard',
    text: i18next.t('discard'),
  },
];

const cloneAttachmentsDialogButtons: Array<ModalDialogButtonSetting> = [
  {
    name: 'Yes',
    text: i18next.t('yes'),
    type: 'primary',
  },
  {
    name: 'No',
    text: i18next.t('no'),
  },
];

export interface CloneWizardState {
  status?: DropDownResult;
  personField?: DropDownResult;
  users: DropDownResult[];
  groups: DropDownResult[];
  attachmentOption?: RadioGroupResult;
}

export const initialCloneWizardState = {
  users: [],
  groups: [],
  attachmentOption: { text: 'Yes', value: true },
  status: undefined,
  personField: undefined,
};

function CloneRecordButtonField({
  id,
  buttonCaption,
  recordId,
  readOnly,
  formId,
  saveRecord,
  recordSaveState,
  shouldUseCloneWizzard,
  cloneWizardTargetPersonField,
  cloneWizardTargetWorkflowStep,
  allowUserAttachmentCloning,
  moduleFormCloneTargetId,
}: CloneRecordButtonFieldProps) {
  const [recordCloneJobId, setRecordCloneJobId] = useState(0);
  const [cloneWizardState, setCloneWizardState] = useState<CloneWizardState>(initialCloneWizardState);
  const [showInProgress, setShownInProgress] = useState<boolean>(false);
  const isNewRecord = recordSaveState === RecordSaveState.New;

  const toastId = useRef<string>();
  const { t } = useTranslation();
  const { viewId } = useParams();
  const { activeRecordReference } = useActiveRecordRef();
  const dispatch = useAppDispatch();

  const destroySubject = useRef<Subject<void>>();
  const gridRef = useRef<DataGridRef>();
  const promptBeforeCloningNewRecordRef = useRef<ModalDialogRef>(null);
  const promptForAttachmentPermissionsBeforeCloning = useRef<ModalDialogRef>(null);

  const { data: modules } = useModulesQuery({});
  const [getRecordById] = useLazyGetRecordByIdQuery();
  const [createRecordCloneJob] = useCreateRecordCloneJobMutation();
  const {
    data: recordJobDetails,
    isFetching: recordJobDetailsFetching,
    isError,
  } = useGetRecordCloneJobDetailsQuery(recordCloneJobId, {
    skip: recordCloneJobId === 0,
    pollingInterval: 6000,
  });

  useEffect(() => {
    if (recordJobDetails?.status === 'FINISHED') {
      setRecordCloneJobId(0);
      setShownInProgress(false);
      showSuccess(t('performing-cloning-success'));
      if (!shouldUseCloneWizzard) {
        onClick(recordJobDetails.clonedRecordIds);
      }
      dispatch(
        recordApi.util.invalidateTags([{ type: 'Record', id: 'LIST' }, 'TotalRecordCount', 'AssignedRecordsCount'])
      );
    }
    if (recordJobDetails?.jobDetails === 'Unexpected error' || isError) {
      setRecordCloneJobId(0);
      setShownInProgress(false);
      showError(t('performing-cloning-error'));
    }
  }, [recordJobDetails, recordJobDetailsFetching, isError]);

  useEffect(() => {
    destroySubject.current = new Subject<void>();
    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((dataGridRef) => {
        if (dataGridRef) {
          gridRef.current = dataGridRef;
        }
      });

    return () => {
      destroySubject.current?.next();
      destroySubject.current?.complete();
    };
  }, []);

  useEffect(() => {
    showInProgress
      ? (toastId.current = showInfo(t('performing-cloning-message'), { duration: Infinity }))
      : dismiss(toastId.current);
  }, [showInProgress]);

  const promptOnRecordCloneButtonClick = () => {
    if (isNewRecord) {
      saveNewRecord();
    } else if (allowUserAttachmentCloning && !shouldUseCloneWizzard) {
      onAttachmentsModalComplete();
    } else {
      cloneRecordsAsync();
    }
  };

  const saveNewRecord = () => {
    promptBeforeCloningNewRecordRef.current
      ?.show()
      ?.pipe(take(1))
      .subscribe((result) => {
        if (result.buttonName === 'save') {
          saveRecord?.();
        }
      });
  };

  const onAttachmentsModalComplete = () => {
    promptForAttachmentPermissionsBeforeCloning.current
      ?.show()
      ?.pipe(take(1))
      .subscribe((result) => {
        if (result.buttonName === 'Yes') {
          cloneRecordsAsync(true);
        } else if (result.buttonName === 'No') {
          cloneRecordsAsync(false);
        }
      });
  };

  const cloneRecordsAsync = (cloneAttachment?: boolean) => {
    let recordCloneJobApiProps: CloneRecordsApiProps = { recordId: recordId ?? 0, fieldId: id as number };
    if (!shouldUseCloneWizzard && allowUserAttachmentCloning) {
      recordCloneJobApiProps = { ...recordCloneJobApiProps, cloneAttachment };
    } else if (shouldUseCloneWizzard) {
      const listOfUserIds = cloneWizardState.users.map((item) => {
        return item.value as number;
      });
      const listOfGroupIds = cloneWizardState.groups.map((item) => {
        return item.value as number;
      });
      recordCloneJobApiProps = {
        ...recordCloneJobApiProps,
        targetPersonFieldId: cloneWizardTargetPersonField ?? (cloneWizardState.personField?.value as number) ?? 0,
        targetWorkflowStepId: cloneWizardTargetWorkflowStep ?? (cloneWizardState.status?.value as number) ?? 0,
        userIds: listOfUserIds,
        groupIds: listOfGroupIds,
      };
      if (allowUserAttachmentCloning) {
        recordCloneJobApiProps = {
          ...recordCloneJobApiProps,
          cloneAttachment: (cloneWizardState.attachmentOption?.value as boolean) ?? true,
        };
      }
    }
    setShownInProgress(true);
    createRecordCloneJob(recordCloneJobApiProps)
      .unwrap()
      .then((value) => {
        setRecordCloneJobId(value.backgroundJobId);
        setShownInProgress(true);
      })
      .catch(() => {
        setShownInProgress(false);
        showError(t('performing-cloning-error'));
      });
  };

  const onClick = (clonedRecordId: string) => {
    if (id && recordId && clonedRecordId) {
      getRecordById({ id: clonedRecordId, keyTypeId: true })
        .unwrap()
        .then((data) => {
          const record = data.original;
          const creationDate = record.creationDate ? new Date(record.creationDate) : undefined;
          const title = constructRecordTitle(record.formName, record.docNo, record.status, record.title);
          const details = <RecordTitle title={title} date={creationDate} />;
          const module = modules?.find((item) => item.viewId === viewId);

          activeRecordReference.current?.addActiveRecord(String(record.id), module?.icon || '', details, undefined, {
            title,
            recordId: record.id,
            viewId: viewId,
            moduleIcon: module?.icon,
            formId: record.formId,
            formName: record.formName,
            docNo: record.docNo,
            status: record.status,
            vikingLinkUrl: record.vikingLinkUrl,
            displayText: record.displayText,
            openInEditMode: true,
          });

          // // refresh the data grid to show the cloned record
          gridRef.current?.api.data.getDataOverwritePageCache();
        })
        .catch(() => {
          showError(t('record-toast-message'), { id: clonedRecordId });
        });
    }
  };

  return (
    <>
      {shouldUseCloneWizzard ? (
        <CloneRecordWizzard
          disabled={readOnly}
          buttonCaption={buttonCaption}
          onClick={promptOnRecordCloneButtonClick}
          formId={formId}
          cloneWizardTargetPersonField={cloneWizardTargetPersonField}
          cloneWizardTargetWorkflowStep={cloneWizardTargetWorkflowStep}
          allowUserAttachmentCloning={allowUserAttachmentCloning}
          moduleFormCloneTargetId={moduleFormCloneTargetId}
          cloneWizardState={cloneWizardState}
          setCloneWizardState={setCloneWizardState}
          saveNewRecord={saveNewRecord}
          isNewRecord={isNewRecord}
        />
      ) : (
        <WrapperStyledButtonField
          onClick={promptOnRecordCloneButtonClick}
          disabled={readOnly}
          buttonCaption={buttonCaption}
          icon="FileCopy"
        />
      )}
      <ModalDialog
        ref={promptBeforeCloningNewRecordRef}
        header={t('save-unsaved-changes')}
        buttons={confirmationDialogButtons}
      >
        {t('save-message')}
      </ModalDialog>
      <ModalDialog
        ref={promptForAttachmentPermissionsBeforeCloning}
        buttons={cloneAttachmentsDialogButtons}
        header={t('attachments')}
      >
        {t('clone-records-attachments-label')}
      </ModalDialog>
    </>
  );
}

export default CloneRecordButtonField;
