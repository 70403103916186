import React from 'react';
import {
  DataSearchLocation,
  DropDownNoSelection,
  DropDownResult,
  DynamicFieldComponentProps,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import { useLazyTrainingFieldOptionsQuery } from '../../../redux/services/field';
import OdinDropDown from '../custom-drop-downs/odin-drop-down.component';

// The training field specific blank value
const deselectValue: DropDownResult = {
  value: null,
  text: '',
};

export default function TrainingField({
  label,
  value,
  onChange,
  readOnly,
  required,
  error,
  inputRef,
}: DynamicFieldComponentProps<DropDownResult | DropDownNoSelection>) {
  const [trainingFieldOptions] = useLazyTrainingFieldOptionsQuery();

  const getOptionsData = {
    getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
      if (options && options.page && options.pageSize) {
        try {
          if (options.fieldFilters?.text) {
            options.fieldFilters['name'] = options.fieldFilters.text;
            delete options.fieldFilters.caption;
          }
          const result = await trainingFieldOptions({
            page: options.page,
            pageSize: options.pageSize,
            filters: { ...options.fieldFilters, archived: { value: false, comparison: '=' } },
          }).unwrap();

          const data = result.map((item) => {
            return {
              renewalInterval: item.renewalInterval,
              value: item.id,
              text: item.name,
            };
          });

          subscriber.sendData({ data, requestId: options.requestId });
        } catch (_) {
          subscriber.sendData();
        }
      }
    },
  };

  const onValueChange = (fieldValue: DropDownResult | DropDownNoSelection) => {
    if (onChange && !!fieldValue) {
      onChange(fieldValue);
    }
  };

  return (
    <OdinDropDown
      label={label}
      textField="text"
      valueField="value"
      value={value}
      onChange={(value) => onValueChange(value as DropDownResult | DropDownNoSelection)}
      readOnly={readOnly}
      required={required}
      allowSearch={true}
      error={error}
      data={getOptionsData}
      inputRef={inputRef}
      deselectValue={deselectValue}
      filterLocation={DataSearchLocation.Api}
    />
  );
}
