import React, { useEffect, useState } from 'react';
import { ThumbnailActions } from './thumbnail-actions.component';
import OdinLoadingIndicator from '../../../common/odin-loading-indicator.component';
import { useApiLogger } from '../../../../hooks/use-api-logger';

interface ImageThumbnailProps {
  id: string;
  name: string;
  extension?: string;
  url: string;
  defaultThumbnail: JSX.Element;
  readOnly: boolean;
  attachmentId?: string;
  onDelete: (id: string) => void;
}

export default function ImageThumbnail({
  id,
  name,
  url,
  extension,
  defaultThumbnail,
  readOnly,
  attachmentId,
  onDelete,
}: ImageThumbnailProps) {
  const [loading, setLoading] = useState(false);
  const [imageBlob, setImageBlob] = useState<Blob>();
  const log = useApiLogger();

  useEffect(() => {
    setLoading(true);
    fetch(url)
      .then((result) => result.blob())
      .then((blob) => setImageBlob(blob))
      .catch((exception) => log(`Unable to download image ${name}`, { exception }))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {loading && <OdinLoadingIndicator />}

      {!loading && imageBlob && (
        <img
          src={window.URL.createObjectURL(imageBlob)}
          className="h-full w-full object-cover"
          title={name}
          alt={name}
        />
      )}

      {!loading && defaultThumbnail}

      <ThumbnailActions
        id={id}
        name={name}
        downloadUrl={!loading && imageBlob ? window.URL.createObjectURL(imageBlob) : url}
        previewUrl={
          !loading && imageBlob && extension
            ? window.URL.createObjectURL(new File([imageBlob], name, { type: `image/${extension}` }))
            : undefined
        }
        readOnly={readOnly}
        attachmentId={attachmentId}
        onDelete={onDelete}
      />
    </>
  );
}
