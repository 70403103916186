import React from 'react';
import { Button } from '@myosh/odin-components';
import { Invitation } from './invitation-field.component';
import { useTranslation } from 'react-i18next';

interface InvitationsActionsCellProps {
  showResendInvitationModal: (invitation: Invitation) => void;
  showDeleteCollaboratorModal: (collaboratorId: number) => void;
  invitation: Invitation;
}

const InvitationActionsCell = ({
  showResendInvitationModal,
  showDeleteCollaboratorModal,
  invitation,
}: InvitationsActionsCellProps) => {
  const { t } = useTranslation();

  const editButtonHandler = () => {
    showResendInvitationModal(invitation);
  };

  const removeButtonHandler = () => {
    showDeleteCollaboratorModal(invitation.id);
  };

  return (
    <>
      {invitation.status === 'Pending' && (
        // NOTE: if modifying the padding 'pl-2', please remember to update all other cells
        <div className="flex h-full items-center justify-center pl-2">
          <Button type="text" variant="alternative" onClick={editButtonHandler}>
            {String(t('edit'))}
          </Button>
          <Button type="text" variant="alternative" onClick={removeButtonHandler}>
            {String(t('remove'))}
          </Button>
        </div>
      )}
    </>
  );
};

export default InvitationActionsCell;
