import React, { useEffect, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, Events, EVENTS, STATUS } from 'react-joyride';
import { useUpdateFeatureToursMutation } from '../../../../redux/services/api';
import { showError } from '../../../../services/notification.service';
import { FeatureTours } from '../../../../@types/feature-tours';
import { useTranslation } from 'react-i18next';
import i18next from '../../../../i18n';
import useCustomJoyrideButtonsStyling from '../../../../hooks/use-custom-joyride-buttons-styling';

export interface SettingsMenuTourProps {
  isMenuOpen: boolean;
  featureTourData: FeatureTours;
  setIsMenuOpen: (value: boolean) => void;
}

const steps = [
  {
    target: '.settings-menu-button',
    content: i18next.t('feature-tour-settings-menu-step-one'),
    disableBeacon: true,
    offset: 10,
    popover: {
      placement: 'left',
    },
    spotlightClicks: true,
    disableOverlayClose: true,
    hideFooter: true,
  },
  {
    target: '.logout-menu-item',
    content: i18next.t('feature-tour-settings-menu-step-two'),
    offset: 10,
    popover: {
      placement: 'left',
    },
    spotlightClicks: false,
    disableOverlayClose: true,
    disableBeacon: true,
  },
  {
    target: '.settings-menu-item',
    content: i18next.t('feature-tour-settings-menu-step-three'),
    offset: 10,
    popover: {
      placement: 'left',
    },
    spotlightClicks: false,
    disableOverlayClose: true,
    disableBeacon: true,
  },
  {
    target: '.about-menu-item',
    content: i18next.t('feature-tour-settings-menu-step-four'),
    offset: 10,
    popover: {
      placement: 'left',
    },
    spotlightClicks: false,
    disableOverlayClose: true,
    disableBeacon: true,
  },
];

const SettingsMenuFeatureTour = ({ isMenuOpen, featureTourData, setIsMenuOpen }: SettingsMenuTourProps) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [setFeatureTourAsSeen] = useUpdateFeatureToursMutation();
  const { t } = useTranslation();
  const [runTour, setRunTour] = useState(true);

  const { primaryButton, textButton, closeIconButton } = useCustomJoyrideButtonsStyling();

  useEffect(() => {
    if (isMenuOpen && stepIndex === 0) {
      setStepIndex(1);
    }
  }, [isMenuOpen]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status) || action === 'close') {
      setStepIndex(0);
      if (action === 'close') {
        setRunTour(false);
        setIsMenuOpen(false);
      } else {
        setFeatureTourAsSeen({ ...featureTourData, hasSettingsMenuTourRan: true })
          .unwrap()
          .then(() => setIsMenuOpen(false))
          .catch(() => showError(t('feature-tour-save-fail')));
      }
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as Events[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(nextStepIndex);
    }
  };

  return (
    <Joyride
      steps={steps}
      run={runTour}
      stepIndex={stepIndex}
      continuous
      showProgress
      callback={handleJoyrideCallback}
      styles={{
        options: {
          zIndex: 1000,
        },
        buttonNext: primaryButton,
        buttonBack: textButton,
        buttonClose: closeIconButton,
      }}
      locale={{
        back: t('back'),
        close: t('close'),
        last: t('finish'),
        next: t('next'),
      }}
    />
  );
};

export default SettingsMenuFeatureTour;
