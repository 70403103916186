import React, { Ref, forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from 'react';
import { FormTitle } from '../form/form-title.component';
import { RecordNotificationsPermissions } from '../../common/user.permissions';
import useProfileData from '../../hooks/use-profile-data';
import { getNotificationFormSettings } from './notification-form-settings';
import { debounce } from 'lodash';
import { v4 } from 'uuid';
import {
  DataGridRef,
  DynamicFormButtonSetting,
  DynamicForm,
  DynamicFormRef,
  JsonDataItem,
  JsonDataWrapper,
  ModalDialogRef,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  ModalDialog,
} from '@myosh/odin-components';
import { ActiveRecordContextProps } from '../active-record/active-record.component';
import { FormLoading } from '../form/form-loading.component';
import { BehaviorSubject, Subject, skip, take, takeUntil } from 'rxjs';
import { getDataGridReference } from '../../services/data-grid.service';
import { ExtendedDynamicFormSettings, forceAssert } from '../../common/common-functions';
import { OccupationItem, OccupationMutationResponse } from '../../@types/occupations';
import { FormChangedFields } from '../../@types/common';
import { getChangedFieldsData, saveModalButtons } from '../../common/common-administration-utils';
import { saveButton } from '../../pages/admin/admin-utils';
import usePerformingSaveToast from '../../hooks/use-performing-save-toast';
import { showError, showSuccess } from '../../services/notification.service';
import { useTranslation } from 'react-i18next';
import useDynamicFormNotifier from '../../hooks/use-dynamic-form-notifier';
import CloseActiveItemButton from '../common/close-active-item-button';
import { useGetNotificationByIdQuery } from '../../redux/services/notification';
import { RecordNotification } from '../../@types/record-notifications';
import { createUINotitificationData, getNotificationsFieldOptions } from './notifications-utils';

export interface NotificationDetailsProps {
  notificationId: number;
  title: string;
  editable?: boolean;
  isNewNotification?: boolean;
  onClose: (id: string) => void;
  panelSettings?: BehaviorSubject<ActiveRecordContextProps>;
  onCloseActiveRecordConfirmationModalRef?: ModalDialogRef | null;
}
interface NotificationDetailsRef {
  submitForm?: () => void;
}

const NotificationDetails = (props: NotificationDetailsProps, ref: Ref<NotificationDetailsRef>) => {
  const {
    notificationId,
    title,
    editable,
    isNewNotification = false,
    onClose,
    panelSettings,
    onCloseActiveRecordConfirmationModalRef,
  } = props;

  const [notification, setNotification] = useState<RecordNotification>();
  const [readOnly, setReadOnly] = useState<boolean>(Boolean(notificationId) != editable);
  const [panelContextProps, setPanelContextProps] = useState<ActiveRecordContextProps>();
  const [formButtons, setFormButtons] = useState<Array<DynamicFormButtonSetting>>();

  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const saveModalReference = useRef<ModalDialogRef>(null);
  const dataGridRef = useRef<DataGridRef>();
  const destroySubject = useRef(new Subject<void>());
  const dynamicFormId = useRef(v4());
  const closeOnModalSaveRef = useRef(false);
  const formSettings = useRef<ExtendedDynamicFormSettings>(getNotificationFormSettings());

  const { profileData: { user: userData } = {} } = useProfileData();
  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useGetNotificationByIdQuery(notificationId, {
    skip: notificationId === undefined,
  });

  const { notifyDirty, notifySaveSucceeded, notifySaveFailed } = useDynamicFormNotifier(dynamicFormId.current);

  const { hidePerformingSaveToast } = usePerformingSaveToast({
    shouldFreezeActiveRecord: true,
    disabled: panelContextProps?.batchSaveInProgress,
  });

  useEffect(() => {
    if (isNewNotification) {
      setReadOnly(false);
    } else if (userData) {
      const { adminAccess, superUser } = userData;
      const hasRequiredRole = adminAccess
        ? adminAccess.includes(RecordNotificationsPermissions.NOTIFICATIONS_MODIFY) ||
          adminAccess.includes(RecordNotificationsPermissions.NOTIFICATIONS_CREATE)
        : false;
      if ((adminAccess && superUser) || hasRequiredRole) {
        setReadOnly(false);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (data && !isFetching) {
      setNotification(createUINotitificationData(data));
      const newFormButtons: Array<DynamicFormButtonSetting> = [saveButton];
      if (readOnly) {
        setFormButtons(newFormButtons.map((button) => ({ ...button, disabled: true })));
      } else {
        setFormButtons(newFormButtons);
      }
    }
  }, [data, isFetching, readOnly]);

  useEffect(() => {
    panelSettings?.pipe(skip(1), takeUntil(destroySubject.current)).subscribe((settings) => {
      setPanelContextProps(settings);
    });

    onCloseActiveRecordConfirmationModalRef
      ?.subscribeToDialogResult()
      ?.pipe(takeUntil(destroySubject.current))
      .subscribe((result) => {
        if (result.buttonName === 'save' && dynamicFormReference.current?.formIsDirty()) {
          dynamicFormReference.current?.submitForm(true);
        } else if (result.buttonName === 'discard') {
          onClose(dynamicFormId.current);
        }
      });

    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((gridRef) => {
        if (gridRef) {
          dataGridRef.current = gridRef;
        }
      });

    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    submitForm: dynamicFormReference.current?.submitForm,
  }));

  useEffect(() => {
    if (notification?.archived) {
      setReadOnly(true);
    }
  }, [notification]);

  const showSaveToast = () => {
    if (!panelContextProps?.batchSaveInProgress) {
      showSuccess(t('record-saved'));
    }
    notifySaveSucceeded(dynamicFormId.current);
    closeOnModalSaveRef.current = true;
    if (!notificationId || closeOnModalSaveRef.current) {
      onClose(dynamicFormId.current);
    }
  };

  const showErrorNotificationToast = () => {
    showError(t('occupation-details-save-failed'));
    notifySaveFailed(dynamicFormId.current);
  };

  const createNotification = useCallback((data: JsonDataItem) => {
    saveNotificationData(data, (response) => {
      const convertedResponse = forceAssert<OccupationMutationResponse>(response);
      if (convertedResponse?.data?.result) {
        showSaveToast();
        refreshGrid();
      } else if (convertedResponse?.error) {
        hidePerformingSaveToast();
        showErrorNotificationToast();
      }
    });
  }, []);

  const onFormSubmit = (data: JsonDataItem) => {
    if (notificationId && notification) {
      const dirtyFields = dynamicFormReference.current?.formDirtyFields() || {};
      const patchData = getChangedFieldsData(forceAssert<FormChangedFields<OccupationItem>>(dirtyFields), data);
      saveNotificationData({ id: notificationId, ...patchData }, (response) => {
        const convertedResponse = forceAssert<OccupationMutationResponse>(response);
        if (convertedResponse?.data?.result) {
          dataGridRef.current?.api.data.recordChanged(notificationId);
          hidePerformingSaveToast();
          showSaveToast();
        } else if (convertedResponse?.error) {
          hidePerformingSaveToast();
          showErrorNotificationToast();
        }
      });
    } else {
      createNotification(data);
    }
  };

  const refreshGrid = () => {
    dataGridRef.current?.api.data.getDataOverwritePageCache();
  };

  const saveNotificationData = (
    notificationData: Partial<OccupationItem>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    successCallback: (response?: unknown) => void
  ) => {
    //showPerformingSaveToast();
    if (notificationData.id) {
      //TODO implement update notification
    } else {
      //TODO implement add notification
    }
  };

  const onCloseNotificationDetails = () => {
    if (!dynamicFormReference.current?.formIsDirty()) {
      onClose(dynamicFormId.current);
    } else {
      saveModalReference.current
        ?.show()
        ?.pipe(take(1))
        .subscribe((result) => {
          if ('save' === result.buttonName) {
            const data = dynamicFormReference.current?.getData() || {};
            if (notificationId) {
            } else if (!notificationId) {
              createNotification(data);
            }
          } else if ('discard' === result.buttonName) {
            onClose(dynamicFormId.current);
          }
        });
    }
  };

  const dataRetrieval: OdinDataRetrieval = {
    getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
      if ('COMBOBOX' === options?.fieldType || 'OPTIONGROUP' === options?.fieldType) {
        await getNotificationsFieldOptions(subscriber, options);
      } else {
        subscriber.sendData();
      }
    },
  };

  const onFieldChanged = debounce(() => notifyDirty(dynamicFormReference.current), 500);

  return (
    <>
      <div className="flex flex-shrink-0 flex-col">
        <div className="flex">
          <FormTitle title={title} />
          {!isLoading && (
            <div className="flex min-w-min justify-end">
              <CloseActiveItemButton onClick={onCloseNotificationDetails} />
            </div>
          )}
        </div>
      </div>
      <div className="admin-form-default">
        {!formSettings.current && <FormLoading />}
        {formSettings.current && (
          <DynamicForm
            ref={dynamicFormReference}
            dynamicFormId={dynamicFormId.current}
            data={notification}
            settings={formSettings.current}
            dataRetrieval={dataRetrieval}
            buttons={formButtons}
            buttonsLocation={0}
            readOnly={readOnly}
            buttonsPosition={1}
            onSubmit={onFormSubmit}
            onFieldChanged={onFieldChanged}
          />
        )}
      </div>
      <ModalDialog ref={saveModalReference} header={t('save-unsaved-changes')} buttons={saveModalButtons}>
        <div>{t('save-message')}</div>
      </ModalDialog>
    </>
  );
};
export default forwardRef(NotificationDetails);
