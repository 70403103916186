import { DynamicFieldComponentProps } from '@myosh/odin-components';
import React, { useEffect, useRef, useState } from 'react';
import { useLazyGetRecordFieldTemplateExportQuery } from '../../../redux/services/record';
import WrapperStyledButtonField from '../../common/wrapper-styled-button-field';
import { dismiss, showInfo } from '../../../services/notification.service';
import { useTranslation } from 'react-i18next';

export interface ExportButtonFieldProps extends DynamicFieldComponentProps<unknown> {
  buttonCaption?: string;
  fieldType?: string;
  recordId?: number;
}

function ExportButtonField({ id, buttonCaption, recordId, fieldType, readOnly }: ExportButtonFieldProps) {
  const [getRecordFieldTemplateExport] = useLazyGetRecordFieldTemplateExportQuery();
  const [showInProgress, setShownInProgress] = useState<boolean>(false);
  const toastId = useRef<string>();
  const { t } = useTranslation();

  useEffect(() => {
    showInProgress
      ? (toastId.current = showInfo(t('performing-file-export'), { duration: Infinity }))
      : dismiss(toastId.current);
  }, [showInProgress]);

  const getFileExtension = (fieldType: string) => {
    switch (fieldType) {
      case 'PDF_EXPORT':
        return '.pdf';
      case 'WORD_EXPORT':
        return '.docx';
    }
  };

  const getIcon = () => {
    switch (fieldType) {
      case 'PDF_EXPORT':
        return 'FilePdf';
      case 'WORD_EXPORT':
        return 'FileWord2';
    }
  };

  const onClick = () => {
    if (id && recordId && fieldType) {
      setShownInProgress(true);
      getRecordFieldTemplateExport({ recordId: recordId, fieldId: Number(id), fieldType: fieldType })
        .unwrap()
        .then((response) => {
          const button = document.createElement('a');
          button.href = response as string;
          button.download = `${buttonCaption}${getFileExtension(fieldType)}`;
          button.click();
        })
        .finally(() => {
          setShownInProgress(false);
        });
    }
  };

  return (
    <WrapperStyledButtonField
      onClick={onClick}
      disabled={readOnly || showInProgress}
      buttonCaption={buttonCaption}
      icon={getIcon()}
    />
  );
}

export default ExportButtonField;
