import {
  CheckboxChangeEventType,
  DynamicFieldDataProps,
  DynamicFormRequiredType,
  DynamicFormSettings,
} from '@myosh/odin-components';
import i18next from '../../../i18n';
import { forceAssert } from '../../../common/common-functions';
import HfDropDownMultiSelect from '../../fields/custom-drop-downs/hf-drop-down-multi-select.component';

const dataSettings: DynamicFieldDataProps = {
  valueField: 'value',
  textField: 'text',
};

export const getOccupationFormSettings = (readOnly: boolean): DynamicFormSettings => {
  return {
    id: 0,
    caption: '',
    fields: [
      {
        id: 'caption',
        fieldType: 'TEXTFIELD',
        fieldName: 'caption',
        caption: i18next.t('name'),
        position: 0,
        required: DynamicFormRequiredType.True,
        permissions: { read: true, edit: !readOnly },
      },
      {
        id: 'roles',
        fieldType: 'DROPDOWNMULTISELECT',
        fieldName: 'roles',
        caption: i18next.t('groups'),
        position: 1,
        dataSettings: dataSettings,
        permissions: { read: true, edit: !readOnly },
      },
      {
        id: 'external',
        fieldType: 'CHECKBOX',
        fieldName: 'external',
        caption: i18next.t('external-only'),
        position: 2,
        dataSettings: dataSettings,
        permissions: { read: true, edit: !readOnly },
      },
    ],
    fieldValueConverters: {
      CHECKBOX: (value) => {
        const convertedValue = forceAssert<CheckboxChangeEventType>(value);
        return convertedValue.checked;
      },
    },
    customFieldComponents: {
      DROPDOWNMULTISELECT: HfDropDownMultiSelect,
    },
  };
};
