import {
  DynamicFieldDataProps,
  DynamicForm,
  DynamicFormButtonSetting,
  DynamicFormRef,
  DynamicFormRequiredType,
  DynamicFormSettings,
  JsonDataItem,
  JsonDataWrapper,
  ModalDialog,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import i18next from 'i18next';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { RiskMatrixDataItem } from '../../../@types/risk-matrix-field';
import { useEditRiskRatingMutation, useRiskMatrixRiskLevelsQuery } from '../../../redux/services/risk-matrix';
import HfDropDown from '../custom-drop-downs/hf-drop-down.component';
import { DropDownValue } from '../../../@types/common';
import { handleRiskMatrixApiResponse } from './risk-rating-functions';

interface RiskRatingModalProps {
  visible: boolean;
  riskMatrixId: number;
  selectedRiskMatrixCell?: RiskMatrixDataItem;
  handleModalClose: () => void;
}

const dataSettings: DynamicFieldDataProps = {
  valueField: 'value',
  textField: 'text',
};

const formSettings: DynamicFormSettings = {
  id: 0,
  caption: '',
  fields: [
    {
      id: 'rating',
      fieldType: 'TEXTFIELD',
      fieldName: 'rating',
      caption: i18next.t('rating'),
      position: 0,
      required: DynamicFormRequiredType.True,
      permissions: { read: true, edit: true },
    },
    {
      id: 'riskLevel',
      fieldType: 'COMBOBOX',
      fieldName: 'riskLevel',
      caption: i18next.t('risk-level'),
      position: 1,
      dataSettings: dataSettings,
      required: DynamicFormRequiredType.True,
      permissions: { read: true, edit: true },
    },
  ],
  customFieldComponents: {
    COMBOBOX: HfDropDown,
  },
};

const RiskRatingModal = ({ selectedRiskMatrixCell, riskMatrixId, visible, handleModalClose }: RiskRatingModalProps) => {
  const { t } = useTranslation();
  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const dynamicFormId = useRef(v4());

  const { data: riskLevelsData } = useRiskMatrixRiskLevelsQuery(riskMatrixId);
  const [editRiskRating] = useEditRiskRatingMutation();
  const dataRetrieval = useMemo(() => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if ('COMBOBOX' === options?.fieldType && options.page === 1) {
          subscriber.sendData({
            data: riskLevelsData?.map((item) => {
              return { value: item.id, text: item.caption };
            }),
          });
        } else {
          subscriber.sendData();
        }
      },
    };
  }, [riskLevelsData]);

  const onFormSubmit = (data: JsonDataItem) => {
    const payload = {
      id: data?.cellId,
      rating: data.rating,
      riskLevel: {
        id: (data?.riskLevel as DropDownValue)?.value,
      },
    };
    handleRiskMatrixApiResponse(editRiskRating(payload as Partial<RiskMatrixDataItem>).unwrap());
    handleModalClose();
  };

  const confirmationDialogButtons: Array<DynamicFormButtonSetting> = [
    {
      name: 'save',
      text: i18next.t('save'),
      htmlType: 'submit',
      type: 'primary',
      variant: 'alternative',
    },
    {
      name: 'discard',
      text: i18next.t('discard'),
      htmlType: 'button',
      type: 'default',
      variant: 'alternative',
      onClick: handleModalClose,
    },
  ];

  return (
    <ModalDialog
      header={`${t('risk-rating')} [${selectedRiskMatrixCell?.riskLevelCaptions?.likelihoodCaption} / ${selectedRiskMatrixCell?.riskLevelCaptions?.consequenceCaption}]`}
      visible={visible}
      hidden={handleModalClose}
      minDialogWidth={400}
      closeOnEscape={false}
      buttonsCloseModal={false}
    >
      <DynamicForm
        ref={dynamicFormReference}
        dynamicFormId={dynamicFormId.current}
        dataRetrieval={dataRetrieval}
        data={{
          ...selectedRiskMatrixCell,
          riskLevel: {
            text: selectedRiskMatrixCell?.caption?.translations[0].value,
            value: selectedRiskMatrixCell?.id,
          },
        }}
        settings={formSettings}
        readOnly={false}
        buttons={confirmationDialogButtons}
        buttonsLocation={1}
        buttonsPosition={1}
        onSubmit={onFormSubmit}
        validationEnabled={true}
      />
    </ModalDialog>
  );
};

export default RiskRatingModal;
