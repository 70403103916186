import {
  DomTargetPosition,
  DynamicFieldComponentProps,
  ErrorLabel,
  FieldLabel,
  Link,
  OverlayPanel,
  RequiredIndicator,
  useTestTag,
  combineRefs,
  EmptyFieldLabel,
} from '@myosh/odin-components';
import cx from 'classnames';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiskRatingValueDisplayKey } from '../../../@types/forms';
import { RiskMatrixCellDataProps, RiskMatrixDataItem, RiskMatrixRatingValue } from '../../../@types/risk-matrix-field';
import { useRiskRatingMatrixConfigQuery } from '../../../redux/services/risk-matrix';
import {
  additionalRiskCellItems,
  additionalRiskLevelCaptions,
  findRiskMatrixCaption,
  findSelectedRiskRating,
  formatRiskRatingDisplayValue,
  riskMatrixCellsSorted,
  uniqueRiskMatrixColumns,
} from './risk-rating-functions';
import RiskRatingMatrix from './risk-rating-matrix.component';
import reducer, { RiskRatingBaseActionType, RiskRatingBaseState } from './risk-rating-reducer';
import { cloneDeep } from 'lodash';

export interface RiskRatingProps extends DynamicFieldComponentProps<RiskMatrixRatingValue> {
  displayDescription?: boolean;
  valueDisplayConfig?: Array<RiskRatingValueDisplayKey>;
  riskMatrixId: number;
}

function RiskRating({
  label,
  value,
  readOnly,
  required,
  error,
  onChange,
  displayDescription,
  valueDisplayConfig,
  riskMatrixId,
}: RiskRatingProps) {
  const initialState: RiskRatingBaseState = {
    riskMatrixCells: [],
    displayValue: '',
    currentDescription: '',
    selectedDescription: '',
  };

  const [riskMatrixVisible, setRiskMatrixVisible] = useState<boolean>(false);
  const [state, dispatcher] = useReducer(reducer, initialState);
  const riskMatrixPanel = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { data: riskMatrixData, isFetching } = useRiskRatingMatrixConfigQuery(riskMatrixId);

  useEffect(() => {
    // The first item is a header row
    const cellPlaceholder: RiskMatrixCellDataProps = {
      //1:1 cell should be empty
      header: [{}],
    };

    if (riskMatrixData && riskMatrixData.length > 0 && !isFetching) {
      const matrixDataByLikelihood = cloneDeep(riskMatrixData).sort(
        (riskMatrix1, riskMatrix2) => (riskMatrix1.likelihood.position ?? 0) - (riskMatrix2.likelihood.position ?? 0)
      );
      for (const riskMatrixElement of matrixDataByLikelihood) {
        const likelihoodRowKey =
          (riskMatrixElement?.likelihood?.caption?.translations[0].value as string) +
          String(riskMatrixElement?.likelihood?.id);
        const consequenceColumns = uniqueRiskMatrixColumns(cellPlaceholder['header'], riskMatrixElement);
        const riskCellItems = additionalRiskCellItems(riskMatrixElement);
        const riskLevelCaptions = additionalRiskLevelCaptions(riskMatrixElement);

        const riskLevelProperties = {
          ratingId: riskCellItems?.ratingId,
          rating: riskCellItems?.rating,
          category: riskCellItems?.category,
          consequencePosition: riskMatrixElement.consequence.position,
          riskLevelCaptions: {
            ...riskMatrixElement.riskLevel.riskLevelCaptions,
            consequenceCaption: riskLevelCaptions.consequenceCaption,
            consequenceDescription: riskLevelCaptions.consequenceDescription,
            likelihoodCaption: riskLevelCaptions.likelihoodCaption,
            likelihoodDescription: riskLevelCaptions.likelihoodDescription,
          },
        };

        if (!consequenceColumns) {
          cellPlaceholder['header'] = [...cellPlaceholder['header'], riskMatrixElement.consequence];
        }

        if (!cellPlaceholder[likelihoodRowKey]) {
          cellPlaceholder[likelihoodRowKey] = [
            riskMatrixElement.likelihood,
            {
              ...riskMatrixElement.riskLevel,
              ...riskLevelProperties,
            },
          ];
        } else {
          cellPlaceholder[likelihoodRowKey] = [
            ...cellPlaceholder[likelihoodRowKey],
            {
              ...riskMatrixElement.riskLevel,
              ...riskLevelProperties,
            },
          ];
        }

        if (riskLevelProperties.ratingId === value?.id) {
          const riskRatingDescriptionValue = findRiskMatrixCaption(riskMatrixElement.riskLevel.descriptionCaption);
          dispatcher({
            type: RiskRatingBaseActionType.UpdateCurrentDescription,
            currentDescription: riskRatingDescriptionValue,
          });
        }
      }
      const transformedRiskMatrixCells = riskMatrixCellsSorted(cellPlaceholder);
      dispatcher({
        type: RiskRatingBaseActionType.SetRiskMatrixCells,
        riskMatrixCells: transformedRiskMatrixCells,
      });
    }
  }, [riskMatrixData, isFetching, value]);

  const tagCreator = useTestTag('risk-rating');

  const onRiskMatrixOpen = () => {
    if (!readOnly) {
      setRiskMatrixVisible(true);
    }
  };

  const onRiskMatrixClose = () => {
    setRiskMatrixVisible(false);
  };

  const onRatingPropertiesChange = (riskMatrixCell: RiskMatrixDataItem) => {
    const newDisplayValue = formatRiskRatingDisplayValue(riskMatrixCell, valueDisplayConfig);
    const newRatingDescription = findRiskMatrixCaption(riskMatrixCell.descriptionCaption);
    dispatcher({
      type: RiskRatingBaseActionType.UpdateRiskRatingProperties,
      riskRatingId: riskMatrixCell.ratingId,
      displayValue: newDisplayValue,
      selectedDescription: newRatingDescription,
    });
  };

  const onRiskLevelRatingChange = (riskMatrixCell: RiskMatrixDataItem) => {
    if (riskMatrixCell && riskMatrixCell.ratingId) {
      onRatingPropertiesChange(riskMatrixCell);
      onRiskMatrixClose();
    }
  };

  const onFieldValueChange = (riskMatrixCell: RiskMatrixDataItem) => {
    const consequence = riskMatrixCell.riskLevelCaptions?.consequenceCaption;
    const likelihood = riskMatrixCell.riskLevelCaptions?.likelihoodCaption;
    const localDisplayValue =
      state.displayValue ||
      (value &&
        formatRiskRatingDisplayValue(
          findSelectedRiskRating(state.riskMatrixCells, Number(state.riskRatingId || value.id)),
          valueDisplayConfig
        ));

    onChange?.({
      id: riskMatrixCell.ratingId,
      rating: riskMatrixCell.rating,
      category: riskMatrixCell.category,
      riskLevel: findRiskMatrixCaption(riskMatrixCell.caption),
      consequence,
      likelihood,
      displayValue: localDisplayValue,
    });
  };

  const displayValue = useMemo(
    () =>
      state.displayValue ||
      (value &&
        formatRiskRatingDisplayValue(
          findSelectedRiskRating(state.riskMatrixCells, Number(state.riskRatingId || value.id)),
          valueDisplayConfig
        )),
    [value?.id, state.riskRatingId, state.riskMatrixCells.length]
  );
  const description = state.selectedDescription || state.currentDescription;

  const buttonClasses = cx(
    'text-left',
    {
      'decoration-gray-2 pointer-events-none': readOnly,
    },
    {
      'border border-error': !value && error && !readOnly,
    }
  );

  const divClasses = cx('flex flex-col gap-1 text-sm leading-4 [&>button]:ml-0', {
    'py-1 mx-1': value && !readOnly,
  });

  const combinedRef = combineRefs(riskMatrixPanel, tagCreator('select'));
  return (
    <>
      {label && (
        <div className="flex">
          <FieldLabel label={label} />
          <RequiredIndicator readOnly={readOnly} required={required} />
        </div>
      )}
      <div ref={combinedRef} className={divClasses}>
        {value ? (
          <>
            <Link classNames={buttonClasses} onClick={onRiskMatrixOpen}>
              {displayValue}
            </Link>
            {displayDescription && (
              <>
                <p className="font-bold">{`${t('description')}: `}</p>
                <p ref={tagCreator('description')}>{description}</p>
              </>
            )}
          </>
        ) : readOnly ? (
          <EmptyFieldLabel />
        ) : (
          <>
            <Link classNames={buttonClasses} onClick={onRiskMatrixOpen}>
              {t('please-select')}
            </Link>
            {error && <ErrorLabel>{error}</ErrorLabel>}
          </>
        )}
      </div>
      {!readOnly && (
        <OverlayPanel
          visible={riskMatrixVisible}
          hidden={() => setRiskMatrixVisible(false)}
          target={riskMatrixPanel.current}
          targetPosition={DomTargetPosition.BottomLeft}
          shouldCheckZIndex={true}
          shouldScrollCloseOverlay={true}
          className="bg-mono-1"
          shouldRemainOnScreen={true}
        >
          <div className="m-2">
            <RiskRatingMatrix
              riskMatrixVisible={riskMatrixVisible}
              riskMatrixData={state.riskMatrixCells}
              handleRatingChange={(riskMatrixCellData) => onRiskLevelRatingChange(riskMatrixCellData)}
              handleFieldValueChange={(riskMatrixCellData: RiskMatrixDataItem) =>
                onFieldValueChange(riskMatrixCellData)
              }
            />
          </div>
        </OverlayPanel>
      )}
    </>
  );
}

export default RiskRating;
