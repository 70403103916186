import { useEffect, useRef } from 'react';

/**
 * A hook that opens a Broadcast Channel for communicating with the application service worker.
 *
 * The primary functionality of this hook is to provide the API authentication keys.
 */
export const useServiceWorker = () => {
  const broadcast = useRef<BroadcastChannel>();

  useEffect(() => {
    // Set up channel to communicate with the service worker
    broadcast.current = new BroadcastChannel('odin-app-channel');

    // Listen to channel messages
    broadcast.current.addEventListener('message', (event) => {
      if ('GET_REQUEST_HEADERS' === event?.data?.type) {
        let headers: Record<string, string> = {
          'x-api-key': `${process.env.REACT_APP_API_KEY}`,
        };

        const userState = localStorage.getItem('UserState');
        if (userState) {
          const parsedUserState = JSON.parse(userState || '{}');
          const idToken = parsedUserState?.tokens?.id_token;
          const schema = parsedUserState?.user?.currentSchema;

          if (idToken) {
            headers = {
              ...headers,
              authorization: `Bearer ${idToken}`,
            };
          }

          if (schema) {
            headers = {
              ...headers,
              'Myosh-Schema': schema,
            };
          }
        }

        const clusterConfig = localStorage.getItem('ClusterConfig');
        if (clusterConfig) {
          const parsedClusterConfig = JSON.parse(clusterConfig || '{}');
          const apiKey = parsedClusterConfig?.apiKey;

          if (apiKey) {
            headers = {
              ...headers,
              'x-api-key': apiKey,
            };
          }
        }

        broadcast.current?.postMessage({
          type: 'REQUEST_HEADERS',
          headers,
        });
      }
    });

    return () => {
      broadcast.current?.close();
    };
  }, []);
};
