import React, { useEffect, useState } from 'react';
import { UserActionTypes, useUser } from '@myosh/myosh-login';
import { ModalDialog, ModalDialogButtonSetting } from '@myosh/odin-components';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { api } from '../../../redux/services/api';
import { selectIsManualSchemaUpdate } from '../../../redux/slices/schema';
import { useTranslation } from 'react-i18next';
import { showError } from '../../../services/notification.service';

interface SchemaConfirmationModalProps {
  visible: boolean;
  schema?: string;
}

export function SchemaConfirmationModal({ visible = false, schema }: SchemaConfirmationModalProps) {
  const dispatch = useAppDispatch();
  const { state: userState, dispatch: userDispatch } = useUser();
  const isManualSchemaUpdate = useAppSelector(selectIsManualSchemaUpdate);
  const { t } = useTranslation();

  const [hasUserAccessToSchema, setHasUserAccessToSchema] = useState(false);
  const isSchemaConfirmationModalVisible = visible && !isManualSchemaUpdate && hasUserAccessToSchema;

  useEffect(() => {
    const schemas = userState.user?.schemas;
    if (schemas && schema) {
      const userSchemas = schemas.split(',');
      const hasAccessToSchema = userSchemas.includes(schema);
      if (!hasAccessToSchema) {
        showError(t('error', { message: t('permission-access-schema', { schema: schema }) }));
        onCancel();
      }

      setHasUserAccessToSchema(hasAccessToSchema);
    }
  }, [userState.user, schema]);

  const onConfirm = () => {
    if (schema) {
      userDispatch({ type: UserActionTypes.UpdateCurrentSchema, payload: schema });
      dispatch(api.util.resetApiState());
      window.location.replace(window.location.pathname);
    }
  };

  const onCancel = () => {
    window.location.replace('/');
  };

  const schemaConfirmationModalButtons: Array<ModalDialogButtonSetting> = [
    {
      name: 'ok',
      text: t('ok'),
      type: 'primary',
      onClick: onConfirm,
    },
    {
      name: 'cancel',
      text: t('cancel'),
      onClick: onCancel,
    },
  ];

  return (
    <>
      <ModalDialog
        header={t('warning')}
        minDialogWidth={340}
        hidden={onCancel}
        visible={isSchemaConfirmationModalVisible}
        buttons={schemaConfirmationModalButtons}
        showCloseIcon={false}
      >
        {t('navigating-to-schema', { schema: schema })}
        {/* {`Navigating to ${schema}. Do you wish to proceed?`} */}
      </ModalDialog>
    </>
  );
}
