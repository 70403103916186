import React, { Ref, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FormTitle } from '../../form/form-title.component';
import { GroupsPermissions } from '../../../common/user.permissions';
import useProfileData from '../../../hooks/use-profile-data';
import { getGroupFormSettings } from './group-form-settings';
import { cloneDeep, debounce } from 'lodash';
import { v4 } from 'uuid';
import {
  DataGridRef,
  DynamicFormButtonSetting,
  DynamicForm,
  DynamicFormRef,
  IconButton,
  JsonDataItem,
  ModalDialogRef,
  OdinIcon,
  OdinIconSize,
  ModalDialog,
} from '@myosh/odin-components';
import { ActiveRecordContextProps } from '../../active-record/active-record.component';
import { FormLoading } from '../../form/form-loading.component';
import { BehaviorSubject, Subject, take, takeUntil } from 'rxjs';
import { getDataGridReference } from '../../../services/data-grid.service';
import { ExtendedDynamicFormSettings, forceAssert } from '../../../common/common-functions';
import { GroupItem, GroupMutationResponse, GroupStructure } from '../../../@types/groups';
import { createGroupPatch } from '../../../common/group-functions';
import GroupMenu from './group-menu.component';
import { groupApi, useGetGroupByIdQuery, useUpdateGroupMutation } from '../../../redux/services/group';
import usePerformingSaveToast from '../../../hooks/use-performing-save-toast';
import { useApiLogger } from '../../../hooks/use-api-logger';
import { saveModalButtons } from '../../../common/common-administration-utils';
import { promiseToast, showError, showSuccess } from '../../../services/notification.service';
import i18next from '../../../i18n';
import { useTranslation } from 'react-i18next';
import useDynamicFormNotifier from '../../../hooks/use-dynamic-form-notifier';
import CloseActiveItemButton from '../../common/close-active-item-button';
import { useDispatch } from 'react-redux';
import { getArchiveRestoreMessages } from '../../../pages/admin/admin-utils';
import { useUserAccess } from '../../../hooks/use-user-access';

export interface GroupDetailsProps {
  groupId: number;
  isNewGroup?: boolean;
  title: string;
  onClose: (id: string) => void;
  panelSettings?: BehaviorSubject<ActiveRecordContextProps>;
  onCloseActiveRecordConfirmationModalRef?: ModalDialogRef | null;
}

interface GroupDetailsRef {
  submitForm?: () => void;
}

const saveButton: DynamicFormButtonSetting = {
  name: 'save',
  text: i18next.t('save'),
  htmlType: 'submit',
  type: 'primary',
  variant: 'alternative',
};
const archieveButton: DynamicFormButtonSetting = {
  name: 'archive',
  text: i18next.t('archive'),
  htmlType: 'button',
  type: 'default',
  variant: 'alternative',
};

const restoreButton: DynamicFormButtonSetting = {
  name: 'restore',
  text: i18next.t('restore'),
  htmlType: 'button',
  type: 'default',
  variant: 'alternative',
  disabled: false,
};

const GroupDetails = (props: GroupDetailsProps, ref: Ref<GroupDetailsRef>) => {
  const { groupId, isNewGroup = false, title, onClose, panelSettings, onCloseActiveRecordConfirmationModalRef } = props;

  const [group, setGroup] = useState<GroupStructure>();
  const [panelContextProps, setPanelContextProps] = useState<ActiveRecordContextProps>();
  const [formButtons, setFormButtons] = useState<Array<DynamicFormButtonSetting>>();
  const [formSettings, setFormSettings] = useState<ExtendedDynamicFormSettings>();
  const dispatch = useDispatch();

  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const saveModalReference = useRef<ModalDialogRef>(null);
  const dataGridRef = useRef<DataGridRef>();
  const destroySubject = useRef(new Subject<void>());
  const dynamicFormId = useRef(v4());
  const closeOnModalSaveRef = useRef(false);

  const { profileData: { user: userData } = {} } = useProfileData();
  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useGetGroupByIdQuery(groupId);
  const [updateGroup] = useUpdateGroupMutation();
  const log = useApiLogger();

  const { notifyDirty, notifySaveSucceeded, notifySaveFailed } = useDynamicFormNotifier(dynamicFormId.current);

  const { showPerformingSaveToast, hidePerformingSaveToast } = usePerformingSaveToast({
    shouldFreezeActiveRecord: true,
    disabled: panelContextProps?.batchSaveInProgress,
  });

  const { readOnly, editAccess, setReadOnly } = useUserAccess({
    initialAccess: isNewGroup,
    userData,
    permissions: {
      MODIFY: [GroupsPermissions.ROLES_MODIFY, GroupsPermissions.ROLES_CREATE],
      READ: GroupsPermissions.ROLES_READ,
    },
  });

  useEffect(() => {
    if (editAccess) {
      setReadOnly(false);
    }
  }, [editAccess]);

  const onArchieveGroup = (archive: boolean) => {
    if (groupId) {
      promiseToast(
        updateGroup({ id: groupId, archived: archive })
          .then(() => {
            onClose(dynamicFormId.current);
            dataGridRef.current?.api.data.getDataOverwritePageCache();
            dispatch(groupApi.util.invalidateTags([{ type: 'Group', id: 'LIST' }]));
          })
          .catch(() => showErrorToast()),
        getArchiveRestoreMessages(archive)
      );
    }
  };

  useEffect(() => {
    if (data && !isFetching) {
      setGroup(cloneDeep(data));
      const newFormButtons: Array<DynamicFormButtonSetting> = [];
      if (data.original.archived) {
        newFormButtons.push(restoreButton);
        restoreButton.onClick = () => onArchieveGroup(false);
        restoreButton.disabled = !editAccess;
      } else {
        archieveButton.onClick = () => onArchieveGroup(true);
        newFormButtons.push(saveButton, archieveButton);
      }
      setFormButtons(newFormButtons);
    }
  }, [data, isFetching, editAccess]);

  useEffect(() => {
    if (group?.original.archived) {
      setReadOnly(true);
    }
  }, [group]);

  useEffect(() => {
    setFormSettings(getGroupFormSettings(readOnly));
  }, [readOnly]);

  useEffect(() => {
    panelSettings?.pipe(takeUntil(destroySubject.current)).subscribe((settings) => {
      setPanelContextProps(settings);
    });

    onCloseActiveRecordConfirmationModalRef
      ?.subscribeToDialogResult()
      ?.pipe(takeUntil(destroySubject.current))
      .subscribe((result) => {
        if (result.buttonName === 'save' && dynamicFormReference.current?.formIsDirty()) {
          dynamicFormReference.current?.submitForm(true);
        } else if (result.buttonName === 'discard') {
          onClose(dynamicFormId.current);
        }
      });

    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((gridRef) => {
        if (gridRef) {
          dataGridRef.current = gridRef;
        }
      });

    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
    };
  }, []);

  const renderEditAccessElements = () => {
    if (
      userData &&
      (userData.superUser ||
        userData.adminAccess?.includes(GroupsPermissions.ROLES_MODIFY) ||
        userData.adminAccess?.includes(GroupsPermissions.ROLES_CREATE))
    ) {
      return (
        <div className="group relative">
          {!panelContextProps?.fullScreen && (
            <IconButton classNames="mx-2 h-9 flex items-center">
              <OdinIcon size={OdinIconSize.Medium} icon="More" className="w-6" />
            </IconButton>
          )}
          <GroupMenu groupTitle={title} groupId={groupId} fullScreen={panelContextProps?.fullScreen} />
        </div>
      );
    }
    return;
  };

  useImperativeHandle(ref, () => ({
    submitForm: dynamicFormReference.current?.submitForm,
  }));

  const onFormSubmit = (data: JsonDataItem) => {
    if (groupId && group?.original) {
      const patchData = createGroupPatch(data, dynamicFormReference.current?.formDirtyFields() || {});

      const groupChanges = {
        ...patchData,
        id: group.id,
        caption: patchData?.caption ?? group.caption,
      };

      saveGroupData(groupChanges as GroupItem, (response) => {
        const convertedResponse = forceAssert<GroupMutationResponse>(response);
        if (convertedResponse?.data?.result) {
          hidePerformingSaveToast();
          showSaveToast();
        } else if (convertedResponse?.error) {
          hidePerformingSaveToast();
          showErrorToast();
          log('Failed to update group data', { error: convertedResponse.error });
        }
      });
    }
  };

  const saveGroupData = (groupData: GroupItem, successCallback: (response?: unknown) => void) => {
    showPerformingSaveToast();
    if (groupData.id) {
      updateGroup(groupData)
        .then(successCallback)
        .catch(() => showErrorToast());
    }
  };

  const onCloseGroupDetails = () => {
    if (!dynamicFormReference.current?.formIsDirty()) {
      onClose(dynamicFormId.current);
    } else {
      saveModalReference.current
        ?.show()
        ?.pipe(take(1))
        .subscribe((result) => {
          if ('save' === result.buttonName) {
            if (groupId && group?.original) {
              const patchData = createGroupPatch(group, dynamicFormReference.current?.formDirtyFields() || {});
              const groupChanges = {
                ...patchData,
                id: group.id,
                caption: patchData?.caption ?? group.caption,
              };
              saveGroupData(groupChanges as GroupItem, (response) => {
                const convertedResponse = forceAssert<GroupMutationResponse>(response);
                if (convertedResponse?.data?.result) {
                  hidePerformingSaveToast();
                  showSaveToast();
                } else if (convertedResponse?.error) {
                  hidePerformingSaveToast();
                  showErrorToast();
                  log('Failed to save group data', { error: convertedResponse.error });
                }
              });

              return;
            }
          }

          onClose(dynamicFormId.current);
        });
    }
  };

  const showSaveToast = () => {
    if (!panelContextProps?.batchSaveInProgress) {
      showSuccess(t('record-saved'));
    }
    notifySaveSucceeded(dynamicFormId.current);
    closeOnModalSaveRef.current = true;
    if (!groupId || closeOnModalSaveRef.current) {
      onClose(dynamicFormId.current);
    }
  };

  const showErrorToast = () => {
    if (!panelContextProps?.batchSaveInProgress) {
      showError(t('group-details-save-failed'));
    }
    notifySaveFailed(dynamicFormId.current);
  };

  const onFieldChanged = debounce(() => notifyDirty(dynamicFormReference.current), 500);

  return (
    <>
      <div className="flex flex-shrink-0 flex-col">
        <div className="flex">
          <FormTitle title={title} />
          {!isLoading && (
            <div className="flex min-w-min justify-end">
              {renderEditAccessElements()}
              <CloseActiveItemButton onClick={onCloseGroupDetails} />
            </div>
          )}
        </div>
      </div>
      <div className="admin-form-default">
        {!group && <FormLoading />}
        {group && formSettings && (
          <DynamicForm
            ref={dynamicFormReference}
            dynamicFormId={dynamicFormId.current}
            data={group}
            settings={formSettings}
            buttons={formButtons}
            buttonsLocation={0}
            readOnly={readOnly}
            buttonsPosition={1}
            onSubmit={onFormSubmit}
            onFieldChanged={onFieldChanged}
          />
        )}
      </div>
      <ModalDialog ref={saveModalReference} header={t('save-unsaved-changes')} buttons={saveModalButtons}>
        <div>{t('save-message')}</div>
      </ModalDialog>
    </>
  );
};
export default forwardRef(GroupDetails);
