import React, { ReactNode } from 'react';
import { RowTemplateType, DataGridDataRowTemplateProps } from '@myosh/odin-components';
import { ActiveRecordRef } from '../active-record/active-record.component';
import { AdminTabs } from '../../pages/admin/admin-utils';

interface OdinDataGridLinkedUsersRowTemplateProps {
  activeRecordReference?: ActiveRecordRef | null;
  isClickable?: boolean;
  triggerDefaultRowClickedEvent?: boolean;
  successCallback?: () => void;
}

export function OdinDataGridLinkedUsersRowTemplate({
  activeRecordReference,
  isClickable = true,
  triggerDefaultRowClickedEvent = false,
  successCallback,
}: OdinDataGridLinkedUsersRowTemplateProps) {
  const rowTemplate: RowTemplateType = (
    props: DataGridDataRowTemplateProps,
    children: ReactNode | Array<ReactNode>
  ) => {
    if (isClickable) {
      const details = props.data.fullName as string;
      const id = props.data.id as string;
      return (
        <div
          className="flex w-full cursor-pointer p-px"
          onClick={(event) => {
            triggerDefaultRowClickedEvent && props.onRowClicked(event);
            activeRecordReference?.addActiveRecord(id, 'USER', details, false, {
              userId: id,
              title: details,
              administrationTab: AdminTabs.USERS,
              onLinkedUserSuccessCallback: successCallback,
            });
          }}
        >
          {children}
        </div>
      );
    } else {
      return <>{children}</>;
    }
  };

  return rowTemplate;
}
