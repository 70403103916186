import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  DataGrid,
  DataGridDataRowTemplateProps,
  RowTemplateType,
  DataGridSettingsMenuItem,
  OdinDataRetrievalOptions,
  OdinDataRetrieval,
  DynamicFormFieldType,
  OdinDataSender,
  JsonDataWrapper,
  JsonData,
  DataSearchLocation,
  DataGridRef,
} from '@myosh/odin-components';
import { dateFormatBasedOnFilter, forceAssert, processFieldFilters } from '../../../../common/common-functions';
import useFetchUsers from '../../../../hooks/use-fetch-users';
import { UserFields } from '../../../../common/user-config';
import { extractUserIds } from '../user-record-link.component';
import { linkedUsersCustomComponents } from '../user-record-link.component';
import { UserRecordLinkFields, usersDataGridColumns } from './user-recordlink-columns-config';
import { LinkedUser } from '../../../../@types/users';
import { useHierarchyTypesQuery } from '../../../../redux/services/hierarchy';
import { getUsersFieldOptionsData } from '../../../../common/user-functions';
import { useAppDispatch } from '../../../../redux/hooks';
import { setDataGridReference } from '../../../../services/data-grid.service';

interface SelectedUsersGridProps {
  linkedUsers: Array<LinkedUser>;
  onRemoved: (value: LinkedUser) => void;
  menuGridSettings?: DataGridSettingsMenuItem[];
}

function SelectedUsersGrid({ linkedUsers, onRemoved, menuGridSettings }: SelectedUsersGridProps) {
  const [linkedUsersIds, setLinkedUsersIds] = useState<Array<number>>([]);
  const [gridOptions, setGridOptions] = useState<OdinDataRetrievalOptions>();
  const dispatch = useAppDispatch();
  const gridRef = useRef<DataGridRef>();
  const gridSubscriber = useRef<OdinDataSender<JsonDataWrapper>>();

  const { users } = useFetchUsers(
    linkedUsersIds,
    UserRecordLinkFields.join(),
    processFieldFilters(gridOptions?.fieldFilters, dateFormatBasedOnFilter),
    gridOptions?.sortedFields
  );
  const { data: hierarchyTypes } = useHierarchyTypesQuery({ archived: 'false' });

  useEffect(() => {
    const userIds = extractUserIds(linkedUsers);
    setLinkedUsersIds(userIds);
  }, [linkedUsers, gridOptions]);

  const gridSettings = useMemo(() => {
    if (hierarchyTypes) {
      return {
        columns: usersDataGridColumns(hierarchyTypes),
        components: linkedUsersCustomComponents,
        fullHeight: true,
        filterLocation: DataSearchLocation.Api,
      };
    }
  }, [hierarchyTypes]);

  const removeUser = (value: LinkedUser) => {
    onRemoved(value);
    gridRef?.current?.api?.data?.removeRowById(value.value);
  };

  const rowTemplate: RowTemplateType = (props: DataGridDataRowTemplateProps, children: ReactNode | ReactNode[]) => (
    <div
      className="flex"
      onClick={() =>
        removeUser({
          // eslint-disable-next-line react/prop-types
          value: props.data[UserFields.id] as number,
          // eslint-disable-next-line react/prop-types
          text: props.data[UserFields.lastName] + ' ' + props.data[UserFields.firstName],
        })
      }
    >
      {children}
    </div>
  );
  useEffect(() => {
    if (users) {
      gridSubscriber.current?.sendData({ data: forceAssert<JsonData>(users), requestId: gridOptions?.requestId });
    } else {
      gridSubscriber.current?.sendData();
    }
  }, [users]);

  const gridData = useMemo<OdinDataRetrieval>(() => {
    return {
      getSubscriber: (subscriber, fieldType?: DynamicFormFieldType) => {
        if (fieldType !== 'COMBOBOX') {
          gridSubscriber.current = subscriber;
        }
      },
      getData: (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if (options?.fieldType === 'COMBOBOX') {
          getUsersFieldOptionsData(subscriber, options, dispatch);
        } else {
          setGridOptions(options);
        }
      },
    };
  }, []);

  const onDataGridRefCreated = (dataGridRef: DataGridRef) => {
    gridRef.current = dataGridRef;
    setDataGridReference(dataGridRef);
  };

  return (
    <div className="flex h-full flex-col gap-4">
      {gridSettings && (
        <DataGrid
          ref={(gridRef) => gridRef && onDataGridRefCreated(gridRef)}
          data={gridData}
          gridSettings={gridSettings}
          rowTemplate={rowTemplate}
          customSettingsMenuItems={menuGridSettings}
          showGridMenu={false}
        />
      )}
    </div>
  );
}

export default SelectedUsersGrid;
