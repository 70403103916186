// Logs the client side messages
export const log = (data: Record<string, unknown>) => {
  data.environment = `${process.env.REACT_APP_LOGGER_ENV}` ?? `${process.env.NODE_ENV}`;

  fetch(`${process.env.REACT_APP_LOGGER_API_URL}`, {
    method: 'POST',
    headers: { 'x-api-key': `${process.env.REACT_APP_LOGGER_API_KEY}` },
    mode: 'cors',
    body: JSON.stringify(data),
  });
};
