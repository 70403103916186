import React from 'react';
import { DataGridSettingsMenuItem } from './data-grid-menu-utils';
import classNames from 'classnames';
import { OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';
import { RecordListMenuItemValue } from '../../../@types/record-list-menu';
import { useTranslation } from 'react-i18next';

interface PinnableMenuProps {
  items: Array<DataGridSettingsMenuItem>;
  onPinMenuItemClick?: (key: RecordListMenuItemValue, isPinned: boolean, addonId?: number) => void;
  isModalMenu?: boolean;
}

function PinnableMenu({ items, onPinMenuItemClick, isModalMenu = false }: PinnableMenuProps) {
  const { t } = useTranslation();

  const createItems = (items: Array<DataGridSettingsMenuItem>) => {
    return items.map((menuItem, index) => {
      if (menuItem.isSeparator) {
        return (
          <li key={menuItem.key} className="py-1" hidden={menuItem.visible === false}>
            <hr className="border-gray-3" />
          </li>
        );
      }

      const menuItemStyles = classNames(
        'relative flex items-center gap-2 px-3 py-2 hover:bg-primary-4 group/menu-item',
        { 'hover:!bg-mono-1': menuItem.disabled, [menuItem.className || '']: isModalMenu }
      );

      const menuItemLinkStyles = classNames('flex gap-2 items-center flex-1 whitespace-nowrap cursor-pointer', {
        'text-gray-3 pointer-events-none !cursor-default': menuItem.disabled,
      });

      return (
        <li
          key={`${menuItem.key}_${menuItem.label}_${index}`}
          className={menuItemStyles}
          hidden={menuItem.visible === false}
        >
          <a target={menuItem.target} href={menuItem.url} className={menuItemLinkStyles} onClick={menuItem.onClick}>
            {menuItem.icon && (
              <span>
                <OdinIcon icon={menuItem.icon} type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} />
              </span>
            )}
            {menuItem.label}
          </a>
          <button
            className="h-6 w-6 grid place-content-center rounded-full hover:bg-primary-3"
            title={menuItem.isPinned ? t('unpin') : t('pin')}
            onClick={() =>
              onPinMenuItemClick?.(
                menuItem.key as RecordListMenuItemValue,
                menuItem.isPinned ?? false,
                menuItem.addonId
              )
            }
            hidden={!!menuItem.isPinIconHidden}
          >
            {menuItem.isPinned ? (
              <OdinIcon icon="Pushpin" size={OdinIconSize.ExtraSmall} />
            ) : (
              <OdinIcon icon="Pushpin" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} />
            )}
          </button>
          {menuItem.subMenuItems && createMenu(menuItem.subMenuItems, true)}
        </li>
      );
    });
  };

  const menuStyles = `hidden py-1 text-sm rounded-md shadow-md bg-mono-1 absolute right-0 z-50`;

  const createMenu = (items: Array<DataGridSettingsMenuItem>, isSubMenu?: boolean) => {
    if (isSubMenu) {
      return (
        <div className={`${menuStyles} left-0 top-0 -translate-x-full max-sm:max-w-min group-hover/menu-item:block`}>
          <ul>{createItems(items)}</ul>
        </div>
      );
    }

    return <ul>{createItems(items)}</ul>;
  };

  return <>{createMenu(items)}</>;
}

export default PinnableMenu;
