import useFetchRecords from '../../../hooks/use-fetch-records';

// A convenience hook to fetch a list of records and return the formatted display value
const useLinkedRecordsDisplayValue = (recordIds: Array<string>) => {
  const { records, isLoading } = useFetchRecords(recordIds);

  let value = '';
  if (records?.length > 0) {
    value = records.map((record) => `#${record.docNo}`).join(', ');
  }

  return {
    value,
    isLoading,
  };
};

export default useLinkedRecordsDisplayValue;
