import { DynamicFieldComponentProps, JsonData } from '@myosh/odin-components';
import { Control, FieldValues } from 'react-hook-form';
import { RecordNotification } from '../../../../@types/record-notifications';
import { ExtendedDynamicFormSettings } from '../../../../common/common-functions';
export interface NotificationTypeFieldProps extends DynamicFieldComponentProps<RecordNotification> {
  control: Control<FieldValues>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const excludedTriggerFieldsTypes = [
  'API_QUERY',
  'CLONERECORD',
  'EMAIL_THIS_BUTTON',
  'INVITE_BUTTON',
  'LABEL',
  'PDF_EXPORT',
  'REVERSE_RECORD_LINK',
  'SUMMARY_RECORD_LINK',
];

export const getTriggerFieldsForType = (formSettings?: ExtendedDynamicFormSettings, type?: Array<string>) => {
  const allApplicableOptions: JsonData =
    formSettings?.fields
      .flatMap((field) => (field.fieldType === 'GROUP' ? field.fields : field))
      .map((field) => {
        return { ...field, text: field?.caption, value: field?.id };
      }) ?? [];

  if (type) {
    return allApplicableOptions.filter((field) => type.includes(field.fieldType as string));
  }
  return allApplicableOptions;
};
