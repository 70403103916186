import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import {
  DropDownResult,
  OdinDataSender,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  isDropDownResult,
  DropDownRef,
  DropDownMultiSelect,
  DropDownNoSelection,
  DynamicFormRequiredType,
} from '@myosh/odin-components';
import { useHierarchyTypesQuery, useLazyHierarchyValuesQuery } from '../../../../redux/services/hierarchy';
import HfDropDown from '../../custom-drop-downs/hf-drop-down.component';
import { HierarchyType } from '../../../../@types/hierarchy-fields';
import { useTranslation } from 'react-i18next';
import { NotificationTypeFieldProps } from './notification-properties-utils';
import { TypeOrArray } from '@myosh/odin-components/dist/types/components/common-interfaces';

const HierarchyNotificationField = ({ control, value, readOnly }: NotificationTypeFieldProps) => {
  const [hierarchyType, setHierarchyType] = useState<HierarchyType>();
  const [hierarchyTypeSelected, setHierarchyTypeSelected] = useState<DropDownResult>();
  const [hierachyValuesSelected, setHierarchyValuesSelected] = useState<TypeOrArray<DropDownResult>>();
  const subscriberRef = useRef<OdinDataSender<JsonDataWrapper>>();
  const [options, setOptions] = useState<OdinDataRetrievalOptions>();

  const { data: hierarchyTypes, isFetching: fetchingTypes } = useHierarchyTypesQuery({ archived: 'false' });
  const [getHierarchyValues] = useLazyHierarchyValuesQuery();
  const { t } = useTranslation();
  const dropDownRef = useRef<DropDownRef>(null);

  const hierarchyTypeData = useMemo(() => {
    if (!fetchingTypes) {
      return hierarchyTypes?.map((item) => {
        return { ...item, originalData: item };
      });
    }
    return [];
  }, [hierarchyTypes, fetchingTypes]);

  const onHierarchyTypeChange = useCallback((event: TypeOrArray<DropDownResult> | DropDownNoSelection | undefined) => {
    if (isDropDownResult(event)) {
      setHierarchyType(event.originalData as unknown as HierarchyType);
      setHierarchyTypeSelected(event);
    }
  }, []);

  useEffect(() => {
    const hierarchyId = value?.notificationPropertiesDTO?.hierarchyTypeId;
    if (hierarchyId && hierarchyTypeData) {
      const found = hierarchyTypeData.find((hierarchy) => hierarchy.id === hierarchyId);
      if (found) {
        setHierarchyTypeSelected({ text: found.caption, value: found.id });
      }
    }
  }, [value, hierarchyTypeData]);

  useEffect(() => {
    if (hierarchyType?.id && options) {
      getHierarchyValues({
        page: options.page ?? 1,
        pageSize: options.pageSize ?? 50,
        sort: 'caption',
        filters: { typeId: { value: hierarchyType.id, comparison: '=' }, archived: { value: false, comparison: '=' } },
      })
        .unwrap()
        .then((result) => {
          if (result?.items) {
            const items = result.items;
            const selectedValues = value?.notificationPropertiesDTO?.hierarchyValueIds;
            if (selectedValues && items) {
              const values = items
                .filter((item) => selectedValues.includes(item.id))
                .map((hierarchy) => {
                  return { value: hierarchy.id, text: hierarchy.caption, original: hierarchy };
                });
              setHierarchyValuesSelected(values);
            }
            subscriberRef.current?.sendData({
              data: items.map((hierarchy) => {
                return {
                  text: hierarchy.caption,
                  value: hierarchy.id,
                  originalData: hierarchy,
                };
              }),
              requestId: options.requestId,
            });
            if (result.page === result.availablePages) {
              subscriberRef.current?.sendData();
            }
          } else {
            subscriberRef.current?.sendData();
          }
        })
        .catch(() => subscriberRef.current?.sendData());
    } else {
      subscriberRef.current?.sendData();
    }
  }, [hierarchyType, options, value?.notificationPropertiesDTO?.hierarchyValueIds]);

  useEffect(() => {
    setHierarchyTypeSelected(undefined);
    dropDownRef.current?.resetDropDownData();
  }, [hierarchyType?.id]);

  const getOptionsData = useMemo(() => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        subscriberRef.current = subscriber;
        setOptions(options);
      },
    };
  }, []);

  return (
    <div className="my-2">
      <HfDropDown
        control={control}
        selectorStyles="text-sm"
        label={t('hierarchy-type')}
        name="hierarchyTypeId"
        data={hierarchyTypeData}
        valueField="id"
        textField="caption"
        onChange={onHierarchyTypeChange}
        className="mb-2"
        setDefaultValue={true}
        value={hierarchyTypeSelected}
        required={DynamicFormRequiredType.True}
        readOnly={readOnly}
      />
      <DropDownMultiSelect
        label={t('hierarchy-values')}
        textField="text"
        valueField="value"
        value={hierachyValuesSelected}
        data={getOptionsData}
        ref={dropDownRef}
        required={DynamicFormRequiredType.True}
        readOnly={readOnly}
      />
    </div>
  );
};

export default HierarchyNotificationField;
