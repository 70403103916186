import {
  DataGridSettings,
  ModalDialog,
  ModalDialogRef,
  OdinDataRetrieval,
  TabView,
  TabViewPanel,
} from '@myosh/odin-components';
import React, { Ref, forwardRef, useEffect, useState } from 'react';
import VersionLogs from './version-logs.component';
import NotificationLog from '../record/notification-log/notification-log';
import { useTranslation } from 'react-i18next';
import { AuditLogData, AuditLogDataItem } from '../../@types/audit';
import { GroupAuditLogData } from '../../@types/groups';
interface AuditLogModalProps {
  header: string;
  dataGridData: OdinDataRetrieval;
  dataGridSettings: DataGridSettings;
  recordId?: string;
  onRowSelectionChange: (selectedRow: AuditLogData | GroupAuditLogData) => Promise<Array<AuditLogDataItem>>;
}

const AuditLogModal = (
  { header, recordId, dataGridData, dataGridSettings, onRowSelectionChange }: AuditLogModalProps,
  ref: Ref<ModalDialogRef>
) => {
  const { t } = useTranslation();

  const [pages, setPages] = useState<JSX.Element | JSX.Element[]>();

  const views = [
    {
      key: 'versions',
      header: t('versions'),
      component: (
        <VersionLogs
          auditGridData={dataGridData}
          dataGridSettings={dataGridSettings}
          onRowSelectionChange={onRowSelectionChange}
        />
      ),
      hidden: false,
    },
    {
      key: 'notifications',
      header: t('notification-log'),
      component: <NotificationLog recordId={recordId} />,
      hidden: Boolean(!recordId),
    },
  ];

  useEffect(() => {
    const filteredViews = views.filter((view) => view.hidden === false);

    if (filteredViews.length > 1) {
      setPages(
        <TabView>
          {filteredViews.map((view) => {
            return (
              <TabViewPanel key={view.key} header={view.header}>
                {view.component}
              </TabViewPanel>
            );
          })}
        </TabView>
      );
    } else {
      setPages(
        filteredViews.map((item) => {
          return <React.Fragment key={item.key}>{item.component}</React.Fragment>;
        })
      );
    }
  }, [dataGridData]);

  return (
    <ModalDialog ref={ref} header={header} fullScreen={true} shouldCheckZIndex={true} renderChildrenLazily={true}>
      {pages}
    </ModalDialog>
  );
};

export default forwardRef(AuditLogModal);
