import { cloneDeep, isObject } from 'lodash';
import {
  GlobalHierarchyFilterStructure,
  GlobalHierarchyFilters,
  HierarchyDropdownValue,
} from '../@types/hierarchy-fields';
import { JsonData, JsonDataItem } from '@myosh/odin-components';
import i18n from '../i18n';

export function formatGlobalHierarchyFiltersData(apiResponse: GlobalHierarchyFilters) {
  const modifiedApiResponse: Record<keyof GlobalHierarchyFilters, unknown> = cloneDeep(apiResponse);
  const keys = Object.keys(modifiedApiResponse);
  for (let i = 0, ilength = keys.length; i < ilength; i++) {
    const dataKey = keys[i] as keyof GlobalHierarchyFilters;
    const dataValue = apiResponse[dataKey];
    if (isObject(dataValue)) {
      if (Array.isArray(dataValue) && dataKey === 'globalHierarchyFilters') {
        const globalHierarchyFilters: Record<string, HierarchyDropdownValue[]> = {};
        for (let j = 0; j < dataValue.length ?? 0; j++) {
          const globalHierarchyFiltersData = dataValue[j];
          if (globalHierarchyFiltersData) {
            const translationItem = globalHierarchyFiltersData.caption?.translations[0];
            const hierarchyItemKey = translationItem.value;
            const hierarchyTypeCaption = globalHierarchyFiltersData.hierarchyTypeCaption?.translations[0]
              .value as string;
            const hierarchyOption = {
              id: globalHierarchyFiltersData.id,
              caption: hierarchyItemKey,
              hierarchyTypeId: globalHierarchyFiltersData.hierarchyTypeId,
              parentId: globalHierarchyFiltersData?.parentId,
            };
            if (globalHierarchyFilters[hierarchyTypeCaption]) {
              globalHierarchyFilters[hierarchyTypeCaption].push(hierarchyOption);
            } else {
              globalHierarchyFilters[hierarchyTypeCaption] = [hierarchyOption];
            }
          }
        }

        modifiedApiResponse.globalHierarchyFilters = globalHierarchyFilters; // needed for the hierarchy
      } else {
        modifiedApiResponse.globalHierarchyFilters = {};
      }
    } else if (dataKey === 'hierarchyMatchingOption') {
      modifiedApiResponse[dataKey] = {
        text: dataValue,
        value: dataValue,
      };
    }
  }

  const data: GlobalHierarchyFilterStructure = {
    original: {
      ...apiResponse,
    },
    globalHierarchyFilters: [{}],
    hierarchyMatchingOption: {
      value: 'MATCH_ANY',
      text: i18n.t('match-any-hierarchy-values', { any: `<strong>${i18n.t('any').toLowerCase()}</strong>` }),
    },
    ...modifiedApiResponse,
  };
  return data;
}

export function createGlobalHierarchyFiltersPatch(data: JsonDataItem) {
  const dirtyFields = Object.keys(data);
  const patch: JsonDataItem = {};

  for (let index = 0, length = dirtyFields.length; index < length; index++) {
    const fieldKey = dirtyFields[index];
    const fieldValue = data[fieldKey];
    if (!fieldValue) {
      continue;
    }
    if (fieldKey === 'globalHierarchyFilters') {
      const hierarchyFields = fieldValue as Record<string, JsonData>;
      const keys = Object.keys(hierarchyFields);
      const transformedHierarchyFields = [];
      for (let i = 0; i < keys.length; i++) {
        const field = keys[i];
        const fieldValues = hierarchyFields[field]?.map((hierarchyValue) => ({ id: hierarchyValue.id }));

        if (fieldValues) {
          transformedHierarchyFields.push(...fieldValues);
        }
      }
      patch[fieldKey] = transformedHierarchyFields;
    } else if (fieldKey === 'hierarchyMatchingOption') {
      patch[fieldKey] = (fieldValue as JsonDataItem).value;
    }
  }
  return patch;
}
