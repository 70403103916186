import { useCallback } from 'react';
import { DynamicFormRef } from '@myosh/odin-components';
import useActiveRecordRef from './use-active-record-ref';

const useDynamicFormNotifier = (dynamicFormId: string) => {
  const { activeRecordReference } = useActiveRecordRef();

  const notifyDirty = useCallback((dynamicFormReference: DynamicFormRef | null) => {
    const isDirty = Boolean(dynamicFormReference?.formIsDirty());
    if (isDirty) {
      activeRecordReference.current?.setItemDirty(dynamicFormId);
    } else {
      activeRecordReference.current?.clearItemDirty(dynamicFormId);
    }
  }, []);

  const notifySaveFailed = useCallback((id: string) => {
    activeRecordReference.current?.setItemSaveFailed(id);
  }, []);

  const notifySaveSucceeded = useCallback((id: string) => {
    activeRecordReference.current?.clearItemDirty(id);
  }, []);

  return {
    notifyDirty,
    notifySaveFailed,
    notifySaveSucceeded,
  };
};

export default useDynamicFormNotifier;
