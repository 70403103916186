import React from 'react';
import { DynamicFormRequiredType } from '@myosh/odin-components';
import { Observable } from 'rxjs';
import { LinkedRecordResult, RecordLinkProps } from '../../../@types/linked-records-field';
import { RecordResult } from '../../../@types/records';
import LinkedRecords from '../linked-records/linked-records.component';
import { useTranslation } from 'react-i18next';

interface QuestionnaireLinkedRecordsProps {
  recordId?: number;
  fieldId: number;
  recordLink: RecordLinkProps;
  value?: Array<LinkedRecordResult>;
  readOnly?: boolean;
  onChange: (value?: Array<string>) => void;
  saveRecord?: () => Observable<RecordResult>;
  required?: DynamicFormRequiredType;
  error?: string;
}

const QuestionnaireLinkedRecords = ({
  recordId,
  fieldId,
  recordLink,
  value,
  readOnly,
  onChange,
  saveRecord,
  required,
  error,
}: QuestionnaireLinkedRecordsProps) => {
  const { t } = useTranslation();

  const { recordLinkFormat, targetModuleFormId, buttonCaption, showSelectButton } = recordLink;
  return (
    <LinkedRecords
      label={t('linked-records')}
      value={value}
      recordId={recordId}
      id={fieldId}
      recordLinkFormat={recordLinkFormat}
      targetModuleFormId={targetModuleFormId}
      buttonCaption={buttonCaption}
      showSelectButton={showSelectButton}
      readOnly={readOnly}
      onChange={onChange}
      saveRecord={saveRecord}
      required={required}
      error={error}
      isTextualQuestionnaireLinkedRecords={true}
    />
  );
};

export default QuestionnaireLinkedRecords;
