import React from 'react';
import { ActiveRecordChildRenderProps } from '../../active-record/active-record.component';
import Record, { RecordProps, RecordRef } from '../../record/record.component';
import UserDetails, { UserDetailsProps } from '../../admin/user/user-details.component';
import ModuleDetails from '../../admin/module/module-details.component';
import { ModuleDetailsProps } from '../../admin/module/module-details.props';
import { setRecordReference } from '../../../services/record.service';
import GroupDetails, { GroupDetailsProps } from '../../admin/group/group-details.component';
import OccupationDetails, { OccupationDetailsProps } from '../../admin/occupation/occupation-details.component';
import CompetencyGroupsDetails, {
  CompetencyGroupsDetailsProps,
} from '../../admin/training-management/competency-groups/competency-groups-details-component';
import CompetencyDetails, {
  CompetencyDetailsProps,
} from '../../admin/training-management/competency/competency-details.component';
import OccupationalRequirementsDetails, {
  OccupationalRequirementsDetailsProps,
} from '../../admin/training-management/occupational-requirements/occupational-requirements-details-component';
import SchedulerDetails, { SchedulerDetailsProps } from '../../admin/scheduler/scheduler-details.component';
import RuleDetails, { RuleDetailsProps } from '../../admin/rule/rule-details.component';
import NotificationDetailsComponent, {
  NotificationDetailsProps,
} from '../../record-notification/notification-details.component';
import RiskMatrixDetails, { RiskMatrixDetailsProps } from '../../admin/risk-matrix/risk-matrix-details';
import HierarchyDetails, { HierarchyDetailsProps } from '../../hierarchy/hierarchy-details.component';

export default function ActiveSidePanelItem({
  onCloseActiveRecord,
  settingsSubject,
  extraSettings,
  onCloseActiveRecordConfirmationModalRef,
}: ActiveRecordChildRenderProps) {
  const settings = extraSettings as Record<string, unknown>;

  if (settings?.userId || settings?.isNewUser === true) {
    return (
      <UserDetails
        {...(extraSettings as UserDetailsProps)}
        onClose={onCloseActiveRecord}
        panelSettings={settingsSubject}
        onCloseActiveRecordConfirmationModalRef={onCloseActiveRecordConfirmationModalRef}
      />
    );
  } else if (settings?.competencyGroupId) {
    return (
      <CompetencyGroupsDetails
        {...(extraSettings as CompetencyGroupsDetailsProps)}
        onClose={onCloseActiveRecord}
        panelSettings={settingsSubject}
        onCloseActiveRecordConfirmationModalRef={onCloseActiveRecordConfirmationModalRef}
      />
    );
  } else if (settings?.competencyId) {
    return (
      <CompetencyDetails
        {...(extraSettings as CompetencyDetailsProps)}
        onClose={onCloseActiveRecord}
        panelSettings={settingsSubject}
        onCloseActiveRecordConfirmationModalRef={onCloseActiveRecordConfirmationModalRef}
      />
    );
  } else if (settings?.formId) {
    return (
      <Record
        ref={(recordRef: RecordRef) => recordRef && setRecordReference(recordRef)}
        {...(extraSettings as RecordProps)}
        onClose={onCloseActiveRecord}
        panelSettings={settingsSubject}
        onCloseActiveRecordConfirmationModalRef={onCloseActiveRecordConfirmationModalRef}
      />
    );
  } else if (settings?.moduleId) {
    return (
      <ModuleDetails
        {...(extraSettings as ModuleDetailsProps)}
        onClose={onCloseActiveRecord}
        panelSettings={settingsSubject}
        onCloseActiveRecordConfirmationModalRef={onCloseActiveRecordConfirmationModalRef}
      />
    );
  } else if (settings?.groupId) {
    return (
      <GroupDetails
        {...(extraSettings as GroupDetailsProps)}
        onClose={onCloseActiveRecord}
        panelSettings={settingsSubject}
        onCloseActiveRecordConfirmationModalRef={onCloseActiveRecordConfirmationModalRef}
      />
    );
  } else if (settings?.occupationId || settings?.isNewOccupation) {
    return (
      <OccupationDetails
        {...(extraSettings as OccupationDetailsProps)}
        onClose={onCloseActiveRecord}
        panelSettings={settingsSubject}
        onCloseActiveRecordConfirmationModalRef={onCloseActiveRecordConfirmationModalRef}
      />
    );
  } else if (settings?.riskMatrixId || settings?.isNewRiskMatrix) {
    return (
      <RiskMatrixDetails
        {...(extraSettings as RiskMatrixDetailsProps)}
        onClose={onCloseActiveRecord}
        panelSettings={settingsSubject}
        onCloseActiveRecordConfirmationModalRef={onCloseActiveRecordConfirmationModalRef}
      />
    );
  } else if (settings?.hierarchyId || settings?.isNewHierarchy) {
    return (
      <HierarchyDetails
        {...(extraSettings as HierarchyDetailsProps)}
        onClose={onCloseActiveRecord}
        onCloseActiveRecordConfirmationModalRef={onCloseActiveRecordConfirmationModalRef}
      />
    );
  } else if (settings?.occupationalRequirementId) {
    return (
      <OccupationalRequirementsDetails
        {...(extraSettings as OccupationalRequirementsDetailsProps)}
        onClose={onCloseActiveRecord}
        panelSettings={settingsSubject}
        onCloseActiveRecordConfirmationModalRef={onCloseActiveRecordConfirmationModalRef}
      />
    );
  } else if (settings?.schedulerId || settings?.isNewScheduler) {
    return (
      <SchedulerDetails
        {...(extraSettings as SchedulerDetailsProps)}
        onClose={onCloseActiveRecord}
        panelSettings={settingsSubject}
        onCloseActiveRecordConfirmationModalRef={onCloseActiveRecordConfirmationModalRef}
      />
    );
  } else if (settings?.notificationId || settings?.isNewNotification) {
    return (
      <NotificationDetailsComponent
        {...(extraSettings as NotificationDetailsProps)}
        onClose={onCloseActiveRecord}
        panelSettings={settingsSubject}
        onCloseActiveRecordConfirmationModalRef={onCloseActiveRecordConfirmationModalRef}
      />
    );
  }

  if (settings?.ruleId) {
    return (
      <RuleDetails
        {...(extraSettings as RuleDetailsProps)}
        onClose={onCloseActiveRecord}
        panelSettings={settingsSubject}
        onCloseActiveRecordConfirmationModalRef={onCloseActiveRecordConfirmationModalRef}
      />
    );
  }

  return null;
}
