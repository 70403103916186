import React from 'react';
import { Control } from 'react-hook-form';
import { OdinController } from '@myosh/odin-components';
import { RiskRatingProps } from './risk-rating.component';
import RiskMatrixAdmin from './risk-matrix-admin.component';

interface HfRiskRatingProps extends RiskRatingProps {
  control?: Control;
  name?: string;
}

export default function HfRiskMatrixAdmin({
  control,
  name,
  label,
  readOnly,
  value,
  onChange,
  riskMatrixId,
}: HfRiskRatingProps) {
  return (
    <OdinController
      name={name as string}
      control={control}
      defaultValue={value}
      onChange={onChange}
      render={() => <RiskMatrixAdmin riskMatrixId={riskMatrixId} label={label} readOnly={readOnly} />}
    />
  );
}
