import { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useLazyGetRecordByIdQuery } from '../../redux/services/record';
import { useTranslation } from 'react-i18next';
import { showError } from '../../services/notification.service';

// A hook that resolves the formId and recordId from a *deprecated* record URL
const useLegacyRecordUrl = () => {
  const [formId, setFormId] = useState<number>();
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const [getRecordById] = useLazyGetRecordByIdQuery();

  // needed to resolve backward compatible access to a record
  const legacyMatch = useMatch('/:schema/modules/:viewId/records/:recordId');
  const { recordId } = legacyMatch?.params ?? {};

  useEffect(() => {
    if (recordId && !recordId.includes('new')) {
      let id: number;
      if (!isNaN((id = parseInt(recordId)))) {
        getRecordById({ id, keyTypeId: true })
          .unwrap()
          .then((data) => {
            const record = data.original;
            setFormId(record.formId);
          })
          .catch((error) => {
            if (error?.status === 404 && error?.data?.validation?.errors[0]?.includes('DeletedRecordException')) {
              showError(t('deleted-record-message'), { id: recordId });
            } else {
              showError(t('record-toast-message'), { id: recordId });
            }

            setError(true);
          });
      }
    }
  }, [recordId]);

  return {
    recordId,
    formId,
    error,
  };
};

export default useLegacyRecordUrl;
