export const timeZones = [
  {
    value: 'Pacific/Pago_Pago',
    text: 'Pacific/Pago_Pago (UTC -11)',
  },
  {
    value: 'Pacific/Samoa',
    text: 'Pacific/Samoa (UTC -11)',
  },
  {
    value: 'Pacific/Niue',
    text: 'Pacific/Niue (UTC -11)',
  },
  {
    value: 'US/Samoa',
    text: 'US/Samoa (UTC -11)',
  },
  {
    value: 'Pacific/Midway',
    text: 'Pacific/Midway (UTC -11)',
  },
  {
    value: 'Pacific/Honolulu',
    text: 'Pacific/Honolulu (UTC -10)',
  },
  {
    value: 'Pacific/Rarotonga',
    text: 'Pacific/Rarotonga (UTC -10)',
  },
  {
    value: 'Pacific/Tahiti',
    text: 'Pacific/Tahiti (UTC -10)',
  },
  {
    value: 'Pacific/Johnston',
    text: 'Pacific/Johnston (UTC -10)',
  },
  {
    value: 'America/Atka',
    text: 'America/Atka (UTC -10)',
  },
  {
    value: 'US/Hawaii',
    text: 'US/Hawaii (UTC -10)',
  },
  {
    value: 'America/Adak',
    text: 'America/Adak (UTC -10)',
  },
  {
    value: 'US/Aleutian',
    text: 'US/Aleutian (UTC -10)',
  },
  {
    value: 'Pacific/Marquesas',
    text: 'Pacific/Marquesas (UTC -09)',
  },
  {
    value: 'US/Alaska',
    text: 'US/Alaska (UTC -09)',
  },
  {
    value: 'America/Juneau',
    text: 'America/Juneau (UTC -09)',
  },
  {
    value: 'America/Metlakatla',
    text: 'America/Metlakatla (UTC -09)',
  },
  {
    value: 'Pacific/Gambier',
    text: 'Pacific/Gambier (UTC -09)',
  },
  {
    value: 'America/Yakutat',
    text: 'America/Yakutat (UTC -09)',
  },
  {
    value: 'America/Sitka',
    text: 'America/Sitka (UTC -09)',
  },
  {
    value: 'America/Anchorage',
    text: 'America/Anchorage (UTC -09)',
  },
  {
    value: 'America/Nome',
    text: 'America/Nome (UTC -09)',
  },
  {
    value: 'Canada/Pacific',
    text: 'Canada/Pacific (UTC -08)',
  },
  {
    value: 'PST8PDT',
    text: 'PST8PDT (UTC -08)',
  },
  {
    value: 'Pacific/Pitcairn',
    text: 'Pacific/Pitcairn (UTC -08)',
  },
  {
    value: 'Mexico/BajaNorte',
    text: 'Mexico/BajaNorte (UTC -08)',
  },
  {
    value: 'America/Tijuana',
    text: 'America/Tijuana (UTC -08)',
  },
  {
    value: 'America/Santa_Isabel',
    text: 'America/Santa_Isabel (UTC -08)',
  },
  {
    value: 'America/Vancouver',
    text: 'America/Vancouver (UTC -08)',
  },
  {
    value: 'America/Ensenada',
    text: 'America/Ensenada (UTC -08)',
  },
  {
    value: 'America/Los_Angeles',
    text: 'America/Los_Angeles (UTC -08)',
  },
  {
    value: 'US/Pacific',
    text: 'US/Pacific (UTC -08)',
  },
  {
    value: 'Canada/Yukon',
    text: 'Canada/Yukon (UTC -07)',
  },
  {
    value: 'US/Arizona',
    text: 'US/Arizona (UTC -07)',
  },
  {
    value: 'Mexico/BajaSur',
    text: 'Mexico/BajaSur (UTC -07)',
  },
  {
    value: 'America/Dawson_Creek',
    text: 'America/Dawson_Creek (UTC -07)',
  },
  {
    value: 'America/Denver',
    text: 'America/Denver (UTC -07)',
  },
  {
    value: 'America/Yellowknife',
    text: 'America/Yellowknife (UTC -07)',
  },
  {
    value: 'America/Inuvik',
    text: 'America/Inuvik (UTC -07)',
  },
  {
    value: 'America/Mazatlan',
    text: 'America/Mazatlan (UTC -07)',
  },
  {
    value: 'America/Boise',
    text: 'America/Boise (UTC -07)',
  },
  {
    value: 'America/Dawson',
    text: 'America/Dawson (UTC -07)',
  },
  {
    value: 'MST7MDT',
    text: 'MST7MDT (UTC -07)',
  },
  {
    value: 'America/Chihuahua',
    text: 'America/Chihuahua (UTC -07)',
  },
  {
    value: 'America/Ojinaga',
    text: 'America/Ojinaga (UTC -07)',
  },
  {
    value: 'US/Mountain',
    text: 'US/Mountain (UTC -07)',
  },
  {
    value: 'America/Creston',
    text: 'America/Creston (UTC -07)',
  },
  {
    value: 'America/Edmonton',
    text: 'America/Edmonton (UTC -07)',
  },
  {
    value: 'America/Hermosillo',
    text: 'America/Hermosillo (UTC -07)',
  },
  {
    value: 'Canada/Mountain',
    text: 'Canada/Mountain (UTC -07)',
  },
  {
    value: 'America/Cambridge_Bay',
    text: 'America/Cambridge_Bay (UTC -07)',
  },
  {
    value: 'Navajo',
    text: 'Navajo (UTC -07)',
  },
  {
    value: 'America/Phoenix',
    text: 'America/Phoenix (UTC -07)',
  },
  {
    value: 'America/Whitehorse',
    text: 'America/Whitehorse (UTC -07)',
  },
  {
    value: 'America/Fort_Nelson',
    text: 'America/Fort_Nelson (UTC -07)',
  },
  {
    value: 'America/Shiprock',
    text: 'America/Shiprock (UTC -07)',
  },
  {
    value: 'America/El_Salvador',
    text: 'America/El_Salvador (UTC -06)',
  },
  {
    value: 'America/Guatemala',
    text: 'America/Guatemala (UTC -06)',
  },
  {
    value: 'America/Belize',
    text: 'America/Belize (UTC -06)',
  },
  {
    value: 'America/Managua',
    text: 'America/Managua (UTC -06)',
  },
  {
    value: 'America/Chicago',
    text: 'America/Chicago (UTC -06)',
  },
  {
    value: 'America/Tegucigalpa',
    text: 'America/Tegucigalpa (UTC -06)',
  },
  {
    value: 'America/Regina',
    text: 'America/Regina (UTC -06)',
  },
  {
    value: 'Mexico/General',
    text: 'Mexico/General (UTC -06)',
  },
  {
    value: 'America/Rankin_Inlet',
    text: 'America/Rankin_Inlet (UTC -06)',
  },
  {
    value: 'US/Central',
    text: 'US/Central (UTC -06)',
  },
  {
    value: 'Pacific/Galapagos',
    text: 'Pacific/Galapagos (UTC -06)',
  },
  {
    value: 'America/Rainy_River',
    text: 'America/Rainy_River (UTC -06)',
  },
  {
    value: 'America/Swift_Current',
    text: 'America/Swift_Current (UTC -06)',
  },
  {
    value: 'America/Costa_Rica',
    text: 'America/Costa_Rica (UTC -06)',
  },
  {
    value: 'America/Indiana/Knox',
    text: 'America/Indiana/Knox (UTC -06)',
  },
  {
    value: 'America/North_Dakota/Beulah',
    text: 'America/North_Dakota/Beulah (UTC -06)',
  },
  {
    value: 'America/Monterrey',
    text: 'America/Monterrey (UTC -06)',
  },
  {
    value: 'America/North_Dakota/Center',
    text: 'America/North_Dakota/Center (UTC -06)',
  },
  {
    value: 'America/Indiana/Tell_City',
    text: 'America/Indiana/Tell_City (UTC -06)',
  },
  {
    value: 'America/Mexico_City',
    text: 'America/Mexico_City (UTC -06)',
  },
  {
    value: 'America/Matamoros',
    text: 'America/Matamoros (UTC -06)',
  },
  {
    value: 'CST6CDT',
    text: 'CST6CDT (UTC -06)',
  },
  {
    value: 'America/Knox_IN',
    text: 'America/Knox_IN (UTC -06)',
  },
  {
    value: 'America/Menominee',
    text: 'America/Menominee (UTC -06)',
  },
  {
    value: 'America/Resolute',
    text: 'America/Resolute (UTC -06)',
  },
  {
    value: 'Canada/Central',
    text: 'Canada/Central (UTC -06)',
  },
  {
    value: 'America/Bahia_Banderas',
    text: 'America/Bahia_Banderas (UTC -06)',
  },
  {
    value: 'US/Indiana-Starke',
    text: 'US/Indiana-Starke (UTC -06)',
  },
  {
    value: 'America/Merida',
    text: 'America/Merida (UTC -06)',
  },
  {
    value: 'Canada/Saskatchewan',
    text: 'Canada/Saskatchewan (UTC -06)',
  },
  {
    value: 'America/North_Dakota/New_Salem',
    text: 'America/North_Dakota/New_Salem (UTC -06)',
  },
  {
    value: 'America/Winnipeg',
    text: 'America/Winnipeg (UTC -06)',
  },
  {
    value: 'America/Panama',
    text: 'America/Panama (UTC -05)',
  },
  {
    value: 'America/Indiana/Petersburg',
    text: 'America/Indiana/Petersburg (UTC -05)',
  },
  {
    value: 'America/Eirunepe',
    text: 'America/Eirunepe (UTC -05)',
  },
  {
    value: 'America/Grand_Turk',
    text: 'America/Grand_Turk (UTC -05)',
  },
  {
    value: 'Cuba',
    text: 'Cuba (UTC -05)',
  },
  {
    value: 'Pacific/Easter',
    text: 'Pacific/Easter (UTC -05)',
  },
  {
    value: 'America/Fort_Wayne',
    text: 'America/Fort_Wayne (UTC -05)',
  },
  {
    value: 'America/Havana',
    text: 'America/Havana (UTC -05)',
  },
  {
    value: 'America/Porto_Acre',
    text: 'America/Porto_Acre (UTC -05)',
  },
  {
    value: 'US/Michigan',
    text: 'US/Michigan (UTC -05)',
  },
  {
    value: 'America/Louisville',
    text: 'America/Louisville (UTC -05)',
  },
  {
    value: 'America/Guayaquil',
    text: 'America/Guayaquil (UTC -05)',
  },
  {
    value: 'America/Indiana/Vevay',
    text: 'America/Indiana/Vevay (UTC -05)',
  },
  {
    value: 'America/Indiana/Vincennes',
    text: 'America/Indiana/Vincennes (UTC -05)',
  },
  {
    value: 'America/Indianapolis',
    text: 'America/Indianapolis (UTC -05)',
  },
  {
    value: 'America/Iqaluit',
    text: 'America/Iqaluit (UTC -05)',
  },
  {
    value: 'America/Kentucky/Louisville',
    text: 'America/Kentucky/Louisville (UTC -05)',
  },
  {
    value: 'EST5EDT',
    text: 'EST5EDT (UTC -05)',
  },
  {
    value: 'America/Nassau',
    text: 'America/Nassau (UTC -05)',
  },
  {
    value: 'America/Jamaica',
    text: 'America/Jamaica (UTC -05)',
  },
  {
    value: 'America/Atikokan',
    text: 'America/Atikokan (UTC -05)',
  },
  {
    value: 'America/Kentucky/Monticello',
    text: 'America/Kentucky/Monticello (UTC -05)',
  },
  {
    value: 'America/Coral_Harbour',
    text: 'America/Coral_Harbour (UTC -05)',
  },
  {
    value: 'America/Cayman',
    text: 'America/Cayman (UTC -05)',
  },
  {
    value: 'Chile/EasterIsland',
    text: 'Chile/EasterIsland (UTC -05)',
  },
  {
    value: 'America/Indiana/Indianapolis',
    text: 'America/Indiana/Indianapolis (UTC -05)',
  },
  {
    value: 'America/Thunder_Bay',
    text: 'America/Thunder_Bay (UTC -05)',
  },
  {
    value: 'America/Indiana/Marengo',
    text: 'America/Indiana/Marengo (UTC -05)',
  },
  {
    value: 'America/Bogota',
    text: 'America/Bogota (UTC -05)',
  },
  {
    value: 'US/Eastern',
    text: 'US/Eastern (UTC -05)',
  },
  {
    value: 'Canada/Eastern',
    text: 'Canada/Eastern (UTC -05)',
  },
  {
    value: 'America/Port-au-Prince',
    text: 'America/Port-au-Prince (UTC -05)',
  },
  {
    value: 'America/Nipigon',
    text: 'America/Nipigon (UTC -05)',
  },
  {
    value: 'Brazil/Acre',
    text: 'Brazil/Acre (UTC -05)',
  },
  {
    value: 'US/East-Indiana',
    text: 'US/East-Indiana (UTC -05)',
  },
  {
    value: 'America/Cancun',
    text: 'America/Cancun (UTC -05)',
  },
  {
    value: 'America/Lima',
    text: 'America/Lima (UTC -05)',
  },
  {
    value: 'America/Rio_Branco',
    text: 'America/Rio_Branco (UTC -05)',
  },
  {
    value: 'America/Detroit',
    text: 'America/Detroit (UTC -05)',
  },
  {
    value: 'Jamaica',
    text: 'Jamaica (UTC -05)',
  },
  {
    value: 'America/Pangnirtung',
    text: 'America/Pangnirtung (UTC -05)',
  },
  {
    value: 'America/Montreal',
    text: 'America/Montreal (UTC -05)',
  },
  {
    value: 'America/Indiana/Winamac',
    text: 'America/Indiana/Winamac (UTC -05)',
  },
  {
    value: 'America/New_York',
    text: 'America/New_York (UTC -05)',
  },
  {
    value: 'America/Toronto',
    text: 'America/Toronto (UTC -05)',
  },
  {
    value: 'America/Cuiaba',
    text: 'America/Cuiaba (UTC -04)',
  },
  {
    value: 'America/Marigot',
    text: 'America/Marigot (UTC -04)',
  },
  {
    value: 'Canada/Atlantic',
    text: 'Canada/Atlantic (UTC -04)',
  },
  {
    value: 'America/Manaus',
    text: 'America/Manaus (UTC -04)',
  },
  {
    value: 'America/St_Thomas',
    text: 'America/St_Thomas (UTC -04)',
  },
  {
    value: 'America/Anguilla',
    text: 'America/Anguilla (UTC -04)',
  },
  {
    value: 'America/Barbados',
    text: 'America/Barbados (UTC -04)',
  },
  {
    value: 'America/Curacao',
    text: 'America/Curacao (UTC -04)',
  },
  {
    value: 'America/Guyana',
    text: 'America/Guyana (UTC -04)',
  },
  {
    value: 'America/Martinique',
    text: 'America/Martinique (UTC -04)',
  },
  {
    value: 'America/Puerto_Rico',
    text: 'America/Puerto_Rico (UTC -04)',
  },
  {
    value: 'America/Port_of_Spain',
    text: 'America/Port_of_Spain (UTC -04)',
  },
  {
    value: 'America/Kralendijk',
    text: 'America/Kralendijk (UTC -04)',
  },
  {
    value: 'America/Antigua',
    text: 'America/Antigua (UTC -04)',
  },
  {
    value: 'America/Moncton',
    text: 'America/Moncton (UTC -04)',
  },
  {
    value: 'America/St_Vincent',
    text: 'America/St_Vincent (UTC -04)',
  },
  {
    value: 'America/Dominica',
    text: 'America/Dominica (UTC -04)',
  },
  {
    value: 'Atlantic/Bermuda',
    text: 'Atlantic/Bermuda (UTC -04)',
  },
  {
    value: 'Brazil/West',
    text: 'Brazil/West (UTC -04)',
  },
  {
    value: 'America/Aruba',
    text: 'America/Aruba (UTC -04)',
  },
  {
    value: 'America/Halifax',
    text: 'America/Halifax (UTC -04)',
  },
  {
    value: 'America/La_Paz',
    text: 'America/La_Paz (UTC -04)',
  },
  {
    value: 'America/Blanc-Sablon',
    text: 'America/Blanc-Sablon (UTC -04)',
  },
  {
    value: 'America/Santo_Domingo',
    text: 'America/Santo_Domingo (UTC -04)',
  },
  {
    value: 'America/Glace_Bay',
    text: 'America/Glace_Bay (UTC -04)',
  },
  {
    value: 'America/St_Barthelemy',
    text: 'America/St_Barthelemy (UTC -04)',
  },
  {
    value: 'America/St_Lucia',
    text: 'America/St_Lucia (UTC -04)',
  },
  {
    value: 'America/Montserrat',
    text: 'America/Montserrat (UTC -04)',
  },
  {
    value: 'America/Lower_Princes',
    text: 'America/Lower_Princes (UTC -04)',
  },
  {
    value: 'America/Thule',
    text: 'America/Thule (UTC -04)',
  },
  {
    value: 'America/Tortola',
    text: 'America/Tortola (UTC -04)',
  },
  {
    value: 'America/Porto_Velho',
    text: 'America/Porto_Velho (UTC -04)',
  },
  {
    value: 'America/Campo_Grande',
    text: 'America/Campo_Grande (UTC -04)',
  },
  {
    value: 'America/Goose_Bay',
    text: 'America/Goose_Bay (UTC -04)',
  },
  {
    value: 'America/Virgin',
    text: 'America/Virgin (UTC -04)',
  },
  {
    value: 'America/Boa_Vista',
    text: 'America/Boa_Vista (UTC -04)',
  },
  {
    value: 'America/Grenada',
    text: 'America/Grenada (UTC -04)',
  },
  {
    value: 'America/St_Kitts',
    text: 'America/St_Kitts (UTC -04)',
  },
  {
    value: 'America/Caracas',
    text: 'America/Caracas (UTC -04)',
  },
  {
    value: 'America/Guadeloupe',
    text: 'America/Guadeloupe (UTC -04)',
  },
  {
    value: 'America/St_Johns',
    text: 'America/St_Johns (UTC -03)',
  },
  {
    value: 'Canada/Newfoundland',
    text: 'Canada/Newfoundland (UTC -03)',
  },
  {
    value: 'Chile/Continental',
    text: 'Chile/Continental (UTC -03)',
  },
  {
    value: 'America/Miquelon',
    text: 'America/Miquelon (UTC -03)',
  },
  {
    value: 'America/Argentina/Catamarca',
    text: 'America/Argentina/Catamarca (UTC -03)',
  },
  {
    value: 'America/Argentina/Cordoba',
    text: 'America/Argentina/Cordoba (UTC -03)',
  },
  {
    value: 'America/Araguaina',
    text: 'America/Araguaina (UTC -03)',
  },
  {
    value: 'America/Argentina/Salta',
    text: 'America/Argentina/Salta (UTC -03)',
  },
  {
    value: 'America/Montevideo',
    text: 'America/Montevideo (UTC -03)',
  },
  {
    value: 'Brazil/East',
    text: 'Brazil/East (UTC -03)',
  },
  {
    value: 'America/Argentina/Mendoza',
    text: 'America/Argentina/Mendoza (UTC -03)',
  },
  {
    value: 'America/Argentina/Rio_Gallegos',
    text: 'America/Argentina/Rio_Gallegos (UTC -03)',
  },
  {
    value: 'America/Catamarca',
    text: 'America/Catamarca (UTC -03)',
  },
  {
    value: 'America/Godthab',
    text: 'America/Godthab (UTC -03)',
  },
  {
    value: 'America/Cordoba',
    text: 'America/Cordoba (UTC -03)',
  },
  {
    value: 'America/Sao_Paulo',
    text: 'America/Sao_Paulo (UTC -03)',
  },
  {
    value: 'America/Argentina/Jujuy',
    text: 'America/Argentina/Jujuy (UTC -03)',
  },
  {
    value: 'America/Cayenne',
    text: 'America/Cayenne (UTC -03)',
  },
  {
    value: 'America/Recife',
    text: 'America/Recife (UTC -03)',
  },
  {
    value: 'America/Buenos_Aires',
    text: 'America/Buenos_Aires (UTC -03)',
  },
  {
    value: 'America/Paramaribo',
    text: 'America/Paramaribo (UTC -03)',
  },
  {
    value: 'America/Mendoza',
    text: 'America/Mendoza (UTC -03)',
  },
  {
    value: 'America/Santarem',
    text: 'America/Santarem (UTC -03)',
  },
  {
    value: 'America/Asuncion',
    text: 'America/Asuncion (UTC -03)',
  },
  {
    value: 'America/Maceio',
    text: 'America/Maceio (UTC -03)',
  },
  {
    value: 'Atlantic/Stanley',
    text: 'Atlantic/Stanley (UTC -03)',
  },
  {
    value: 'Antarctica/Rothera',
    text: 'Antarctica/Rothera (UTC -03)',
  },
  {
    value: 'America/Argentina/San_Luis',
    text: 'America/Argentina/San_Luis (UTC -03)',
  },
  {
    value: 'America/Santiago',
    text: 'America/Santiago (UTC -03)',
  },
  {
    value: 'America/Argentina/Ushuaia',
    text: 'America/Argentina/Ushuaia (UTC -03)',
  },
  {
    value: 'Antarctica/Palmer',
    text: 'Antarctica/Palmer (UTC -03)',
  },
  {
    value: 'America/Punta_Arenas',
    text: 'America/Punta_Arenas (UTC -03)',
  },
  {
    value: 'America/Fortaleza',
    text: 'America/Fortaleza (UTC -03)',
  },
  {
    value: 'America/Argentina/La_Rioja',
    text: 'America/Argentina/La_Rioja (UTC -03)',
  },
  {
    value: 'America/Belem',
    text: 'America/Belem (UTC -03)',
  },
  {
    value: 'America/Jujuy',
    text: 'America/Jujuy (UTC -03)',
  },
  {
    value: 'America/Bahia',
    text: 'America/Bahia (UTC -03)',
  },
  {
    value: 'America/Argentina/San_Juan',
    text: 'America/Argentina/San_Juan (UTC -03)',
  },
  {
    value: 'America/Argentina/ComodRivadavia',
    text: 'America/Argentina/ComodRivadavia (UTC -03)',
  },
  {
    value: 'America/Argentina/Tucuman',
    text: 'America/Argentina/Tucuman (UTC -03)',
  },
  {
    value: 'America/Nuuk',
    text: 'America/Nuuk (UTC -03)',
  },
  {
    value: 'America/Rosario',
    text: 'America/Rosario (UTC -03)',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    text: 'America/Argentina/Buenos_Aires (UTC -03)',
  },
  {
    value: 'America/Noronha',
    text: 'America/Noronha (UTC -02)',
  },
  {
    value: 'Brazil/DeNoronha',
    text: 'Brazil/DeNoronha (UTC -02)',
  },
  {
    value: 'Atlantic/South_Georgia',
    text: 'Atlantic/South_Georgia (UTC -02)',
  },
  {
    value: 'Atlantic/Cape_Verde',
    text: 'Atlantic/Cape_Verde (UTC -01)',
  },
  {
    value: 'Atlantic/Azores',
    text: 'Atlantic/Azores (UTC -01)',
  },
  {
    value: 'America/Scoresbysund',
    text: 'America/Scoresbysund (UTC -01)',
  },
  {
    value: 'Europe/London',
    text: 'Europe/London (UTC +00)',
  },
  {
    value: 'GMT',
    text: 'GMT (UTC +00)',
  },
  {
    value: 'Europe/Jersey',
    text: 'Europe/Jersey (UTC +00)',
  },
  {
    value: 'Atlantic/St_Helena',
    text: 'Atlantic/St_Helena (UTC +00)',
  },
  {
    value: 'Europe/Guernsey',
    text: 'Europe/Guernsey (UTC +00)',
  },
  {
    value: 'Europe/Isle_of_Man',
    text: 'Europe/Isle_of_Man (UTC +00)',
  },
  {
    value: 'Africa/Banjul',
    text: 'Africa/Banjul (UTC +00)',
  },
  {
    value: 'Etc/GMT',
    text: 'Etc/GMT (UTC +00)',
  },
  {
    value: 'Africa/Freetown',
    text: 'Africa/Freetown (UTC +00)',
  },
  {
    value: 'GB-Eire',
    text: 'GB-Eire (UTC +00)',
  },
  {
    value: 'Africa/Bamako',
    text: 'Africa/Bamako (UTC +00)',
  },
  {
    value: 'GB',
    text: 'GB (UTC +00)',
  },
  {
    value: 'Africa/Conakry',
    text: 'Africa/Conakry (UTC +00)',
  },
  {
    value: 'Portugal',
    text: 'Portugal (UTC +00)',
  },
  {
    value: 'Universal',
    text: 'Universal (UTC +00)',
  },
  {
    value: 'Africa/Sao_Tome',
    text: 'Africa/Sao_Tome (UTC +00)',
  },
  {
    value: 'Africa/Nouakchott',
    text: 'Africa/Nouakchott (UTC +00)',
  },
  {
    value: 'Antarctica/Troll',
    text: 'Antarctica/Troll (UTC +00)',
  },
  {
    value: 'UTC',
    text: 'UTC',
  },
  {
    value: 'Etc/Universal',
    text: 'Etc/Universal (UTC +00)',
  },
  {
    value: 'Atlantic/Faeroe',
    text: 'Atlantic/Faeroe (UTC +00)',
  },
  {
    value: 'Africa/Abidjan',
    text: 'Africa/Abidjan (UTC +00)',
  },
  {
    value: 'Eire',
    text: 'Eire (UTC +00)',
  },
  {
    value: 'Africa/Accra',
    text: 'Africa/Accra (UTC +00)',
  },
  {
    value: 'Atlantic/Faroe',
    text: 'Atlantic/Faroe (UTC +00)',
  },
  {
    value: 'Etc/UCT',
    text: 'Etc/UCT (UTC +00)',
  },
  {
    value: 'GMT0',
    text: 'GMT0 (UTC +00)',
  },
  {
    value: 'Europe/Dublin',
    text: 'Europe/Dublin (UTC +00)',
  },
  {
    value: 'Zulu',
    text: 'Zulu (UTC +00)',
  },
  {
    value: 'Africa/Ouagadougou',
    text: 'Africa/Ouagadougou (UTC +00)',
  },
  {
    value: 'Atlantic/Reykjavik',
    text: 'Atlantic/Reykjavik (UTC +00)',
  },
  {
    value: 'Atlantic/Madeira',
    text: 'Atlantic/Madeira (UTC +00)',
  },
  {
    value: 'Etc/Zulu',
    text: 'Etc/Zulu (UTC +00)',
  },
  {
    value: 'Iceland',
    text: 'Iceland (UTC +00)',
  },
  {
    value: 'Europe/Lisbon',
    text: 'Europe/Lisbon (UTC +00)',
  },
  {
    value: 'Atlantic/Canary',
    text: 'Atlantic/Canary (UTC +00)',
  },
  {
    value: 'Africa/Lome',
    text: 'Africa/Lome (UTC +00)',
  },
  {
    value: 'Greenwich',
    text: 'Greenwich (UTC +00)',
  },
  {
    value: 'Europe/Belfast',
    text: 'Europe/Belfast (UTC +00)',
  },
  {
    value: 'Etc/GMT0',
    text: 'Etc/GMT0 (UTC +00)',
  },
  {
    value: 'America/Danmarkshavn',
    text: 'America/Danmarkshavn (UTC +00)',
  },
  {
    value: 'Africa/Dakar',
    text: 'Africa/Dakar (UTC +00)',
  },
  {
    value: 'Africa/Bissau',
    text: 'Africa/Bissau (UTC +00)',
  },
  {
    value: 'WET',
    text: 'WET (UTC +00)',
  },
  {
    value: 'Etc/Greenwich',
    text: 'Etc/Greenwich (UTC +00)',
  },
  {
    value: 'Africa/Timbuktu',
    text: 'Africa/Timbuktu (UTC +00)',
  },
  {
    value: 'UCT',
    text: 'UCT (UTC +00)',
  },
  {
    value: 'Africa/Monrovia',
    text: 'Africa/Monrovia (UTC +00)',
  },
  {
    value: 'Etc/UTC',
    text: 'Etc/UTC',
  },
  {
    value: 'Europe/Brussels',
    text: 'Europe/Brussels (UTC +01)',
  },
  {
    value: 'Europe/Warsaw',
    text: 'Europe/Warsaw (UTC +01)',
  },
  {
    value: 'CET',
    text: 'CET (UTC +01)',
  },
  {
    value: 'Europe/Luxembourg',
    text: 'Europe/Luxembourg (UTC +01)',
  },
  {
    value: 'Africa/Tunis',
    text: 'Africa/Tunis (UTC +01)',
  },
  {
    value: 'Europe/Malta',
    text: 'Europe/Malta (UTC +01)',
  },
  {
    value: 'Europe/Busingen',
    text: 'Europe/Busingen (UTC +01)',
  },
  {
    value: 'Africa/Malabo',
    text: 'Africa/Malabo (UTC +01)',
  },
  {
    value: 'Europe/Skopje',
    text: 'Europe/Skopje (UTC +01)',
  },
  {
    value: 'Europe/Sarajevo',
    text: 'Europe/Sarajevo (UTC +01)',
  },
  {
    value: 'Africa/Lagos',
    text: 'Africa/Lagos (UTC +01)',
  },
  {
    value: 'Europe/Rome',
    text: 'Europe/Rome (UTC +01)',
  },
  {
    value: 'Africa/Algiers',
    text: 'Africa/Algiers (UTC +01)',
  },
  {
    value: 'Europe/Zurich',
    text: 'Europe/Zurich (UTC +01)',
  },
  {
    value: 'Europe/Gibraltar',
    text: 'Europe/Gibraltar (UTC +01)',
  },
  {
    value: 'Europe/Vaduz',
    text: 'Europe/Vaduz (UTC +01)',
  },
  {
    value: 'Europe/Ljubljana',
    text: 'Europe/Ljubljana (UTC +01)',
  },
  {
    value: 'Europe/Berlin',
    text: 'Europe/Berlin (UTC +01)',
  },
  {
    value: 'Europe/Stockholm',
    text: 'Europe/Stockholm (UTC +01)',
  },
  {
    value: 'Europe/Budapest',
    text: 'Europe/Budapest (UTC +01)',
  },
  {
    value: 'Europe/Zagreb',
    text: 'Europe/Zagreb (UTC +01)',
  },
  {
    value: 'Europe/Paris',
    text: 'Europe/Paris (UTC +01)',
  },
  {
    value: 'Africa/Ndjamena',
    text: 'Africa/Ndjamena (UTC +01)',
  },
  {
    value: 'Africa/Ceuta',
    text: 'Africa/Ceuta (UTC +01)',
  },
  {
    value: 'Europe/Prague',
    text: 'Europe/Prague (UTC +01)',
  },
  {
    value: 'Europe/Copenhagen',
    text: 'Europe/Copenhagen (UTC +01)',
  },
  {
    value: 'Europe/Vienna',
    text: 'Europe/Vienna (UTC +01)',
  },
  {
    value: 'Europe/Tirane',
    text: 'Europe/Tirane (UTC +01)',
  },
  {
    value: 'MET',
    text: 'MET (UTC +01)',
  },
  {
    value: 'Europe/Amsterdam',
    text: 'Europe/Amsterdam (UTC +01)',
  },
  {
    value: 'Africa/Libreville',
    text: 'Africa/Libreville (UTC +01)',
  },
  {
    value: 'Africa/El_Aaiun',
    text: 'Africa/El_Aaiun (UTC +01)',
  },
  {
    value: 'Europe/San_Marino',
    text: 'Europe/San_Marino (UTC +01)',
  },
  {
    value: 'Africa/Douala',
    text: 'Africa/Douala (UTC +01)',
  },
  {
    value: 'Africa/Brazzaville',
    text: 'Africa/Brazzaville (UTC +01)',
  },
  {
    value: 'Africa/Porto-Novo',
    text: 'Africa/Porto-Novo (UTC +01)',
  },
  {
    value: 'Poland',
    text: 'Poland (UTC +01)',
  },
  {
    value: 'Europe/Andorra',
    text: 'Europe/Andorra (UTC +01)',
  },
  {
    value: 'Europe/Oslo',
    text: 'Europe/Oslo (UTC +01)',
  },
  {
    value: 'Europe/Podgorica',
    text: 'Europe/Podgorica (UTC +01)',
  },
  {
    value: 'Africa/Casablanca',
    text: 'Africa/Casablanca (UTC +01)',
  },
  {
    value: 'Africa/Luanda',
    text: 'Africa/Luanda (UTC +01)',
  },
  {
    value: 'Atlantic/Jan_Mayen',
    text: 'Atlantic/Jan_Mayen (UTC +01)',
  },
  {
    value: 'Africa/Kinshasa',
    text: 'Africa/Kinshasa (UTC +01)',
  },
  {
    value: 'Europe/Madrid',
    text: 'Europe/Madrid (UTC +01)',
  },
  {
    value: 'Africa/Bangui',
    text: 'Africa/Bangui (UTC +01)',
  },
  {
    value: 'Europe/Belgrade',
    text: 'Europe/Belgrade (UTC +01)',
  },
  {
    value: 'Africa/Niamey',
    text: 'Africa/Niamey (UTC +01)',
  },
  {
    value: 'Europe/Bratislava',
    text: 'Europe/Bratislava (UTC +01)',
  },
  {
    value: 'Arctic/Longyearbyen',
    text: 'Arctic/Longyearbyen (UTC +01)',
  },
  {
    value: 'Europe/Vatican',
    text: 'Europe/Vatican (UTC +01)',
  },
  {
    value: 'Europe/Monaco',
    text: 'Europe/Monaco (UTC +01)',
  },
  {
    value: 'Africa/Cairo',
    text: 'Africa/Cairo (UTC +02)',
  },
  {
    value: 'Africa/Mbabane',
    text: 'Africa/Mbabane (UTC +02)',
  },
  {
    value: 'Europe/Zaporozhye',
    text: 'Europe/Zaporozhye (UTC +02)',
  },
  {
    value: 'Libya',
    text: 'Libya (UTC +02)',
  },
  {
    value: 'Africa/Kigali',
    text: 'Africa/Kigali (UTC +02)',
  },
  {
    value: 'Africa/Tripoli',
    text: 'Africa/Tripoli (UTC +02)',
  },
  {
    value: 'Israel',
    text: 'Israel (UTC +02)',
  },
  {
    value: 'Europe/Kaliningrad',
    text: 'Europe/Kaliningrad (UTC +02)',
  },
  {
    value: 'Africa/Windhoek',
    text: 'Africa/Windhoek (UTC +02)',
  },
  {
    value: 'Europe/Bucharest',
    text: 'Europe/Bucharest (UTC +02)',
  },
  {
    value: 'Europe/Mariehamn',
    text: 'Europe/Mariehamn (UTC +02)',
  },
  {
    value: 'Africa/Lubumbashi',
    text: 'Africa/Lubumbashi (UTC +02)',
  },
  {
    value: 'Europe/Tiraspol',
    text: 'Europe/Tiraspol (UTC +02)',
  },
  {
    value: 'Europe/Chisinau',
    text: 'Europe/Chisinau (UTC +02)',
  },
  {
    value: 'Europe/Helsinki',
    text: 'Europe/Helsinki (UTC +02)',
  },
  {
    value: 'Asia/Beirut',
    text: 'Asia/Beirut (UTC +02)',
  },
  {
    value: 'Asia/Tel_Aviv',
    text: 'Asia/Tel_Aviv (UTC +02)',
  },
  {
    value: 'Europe/Sofia',
    text: 'Europe/Sofia (UTC +02)',
  },
  {
    value: 'Africa/Gaborone',
    text: 'Africa/Gaborone (UTC +02)',
  },
  {
    value: 'Asia/Gaza',
    text: 'Asia/Gaza (UTC +02)',
  },
  {
    value: 'Europe/Riga',
    text: 'Europe/Riga (UTC +02)',
  },
  {
    value: 'Africa/Maputo',
    text: 'Africa/Maputo (UTC +02)',
  },
  {
    value: 'Asia/Damascus',
    text: 'Asia/Damascus (UTC +02)',
  },
  {
    value: 'Europe/Uzhgorod',
    text: 'Europe/Uzhgorod (UTC +02)',
  },
  {
    value: 'Asia/Jerusalem',
    text: 'Asia/Jerusalem (UTC +02)',
  },
  {
    value: 'Africa/Bujumbura',
    text: 'Africa/Bujumbura (UTC +02)',
  },
  {
    value: 'Europe/Kiev',
    text: 'Europe/Kiev (UTC +02)',
  },
  {
    value: 'Europe/Vilnius',
    text: 'Europe/Vilnius (UTC +02)',
  },
  {
    value: 'Africa/Maseru',
    text: 'Africa/Maseru (UTC +02)',
  },
  {
    value: 'Africa/Blantyre',
    text: 'Africa/Blantyre (UTC +02)',
  },
  {
    value: 'Africa/Lusaka',
    text: 'Africa/Lusaka (UTC +02)',
  },
  {
    value: 'Africa/Harare',
    text: 'Africa/Harare (UTC +02)',
  },
  {
    value: 'Europe/Tallinn',
    text: 'Europe/Tallinn (UTC +02)',
  },
  {
    value: 'Africa/Khartoum',
    text: 'Africa/Khartoum (UTC +02)',
  },
  {
    value: 'Africa/Johannesburg',
    text: 'Africa/Johannesburg (UTC +02)',
  },
  {
    value: 'Africa/Juba',
    text: 'Africa/Juba (UTC +02)',
  },
  {
    value: 'Asia/Nicosia',
    text: 'Asia/Nicosia (UTC +02)',
  },
  {
    value: 'Asia/Famagusta',
    text: 'Asia/Famagusta (UTC +02)',
  },
  {
    value: 'EET',
    text: 'EET (UTC +02)',
  },
  {
    value: 'Asia/Hebron',
    text: 'Asia/Hebron (UTC +02)',
  },
  {
    value: 'Egypt',
    text: 'Egypt (UTC +02)',
  },
  {
    value: 'Asia/Amman',
    text: 'Asia/Amman (UTC +02)',
  },
  {
    value: 'Europe/Nicosia',
    text: 'Europe/Nicosia (UTC +02)',
  },
  {
    value: 'Europe/Athens',
    text: 'Europe/Athens (UTC +02)',
  },
  {
    value: 'Asia/Aden',
    text: 'Asia/Aden (UTC +03)',
  },
  {
    value: 'Africa/Nairobi',
    text: 'Africa/Nairobi (UTC +03)',
  },
  {
    value: 'Europe/Istanbul',
    text: 'Europe/Istanbul (UTC +03)',
  },
  {
    value: 'Indian/Comoro',
    text: 'Indian/Comoro (UTC +03)',
  },
  {
    value: 'Antarctica/Syowa',
    text: 'Antarctica/Syowa (UTC +03)',
  },
  {
    value: 'Africa/Mogadishu',
    text: 'Africa/Mogadishu (UTC +03)',
  },
  {
    value: 'Africa/Asmera',
    text: 'Africa/Asmera (UTC +03)',
  },
  {
    value: 'Asia/Istanbul',
    text: 'Asia/Istanbul (UTC +03)',
  },
  {
    value: 'Europe/Moscow',
    text: 'Europe/Moscow (UTC +03)',
  },
  {
    value: 'Africa/Djibouti',
    text: 'Africa/Djibouti (UTC +03)',
  },
  {
    value: 'Europe/Simferopol',
    text: 'Europe/Simferopol (UTC +03)',
  },
  {
    value: 'Africa/Asmara',
    text: 'Africa/Asmara (UTC +03)',
  },
  {
    value: 'Asia/Baghdad',
    text: 'Asia/Baghdad (UTC +03)',
  },
  {
    value: 'Africa/Dar_es_Salaam',
    text: 'Africa/Dar_es_Salaam (UTC +03)',
  },
  {
    value: 'Africa/Addis_Ababa',
    text: 'Africa/Addis_Ababa (UTC +03)',
  },
  {
    value: 'Asia/Riyadh',
    text: 'Asia/Riyadh (UTC +03)',
  },
  {
    value: 'Asia/Kuwait',
    text: 'Asia/Kuwait (UTC +03)',
  },
  {
    value: 'Europe/Kirov',
    text: 'Europe/Kirov (UTC +03)',
  },
  {
    value: 'Africa/Kampala',
    text: 'Africa/Kampala (UTC +03)',
  },
  {
    value: 'Europe/Minsk',
    text: 'Europe/Minsk (UTC +03)',
  },
  {
    value: 'Asia/Qatar',
    text: 'Asia/Qatar (UTC +03)',
  },
  {
    value: 'Asia/Bahrain',
    text: 'Asia/Bahrain (UTC +03)',
  },
  {
    value: 'Indian/Antananarivo',
    text: 'Indian/Antananarivo (UTC +03)',
  },
  {
    value: 'Indian/Mayotte',
    text: 'Indian/Mayotte (UTC +03)',
  },
  {
    value: 'Europe/Volgograd',
    text: 'Europe/Volgograd (UTC +03)',
  },
  {
    value: 'Turkey',
    text: 'Turkey (UTC +03)',
  },
  {
    value: 'W-SU',
    text: 'W-SU (UTC +03)',
  },
  {
    value: 'Iran',
    text: 'Iran (UTC +03:30)',
  },
  {
    value: 'Asia/Tehran',
    text: 'Asia/Tehran (UTC +03:30)',
  },
  {
    value: 'Asia/Yerevan',
    text: 'Asia/Yerevan (UTC +04)',
  },
  {
    value: 'Asia/Dubai',
    text: 'Asia/Dubai (UTC +04)',
  },
  {
    value: 'Indian/Reunion',
    text: 'Indian/Reunion (UTC +04)',
  },
  {
    value: 'Indian/Mauritius',
    text: 'Indian/Mauritius (UTC +04)',
  },
  {
    value: 'Europe/Saratov',
    text: 'Europe/Saratov (UTC +04)',
  },
  {
    value: 'Europe/Samara',
    text: 'Europe/Samara (UTC +04)',
  },
  {
    value: 'Indian/Mahe',
    text: 'Indian/Mahe (UTC +04)',
  },
  {
    value: 'Asia/Baku',
    text: 'Asia/Baku (UTC +04)',
  },
  {
    value: 'Asia/Muscat',
    text: 'Asia/Muscat (UTC +04)',
  },
  {
    value: 'Europe/Astrakhan',
    text: 'Europe/Astrakhan (UTC +04)',
  },
  {
    value: 'Asia/Tbilisi',
    text: 'Asia/Tbilisi (UTC +04)',
  },
  {
    value: 'Europe/Ulyanovsk',
    text: 'Europe/Ulyanovsk (UTC +04)',
  },
  {
    value: 'Asia/Kabul',
    text: 'Asia/Kabul (UTC +04:30)',
  },
  {
    value: 'Asia/Aqtau',
    text: 'Asia/Aqtau (UTC +05)',
  },
  {
    value: 'Asia/Samarkand',
    text: 'Asia/Samarkand (UTC +05)',
  },
  {
    value: 'Asia/Karachi',
    text: 'Asia/Karachi (UTC +05)',
  },
  {
    value: 'Asia/Yekaterinburg',
    text: 'Asia/Yekaterinburg (UTC +05)',
  },
  {
    value: 'Asia/Dushanbe',
    text: 'Asia/Dushanbe (UTC +05)',
  },
  {
    value: 'Indian/Maldives',
    text: 'Indian/Maldives (UTC +05)',
  },
  {
    value: 'Asia/Oral',
    text: 'Asia/Oral (UTC +05)',
  },
  {
    value: 'Asia/Tashkent',
    text: 'Asia/Tashkent (UTC +05)',
  },
  {
    value: 'Antarctica/Mawson',
    text: 'Antarctica/Mawson (UTC +05)',
  },
  {
    value: 'Asia/Qyzylorda',
    text: 'Asia/Qyzylorda (UTC +05)',
  },
  {
    value: 'Asia/Aqtobe',
    text: 'Asia/Aqtobe (UTC +05)',
  },
  {
    value: 'Asia/Ashkhabad',
    text: 'Asia/Ashkhabad (UTC +05)',
  },
  {
    value: 'Asia/Ashgabat',
    text: 'Asia/Ashgabat (UTC +05)',
  },
  {
    value: 'Asia/Atyrau',
    text: 'Asia/Atyrau (UTC +05)',
  },
  {
    value: 'Indian/Kerguelen',
    text: 'Indian/Kerguelen (UTC +05)',
  },
  {
    value: 'Asia/Kolkata',
    text: 'Asia/Kolkata (UTC +05:30)',
  },
  {
    value: 'Asia/Colombo',
    text: 'Asia/Colombo (UTC +05:30)',
  },
  {
    value: 'Asia/Calcutta',
    text: 'Asia/Calcutta (UTC +05:30)',
  },
  {
    value: 'Asia/Kashgar',
    text: 'Asia/Kashgar (UTC +06)',
  },
  {
    value: 'Asia/Almaty',
    text: 'Asia/Almaty (UTC +06)',
  },
  {
    value: 'Asia/Dacca',
    text: 'Asia/Dacca (UTC +06)',
  },
  {
    value: 'Asia/Omsk',
    text: 'Asia/Omsk (UTC +06)',
  },
  {
    value: 'Asia/Dhaka',
    text: 'Asia/Dhaka (UTC +06)',
  },
  {
    value: 'Indian/Chagos',
    text: 'Indian/Chagos (UTC +06)',
  },
  {
    value: 'Asia/Qostanay',
    text: 'Asia/Qostanay (UTC +06)',
  },
  {
    value: 'Asia/Bishkek',
    text: 'Asia/Bishkek (UTC +06)',
  },
  {
    value: 'Antarctica/Vostok',
    text: 'Antarctica/Vostok (UTC +06)',
  },
  {
    value: 'Asia/Urumqi',
    text: 'Asia/Urumqi (UTC +06)',
  },
  {
    value: 'Asia/Thimbu',
    text: 'Asia/Thimbu (UTC +06)',
  },
  {
    value: 'Asia/Thimphu',
    text: 'Asia/Thimphu (UTC +06)',
  },
  {
    value: 'Asia/Yangon',
    text: 'Asia/Yangon (UTC +06:30)',
  },
  {
    value: 'Asia/Rangoon',
    text: 'Asia/Rangoon (UTC +06:30)',
  },
  {
    value: 'Indian/Cocos',
    text: 'Indian/Cocos (UTC +06:30)',
  },
  {
    value: 'Asia/Pontianak',
    text: 'Asia/Pontianak (UTC +07)',
  },
  {
    value: 'Asia/Phnom_Penh',
    text: 'Asia/Phnom_Penh (UTC +07)',
  },
  {
    value: 'Asia/Novosibirsk',
    text: 'Asia/Novosibirsk (UTC +07)',
  },
  {
    value: 'Antarctica/Davis',
    text: 'Antarctica/Davis (UTC +07)',
  },
  {
    value: 'Asia/Tomsk',
    text: 'Asia/Tomsk (UTC +07)',
  },
  {
    value: 'Asia/Jakarta',
    text: 'Asia/Jakarta (UTC +07)',
  },
  {
    value: 'Asia/Barnaul',
    text: 'Asia/Barnaul (UTC +07)',
  },
  {
    value: 'Indian/Christmas',
    text: 'Indian/Christmas (UTC +07)',
  },
  {
    value: 'Asia/Ho_Chi_Minh',
    text: 'Asia/Ho_Chi_Minh (UTC +07)',
  },
  {
    value: 'Asia/Hovd',
    text: 'Asia/Hovd (UTC +07)',
  },
  {
    value: 'Asia/Bangkok',
    text: 'Asia/Bangkok (UTC +07)',
  },
  {
    value: 'Asia/Vientiane',
    text: 'Asia/Vientiane (UTC +07)',
  },
  {
    value: 'Asia/Novokuznetsk',
    text: 'Asia/Novokuznetsk (UTC +07)',
  },
  {
    value: 'Asia/Krasnoyarsk',
    text: 'Asia/Krasnoyarsk (UTC +07)',
  },
  {
    value: 'Asia/Saigon',
    text: 'Asia/Saigon (UTC +07)',
  },
  {
    value: 'Asia/Kuching',
    text: 'Asia/Kuching (UTC +08)',
  },
  {
    value: 'Asia/Chungking',
    text: 'Asia/Chungking (UTC +08)',
  },
  {
    value: 'Australia/Perth',
    text: 'Australia/Perth (UTC +08)',
  },
  {
    value: 'Asia/Macao',
    text: 'Asia/Macao (UTC +08)',
  },
  {
    value: 'Asia/Macau',
    text: 'Asia/Macau (UTC +08)',
  },
  {
    value: 'Asia/Choibalsan',
    text: 'Asia/Choibalsan (UTC +08)',
  },
  {
    value: 'Asia/Shanghai',
    text: 'Asia/Shanghai (UTC +08)',
  },
  {
    value: 'Asia/Ulan_Bator',
    text: 'Asia/Ulan_Bator (UTC +08)',
  },
  {
    value: 'Asia/Chongqing',
    text: 'Asia/Chongqing (UTC +08)',
  },
  {
    value: 'Asia/Ulaanbaatar',
    text: 'Asia/Ulaanbaatar (UTC +08)',
  },
  {
    value: 'Asia/Taipei',
    text: 'Asia/Taipei (UTC +08)',
  },
  {
    value: 'Asia/Manila',
    text: 'Asia/Manila (UTC +08)',
  },
  {
    value: 'PRC',
    text: 'PRC (UTC +08)',
  },
  {
    value: 'Asia/Ujung_Pandang',
    text: 'Asia/Ujung_Pandang (UTC +08)',
  },
  {
    value: 'Asia/Harbin',
    text: 'Asia/Harbin (UTC +08)',
  },
  {
    value: 'Singapore',
    text: 'Singapore (UTC +08)',
  },
  {
    value: 'Asia/Brunei',
    text: 'Asia/Brunei (UTC +08)',
  },
  {
    value: 'Australia/West',
    text: 'Australia/West (UTC +08)',
  },
  {
    value: 'Asia/Hong_Kong',
    text: 'Asia/Hong_Kong (UTC +08)',
  },
  {
    value: 'Asia/Makassar',
    text: 'Asia/Makassar (UTC +08)',
  },
  {
    value: 'Hongkong',
    text: 'Hongkong (UTC +08)',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    text: 'Asia/Kuala_Lumpur (UTC +08)',
  },
  {
    value: 'Asia/Irkutsk',
    text: 'Asia/Irkutsk (UTC +08)',
  },
  {
    value: 'Asia/Singapore',
    text: 'Asia/Singapore (UTC +08)',
  },
  {
    value: 'Pacific/Palau',
    text: 'Pacific/Palau (UTC +09)',
  },
  {
    value: 'Asia/Chita',
    text: 'Asia/Chita (UTC +09)',
  },
  {
    value: 'Asia/Dili',
    text: 'Asia/Dili (UTC +09)',
  },
  {
    value: 'Asia/Jayapura',
    text: 'Asia/Jayapura (UTC +09)',
  },
  {
    value: 'Asia/Yakutsk',
    text: 'Asia/Yakutsk (UTC +09)',
  },
  {
    value: 'Asia/Pyongyang',
    text: 'Asia/Pyongyang (UTC +09)',
  },
  {
    value: 'ROK',
    text: 'ROK (UTC +09)',
  },
  {
    value: 'Asia/Seoul',
    text: 'Asia/Seoul (UTC +09)',
  },
  {
    value: 'Asia/Khandyga',
    text: 'Asia/Khandyga (UTC +09)',
  },
  {
    value: 'Japan',
    text: 'Japan (UTC +09)',
  },
  {
    value: 'Asia/Tokyo',
    text: 'Asia/Tokyo (UTC +09)',
  },
  {
    value: 'Australia/North',
    text: 'Australia/North (UTC +09:30)',
  },
  {
    value: 'Australia/Darwin',
    text: 'Australia/Darwin (UTC +09:30)',
  },
  {
    value: 'Pacific/Yap',
    text: 'Pacific/Yap (UTC +10)',
  },
  {
    value: 'Pacific/Port_Moresby',
    text: 'Pacific/Port_Moresby (UTC +10)',
  },
  {
    value: 'Pacific/Chuuk',
    text: 'Pacific/Chuuk (UTC +10)',
  },
  {
    value: 'Australia/Queensland',
    text: 'Australia/Queensland (UTC +10)',
  },
  {
    value: 'Pacific/Guam',
    text: 'Pacific/Guam (UTC +10)',
  },
  {
    value: 'Pacific/Truk',
    text: 'Pacific/Truk (UTC +10)',
  },
  {
    value: 'Asia/Vladivostok',
    text: 'Asia/Vladivostok (UTC +10)',
  },
  {
    value: 'Pacific/Saipan',
    text: 'Pacific/Saipan (UTC +10)',
  },
  {
    value: 'Antarctica/DumontDUrville',
    text: 'Antarctica/DumontDUrville (UTC +10)',
  },
  {
    value: 'Australia/Brisbane',
    text: 'Australia/Brisbane (UTC +10)',
  },
  {
    value: 'Asia/Ust-Nera',
    text: 'Asia/Ust-Nera (UTC +10)',
  },
  {
    value: 'Australia/Lindeman',
    text: 'Australia/Lindeman (UTC +10)',
  },
  {
    value: 'Australia/Yancowinna',
    text: 'Australia/Yancowinna (UTC +10:30)',
  },
  {
    value: 'Australia/Adelaide',
    text: 'Australia/Adelaide (UTC +10:30)',
  },
  {
    value: 'Australia/Broken_Hill',
    text: 'Australia/Broken_Hill (UTC +10:30)',
  },
  {
    value: 'Australia/South',
    text: 'Australia/South (UTC +10:30)',
  },
  {
    value: 'Australia/Hobart',
    text: 'Australia/Hobart (UTC +11)',
  },
  {
    value: 'Australia/Tasmania',
    text: 'Australia/Tasmania (UTC +11)',
  },
  {
    value: 'Australia/ACT',
    text: 'Australia/ACT (UTC +11)',
  },
  {
    value: 'Pacific/Ponape',
    text: 'Pacific/Ponape (UTC +11)',
  },
  {
    value: 'Pacific/Bougainville',
    text: 'Pacific/Bougainville (UTC +11)',
  },
  {
    value: 'Australia/Victoria',
    text: 'Australia/Victoria (UTC +11)',
  },
  {
    value: 'Antarctica/Macquarie',
    text: 'Antarctica/Macquarie (UTC +11)',
  },
  {
    value: 'Antarctica/Casey',
    text: 'Antarctica/Casey (UTC +11)',
  },
  {
    value: 'Australia/Canberra',
    text: 'Australia/Canberra (UTC +11)',
  },
  {
    value: 'Australia/Currie',
    text: 'Australia/Currie (UTC +11)',
  },
  {
    value: 'Australia/Lord_Howe',
    text: 'Australia/Lord_Howe (UTC +11)',
  },
  {
    value: 'Australia/NSW',
    text: 'Australia/NSW (UTC +11)',
  },
  {
    value: 'Pacific/Pohnpei',
    text: 'Pacific/Pohnpei (UTC +11)',
  },
  {
    value: 'Pacific/Efate',
    text: 'Pacific/Efate (UTC +11)',
  },
  {
    value: 'Asia/Magadan',
    text: 'Asia/Magadan (UTC +11)',
  },
  {
    value: 'Pacific/Kosrae',
    text: 'Pacific/Kosrae (UTC +11)',
  },
  {
    value: 'Australia/Sydney',
    text: 'Australia/Sydney (UTC +11)',
  },
  {
    value: 'Australia/LHI',
    text: 'Australia/LHI (UTC +11)',
  },
  {
    value: 'Asia/Sakhalin',
    text: 'Asia/Sakhalin (UTC +11)',
  },
  {
    value: 'Pacific/Noumea',
    text: 'Pacific/Noumea (UTC +11)',
  },
  {
    value: 'Asia/Srednekolymsk',
    text: 'Asia/Srednekolymsk (UTC +11)',
  },
  {
    value: 'Australia/Melbourne',
    text: 'Australia/Melbourne (UTC +11)',
  },
  {
    value: 'Pacific/Guadalcanal',
    text: 'Pacific/Guadalcanal (UTC +11)',
  },
  {
    value: 'Pacific/Kwajalein',
    text: 'Pacific/Kwajalein (UTC +12)',
  },
  {
    value: 'Pacific/Wallis',
    text: 'Pacific/Wallis (UTC +12)',
  },
  {
    value: 'Pacific/Fiji',
    text: 'Pacific/Fiji (UTC +12)',
  },
  {
    value: 'Pacific/Funafuti',
    text: 'Pacific/Funafuti (UTC +12)',
  },
  {
    value: 'Pacific/Nauru',
    text: 'Pacific/Nauru (UTC +12)',
  },
  {
    value: 'Kwajalein',
    text: 'Kwajalein (UTC +12)',
  },
  {
    value: 'Pacific/Wake',
    text: 'Pacific/Wake (UTC +12)',
  },
  {
    value: 'Pacific/Norfolk',
    text: 'Pacific/Norfolk (UTC +12)',
  },
  {
    value: 'Pacific/Tarawa',
    text: 'Pacific/Tarawa (UTC +12)',
  },
  {
    value: 'Asia/Kamchatka',
    text: 'Asia/Kamchatka (UTC +12)',
  },
  {
    value: 'Asia/Anadyr',
    text: 'Asia/Anadyr (UTC +12)',
  },
  {
    value: 'Pacific/Majuro',
    text: 'Pacific/Majuro (UTC +12)',
  },
  {
    value: 'Pacific/Fakaofo',
    text: 'Pacific/Fakaofo (UTC +13)',
  },
  {
    value: 'Antarctica/McMurdo',
    text: 'Antarctica/McMurdo (UTC +13)',
  },
  {
    value: 'Pacific/Enderbury',
    text: 'Pacific/Enderbury (UTC +13)',
  },
  {
    value: 'NZ',
    text: 'NZ (UTC +13)',
  },
  {
    value: 'Pacific/Apia',
    text: 'Pacific/Apia (UTC +13)',
  },
  {
    value: 'Antarctica/South_Pole',
    text: 'Antarctica/South_Pole (UTC +13)',
  },
  {
    value: 'Pacific/Kanton',
    text: 'Pacific/Kanton (UTC +13)',
  },
  {
    value: 'Pacific/Auckland',
    text: 'Pacific/Auckland (UTC +13)',
  },
  {
    value: 'Pacific/Tongatapu',
    text: 'Pacific/Tongatapu (UTC +13)',
  },
  {
    value: 'Pacific/Kiritimati',
    text: 'Pacific/Kiritimati (UTC +14)',
  },
];
