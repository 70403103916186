import { useState, useEffect } from 'react';
import { useLazyGetUserByIdQuery } from '../redux/services/user';
import { UserResult, UserStructure } from '../@types/users';
import { batchPromises, makeCancelable } from '../common/promise-util';
import { FieldFilters } from '../@types/common';
import { FieldSorts } from '../@types/common';
import { resolveFieldDisplayValue } from '../common/field-property-util';

interface FetchUsersState {
  isLoading: boolean;
  users: UserResult[];
}

/**
 * A hook that fetches users by id, and fields configured to be displayed
 * @param {number | Array<number>} userIds
 * @param {string} userFields
 * @param {FieldFilters} filters
 * @param {FieldSorts} sorts
 * @returns {{isLoading: boolean, users: Array<UserResult>}}
 */
const useFetchUsers = (
  userIds: number | Array<number>,
  userFields: string,
  filters?: FieldFilters,
  sorts?: FieldSorts
) => {
  const [state, setState] = useState<FetchUsersState>({ isLoading: false, users: [] });
  const [getUserById] = useLazyGetUserByIdQuery();

  const applySorting = (users: Array<UserStructure>, sortConfig: FieldSorts) => {
    const sortedFieldsArray = Object.entries(sortConfig).sort(([, a], [, b]) => a.order - b.order);
    return users.slice().sort((a, b) => {
      for (const [field, sortOrder] of sortedFieldsArray) {
        const aValue = resolveFieldDisplayValue(a[field]).toLowerCase();
        const bValue = resolveFieldDisplayValue(b[field]).toLowerCase();

        if (aValue < bValue) return sortOrder.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortOrder.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    return users;
  };

  const fetchUsers = async (userIds: Array<number>) => {
    const userRequests: Array<() => Promise<UserStructure>> = []; // holds the list of record requests to be made

    userIds.forEach((_id) =>
      userRequests.push(() => {
        return getUserById({
          id: _id,
          fields: userFields,
          filters: filters,
        }).unwrap();
      })
    );

    // fetch users in batches
    const promises = await batchPromises(userRequests, 10);

    // extract the fulfilled promises
    const fulfilledResponses: Array<UserStructure> = (
      promises.filter((promise) => promise.status === 'fulfilled') as PromiseFulfilledResult<UserStructure>[]
    ).map((promise) => promise.value);

    const users: Array<UserStructure> = [];
    fulfilledResponses.forEach((fulfilledResponse) => fulfilledResponse && users.push(fulfilledResponse));

    if (sorts) {
      return applySorting(users, sorts);
    }

    return users;
  };

  useEffect(() => {
    const users = Array.isArray(userIds) ? userIds : Array.of(userIds);
    const fetchUsersPromise = makeCancelable(fetchUsers(users));

    setState({
      ...state,
      isLoading: true,
    });

    fetchUsersPromise.promise
      .then((_users) =>
        setState({
          isLoading: false,
          users: _users as UserResult[],
        })
      )
      .catch(({ isCanceled }) => {
        if (isCanceled !== true) {
          // only update the state if the promise was not cancelled
          setState({
            ...state,
            isLoading: false,
          });
        }
      });

    return () => {
      fetchUsersPromise.cancel();
    };
  }, [userIds, sorts]);

  return {
    ...state,
  };
};

export default useFetchUsers;
