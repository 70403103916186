import React from 'react';
import { useTranslation } from 'react-i18next';

import { selectionDataSettings } from '../../../record-notification/notifications-utils';
import { NotificationTypeFieldProps } from './notification-properties-utils';
import useModuleWorkflowSteps from '../../../../hooks/use-module-wokflow-steps';
import { DynamicFormRequiredType } from '@myosh/odin-components';
import HfDropDown from '../../custom-drop-downs/hf-drop-down.component';

const TriggerWorkflowSteps = ({ control, value, readOnly }: NotificationTypeFieldProps) => {
  const { t } = useTranslation();
  const steps = useModuleWorkflowSteps(value?.moduleForm.id);

  return (
    <HfDropDown
      data={steps}
      control={control}
      label={t('status')}
      name="triggerWorkflowStep"
      readOnly={readOnly}
      required={DynamicFormRequiredType.True}
      {...selectionDataSettings}
    />
  );
};

export default TriggerWorkflowSteps;
