import { useCallback, useEffect, useRef } from 'react';

type TimeoutReturnType = ReturnType<typeof setTimeout>;

/**
 * A helper hook that provides a timeout ref and a clear method
 *
 * This hook ensures that any set timeout is correctly cleared when the component is unmounted.
 */
const useTimeoutRef = () => {
  const timeoutRef = useRef<TimeoutReturnType>();

  useEffect(() => {
    return () => {
      _clearTimeout();
    };
  }, []);

  const _clearTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, []);

  return {
    timeoutRef,
    clearTimeoutRef: _clearTimeout,
  };
};

export default useTimeoutRef;
