import React, { useEffect, useRef, useState } from 'react';
import OdinDropDownComponent from '../custom-drop-downs/odin-drop-down.component';
import {
  DropDownMultiSelect,
  DropDownNoSelection,
  DropDownRef,
  DropDownResult,
  JsonDataItem,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import { forceAssert } from '../../../common/common-functions';
import { useFormSettingsQuery } from '../../../redux/services/api';
import { CombinedFieldType } from '../../../@types/forms';
import { useLazyFieldGroupsOptionsQuery, useLazyPersonFieldOptionsQuery } from '../../../redux/services/field';
import { TypeOrArray } from '@myosh/odin-components/dist/types/components/common-interfaces';
import { CloneWizardState } from './clone-record-button-field.component';
import { useTranslation } from 'react-i18next';

interface SecondStepProps {
  formId: number;
  moduleFormCloneTargetId?: number;
  cloneWizardTargetPersonField?: number;
  error: boolean;
  cloneWizardState: CloneWizardState;
  setCloneWizardState: React.Dispatch<React.SetStateAction<CloneWizardState>>;
  setSecondStepError: (value: boolean) => void;
}

const SecondStep = ({
  formId,
  moduleFormCloneTargetId,
  cloneWizardTargetPersonField,
  error,
  cloneWizardState,
  setCloneWizardState,
  setSecondStepError,
}: SecondStepProps) => {
  const { t } = useTranslation();

  const [personFieldList, setPersonFieldList] = useState<DropDownResult[]>([]);

  const { data: formSettingsData } = useFormSettingsQuery(moduleFormCloneTargetId ?? formId ?? 0);
  const [getPersonField] = useLazyPersonFieldOptionsQuery();
  const [getGroups] = useLazyFieldGroupsOptionsQuery();
  const nextRef = useRef<string>();
  const usersDropDownRef = useRef<DropDownRef>(null);
  const groupsDropDownRef = useRef<DropDownRef>(null);

  const subscriberRef = useRef<OdinDataSender<JsonDataWrapper>>();
  const [options, setOptions] = useState<OdinDataRetrievalOptions>();

  const userSubscriberRef = useRef<OdinDataSender<JsonDataWrapper>>();
  const [userOptions, setUserOptions] = useState<OdinDataRetrievalOptions>();

  useEffect(() => {
    if (formSettingsData) {
      const fields = formSettingsData.fields;
      const personFieldsList: DropDownResult[] = [];
      for (let i = 0; i < fields.length; i++) {
        const filteredField = fields[i].fields?.filter((item) => {
          const fieldType: CombinedFieldType = item.fieldType;
          if (['PERSONFIELD'].includes(fieldType)) {
            return {
              item,
            };
          }
        });
        filteredField?.map((item) => {
          if (cloneWizardTargetPersonField) {
            if (item.id === cloneWizardTargetPersonField) {
              personFieldsList.push({
                value: item.id,
                text: item.caption,
              });
            }
          } else {
            personFieldsList.push({
              value: item.id,
              text: item.caption,
            });
          }
        });
      }
      setPersonFieldList(personFieldsList);
    }
  }, [formSettingsData, cloneWizardTargetPersonField]);

  const getGroupOptionsData = () => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        subscriberRef.current = subscriber;
        setOptions(options);
      },
    };
  };

  const getUserOptionsData = () => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        userSubscriberRef.current = subscriber;
        setUserOptions(options);
      },
    };
  };

  useEffect(() => {
    if (options) {
      try {
        getGroups(cloneWizardTargetPersonField ?? (cloneWizardState.personField?.value as number) ?? 0)
          .unwrap()
          .then((result) => {
            const fieldGroupsOptions: JsonDataItem[] | undefined = result.items?.map((item) => {
              return {
                value: item.id,
                text: item.caption.translations[0].value,
              };
            });
            subscriberRef?.current?.sendData({ data: fieldGroupsOptions, requestId: options.requestId });
          });
      } catch (_) {
        subscriberRef?.current?.sendData();
      }
    } else {
      subscriberRef?.current?.sendData();
    }
  }, [options, cloneWizardState.personField?.value]);

  useEffect(() => {
    if (userOptions) {
      try {
        getPersonField({
          queryId: cloneWizardTargetPersonField ?? (cloneWizardState.personField?.value as number) ?? 0,
          limit: userOptions.pageSize ?? 50,
          next: nextRef.current,
        })
          .unwrap()
          .then((result) => {
            nextRef.current = result.next;
            const personFieldOptions: JsonDataItem[] | undefined = result.items?.map((item) => {
              return {
                value: item.id,
                text: item.value,
              };
            });
            userSubscriberRef?.current?.sendData({ data: personFieldOptions, requestId: userOptions.requestId });
          });
      } catch (_) {
        userSubscriberRef?.current?.sendData();
      }
    } else {
      userSubscriberRef?.current?.sendData();
    }
  }, [userOptions, cloneWizardState.personField?.value]);

  const onPersonFieldChange = (value: TypeOrArray<DropDownResult> | DropDownNoSelection | undefined) => {
    nextRef.current = undefined;
    usersDropDownRef.current?.resetDropDownData();
    usersDropDownRef.current?.getDataOverwritePageCache();
    groupsDropDownRef.current?.resetDropDownData();
    groupsDropDownRef.current?.getDataOverwritePageCache();
    setCloneWizardState((prevState: CloneWizardState) => ({
      ...prevState,
      personField: forceAssert(value),
      users: [],
      groups: [],
    }));
  };

  return (
    <div className="flex flex-col gap-5 pb-3">
      {Boolean(cloneWizardTargetPersonField) ? (
        <div>{t('clone-records-selected-person-field', { fieldName: personFieldList[0]?.value ?? '' })}</div>
      ) : (
        <div>{t('clone-records-no-selected-person-field')}</div>
      )}
      {!Boolean(cloneWizardTargetPersonField) && (
        <OdinDropDownComponent
          valueField="value"
          textField="text"
          data={forceAssert(personFieldList)}
          label={t('clone-records-person-field-label')}
          onChange={(value) => {
            onPersonFieldChange(value);
          }}
          allowClear={false}
        />
      )}
      {(cloneWizardState.personField || Boolean(cloneWizardTargetPersonField)) && (
        <DropDownMultiSelect
          ref={usersDropDownRef}
          valueField="value"
          textField="text"
          data={getUserOptionsData()}
          label={t('clone-records-users-dropdown-label')}
          onChange={(value) => {
            setCloneWizardState((prevState: CloneWizardState) => ({
              ...prevState,
              users: forceAssert(value),
            }));
            setSecondStepError(false);
          }}
        />
      )}
      {(cloneWizardState.personField || Boolean(cloneWizardTargetPersonField)) && (
        <DropDownMultiSelect
          ref={groupsDropDownRef}
          valueField="value"
          textField="text"
          data={getGroupOptionsData()}
          label={t('clone-records-groups-dropdown-label')}
          onChange={(value) => {
            setCloneWizardState((prevState: CloneWizardState) => ({
              ...prevState,
              groups: forceAssert(value),
            }));
            setSecondStepError(false);
          }}
        />
      )}
      {error && <span className="text-error">{t('clone-records-assignment-error')}</span>}
    </div>
  );
};

export default SecondStep;
