import React from 'react';
import { OdinController } from '@myosh/odin-components';
import ComboboxMultiselect, { ComboboxMultiselectProps } from './combobox-multiselect.component';

interface HfDropDownMultiSelectProps extends ComboboxMultiselectProps {
  name: string;
}

export default function HfComboboxMultiselect({
  control,
  name,
  value,
  onChange,
  textField,
  valueField,
  fieldValueConverter,
  subType,
  ...rest
}: HfDropDownMultiSelectProps) {
  return (
    <OdinController
      name={name}
      control={control}
      onChange={onChange}
      defaultValue={value}
      fieldValueConverter={fieldValueConverter}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ComboboxMultiselect
          {...rest}
          name={name}
          control={control}
          value={value}
          onChange={onChange}
          error={error?.message}
          textField={textField}
          valueField={valueField}
          subType={subType}
        />
      )}
    />
  );
}
