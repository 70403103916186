import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuthAndUrlEncoding } from './api';
import {
  NotificationResponse,
  RecordNotification,
  RecordNotificationsResponse,
} from '../../@types/record-notifications';

export const notificationApi = createApi({
  reducerPath: 'notification',
  baseQuery: baseQueryWithReAuthAndUrlEncoding,
  tagTypes: ['RecordNotification'],
  endpoints: (builder) => ({
    getRecordNotifications: builder.query<Array<RecordNotification>, number>({
      query: (moduleId) => ({
        url: `/notification/module/${moduleId}/record-notifications/simple`,
        method: 'GET',
      }),
      transformResponse: (response: RecordNotificationsResponse) =>
        response?.result?.map((item) => {
          return { ...item, original: item };
        }) ?? [],
      providesTags: (result) =>
        result
          ? [
              ...result.map((item) => ({ type: 'RecordNotification' as const, id: item.id })),
              { type: 'RecordNotification' as const, id: 'LIST' },
            ]
          : [{ type: 'RecordNotification' as const, id: 'LIST' }],
    }),

    getNotificationById: builder.query<RecordNotification | undefined, unknown>({
      query: (id: string) => `/notification/record-notifications/simple/${id}`,
      transformResponse: (response: NotificationResponse) => {
        const result = response?.result;
        return result ? { ...result, original: result } : undefined;
      },
      providesTags: (response) =>
        response ? [{ type: 'RecordNotification' as const, id: response.id }] : ['RecordNotification'],
    }),
  }),
});

export const { useGetRecordNotificationsQuery, useLazyGetNotificationByIdQuery, useGetNotificationByIdQuery } =
  notificationApi;
