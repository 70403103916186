import { createApi } from '@reduxjs/toolkit/query/react';
import { isEmpty } from 'lodash';
import { GetFieldImageUrlResponse, GetFieldImageUrlProps } from '../../@types/common';
import {
  OptionsWrapper,
  OptionPostBody,
  OptionPostResponse,
  PersonOptionResponse,
  PersonOptionApiProps,
  FieldOptionsApiProps,
  AllOptionsWrapper,
  AccountablePersonResponse,
  BasicFieldOptionsApiProps,
  LinkedPersonOptionApiProps,
  LinkedFieldOptionsApiProps,
  FieldGroupsOptionsResponse,
  AuthorOptionsWrapper,
  AuthorColumnsApiProps,
  GetFieldWorkflowStepsResponse,
} from '../../@types/options';
import { TrainingDropDownValue, TrainingFieldApiProps, TrainingFieldResponse } from '../../@types/training-field';
import { baseQueryWithReAuthAndUrlEncoding } from './api';
import { createFieldFilterText } from '../../common/common-functions';

export const fieldApi = createApi({
  reducerPath: 'field',
  baseQuery: baseQueryWithReAuthAndUrlEncoding,
  tagTypes: ['FieldOptions', 'PersonFieldOptions'],
  endpoints: (builder) => ({
    fieldOptions: builder.query<OptionsWrapper, FieldOptionsApiProps>({
      query: (args) => resolveFieldOptionsUrl(args),
      providesTags: (result, error, { queryId, filters }) => {
        if (result) {
          const caption = filters?.caption?.value;
          const cacheId = !isEmpty(caption) ? `${queryId}-${caption}-LIST` : `${queryId}-LIST`;
          return result.items
            ? [
                ...result.items.map((item) => ({ type: 'FieldOptions' as const, id: item.id })),
                { type: 'FieldOptions' as const, id: cacheId },
              ]
            : [{ type: 'FieldOptions' as const, id: cacheId }];
        }

        return [];
      },
    }),
    addFieldOption: builder.mutation<OptionPostResponse, OptionPostBody>({
      query: ({ fieldId, caption }) => ({
        url: `/fields/${fieldId}/options/simple`,
        method: 'POST',
        body: { caption },
      }),
      invalidatesTags: (result, error, { fieldId, caption }) => [
        { type: 'FieldOptions', id: `${fieldId}-${caption}-LIST` },
        { type: 'FieldOptions', id: `${fieldId}-LIST` },
      ],
    }),
    personFieldOptions: builder.query<PersonOptionResponse, PersonOptionApiProps | LinkedPersonOptionApiProps>({
      query: (args) => resolvePersonFieldOptionsUrl(args),
      transformResponse: (response: PersonOptionResponse) => {
        return {
          ...response,
          items: response.items ?? [],
        };
      },
    }),
    fieldGroupsOptions: builder.query<FieldGroupsOptionsResponse, number>({
      query: (id) => `fields/${id}/groups`,
      transformResponse: (response: FieldGroupsOptionsResponse) => {
        return {
          ...response,
          items: response.items ?? [],
        };
      },
    }),
    fieldWorkflowSteps: builder.query<Array<string>, number>({
      query: (fieldId) => `/fields/${fieldId}/workflow-steps?filter=archived:eq:false`,
      transformResponse: (response: GetFieldWorkflowStepsResponse) => response.result,
    }),
    accountablePersonFieldOptions: builder.query<AccountablePersonResponse, BasicFieldOptionsApiProps>({
      query: ({ queryId, page, pageSize, filters }) => {
        return `/accountable-persons?formId=${queryId}&page=${page}&pageSize=${pageSize}${createFieldFilterText({ filters })}`;
      },
    }),
    fieldAccountablePersonOptions: builder.query<AccountablePersonResponse, BasicFieldOptionsApiProps>({
      query: ({ queryId: fieldId, page, pageSize, filters }) =>
        `/accountable-persons?fieldId=${fieldId}&page=${page}&pageSize=${pageSize}${createFieldFilterText({ filters })}`,
    }),
    authorOptions: builder.query<AuthorOptionsWrapper, AuthorColumnsApiProps>({
      query: ({ page, pageSize, formId, linkedFieldId, filters }) =>
        `/authors?page=${page}&pageSize=${pageSize}${formId ? `&formId=${formId}` : ''}${linkedFieldId ? `&fieldId=${linkedFieldId}` : ''}${createFieldFilterText(
          {
            filters,
          }
        )}`,
    }),
    allFormsPersonFieldOptions: builder.query<PersonOptionResponse, BasicFieldOptionsApiProps>({
      query: ({ queryId, page, pageSize, filters }) => {
        return `/modules/all-forms/columns/${queryId}/persons?page=${page}&pageSize=${pageSize}${createFieldFilterText({
          filters,
        })}`;
      },
      transformResponse: (response: PersonOptionResponse) => {
        return {
          ...response,
          items: response.items ?? [],
        };
      },
    }),
    allFormsFieldOptions: builder.query<AllOptionsWrapper, BasicFieldOptionsApiProps>({
      query: ({ queryId, page, pageSize, filters }) => {
        return `/modules/all-forms/columns/${queryId}/options/simple?page=${page}&pageSize=${pageSize}${createFieldFilterText(
          {
            filters,
          }
        )}`;
      },
      transformResponse: (response: AllOptionsWrapper) => {
        return {
          ...response,
          items: response.items
            ? response.items.map((item) => {
                return { ...item, id: item.optionIds };
              })
            : [],
        };
      },
    }),
    imageFieldUrl: builder.query<string, GetFieldImageUrlProps>({
      query: (args: { fieldId: number; imageId: number }) => `/fields/${args.fieldId}/images/${args.imageId}/url`,
      transformResponse: (response: GetFieldImageUrlResponse) => response.result,
    }),
    trainingFieldOptions: builder.query<Array<TrainingDropDownValue>, TrainingFieldApiProps>({
      query: ({ page, pageSize, filters }) => {
        return `/competencies?page=${page}&pageSize=${pageSize}&sort=name&order_by=ASC${createFieldFilterText({
          filters,
        })}`;
      },
      transformResponse: (response: TrainingFieldResponse) => response?.items || [],
    }),
  }),
});

export const {
  useLazyFieldOptionsQuery,
  useLazyPersonFieldOptionsQuery,
  useLazyFieldGroupsOptionsQuery,
  useImageFieldUrlQuery,
  useLazyTrainingFieldOptionsQuery,
  useAddFieldOptionMutation,
  usePersonFieldOptionsQuery,
} = fieldApi;

// Utility methods
const resolveFieldOptionsUrl = (args: FieldOptionsApiProps | LinkedFieldOptionsApiProps) => {
  const { queryId, formId, dependsOn, filters, next, limit, keyValue, hierarchyFieldId, formRecordsOnly } = args;
  let fieldOptionsUrl = `/fields/${queryId}`;

  if ('linkedFieldId' in args && args.linkedFieldId) {
    fieldOptionsUrl += `/linked-fields/${args.linkedFieldId}`;
  }

  fieldOptionsUrl += `/options/simple?formId=${formId}&limit=${limit}${createFieldFilterText({ filters })}`;

  if (dependsOn) {
    fieldOptionsUrl += `&dependsOn=${dependsOn}`;
  }
  if (next) {
    fieldOptionsUrl += `&next=${next}`;
  }
  if (keyValue) {
    fieldOptionsUrl += `&keyValue=${keyValue}`;
  }
  if (hierarchyFieldId) {
    fieldOptionsUrl += `&hierarchyFieldId=${hierarchyFieldId}`;
  }
  if (formRecordsOnly) {
    fieldOptionsUrl += '&formRecordsOnly=true';
  }
  return fieldOptionsUrl;
};

const resolvePersonFieldOptionsUrl = (args: PersonOptionApiProps | LinkedPersonOptionApiProps) => {
  let personsUrl = `/fields/${args.queryId}`;

  if ('linkedFieldId' in args) {
    personsUrl += `/linked-fields/${args.linkedFieldId}`;
  }

  personsUrl += `/persons?limit=${args.limit}${createFieldFilterText({ filters: args.filters })}`;

  if (!isEmpty(args.next)) {
    personsUrl += `&next=${args.next}`;
  }
  if (args.formRecordsOnly) {
    personsUrl += '&formRecordsOnly=true';
  }
  if (args.keyValue) {
    personsUrl += `&keyValue=${args.keyValue}`;
  }
  if (args.hierarchyFieldId) {
    personsUrl += `&hierarchyFieldId=${args.hierarchyFieldId}`;
  }
  if (args.globalHierarchyFilters) {
    personsUrl += args.globalHierarchyFilters;
  }
  return personsUrl;
};
