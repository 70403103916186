import React, { forwardRef, Ref, useEffect, useRef, useState, useImperativeHandle, Key } from 'react';
import {
  CheckboxChangeEventType,
  DataGridRef,
  DynamicFormButtonSetting,
  DynamicFormFieldType,
  DynamicForm,
  DynamicFormRef,
  JsonDataItem,
  JsonDataWrapper,
  ModalDialogRef,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  ModalDialog,
} from '@myosh/odin-components';
import { ActiveRecordContextProps } from '../../../active-record/active-record.component';
import { cloneDeep, debounce } from 'lodash';
import { BehaviorSubject, Subject, skip, take, takeUntil } from 'rxjs';
import { v4 } from 'uuid';
import { useHierarchyTypesQuery } from '../../../../redux/services/hierarchy';
import {
  competencyApi,
  useGetCompetenciesQuery,
  useUpdateCompetencyMutation,
} from '../../../../redux/services/competency';
import { FormLoading } from '../../../form/form-loading.component';
import { FormTitle } from '../../../form/form-title.component';
import useProfileData from '../../../../hooks/use-profile-data';
import { getDataGridReference } from '../../../../services/data-grid.service';
import { processFieldFilters, dateFormatBasedOnFilter, forceAssert } from '../../../../common/common-functions';
import { getCompetencyFormSettings } from './competency-form-settings';
import { createCompetencyData, getCompetencyFieldOptionsData } from '../../../../common/training-management-functions';
import { useAppDispatch } from '../../../../redux/hooks';
import { CompetencyMutationResponse, CompetencyPatch, CompetencyStructure } from '../../../../@types/competencies';
import useTrainingManagementEditAccess from '../../../../hooks/use-training-management-edit-access';
import usePerformingSaveToast from '../../../../hooks/use-performing-save-toast';
import { transformCompetencyData } from './competency-functions';
import { promiseToast, showError, showSuccess } from '../../../../services/notification.service';
import { saveModalButtons } from '../../../../common/common-administration-utils';
import useDynamicFormNotifier from '../../../../hooks/use-dynamic-form-notifier';
import CloseActiveItemButton from '../../../common/close-active-item-button';
import { useTranslation } from 'react-i18next';
import {
  archieveButton,
  getArchiveRestoreMessages,
  restoreButton,
  saveButton,
} from '../../../../pages/admin/admin-utils';
import { useApiLogger } from '../../../../hooks/use-api-logger';
import { Validation } from '../../../../@types/common';
interface ErrorType {
  data: {
    validation: Validation;
    environtment: string;
    status: number;
  };
}
interface CompetencyRef {
  submitForm?: () => void;
}

export interface CompetencyDetailsProps {
  competencyId: number;
  title?: string;
  editable?: boolean;
  onClose: (id: string) => void;
  panelSettings?: BehaviorSubject<ActiveRecordContextProps>;
  onCloseActiveRecordConfirmationModalRef?: ModalDialogRef | null;
}

const getFilters = (competencyId: number) => {
  const filterById = { id: { value: competencyId, comparison: '=' } };
  const filters = processFieldFilters(filterById, dateFormatBasedOnFilter);
  return filters;
};

function CompetencyDetails(props: CompetencyDetailsProps, ref: Ref<CompetencyRef>) {
  const { title, competencyId, onClose, panelSettings, onCloseActiveRecordConfirmationModalRef } = props;

  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const dynamicFormId = useRef(v4());
  const destroySubject = useRef(new Subject<void>());
  const dataGridRef = useRef<DataGridRef>();
  const saveModalReference = useRef<ModalDialogRef>(null);

  const [panelContextProps, setPanelContextProps] = useState<ActiveRecordContextProps>();
  const [formButtons, setFormButtons] = useState<Array<DynamicFormButtonSetting>>();
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [competency, setCompetency] = useState<CompetencyStructure>();
  const [ignoreHierarchyAccess, setIgnoreHierarchyAccess] = useState<boolean>();

  const dispatch = useAppDispatch();
  const log = useApiLogger();
  const { t } = useTranslation();

  const { data: hierarchyTypes } = useHierarchyTypesQuery({ archived: 'false' });
  const { data, isLoading, isFetching } = useGetCompetenciesQuery(
    {
      filters: getFilters(competencyId),
      page: 1,
      pageSize: 50,
    },
    { skip: competencyId === undefined }
  );

  const { notifyDirty, notifySaveSucceeded, notifySaveFailed } = useDynamicFormNotifier(dynamicFormId.current);

  const [updateCompetency] = useUpdateCompetencyMutation();

  const { profileData: { user: userData } = {} } = useProfileData();
  const editDisabled = useTrainingManagementEditAccess(userData);
  const { showPerformingSaveToast, hidePerformingSaveToast } = usePerformingSaveToast({
    shouldFreezeActiveRecord: true,
    disabled: panelContextProps?.batchSaveInProgress,
  });

  useEffect(() => {
    setReadOnly(editDisabled);
    if (competency?.original?.archived) {
      setReadOnly(true);
    }
  }, [editDisabled, competency?.original?.archived]);

  useEffect(() => {
    if (data && !isFetching) {
      const competencyData = cloneDeep(createCompetencyData(data[0]));
      setCompetency(competencyData);
      setIgnoreHierarchyAccess(competencyData?.original.ignoreHierarchyAccess);
      const newFormButtons: Array<DynamicFormButtonSetting> = [];
      if (competencyData?.original.archived) {
        newFormButtons.push({ ...restoreButton, onClick: () => onArchieveCompetency(false) });
      } else {
        newFormButtons.push(saveButton);
        newFormButtons.push({ ...archieveButton, onClick: () => onArchieveCompetency(true) });
      }
      if (editDisabled) {
        setFormButtons(newFormButtons.map((button) => ({ ...button, disabled: true })));
      } else {
        setFormButtons(newFormButtons);
      }
    }
  }, [data, isFetching, editDisabled]);

  useEffect(() => {
    panelSettings?.pipe(skip(1), takeUntil(destroySubject.current)).subscribe((settings) => {
      setPanelContextProps(settings);
    });

    onCloseActiveRecordConfirmationModalRef
      ?.subscribeToDialogResult()
      ?.pipe(takeUntil(destroySubject.current))
      .subscribe((result) => {
        if (result.buttonName === 'save' && dynamicFormReference.current?.formIsDirty()) {
          dynamicFormReference.current?.submitForm();
        } else if (result.buttonName === 'discard') {
          onClose(dynamicFormId.current);
        }
      });

    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((gridRef) => {
        if (gridRef) {
          dataGridRef.current = gridRef;
        }
      });

    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
    };
  }, []);

  const dataRetrieval: OdinDataRetrieval = {
    getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
      if ('COMBOBOX' === options?.fieldType) {
        await getCompetencyFieldOptionsData(subscriber, options, dispatch);
      } else {
        subscriber.sendData();
      }
    },
  };

  const saveCompetencyData = (competencyData: CompetencyPatch, successCallback: (response: unknown) => void) => {
    showPerformingSaveToast();
    if (competencyData.id) {
      updateCompetency(competencyData)
        .unwrap()
        .then(successCallback)
        .catch((e) => showErrorToast(e));
    }
  };

  const onSaveCallback = (response: unknown) => {
    const convertedResponse = forceAssert<CompetencyMutationResponse>(response);
    if (convertedResponse?.result) {
      dataGridRef.current?.api.data.recordChanged(competencyId);
      hidePerformingSaveToast();
      showSaveToast();
    } else if (convertedResponse?.validation?.errors || convertedResponse.validation?.responseErrors) {
      hidePerformingSaveToast();
      if (convertedResponse.validation?.responseErrors?.[0]?.message) {
        showError(convertedResponse.validation.responseErrors[0].message);
      } else {
        showError(t('competency-details-save-failed'));
      }
      log('Failed to update competency', {
        error: convertedResponse?.validation?.errors || convertedResponse.validation?.responseErrors,
      });
    }
  };

  const onFormSubmit = (data: JsonDataItem) => {
    if (competencyId && competency?.original && !readOnly) {
      const competencyData = transformCompetencyData(forceAssert(data));
      saveCompetencyData(competencyData, onSaveCallback);
    }
  };

  const onArchieveCompetency = (archive: boolean) => {
    if (competencyId) {
      const data = dynamicFormReference.current?.getData() || {};
      const competencyData: CompetencyPatch = {
        ...transformCompetencyData(forceAssert(data)),
        archived: archive,
      };
      promiseToast(
        updateCompetency(competencyData)
          .unwrap()
          .then(() => {
            onClose(dynamicFormId.current);
            dataGridRef.current?.api.data.getDataOverwritePageCache();
            dispatch(competencyApi.util.invalidateTags([{ type: 'Competency', id: 'LIST' }]));
          })
          .catch(() => showErrorToast()),
        getArchiveRestoreMessages(archive)
      );
    }
  };
  const onCloseCompetencyGroupDetails = () => {
    if (!dynamicFormReference.current?.formIsDirty()) {
      onClose(dynamicFormId.current);
    } else {
      saveModalReference.current
        ?.show()
        ?.pipe(take(1))
        .subscribe((result) => {
          if ('save' === result.buttonName) {
            const data = dynamicFormReference.current?.getData() || {};
            if (competencyId && competency?.original) {
              const competencyData = transformCompetencyData(forceAssert(data));
              saveCompetencyData(competencyData, onSaveCallback);
            }
          }
        });
    }
  };

  const onFieldChanged = debounce(
    (fieldId: Key, fieldName: string, fieldType: DynamicFormFieldType, fieldValue: unknown) => {
      if (fieldId === 'hierarchies') {
        const ignoreHierarchyValue = (fieldValue as Record<string, CheckboxChangeEventType>)['ignoreHierarchyAccess']
          ?.checked;

        if ((fieldValue as Record<string, CheckboxChangeEventType>)['ignoreHierarchyAccess']) {
          setIgnoreHierarchyAccess(ignoreHierarchyValue);
        }
      }

      notifyDirty(dynamicFormReference.current);
    },
    500
  );

  const showErrorToast = (error?: ErrorType) => {
    if (error?.data?.validation?.responseErrors?.[0]?.message) {
      showError(error?.data?.validation?.responseErrors?.[0]?.message);
    } else {
      showError(t('competency-details-save-failed'));
    }
    if (!panelContextProps?.batchSaveInProgress) {
      showError(t('competency-group-details-save-failed'));
    }
    hidePerformingSaveToast();
    notifySaveFailed(dynamicFormId.current);
  };

  const showSaveToast = () => {
    if (!panelContextProps?.batchSaveInProgress) {
      showSuccess(t('record-saved'));
    }
    notifySaveSucceeded(dynamicFormId.current);

    onClose(dynamicFormId.current);
  };

  useImperativeHandle(ref, () => ({
    submitForm: dynamicFormReference.current?.submitForm,
  }));

  const onlineLearning = Boolean(competency?.onlineLearning);
  const competencySettings =
    competency && competencyId && hierarchyTypes
      ? getCompetencyFormSettings(hierarchyTypes, onlineLearning, ignoreHierarchyAccess, readOnly)
      : null;

  return (
    <>
      <div className="flex flex-shrink-0 flex-col">
        <div className="flex">
          <FormTitle title={`Competency for occupation - ${title}`} />
          <div className="flex min-w-min justify-end">
            <CloseActiveItemButton onClick={onCloseCompetencyGroupDetails} />
          </div>
        </div>
      </div>
      <div className="admin-form-default">
        {isLoading && <FormLoading />}
        {competencySettings && (
          <DynamicForm
            ref={dynamicFormReference}
            dynamicFormId={dynamicFormId.current}
            data={competency ?? {}}
            settings={competencySettings}
            dataRetrieval={dataRetrieval}
            readOnly={readOnly}
            buttons={formButtons}
            buttonsLocation={0}
            buttonsPosition={1}
            onSubmit={onFormSubmit}
            onFieldChanged={onFieldChanged}
          />
        )}
      </div>
      <ModalDialog ref={saveModalReference} header={t('save-unsaved-changes')} buttons={saveModalButtons}>
        <div>{t('save-message')}</div>
      </ModalDialog>
    </>
  );
}

export default forwardRef(CompetencyDetails);
