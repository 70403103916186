import { JsonData, JsonDataWrapper, OdinDataRetrievalOptions, OdinDataSender } from '@myosh/odin-components';
import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import { OptionsItem, PersonOption } from '../../@types/options';
import { fieldApi } from '../../redux/services/field';
import { forceAssert } from '../../common/common-functions';

export const getRecordFieldOptions = async (
  formId: number,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch,
  subscriber: OdinDataSender<JsonDataWrapper>,
  options?: OdinDataRetrievalOptions,
  globalHierarchyFilters?: string
) => {
  if (options && ['COMBOBOX', 'LIST_BOX'].includes(options.fieldType || '')) {
    if (
      options.fieldSubType === 'TWINCOLUMNSELECTPERSONFIELD' &&
      (options.page === 1 || options.customProperties?.next !== undefined)
    ) {
      const personDispatch = dispatch(
        fieldApi.endpoints.personFieldOptions.initiate({
          queryId: Number(options.fieldId),
          limit: options.pageSize || 50,
          filters: options.fieldFilters,
          next: options.customProperties?.next as string,
          globalHierarchyFilters,
        })
      );
      const personResult = await personDispatch;
      if ('fulfilled' === personResult.status) {
        const comboboxOptions =
          personResult.data?.items?.map((item: PersonOption) => {
            return {
              id: item.id,
              caption: item.value,
            };
          }) || [];
        subscriber.sendData({
          data: comboboxOptions,
          requestId: options.requestId,
          customProperties: { next: personResult.data.next },
        });
      } else if ('rejected' === personResult.status) {
        subscriber.sendData();
      }

      // remove RTK subscription
      personDispatch.unsubscribe();
    } else if (options.page === 1 || options.customProperties?.next !== undefined) {
      const dispatchResult = dispatch(
        fieldApi.endpoints.fieldOptions.initiate({
          queryId: Number(options.fieldId),
          formId,
          filters: options.fieldFilters,
          dependsOn: options.fieldFilters?.parentField?.value as number,
          next: options.customProperties?.next as string,
          limit: options.pageSize || 50,
        })
      );
      const result = await dispatchResult;
      if ('fulfilled' === result.status) {
        const comboboxOptions =
          result.data?.items?.map((item: OptionsItem) => {
            return {
              id: item.id,
              caption: item.caption,
            };
          }) || [];

        subscriber.sendData({
          data: comboboxOptions,
          requestId: options.requestId,
          customProperties: { next: result.data.next },
        });
      } else if ('rejected' === result.status) {
        subscriber.sendData();
      }

      // remove RTK subscription
      dispatchResult.unsubscribe();
    } else {
      subscriber.sendData();
    }
  } else if (
    'TWIN_COLUMN_SELECT' === options?.fieldType &&
    ['MULTISELECTFIELD', 'TWINCOLUMNSELECT'].includes(options?.fieldSubType ?? '') &&
    options?.getAllData
  ) {
    const dispatchResult = dispatch(
      fieldApi.endpoints.fieldOptions.initiate({
        queryId: Number(options.fieldId),
        formId,
        next: options.customProperties?.next as string,
        limit: -1,
      })
    );
    const result = await dispatchResult;
    if ('fulfilled' === result.status) {
      const twinColAllOptions: Array<OptionsItem> =
        result.data?.items?.map((item: OptionsItem) => {
          return {
            id: item.id,
            caption: item.caption,
          };
        }) || [];

      subscriber.sendData({ data: forceAssert<JsonData>(twinColAllOptions), requestId: options.requestId });
    } else if ('rejected' === result.status) {
      subscriber.sendData();
    }

    // remove RTK subscription
    dispatchResult.unsubscribe();
  } else if (
    'TWIN_COLUMN_SELECT' === options?.fieldType &&
    'TWINCOLUMNSELECTPERSONFIELD' === options?.fieldSubType &&
    options?.getAllData
  ) {
    const personDispatch = dispatch(
      fieldApi.endpoints.personFieldOptions.initiate({
        queryId: Number(options.fieldId),
        limit: -1,
        filters: options.fieldFilters,
        next: options.customProperties?.next as string,
        globalHierarchyFilters,
      })
    );
    const personResult = await personDispatch;
    if ('fulfilled' === personResult.status) {
      const comboboxOptions =
        personResult.data?.items?.map((item: PersonOption) => {
          return {
            id: item.id,
            caption: item.value,
          };
        }) || [];
      subscriber.sendData({
        data: comboboxOptions,
        requestId: options.requestId,
        customProperties: { next: personResult.data.next },
      });
    } else if ('rejected' === personResult.status) {
      subscriber.sendData();
    }

    // remove RTK subscription
    personDispatch.unsubscribe();
  } else {
    subscriber.sendData();
  }
};
