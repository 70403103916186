import React, { useEffect, useRef, useState } from 'react';
import useAsyncExportQueue from '../../hooks/use-async-export-queue';
import { IconButton, OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';
import { JobStatus, removeExportJob, selectSubmittedJobs, SubmitJobDetails } from '../../redux/slices/export-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { orderBy } from 'lodash';

function ExportProcessPanel() {
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [hasViewedJobs, setHasViewedJobs] = useState(false);

  const exportPanelButtonRef = useRef<HTMLButtonElement>(null);

  const submittedJobs = useAppSelector(selectSubmittedJobs);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useAsyncExportQueue();

  useEffect(() => {
    if (isPanelVisible && submittedJobs.every((job) => job.status !== JobStatus.InProgress)) {
      setHasViewedJobs(true);
    } else if (submittedJobs.some((job) => job.status === JobStatus.InProgress)) {
      setHasViewedJobs(false);
    }

    if (submittedJobs.length === 0 && isPanelVisible) {
      setIsPanelVisible(false);
    }
  }, [isPanelVisible, submittedJobs]);

  const onFileClick = (job: SubmitJobDetails) => {
    if (job && job.fileDownloadUrl && job.fileName && job.status === JobStatus.Success) {
      const a = document.createElement('a');
      a.href = job.fileDownloadUrl;
      a.download = job.fileName;
      a.click();
    }
  };

  const onJobRemove = (id: string | undefined) => {
    if (id) {
      dispatch(removeExportJob(id));
    }
  };

  const onExportPanelButtonClick = () => setIsPanelVisible(!isPanelVisible);
  const onCloseButtonClick = () => setIsPanelVisible(false);

  const getPanelButtonStatusIcon = () => {
    const statusIconClasses = 'absolute -top-2 -right-2';

    const sortedJobs = orderBy(submittedJobs, (job) => new Date(job.completedDate ?? ''), 'desc');
    const latestJobStatus = sortedJobs[0].status;

    if (submittedJobs.some((job) => job.status === JobStatus.InProgress)) {
      return <OdinIcon icon="Loader" size={OdinIconSize.ExtraSmall} className={statusIconClasses} spin />;
    } else if (hasViewedJobs) {
      return null;
    } else if (latestJobStatus === JobStatus.Failed) {
      return (
        <OdinIcon icon="ErrorWarning" size={OdinIconSize.ExtraSmall} className={`${statusIconClasses} text-error`} />
      );
    } else if (latestJobStatus === JobStatus.Success) {
      return (
        <OdinIcon
          icon="CheckboxCircle"
          size={OdinIconSize.ExtraSmall}
          className={`${statusIconClasses} text-success`}
        />
      );
    } else {
      return null;
    }
  };

  const getFileIcon = (format: string) => {
    if (format === 'PDF') {
      return <OdinIcon icon="FilePdf" type={OdinIconType.Line} />;
    } else if (format === 'EXCEL') {
      return <OdinIcon icon="FileExcel" type={OdinIconType.Line} />;
    } else {
      return <OdinIcon icon="FileText" type={OdinIconType.Line} />;
    }
  };

  const getStatusIcon = (status?: JobStatus) => {
    if (status) {
      if (status === JobStatus.InProgress) {
        return <OdinIcon icon="Loader" size={OdinIconSize.ExtraSmall} spin />;
      } else if (status === JobStatus.Success) {
        return <OdinIcon icon="CheckboxCircle" size={OdinIconSize.ExtraSmall} className="text-success" />;
      } else if (status === JobStatus.Failed) {
        return <OdinIcon icon="ErrorWarning" size={OdinIconSize.ExtraSmall} className="text-error" />;
      }
    }
  };

  return (
    <>
      {submittedJobs.length > 0 && (
        <div className="relative h-6 w-6">
          <button ref={exportPanelButtonRef} onClick={onExportPanelButtonClick} className="relative">
            <OdinIcon icon="FolderDownload" type={OdinIconType.Line} />
            {getPanelButtonStatusIcon()}
          </button>
          {isPanelVisible && (
            <div className="absolute top-10 right-0 z-30 shadow-lg rounded-lg bg-gray-5 min-w-[14rem] max-w-[25rem]">
              <div className="flex items-center justify-between p-4">
                <span className="whitespace-nowrap">{t('file-downloads')}</span>
                <IconButton onClick={onCloseButtonClick}>
                  <OdinIcon icon="Close" />
                </IconButton>
              </div>
              <div className="bg-mono-1 rounded-b-lg p-4 space-y-2">
                {submittedJobs.map((job) => {
                  const fileItemStyles = classNames('flex gap-2 items-center min-w-0 w-full', {
                    'hover:cursor-pointer': job.status === JobStatus.Success,
                  });

                  const removeIconStyles = classNames('w-4 h-4 rounded-full hover:bg-primary-5 hover:cursor-pointer', {
                    'pointer-events-none': job.status === JobStatus.InProgress,
                  });

                  return (
                    <div key={job.id} className="flex items-center p-1 [&:has(div:hover)]:bg-primary-5">
                      <div className={fileItemStyles} onClick={() => onFileClick(job)}>
                        <span>{getFileIcon(job.format)}</span>
                        <span className="flex-1 truncate" title={job.fileName}>
                          {job.fileName}
                        </span>
                        <span>{getStatusIcon(job.status)}</span>
                      </div>
                      <span className={removeIconStyles} onClick={() => onJobRemove(job.id)}>
                        {job.status !== JobStatus.InProgress && (
                          <OdinIcon icon="Close" size={OdinIconSize.ExtraSmall} />
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ExportProcessPanel;
