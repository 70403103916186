import React from 'react';
import { Signal, useSignal, useSignalEffect } from '@preact/signals-react';
import { debounce } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import ExtendedMultiSelect, { ExtendedMultiSelectProps } from './extended-multi-select-drop-down.component';
import { DynamicFormParentFieldValue } from '@myosh/odin-components/dist/types/components/dynamic-form/dynamic-form.interfaces';
import { DropDownProps } from '@myosh/odin-components/dist/types/components/fields/drop-down/drop-down.component';
import { DropDownMultiSelectProps } from '@myosh/odin-components/dist/types/components/fields/multi-select-drop-down/drop-down-multi-select.component';
import {
  ContinuousScrollerComparisonType,
  ContinuousScrollerFilter,
  DropDown,
  DropDownMultiSelect,
  DropDownNoSelection,
  DropDownResult,
  SearchValueType,
  isDropDownResult,
} from '@myosh/odin-components';
import { TypeOrArray } from '@myosh/odin-components/dist/types/components/common-interfaces';
import DisabledDropdownWrapper from './disabled-dropdown-wrapper.component';
import { useTranslation } from 'react-i18next';

export enum DependentDropDownType {
  DropDown,
  MultiSelectDropDown,
  ExtendedMultiSelectDropDown,
}

interface DependentMainProps {
  parentFieldId: string;
  parentLabel: string;
  parentValue: Signal<Array<DynamicFormParentFieldValue>>;
  dropDownType: DependentDropDownType;
}

type DependentDropDownProps = DependentMainProps &
  (DropDownProps | DropDownMultiSelectProps | ExtendedMultiSelectProps);

const DependentDropDown = (props: DependentDropDownProps) => {
  const { parentFieldId, parentLabel, parentValue, shouldUseCreatePortal = false, ...dropDownProps } = props;
  const { t } = useTranslation();
  const [showOverlay, setShowOverlay] = useState(true);

  const currentParentFieldValue = useRef<unknown>();

  const filterSignal = useSignal<Array<ContinuousScrollerFilter>>([]);

  useSignalEffect(() => {
    let tempParentValue: DynamicFormParentFieldValue | undefined;
    if ((tempParentValue = parentValue.value.find((value) => value.parentFieldId === parentFieldId)) !== undefined) {
      const tempFieldValue = isDropDownResult(tempParentValue.fieldValue)
        ? tempParentValue.fieldValue.value
        : undefined;

      if (!currentParentFieldValue.current) {
        currentParentFieldValue.current = tempFieldValue;
      } else if (currentParentFieldValue.current !== tempFieldValue) {
        currentParentFieldValue.current = tempFieldValue;
        // I don't really like this, but since its specific to the drop-down-old I'll accept it for now
        fireOnChange({
          noSelectionValue: null,
        });
      }

      if (currentParentFieldValue.current !== undefined) {
        setShowOverlay(false);
      } else {
        setShowOverlay(true);
      }

      filterSignal.value = [
        {
          scrollerId: 'parentField',
          field: 'parentField',
          value: currentParentFieldValue.current as SearchValueType,
          comparison: 1 as ContinuousScrollerComparisonType,
        },
      ];
    }
  });

  const fireOnChange = useCallback(
    debounce((value?: TypeOrArray<DropDownResult> | DropDownNoSelection) => {
      dropDownProps.onChange?.(value);
    }, 20),
    []
  );

  const renderDropDown = (dropDownType: DependentDropDownType) => {
    switch (dropDownType) {
      case DependentDropDownType.DropDown:
        return (
          <DropDown
            {...(dropDownProps as DropDownProps)}
            shouldUseCreatePortal={shouldUseCreatePortal}
            filterSignal={filterSignal}
          />
        );
      case DependentDropDownType.MultiSelectDropDown:
        return (
          <DropDownMultiSelect
            {...(dropDownProps as DropDownProps)}
            shouldUseCreatePortal={shouldUseCreatePortal}
            filterSignal={filterSignal}
          />
        );
      case DependentDropDownType.ExtendedMultiSelectDropDown:
        return (
          <ExtendedMultiSelect
            {...(dropDownProps as ExtendedMultiSelectProps)}
            shouldUseCreatePortal={shouldUseCreatePortal}
            filterSignal={filterSignal}
          />
        );
    }
  };

  return (
    <DisabledDropdownWrapper
      visible={showOverlay}
      message={t('must-be-selected-from', { parent: parentLabel })}
      {...(dropDownProps as DropDownProps)}
    >
      {renderDropDown(dropDownProps.dropDownType)}
    </DisabledDropdownWrapper>
  );
};

export default DependentDropDown;
