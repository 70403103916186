import { OdinController } from '@myosh/odin-components';
import { Control } from 'react-hook-form';
import TwinColumnSelectField, { TwinColumnSelectFieldProps } from './twin-column-select-field.component';
import React from 'react';

export interface HfTwinColumnSelectProps extends TwinColumnSelectFieldProps {
  control: Control;
  name: string;
}

const HfTwinColumnSelect = ({
  control,
  name,
  value,
  onChange,
  fieldValueConverter,
  readOnly,
  ...rest
}: HfTwinColumnSelectProps) => {
  return (
    <OdinController
      name={name}
      defaultValue={value}
      control={control}
      onChange={onChange}
      fieldValueConverter={fieldValueConverter}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TwinColumnSelectField
          {...rest}
          control={control}
          value={value}
          onChange={onChange}
          error={error?.message}
          readOnly={readOnly}
        />
      )}
    />
  );
};

export default HfTwinColumnSelect;
