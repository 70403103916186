import React, { ReactNode } from 'react';
import OdinDropDown from './odin-drop-down.component';
import { DropDownProps } from '@myosh/odin-components/dist/types/components/fields/drop-down/drop-down.component';

interface DisabledFieldWrapperProps extends Partial<DropDownProps> {
  visible: boolean;
  message: string;
  children: ReactNode | Array<ReactNode>;
}

const DisabledDropdownWrapper = ({ visible, message, children, id, ...rest }: DisabledFieldWrapperProps) => {
  const infoItem = {
    id: 'dependent-field-message',
    caption: message,
  };

  return visible ? (
    <OdinDropDown
      allowSearch={false}
      label={rest.label}
      required={rest.required}
      showAddNewOption={false}
      id={`${id}-disabled`}
      textField="caption"
      valueField="id"
      data={[infoItem]}
      optionHoverStyle="hover:bg-info hover:text-mono-1"
      optionSelectedStyle="bg-info text-mono-1"
      optionListStyle="bg-info text-mono-1 pointer-events-none"
    />
  ) : (
    children
  );
};

export default DisabledDropdownWrapper;
