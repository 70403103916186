import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuthAndUrlEncoding, providesList } from './api';
import { createFieldFilterText, forceAssert } from '../../common/common-functions';
import {
  ScheduleMutationResponse,
  SchedulerRecord,
  SchedulerRecordApiProps,
  SchedulerRecordPatch,
  SchedulerRecordResponse,
} from '../../@types/scheduler-record';
import { cloneDeep } from 'lodash';
import { isSchedulerRuleField } from '../../components/admin/scheduler/scheduler-utils';

export const scheduledRecordApi = createApi({
  reducerPath: 'schedules',
  baseQuery: baseQueryWithReAuthAndUrlEncoding,
  tagTypes: ['Schedule'],
  endpoints: (builder) => ({
    getSchedules: builder.query<Array<SchedulerRecord>, SchedulerRecordApiProps>({
      query: (args) => {
        //FIXME: Once the API supports the new filter format change this code
        const filteredFields = { ...args.filters };
        let targetFieldFilter = '',
          targetStatusFilter = '';
        if (filteredFields.targetField) {
          targetFieldFilter = `&filter=targetField:caption:like:${filteredFields.targetField.value}`;
          delete filteredFields.targetField;
        }
        if (filteredFields.targetStatus) {
          targetStatusFilter = `&filter=targetStatus:caption:like:${filteredFields.targetStatus.value}`;
          delete filteredFields.targetStatus;
        }
        return `/schedules?page=${args.page}&pageSize=${args.pageSize}&includeTemplates=true${createFieldFilterText({
          filters: filteredFields,
        })}&filter=moduleId:eq:${args.moduleId}${targetFieldFilter}${targetStatusFilter}`;
      },
      transformResponse: (response: SchedulerRecordResponse) => response?.items?.map(enhanceSchedulerRecord) || [],
      providesTags: (result) => providesList(result, 'Schedule'),
    }),
    getScheduleById: builder.query<SchedulerRecord, number>({
      query: (id) => {
        return `/schedules/${id}`;
      },
      transformResponse: (response: SchedulerRecord) => enhanceSchedulerRecord(response),
      providesTags: (result) => (result ? [{ type: 'Schedule' as const, id: result.id as number }] : ['Schedule']),
    }),
    deleteScheduleRecord: builder.mutation<ScheduleMutationResponse, number>({
      query: (id) => ({
        url: `/schedules/${id}`,
        method: 'DELETE',
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),
      invalidatesTags: [{ type: 'Schedule', id: 'LIST' }],
    }),
    updateScheduleRecord: builder.mutation<void, Partial<SchedulerRecordPatch>>({
      query: ({ id, ...patch }) => ({
        url: `/schedules/${id}`,
        method: 'PATCH',
        body: patch,
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result) {
          return [{ type: 'Schedule', id: arg.id }];
        } else {
          return [];
        }
      },
    }),
    addScheduleRecord: builder.mutation<void, Partial<SchedulerRecordPatch>>({
      query: (arg) => ({
        url: `/schedules`,
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: [{ type: 'Schedule', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetSchedulesQuery,
  useGetScheduleByIdQuery,
  useLazyGetScheduleByIdQuery,
  useAddScheduleRecordMutation,
  useUpdateScheduleRecordMutation,
  useDeleteScheduleRecordMutation,
} = scheduledRecordApi;

const enhanceSchedulerRecord = (record: SchedulerRecord): SchedulerRecord => {
  const finalResult = cloneDeep(record);
  const userAssignment = record?.userAssignment;
  if (userAssignment) {
    if (userAssignment.targetField) {
      const targetFieldCaption = userAssignment.targetField.caption.translations[0].value;
      finalResult['targetField'] = { value: userAssignment.targetField.id, text: targetFieldCaption };
    }
    if (userAssignment.users) {
      finalResult['users'] = userAssignment.users.map((user) => {
        return { text: `${user.firstName} ${user.lastName}`, value: user.id };
      });
    }
    if (userAssignment.groups) {
      finalResult['groups'] = userAssignment.groups.map((group) => {
        return { text: `${group.caption.translations[0].value}`, value: group.id };
      });
    }
  }

  const targetStatus = record.targetWorkflowStepCaption;
  if (targetStatus) {
    finalResult['targetStatus'] = { text: `${targetStatus.translations[0].value}`, value: '4' };
  }

  const caption = record.caption?.translations?.[0].value;
  if (caption) {
    finalResult['caption'] = forceAssert(caption);
  }

  const rules = record.rules;
  if (rules) {
    const transformedRules = rules?.map((rule) => {
      if (isSchedulerRuleField(rule)) {
        return {
          ...rule,
          conditionField: { text: rule?.fieldCaption.translations[0].value, value: rule?.conditionFieldId },
        };
      } else {
        return rule;
      }
    });

    finalResult['rules'] = transformedRules;
  }

  return finalResult;
};
