import React from 'react';
import { IconButton, OdinIcon, OdinIconSize, Tooltip } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';

interface CloseButtonProps {
  onClick: () => void;
  disabled?: boolean;
  spin?: boolean;
  size?: OdinIconSize;
}

const CloseActiveItemButton = ({ onClick, disabled, spin, size }: CloseButtonProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip description={String(t('close'))} tooltipClassName="bg-mono-1" debounceTime={200}>
      <IconButton
        classNames="ml-1 h-9 flex items-center border-transparent hover:text-primary-2"
        onClick={onClick}
        disabled={disabled}
      >
        <OdinIcon size={size} icon={spin ? 'Loader' : 'Close'} className="w-7" spin={spin} />
      </IconButton>
    </Tooltip>
  );
};
export default CloseActiveItemButton;
