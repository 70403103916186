import { createApi } from '@reduxjs/toolkit/query/react';
import {
  createFieldFilterText,
  createFieldFilterTextAsFirstQueryParameter,
  createFieldSortText,
  createRecordData,
  createSummaryLinkedRecordsFilterText,
  exportRecordListQueryHandler,
  exportRecordURLConstruct,
  fileToSrc,
  forceAssert,
  transformAuditLogResponse,
} from '../../common/common-functions';
import {
  AssignedRecordsCount,
  AssignedRecordsCountResponse,
  AssignedRecordsResponse,
  AssignedRecordsResult,
  GetRecordByIdApiProps,
  GetRecordsApiProps,
  PostRecordApiProps,
  PatchRecordApiProps,
  RecordPermissionsResponse,
  RecordPermissionsResult,
  RecordResult,
  RecordStructure,
  RecordsWrapper,
  RecordsResult,
  ExportRecords,
  RecordFieldTemplateExport,
  RecordCollaborators,
  RecordDefaultValuesWrapper,
  RecordCollaboratorsWrapper,
  ExportAllFormsRecords,
  ExportRecordResponse,
  ExportRecord,
  RecordCountApiProps,
  CloneRecordsApiProps,
  AssignedRecordItem,
  MyActivitiesApiProps,
  MyActivitiesApiResponse,
  DeleteRecordResponse,
  LinkedRecordDefaultValuesApiProps,
} from '../../@types/records';
import {
  LinkedRecordResult,
  LinkedRecordsApiProps,
  LinkedRecordsWrapper,
  SaveLayoutApiProps,
} from '../../@types/linked-records-field';
import { SummaryRecordLinkResult, SummaryRecordLinkResponse } from '../../@types/summary-record-link-field';
import { baseQueryWithReAuthAndUrlEncoding, providesList } from './api';
import {
  AuditLogData,
  AuditLogDataItem,
  AuditLogDetailsRequestBody,
  AuditLogDetailsResponse,
  AuditLogsRequestBody,
  AuditLogsResponse,
  NotificationLogData,
  NotificationLogParameters,
  NotificationLogResponse,
} from '../../@types/audit';
import { FieldFilters, ResultValidationResponse } from '../../@types/common';
import {
  ExternalEmailInvitation,
  ExternalUserEmailTemplate,
} from '../../components/fields/invite-button-field/invite-modal.component';
import { EmailThisTemplate } from '../../components/fields/email-this-button/email-this-modal.component';
import { GetAllFormsApiProps } from '../../@types/forms';
import { CollaboratorsRequestBody } from '../../components/fields/invitation-field/invitation-field.component';
import {
  CloneRecordsDetailsResponse,
  CloneRecordsJobDetails,
  CloneRecordsJobResponse,
  CloneRecordsJobResult,
  CreateExportJobResult,
  CreateImportJobResponse,
  CreateImportJobResult,
  ExportJobDetailsResponse,
  ExportJobDetailsResult,
  ImportJobDetailsResponse,
  ImportJobDetailsResult,
  StartImportJobApiProps,
  StartImportJobResponse,
  StartImportJobResult,
} from '../../@types/background-jobs';

export const recordApi = createApi({
  reducerPath: 'record',
  baseQuery: baseQueryWithReAuthAndUrlEncoding,
  tagTypes: [
    'Record',
    'DefaultRecord',
    'LinkedRecord',
    'ReverseLinkedRecord',
    'SummaryLinkedRecord',
    'TotalRecordCount',
    'Collaborator',
    'AllFormsRecord',
    'AssignedRecords',
    'AssignedRecordsCount',
    'RecordVersion',
    'RecordPermissions',
  ],
  endpoints: (builder) => ({
    getRecordById: builder.query<RecordStructure, GetRecordByIdApiProps>({
      keepUnusedDataFor: 5,
      query: (args: { id: number; keyTypeId: boolean; fetchLinkedRecords?: boolean }) => ({
        url: `/records/simple/${args.id}`,
        params: {
          keyType: args.keyTypeId === true ? 'id' : undefined,
          fetchLinkedRecords: args.fetchLinkedRecords,
        },
      }),
      transformResponse: (response: RecordsWrapper) => createRecordData(response.result.results[0]),
      providesTags: (result, _error, { cache = true }) => {
        if (cache) {
          return result ? [{ type: 'Record' as const, id: result.original.id }] : ['Record'];
        }
        return [];
      },
    }),
    getDefaultRecordValues: builder.query<RecordStructure, GetRecordByIdApiProps>({
      query: ({ id, keyTypeId }) => `/records/default/simple/${id}${keyTypeId ? '?keyType=id' : ''}`,
      transformResponse: (response: RecordDefaultValuesWrapper) => createRecordData(response.result),
      providesTags: (result) =>
        result ? [{ type: 'DefaultRecord' as const, id: result.original.formId }] : ['DefaultRecord'],
    }),
    getRecordLinkFieldsDefaultValues: builder.query<RecordStructure, LinkedRecordDefaultValuesApiProps>({
      query: ({ recordId, fieldId }) => `records/${recordId}/fields/${fieldId}/default/simple?keyType=id`,
      transformResponse: (response: RecordDefaultValuesWrapper) => createRecordData(response.result),
      providesTags: (result) =>
        result ? [{ type: 'DefaultRecord' as const, id: result.original.formId }] : ['DefaultRecord'],
    }),
    getRecords: builder.query<Array<RecordStructure>, GetRecordsApiProps>({
      keepUnusedDataFor: 5,
      query: (args: {
        formId: number;
        keyTypeId: boolean;
        page?: number;
        pageSize?: number;
        filters?: FieldFilters;
        sortDirective?: string;
        globalHierarchyFilters?: string;
      }) =>
        `/forms/${args.formId}/records/simple?${args.keyTypeId ? 'keyType=id&' : ''}page=${args.page}&pageSize=${
          args.pageSize
        }${createFieldFilterText({ filters: args.filters })}${args.globalHierarchyFilters ?? ''}${args.sortDirective}`,
      transformResponse: (response: RecordsWrapper) => {
        return response.result.hits > 0
          ? response.result.results.filter((value) => value.fields).map((item) => createRecordData(item))
          : [];
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map((item) => ({ type: 'Record', id: item.original.id }) as const),
              { type: 'Record', id: 'LIST' },
            ]
          : [{ type: 'Record', id: 'LIST' }],
    }),
    getLinkedRecords: builder.query<LinkedRecordResult[], LinkedRecordsApiProps>({
      query: (args: LinkedRecordsApiProps) =>
        `records/${args.recordId}/fields/${args.fieldId}/linked-records?linkedRecordIds=${
          args.linkedRecordIds
        }${createFieldSortText(args.sorts)}`,
      transformResponse: (response: LinkedRecordsWrapper) => response.result ?? [],
      providesTags: (result, _error, { recordId }) =>
        result
          ? [
              ...result.map((item) => ({ type: 'LinkedRecord' as const, id: item.id })),
              { type: 'LinkedRecord', id: `LIST-${recordId}` } as const,
              'LinkedRecord',
            ]
          : [{ type: 'LinkedRecord', id: `LIST-${recordId}` } as const],
    }),
    getReverseLinkedRecords: builder.query<LinkedRecordResult[], LinkedRecordsApiProps>({
      query: (args: LinkedRecordsApiProps) =>
        `records/${args.recordId}/fields/${args.fieldId}/reverse-linked-records?linkedRecordIds=${
          args.linkedRecordIds
        }${createFieldSortText(args.sorts)}`,
      transformResponse: (response: LinkedRecordsWrapper) => response.result ?? [],
      providesTags: (result, _error, { recordId }) =>
        result
          ? [
              ...result.map((item) => ({ type: 'ReverseLinkedRecord' as const, id: item.id })),
              { type: 'ReverseLinkedRecord', id: `LIST-${recordId}` } as const,
              'ReverseLinkedRecord',
            ]
          : [{ type: 'ReverseLinkedRecord', id: `LIST-${recordId}` } as const],
    }),
    getSummaryRecordsLinked: builder.query<Array<SummaryRecordLinkResult>, LinkedRecordsApiProps>({
      query: ({ recordId, fieldId, sorts, linkedRecordIds }: LinkedRecordsApiProps) =>
        `records/${recordId}/fields/${fieldId}/summary-records${createSummaryLinkedRecordsFilterText(
          linkedRecordIds
        )}${createFieldSortText(sorts)}`,
      transformResponse: (response: SummaryRecordLinkResponse) => response.result,
      providesTags: (result, _error, { recordId }) =>
        result
          ? [
              ...result.map((item) => ({ type: 'SummaryLinkedRecord' as const, id: item.fieldId })),
              { type: 'SummaryLinkedRecord', id: `LIST-${recordId}` } as const,
              'SummaryLinkedRecord',
            ]
          : [{ type: 'SummaryLinkedRecord', id: `LIST-${recordId}` } as const],
    }),
    saveLinkedRecordsLayout: builder.mutation<boolean, SaveLayoutApiProps>({
      query: ({ linkedRecordsFieldId, recordId, linkedRecordIds }) => ({
        url: `records/${recordId}/fields/${linkedRecordsFieldId}/layout`,
        method: 'POST',
        body: linkedRecordIds,
      }),
      invalidatesTags: ['LinkedRecord'],
    }),
    getRecordFieldTemplateExport: builder.query<string, RecordFieldTemplateExport>({
      query: ({ recordId, fieldId, fieldType }) => {
        return {
          url: `/records/${recordId}/fields/${fieldId}/export?fieldType=${fieldType}`,
          responseHandler: (response: Response) => {
            if (response.status === 200) {
              return response.blob();
            }

            return response.json();
          },
        };
      },
      transformResponse: (response: Blob) => {
        return fileToSrc(response);
      },
    }),
    getAssignedRecords: builder.query<Array<AssignedRecordsResult>, unknown>({
      query: (args: { filters?: FieldFilters; page?: number; pageSize?: number }) => {
        let url = 'records/important/simple';
        if (args.page && args.pageSize) {
          url += `?page=${args.page}&pageSize=${args.pageSize}`;
          if (args.filters && Object.keys(args.filters).length > 0) {
            url += `${createFieldFilterText({ filters: args?.filters })}`;
          }
        }
        return url;
      },
      providesTags: ['AssignedRecords'],
      transformResponse: (response: AssignedRecordsResponse) => response.result.results || [],
    }),
    getMyActivities: builder.query<Array<AssignedRecordItem>, MyActivitiesApiProps>({
      query: ({ filters, page, pageSize, sortDirective }) =>
        `my-activities?page=${page}&pageSize=${pageSize}${createFieldFilterText({ filters })}${sortDirective ?? ''}`,
      providesTags: ['AssignedRecords'],
      transformResponse: (response: MyActivitiesApiResponse) => response.items || [],
    }),
    getAssignedRecordsCount: builder.query<Array<AssignedRecordsCount>, void>({
      query: () => `records/important/simple/count`,
      providesTags: ['AssignedRecordsCount'],
      transformResponse: (response: AssignedRecordsCountResponse) => response.result || [],
    }),
    getRecordPermissions: builder.query<RecordPermissionsResult, unknown>({
      query: (args: { recordId: number }) => `records/${args.recordId}/permissions`,
      transformResponse: (response: RecordPermissionsResponse) => response.result,
      providesTags: ['RecordPermissions'],
    }),
    updateRecord: builder.mutation<void, PatchRecordApiProps>({
      query: ({ id, patch, applyDefaultValues = false }) => ({
        url: `/records/simple/${id}?keyType=id&applyDefaultValues=${applyDefaultValues}`,
        method: 'PATCH',
        body: patch,
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),
      invalidatesTags: (_result, error, { id }) => {
        const isConflictError = error && error.status === 900; // 900 CONFLICT_DATA is a custom error code
        return isConflictError
          ? []
          : [
              { type: 'Record', id },
              { type: 'Record', id: 'LIST' },
              'Record',
              'LinkedRecord',
              'ReverseLinkedRecord',
              'SummaryLinkedRecord',
              'AssignedRecords',
              'AssignedRecordsCount',
              'RecordPermissions',
            ];
      },
    }),
    addRecord: builder.mutation<number, PostRecordApiProps>({
      query: ({ body, applyDefaultValues = false }) => ({
        url: `/records/simple?keyType=id&applyDefaultValues=${applyDefaultValues}`,
        method: 'POST',
        body: [body],
        validateStatus: (response, result) => response.status === 200 && result?.result?.[0]?.success === true,
      }),
      invalidatesTags: [{ type: 'Record', id: 'LIST' }, 'TotalRecordCount', 'AssignedRecordsCount'],
    }),
    deleteRecord: builder.mutation<DeleteRecordResponse, number>({
      query: (id: number) => ({
        url: `/records/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'Record', id: 'LIST' },
        'LinkedRecord',
        'ReverseLinkedRecord',
        'SummaryLinkedRecord',
        'TotalRecordCount',
        'AssignedRecordsCount',
      ],
    }),
    getAuditLogs: builder.query<Array<AuditLogData>, AuditLogsRequestBody>({
      query: ({ recordId, page, pageSize, sortByVersion }: AuditLogsRequestBody) => {
        let url = `records/${recordId}/audit-logs?page=${page}&pageSize=${pageSize}`;
        if (sortByVersion === 'desc') {
          url += '&sort=-version';
        }
        return url;
      },
      transformResponse: (response: AuditLogsResponse) => transformAuditLogResponse(response.items ?? []),
    }),
    getAuditLogDetails: builder.query<Array<AuditLogDataItem>, AuditLogDetailsRequestBody>({
      query: ({ recordId, auditLogVersion }: AuditLogDetailsRequestBody) =>
        `records/${recordId}/audit-logs/${auditLogVersion}`,
      transformResponse: (response: AuditLogDetailsResponse) => response.result ?? [],
    }),
    getNotificationLog: builder.query<Array<NotificationLogData>, NotificationLogParameters>({
      query: (args) => `records/${args.recordId}/notification-log`,
      transformResponse: (response: NotificationLogResponse) => response.result || [],
    }),
    getRecordCount: builder.query<number, RecordCountApiProps>({
      query: (args) =>
        `/forms/${args.formId}/records/count${createFieldFilterTextAsFirstQueryParameter(args.filters, true)}${
          args.globalHierarchyFilters ?? ''
        }`,
      transformResponse: (response: ResultValidationResponse<number>) => response.result,
      providesTags: ['TotalRecordCount'],
    }),
    getRecordVersions: builder.query<Array<RecordResult>, { recordId: number }>({
      query: (args) => `/records/${args.recordId}/versions`,
      transformResponse: (response: ResultValidationResponse<RecordsResult>) => response.result.results,
      providesTags: (result) =>
        result
          ? [
              ...result.map((item) => ({ type: 'RecordVersion' as const, id: item.id })),
              { type: 'RecordVersion', id: 'LIST' } as const,
            ]
          : [{ type: 'RecordVersion', id: 'LIST' } as const],
    }),
    exportAllFormsRecords: builder.query<string, ExportAllFormsRecords>({
      query: (args) => {
        const url = `/modules/${args.moduleId}/records/export?format=${args.format}`;
        return exportRecordListQueryHandler(url, args);
      },
      transformResponse: (response: Blob) => fileToSrc(response),
    }),
    exportRecord: builder.query<ExportRecord, { recordId: string; format: string }>({
      query: (args) => {
        const url = `/records/${args.recordId}/export?format=${args.format}`;
        return {
          url: url,
          responseHandler: async (response: Response) => {
            if (response.status === 200) {
              const contentDisposition = response.headers.get('Content-Disposition');
              if (contentDisposition) {
                const file = await response.blob();
                if (contentDisposition.includes('inline')) {
                  return { action: 'new-tab', file };
                } else if (contentDisposition.includes('attachment')) {
                  return { action: 'download', file };
                }
              }
            } else {
              response.json();
            }
          },
        };
      },
      transformResponse: (response: ExportRecordResponse) => {
        return { action: response.action, fileSource: fileToSrc(response.file) };
      },
    }),
    getExternalUserEmailTemplate: builder.query<ExternalUserEmailTemplate, unknown>({
      query: () => `/email-template/external-user/simple`,
      transformResponse: (response: ResultValidationResponse<Array<ExternalUserEmailTemplate>>) => {
        return response.result[0];
      },
    }),
    sendInvitationEmail: builder.mutation<boolean, ExternalEmailInvitation>({
      query: (args) => ({
        url: `/records/${args.recordId}/fields/${args.template.id}/invite`,
        method: 'POST',
        body: args.template,
      }),
      invalidatesTags: [{ type: 'Collaborator', id: 'LIST' }],
      transformResponse: (response: ResultValidationResponse<boolean>) => response.result,
    }),
    sendEmailThis: builder.mutation<boolean, EmailThisTemplate>({
      query: (args) => ({
        url: `/records/${args.recordId}/fields/${args.fieldId}/email`,
        method: 'POST',
        body: args.emailData,
      }),
      invalidatesTags: [{ type: 'Collaborator', id: 'LIST' }],
      transformResponse: (response: ResultValidationResponse<boolean>) => response.result,
    }),
    getRecordCollaborators: builder.query<Array<RecordCollaborators>, CollaboratorsRequestBody>({
      query: ({ recordId }) => ({
        url: `/records/${recordId}/collaborators`,
        method: 'GET',
      }),
      transformResponse: (response: RecordCollaboratorsWrapper) => {
        return (
          response.result?.map((collaborator) => {
            return {
              ...collaborator,
              email: collaborator.email === 'null' || collaborator.email === null ? '' : collaborator.email,
            };
          }) ?? []
        );
      },
      providesTags: (result) => providesList(result, 'Collaborator'),
    }),
    getCollaboratorEmail: builder.query<ExternalUserEmailTemplate, number | undefined>({
      query: (emailId) => `/records/collaborator-emails/${emailId}`,
      transformResponse: (response: ResultValidationResponse<ExternalUserEmailTemplate>) => {
        return response.result;
      },
    }),
    editRecordCollaborator: builder.mutation<number, ExternalUserEmailTemplate>({
      query: ({ id, ...patch }) => ({
        url: `/collaborators/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: [{ type: 'Collaborator', id: 'LIST' }],
    }),
    deleteRecordCollaborator: builder.mutation<number, number>({
      query: (id) => ({
        url: `/collaborators/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Collaborator', id: 'LIST' }],
    }),
    getAllFormsRecords: builder.query<RecordsResult, GetAllFormsApiProps>({
      query: (args) =>
        `/modules/${args.moduleId}/all-forms/records/simple?keyType=id&page=${args.page}&pageSize=${args.pageSize}${
          args.globalHierarchyFilters ?? ''
        }${createFieldFilterText({
          filters: args.filters,
          captionValuesBase64encoded: args.captionValuesBase64encoded,
          isAllForms: true,
        })}${args.sorts}&includeTotal=true`,
      transformResponse: (response: RecordsWrapper) => {
        return {
          ...response.result,
          results:
            response.result?.results?.map((item) => forceAssert<RecordResult>(createRecordData(item).flat)) ?? [],
        };
      },
      providesTags: (result) =>
        result && result.results
          ? [
              ...result.results.map((item) => ({ type: 'AllFormsRecord', id: item.id }) as const),
              { type: 'AllFormsRecord', id: 'LIST' },
            ]
          : [{ type: 'AllFormsRecord', id: 'LIST' }],
    }),
    deleteLatestVersion: builder.mutation<ResultValidationResponse<number>, number>({
      query: (id: number) => ({
        url: `/records/${id}/latest-version`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'Record', id: 'LIST' },
        { type: 'RecordVersion', id: 'LIST' },
      ],
    }),
    createNewVersion: builder.mutation<ResultValidationResponse<number>, number>({
      query: (id: number) => ({
        url: `/records/${id}/new-version`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: [
        { type: 'Record', id: 'LIST' },
        { type: 'RecordVersion', id: 'LIST' },
      ],
    }),
    createExportJob: builder.mutation<CreateExportJobResult, ExportRecords>({
      query: (args) => ({
        url: exportRecordURLConstruct(`/forms/${args.formId}/records/export?format=${args.format}`, args),
        method: 'POST',
      }),
    }),
    createAllFormsExportJob: builder.mutation<CreateExportJobResult, ExportAllFormsRecords>({
      query: (args) => ({
        url: exportRecordURLConstruct(`/modules/${args.moduleId}/records/export?format=${args.format}`, args),
        method: 'POST',
      }),
    }),
    getExportJobDetails: builder.query<ExportJobDetailsResult, number>({
      query: (id) => `/background-jobs/export-records/${id}`,
      transformResponse: (response: ExportJobDetailsResponse) => response.result,
    }),
    createImportJob: builder.mutation<CreateImportJobResult, number>({
      query: (formId) => ({
        url: `/background-jobs/import-records/${formId}`,
        method: 'POST',
      }),
      transformResponse: (response: CreateImportJobResponse) => response.result,
    }),
    startImportJob: builder.mutation<StartImportJobResult, StartImportJobApiProps>({
      query: ({ id, ...rest }) => ({
        url: `/background-jobs/import-records/${id}/start`,
        params: rest,
        method: 'POST',
      }),
      transformResponse: (response: StartImportJobResponse) => response.result,
    }),
    confirmImportJob: builder.mutation<StartImportJobResult, number>({
      query: (id) => ({
        url: `/background-jobs/import-records/${id}/confirm`,
        method: 'POST',
      }),
      transformResponse: (response: StartImportJobResponse) => response.result,
    }),
    getImportJobDetails: builder.query<ImportJobDetailsResult, number>({
      query: (id) => `/background-jobs/import-records/${id}`,
      transformResponse: (response: ImportJobDetailsResponse) => response.result,
    }),
    createRecordCloneJob: builder.mutation<CloneRecordsJobResult, CloneRecordsApiProps>({
      query: ({ recordId, fieldId, ...rest }) => ({
        url: `records/${recordId}/fields/${fieldId}/clone-async`,
        body: rest,
        method: 'POST',
      }),
      transformResponse: (response: CloneRecordsJobResponse) => response.result,
    }),
    getRecordCloneJobDetails: builder.query<CloneRecordsJobDetails, number>({
      query: (id) => `/background-jobs/clone-records/${id}`,
      transformResponse: (response: CloneRecordsDetailsResponse) => response.result,
    }),
  }),
});

export const {
  useGetRecordByIdQuery,
  useGetDefaultRecordValuesQuery,
  useLazyGetRecordByIdQuery,
  useGetRecordsQuery,
  useLazyGetRecordsQuery,
  useGetLinkedRecordsQuery,
  useGetReverseLinkedRecordsQuery,
  useGetSummaryRecordsLinkedQuery,
  useLazyGetRecordFieldTemplateExportQuery,
  useGetAssignedRecordsQuery,
  useGetMyActivitiesQuery,
  useGetAssignedRecordsCountQuery,
  useGetRecordPermissionsQuery,
  useUpdateRecordMutation,
  useAddRecordMutation,
  useDeleteRecordMutation,
  useLazyGetAuditLogsQuery,
  useLazyGetAuditLogDetailsQuery,
  useLazyGetNotificationLogQuery,
  useGetRecordCountQuery,
  useGetRecordVersionsQuery,
  useLazyExportAllFormsRecordsQuery,
  useLazyExportRecordQuery,
  useLazyGetExternalUserEmailTemplateQuery,
  useSendInvitationEmailMutation,
  useSendEmailThisMutation,
  useGetRecordCollaboratorsQuery,
  useLazyGetCollaboratorEmailQuery,
  useEditRecordCollaboratorMutation,
  useDeleteRecordCollaboratorMutation,
  useGetAllFormsRecordsQuery,
  useDeleteLatestVersionMutation,
  useCreateNewVersionMutation,
  useCreateExportJobMutation,
  useCreateAllFormsExportJobMutation,
  useGetExportJobDetailsQuery,
  useLazyGetExportJobDetailsQuery,
  useCreateImportJobMutation,
  useStartImportJobMutation,
  useGetImportJobDetailsQuery,
  useCreateRecordCloneJobMutation,
  useGetRecordCloneJobDetailsQuery,
  useConfirmImportJobMutation,
  useGetRecordLinkFieldsDefaultValuesQuery,
  useSaveLinkedRecordsLayoutMutation,
} = recordApi;
