import { JsonDataWrapper, OdinDataRetrievalOptions, OdinDataSender } from '@myosh/odin-components';
import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import { getFormWorkflowSteps, getModuleForms, getModules } from '../../../common/common-administration-utils';
import { Rule, RuleResponse } from '../../../@types/rules';
import { t } from 'i18next';

export const transformRuleResponse = (data: RuleResponse): Rule => {
  const ruleData = data.result;
  return {
    ...ruleData,
    module: ruleData.module && { value: ruleData.module.id, text: ruleData.module.caption },
    moduleForm: ruleData.moduleForm && { value: ruleData.moduleForm.id, text: ruleData.moduleForm.caption },
    autoRuleType: autoRuleTypes.find((autoRuleType) => autoRuleType.value === ruleData.autoRuleType),
    autoRuleTrigger: triggerTypes.find((triggerType) => triggerType.value === ruleData.autoRuleTrigger),
    triggerWorkflowStep: ruleData.triggerWorkflowStep && {
      value: ruleData.triggerWorkflowStep.id,
      text: ruleData.triggerWorkflowStep.caption,
    },
  };
};

export const getRulesFieldOptionsData = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions,
  dispatch: ThunkDispatch<never, undefined, AnyAction> & Dispatch
) => {
  switch (options?.fieldId) {
    case 'autoRuleType':
      getAutoRuleTypes(subscriber, options);
      break;
    case 'autoRuleTrigger':
      getTriggerTypes(subscriber, options);
      break;
    case 'module':
      await getModules(subscriber, options, dispatch);
      break;
    case 'moduleForm':
      await getModuleForms(subscriber, options, dispatch);
      break;
    case 'triggerWorkflowStep':
      await getFormWorkflowSteps(subscriber, options, dispatch);
      break;
    default:
      subscriber.sendData();
  }
};

const getAutoRuleTypes = async (subscriber: OdinDataSender<JsonDataWrapper>, options: OdinDataRetrievalOptions) => {
  if (options.page === 1) {
    subscriber.sendData({ data: autoRuleTypes });
  } else {
    subscriber.sendData();
  }
};

const getTriggerTypes = (subscriber: OdinDataSender<JsonDataWrapper>, options: OdinDataRetrievalOptions) => {
  if (options.page === 1) {
    subscriber.sendData({ data: triggerTypes });
  } else {
    subscriber.sendData();
  }
};

const triggerTypes = [
  {
    value: 'WORKFLOW_PROGRESS',
    text: t('record-changed-status'),
  },
  {
    value: 'RECORD_SAVE',
    text: t('record-edited'),
  },
];

const autoRuleTypes = [
  {
    value: 'CREATE_NEW',
    text: t('create-a-new-record'),
    defaultOption: false,
  },
  {
    value: 'UPDATE_EXISTING',
    text: t('update-an-existing-record'),
    defaultOption: false,
  },
  {
    value: 'UPDATE_PARENT_RECORDS',
    text: t('update-parent-records'),
    defaultOption: false,
  },
  {
    value: 'UPDATE_CURRENT_RECORD',
    text: t('update-current-record'),
    defaultOption: false,
  },
];
