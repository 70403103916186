import React from 'react';
import {
  RiskMatrixDataItem,
  RiskMatrixData,
  RiskMatrixDataProps,
  PartialRiskLevelTooltipData,
} from '../../../@types/risk-matrix-field';
import { DomElementAlignment, DomTargetPosition, Tooltip, useTestTag } from '@myosh/odin-components';
import { findRiskMatrixCaption } from './risk-rating-functions';
import DOMPurify from 'dompurify';
import cx from 'classnames';
import { dataTag } from '../../../common/data-tags';

const createMarkup = (value?: string) => {
  if (value) {
    return { __html: DOMPurify.sanitize(value, { USE_PROFILES: { html: true } }) };
  }
};

function RiskRatingMatrix({
  riskMatrixVisible,
  riskMatrixData,
  handleRatingChange,
  handleFieldValueChange,
  handleConsequenceValueChange,
  handleLikelyhoodValueChange,
}: RiskMatrixDataProps) {
  const tagCreator = useTestTag('risk-rating');

  const riskMatrixHeadersTooltip = (riskMatrixCellData: RiskMatrixDataItem) => {
    if (riskMatrixCellData) {
      const matrixCellCaption = findRiskMatrixCaption(riskMatrixCellData.caption);
      const matrixCellDescriptionCaption = findRiskMatrixCaption(riskMatrixCellData.descriptionCaption);

      return (
        <div className="m-1 flex flex-col text-sm">
          <p className="mb-2 font-bold">{matrixCellCaption}</p>
          <div dangerouslySetInnerHTML={createMarkup(matrixCellDescriptionCaption)}></div>
        </div>
      );
    }
  };

  const riskMatrixBodyCellsTooltip = (
    consequenceData: PartialRiskLevelTooltipData,
    likelihoodData: PartialRiskLevelTooltipData
  ) => {
    if (consequenceData && likelihoodData) {
      return (
        <div className="m-1 flex flex-col text-sm">
          <p className="mb-2 font-bold">{consequenceData.caption}</p>
          <div dangerouslySetInnerHTML={createMarkup(consequenceData.descriptionCaption)}></div>
          <p className="mb-2 mt-2 font-bold">{likelihoodData.caption}</p>
          <div dangerouslySetInnerHTML={createMarkup(likelihoodData.descriptionCaption)}></div>
        </div>
      );
    }
  };

  const renderMatrixHeaderCells = (riskMatrixCellData: RiskMatrixData) => {
    if (riskMatrixCellData) {
      return (
        <thead ref={tagCreator('header-row')}>
          <tr>
            {riskMatrixCellData.map((riskMatrixCell: RiskMatrixDataItem, index: number) => {
              if (riskMatrixCell) {
                const consequenceCaptionValue = findRiskMatrixCaption(riskMatrixCell.caption);
                const isEditableCell = index > 0 && Boolean(handleConsequenceValueChange);
                return (
                  <td
                    key={index}
                    className={cx(
                      {
                        'border-l': consequenceCaptionValue,
                        'cursor-pointer': isEditableCell,
                      },
                      headerCellsStyle
                    )}
                    ref={tagCreator(dataTag(consequenceCaptionValue))}
                    onClick={() => isEditableCell && handleConsequenceValueChange?.(riskMatrixCell)}
                  >
                    <Tooltip
                      description={riskMatrixHeadersTooltip(riskMatrixCell)}
                      tooltipTargetPosition={tooltipTargetPosition}
                      tooltipAlignment={tooltipAlignment}
                      tooltipClassName={tooltipDescription}
                      debounceTime={200}
                      tooltipZIndexCheck
                    >
                      {consequenceCaptionValue}
                    </Tooltip>
                  </td>
                );
              }
            })}
          </tr>
        </thead>
      );
    }
  };

  const renderMatrixBodyCells = (riskMatrixCellData: RiskMatrixData, rowIndex: number) => {
    return (
      <tr key={rowIndex}>
        {riskMatrixCellData.map((riskMatrixCell: RiskMatrixDataItem, index: number) => {
          if (riskMatrixCell) {
            const isCellStyleConfigured = riskMatrixCell.backgroundColor && riskMatrixCell.textColor;
            const likelihoodCaptionValue = findRiskMatrixCaption(riskMatrixCell.caption);
            const riskLevelRatingValue = riskMatrixCell.rating;
            const likelihoodTooltipData = {
              caption: riskMatrixCell.riskLevelCaptions?.likelihoodCaption,
              descriptionCaption: riskMatrixCell.riskLevelCaptions?.likelihoodDescription,
            };
            const consequenceTooltipData = {
              caption: riskMatrixCell.riskLevelCaptions?.consequenceCaption,
              descriptionCaption: riskMatrixCell.riskLevelCaptions?.consequenceDescription,
            };

            const isEditableCell = index > 0 && Boolean(handleLikelyhoodValueChange);
            return (
              <td
                key={index}
                className={cx(
                  'cursor-pointer',
                  {
                    'text-center': isCellStyleConfigured,
                    'border-r': !isCellStyleConfigured,
                    '!cursor-default': !isEditableCell,
                  },
                  rowCellsStyle
                )}
                style={
                  isCellStyleConfigured
                    ? { background: riskMatrixCell.backgroundColor, color: riskMatrixCell.textColor }
                    : {}
                }
                onClick={() => {
                  if (index > 0) {
                    handleFieldValueChange?.(riskMatrixCell);
                    handleRatingChange(riskMatrixCell);
                  } else {
                    handleLikelyhoodValueChange?.(riskMatrixCell);
                  }
                }}
              >
                <Tooltip
                  description={
                    riskMatrixCell?.rating
                      ? riskMatrixBodyCellsTooltip(consequenceTooltipData, likelihoodTooltipData)
                      : riskMatrixHeadersTooltip(riskMatrixCell)
                  }
                  tooltipTargetPosition={tooltipTargetPosition}
                  tooltipAlignment={tooltipAlignment}
                  tooltipClassName={tooltipDescription}
                  debounceTime={200}
                  tooltipZIndexCheck
                  disabled={!riskMatrixVisible}
                >
                  {index === 0 ? (
                    <p ref={tagCreator(dataTag(riskLevelRatingValue) || dataTag(likelihoodCaptionValue))}>
                      {riskLevelRatingValue || likelihoodCaptionValue}
                    </p>
                  ) : (
                    <button
                      className="focus:rounded-sm focus:outline-none focus:ring-2 focus:ring-primary-1"
                      ref={tagCreator(dataTag(riskLevelRatingValue) || dataTag(likelihoodCaptionValue))}
                      type="button"
                    >
                      {riskLevelRatingValue || likelihoodCaptionValue}
                    </button>
                  )}
                </Tooltip>
              </td>
            );
          }
        })}
      </tr>
    );
  };

  function renderRiskMatrixCells(riskMatrixData: Array<RiskMatrixData>) {
    return riskMatrixData.map((riskMatrixDataCell: RiskMatrixData, index: number) => {
      if (index === 0) {
        return;
      }
      return renderMatrixBodyCells(riskMatrixDataCell, index);
    });
  }

  const tableCellsStyle = 'border-t border-gray-4 text-sm p-1.5 cursor-default';
  const headerCellsStyle = `${tableCellsStyle} text-center`;
  const rowCellsStyle = `${tableCellsStyle} bg-gray-5 text-left cursor-pointer`;
  const tooltipDescription =
    'bg-mono-1 [&_ul]:list-disc [&_ul]:pl-5 [&_ol]:list-decimal [&_ol]:pl-5 [&_li]:ml-2 [&_a]:text-url [&_a]:underline';
  const tooltipTargetPosition = DomTargetPosition.BottomRight;
  const tooltipAlignment = DomElementAlignment.TopRight;

  return (
    <table ref={tagCreator('matrix')}>
      {riskMatrixData && renderMatrixHeaderCells(riskMatrixData[0])}
      <tbody>{riskMatrixData && renderRiskMatrixCells(riskMatrixData)}</tbody>
    </table>
  );
}

export default RiskRatingMatrix;
