import { useEffect, useState } from 'react';
import { SchemaDetails } from '../@types/schema';
import { SchemaDropdownItem } from '../components/layout/components/schema-selector.component';
import { useLazyGetSchemaDetailsQuery } from '../redux/services/api';

interface User {
  prefered_username?: string;
  schemas?: string;
  currentSchema?: string;
  superuser: string;
  controlledUsername?: string;
}

interface Tokens {
  id_token: string;
  access_token: string;
  expires_at: number;
  refresh_token?: string;
  auth_time?: number;
}

interface UserState {
  auth: boolean;
  user?: User;
  tokens?: Tokens;
  loginStatus?: 'idp' | 'login' | 'schema' | 'done';
}

const useUserSchemas = (userState: UserState) => {
  const [schemas, setSchemas] = useState<SchemaDropdownItem[] | undefined>(undefined);
  const [currentSchema, setCurrentSchema] = useState<SchemaDropdownItem | undefined>(undefined);
  const [getSchemas] = useLazyGetSchemaDetailsQuery();
  const isControllingSuperuser = Boolean(userState?.user?.superuser === 'true' && userState?.user?.controlledUsername);

  useEffect(() => {
    getSchemas()
      .unwrap()
      .then((schemas: Array<SchemaDetails>) => {
        setSchemas(
          [...schemas]
            .sort((schema1, schema2) => schema1.name.localeCompare(schema2.name))
            .map((schema: SchemaDetails) => {
              return { value: schema.code, text: schema.name };
            })
        );
        if (userState.user?.currentSchema) {
          const currentSchema = schemas.find((schema: SchemaDetails) => schema.code === userState.user?.currentSchema);
          if (currentSchema) {
            setCurrentSchema({ value: currentSchema.code, text: currentSchema.name });
          }
        }
      });
  }, [userState]);

  return { schemas, currentSchema, isControllingSuperuser, setSchemas, setCurrentSchema };
};

export default useUserSchemas;
