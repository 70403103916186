import React, { useEffect, useReducer, useMemo, useState, useRef } from 'react';
import { Checkbox, DynamicFieldComponentProps, CheckboxChangeEventType, JsonDataItem } from '@myosh/odin-components';
import cx from 'classnames';
import { Control, FieldError, useFormState } from 'react-hook-form';
import { ExtendedDynamicFormHierarchySettings } from '../../../common/common-functions';
import {
  HierarchyType,
  HierarchyDropdownValue,
  TransformedFormHierarchyTypes,
  HierarchyAccessRestriction,
} from '../../../@types/hierarchy-fields';
import HierarchyFieldComponent from './hierarchy-field.component';
import useGetPersonHierarchies from '../person/use-get-person-hierarchy';
import { HierarchiesActionType, HierarchiesReducer } from './hierarchies.reducer';
import useProfileData from '../../../hooks/use-profile-data';
import { useTranslation } from 'react-i18next';
import { useHierarchyTypesQuery } from '../../../redux/services/hierarchy';
import { isHierarchyKeyValueEqual, setNewParentPersonHierarchyValues } from './hierarchy-functions';
import { useGetCurrentSchemaInfoQuery } from '../../../redux/services/api';

export type RecordHierarchyFields = TransformedFormHierarchyTypes | HierarchyType;

export type HierarchyFieldError = Record<string, Record<string, FieldError>>;

export interface HierarchyFieldGroupProps
  extends DynamicFieldComponentProps<Record<string, HierarchyDropdownValue | HierarchyDropdownValue[]>> {
  recordId?: number;
  hierarchySettings?: ExtendedDynamicFormHierarchySettings;
  hierarchyTypes?: Array<TransformedFormHierarchyTypes | HierarchyType>;
  isUserHierarchy?: boolean;
  isExternal?: boolean;
  errorObject?: HierarchyFieldError;
  isCompetencyHierarchy?: boolean;
  ignoreHierarchyAccess?: boolean;
  usesMultiSelectDropdowns?: boolean;
  showRecordWillBecomeUnavailableMessage?: (field?: string) => void;
  hideRecordWillBecomeUnavailableMessage?: (field?: string | string[]) => void;
  control?: Control;
}

export function HierarchyFieldGroup({
  recordId,
  errorObject,
  value = {},
  hierarchyTypes,
  readOnly,
  onChange,
  hierarchySettings,
  isUserHierarchy = false,
  isExternal,
  isCompetencyHierarchy = false,
  ignoreHierarchyAccess,
  usesMultiSelectDropdowns = false,
  showRecordWillBecomeUnavailableMessage,
  hideRecordWillBecomeUnavailableMessage,
  control,
}: HierarchyFieldGroupProps) {
  const { data: schemaInfo } = useGetCurrentSchemaInfoQuery();
  const [{ dependentHierarchyTypes, hierarchyFieldsState, fieldsValues }, dispatch] = useReducer(
    HierarchiesReducer,
    {}
  );
  const { data: hierachyTypesAll } = useHierarchyTypesQuery({ archived: 'false' });
  const [ignoreHierarchyAccessValue, setIgnoreHierarchyAccessValue] = useState<boolean>(Boolean(ignoreHierarchyAccess));
  const hasChangedValues = useRef(false);
  const hasCompletedInitialisation = useRef(false);
  const { t } = useTranslation();
  const personFieldId = hierarchySettings?.autoPopulateHierarchyFromPersonFieldId;
  const hierarchyAccessRestrictionIgnoreMode = hierarchySettings?.hierarchyAccessRestrictionIgnoreMode;

  const {
    profileData: { user: { hierarchyAccessRestrictions, isExternal: isUserExternal, userHierarchies } = {} } = {},
  } = useProfileData();

  useEffect(() => {
    if (!hasCompletedInitialisation.current && value && hierarchyFieldsState && dependentHierarchyTypes) {
      hasCompletedInitialisation.current = true;
      dispatch({
        type: HierarchiesActionType.SetInitialFieldsValue,
        value,
        usesMultiSelectDropdowns,
      });
    }
  }, [value, hierarchyFieldsState, dependentHierarchyTypes]);

  useEffect(() => {
    if (!usesMultiSelectDropdowns && fieldsValues && !isHierarchyKeyValueEqual(value, fieldsValues)) {
      dispatch({
        type: HierarchiesActionType.SetInitialFieldsValue,
        value,
        usesMultiSelectDropdowns,
      });
    }
  }, [value]);

  useEffect(() => {
    if (hierarchyTypes?.length && hierachyTypesAll?.length && schemaInfo) {
      dispatch({
        isCompetencyHierarchy,
        type: HierarchiesActionType.SetInitialFieldsConfigurations,
        hierarchyTypes,
        isUserHierarchy,
        isExternal,
        hierachyTypesAll,
        isTreeHierarchy: schemaInfo['tree-hierarchies-enabled'] === 'true',
      });
    }
  }, [hierarchyTypes, hierachyTypesAll, schemaInfo]);

  const onChangeHandler = (
    hierarchyField: RecordHierarchyFields,
    fieldValue: HierarchyDropdownValue | HierarchyDropdownValue[]
  ) => {
    dispatch({ type: HierarchiesActionType.UpdateFieldValue, hierarchyField, fieldValue, usesMultiSelectDropdowns });
    hasChangedValues.current = true;
  };

  useEffect(() => {
    if (fieldsValues && hasChangedValues.current) {
      onChange?.(fieldsValues);
      hasChangedValues.current = false;
    }
  }, [fieldsValues, ignoreHierarchyAccessValue]);

  const personHierarchies = useGetPersonHierarchies({
    control,
    parentFieldId: personFieldId,
  });
  const { dirtyFields } = useFormState({
    control,
  });

  // Update value only if parent person field has been actually changed
  useEffect(() => {
    if (personFieldId && dirtyFields[String(personFieldId)]) {
      const updatedPersonHierarchyValues = setNewParentPersonHierarchyValues(
        personHierarchies,
        hierarchyFieldsState,
        fieldsValues,
        isUserHierarchy,
        isCompetencyHierarchy
      );
      dispatch({
        type: HierarchiesActionType.SetInitialFieldsValue,
        value: updatedPersonHierarchyValues,
      });
      hasChangedValues.current = true;
    }
  }, [personHierarchies, personFieldId]);

  const hierarchyAccessRestrictionIgnored = useMemo(() => {
    if (hierarchyAccessRestrictionIgnoreMode === 'BOTH') {
      return true;
    } else if (isUserExternal === true && hierarchyAccessRestrictionIgnoreMode === 'EXTERNAL_ONLY') {
      return true;
    } else if (isUserExternal === false && hierarchyAccessRestrictionIgnoreMode === 'INTERNAL_ONLY') {
      return true;
    }
    return false;
  }, [hierarchyAccessRestrictionIgnoreMode, isUserExternal]);

  const hierarchyRestrictions = useMemo(() => {
    if (hierarchyAccessRestrictions?.length) {
      return hierarchyFieldsState?.reduce((restrictionsAccumulator: JsonDataItem, hierarchyField) => {
        restrictionsAccumulator[hierarchyField.caption] = hierarchyAccessRestrictions.find(
          (hierarchyAccessRestriction) => hierarchyAccessRestriction.hierarchyTypeId === hierarchyField.id
        );
        return restrictionsAccumulator;
      }, {});
    }
  }, [hierarchyAccessRestrictions]);

  useEffect(() => {
    if (hierarchyRestrictions && !hierarchyAccessRestrictionIgnored && value && !usesMultiSelectDropdowns) {
      let shouldShowMessage = '';
      for (const key in value) {
        if (hierarchyRestrictions[key] !== undefined) {
          if (
            (value[key] as HierarchyDropdownValue).id === (hierarchyRestrictions[key] as HierarchyAccessRestriction)?.id
          ) {
            shouldShowMessage = '';
            hideRecordWillBecomeUnavailableMessage?.(Object.keys(value));
            break;
          } else {
            shouldShowMessage = key;
          }
        }
      }

      if (shouldShowMessage) {
        showRecordWillBecomeUnavailableMessage?.(shouldShowMessage);
      }
    }
  }, [hierarchyRestrictions, hierarchyAccessRestrictionIgnored, value]);

  const hierarchyStyles = cx({ hidden: hierarchySettings?.hiddenInReadMode && readOnly });

  return (
    <>
      {isCompetencyHierarchy && (
        <div>
          <Checkbox
            name="ignore-hierarchy-access"
            label={t('ignore-hierarchy-access')}
            initialChecked={ignoreHierarchyAccessValue}
            onChange={(event?: CheckboxChangeEventType) => {
              if (event && setIgnoreHierarchyAccessValue) {
                hasChangedValues.current = true;
                setIgnoreHierarchyAccessValue(event.checked);
                dispatch({ type: HierarchiesActionType.SetIgnoreHIerarchyAccess, ignoreHierarchyAccess: event });
              }
            }}
            readOnly={readOnly}
          />
        </div>
      )}
      {!ignoreHierarchyAccessValue && (
        <div className={hierarchyStyles}>
          {hierarchyFieldsState?.map((hierarchyField) => (
            <HierarchyFieldComponent
              key={hierarchyField.id}
              recordId={recordId}
              hierarchySettings={hierarchySettings}
              value={fieldsValues}
              hierarchyField={hierarchyField}
              userHierarchies={userHierarchies}
              hierarchyRestrictions={hierarchyRestrictions as Record<string, HierarchyAccessRestriction>}
              readOnly={readOnly}
              errorObject={errorObject}
              isUserHierarchy={isUserHierarchy}
              onChange={onChangeHandler}
              isCompetencyHierarchy={isCompetencyHierarchy}
              showRecordWillBecomeUnavailableMessage={showRecordWillBecomeUnavailableMessage}
              hideRecordWillBecomeUnavailableMessage={hideRecordWillBecomeUnavailableMessage}
              usesMultiSelectDropdowns={usesMultiSelectDropdowns}
            />
          ))}
        </div>
      )}
    </>
  );
}
