import { Notification, NotificationProps as CustomNotificationProps } from '@myosh/odin-components';
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastOptions, useToaster } from 'react-hot-toast';

export interface NotificationProps
  extends ToastOptions,
    Pick<CustomNotificationProps, 'showCloseIcon' | 'onCloseClick'> {}

const Notifications = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;

  const createNotifications = () => {
    return (
      <div
        style={{
          position: 'fixed',
          top: '50%',
          display: 'flex',
          width: '100%',
          zIndex: 9999,
          flex: '0 1 auto',
          justifyItems: 'center',
        }}
        onMouseEnter={startPause}
        onMouseLeave={endPause}
      >
        {toasts.map((toast) => {
          const offset = calculateOffset(toast, {
            reverseOrder: false,
            gutter: 8,
          });

          const ref = (el: HTMLDivElement) => {
            if (el && !toast.height) {
              const height = el.getBoundingClientRect().height;
              updateHeight(toast.id, height);
            }
          };

          return (
            <Notification
              key={toast.id}
              ref={ref}
              {...toast.ariaProps}
              className="max-w-sm"
              style={{
                position: 'absolute',
                left: '50%',
                top: '-1rem',
                transition: 'all 0.5s ease-out',
                opacity: toast.visible ? 1 : 0,
                transform: `translate(-50%, ${offset}px)`,
                zIndex: 9999999,
                wordBreak: 'break-all',
              }}
              type={toast.type}
              showCloseIcon={(toast as NotificationProps)?.showCloseIcon}
              onCloseClick={(toast as NotificationProps)?.onCloseClick}
            >
              {String(toast.message)}
            </Notification>
          );
        })}
      </div>
    );
  };

  return ReactDOM.createPortal(createNotifications(), document.body);
};

export default Notifications;
