import { Loader } from '@myosh/odin-components';
import React, { useState } from 'react';
import Meta from '../features/meta/meta';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

interface EmbeddedModuleProps {
  slug: string;
  title: string;
  url?: string; // to load `Dashboard` and `Viking Analytics` modules in an iframe tag
}

export default function EmbeddedPage({ slug, title, url }: EmbeddedModuleProps) {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const hideSpinner = () => setIsLoading(false);

  const loadingIndicatorStyles = cx('flex flex-col items-center justify-center w-full h-screen mx-auto', {
    hidden: !isLoading,
  });

  return (
    <>
      <Meta title={`myosh - ${slug}`} />
      <div className={loadingIndicatorStyles}>
        <Loader title={`${t('loading')} ${title}`} />
      </div>
      <iframe id={slug} src={url} hidden={isLoading} onLoad={hideSpinner} width="100%" height="100%" />
    </>
  );
}
