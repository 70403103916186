import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AttachmentItem,
  AttachmentsResponse,
  GetAttachmentsProps,
  GetQuestionnaireAttachmentsProps,
  PostAttachmentProps,
  PostQuestionnaireAttachmentProps,
  PostSingleAttachmentResponse,
  PostAttachmentStagingProps,
  PostAttachmentStagingResponse,
  DeleteAttachmentProps,
  PostMultipleAttachmentResponse,
  AttachmentInfoResponse,
  AttachmentPresignedUrl,
} from '../../@types/attachments';
import { baseQueryWithReAuthAndUrlEncoding } from './api';
import { ResultValidationResponse } from '../../@types/common';
import {
  CreateImportJobResult,
  ImportJobDetailsResult,
  StartImportJobApiArgs,
  StartImportJobResult,
} from '../../@types/background-jobs';

export const fileApi = createApi({
  reducerPath: 'file',
  baseQuery: baseQueryWithReAuthAndUrlEncoding,
  tagTypes: ['File', 'QuestionnaireFile'],
  endpoints: (builder) => ({
    getFilesByRecordId: builder.query<Array<AttachmentItem>, GetAttachmentsProps>({
      query: ({ recordId }) => `/records/${recordId}/files`,
      transformResponse: (response: AttachmentsResponse) => {
        return response?.result?.filter((item) => item?.deleted !== true) || [];
      },
      providesTags: (result, error, { recordId }) =>
        result
          ? [
              ...result.map((item) => ({
                type: 'File' as const,
                id: item.id,
              })),
              { type: 'File', id: `${recordId}-LIST` },
            ]
          : [{ type: 'File', id: `${recordId}-LIST` }],
    }),
    getFilesByRecordIdAndQuestionId: builder.query<Array<AttachmentItem>, GetQuestionnaireAttachmentsProps>({
      query: ({ recordId, questionId }) => `/records/${recordId}/questions/${questionId}/files`,
      transformResponse: (response: AttachmentsResponse) => {
        return response?.result || [];
      },
      providesTags: (result, error, { recordId, questionId }) =>
        result
          ? [
              ...result.map((item) => ({
                type: 'QuestionnaireFile' as const,
                id: item.id,
              })),
              { type: 'QuestionnaireFile', id: `${recordId}-${questionId}` },
            ]
          : [{ type: 'QuestionnaireFile', id: `${recordId}-${questionId}` }],
    }),
    addFileToStaging: builder.mutation<string | undefined, PostAttachmentStagingProps>({
      query: ({ formData }) => ({
        url: `/files/staging`,
        method: 'POST',
        body: formData,
      }),
      transformResponse: (response: PostAttachmentStagingResponse) => {
        return response.result;
      },
    }),
    getPresignedUrl: builder.query<string, number>({
      // number used to invalidate cache
      query: () => '/files/staging/url',
      transformResponse: (response: AttachmentPresignedUrl) => {
        return response.result;
      },
    }),
    addFileByRecordId: builder.mutation<Array<AttachmentItem> | undefined, PostAttachmentProps>({
      query: ({ confirmations, recordId, updateRecordLog = false }) => ({
        url: `/files/confirm?recordId=${recordId}&updateRecordLog=${updateRecordLog}`,
        method: 'POST',
        body: { confirmations },
      }),
      transformResponse: (response: PostMultipleAttachmentResponse) => {
        return response.result;
      },
      invalidatesTags: (result, error, { recordId }) => [{ type: 'File', id: `${recordId}-LIST` }],
    }),
    addFileByRecordIdAndQuestionId: builder.mutation<AttachmentItem | undefined, PostQuestionnaireAttachmentProps>({
      query: ({ objectKey, recordId, questionId }) => ({
        url: `/records/${recordId}/questions/${questionId}/upload/confirm`,
        method: 'POST',
        body: { objectKey },
      }),
      transformResponse: (response: PostSingleAttachmentResponse) => {
        return response.result;
      },
      invalidatesTags: (result, error, { recordId, questionId }) => [
        { type: 'QuestionnaireFile', id: `${recordId}-${questionId}` },
      ],
    }),
    deleteAttachment: builder.mutation<number, DeleteAttachmentProps>({
      query: ({ id }) => ({
        url: `/files/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { recordId, questionId }) => {
        if (questionId) {
          return [
            { type: 'QuestionnaireFile', id: `${recordId}-${questionId}` },
            { type: 'File', id: `${recordId}-LIST` },
          ];
        } else {
          return [{ type: 'File', id: `${recordId}-LIST` }];
        }
      },
    }),
    getFileUrl: builder.query<string, string>({
      query: (sourceId) => `/files/${sourceId}/url`,
      transformResponse: (response: AttachmentInfoResponse) => response.result,
    }),
    createImportAttachmentsJob: builder.mutation<CreateImportJobResult, void>({
      query: () => ({
        url: `/background-jobs/import-attachments`,
        method: 'POST',
      }),
      transformResponse: (response: ResultValidationResponse<CreateImportJobResult>) => response.result,
    }),
    startImportAttachmentsJob: builder.mutation<StartImportJobResult, StartImportJobApiArgs>({
      query: ({ jobId, prefix }) => ({
        url: `/background-jobs/import-attachments/${jobId}/start`,
        method: 'POST',
        params: { prefix: prefix },
      }),
      transformResponse: (response: ResultValidationResponse<StartImportJobResult>) => response.result,
    }),
    getImportAttachmentsJobDetails: builder.query<ImportJobDetailsResult, number>({
      query: (jobId) => `/background-jobs/import-attachments/${jobId}`,
      transformResponse: (response: ResultValidationResponse<ImportJobDetailsResult>) => response.result,
    }),
  }),
});

export const {
  useGetFilesByRecordIdQuery,
  useAddFileByRecordIdMutation,
  useGetFilesByRecordIdAndQuestionIdQuery,
  useLazyGetFilesByRecordIdAndQuestionIdQuery,
  useAddFileToStagingMutation,
  useAddFileByRecordIdAndQuestionIdMutation,
  useDeleteAttachmentMutation,
  useLazyGetFileUrlQuery,
  useLazyGetPresignedUrlQuery,
  useCreateImportAttachmentsJobMutation,
  useStartImportAttachmentsJobMutation,
  useGetImportAttachmentsJobDetailsQuery,
} = fileApi;
