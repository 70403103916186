import { ActiveRecordRef } from '../components/active-record/active-record.component';
import { useEffect, useRef } from 'react';
import { Subject, takeUntil } from 'rxjs';
import { getActiveRecordReference } from '../services/active-record.service';

const useActiveRecordRef = () => {
  const activeRecordReference = useRef<ActiveRecordRef | null>();
  const destroySubject = new Subject<void>();

  useEffect(() => {
    getActiveRecordReference()
      .pipe(takeUntil(destroySubject))
      .subscribe((reference) => {
        activeRecordReference.current = reference;
      });

    return () => {
      destroySubject.next();
      destroySubject.complete();
    };
  }, []);

  return {
    activeRecordReference,
  };
};

export default useActiveRecordRef;
