import React from 'react';
import { Control, Controller } from 'react-hook-form';
import InviteButtonField, { InviteButtonFieldProps } from './invite-button-field.component';

interface HfInviteButtonFieldProps extends InviteButtonFieldProps {
  control?: Control;
  name?: string;
}

function HfInviteButtonField({
  id,
  name = 'invite_button',
  control,
  buttonCaption,
  recordId,
  readOnly,
  saveRecord,
}: HfInviteButtonFieldProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <InviteButtonField
          id={id}
          buttonCaption={buttonCaption}
          recordId={recordId}
          readOnly={readOnly}
          saveRecord={saveRecord}
        />
      )}
    />
  );
}

export default HfInviteButtonField;
