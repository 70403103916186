import {
  DynamicFieldDataProps,
  JsonDataItem,
  JsonDataWrapper,
  OdinDataRetrievalOptions,
  OdinDataSender,
} from '@myosh/odin-components';
import i18next from '../../i18n';
import { RecordNotification } from '../../@types/record-notifications';
import { cloneDeep, isArray } from 'lodash';
import { dayValues, getTimeZoneOffset } from '../fields/schedule-rules-field/scheduler-rules-utils';

export const selectionDataSettings: DynamicFieldDataProps = {
  valueField: 'value',
  textField: 'text',
};

const notificationType: Array<JsonDataItem> = [
  { text: i18next.t('triggered'), value: 'TRIGGERED' },
  { text: i18next.t('timed'), value: 'TIMED' },
  { text: i18next.t('summary'), value: 'SUMMARY' },
];
export const deliveryMethod: Array<JsonDataItem> = [
  { text: i18next.t('email'), value: 'EMAIL' },
  { text: i18next.t('push-notification'), value: 'PUSH' },
  { text: i18next.t('both'), value: 'BOTH' },
];

export const intervalsNotification: Array<JsonDataItem> = [
  { text: i18next.t('days'), value: 'DAY' },
  { text: i18next.t('weeks'), value: 'WEEK' },
  { text: i18next.t('months'), value: 'MONTH' },
  { text: i18next.t('years'), value: 'YEAR' },
];

export const stateChangeTriggered: Array<JsonDataItem> = [
  { text: i18next.t('record-changed-status'), value: 'WORKFLOW_PROGRESS' },
  { text: i18next.t('date-field'), value: 'DATE_FIELD' },
  { text: i18next.t('record-edited'), value: 'DATA_CHANGED' },
  { text: i18next.t('record-deleted'), value: 'DELETED' },
];

export const stateChangeSummary: Array<JsonDataItem> = [
  { text: i18next.t('records-particular-statuses'), value: 'WORKFLOW_PROGRESS' },
  { text: i18next.t('date-field'), value: 'DATE_FIELD' },
];

export const groupRecordsBy: Array<JsonDataItem> = [
  { text: i18next.t('hierarchy'), value: 'HIERARCHY' },
  { text: i18next.t('person-field'), value: 'PERSONFIELD' },
];

export const dateChangeType: Array<JsonDataItem> = [
  { text: i18next.t('date-field'), value: 'ON_DATE' },
  { text: i18next.t('date-field-plus'), value: 'DATE_PLUS' },
  { text: i18next.t('date-field-minus'), value: 'DATE_MINUS' },
];

const getNotificationTypes = async (subscriber: OdinDataSender<JsonDataWrapper>, options: OdinDataRetrievalOptions) => {
  if (options.page === 1) {
    subscriber.sendData({ data: notificationType });
  } else {
    subscriber.sendData();
  }
};
const getDeliveryMethod = async (subscriber: OdinDataSender<JsonDataWrapper>, options: OdinDataRetrievalOptions) => {
  if (options.page === 1) {
    subscriber.sendData({ data: deliveryMethod });
  } else {
    subscriber.sendData();
  }
};

const getTrigger = async (subscriber: OdinDataSender<JsonDataWrapper>, options: OdinDataRetrievalOptions) => {
  if (options.page === 1) {
    subscriber.sendData({ data: stateChangeTriggered });
  } else {
    subscriber.sendData();
  }
};

export const getNotificationsFieldOptions = async (
  subscriber: OdinDataSender<JsonDataWrapper>,
  options: OdinDataRetrievalOptions
) => {
  switch (options?.fieldId?.toString()) {
    case 'notificationType':
      await getNotificationTypes(subscriber, options);
      break;
    case 'notificationDelivery':
      await getDeliveryMethod(subscriber, options);
      break;
    case 'stateChange':
      await getTrigger(subscriber, options);
      break;
    default:
      subscriber.sendData();
  }
};

export const createUINotitificationData = (originalRecord: RecordNotification) => {
  const updatedRecord = cloneDeep(originalRecord);
  if (updatedRecord) {
    const {
      notificationDelivery,
      stateChange,
      notificationType,
      sendTimeZoneId,
      weekDays,
      triggerWorkflowStep,
      recordGroupingType,
      sourceStatus,
      destinationStatus,
      personField,
    } = {
      ...updatedRecord.notificationPropertiesDTO,
      ...updatedRecord.notificationPropertiesDTO.notificationEventDetailDTO,
    };
    const weekDaysResult = isArray(weekDays) ? dayValues.filter((day) => weekDays.includes(day.value as string)) : [];
    const changeType = updatedRecord.notificationPropertiesDTO?.notificationEventDetailDTO?.changeType;
    updatedRecord['weekDays'] = weekDaysResult;
    if (triggerWorkflowStep) {
      updatedRecord['triggerWorkflowStep'] = { text: triggerWorkflowStep?.caption, value: triggerWorkflowStep?.id };
    }
    //Default optiongroups & dropdowns
    convertToOptionGroup(updatedRecord, 'notificationDelivery', notificationDelivery);
    convertToOptionGroup(updatedRecord, 'stateChange', stateChange);
    convertToOptionGroup(updatedRecord, 'notificationType', notificationType);
    convertToOptionGroup(updatedRecord, 'changeType', changeType, false);

    //Hierarchy summary record
    if (recordGroupingType) {
      updatedRecord['recordGroupingType'] = {
        text: i18next.t(recordGroupingType.toLowerCase()),
        value: recordGroupingType,
      };
    }

    if (sourceStatus) {
      updatedRecord['sourceStatus'] = { text: sourceStatus.caption, value: sourceStatus.id };
    }
    if (destinationStatus) {
      updatedRecord['destinationStatus'] = { text: destinationStatus.caption, value: destinationStatus.id };
    }
    if (personField) {
      updatedRecord['personField'] = [{ text: personField.caption, value: personField.id }];
    }
    if (sendTimeZoneId) {
      const timeZoneValue = { text: `${sendTimeZoneId} ${getTimeZoneOffset(sendTimeZoneId)}`, value: sendTimeZoneId };
      updatedRecord['sendTimeZoneId'] = timeZoneValue;
    }
  }

  return updatedRecord;
};

const convertToOptionGroup = (
  record: RecordNotification,
  path: string,
  value?: string,
  captionLowerCase: boolean = true
) => {
  if (value) {
    const stateChangeDropDown = { text: captionLowerCase ? value.toLowerCase() : value, value: value };
    record[path] = stateChangeDropDown;
  }
};
