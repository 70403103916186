import React from 'react';
import { HfCheckbox, HelpComponent } from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';
import { selectionDataSettings } from '../../../record-notification/notifications-utils';
import HfDropDown from '../../custom-drop-downs/hf-drop-down.component';
import { NotificationTypeFieldProps, getTriggerFieldsForType } from '../property-section/notification-properties-utils';
import { useFormSettingsQuery } from '../../../../redux/services/api';

const TriggerRecordEditedField = ({ value, control, readOnly }: NotificationTypeFieldProps) => {
  const { t } = useTranslation();
  const { data: formSettingsData } = useFormSettingsQuery(value?.moduleForm?.id ?? 0, {
    skip: value?.moduleForm?.id === undefined,
  });

  return (
    <div className="flex flex-col my-2">
      <div className="odin-relative odin-flex odin-w-full odin-items-center my-1">
        <HfDropDown
          label={t('only-send-fields-edited')}
          control={control}
          name="triggeringFields"
          data={getTriggerFieldsForType(formSettingsData)}
          readOnly={readOnly}
          {...selectionDataSettings}
        />
        <HelpComponent className="help-component-default" description={t('only-send-fields-edited-tooltip')} />
      </div>
      <div className="odin-relative odin-flex odin-w-full odin-items-center my-1">
        <HfCheckbox
          control={control}
          id="notificationPropertiesDTO.notificationEventDetailDTO.ignoreNewRecords"
          name="notificationPropertiesDTO.notificationEventDetailDTO.ignoreNewRecords"
          label={t('no-trigger-first-save')}
          readOnly={readOnly}
        />
        <HelpComponent className="help-component-default" description={t('no-trigger-first-tooltip')} />
      </div>
    </div>
  );
};

export default TriggerRecordEditedField;
