import React, {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useCallback,
  useMemo,
  Key,
} from 'react';
import { getSchedulerFormSettings } from './scheduler-form-settings';
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';
import {
  DataGridRef,
  DynamicFormButtonSetting,
  DynamicForm,
  DynamicFormRef,
  JsonDataItem,
  JsonDataWrapper,
  ModalDialogRef,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  DynamicFormFieldType,
  DropDownResult,
  DynamicFormSettings,
  ModalDialog,
} from '@myosh/odin-components';
import i18next from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { BehaviorSubject, Subject, take, takeUntil } from 'rxjs';
import { FormChangedFields } from '../../../@types/common';
import { OccupationMutationResponse } from '../../../@types/occupations';
import { deleteButton, getChangedFieldsData, saveModalButtons } from '../../../common/common-administration-utils';
import { forceAssert } from '../../../common/common-functions';
import useDynamicFormNotifier from '../../../hooks/use-dynamic-form-notifier';
import usePerformingSaveToast from '../../../hooks/use-performing-save-toast';
import { useAppDispatch } from '../../../redux/hooks';
import { getDataGridReference } from '../../../services/data-grid.service';
import { showSuccess, showError } from '../../../services/notification.service';
import { ActiveRecordContextProps } from '../../active-record/active-record.component';
import { FormLoading } from '../../form/form-loading.component';
import useProfileData from '../../../hooks/use-profile-data';
import { SchedulePagePermissions } from '../../../common/user.permissions';
import { FormTitle } from '../../form/form-title.component';
import {
  scheduledRecordApi,
  useAddScheduleRecordMutation,
  useDeleteScheduleRecordMutation,
  useGetScheduleByIdQuery,
  useUpdateScheduleRecordMutation,
} from '../../../redux/services/scheduled-record';
import { getSchedulerOptions, transformSchedulerPatchData } from './scheduler-utils';
import { ScheduleMutationResponse, SchedulerRecord, SchedulerRecordPatch } from '../../../@types/scheduler-record';
import SchedulerMenu from './scheduler-menu.component';
import usePerformingDeleteToast from '../../../hooks/use-performing-delete-toast';
import { setActiveRecordReference } from '../../../services/active-record.service';
import { clearActiveRecordId } from '../../../redux/slices/layout';
import CloseActiveItemButton from '../../common/close-active-item-button';
import { useFormSettingsQuery, useLazyFormSettingsQuery } from '../../../redux/services/api';
import { useUserAccess } from '../../../hooks/use-user-access';

export interface SchedulerDetailsProps {
  schedulerId: number;
  title: string;
  isNewScheduler?: boolean;
  onClose: (id: string) => void;
  panelSettings?: BehaviorSubject<ActiveRecordContextProps>;
  onCloseActiveRecordConfirmationModalRef?: ModalDialogRef | null;
}
interface SchedulerDetailsRef {
  submitForm?: () => void;
}

const formButtons: Array<DynamicFormButtonSetting> = [
  {
    name: 'save',
    text: i18next.t('save'),
    htmlType: 'submit',
    type: 'primary',
    variant: 'alternative',
  },
];

const SchedulerDetails = (props: SchedulerDetailsProps, ref: Ref<SchedulerDetailsRef>) => {
  const {
    schedulerId,
    title,
    isNewScheduler = false,
    onClose,
    panelSettings,
    onCloseActiveRecordConfirmationModalRef,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [scheduler, setScheduler] = useState<SchedulerRecord>();
  const [panelContextProps, setPanelContextProps] = useState<ActiveRecordContextProps>();
  const [validateUserAssignment, setValidateUserAssignment] = useState<boolean>(false);
  const [formSettings, setFormSettings] = useState<DynamicFormSettings>();

  const dynamicFormReference = useRef<DynamicFormRef>(null);
  const saveModalReference = useRef<ModalDialogRef>(null);
  const deleteModalReference = useRef<ModalDialogRef>(null);
  const dataGridRef = useRef<DataGridRef>();
  const destroySubject = useRef(new Subject<void>());
  const dynamicFormId = useRef(v4());
  const closeOnModalSaveRef = useRef(false);

  const { profileData: { user: userData } = {} } = useProfileData();

  const { readOnly, editAccess } = useUserAccess({
    initialAccess: isNewScheduler,
    userData,
    permissions: {
      MODIFY: [SchedulePagePermissions.SCHEDULES_CREATE, SchedulePagePermissions.SCHEDULES_MODIFY],
      READ: SchedulePagePermissions.SCHEDULES_READ,
    },
  });

  const { data, isLoading, isFetching } = useGetScheduleByIdQuery(schedulerId, {
    skip: schedulerId === undefined,
  });
  const { data: formSettingsData, isFetching: isFormDataFetching } = useFormSettingsQuery(data?.moduleFormId ?? 0, {
    skip: data?.moduleFormId === undefined,
  });

  const [getFormSettings] = useLazyFormSettingsQuery();
  const [addSchedule] = useAddScheduleRecordMutation();
  const [updateSchedule] = useUpdateScheduleRecordMutation();
  const [deleteSchedule] = useDeleteScheduleRecordMutation();

  const { notifyDirty, notifySaveSucceeded, notifySaveFailed } = useDynamicFormNotifier(dynamicFormId.current);

  const { showPerformingSaveToast, hidePerformingSaveToast } = usePerformingSaveToast({
    shouldFreezeActiveRecord: true,
    disabled: panelContextProps?.batchSaveInProgress,
  });
  const { showPerformingDeleteToast, hidePerformingDeleteToast } = usePerformingDeleteToast({
    shouldFreezeActiveRecord: true,
  });

  const initialiseFormSettings = useCallback(async () => {
    if (formSettingsData && !isFormDataFetching) {
      const formFieldsFlat = formSettingsData?.fields.map((field) => field.fields).flat();

      const reverseRecordLinkFields = formFieldsFlat.filter(
        (field) => (field?.fieldType as string) === 'REVERSE_RECORD_LINK'
      );

      const formIntFields = formFieldsFlat
        .filter((field) => (field?.fieldType as string) === 'INTFIELD')
        .map((intField) => ({ value: intField?.id, text: intField?.caption }));

      if (reverseRecordLinkFields.length > 0) {
        const formsResult = await Promise.all(
          reverseRecordLinkFields
            .flatMap((field) => {
              const targetModuleId = field?.dynamicProperties?.targetModuleFormId as number;
              return isNaN(targetModuleId) ? [] : targetModuleId;
            })
            .map((moduleId) => getFormSettings(moduleId, true))
        );

        for (let i = 0; i < formsResult.length; i++) {
          const reverseRecordLinkFiled = formsResult[i].data;
          if (reverseRecordLinkFiled) {
            formIntFields.push(
              ...reverseRecordLinkFiled?.fields
                ?.map((field) => field.fields)
                .flat()
                .filter((field) => (field?.fieldType as string) === 'INTFIELD')
                .map((intField) => {
                  return {
                    value: intField?.id,
                    text: `${reverseRecordLinkFields[i]?.caption} / ${intField?.caption}`,
                  };
                })
            );
          }
        }
      }

      const formSettingsConfiguration = getSchedulerFormSettings(
        cloneDeep(formSettingsData),
        formIntFields,
        validateUserAssignment,
        readOnly
      );
      setFormSettings(formSettingsConfiguration);
    }
  }, [formSettingsData, validateUserAssignment, readOnly, isFormDataFetching]);

  useEffect(() => {
    initialiseFormSettings();
  }, [formSettingsData, isFormDataFetching, readOnly, validateUserAssignment]);

  useEffect(() => {
    if (data && !isFetching) {
      const cloneData = cloneDeep(data);

      setScheduler(cloneData);
      if (
        cloneData.userAssignment?.targetField?.id &&
        !cloneData.userAssignment?.users &&
        !cloneData.userAssignment?.groups
      ) {
        setValidateUserAssignment(true);
      }
    }
  }, [data, isFetching]);

  useEffect(() => {
    panelSettings?.pipe(takeUntil(destroySubject.current)).subscribe((settings) => {
      setPanelContextProps(settings);
    });

    onCloseActiveRecordConfirmationModalRef
      ?.subscribeToDialogResult()
      ?.pipe(takeUntil(destroySubject.current))
      .subscribe((result) => {
        if (result.buttonName === 'save' && dynamicFormReference.current?.formIsDirty()) {
          dynamicFormReference.current?.submitForm(true);
        } else if (result.buttonName === 'discard') {
          onClose(dynamicFormId.current);
        }
      });

    getDataGridReference()
      .pipe(takeUntil(destroySubject.current))
      .subscribe((gridRef) => {
        if (gridRef) {
          dataGridRef.current = gridRef;
        }
      });

    return () => {
      destroySubject.current.next();
      destroySubject.current.complete();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    submitForm: dynamicFormReference.current?.submitForm,
  }));

  const showSaveToast = () => {
    if (!panelContextProps?.batchSaveInProgress) {
      showSuccess(t('record-saved'));
    }
    notifySaveSucceeded(dynamicFormId.current);
    closeOnModalSaveRef.current = true;
    if (!schedulerId || closeOnModalSaveRef.current) {
      onClose(dynamicFormId.current);
    }
  };

  const showErrorNotificationToast = () => {
    showError(t('scheduled-details-save-failed'));
    notifySaveFailed(dynamicFormId.current);
  };

  const createSchedule = useCallback((data: Partial<SchedulerRecordPatch>) => {
    persistScheduleData(data, (response) => {
      const convertedResponse = forceAssert<OccupationMutationResponse>(response);
      if (convertedResponse?.data?.result) {
        showSaveToast();
        refreshGrid();
      } else if (convertedResponse?.error) {
        hidePerformingSaveToast();
        showErrorNotificationToast();
      }
    });
  }, []);

  const getTransformedPatchData = useCallback((data: JsonDataItem) => {
    const dirtyFields = dynamicFormReference.current?.formDirtyFields() || {};
    const patchData = getChangedFieldsData(forceAssert<FormChangedFields<SchedulerRecord>>(dirtyFields), data);
    return transformSchedulerPatchData(patchData);
  }, []);

  const onFormSubmit = (data: JsonDataItem) => {
    if (schedulerId && scheduler) {
      const patchData = getTransformedPatchData(data);

      persistScheduleData({ ...patchData, id: Number(schedulerId) }, (response) => {
        const convertedResponse = forceAssert<OccupationMutationResponse>(response);
        if (convertedResponse?.data?.result) {
          dataGridRef.current?.api.data.recordChanged(schedulerId);
          refreshGrid();
          hidePerformingSaveToast();
          showSaveToast();
        } else if (convertedResponse?.error) {
          hidePerformingSaveToast();
          showErrorNotificationToast();
        }
      });
    } else {
      createSchedule(data);
    }
  };

  const refreshGrid = () => {
    dataGridRef.current?.api.data.getDataOverwritePageCache();
  };

  const persistScheduleData = (
    scheduleData: Partial<SchedulerRecordPatch>,
    successCallback: (response?: unknown) => void
  ) => {
    showPerformingSaveToast();
    if (scheduleData.id) {
      updateSchedule(scheduleData)
        .then(successCallback)
        .catch(() => {
          showErrorNotificationToast();
        });
    } else {
      addSchedule(scheduleData)
        .then(successCallback)
        .catch(() => {
          showErrorNotificationToast();
        });
    }
  };

  const onCloseScheduleDetails = () => {
    if (!dynamicFormReference.current?.formIsDirty()) {
      onClose(dynamicFormId.current);
    } else {
      saveModalReference.current
        ?.show()
        ?.pipe(take(1))
        .subscribe((result) => {
          if ('save' === result.buttonName) {
            const data = dynamicFormReference.current?.getData() || {};
            if (schedulerId) {
              const patchData = getTransformedPatchData(data);

              persistScheduleData({ ...patchData, id: Number(schedulerId) }, (response) => {
                const convertedResponse = forceAssert<OccupationMutationResponse>(response);
                if (convertedResponse?.data?.result) {
                  dataGridRef.current?.api.data.recordChanged(schedulerId);
                  refreshGrid();
                  hidePerformingSaveToast();
                  showSaveToast();
                } else if (convertedResponse?.error) {
                  hidePerformingSaveToast();
                  showErrorNotificationToast();
                }
              });
            } else if (!schedulerId) {
              createSchedule(data);
            }
          } else if ('discard' === result.buttonName) {
            onClose(dynamicFormId.current);
          }
        });
    }
  };

  const dataRetrieval: OdinDataRetrieval = useMemo(() => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if ('COMBOBOX' === options?.fieldType || 'OPTIONGROUP' === options?.fieldType) {
          options.customProperties = {
            ...options.customProperties,
            moduleFormId: scheduler?.moduleFormId,
            formId: scheduler?.moduleFormId,
          };
          await getSchedulerOptions(subscriber, options, dispatch);
        } else {
          subscriber.sendData();
        }
      },
    };
  }, [scheduler]);

  const onFieldChanged = useCallback(
    (fieldId: Key, fieldName: string, fieldType: DynamicFormFieldType, fieldValue: unknown) => {
      const dynamicFormData = dynamicFormReference.current?.getData();

      if (fieldId === 'targetField') {
        const { groups, users } = dynamicFormData || {};
        setValidateUserAssignment(Boolean(!groups && !users && Boolean((fieldValue as DropDownResult).value)));
      } else if (fieldId === 'users' || fieldId === 'groups') {
        const targetFieldData = dynamicFormData?.targetField as DropDownResult;
        const otherField = fieldName === 'users' ? 'groups' : 'users';
        const otherFieldItemsCount = (dynamicFormData?.[otherField] as JsonDataItem)?.length || 0;
        const hasFieldValue = Array.isArray(fieldValue) && fieldValue.length > 0;
        const nextState = Boolean(targetFieldData?.value) && !(hasFieldValue || otherFieldItemsCount);

        setValidateUserAssignment(Boolean(nextState));
      }
      notifyDirty(dynamicFormReference.current);
    },
    [dynamicFormReference]
  );

  const onDeleteSchedule = () => {
    deleteModalReference.current
      ?.show()
      ?.pipe(take(1))
      .subscribe((result) => {
        if ('delete' === result.buttonName) {
          if (schedulerId) {
            showPerformingDeleteToast();
            deleteSchedule(schedulerId)
              .then((response) => {
                const convertedResponse = forceAssert<ScheduleMutationResponse>(response);
                if (convertedResponse?.data?.result) {
                  hidePerformingDeleteToast();
                  showSuccess(t('delete-successful-message'));
                  onClose(dynamicFormId.current);
                  setActiveRecordReference(null);
                  dispatch(clearActiveRecordId());

                  dispatch(
                    scheduledRecordApi.util.invalidateTags([
                      { type: 'Schedule', id: `LIST-${scheduler?.moduleFormId}` },
                      { type: 'Schedule', id: `${schedulerId}` },
                    ])
                  );
                } else if (convertedResponse?.error) {
                  hidePerformingDeleteToast();
                  showError(t('record-delete-failed'));
                }
              })
              .finally(() => {
                refreshGrid();
              });
          }
        }
      });
  };

  return (
    <>
      <div className="flex flex-shrink-0 flex-col">
        <div className="flex">
          <FormTitle title={title} />
          {!isLoading && (
            <div className="flex min-w-min justify-end">
              <SchedulerMenu
                onDeleteSchedule={onDeleteSchedule}
                isAdmin={editAccess}
                canModify={editAccess}
                fullScreen={panelContextProps?.fullScreen}
              />
              <CloseActiveItemButton onClick={onCloseScheduleDetails} />
            </div>
          )}
        </div>
      </div>
      <div className="admin-form-default">
        {!formSettings && <FormLoading />}
        {formSettings && (
          <DynamicForm
            ref={dynamicFormReference}
            dynamicFormId={dynamicFormId.current}
            data={scheduler ?? {}}
            settings={formSettings}
            dataRetrieval={dataRetrieval}
            buttons={formButtons}
            buttonsLocation={0}
            readOnly={readOnly}
            showSubmitButtons={!readOnly}
            buttonsPosition={1}
            onSubmit={onFormSubmit}
            onFieldChanged={onFieldChanged}
          />
        )}
      </div>
      <ModalDialog ref={saveModalReference} header={t('save-unsaved-changes')} buttons={saveModalButtons}>
        <div>{t('save-message')}</div>
      </ModalDialog>
      <ModalDialog ref={deleteModalReference} header={t('scheduled-delete-confirmation')} buttons={[deleteButton]}>
        <div>{t('scheduled-delete')}</div>
      </ModalDialog>
    </>
  );
};
export default forwardRef(SchedulerDetails);
