import React from 'react';
import { Control } from 'react-hook-form';
import { OdinController } from '@myosh/odin-components';
import Questionnaire, { QuestionnaireProps } from './questionnaire.component';

interface HfQuestionnaireProps extends QuestionnaireProps {
  control?: Control;
  name?: string;
}

export default function HfQuestionnaire({
  control,
  name = 'questionaire',
  label,
  value,
  configuration,
  readOnly,
  required,
  onChange,
  id,
  recordId,
  registerPreSubmitHandler,
  registerPostSubmitHandler,
  saveRecord,
}: HfQuestionnaireProps) {
  return (
    <OdinController
      name={name}
      control={control}
      defaultValue={value}
      onChange={onChange}
      render={({ field: { onChange, value }, fieldState: { error }, formState: { submitCount } }) => {
        return (
          <Questionnaire
            id={id}
            label={label}
            value={value}
            errorObject={error}
            onChange={onChange}
            configuration={configuration}
            readOnly={readOnly}
            required={required}
            recordId={recordId}
            registerPreSubmitHandler={registerPreSubmitHandler}
            registerPostSubmitHandler={registerPostSubmitHandler}
            saveRecord={saveRecord}
            submitCount={submitCount}
          />
        );
      }}
    />
  );
}
