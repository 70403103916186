import {
  CurrentTimeButton,
  DropDownResult,
  DynamicFieldDataProps,
  DynamicFormField as OdinDynamicFormField,
  DynamicFormField,
  DynamicFormRequiredType,
  DynamicFormSettings as OdinDynamicFormSettings,
  isObject,
  JsonDataItem,
  SearchValueType,
  VisibilityCondition,
  DataGridColumnSettings,
  OptionWithOptionalProperties,
} from '@myosh/odin-components';
import i18next from '../i18n';
import { cloneDeep, orderBy, isEmpty } from 'lodash';
import { AuditLogData } from '../@types/audit';
import { Caption, FieldFilters, FieldSorts, ValueTextType } from '../@types/common';
import {
  CombinedFieldType,
  DynamicFormAccessRights,
  DynamicFormHierarchyType,
  DynamicFormKeywords,
  DynamicFormPermissions,
  DynamicFormSettings,
  DynamicFormWorkflowStep,
  ExtendedDynamicFormField,
  HierarchyAccessRestrictionIgnoreModeType,
  QuestionRecordListFieldType,
} from '../@types/forms';
import { FormHierarchyTypes, TransformedFormHierarchyTypes } from '../@types/hierarchy-fields';
import { ModuleForm, ModuleForms, Modules } from '../@types/modules';
import { NewsInfo, NewsResult } from '../@types/news';
import {
  DesirableCompetencies,
  HierarchyFields,
  MandatoryCompetencies,
  OccupationalRequirementsItem,
} from '../@types/occupational-requirements';
import { ExportRecordsQueryProps, RecordResult, RecordStructure } from '../@types/records';
import { UserApiProps } from '../@types/users';
import { AddonsValidation, ViewConfigDateRange, ViewConfigsWrapper } from '../@types/views';
import { NewsEntryProps } from '../components/home-page/news-entry.component';
import { Buffer } from 'buffer';

// FIXME: This utility needs to be broken up and the various methods organised
export interface ExtendedDynamicFormSettings extends OdinDynamicFormSettings {
  workflowSteps?: Array<ExtendedDynamicFormWorkflowStep>;
  initialStep?: DynamicFormWorkflowStep;
  hierarchySettings?: ExtendedDynamicFormHierarchySettings;
  dateRange?: ViewConfigDateRange;
  displayRecordListColour?: boolean;
  accessRights?: Record<string, Array<DynamicFormAccessRights>>;
  hideFirstColumn?: boolean;
  keywords?: Array<DynamicFormKeywords>;
  questionRecordListFields?: Array<QuestionRecordListFieldType>;
  versionType?: number;
}

export interface ExtendedDynamicFormWorkflowStep {
  id: number;
  label: string;
  draft: boolean;
  rolesPermissions: Array<DynamicFormPermissions>;
  nextStepVisibilities?: Array<VisibilityCondition>;
  nextSteps?: Array<ExtendedDynamicFormNextWorkflowStep>;
  backgroundColor?: string;
  hideRecordSaveButton: boolean;
  editModeByDefault: boolean;
}

export interface ExtendedDynamicFormNextWorkflowStep {
  id: number;
  label: string;
  archivedStep: boolean;
  buttonCaption?: string;
  hidden?: boolean;
  visibilityConditions?: Array<VisibilityCondition>;
  closeOnSave?: boolean;
  skipMandatoryFieldValidation?: boolean;
  promptBeforeAdvance?: boolean;
  submissionBehaviour?: string;
  navigationUrl?: string;
}

export interface ExtendedDynamicFormHierarchySettings {
  hiddenInReadMode?: boolean;
  isExpanded?: boolean;
  autoPopulateFromUserHierarchy?: boolean;
  hierarchyTypes?: Array<DynamicFormHierarchyType>;
  hierarchyAccessRestrictionIgnoreMode?: HierarchyAccessRestrictionIgnoreModeType;
  autoPopulateHierarchyFromPersonFieldId?: number;
}

interface FilterTextProps {
  filters?: FieldFilters;
  isFirstParam?: boolean;
  isAllForms?: boolean;
  captionValuesBase64encoded?: boolean;
}

export interface FilterCustomProperties {
  entityId: number;
  entityType: string;
  entityPropertyName: string;
  linkedFieldId?: number;
  linkedEntityType?: string;
  grandScoreFieldItemType?: string;
  questionRecordListFieldTypes?: string;
  ignoreBlankFilter?: boolean;
}

/**
 * This string version regular expression is used to validate user input by only alowing the user to enter Full numbers including negative ones
 * */
export const intNumberPattern = '^-?[0-9]*$';

/**
 * This string version regular expression is used to validate user input by only alowing the user to enter a number of maximum length of 10
 * */
const maxNumberLengthPattern = '^.{0,10}$';

/**
 * This string version regular expression is used to validate user input by only alowing the user to enter:
 * Full numbers, Double numbers, Empty string, Negative Full or Double numbers,
 * Does not allow any type of letters or special character,
 * Does not allow the number to start with "." eg ".47".
 * */
const doubleNumberPattern = '^(?:$|-?\\d*(?:\\.\\d*)?|\\.\\d+)$';

function getTopLevelProperties(object: Record<string, unknown>) {
  const properties: Record<string, unknown> = {};
  const keys = Object.keys(object);

  for (let i = 0, length = keys.length; i < length; i++) {
    if (object.hasOwnProperty(keys[i])) {
      properties[keys[i]] = object[keys[i]];
    }
  }

  return properties;
}

function toDropDownResult(originalField: unknown): DropDownResult | OptionWithOptionalProperties {
  let result: DropDownResult | OptionWithOptionalProperties = forceAssert<
    DropDownResult | OptionWithOptionalProperties
  >(originalField);
  if (
    isObject(originalField) &&
    originalField.hasOwnProperty('id') &&
    originalField.hasOwnProperty('value') &&
    originalField.hasOwnProperty('position') &&
    originalField.hasOwnProperty('archivedOption') &&
    originalField.hasOwnProperty('defaultOption')
  ) {
    result = {
      value: originalField.id,
      text: originalField.value as string,
      originalData: originalField,
      position: originalField.position as number,
      archivedOption: originalField.archivedOption as boolean,
      defaultOption: originalField.defaultOption as boolean,
    };
  } else if (isObject(originalField) && originalField.hasOwnProperty('id') && originalField.hasOwnProperty('value')) {
    result = {
      value: originalField.id,
      text: originalField.value as string,
      originalData: originalField,
    };
  } else if (isObject(originalField) && originalField.hasOwnProperty('id') && originalField.hasOwnProperty('name')) {
    // special format for TRAINING field types
    result = {
      value: originalField.id,
      text: originalField.name as string,
      originalData: originalField,
    };
  }
  return result;
}

export function createRecordData(item: RecordResult) {
  const dataItem: RecordStructure = {
    original: {
      ...cloneDeep(item),
    },
    flat: {},
  };

  const clonedItem = cloneDeep(item);
  const fieldKeys = Object.keys(clonedItem.fields);
  for (let i = 0, length = fieldKeys.length; i < length; i++) {
    const originalField = clonedItem.fields[fieldKeys[i]];
    if (typeof originalField === 'object') {
      if (Array.isArray(originalField)) {
        for (let j = 0; j < originalField.length; j++) {
          forceAssert<Array<unknown>>(clonedItem.fields[fieldKeys[i]])[j] = toDropDownResult(originalField[j]);
        }
      } else {
        clonedItem.fields[fieldKeys[i]] = toDropDownResult(originalField);
      }
    }
  }

  dataItem.flat = {
    ...clonedItem.fields,
    ...getTopLevelProperties(forceAssert<Record<string, unknown>>(clonedItem)),
  };

  return dataItem;
}

export function processWorkflowSteps(steps: Array<DynamicFormWorkflowStep>) {
  const convertedWorkflowSteps: Array<ExtendedDynamicFormWorkflowStep> = [];

  for (let i = 0, length = steps.length; i < length; i++) {
    const currentStep = steps[i];

    //FIXME: Once the API provides us with the users translation change this code
    convertedWorkflowSteps.push({
      id: currentStep.id,
      label: currentStep.label.translations[0].value,
      draft: currentStep.draft || false,
      rolesPermissions: currentStep.rolesPermissions,
      editModeByDefault: currentStep.editModeByDefault,
      hideRecordSaveButton: currentStep.hideRecordSaveButton,
      nextSteps: currentStep.nextSteps?.map((nextStep) => {
        //FIXME: Once the API provides us with the users translation change this code
        return {
          id: nextStep.id,
          label: nextStep.label.translations[0].value,
          archivedStep: nextStep.archivedStep,
          buttonCaption: nextStep.buttonCaption?.translations[0].value,
          visibilityConditions: nextStep.nextStepVisibilities,
          closeOnSave: nextStep.closeOnSave,
          promptBeforeAdvance: nextStep.promptBeforeAdvance,
          skipMandatoryFieldValidation: nextStep.skipMandatoryFieldValidation,
          submissionBehaviour: nextStep.submissionBehaviour,
          navigationUrl: nextStep.navigationUrl,
        };
      }),
      backgroundColor: currentStep.backgroundColor,
    });
  }

  return convertedWorkflowSteps;
}

function getDisplayType(displayType: 'EXPAND' | 'COLLAPSE'): boolean {
  return displayType !== 'COLLAPSE';
}

export const dataProps: DynamicFieldDataProps = {
  valueField: 'id',
  textField: 'caption',
  archivedField: 'archived',
};

export function settingsToDynamicForm(settings: DynamicFormSettings) {
  //FIXME: Once the API provides us with the users translation change the caption line
  const formSettings: ExtendedDynamicFormSettings = {
    id: settings.id,
    caption: settings.formCaption.translations[0].value,
    fields: [],
    globalProperties: settings.globalProperties ?? {},
    visibilityConditions: [],
    initialStep: settings?.initialStep,
    hierarchySettings: {
      hierarchyTypes: transformAndSortDynamicFormHierarchyTypes(
        settings?.hierarchyTypes
      ) as Array<TransformedFormHierarchyTypes>,
      isExpanded: getDisplayType(settings.hierarchyDisplayType ?? 'EXPAND'),
      hiddenInReadMode: settings.hierarchyHiddenInReadMode,
      autoPopulateFromUserHierarchy: settings.autoPopulateHierarchyFromUserHierarchy,
      autoPopulateHierarchyFromPersonFieldId: settings.autoPopulateHierarchyFromPersonFieldId,
      hierarchyAccessRestrictionIgnoreMode: settings.hierarchyAccessRestrictionIgnoreMode,
    },
    dateRange: settings.dateRange,
    displayRecordListColour: settings.displayRecordListColour,
    accessRights: {},
    hideFirstColumn: settings.hideFirstColumn,
    keywords: settings.keywords,
    questionRecordListFields: settings.questionRecordListFields,
    versionType: settings.versionType,
  };

  for (let i = 0, length = settings.fieldGroups.length; i < length; i++) {
    const fieldGroup = settings.fieldGroups[i];

    if (fieldGroup.sectionVisibilities) {
      formSettings.visibilityConditions?.push({
        id: fieldGroup.id,
        type: 'GROUP',
        groups: [
          {
            conditions: fieldGroup.sectionVisibilities,
          },
        ],
      });
    }

    if (fieldGroup.accessRights && formSettings.accessRights) {
      formSettings.accessRights[fieldGroup.id] = fieldGroup.accessRights;
    }

    //FIXME: Once the API provides us with the users translation change this code
    formSettings.fields.push({
      id: fieldGroup.id,
      caption: fieldGroup.caption.translations[0].value,
      fieldName: String(fieldGroup.id),
      fieldType: 'GROUP',
      position: i,
      startOpen: getDisplayType(fieldGroup.displayType),
      fields: [],
    });
    const groupField = formSettings.fields[i];

    if (fieldGroup.fields) {
      for (let j = 0, fLength = fieldGroup.fields.length; j < fLength; j++) {
        const fieldGroupField: ExtendedDynamicFormField = fieldGroup.fields[j] as ExtendedDynamicFormField;
        //FIXME: Once the API provides us with the users translation change the caption, helpMessage and hintMessage code
        const field: OdinDynamicFormField = {
          id: fieldGroupField.id,
          parentFieldId: fieldGroupField.parentFieldId?.toString(),
          caption: fieldGroupField.fieldCaption.translations[0].value,
          fieldName: String(fieldGroupField.id),
          fieldType: fieldGroupField.fieldType,
          position: fieldGroupField.position,
          dataSettings: dataProps,
          required: fieldGroupField.required ? DynamicFormRequiredType.True : DynamicFormRequiredType.False,
          helpMessage: fieldGroupField?.help?.translations[0]?.value,
          hintMessage: fieldGroupField?.hint?.translations[0]?.value,
          importEnabled: fieldGroupField?.importEnabled,
        };

        const fieldType: CombinedFieldType = fieldGroupField.fieldType as CombinedFieldType;

        if ('LABEL' === fieldType && fieldGroupField.fieldData && !isEmpty(fieldGroupField.fieldData.translations)) {
          field.fieldData = fieldGroupField.fieldData.translations[0].value ?? '';
        }

        if ('OPTION_BUILDER' === fieldType) {
          field.dataSettings = {
            textField: 'text',
            valueField: 'value',
          };
        }

        if ('OPTIONGROUP' === fieldType && fieldGroupField.optionGroupOrientation) {
          field.dynamicProperties = { orientation: fieldGroupField.optionGroupOrientation };
          // modify type to the 'record' specific optiongroup
          (field.fieldType as unknown as CombinedFieldType) = 'RECORD_OPTIONGROUP';
        }

        if (
          ('RECORDLINK' === fieldType || 'USER_RECORDLINK' === fieldType || 'REVERSE_RECORD_LINK' === fieldType) &&
          fieldGroupField.recordLinkFormat &&
          !(fieldGroupField.questions || fieldGroupField.answers)
        ) {
          //FIXME: Once the API provides us with the users translation change the buttonCaption code
          field.dynamicProperties = {
            autoPopulateEnabled: fieldGroupField.autoPopulateEnabled,
            recordLinkFormat: fieldGroupField.recordLinkFormat,
            buttonCaption: fieldGroupField.buttonCaption?.translations[0].value,
            targetModuleFormId: fieldGroupField.targetModuleFormId,
            targetModuleId: fieldGroupField.targetModuleId,
            showSelectButton: fieldGroupField.showSelectButton,
            createExternalUsers: fieldGroupField.createExternalUsers,
            recordLinkRecordCreationDisabled: fieldGroupField.recordLinkRecordCreationDisabled,
            recordLinkType: fieldGroupField.recordLinkType,
            sourceModuleFormId: fieldGroupField.sourceModuleFormId,
            sourceModuleId: fieldGroupField.sourceModuleId,
          };
        }

        if ('SUMMARY_RECORD_LINK' === fieldType && fieldGroupField.fieldsToInclude) {
          field.dynamicProperties = {
            fieldsToInclude: fieldGroupField.fieldsToInclude,
          };
        }

        if ('COMBOBOX' === fieldType) {
          field.dynamicProperties = {
            deselectValue: {
              [dataProps.valueField]: 0,
              [dataProps.textField]: '',
            },
            showAddNewOption: fieldGroupField.allowNewValue,
          };

          // modify type to the 'record' specific optiongroup
          (field.fieldType as unknown as CombinedFieldType) = 'RECORD_COMBOBOX';
        }

        if ('PERSONFIELD' === fieldType) {
          field.dynamicProperties = {
            defaultFieldConfiguration: fieldGroupField.defaultFieldConfiguration,
          };
        }

        if ('READERS' === fieldType) {
          field.dynamicProperties = {
            readersExemptGroups: fieldGroupField.readersExemptGroups,
          };
        }

        if ('TEXTAREA' === fieldGroupField.fieldType) {
          field.dynamicProperties = {
            rows: fieldGroupField?.numberOfLines,
          };
        }

        if ('DATEFIELD' === fieldType || 'TIMEFIELD' === fieldType) {
          //"defaultValue": "[DATEFIELD+COMPETENCY]"
          if (fieldGroupField.defaultFieldConfiguration?.defaultValue === '[DATEFIELD+COMPETENCY]') {
            field.fieldType = 'DATEFIELD_COMPETENCY';
          }

          field.dynamicProperties = {
            currentTimeBtn: { caption: i18next.t('now') } as CurrentTimeButton,
            defaultFieldConfiguration: fieldGroupField.defaultFieldConfiguration,
            fieldValidations: fieldGroupField.fieldValidations?.[0],
            ignoreUserTimezone: fieldGroupField.ignoreUserTimezone,
          };
        }
        if ('INTFIELD' === fieldType) {
          field.dynamicProperties = {
            validations: [
              {
                pattern: intNumberPattern,
                errorLabel: i18next.t('integer-validation-message'),
              },
              {
                pattern: maxNumberLengthPattern,
                errorLabel: i18next.t('integer-max-length-message', { maxLength: 10 }),
              },
            ],
          };
        }

        if ('DOUBLEFIELD' === fieldType) {
          field.dynamicProperties = {
            validations: [
              {
                pattern: doubleNumberPattern,
                errorLabel: i18next.t('number-validation-message'),
              },
            ],
          };
        }

        if (
          ('TEXTUAL_QUESTIONNAIRE' === fieldType || 'NUMERIC_QUESTIONNAIRE' === fieldType) &&
          fieldGroupField.questions &&
          fieldGroupField.answers
        ) {
          delete field.dataSettings;

          // FIXME: Make questions a real type instead of Record<string, unknown>, also remove the forceAssert in the sort
          const questions = fieldGroupField.questions
            .sort(
              (question1, question2) =>
                forceAssert<{ position: number }>(question1).position -
                forceAssert<{ position: number }>(question2).position
            )
            .map((question) => {
              //FIXME: Once the API provides us with the users translation change this code
              return {
                ...question,
                caption: (question.caption as Caption)?.translations[0].value,
              };
            });

          // FIXME: Make answers a real type instead of Record<string, unknown>, also remove the forceAssert in the sort
          const answers = fieldGroupField.answers
            .sort(
              (answer1, answer2) =>
                forceAssert<{ position: number }>(answer1).position -
                forceAssert<{ position: number }>(answer2).position
            )
            .map((answer) => {
              //FIXME: Once the API provides us with the users translation change this code
              return {
                ...answer,
                caption: answer.caption ? (answer.caption as Caption).translations[0].value : '',
              };
            });

          const isFieldPartiallyRequired = fieldGroupField.fieldValidations
            ? DynamicFormRequiredType.Partial
            : DynamicFormRequiredType.False;

          field.required = fieldGroupField.required ? DynamicFormRequiredType.True : isFieldPartiallyRequired;

          field.dynamicProperties = {
            configuration: {
              id: field.id,
              questions,
              answers,
              allowAttachments: fieldGroupField.allowAttachmentsForQuestions,
              allowRecordLinks: fieldGroupField.allowRecordLinksForQuestions,
              allowObservations: fieldGroupField.allowTextInputWhileAnsweringQuestions,
              displayScores: fieldGroupField.displayScores,
              showFieldCaption: fieldGroupField.showFieldCaption,
              fieldValidations: fieldGroupField.fieldValidations,
              required: field.required,
              type: fieldType,
            },
          };

          if (fieldGroupField.recordLinkFormat) {
            //FIXME: Once the API provides us with the users translation change the buttonCaption code
            field.dynamicProperties.configuration = {
              ...(field.dynamicProperties.configuration as JsonDataItem),
              recordLink: {
                // configuration for the RECORDLINK field as part of the questionnaire field type
                recordLinkFormat: fieldGroupField.recordLinkFormat,
                buttonCaption: fieldGroupField.buttonCaption?.translations[0].value,
                targetModuleFormId: fieldGroupField.targetModuleFormId,
                showSelectButton: fieldGroupField.showSelectButton,
              },
            };
          }
        }

        if ('RISKRATING' === fieldType && fieldGroupField.riskRatingValueDisplay) {
          field.dynamicProperties = {
            riskMatrixId: fieldGroupField.riskMatrixId,
            displayDescription: Boolean(fieldGroupField.displayDescription),
            valueDisplayConfig: fieldGroupField.riskRatingValueDisplay,
          };
        }

        if ('INVITE_BUTTON' === fieldType) {
          //fieldCaption.translations[0].value had no value, so if fieldName is not found add fieldname
          field.fieldName = field.fieldName ?? 'invite_button';
          //FIXME: Once the API provides us with the users translation change this code
          field.dynamicProperties = {
            buttonCaption: fieldGroupField.buttonCaption?.translations[0].value,
          };
          formSettings.globalProperties = { ...formSettings.globalProperties, invitationFieldId: field.id };
        }

        if ('EMAIL_THIS_BUTTON' === fieldType) {
          field.fieldName = field.fieldName ?? 'email_this_button';
          field.dynamicProperties = {
            //TODO Endpoint /forms/simple/{formId} currently returns the buttonCaption as a string
            //If we use /forms/simple/{formId} instead will not need to use translations array
            //same for hint
            buttonCaption: fieldGroupField.buttonCaption?.translations[0].value,
            defaultFieldConfiguration: fieldGroupField.defaultFieldConfiguration,
            hint: fieldGroupField.hint?.translations[0].value,
            keepEmailAudit: Boolean(fieldGroupField.keepEmailAudit),
            includeAttachment: Boolean(fieldGroupField.includeAttachment),
            attachGeneratedDoc: Boolean(fieldGroupField.attachGeneratedDoc),
            emailThisDefaultEmailSubject: fieldGroupField.emailThisDefaultEmailSubject,
            emailThisDefaultEmailBody: fieldGroupField.emailThisDefaultEmailBody,
          };
          formSettings.globalProperties = { ...formSettings.globalProperties, emailThisFieldId: field.id };
        }

        if ('PDF_EXPORT' === fieldType || 'WORD_EXPORT' === fieldType) {
          //FIXME: Once the API provides us with the users translation change this code
          field.fieldName = field.fieldName ?? fieldGroupField?.fieldCaption?.translations[0].value;
          //FIXME: Once the API provides us with the users translation change the buttonCaption and firstWorkflowStep code
          field.dynamicProperties = {
            buttonCaption: fieldGroupField?.buttonCaption?.translations[0].value,
            fieldType: field.fieldType,
            firstWorkflowStep: settings?.initialStep?.label.translations[0].value,
          };
        }

        if ('CLONERECORD' === fieldType) {
          //FIXME: Once the API provides us with the users translation change this code
          field.fieldName = field.fieldName ?? fieldGroupField?.fieldCaption?.translations[0].value;
          //FIXME: Once the API provides us with the users translation change the buttonCaption and firstWorkflowStep code
          field.dynamicProperties = {
            buttonCaption: fieldGroupField?.buttonCaption?.translations[0].value,
            fieldType: field.fieldType,
            firstWorkflowStep: settings?.initialStep?.label.translations[0].value,
            shouldUseCloneWizzard: fieldGroupField.enableBackgroundCopyingUsingWizard,
            cloneWizardTargetPersonField: fieldGroupField.cloneWizardTargetPersonField,
            cloneWizardTargetWorkflowStep: fieldGroupField.cloneWizardTargetWorkflowStep,
            allowUserAttachmentCloning: fieldGroupField.allowUserAttachmentCloning,
            moduleFormCloneTargetId: fieldGroupField.moduleFormCloneTargetId,
          };
        }

        if ('LINK' === fieldType) {
          field.dynamicProperties = {
            target: fieldGroupField?.target,
          };
        }

        if ('MULTISELECTFIELD' === fieldType) {
          field.dynamicProperties = {
            showAddNewOption: fieldGroupField.allowNewValue,
            subType: fieldType,
          };

          // modify type to the 'record' specific optiongroup
          (field.fieldType as unknown as CombinedFieldType) = 'RECORD_MULTISELECTFIELD';
        }

        if ('MULTISELECTCHECKBOX' === fieldType) {
          field.dynamicProperties = {
            multiSelectCheckboxType: fieldGroupField.multiSelectCheckboxType,
          };

          // modify type to the 'record' specific multiselect-checkbox
          (field.fieldType as unknown as CombinedFieldType) = 'RECORD_MULTISELECTCHECKBOX';
        }

        if ('TWINCOLUMNSELECT' === fieldType || 'TWINCOLUMNSELECTPERSONFIELD' === fieldType) {
          field.dynamicProperties = {
            ...field.dynamicProperties,
            showAddNewOption: fieldGroupField.allowNewValue,
            subType: fieldType,
          };
          (field.fieldType as unknown as CombinedFieldType) = 'RECORD_TWINCOLUMNSELECT';
        }

        // lookup type information
        if (fieldGroupField.lookupType) {
          field.dynamicProperties = {
            ...field.dynamicProperties,
            lookupType: fieldGroupField.lookupType,
            lookupId: fieldGroupField.lookupId,
            lookupRecordId: fieldGroupField.lookupRecordId,
            lookupKeyId: fieldGroupField.lookupKeyId,
            lookupKeyTargetId: fieldGroupField.lookupKeyTargetId,
            lookupModuleFormId: fieldGroupField.lookupModuleFormId,
            lookupHierarchyId: fieldGroupField.lookupHierarchyId,
          };
        }

        // visibiliy information (needs to be last to ensure correct field type is mapped to condition)
        if (fieldGroupField.fieldVisibilities) {
          formSettings.visibilityConditions?.push({
            id: field.id,
            type: field.fieldType,
            groups: [
              {
                conditions: fieldGroupField.fieldVisibilities,
              },
            ],
          });
        }

        groupField?.fields?.push(field);
      }
    }
  }
  return formSettings;
}

const transformAndSortDynamicFormHierarchyTypes = (hierarchyTypes?: Array<FormHierarchyTypes>) => {
  return hierarchyTypes
    ?.map((hierarchy) => {
      return { ...hierarchy, caption: hierarchy.caption.translations[0].value };
    })
    ?.sort((a, b) => a.weight - b.weight);
};

export function fileToSrc(blob: Blob) {
  return window.URL.createObjectURL(blob);
}

export function isInstanceOfValueTextType(object: unknown): object is ValueTextType {
  return (
    isObject(object) &&
    (typeof object.value === 'number' || typeof object.value === 'string') &&
    typeof object.text === 'string'
  );
}

export function isInstanceOfOptionBuilder(object: unknown): object is OptionWithOptionalProperties {
  return (
    isObject(object) &&
    (typeof object.value === 'number' || typeof object.value === 'string') &&
    typeof object.text === 'string' &&
    typeof object.position === 'number' &&
    typeof object.archivedOption === 'boolean' &&
    typeof object.defaultOption === 'boolean'
  );
}

export function isArrayOfValueTextType(item: unknown): item is Array<ValueTextType> {
  return (
    Array.isArray(item) &&
    (typeof item[0].value === 'number' || typeof item[0].value === 'string') &&
    typeof item[0].text === 'string'
  );
}

export function isArrayOfOptionWithOptionalProperties(item: unknown): item is Array<OptionWithOptionalProperties> {
  return (
    Array.isArray(item) &&
    (typeof item[0].value === 'number' || typeof item[0].value === 'string') &&
    typeof item[0].text === 'string' &&
    typeof item[0].position === 'number' &&
    typeof item[0].archivedOption === 'boolean' &&
    typeof item[0].defaultOption === 'boolean'
  );
}

type FormattingFunction = (filterKey: string, originalValue: unknown) => unknown;

export const dateFormatBasedOnFilter: FormattingFunction = (filterKey, originalValue) => {
  if (originalValue instanceof Date) {
    if (filterKey !== 'creationDate' && filterKey !== 'lastChange') {
      return originalValue.toISOString().replace('Z', '');
    }
  }
};

/**
 * Since JavaScript Date types cannot be serialized we need to convert them to strings, and we use UTC date strings.
 * @param {FieldFilters} filters - The filters to process the dates for.
 * @param {FormattingFunction} formatterFunction - A function that will allow for certain fields to have custom formatting.
 * @returns {FieldFilters} The field filters with the dates converted to UTC.
 */
export function processFieldFilters(filters: FieldFilters = {}, formatterFunction?: FormattingFunction) {
  const processedFilters: FieldFilters = {};
  const filterKeys = Object.keys(filters);
  for (let i = 0, length = filterKeys.length; i < length; i++) {
    const filterValue = filters[filterKeys[i]];
    if (Array.isArray(filterValue.value)) {
      if (filterValue.value.every((item) => item instanceof Date)) {
        const offset = (filterValue.value[0] as Date).getTimezoneOffset();
        processedFilters[filterKeys[i]] = {
          value: filterValue.value
            .map((item) => item as Date)
            .map((date, index) => {
              const correctedDate = new Date(date);
              if (index === 0) {
                correctedDate.setHours(0 - offset / 60, 0, 0, 0);
              } else {
                correctedDate.setHours(23 - offset / 60, 59, 59, 999);
              }
              return formatterFunction
                ? formatterFunction(filterKeys[i], correctedDate) || correctedDate.toISOString()
                : correctedDate.toISOString();
            }) as Array<Date>,
          comparison: filterValue.comparison,
          customDataProperties: filterValue.customDataProperties,
        };
      } else {
        processedFilters[filterKeys[i]] = formatterFunction
          ? {
              value: formatterFunction
                ? (filterValue.value.map(
                    (item) => formatterFunction(filterKeys[i], item) || item
                  ) as Array<SearchValueType>)
                : filterValue.value,
              comparison: filterValue.comparison,
              customDataProperties: filterValue.customDataProperties,
            }
          : filterValue;
      }
    } else if (filterValue.value instanceof Date) {
      const offset = filterValue.value.getTimezoneOffset();
      const correctedDate = new Date(filterValue.value);
      correctedDate.setHours(0 - offset / 60, 0, 0, 0);
      processedFilters[filterKeys[i]] = {
        value: formatterFunction
          ? ((formatterFunction(filterKeys[i], correctedDate) || correctedDate.toISOString()) as SearchValueType)
          : correctedDate.toISOString(),
        comparison: filterValue.comparison,
        customDataProperties: filterValue.customDataProperties,
      };
    } else {
      processedFilters[filterKeys[i]] = formatterFunction
        ? {
            value: (formatterFunction(filterKeys[i], filterValue.value) || filterValue.value) as SearchValueType,
            comparison: filterValue.comparison,
            customDataProperties: filterValue.customDataProperties,
          }
        : filterValue;
    }
  }

  return processedFilters;
}

export function createFieldFilterTextAsFirstQueryParameter(filters?: FieldFilters, isAllForms = false) {
  if (!filters || Object.keys(filters).length === 0) {
    return '';
  }
  return `?${createFieldFilterText({ filters, isFirstParam: true, isAllForms })}`;
}

const fieldFilterFormat = (
  isAllForms: boolean,
  fieldEntityId: number,
  fieldName: string,
  fieldEntityType: string,
  fieldSubType?: string,
  linkedFieldFormat?: string
) => {
  switch (fieldEntityType) {
    case 'FIELD':
      return `field:${fieldEntityId}`;
    case 'KEYWORD':
      return `keyword:${fieldName}`;
    case 'FORM_NAME':
      return 'formId';
    case 'WORKFLOW_STEP':
      return `workflowStep:${fieldEntityId}:transitionDate`;
    case 'HIERARCHY_TYPE':
      return `hierarchy:${fieldEntityId}`;
    case 'HIERARCHY_FIELD':
      return `hierarchyFieldId`;
    case 'RECORD_LINK':
      return fieldSubType === 'HIERARCHY_TYPE'
        ? `RECORDLINK:${fieldEntityId}:HIERARCHY:${linkedFieldFormat}`
        : `RECORDLINK:${fieldEntityId}:${fieldSubType}:${linkedFieldFormat}`;
    case 'REVERSE_RECORD_LINK':
      return fieldSubType === 'HIERARCHY_TYPE'
        ? `REVERSEDRECORDLINK:${fieldEntityId}:HIERARCHY:${linkedFieldFormat}`
        : `REVERSEDRECORDLINK:${fieldEntityId}:${fieldSubType}:${linkedFieldFormat}`;
    case 'QUESTIONNAIRE':
      return `QUESTIONNAIRE:${fieldEntityId}:${fieldSubType}`;
    case 'GRAND_SCORE':
      if (!isAllForms) {
        return `GRANDSCORE:${fieldEntityId}:${fieldSubType}`;
      } else {
        return `field:${fieldEntityId}`;
      }
    default:
      if (fieldName === 'archivedStep' || fieldName === 'lastModifiedDateRange') {
        return `keyword:${fieldName}`;
      } else if (fieldName === 'defaultForm') {
        return `moduleForm:${fieldName}`;
      } else {
        return fieldName;
      }
  }
};

const linkedFieldsFormat = (linkedEntityType?: string, linkedFieldId?: number, fieldEntityPropertyName?: string) => {
  switch (linkedEntityType) {
    case 'FIELD':
      return `${linkedFieldId}`;
    case 'KEYWORD':
      if (fieldEntityPropertyName?.includes('docNo')) {
        return 'docNo';
      } else if (fieldEntityPropertyName?.includes('status')) {
        return 'status';
      } else if (fieldEntityPropertyName?.includes('author')) {
        return 'author';
      } else if (fieldEntityPropertyName?.includes('creationDate')) {
        return 'creationDate';
      } else if (fieldEntityPropertyName?.includes('accountable')) {
        return 'accountable';
      } else if (fieldEntityPropertyName?.includes('version')) {
        return 'version';
      } else if (fieldEntityPropertyName?.includes('linkUrl')) {
        return 'linkUrl';
      } else if (fieldEntityPropertyName?.includes('formName')) {
        return 'formName';
      } else {
        return fieldEntityPropertyName;
      }
    case 'HIERARCHY_TYPE':
      return `${linkedFieldId}`;
    default:
      return fieldEntityPropertyName;
  }
};

// Operators will be updated to the new format on an entity type basis
const filterOperatorFormat = (
  fieldEntityType: string,
  containsBlank = false,
  operatorType: string,
  linkedEntityType?: string
) => {
  if (
    ['FIELD', 'HIERARCHY_TYPE'].includes(fieldEntityType) ||
    (['RECORD_LINK', 'REVERSE_RECORD_LINK'].includes(fieldEntityType) &&
      ['FIELD', 'HIERARCHY_TYPE', 'KEYWORD'].includes(linkedEntityType ?? ''))
  ) {
    return containsBlank ? `${operatorType}:in_with_blank:` : `${operatorType}:in:`;
  } else if (fieldEntityType === 'HIERARCHY_FIELD') {
    return containsBlank ? 'in_with_blank:' : 'in:';
  } else if (fieldEntityType === 'KEYWORD') {
    return containsBlank ? `${operatorType}:in_with_blank:` : `${operatorType}:in:`;
  } else if (operatorType === 'id') {
    // NOTE: this is a deprecated format (https://myosh.atlassian.net/browse/MYOSH-4114)
    if (fieldEntityType === 'ADMIN_FIELD') {
      return containsBlank ? 'id:in_with_blank:' : 'id:in:';
    } else if (fieldEntityType === 'FORM_NAME') {
      return 'in:';
    } else {
      return containsBlank ? 'id_in_with_blank:' : 'id_in:';
    }
  } else if (operatorType === 'caption') {
    if (fieldEntityType === 'ADMIN_FIELD') {
      return containsBlank ? 'in_with_blank:' : 'in:';
    } else {
      return 'in:';
    }
  }
};

export function createFieldFilterText({
  filters,
  isFirstParam = false,
  isAllForms = false,
  captionValuesBase64encoded = false,
}: FilterTextProps) {
  if (!filters || Object.keys(filters).length === 0) {
    return '';
  }
  let filterText = '';
  let valueWasEncoded = false;

  const filterKeys = Object.keys(filters);
  for (let i = 0, length = filterKeys.length; i < length; i++) {
    const fieldName = filterKeys[i];
    const filterComparisonValue = filters[fieldName];
    const { customDataProperties = {} } = filters[fieldName];
    const {
      entityId,
      entityType,
      entityPropertyName,
      linkedFieldId,
      linkedEntityType: linkedFieldSubType,
      grandScoreFieldItemType: grandScoreSubType,
      questionRecordListFieldTypes: questionnaireSubType,
      ignoreBlankFilter,
    } = forceAssert<FilterCustomProperties>(customDataProperties);
    const fieldSubType = grandScoreSubType ?? questionnaireSubType ?? linkedFieldSubType;
    const linkedFieldFormat = linkedFieldsFormat(linkedFieldSubType, linkedFieldId, entityPropertyName);
    const filterFormat = fieldFilterFormat(
      isAllForms,
      entityId,
      fieldName,
      entityType,
      fieldSubType,
      linkedFieldFormat
    );
    if (Array.isArray(filterComparisonValue.value)) {
      const filterValues = ignoreBlankFilter
        ? filterComparisonValue.value.filter((item) => item !== 'blank')
        : filterComparisonValue.value;

      if (filterComparisonValue.comparison === 'between' && filterValues.length === 2) {
        filterText += `&filter=${filterFormat}:gte:${filterValues[0]}&filter=${filterFormat}:lte:${filterValues[1]}`;
      } else if (filterComparisonValue.comparison === 'contains' && filterValues.length > 0) {
        const isOnlyBlankFilter = filterValues.length === 1 && filterValues[0] === 'blank';
        const containsBlank = !isOnlyBlankFilter && filterValues.includes('blank');
        const arrayWithoutBlank = filterValues.filter((value) => value !== 'blank');
        const operatorType = typeof arrayWithoutBlank[0] === 'number' ? 'id' : 'caption';
        const operatorFormat =
          !isOnlyBlankFilter && filterOperatorFormat(entityType, containsBlank, operatorType, linkedFieldSubType);
        const convertFilterValues = (value: SearchValueType) => {
          if (entityType === 'FIELD' && captionValuesBase64encoded && operatorType !== 'id' && !isOnlyBlankFilter) {
            valueWasEncoded = true;
            return Buffer.from(String(value)).toString('base64');
          }
          return value;
        };

        if (isOnlyBlankFilter) {
          if (entityType === 'ADMIN_FIELD' && entityPropertyName === 'affiliation') {
            filterText += `&filter=${filterFormat}:is_blank:true`;
          } else {
            filterText += `&filter=${filterFormat}:id:is_blank:true`;
          }
        } else if (containsBlank) {
          filterText += `&filter=${filterFormat}:${operatorFormat}${arrayWithoutBlank
            .map(convertFilterValues)
            .join(',')}`;
        } else {
          filterText += `&filter=${filterFormat}:${operatorFormat}${filterValues.map(convertFilterValues).join(',')}`;
        }
      } else if (filterComparisonValue.comparison === '=') {
        if (typeof filterComparisonValue.value[0] === 'boolean') {
          if (filterValues.length === 1) {
            filterText += `&filter=${filterFormat}:eq:${filterValues[0]}`;
          }
        } else {
          filterText += `&filter=${filterFormat}:eq:${filterValues}`;
        }
      }
    } else {
      if (typeof filterComparisonValue.value === 'string') {
        if (filterComparisonValue.comparison === 'contains') {
          filterText += `&filter=${filterFormat}:like:${filterComparisonValue.value}`;
        } else if (filterComparisonValue.comparison === '=') {
          filterText += `&filter=${filterFormat}:eq:${filterComparisonValue.value}`;
        } else if (filterComparisonValue.comparison === '>=') {
          filterText += `&filter=${filterFormat}:gte:${filterComparisonValue.value}`;
        } else if (filterComparisonValue.comparison === '<=') {
          filterText += `&filter=${filterFormat}:lte:${filterComparisonValue.value}`;
        }
      } else if (typeof filterComparisonValue.value === 'number') {
        if (filterComparisonValue.comparison === '=') {
          filterText += `&filter=${filterFormat}:eq:${filterComparisonValue.value.toString()}`;
        } else if (filterComparisonValue.comparison === '>') {
          filterText += `&filter=${filterFormat}:gt:${filterComparisonValue.value.toString()}`;
        } else if (filterComparisonValue.comparison === '<') {
          filterText += `&filter=${filterFormat}:lt:${filterComparisonValue.value.toString()}`;
        } else if (filterComparisonValue.comparison === '>=') {
          filterText += `&filter=${filterFormat}:gte:${filterComparisonValue.value.toString()}`;
        } else if (filterComparisonValue.comparison === '<=') {
          filterText += `&filter=${filterFormat}:lte:${filterComparisonValue.value.toString()}`;
        }
      } else if (typeof filterComparisonValue.value === 'boolean') {
        if (filterComparisonValue.comparison === '=') {
          filterText += `&filter=${filterFormat}:eq:${filterComparisonValue.value}`;
        } else if (filterComparisonValue.comparison === 'is_blank') {
          filterText += `&filter=${filterFormat}:is_blank:${filterComparisonValue.value}`;
        }
      }
    }
  }

  const encodedParam = valueWasEncoded ? '&captionValuesBase64encoded=true' : '';
  return isFirstParam ? `${filterText.substring(1)}${encodedParam}` : `${filterText}${encodedParam}`;
}

export function createSummaryLinkedRecordsFilterText(linkedRecordIds?: number[]) {
  return linkedRecordIds ? `?linkedRecordIds=${linkedRecordIds}` : '';
}

export function createFieldSortText(sorts?: FieldSorts) {
  if (!sorts) {
    return '';
  }

  let sortUrlString = '';

  const sortKeys = Object.keys(sorts);
  for (let i = 0, length = sortKeys.length; i < length; i++) {
    const sortKey = sortKeys[i];
    const keySortOrder = sorts[sortKey].direction.toUpperCase();
    sortUrlString += sortUrlString.length > 0 ? `,${sortKey}:${keySortOrder}` : `${sortKey}:${keySortOrder}`;
  }

  return `&sort=${sortUrlString}`;
}

export function sortingUsersApiUrl(sorts?: FieldSorts) {
  if (!sorts) {
    return '';
  }

  const sortKeys = Object.keys(sorts);
  const sortRules: Array<string> = [];
  for (let i = 0, length = sortKeys.length; i < length; i++) {
    const sortKey = sortKeys[i];
    const sortValue = sorts[sortKey];
    const sortOrder = sortValue.direction.toUpperCase() === 'DESC' ? '-' : '';
    //Regular field
    let sortRule = `${sortOrder}${sortKey}`;
    //hierarchy field
    if (sortValue.customDataProperties?.entityType === 'HIERARCHY_TYPE') {
      sortRule = `${sortOrder}HIERARCHY:${sortValue.customDataProperties?.entityId}`;
    }
    sortRules.push(sortRule);
  }

  return `&sort=${sortRules.join(',')}`;
}

export const appendOptionalUserQueryArguments = ({
  fields,
  archivedUsers,
  sortedField,
  filters,
  sort,
}: UserApiProps) => {
  let queryArguments = '';

  if (fields) {
    queryArguments += `&fields=${fields}`;
  }

  if (archivedUsers === true || archivedUsers === false) {
    queryArguments += `&filter=archived:eq:${archivedUsers}`;
  }
  if (filters) {
    queryArguments += `${createFieldFilterText({ filters })}`;
  }

  if (sort) {
    queryArguments += sortingUsersApiUrl(sort);
  }

  if (sortedField) {
    queryArguments += `&sort=${sortedField}`;
  }

  return queryArguments;
};

/**
 * Transforms the payload received when fetching news into props that
 * NewsEntry component can accept.
 *
 * @param response The payload received from the GET news endpoint.
 * @returns An array of NewsEntry props that can be passed to said component.
 */
export const transformNewsEntries = (response: NewsResult) => {
  const news = response.result || [];

  const parsedNews: Array<NewsEntryProps> = news.map((newsEntry: NewsInfo) => {
    return {
      ...newsEntry,
      date: newsEntry.published,
      author: newsEntry.author?.value,
    };
  });

  return parsedNews;
};
function transformSingleRequirementsData(occupationalRequirementsResponse: OccupationalRequirementsItem) {
  const _mandatoryCompetencies: Array<MandatoryCompetencies> = [];
  const _desirableCompetencies: Array<DesirableCompetencies> = [];
  const _hierarchyFields: Array<HierarchyFields> = [];
  const {
    mandatoryCompetencies,
    desirableCompetencies,
    hierarchyFields,
    hierarchyMatchingOption: matchOption,
  } = occupationalRequirementsResponse;
  for (let j = 0; j < mandatoryCompetencies.length; j++) {
    const mandatoryCompetency = mandatoryCompetencies[j];

    _mandatoryCompetencies.push({
      ...mandatoryCompetency,
      text: mandatoryCompetency.name,
      value: mandatoryCompetency.id,
    });
  }

  for (let j = 0; j < desirableCompetencies.length; j++) {
    const desirableCompetency = desirableCompetencies[j];

    _desirableCompetencies.push({
      ...desirableCompetency,
      text: desirableCompetency.name,
      value: desirableCompetency.id,
    });
  }

  const _hierarchyPerTypeHelper: Record<string, Array<string>> = {};
  for (let j = 0; j < hierarchyFields.length; j++) {
    const hierarchyField = hierarchyFields[j];
    const translationItem = hierarchyField.caption?.translations[0];

    _hierarchyFields.push({
      ...translationItem,
      text: translationItem?.value,
      value: translationItem?.value,
    });

    const hierachyKey = hierarchyField.hierarchyTypeCaption?.translations[0]?.value;
    if (hierachyKey && translationItem?.value) {
      const currentValues = _hierarchyPerTypeHelper[hierachyKey] ?? [];
      _hierarchyPerTypeHelper[hierachyKey] = [...currentValues, translationItem.value];
    }
  }

  const _hierarchyMatchingOption = matchOption ? { text: matchOption, value: matchOption } : undefined;

  const _hierarchyPerType: Record<string, string> = {};
  Object.keys(_hierarchyPerTypeHelper).forEach((key) => {
    _hierarchyPerType[key] = _hierarchyPerTypeHelper[key].join(', ');
  });
  return {
    ...occupationalRequirementsResponse,
    id: occupationalRequirementsResponse.id,
    mandatoryCompetencies: _mandatoryCompetencies,
    desirableCompetencies: _desirableCompetencies,
    hierarchyFields: _hierarchyFields,
    hierarchyMatchingOption: _hierarchyMatchingOption,
    ..._hierarchyPerType,
  };
}

export function transformOccupationRequirements(items: Array<OccupationalRequirementsItem>) {
  const clonedItems = cloneDeep(items);

  const _items: Array<OccupationalRequirementsItem> = [];
  for (let i = 0; i < clonedItems.length; i++) {
    _items.push(transformSingleRequirementsData(clonedItems[i]));
  }

  return _items;
}

export const allFormsForm: ModuleForm = {
  caption: i18next.t('all-forms'),
  defaultForm: false,
  displayInForms: true,
  id: 0,
  removed: false,
  roles: [],
  workflowId: 0,
};

export function transformModuleForms(response: ModuleForms) {
  const formsOrEmpty = response?.result?.forms ?? [];

  const sortedForms: ModuleForms = {
    ...formsOrEmpty,
    result: {
      ...response.result,
      forms: orderBy(formsOrEmpty, [(form) => form.caption.toLowerCase()], ['asc']),
    },
  };

  if (!isEmpty(sortedForms.result.forms)) {
    sortedForms.result.forms?.push(allFormsForm);
  }

  return sortedForms;
}

export function transformModules(modules: Modules) {
  return modules.items
    ? modules.items.map((module) => {
        return {
          ...module,
          forms: orderBy([...(module.forms ?? [])], [(form) => form.caption.toLowerCase()], ['asc']),
        };
      })
    : [];
}

/**
 * Forcibly asserts an item to a particular type; sometimes when a type needs to be asserted as another type TypeScript
 * will make the user first assert the item as unknown and then to the actual type. This function is a descriptive way
 * to do that.
 * @param item - The item to force to another type.
 * @returns {T} The final type assertion.
 */
export const forceAssert = <T>(item: unknown): T => {
  return item as T;
};

/**
 * Strips HTML tags from the given string.
 *
 * @param html the input HTML string
 * @returns the input stripped away from HTML tags.
 */
export const stripHTML = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

/**
 * Find form field from the given form settings field list.
 *
 * @param fields the list of field as specified in the form settings
 * @param fieldName the field key to look for
 *
 * @returns the found field or undefined if not found.
 */
export const findFieldRecursive = (
  fields: Array<DynamicFormField>,
  fieldName: string
): DynamicFormField | undefined => {
  if (!fields) {
    return undefined;
  }

  for (let i = 0, length = fields.length; i < length; i++) {
    if (fields[i].fieldName === fieldName) {
      return fields[i];
    }
    if (fields[i].fields) {
      const foundField = findFieldRecursive(fields[i].fields as Array<DynamicFormField>, fieldName);
      if (foundField) {
        return foundField;
      }
    }
  }
};

export function transformAuditLogResponse(auditData: AuditLogData[]) {
  return auditData.map((auditItem: AuditLogData, index: number) => {
    return {
      ...auditItem,
      id: index,
      recordVersionUpdate:
        typeof auditItem.recordVersionUpdate === 'boolean' ? String(auditItem.recordVersionUpdate) : '',
    };
  });
}

export function transformFormViewConfigrations(response: ViewConfigsWrapper) {
  return [
    ...response?.result?.filter((element) => element.defaultConfig),
    ...response?.result?.filter((element) => !element.defaultConfig).sort((a, b) => a.name?.localeCompare(b.name)),
  ];
}

export function transformFormAddons(response?: Array<AddonsValidation>) {
  if (response) {
    const listOfAddons: Array<AddonsValidation> = [];
    for (let i = 0, addonsListLength = response?.length; i < addonsListLength; i++) {
      if (response[i].success === true) {
        listOfAddons.push(response[i]);
      }
    }
    return listOfAddons;
  }
}

export function exportRecordURLConstruct(url: string, args?: ExportRecordsQueryProps) {
  if (!args) {
    return url;
  }
  if (args?.columns) {
    url += `&columns=${args.columns}`;
  }
  if (args.filters) {
    url += `${createFieldFilterText({ filters: args.filters })}`;
  }
  if (!isEmpty(args.sorts)) {
    url += `${createFieldSortText(args.sorts)}`;
  }
  return url;
}

export function exportRecordListQueryHandler(url: string, args?: ExportRecordsQueryProps) {
  url = exportRecordURLConstruct(url, args);

  return {
    url: url as string,
    responseHandler: (response: Response) => {
      if (response.status === 200) {
        return response.blob();
      }

      return response.json();
    },
  };
}

/**
 * Constructs the URL sort parameter value.
 *
 * @param sortedFields The field sorts provided by the data grid.
 * @param columns The data grid column configuration
 * @param isAllForms Boolean indicating if the function is run on All Forms view
 *
 * @returns Returns the formatted URL sort parameter value prepended with an `&`
 */
export const buildSortedFieldsUrlString = (
  sortedFields: FieldSorts = {},
  columns: DataGridColumnSettings[] = [],
  isAllForms = false
) => {
  let sortedFieldsUrl = '';

  const sortKeys = Object.keys(sortedFields);
  if (sortKeys.length > 0 && columns.length > 0) {
    const listOfColumnsWithSortFilters = [];
    for (let i = 0, length = columns.length; i < length; i++) {
      const {
        entityType,
        entityPropertyName,
        linkedFieldId,
        linkedEntityType: linkedFieldSubType,
        grandScoreFieldItemType: grandScoreSubType,
        questionRecordListFieldTypes: questionnaireSubType,
      } = forceAssert<FilterCustomProperties>(columns[i].customDataProperties);
      const fieldSubType = grandScoreSubType ?? questionnaireSubType ?? linkedFieldSubType;
      const linkedFieldFormat = linkedFieldsFormat(linkedFieldSubType, linkedFieldId, entityPropertyName);

      const sortKeyIndex = sortKeys.indexOf(columns[i].field);
      if (sortKeyIndex > -1) {
        listOfColumnsWithSortFilters.push({
          ascending: sortedFields[columns[i].field].direction,
          sortEntityType: columns[i]?.customDataProperties?.sortEntityType ?? '',
          entityId: columns[i]?.customDataProperties?.entityId ?? '',
          order: sortedFields[columns[i].field].order,
          entityType,
          entityPropertyName,
          linkedFieldFormat,
          fieldSubType,
        });
      }
    }

    const orderedListOfColumnsWithSortFilters = listOfColumnsWithSortFilters.sort((a, b) => a.order - b.order);
    for (let i = 0, length = orderedListOfColumnsWithSortFilters.length; i < length; i++) {
      const { ascending, sortEntityType, entityType, entityId, linkedFieldFormat, fieldSubType, entityPropertyName } =
        orderedListOfColumnsWithSortFilters[i];
      const sortOrder = ascending === 'asc' ? '' : '-';
      if (sortEntityType) {
        if ((entityType === 'RECORD_LINK' || entityType === 'REVERSE_RECORD_LINK') && entityId) {
          let subType = fieldSubType;
          if (subType === 'HIERARCHY_TYPE') {
            subType = 'hierarchy';
          }
          const entityTypeValue = entityType === 'RECORD_LINK' ? 'recordlink' : 'reversedrecordlink';
          const key = `${entityTypeValue}:${entityId}:${subType}:${linkedFieldFormat}`;
          sortedFieldsUrl += `&sort_directive=${sortOrder}${key}`;
        } else if (!isAllForms && entityType === 'KEYWORD') {
          sortedFieldsUrl += `&sort_directive=${sortOrder}${sortEntityType}:${entityPropertyName}`;
        } else if (!isAllForms && entityType === 'GRAND_SCORE' && entityId) {
          const key = `${entityType.replace('_', '')}:${entityId}:${fieldSubType}`;
          sortedFieldsUrl += `&sort_directive=${sortOrder}${key}`;
        } else if (entityType === 'QUESTIONNAIRE' && entityId) {
          const key = `${entityType}:${entityId}:${fieldSubType}`;
          sortedFieldsUrl += `&sort_directive=${sortOrder}${key}`;
        } else if (entityType === 'WORKFLOW_STEP' && entityId) {
          sortedFieldsUrl += `&sort_directive=${sortOrder}workflowstep:${entityId}:TRANSITIONDATE`;
        } else {
          sortedFieldsUrl += `&sort_directive=${sortOrder}${sortEntityType}:${entityId}`;
        }
      }
    }
  }

  return sortedFieldsUrl;
};

export const buildSortedMyActivitiesUrl = (sortedFields: FieldSorts = {}, columns: DataGridColumnSettings[] = []) => {
  let sortedFieldsUrl = '';
  const sortKeys = Object.keys(sortedFields);
  if (sortKeys.length > 0 && columns.length > 0) {
    const listOfColumnsWithSortFilters = [];
    for (let i = 0, length = columns.length; i < length; i++) {
      const sortKeyIndex = sortKeys.indexOf(columns[i].field);
      if (sortKeyIndex > -1) {
        listOfColumnsWithSortFilters.push({
          ascending: sortedFields[columns[i].field].direction,
          order: sortedFields[columns[i].field].order,
          sortField: sortedFields[columns[i].field].customDataProperties?.sortField,
        });
      }
    }

    const orderedListOfColumnsWithSortFilters = listOfColumnsWithSortFilters.sort((a, b) => a.order - b.order);
    for (let i = 0, length = orderedListOfColumnsWithSortFilters.length; i < length; i++) {
      const { ascending, sortField } = orderedListOfColumnsWithSortFilters[i];
      const sortOrder = ascending === 'asc' ? '' : '-';
      if (sortField) {
        sortedFieldsUrl += `&sort_directive=${sortOrder}${sortField}`;
      }
    }
    return sortedFieldsUrl;
  }
};

export function isEmptyObject(value: Record<string, unknown>) {
  return Object.keys(value).length === 0;
}

/**
 * A helper method to define which color is to be used for a text based on its background.
 *
 * @param color of a background
 * @returns color of a text that meets the contract criteria
 */
export const getTextColor = (color: string) => {
  const rgbColor = parseInt(color.substring(1), 16);
  const red = (rgbColor >> 16) & 0xff;
  const green = (rgbColor >> 8) & 0xff;
  const blue = (rgbColor >> 0) & 0xff;
  const luminance = 0.2126 * red + 0.7152 * green + 0.0722 * blue; // according to ITU-R BT.709-6

  return luminance < 186 ? '#fff' : ''; // 0...255
};

export const createCustomSortWithOrderBy = (rules?: FieldSorts) => {
  if (!rules) {
    return '';
  }

  let sortKeys = '';
  let sortDirections = '';
  for (const rule in rules) {
    const direction = rules[rule].direction.toUpperCase();
    if (sortKeys.length !== 0) {
      sortKeys = sortKeys + ',' + rule;
      sortDirections = sortDirections + ',' + direction;
    } else {
      sortKeys = rule;
      sortDirections = direction;
    }
  }
  return `&sort=${sortKeys}&order_by=${sortDirections}`;
};

export const isTextTruncated = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) {
    return element.scrollWidth > element.clientWidth;
  }
  return false;
};

export const buildGlobalHierarchyFiltersUrl = (
  globalFilters?: Record<number, number[]>,
  globalFiltersMatchingOption?: string
) => {
  if (globalFilters && globalFiltersMatchingOption && globalFiltersMatchingOption.length > 0) {
    let filterString = '';
    const keys = Object.keys(globalFilters);
    for (let i = 0, length = keys.length; i < length; i++) {
      const typeId = keys[i];
      const ids = globalFilters[Number(typeId)];

      filterString += `&filter=globalHierarchy:${typeId}:id:in:${ids.join(',')}`;
    }
    return `${filterString}&globalHierarchyMatch=${globalFiltersMatchingOption}`;
  }
};

export const tryParseInt = (value: string, result: number) => {
  result = parseInt(value);
  return !isNaN(result);
};
