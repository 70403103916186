import React from 'react';
import {
  DynamicFieldComponentProps,
  DynamicFormField,
  EmptyFieldLabel,
  ErrorLabel,
  FieldLabel,
  FormLoader,
  RequiredIndicator,
} from '@myosh/odin-components';
import { SummaryRecordLinkValue } from '../../../@types/summary-record-link-field';
import SummaryRecordLinkField from './summary-record-link-field';
import { useGetSummaryRecordsLinkedQuery } from '../../../redux/services/record';

export interface SummaryRecordLinkProps extends DynamicFieldComponentProps<Array<SummaryRecordLinkValue>> {
  recordId?: number;
  fieldsToInclude?: Array<number>;
  summaryRecordLinkFormFields?: Array<DynamicFormField>;
}

export default function SummaryRecordLink({
  id,
  label,
  recordId,
  fieldsToInclude,
  summaryRecordLinkFormFields,
  error,
  readOnly,
  required,
}: SummaryRecordLinkProps) {
  const { data: summaryLinkedRecords, isFetching } = useGetSummaryRecordsLinkedQuery(
    { recordId, fieldId: Number(id) },
    {
      skip: recordId === undefined || id === undefined || isNaN(Number(id)) || recordId === 0,
    }
  );

  const hasNoSummaryLinkedRecordsFieldItems = summaryLinkedRecords?.every(
    (record) => !record.items || record.items.length === 0
  );

  return (
    <>
      {label && (
        <div className="flex">
          <FieldLabel label={label} />
          <RequiredIndicator readOnly={readOnly} required={required} />
        </div>
      )}
      {!isFetching && readOnly && hasNoSummaryLinkedRecordsFieldItems && <EmptyFieldLabel />}
      {!isFetching &&
        summaryLinkedRecords?.map((summaryRecord) => (
          <SummaryRecordLinkField
            key={`${id}_${summaryRecord.fieldId}`}
            formFields={summaryRecordLinkFormFields ?? []}
            summaryRecordLink={summaryRecord}
            fieldsToInclude={fieldsToInclude ?? []}
            readOnly={readOnly}
            formId={summaryRecord.formId}
            id={Number(id)}
            recordId={recordId ?? 0}
          />
        ))}
      {isFetching && <FormLoader width="xl" height="lg" />}
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </>
  );
}
