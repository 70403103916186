import React from 'react';
import { OdinController } from '@myosh/odin-components';
import { Control } from 'react-hook-form';
import PasswordField, { PasswordFieldProps } from './password-field.component';

interface HfPasswordFieldProps extends PasswordFieldProps {
  control: Control;
}

export default function HfPasswordField({
  control,
  name = '',
  label,
  value,
  readOnly,
  required,
  validations,
  error,
  onChange,
  onChangeEvent,
  fieldValueConverter,
  onFocus,
}: HfPasswordFieldProps) {
  return (
    <OdinController
      name={name}
      defaultValue={value}
      control={control}
      onChange={onChange}
      fieldValueConverter={fieldValueConverter}
      render={({ field: { value, onChange }, fieldState: { error: fieldError } }) => (
        <PasswordField
          name={name}
          label={label}
          value={value}
          error={error ?? fieldError?.message}
          readOnly={readOnly}
          required={required}
          validations={validations}
          onChange={onChange}
          onChangeEvent={onChangeEvent}
          onFocus={onFocus}
        />
      )}
    />
  );
}
