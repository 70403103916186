import { ModalDialog, ModalDialogRef } from '@myosh/odin-components';
import React, { forwardRef, Ref, useRef } from 'react';
import RecordWrapper from './record-wrapper.component';

interface CreateLinkedRecordProps {
  header: string;
  visible: boolean;
  formId: number;
  shown?: () => void;
  hidden: () => void;
  onRecordCreated: (recordId: number) => void;
  fieldId: number;
  parentRecordId?: number;
  isTextualQuestionnaireLinkedRecords?: boolean;
}

const CreateLinkedRecord = (props: CreateLinkedRecordProps, ref: Ref<ModalDialogRef>) => {
  const {
    header,
    visible,
    shown,
    hidden,
    formId,
    onRecordCreated,
    fieldId,
    parentRecordId,
    isTextualQuestionnaireLinkedRecords,
  } = props;

  const linkedRecordId = useRef<number>();

  const onClose = () => {
    if (linkedRecordId.current) {
      onRecordCreated(linkedRecordId.current);
      linkedRecordId.current = undefined;
    }
    hidden();
  };

  const handleLinkedRecordCreated = (id: number) => {
    linkedRecordId.current = id;
  };

  return (
    <ModalDialog ref={ref} header={header} visible={visible} shown={shown} hidden={hidden} fullScreen>
      <div className="h-full overflow-hidden">
        <RecordWrapper
          formId={formId}
          onClose={onClose}
          onLinkedRecordCreated={handleLinkedRecordCreated}
          fieldId={fieldId}
          parentRecordId={parentRecordId}
          isTextualQuestionnaireLinkedRecords={isTextualQuestionnaireLinkedRecords}
        />
      </div>
    </ModalDialog>
  );
};

export default forwardRef(CreateLinkedRecord);
