import { Button, OdinIcon, OdinIconSize } from '@myosh/odin-components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiLogger } from '../../../hooks/use-api-logger';

// error prop is type any from the library
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const ErrorFallback = (error: any) => {
  const { t } = useTranslation();
  const log = useApiLogger();

  useEffect(() => {
    if (error?.error?.message || error?.message) {
      log('Top level error', { error: error?.error?.message || error?.message });
    }
  }, [error?.error?.message, error?.message]);

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div className="flex items-center justify-center min-h-screen  bg-gray-5">
      <div
        role="alert"
        className="flex flex-col items-center justify-center text-center bg-mono-1 text-error p-10 border border-error rounded shadow-lg"
      >
        <OdinIcon size={OdinIconSize.Large} icon="Alert" className="pb-4" />
        <h1 className="text-2xl mb-2 font-bold">{t('error-boundary-warning')}</h1>
        {(error?.error?.message || error?.message) && (
          <pre className="whitespace-pre-wrap break-words mb-4 text-primary-1">
            {error?.error?.message ?? error?.message}
          </pre>
        )}
        <div>
          <Button onClick={handleReload} type="primary">
            {t('reload')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
