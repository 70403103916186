import React, { useRef } from 'react';
import cx from 'classnames';
import { MenuComponentProps, MenuItem } from '../../record/record-menu.component';
import { ModalDialogRef, OdinIconType } from '@myosh/odin-components';
import GroupAuditView from './group-audit-view.component';
import { useTranslation } from 'react-i18next';

interface GroupMenuProps {
  groupTitle: string;
  groupId: number;
  fullScreen?: boolean;
}

const GroupMenu = ({ groupTitle, groupId, fullScreen = false }: GroupMenuProps) => {
  const { t } = useTranslation();

  const auditModalReference = useRef<ModalDialogRef>(null);

  const onAuditButtonClicked = () => {
    if (auditModalReference.current) {
      auditModalReference.current.show();
    }
  };

  const actionDropdownItemStyle = cx({
    'text-sm flex items-center whitespace-nowrap px-2 py-1 leading-5 hover:bg-primary-4 cursor-pointer': !fullScreen,
  });

  const menuStyle = cx({
    'z-30 absolute right-0 top-9 hidden w-48 flex-col bg-mono-1 py-1 shadow group-hover:flex': !fullScreen,
    flex: fullScreen,
  });

  const menus: MenuComponentProps[] = [
    {
      title: t('audit-log'),
      className: actionDropdownItemStyle,
      onClick: onAuditButtonClicked,
      icon: 'Time',
      iconType: OdinIconType.Line,
    },
  ];
  return (
    <div className={menuStyle}>
      {menus.map((menu) => {
        return <MenuItem key={menu.title} menu={menu} fullScreenMode={fullScreen} />;
      })}
      <GroupAuditView
        ref={auditModalReference}
        header={`${t('audit-log')} - ${t('group')} - ${groupTitle}`}
        groupId={groupId}
      />
    </div>
  );
};

export default GroupMenu;
