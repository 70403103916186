import React, { useEffect, useMemo, useState } from 'react';
import { DropDownResult, isDropDownNoSelection, ModalDialog } from '@myosh/odin-components';
import ViewNameEditor, { View } from './view-name-editor.component';
import { ViewConfig } from '../../@types/views';
import { useDeleteViewConfigMutation, useUpdateViewConfigMutation } from '../../redux/services/view';
import useProfileData from '../../hooks/use-profile-data';
import { useTranslation } from 'react-i18next';
import useViewConfigs from '../../hooks/use-view-configs';
import { showError, showSuccess } from '../../services/notification.service';

interface ManageViewsModalProps {
  title?: string;
  visible: boolean;
  moduleId: number;
  selectedForm: DropDownResult;
  onClose: () => void;
}

export default function ManageViewsModal({ title, visible, onClose, moduleId, selectedForm }: ManageViewsModalProps) {
  const isAllForms = selectedForm.value === 0;
  const { profileData: { user: userData } = {} } = useProfileData();
  const [deleteViewConfig] = useDeleteViewConfigMutation();
  const [updateViewConfig] = useUpdateViewConfigMutation();
  const [views, setViews] = useState<View[]>([]);
  const { t } = useTranslation();

  const { data: viewConfigs } = useViewConfigs(
    selectedForm && !isDropDownNoSelection(selectedForm) ? (selectedForm.value as number) : undefined,
    visible,
    moduleId,
    isAllForms
  );

  const viewData = useMemo(() => {
    return (
      viewConfigs
        ?.map((view: ViewConfig) => {
          // default views are not ediable,
          // global views are only editable by admins
          // user views are editable by users
          const canEdit = view.defaultConfig ? false : view.global ? userData?.hasAdminAccess : true;

          return {
            id: view.id,
            name: view.name,
            isGlobal: view.global,
            canEdit: canEdit,
          } as View;
        })
        //Sorts global views first and then alphabetically by name
        .sort((view1, view2) =>
          view1.isGlobal !== view2.isGlobal ? (view1.isGlobal ? -1 : 1) : view1.name.localeCompare(view2.name)
        )
    );
  }, [viewConfigs]);

  useEffect(() => setViews(viewData ?? []), [viewData]);

  const handleChangeItem = (id: number, name: string) => {
    setViews(views.map((view) => (view.id === id ? { ...view, name } : view)));

    updateViewConfig({ id, name })
      .unwrap()
      .then(() => {
        showSuccess(t('view-successfull-update'));
      })
      .catch(() => {
        // revert change if an error occurred
        showError(t('error-rename-view'));
        setViews(views);
      });
  };

  const handleRemoveItem = (id: number) => {
    setViews(views.filter((view) => view.id !== id));

    deleteViewConfig(id)
      .unwrap()
      .then(() => {
        showSuccess(t('view-successfull-delete'));
      })
      .catch(() => {
        // revert delete if an error occurred
        showError(t('error-delete-view'));
        setViews(views);
      });
  };

  return (
    <>
      <ModalDialog
        header={title ?? t('manage-views')}
        visible={visible}
        hidden={onClose}
        minDialogWidth={380}
        maxDialogWidth={380}
        minDialogHeight={280}
        maxDialogHeight={380}
        closeOnEscape={false}
        renderChildrenLazily={true}
      >
        {views.map((view, index) => (
          <ViewNameEditor
            key={view.id}
            view={view}
            isEven={index % 2 === 0}
            onChange={handleChangeItem}
            onRemove={handleRemoveItem}
          />
        ))}
      </ModalDialog>
    </>
  );
}
