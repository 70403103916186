import React from 'react';
import { DynamicFieldComponentProps, Input, MakeRequired } from '@myosh/odin-components';

interface InputValidation {
  pattern: string;
  errorLabel: string;
}

export interface PasswordFieldProps extends MakeRequired<DynamicFieldComponentProps<string>, 'name'> {
  onChangeEvent?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputStyles?: string;
  validations?: Array<InputValidation>;
}

export default function PasswordField({
  name,
  label,
  value,
  error,
  readOnly,
  required,
  validations,
  onChange,
  onChangeEvent,
  onFocus,
}: PasswordFieldProps) {
  return (
    <Input
      name={name}
      label={label}
      type={'password'}
      value={value}
      placeholder={'********'}
      readOnly={readOnly}
      required={required}
      validations={validations}
      onChange={onChange}
      onChangeEvent={onChangeEvent}
      onFocus={onFocus}
      error={error}
    />
  );
}
