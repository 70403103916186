import { JsonDataItem, DataTableSelection } from '@myosh/odin-components';
import { groupBy } from 'lodash';
import { ExtendedDynamicFormHierarchySettings, stripHTML } from '../../../common/common-functions';
import { formatDisplayDate } from '../../../common/date-util';
import { CombinedFieldType } from '../../../@types/forms';
import { PersonOption } from '../../../@types/options';
import { Columns, Conflict, ConflictResolutionItem } from './conflicts-modal.component';

export function selectColumn(data: Conflict[], columnIndex: number) {
  const selected: Array<DataTableSelection> = [];
  for (let i = 0; i < data.length; i++) {
    selected.push({
      rowIndex: i,
      cellIndex: columnIndex,
    });
  }
  return selected;
}

export function formatSelectionData(selection: Array<DataTableSelection>) {
  let selected: Array<DataTableSelection> = [];
  const selectionData = groupBy(selection, 'rowIndex');

  for (const rowIndex in selectionData) {
    let selectedItem;
    if (selectionData[rowIndex].length === 2) {
      selectedItem = selectionData[rowIndex][1]; // keep only latest selection
    } else {
      selectedItem = selectionData[rowIndex][0];
    }

    selected = [...selected, { rowIndex: Number(rowIndex), cellIndex: selectedItem.cellIndex }];
  }

  return selected;
}

export function resolvedFieldConflicts(
  selectedCells: DataTableSelection[],
  data: Conflict[]
): ConflictResolutionItem[] {
  const resolvedFieldConflicts: ConflictResolutionItem[] = [];

  for (let i = 0; i < selectedCells.length; i++) {
    const selectedCell = selectedCells[i];
    const id = data[selectedCell.rowIndex].id;
    if (selectedCell.cellIndex === Columns.Theirs) {
      resolvedFieldConflicts.push({
        id,
        value: data[selectedCell.rowIndex].theirValue,
      });
    } else {
      resolvedFieldConflicts.push({
        id,
        value: data[selectedCell.rowIndex].myValue,
      });
    }
  }

  return resolvedFieldConflicts;
}

export function formatDisplayValue(
  value: unknown,
  type: CombinedFieldType,
  customFieldType?: string,
  dynamicProperties?: JsonDataItem
) {
  let displayValue = value;

  if ('RICHTEXTAREA' === type) {
    displayValue = stripHTML(value as string);
  } else if ('DATEFIELD' === type) {
    const dateObject = typeof value === 'string' ? new Date(Date.parse(value)) : (value as Date);
    displayValue = formatDisplayDate(dateObject);
  } else if ('RISKRATING' === type) {
    displayValue = (value as Record<string, string>).value;
  } else if ('USER_RECORDLINK' === type) {
    displayValue = (value as Array<PersonOption>).map((person) => person.value).join(', ');
  } else if ('CUSTOM' === type && 'HIERARCHY' === customFieldType && dynamicProperties) {
    const _value = value as Record<string, string>;

    const hierarchyTypes =
      (dynamicProperties.hierarchySettings as ExtendedDynamicFormHierarchySettings).hierarchyTypes ?? [];

    displayValue = hierarchyTypes
      .map((hierarchyType) => _value[hierarchyType.caption])
      .filter((value) => value)
      .join(', ');
  }

  return displayValue;
}
