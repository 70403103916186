import { OdinController } from '@myosh/odin-components';
import React from 'react';
import { Control } from 'react-hook-form';
import InvitationField, { InvitationFieldProps } from './invitation-field.component';

interface HfInvitationFieldProps extends InvitationFieldProps {
  control?: Control;
  name?: string;
}

function HfInvitationField({
  name = 'invitation',
  control,
  value,
  onChange,
  readOnly,
  required,
  recordId,
  permissions,
}: HfInvitationFieldProps) {
  return (
    <OdinController
      name={name}
      control={control}
      defaultValue={value}
      onChange={onChange}
      render={({ field: { value }, fieldState: { error } }) => (
        <InvitationField
          readOnly={readOnly}
          required={required}
          error={error?.message}
          value={value}
          recordId={Number(recordId)}
          permissions={permissions}
        />
      )}
    />
  );
}

export default HfInvitationField;
