import { DynamicFieldComponentProps } from '@myosh/odin-components';
import React, { useState } from 'react';
import InviteDialog from './invite-modal.component';
import WrapperStyledButtonField from '../../common/wrapper-styled-button-field';

export interface InviteButtonFieldProps extends DynamicFieldComponentProps<unknown> {
  buttonCaption?: string;
  recordId?: number;
  saveRecord?: () => void;
}

function InviteButtonField({ id, buttonCaption, recordId, readOnly, saveRecord }: InviteButtonFieldProps) {
  const [showInviteDialog, setShowInviteDialog] = useState(false);

  const onOpenDialog = () => {
    if (recordId === undefined) {
      saveRecord?.();
    } else {
      setShowInviteDialog(true);
    }
  };
  const onCloseDialog = () => setShowInviteDialog(false);

  return (
    <>
      <WrapperStyledButtonField onClick={onOpenDialog} hidden={readOnly} buttonCaption={buttonCaption} />
      <InviteDialog fieldId={Number(id)} recordId={recordId ?? 0} visible={showInviteDialog} hide={onCloseDialog} />
    </>
  );
}

export default InviteButtonField;
