import React, { Key, Ref, forwardRef, useCallback, useState, useImperativeHandle, useRef } from 'react';
import {
  DataGridDataCell,
  DataTable,
  DataTableColumn,
  DataTableSelection,
  JsonData,
  JsonDataItem,
  ModalDialog,
} from '@myosh/odin-components';
import ConflictsHeader from './conflicts-header.component';
import ConflictsFooter from './conflicts-footer.component';
import { Observable, Subject } from 'rxjs';
import { useLazyGetAuditLogsQuery } from '../../../redux/services/record';
import { formatSelectionData, resolvedFieldConflicts, selectColumn } from './conflicts-modal-util';
import { AuditLogData } from '../../../@types/audit';
import { forceAssert } from '../../../common/common-functions';
import { useTranslation } from 'react-i18next';
import ConflictsTableCell from './table-cells/conflicts-table-cell.component';
import { CombinedFieldType } from '../../../@types/forms';
export interface Conflict {
  id: Key;
  caption: string;
  type: CombinedFieldType;
  customFieldType?: string;
  theirValue: unknown;
  myValue: unknown;
  dynamicProperties?: JsonDataItem;
}

export enum Columns {
  Theirs = 1, // original
  Mine = 2, // new
}

interface ConflictModalState {
  visible: boolean;
  data: Conflict[];
  selectedCells: Array<DataTableSelection>;
  lastModifiedBy?: string;
}

const initialState: ConflictModalState = {
  visible: false,
  data: [],
  selectedCells: [],
};

export interface ConflictResolution {
  fields: ConflictResolutionItem[];
}

export interface ConflictResolutionItem {
  id: Key;
  value: unknown;
}

interface ConflictModalProps {
  recordId?: string;
}

export interface ConflictsModalRef {
  show: (data: Conflict[]) => Observable<ConflictResolution>;
}

const ConflictsModal = ({ recordId }: ConflictModalProps, ref: Ref<ConflictsModalRef>) => {
  const [state, setState] = useState<ConflictModalState>(initialState);
  const resolved = state.data.length === state.selectedCells.length;
  const conflictResolutionRef = useRef<Subject<ConflictResolution>>(new Subject<ConflictResolution>());
  const [getAuditLogs] = useLazyGetAuditLogsQuery();
  const { t } = useTranslation();

  const handleSelectionChange = useCallback((selection: Array<DataTableSelection>) => {
    const _selected = formatSelectionData(selection);
    setState((state) => {
      return {
        ...state,
        selectedCells: _selected,
      };
    });
  }, []);

  const handleIsCellSelectable = useCallback((field: string) => {
    return field !== 'caption';
  }, []);

  const showDialog = useCallback(async (data: Conflict[]) => {
    let lastModifiedBy: string;
    try {
      const auditData: AuditLogData[] = await getAuditLogs({
        recordId: recordId as string,
        page: 1,
        pageSize: 1,
        sortByVersion: 'desc',
      }).unwrap();

      lastModifiedBy = auditData[0]?.user;
    } catch (_) {}

    setState((state) => {
      return {
        ...state,
        data,
        visible: true,
        selectedCells: selectColumn(data, Columns.Mine),
        lastModifiedBy,
      };
    });
  }, []);

  const hideDialog = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        visible: false,
      };
    });

    conflictResolutionRef.current.complete();
  }, []);

  const handleClose = useCallback(() => {
    setState(initialState);

    conflictResolutionRef.current.next({ fields: [] });
    conflictResolutionRef.current.complete();
  }, []);

  const handleSave = () => {
    const _resolvedFieldConflicts = resolvedFieldConflicts(state.selectedCells, state.data);

    conflictResolutionRef.current.next({
      fields: _resolvedFieldConflicts,
    });

    hideDialog();
  };

  const show = (data: Conflict[]) => {
    showDialog(data);
    return conflictResolutionRef.current.asObservable();
  };

  useImperativeHandle(ref, () => ({
    show,
  }));

  const header = <ConflictsHeader lastModifiedBy={state.lastModifiedBy} />;

  const footer = <ConflictsFooter resolved={resolved} onSave={handleSave} />;

  const conflictCell = (rowData: JsonDataItem, column: Columns) => {
    const conflict = rowData as unknown as Conflict;
    const value = column === Columns.Mine ? conflict.myValue : conflict.theirValue;
    return (
      <ConflictsTableCell
        recordId={recordId}
        value={value}
        type={conflict.type}
        customFieldType={conflict.customFieldType}
        dynamicProperties={conflict.dynamicProperties}
        width={290}
      />
    );
  };

  const columns: Array<DataTableColumn> = [
    {
      field: 'caption',
      header: t('field'),
      customCell: (rowData: JsonDataItem) => <DataGridDataCell value={rowData.caption} width={180} />,
    },
    {
      field: 'theirValue',
      header: t('theirs'),
      customCell: (rowData: JsonDataItem) => conflictCell(rowData, Columns.Theirs),
    },
    {
      field: 'myValue',
      header: t('mine'),
      customCell: (rowData: JsonDataItem) => conflictCell(rowData, Columns.Mine),
    },
  ];

  return (
    <ModalDialog
      minDialogHeight={500}
      minDialogWidth={800}
      maxDialogHeight={500}
      maxDialogWidth={800}
      header={header}
      footer={footer}
      hidden={handleClose}
      visible={state.visible}
      closeOnEscape={false}
      showCloseIcon
      renderChildrenLazily
    >
      <DataTable
        data={forceAssert<JsonData>(state.data)}
        columns={columns}
        selected={state.selectedCells}
        isCellSelection={true}
        onSelectionChange={handleSelectionChange}
        handleIsDataSelectable={handleIsCellSelectable}
      />
    </ModalDialog>
  );
};

export default forwardRef(ConflictsModal);
