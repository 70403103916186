import React, { forwardRef, Ref } from 'react';
import { DataGridCellRendererProps, DataGridDataCell } from '@myosh/odin-components';
import { forceAssert } from '../../common/common-functions';
import { EntityReference } from '../../@types/views';

export type GrandScoreFieldType = 'MAX_POSSIBLE' | 'TOTAL_SCORE' | 'PERCENTAGE';

interface GrandScoreFieldEntityReference extends EntityReference {
  grandScoreFieldItemType: GrandScoreFieldType;
}

export interface GrandScoreDataProps {
  maxPossible: number;
  totalScore: number;
  percentage: string;
}

export function transformGrandScoreCellData(
  grandScoreFieldItemType: GrandScoreFieldType,
  grandScoreData?: GrandScoreDataProps
) {
  if (grandScoreData) {
    switch (grandScoreFieldItemType) {
      case 'MAX_POSSIBLE':
        return grandScoreData.maxPossible.toFixed(2);
      case 'TOTAL_SCORE':
        return grandScoreData.totalScore.toFixed(2);
      case 'PERCENTAGE':
        return grandScoreData.percentage;
      default:
        return '';
    }
  }

  return '';
}

const GrandScoreDataCell = ({ column, rowData }: DataGridCellRendererProps, forwardRef: Ref<HTMLDivElement>) => {
  const columnEntityReference = forceAssert<GrandScoreFieldEntityReference>(column.customDataProperties);
  const { entityId, grandScoreFieldItemType } = columnEntityReference;
  const displayValue = transformGrandScoreCellData(
    grandScoreFieldItemType,
    forceAssert<GrandScoreDataProps>(rowData[entityId])
  );

  return <DataGridDataCell ref={forwardRef} value={displayValue} width={column.width} />;
};

export default forwardRef(GrandScoreDataCell);
