import React from 'react';
import { DynamicFieldComponentProps, OdinController } from '@myosh/odin-components';
import { Control } from 'react-hook-form';
import NotificationPropsField from './notification-props-field';
import { SchedulerRule } from '../../../../@types/scheduler-record';

interface HfNotificationPropsField extends DynamicFieldComponentProps<SchedulerRule> {
  control: Control;
}

export default function HfNotificationPropsField({
  control,
  name = 'notificationType',
  label,
  readOnly,
  onChange,
}: HfNotificationPropsField) {
  return (
    <OdinController
      name={name}
      control={control}
      onChange={onChange}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <NotificationPropsField
          name={name}
          label={label}
          value={value}
          error={error?.message}
          readOnly={readOnly}
          onChange={onChange}
          control={control}
        />
      )}
    />
  );
}
