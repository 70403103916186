import React from 'react';
import { Button, OdinIcon, OdinIconType } from '@myosh/odin-components';

type WrapperStyledButtonField = {
  onClick: () => void;
  buttonCaption?: string;
  hidden?: boolean;
  disabled?: boolean;
  icon?: string;
};
const WrapperStyledButtonField = ({ onClick, buttonCaption, disabled, hidden, icon }: WrapperStyledButtonField) => {
  const getIconElement = () => {
    if (icon) {
      return <OdinIcon icon={icon} type={OdinIconType.Line} className="mr-2" />;
    }
  };

  return (
    <div className="flex h-12 items-center m-1">
      <Button onClick={onClick} disabled={disabled} hidden={hidden} variant="alternative" icon={getIconElement()}>
        {buttonCaption}
      </Button>
    </div>
  );
};

export default WrapperStyledButtonField;
