import { configureStore } from '@reduxjs/toolkit';
import { api } from './services/api';
import { fileApi } from './services/file';
import { fieldApi } from './services/field';
import { recordApi } from './services/record';
import { userApi } from './services/user';
import { viewApi } from './services/view';
import { occupationApi } from './services/occupation';
import { groupApi } from './services/group';
import { hierarchyApi } from './services/hierarchy';
import { competencyApi } from './services/competency';
import { scheduledRecordApi } from './services/scheduled-record';
import { ruleApi } from './services/rule';
import { notificationApi } from './services/notification';
import { riskMatrixApi } from './services/risk-matrix';
import dynamicPage from './slices/dynamic-page-slice';
import layout from './slices/layout';
import schema from './slices/schema';
import globalHierarchyFilters from './slices/global-hierarchies';
import exportState from './slices/export-slice';
import { recordListMenuApi } from './services/record-list-menu';

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [recordApi.reducerPath]: recordApi.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
    [fieldApi.reducerPath]: fieldApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [viewApi.reducerPath]: viewApi.reducer,
    [occupationApi.reducerPath]: occupationApi.reducer,
    [groupApi.reducerPath]: groupApi.reducer,
    [hierarchyApi.reducerPath]: hierarchyApi.reducer,
    [competencyApi.reducerPath]: competencyApi.reducer,
    [scheduledRecordApi.reducerPath]: scheduledRecordApi.reducer,
    [ruleApi.reducerPath]: ruleApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [riskMatrixApi.reducerPath]: riskMatrixApi.reducer,
    [recordListMenuApi.reducerPath]: recordListMenuApi.reducer,
    layout,
    schema,
    dynamicPage,
    globalHierarchyFilters,
    exportState,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(recordApi.middleware)
      .concat(fileApi.middleware)
      .concat(fieldApi.middleware)
      .concat(userApi.middleware)
      .concat(viewApi.middleware)
      .concat(occupationApi.middleware)
      .concat(groupApi.middleware)
      .concat(hierarchyApi.middleware)
      .concat(competencyApi.middleware)
      .concat(scheduledRecordApi.middleware)
      .concat(ruleApi.middleware)
      .concat(notificationApi.middleware)
      .concat(riskMatrixApi.middleware)
      .concat(recordListMenuApi.middleware),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
